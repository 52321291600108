import React from 'react';
import { SummaryDetailProps } from './SummaryDetail.props';

const SummaryDetail: React.FC<SummaryDetailProps> = ({ detail, title }) => {
  return (
    <div className="h-12 mx-2">
      <p className="heading-400">{detail}</p>
      <span className="body-300">{title}</span>
    </div>
  );
};

export default SummaryDetail;
