export const directDebitInactiveBannerStatuses = [
  'CANCELLED',
  'INACTIVE',
  'SUSPENDED',
];

export const directDebitInactiveStatuses = [
  ...directDebitInactiveBannerStatuses,
  'REJECTED',
];
