import React, { useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'ui';
import { formatLineBreaks } from 'ui/utils/FormatText.utils';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

interface Props {
  title: string;
  detail: string | number;
  principal?: boolean;
  className?: string;
  smallDetail?: boolean;
  questionTooltip?: string;
}

const ScheduleDetail = ({
  title,
  detail,
  principal,
  className,
  smallDetail,
  questionTooltip = null,
}: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <div>
      <p
        className={classNames(
          {
            'heading-500': principal && !smallDetail,
            'heading-400': !principal && !smallDetail,
            'heading-300': smallDetail && principal,
            'heading-200': smallDetail,
          },
          className
        )}
      >
        {detail}
      </p>
      <span className="body-400">
        <div
          className="flex items-center gap-x-1"
          onMouseOver={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {title}
          {questionTooltip && (
            <div className="relative flex items-center">
              <QuestionMarkCircleIcon width={14} className="cursor-pointer" />
              {showTooltip && (
                <Tooltip position="right">
                  {formatLineBreaks(questionTooltip)}
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </span>
    </div>
  );
};

export default ScheduleDetail;
