import { add, format, parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

//use this methods to parse ISO to date and compare dates
const parseISOToZonedDate = (isoTimestamp: string) => {
  return utcToZonedTime(parseISO(isoTimestamp), 'Europe/London');
};

const zonedNow = () => {
  return utcToZonedTime(new Date(), 'Europe/London');
};

const zonedDate = (date: Date | string | number) => {
  return utcToZonedTime(new Date(date), 'Europe/London');
};

export const zonedStringDate = (date: Date | string): string => {
  return format(zonedDate(date), 'yyyy-MM-dd');
};

const parseDateToString = (date: Date): string => {
  return format(date, 'yyyy-MM-dd');
};

//send to BE
const parseDateToISO = (date: string | Date) => {
  return utcToZonedTime(new Date(date), 'Europe/London').toISOString();
};

const addDays = (date: Date, value: number) => {
  return add(date, { days: value });
};

const utc = (date: string) => {
  return isZoned(date) ? zonedTimeToUtc(date, 'Europe/London') : new Date(date);
};

const isZoned = (date: string) => {
  return date && date.includes('T');
};

export {
  parseISOToZonedDate,
  zonedNow,
  parseDateToISO,
  zonedDate,
  parseDateToString,
  addDays,
  utc,
};
