import { SelectOption } from 'ui';
import { useGetOriginatorInvestorsQuery } from '@/services/accounts/investor';
import { showInvestorName } from '@/utils/onboarding';

export const useOriginatorInvestorOptions = (
  originatorEncodedKey: string | undefined
) => {
  const { data: originatorInvestors = [], isLoading } =
    useGetOriginatorInvestorsQuery(originatorEncodedKey, {
      skip: !originatorEncodedKey,
    });

  const investorOptions: SelectOption[] = originatorInvestors
    .filter((item) => item?.default === false)
    .map((o) => {
      return {
        value: o?._id,
        label: showInvestorName(o?.details),
      };
    });

  return {
    investorOptions,
    isLoading,
  };
};
