import * as React from 'react';
import { Fragment, useState } from 'react';
import { SvgIcon } from 'kennek/icons';
import { isEmpty } from 'lodash';
import { Surface, Title } from 'ui';
import { CashIcon } from '@heroicons/react/outline';
import useGetUserIdentity from '@/hooks/useGetUserIdentity';
import { ProductComponentVisibility } from '@/hooks/useProductComponentConfiguration';
import { useGetConfigurationProductQuery } from '@/services/kennek';
import { useSelectUser } from '@/store/user/selectors';
import { checkDateIsInThePast } from '@/utils/dates';
import { formatAmount } from '@/utils/formatters';
import { valueHasDecimals } from '@/utils/helpers';
import { NextPayment } from './next-payment';
import { Loan } from 'kennek/interfaces/loans';
import { LoanSummary } from '@/interfaces/loans/queries';

type Props = {
  loanSummary: LoanSummary;
  loanNextPaymentConfig: ProductComponentVisibility;
  loan?: Loan;
  loanId?: string;
};

const NextPaymentWidget: React.FC<Props> = ({
  loanSummary,
  loan,
  loanId,
  loanNextPaymentConfig,
}) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const { isBorrower, isOriginator } = useGetUserIdentity();
  const user = useSelectUser();
  const branchEncodedKey =
    user?.mambuUser?.[0]?.mambuBranchEncodedKey ?? loan?.assignedBranchKey;

  const { data: configurationProductData, isFetching } =
    useGetConfigurationProductQuery(
      {
        branchEncodedKey: branchEncodedKey,
        productEncodedKey: loanSummary?.productTypeKey,
      },
      {
        skip: !branchEncodedKey || !loanSummary?.productTypeKey,
      }
    );

  const hasLoanReachedMaturity = checkDateIsInThePast(
    new Date(loanSummary.maturityDate)
  );

  if (
    isEmpty(loanSummary) ||
    !loanSummary.nextPayment ||
    !loanId ||
    isFetching
  ) {
    return <Fragment />;
  }

  const { nextPayment: nextPaymentData } = loanSummary;

  const accruedInterestEnabled =
    configurationProductData?.interest.accruedInterest;

  const showAccruedInterestCard =
    !accruedInterestEnabled &&
    configurationProductData?.payment.rollUp &&
    !configurationProductData?.payment.rollUpDuration &&
    nextPaymentData?.accruedInterest >= 0 &&
    loanNextPaymentConfig?.showAccruedInterest;

  const canShowPaymentFeatures =
    isOriginator || (isBorrower && !!nextPaymentData?.customerId);

  const totalDueAmount = formatAmount(
    nextPaymentData?.totalDueAmount,
    false,
    loanSummary?.currency
  );

  const totalDueAmountDecimals = formatAmount(
    nextPaymentData?.totalDueAmount,
    true,
    loanSummary?.currency
  );

  const nextIntalmentTotalDue = valueHasDecimals(
    nextPaymentData?.totalDueAmount
  )
    ? totalDueAmountDecimals
    : totalDueAmount;

  const widgetTitle = accruedInterestEnabled
    ? 'Interest balance'
    : 'Next Payment';

  const widgetIcon = accruedInterestEnabled ? (
    <SvgIcon
      name="LoansIcon"
      height={24}
      width={24}
      className="-left-6 top-4"
    />
  ) : (
    <CashIcon height={24} width={24} className="-left-6 top-4" />
  );

  return (
    <Surface padding="sm" border="light">
      <NextPayment.ContextWrapper
        loanSummary={loanSummary}
        loanId={loanId}
        productConfig={configurationProductData}
      >
        <div className="w-full bg-white transition-[height]">
          <div className="flex items-center mb-4">
            <Title
              icon={widgetIcon}
              title={widgetTitle}
              insideCard={true}
              className="my-0"
              titleSize="lg"
            />
          </div>

          {accruedInterestEnabled ? (
            <NextPayment.AccruedInterest
              interestBalance={nextPaymentData?.interestBalance}
              currency={loanSummary?.currency}
            />
          ) : (
            <>
              <NextPayment.NextInstallment
                enableDetails={!hasLoanReachedMaturity}
                totalDueAmount={nextIntalmentTotalDue}
                showDetails={showDetails}
                datePrefix="Due on"
                setShowDetails={setShowDetails}
              />
              <NextPayment.NextInstallments showDetails={showDetails} />
              <NextPayment.ProcessingPaymentBanner />
            </>
          )}

          {canShowPaymentFeatures && (
            <>
              {!configurationProductData?.interest.accruedInterest && (
                <NextPayment.DirectDebitStateText />
              )}

              {!loanSummary?.locks?.payments && (
                <NextPayment.PaymentButton
                  loanId={loanId}
                  setShowPaymentModal={setShowPaymentModal}
                />
              )}
            </>
          )}

          {showAccruedInterestCard && (
            <div className="border-t border-neutral-300 mt-4 pt-4">
              <Title
                title="Interest Balance"
                insideCard={true}
                titleSize="sm"
                className="mb-4 weight-500"
              />
              <NextPayment.AccruedInterest
                interestBalance={nextPaymentData?.accruedInterest}
                currency={loanSummary?.currency}
              />
            </div>
          )}

          {accruedInterestEnabled && (
            <div className="border-t border-neutral-300 mt-4 pt-4">
              <Title
                title="Next due date"
                insideCard={true}
                titleSize="sm"
                className="mb-4 weight-500"
              />
              <NextPayment.NextInstallment
                enableDetails={!hasLoanReachedMaturity}
                totalDueAmount={totalDueAmount}
                showDetails={showDetails}
                datePrefix="On"
                setShowDetails={setShowDetails}
              />
              <NextPayment.NextInstallments showDetails={showDetails} />
              <NextPayment.ProcessingPaymentBanner />
              {canShowPaymentFeatures && <NextPayment.DirectDebitStateText />}
            </div>
          )}
        </div>
        <NextPayment.MakePaymentModal
          showPaymentModal={showPaymentModal}
          setShowPaymentModal={setShowPaymentModal}
          loan={loan}
          totalDueAmount={totalDueAmountDecimals}
        />
      </NextPayment.ContextWrapper>
    </Surface>
  );
};

export { NextPaymentWidget };
