import { Dispatch, SetStateAction } from 'react';
import { Row } from 'react-table';
import classNames from 'classnames';
import { Breadcrumbs } from 'ui';
import { v4 as uuid } from 'uuid';
import { DMTableRow } from '@/hooks/useBrowseDocuments';
import { DownloadFileSettingDocumentsManagement } from '@/hooks/useHandleDocumentManagementFiles';
import {
  DocumentManagementRowType,
  PathData,
} from '@/utils/documentManagement';
import { formatDate } from '@/utils/formatters';
import DocumentManagementRowIcon from './components/DocumentManagementRowIcon';
import { UserTypes } from 'kennek/interfaces/accounts';
import { CellProps, DropDownTableItem } from 'ui/types';

export const filterDropDownItems = (
  items: DropDownTableItem[],
  row: Row<any>
) => {
  const rowType = row.original.rowType;
  return items.filter(({ types }) => types.includes(rowType));
};

export const getNewPath = (currentPath: PathData[], id: string) => {
  let isClickedPath = false;
  return currentPath
    .map((pathItem) => {
      if (isClickedPath) return null;
      if (pathItem.id === id) isClickedPath = true;
      return pathItem;
    })
    .filter((item) => item !== null);
};

export type DocumentNameKey = 'draftName' | 'name';

export const getDocumentNameKey = (
  isEditMode: boolean,
  editMode = true
): DocumentNameKey => {
  return isEditMode && editMode ? 'draftName' : 'name';
};

export const getDocumentNameValue = (
  isEditLoan: boolean,
  name?: string,
  draftName?: string
): string => {
  if (!name && !draftName) return 'Anonymous';
  return isEditLoan ? draftName || name : name;
};

interface TableHelpers {
  goToPath: (id: string, label: string, originalName: string) => void;
  clearSearch: () => void;
  setCurrentPath: Dispatch<SetStateAction<PathData[]>>;
  basePath: PathData;
}

export const getTableColumns = (
  tableFunction: TableHelpers,
  searchIsOn: boolean,
  narrow: boolean,
  isEditLoan: boolean
) => {
  const { goToPath, clearSearch, setCurrentPath, basePath } = tableFunction;
  if (searchIsOn) {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 350,
        Cell: ({
          value,
          row,
        }: {
          value: string;
          row: CellProps<DMTableRow>;
        }) => {
          const rowType = row?.original?.rowType as DocumentManagementRowType;
          const currentValue = getDocumentNameValue(
            isEditLoan,
            value,
            row?.original?.draftName
          );

          const isFolder =
            rowType === DocumentManagementRowType.FOLDER ||
            rowType === DocumentManagementRowType.USER_FOLDER;
          const pathList = row?.original?.pathSplitted;

          const path = [
            basePath,
            ...pathList.map((x: string) => ({
              id: uuid(),
              label: x,
              originalName: x,
            })),
          ];

          return (
            <div className="flex items-center">
              <Breadcrumbs
                items={path}
                onClick={(_, list) => {
                  clearSearch();
                  setCurrentPath(list);
                }}
                className="flex-wrap"
                addEndArrow
              />
              <div
                className={classNames('flex items-center', {
                  'cursor-pointer': isFolder,
                })}
                onClick={() => {
                  if (isFolder) {
                    clearSearch();
                    setCurrentPath([
                      ...path,
                      {
                        id: row?.original?.id,
                        label: currentValue,
                        originalName: value,
                      },
                    ]);
                  }
                }}
              >
                <DocumentManagementRowIcon rowType={rowType} />
                {currentValue}
              </div>
            </div>
          );
        },
      },
    ];
  }

  return [
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: narrow ? 230 : 350,
      maxWidth: narrow ? 230 : 350,
      Cell: ({ value, row }: { value: string; row: CellProps<DMTableRow> }) => {
        const rowType = row?.original?.rowType as DocumentManagementRowType;
        const isFolder = allFolderType.includes(rowType);
        const currentValue = getDocumentNameValue(
          isEditLoan,
          value,
          row?.original?.draftName
        );
        return (
          <div
            className={classNames('flex items-center', {
              'cursor-pointer': isFolder,
              'line-through':
                row?.original?.documentData?.isDraftDeleted && isEditLoan,
            })}
            onClick={() => {
              if (isFolder) goToPath(row?.original?.id, currentValue, value);
            }}
          >
            <DocumentManagementRowIcon rowType={rowType} />
            {currentValue}
          </div>
        );
      },
    },
    {
      Header: 'Access',
      accessor: 'access',
      maxWidth: 100,
      minWidth: 100,
      Cell: ({ value, row }: { value: string; row: CellProps<DMTableRow> }) => (
        <div
          className={classNames({
            'line-through':
              row?.original?.documentData?.isDraftDeleted && isEditLoan,
          })}
        >
          {value}
        </div>
      ),
    },
    {
      Header: 'Date added',
      accessor: 'dateAdded',
      maxWidth: 100,
      minWidth: 100,
      Cell: ({ value, row }: { value: string; row: CellProps<DMTableRow> }) => (
        <div
          className={classNames({
            'line-through':
              row?.original?.documentData?.isDraftDeleted && isEditLoan,
          })}
        >
          {formatDate(value) || ''}
        </div>
      ),
    },
    {
      Header: 'Last modified',
      accessor: 'lastModified',
      maxWidth: 100,
      minWidth: 100,
      Cell: ({ value, row }: { value: string; row: CellProps<DMTableRow> }) => (
        <div
          className={classNames({
            'line-through':
              row?.original?.documentData?.isDraftDeleted && isEditLoan,
          })}
        >
          {formatDate(value) || ''}
        </div>
      ),
    },
  ];
};

interface ActionHandlers {
  handleOpen: (row: DMTableRow) => void;
  handleRename: (row: DMTableRow) => void;
  handleDeleteRowAction: (row: DMTableRow) => void;
  handleMenageAccess: (row: DMTableRow) => void;
  restoreDocument: (row: DMTableRow) => void;
  downloadFile: (
    downloadId: string,
    settings: DownloadFileSettingDocumentsManagement
  ) => void;
}

export const getDropDownTableItems = (
  handlers: ActionHandlers,
  role: UserTypes,
  readOnly: boolean,
  isEditLoan: boolean
) => {
  const {
    handleOpen,
    handleRename,
    handleDeleteRowAction,
    handleMenageAccess,
    restoreDocument,
    downloadFile,
  } = handlers;
  const items = [
    {
      text: 'Open',
      onClickHandler: handleOpen,
      types: [...allFolderType],
    },
    {
      text: 'Rename',
      onClickHandler: handleRename,
      permission: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN'],
      types: readOnly
        ? []
        : [
            DocumentManagementRowType.USER_FOLDER,
            DocumentManagementRowType.USER_FOLDER_EDIT_CORE,
            DocumentManagementRowType.USER_FOLDER_EDIT,
            DocumentManagementRowType.FILE,
            DocumentManagementRowType.FILE_EDIT,
            DocumentManagementRowType.FILE_EDIT_CORE,
          ],
    },
    {
      text: 'Delete',
      onClickHandler: handleDeleteRowAction,
      permission: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN'],
      types: readOnly
        ? []
        : [
            DocumentManagementRowType.USER_FOLDER,
            DocumentManagementRowType.USER_FOLDER_EDIT_CORE,
            DocumentManagementRowType.USER_FOLDER_EDIT,
            DocumentManagementRowType.FILE,
            DocumentManagementRowType.FILE_EDIT,
            DocumentManagementRowType.FILE_EDIT_CORE,
          ],
    },
    {
      text: 'Manage access',
      onClickHandler: handleMenageAccess,
      permission: ['ORIGINATOR_ANALYST', 'ORIGINATOR_ADMIN'],
      types:
        readOnly || isEditLoan
          ? []
          : [
              DocumentManagementRowType.USER_FOLDER,
              DocumentManagementRowType.FOLDER,
            ],
    },
    {
      text: 'Download',
      onClickHandler: ({ downloadId, name }: DMTableRow) =>
        downloadFile(downloadId, { fileName: name }),
      types: [
        DocumentManagementRowType.FILE,
        DocumentManagementRowType.FILE_EDIT,
        DocumentManagementRowType.FILE_EDIT_CORE,
      ],
    },
    {
      text: 'Restore',
      onClickHandler: restoreDocument,
      types: readOnly
        ? []
        : [
            DocumentManagementRowType.FILE_DRAFT_DELETE,
            DocumentManagementRowType.USER_FOLDER_DRAFT_DELETE,
          ],
    },
  ];
  return items.filter(
    ({ permission }) => !permission || permission.includes(role)
  );
};

export const allFileType = [
  DocumentManagementRowType.FILE,
  DocumentManagementRowType.FILE_EDIT,
  DocumentManagementRowType.FILE_EDIT_CORE,
  DocumentManagementRowType.FILE_DRAFT_DELETE,
];
export const allFolderType = [
  DocumentManagementRowType.FOLDER,
  DocumentManagementRowType.USER_FOLDER,
  DocumentManagementRowType.USER_FOLDER_EDIT,
  DocumentManagementRowType.USER_FOLDER_DRAFT_DELETE,
  DocumentManagementRowType.USER_FOLDER_EDIT_CORE,
];

export const leaseEstateOrderedFolderNames = [
  'Algemene vragen over leasing',
  'Financiële en overige informatie over het bedrijf',
  'Vastgoedinformatie',
  'Overeenkomsten en notariële aktes',
  'Diverse',
  'Financieringsinformatie',
];
