import { rootApi } from './rootApi';
import {
  BrandData,
  CustomInformationPageIds,
  LabelConfigurationListData,
  LabelConfigurationPayload,
  LegalDocumentSettings,
  OptionalFeatures,
  ProductComponent,
  ProductComponentResponse,
  ProductFeatures,
  RiskAnalysisData,
} from 'kennek/interfaces/kennek';
import {
  CreateConfigurationQuery,
  GetConfigurationProductResponse,
  SaveViewConfiguration,
  SaveViewConfigurationV2,
  TableViewConfigurationQuery,
  TableViewConfigurationResult,
} from 'kennek/interfaces/kennek/queries';
import { ModulrData, ModulrSettings } from '@/interfaces/modulr';

const baseKennekApi = '/api/kennek';

export const kennekApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getDisabledFeatures: builder.query<
      OptionalFeatures[],
      { branchEncodedKey: string }
    >({
      query: (params) => ({
        url: baseKennekApi + '/configuration/features/disabled',
        method: 'GET',
        params: {
          branchEncodedKey: params.branchEncodedKey,
        },
      }),
    }),
    getBrand: builder.query<
      BrandData | undefined,
      { branchEncodedKey: string }
    >({
      query: (params) => ({
        url: baseKennekApi + '/configuration/brand',
        method: 'GET',
        params: {
          branchEncodedKey: params.branchEncodedKey,
        },
      }),
    }),
    getBrandByDomain: builder.query<
      BrandData | undefined,
      { domainName: string }
    >({
      query: (params) => ({
        url: baseKennekApi + '/configuration/brand/domain',
        method: 'GET',
        params: {
          domainName: params.domainName,
        },
      }),
    }),
    getConfigurationProduct: builder.query<
      GetConfigurationProductResponse,
      { branchEncodedKey: string; productEncodedKey: string }
    >({
      query: (params) => ({
        url: baseKennekApi + '/configuration/product',
        method: 'GET',
        params: {
          branchEncodedKey: params.branchEncodedKey,
          productEncodedKey: params.productEncodedKey,
        },
      }),
    }),
    getViewColumnsConfiguration: builder.query<
      TableViewConfigurationResult,
      TableViewConfigurationQuery
    >({
      query: ({
        userId,
        viewName,
        branchEncodedKey,
        productEncodedKey,
        loanEncodedKey,
      }) => ({
        url: baseKennekApi + '/configuration/view',
        params: {
          userId,
          viewName,
          branchEncodedKey,
          productEncodedKey,
          loanEncodedKey,
        },
      }),
      providesTags: ['Columns'],
    }),
    createViewConfiguration: builder.mutation({
      query: (body: SaveViewConfiguration) => ({
        url: baseKennekApi + '/configuration/view',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Columns', 'RepaymentSchedule'],
    }),
    saveViewConfiguration: builder.mutation({
      query: (body: SaveViewConfiguration) => ({
        url: baseKennekApi + '/configuration/view',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Columns', 'RepaymentSchedule'],
    }),
    saveViewConfigurationV2: builder.mutation({
      query: (body: SaveViewConfigurationV2) => ({
        url: baseKennekApi + '/configuration/view',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Columns', 'RepaymentSchedule'],
    }),
    createConfiguration: builder.mutation({
      query: (body: CreateConfigurationQuery) => ({
        url: baseKennekApi + '/configuration',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Columns', 'RepaymentSchedule'],
    }),
    getCodatKey: builder.query<RiskAnalysisData, { branchEncodedKey: string }>({
      query: ({ branchEncodedKey }) => ({
        url: `${baseKennekApi}/configuration/risk-analysis`,
        method: 'GET',
        params: {
          branchEncodedKey: branchEncodedKey,
        },
      }),
    }),
    getModulrData: builder.mutation<ModulrData, { branchEncodedKey: string }>({
      query: ({ branchEncodedKey }) => ({
        url: `${baseKennekApi}/configuration/modulr`,
        method: 'GET',
        params: {
          branchEncodedKey: branchEncodedKey,
        },
      }),
    }),
    getModulrSettings: builder.query<ModulrSettings, void>({
      query: () => ({
        url: `${baseKennekApi}/configuration/modulr/settings`,
        method: 'GET',
        keepUnusedDataFor: 60 * 60 * 24,
      }),
    }),
    sendModulrData: builder.mutation<
      ModulrData,
      { branchEncodedKey: string; body: ModulrData }
    >({
      query: ({ body }) => {
        return {
          url: `${baseKennekApi}/configuration/modulr`,
          method: 'PUT',
          body,
        };
      },
    }),
    deleteModulrData: builder.mutation<
      ModulrData,
      { branchEncodedKey: string }
    >({
      query: ({ branchEncodedKey }) => {
        return {
          url: `${baseKennekApi}/configuration/modulr`,
          method: 'DELETE',
          params: {
            branchEncodedKey: branchEncodedKey,
          },
        };
      },
    }),
    getLegalDocuments: builder.query<
      LegalDocumentSettings[] | undefined,
      { email?: string; branchEncodedKey?: string; role: string }
    >({
      query: (params) => ({
        url: baseKennekApi + '/configuration/legal-documents/public',
        method: 'GET',
        params: createLegalDocumentsParams(
          params.role,
          params.branchEncodedKey,
          params.email
        ),
      }),
    }),
    getLoanDisabledFeatures: builder.query<
      ProductFeatures[],
      { loanId: string }
    >({
      query: (params) => ({
        url:
          baseKennekApi +
          '/configuration/product-features-configuration/' +
          params.loanId,
        method: 'GET',
      }),
    }),
    getProductComponentConfiguration: builder.query<
      ProductComponentResponse,
      {
        branchEncodedKey: string;
        component: ProductComponent;
        productEncodedKey: string;
      }
    >({
      query: (params) => ({
        url: baseKennekApi + '/configuration/product-components-configuration',
        method: 'GET',
        params,
      }),
    }),
    getLoanOnboardingCustomInformationPageId: builder.query<
      { pageId: CustomInformationPageIds },
      { branchEncodedKey: string }
    >({
      query: (params) => ({
        url:
          baseKennekApi +
          '/configuration/features/loan-onboarding-custom-information-page-id',
        method: 'GET',
        params: {
          branchEncodedKey: params.branchEncodedKey,
        },
      }),
    }),
    getLabelConfig: builder.query<
      LabelConfigurationListData[],
      { branchEncodedKey: string }
    >({
      query: (params) => ({
        url: `${baseKennekApi}/configuration/label`,
        method: 'GET',
        params: {
          branchEncodedKey: params.branchEncodedKey,
        },
      }),
      providesTags: ['Originator'],
    }),
    updateLabelConfig: builder.mutation({
      query: (body: LabelConfigurationPayload) => ({
        url: `${baseKennekApi}/configuration/label`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Originator'],
    }),
  }),
});

export const {
  useGetDisabledFeaturesQuery,
  useGetConfigurationProductQuery,
  useGetViewColumnsConfigurationQuery,
  useSaveViewConfigurationMutation,
  useSaveViewConfigurationV2Mutation,
  useCreateViewConfigurationMutation,
  useCreateConfigurationMutation,
  useGetBrandQuery,
  useGetBrandByDomainQuery,
  useGetCodatKeyQuery,
  useGetModulrSettingsQuery,
  useGetModulrDataMutation,
  useSendModulrDataMutation,
  useDeleteModulrDataMutation,
  useGetLegalDocumentsQuery,
  useGetLoanDisabledFeaturesQuery,
  useGetProductComponentConfigurationQuery,
  useGetLoanOnboardingCustomInformationPageIdQuery,
  useGetLabelConfigQuery,
  useUpdateLabelConfigMutation,
} = kennekApi;

export const {
  useQuerySubscription: useGetViewColumnsConfigurationQuerySubscription,
} = kennekApi.endpoints.getViewColumnsConfiguration;

export const { useQueryState: useGetBrandQueryState } =
  kennekApi.endpoints.getBrand;

const createLegalDocumentsParams = (
  role: string,
  branchEncodedKey?: string,
  email?: string
): { branchEncodedKey?: string; email?: string; role: string } => {
  if (branchEncodedKey) {
    return {
      branchEncodedKey,
      role,
    };
  }

  return {
    email,
    role,
  };
};
