import { CustomFieldsVisibility } from '@/features/onboarding/custom-information/utils';

export interface LeaseEstateCustomInfoForm {
  residualValue: number;
  higherUpfrontPayment: number;
  progressivePaymentSchedule: number;
  tvaOrNot: boolean;
  comment?: string;
  estimatedWorth?: number;
  dateEstimated?: string;
  estimatedBy?: string;
  freeText?: string;
  dateOfConstruction?: string;
  dateOfRenovation?: string;
  surfaceOfPlot?: number;
  builtSurface?: number;
}

export const leaseEstateFieldsVisibility: CustomFieldsVisibility[] = [
  {
    key: 'residualValue',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'higherUpfrontPayment',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'progressivePaymentSchedule',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'tvaOrNot',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'comment',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'estimatedWorth',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'dateEstimated',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'estimatedBy',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'freeText',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'dateOfConstruction',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'dateOfRenovation',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'surfaceOfPlot',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'builtSurface',
    visibility: ['ORIGINATOR_ADMIN'],
  },
];

export const emptyLeaseEstateCustomInformation: LeaseEstateCustomInfoForm = {
  residualValue: null,
  higherUpfrontPayment: null,
  progressivePaymentSchedule: null,
  tvaOrNot: false,
  comment: '',

  estimatedWorth: null,
  dateEstimated: '',
  estimatedBy: '',
  freeText: '',
  dateOfConstruction: '',
  dateOfRenovation: '',
  surfaceOfPlot: null,
  builtSurface: null,
};

export const leaseEstateLabelUnits_EUR: { [key: string]: string } = {
  progressivePaymentSchedule: '[%]',
  surfaceOfPlot: '[m²]',
  builtSurface: '[m²]',
  estimatedWorth: '[€]',
  residualValue: '[€]',
  higherUpfrontPayment: '[€]',
};

export const leaseEstateCustomInformationOrdered_EUR = [
  'Estimated worth [€]',
  'Date estimated',
  'Estimated by',
  'Free text',
  'Date of construction',
  'Date of renovation',
  'Surface of plot [m²]',
  'Built surface [m²]',
  'Residual value [€]',
  'Higher upfront payment [€]',
  'Progressive payment schedule [%]',
  'Tva or not',
  'Comment',
];

export const leaseEstateLabelUnits_GBP: { [key: string]: string } = {
  progressivePaymentSchedule: '[%]',
  surfaceOfPlot: '[m²]',
  builtSurface: '[m²]',
  estimatedWorth: '[£]',
  residualValue: '[£]',
  higherUpfrontPayment: '[£]',
};

export const leaseEstateCustomInformationOrdered_GBP = [
  'Estimated worth [£]',
  'Date estimated',
  'Estimated by',
  'Free text',
  'Date of construction',
  'Date of renovation',
  'Surface of plot [m²]',
  'Built surface [m²]',
  'Residual value [£]',
  'Higher upfront payment [£]',
  'Progressive payment schedule [%]',
  'Tva or not',
  'Comment',
];
