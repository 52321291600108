import React from 'react';
import classNames from 'classnames';

interface EmptyStateProps {
  className?: string;
  children: React.ReactNode;
}

const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { children, className } = props;
  return (
    <div
      className={classNames(
        'p-8 min-h-[200px] flex justify-center items-center w-full',
        className
      )}
    >
      <span className="text-neutral-500 heading-500">{children}</span>
    </div>
  );
};

export default EmptyState;
