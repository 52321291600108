import React from 'react';
import BanknotesIcon from 'kennek/icons/svg/BanknotesIcon';
import { arrayNotEmpty } from 'kennek/utils/validations';
import { Dialog } from 'ui';
import { Loading } from 'ui/components/Dialog/DialogLoading';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import {
  ADD_FUNDING_METHOD_TEXT,
  FUNDING_METHODS_LIST_HEADING,
  NO_FUNDING_METHODS_TEXT,
} from './InvestmentFundingMethod.constants';
import { InvestmentFundingMethod } from './InvestmentFundingMethod.types';
import { getInvestmentFundingMethodAfterSelectChange } from './InvestmentFundingMethod.utils';

type InvestmentFundingMethodListDialogProps = {
  investmentFundingMethods: InvestmentFundingMethod[];
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onSelectClicked: (fundingMethod: InvestmentFundingMethod) => void;
  onDeleteClicked: (fundingMethod: InvestmentFundingMethod) => void;
  onEditClicked: (fundingMethod: InvestmentFundingMethod) => void;
  onAddNewClicked: () => void;
};

export const InvestmentFundingMethodListDialog: React.FC<
  InvestmentFundingMethodListDialogProps
> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.onClose()}
      dialogIconColor="success"
      dialogIcon={<BanknotesIcon />}
      dialogIconTitle={FUNDING_METHODS_LIST_HEADING}
    >
      {props.isLoading && (
        <div className="absolute w-full h-full z-10">
          <Loading />
        </div>
      )}
      <ul className="list-none max-h-[400px] overflow-y-auto">
        {arrayNotEmpty(props.investmentFundingMethods) ? (
          props.investmentFundingMethods.map((element) => (
            <li
              key={element.id}
              className="border-b border-b-neutral-400 w-[336px] flex justify-between items-center group py-2"
            >
              <button
                data-testid={`select-funding-method-button-${element.id}`}
                className="flex-1 py-2 cursor-pointer px-[13px] hover:bg-neutral-200 rounded-full transition-colors text-left"
                onClick={() => {
                  props.onSelectClicked(
                    getInvestmentFundingMethodAfterSelectChange(
                      element.id,
                      props.investmentFundingMethods
                    )
                  );
                }}
              >
                {element.name}
              </button>
              {!element.isPredefined && (
                <div className="flex ml-2 gap-4 opacity-0 group-hover:opacity-100 transition-opacity">
                  <button
                    data-testid={`delete-funding-method-button-${element.id}`}
                    aria-label="Delete"
                    className="w-4 text-darkBlue cursor-pointer"
                    onClick={() => props.onDeleteClicked(element)}
                  >
                    <TrashIcon />
                  </button>
                  <button
                    data-testid={`edit-funding-method-button-${element.id}`}
                    aria-label="Edit"
                    className="w-4 text-darkBlue cursor-pointer"
                    onClick={() => props.onEditClicked(element)}
                  >
                    <PencilAltIcon />
                  </button>
                </div>
              )}
            </li>
          ))
        ) : (
          <p className="border-b border-b-neutral-400 w-[336px] px-[13px] py-4 italic">
            {NO_FUNDING_METHODS_TEXT}
          </p>
        )}
      </ul>
      <button
        data-testid="add-funding-method-button"
        className="text-darkBlue self-start text-left py-4 px-3"
        onClick={() => props.onAddNewClicked()}
      >
        {ADD_FUNDING_METHOD_TEXT}
      </button>
    </Dialog>
  );
};
