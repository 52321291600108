import React from 'react';
import { IconProps } from '../iconTypes';

const LenderOfRecordIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M2 9C2 7.34315 3.34315 6 5 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H6.46676C6.39681 8.60711 6.12915 9.19624 5.66269 9.6627C5.19624 10.1292 4.60711 10.3968 4 10.4668V13.6663C4.60711 13.7362 5.19624 14.0039 5.66269 14.4704C6.09499 14.9027 6.35654 15.4403 6.44821 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H5C3.34315 18 2 16.6569 2 15V9ZM11.2089 11.2132C10.7255 11.427 10.5069 11.9921 10.7207 12.4755C10.9344 12.9589 11.4996 13.1775 11.983 12.9637C12.4881 12.7404 13.0786 12.9688 13.302 13.4739C13.5253 13.979 13.2969 14.5695 12.7918 14.7929C11.2982 15.4534 9.552 14.778 8.89153 13.2843C8.23107 11.7907 8.90646 10.0445 10.4001 9.38406C11.1385 9.05753 11.9408 9.05805 12.6398 9.32182C13.1565 9.51679 13.4173 10.0937 13.2224 10.6105C13.0274 11.1272 12.4504 11.388 11.9337 11.193C11.7057 11.107 11.4483 11.1073 11.2089 11.2132ZM15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13H18.5858L17.2929 14.2929C16.9024 14.6834 16.9024 15.3166 17.2929 15.7071C17.6834 16.0976 18.3166 16.0976 18.7071 15.7071L21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929L18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289C16.9024 8.68342 16.9024 9.31658 17.2929 9.70711L18.5858 11H15Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default LenderOfRecordIcon;
