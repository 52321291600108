import React from 'react';
import { Button } from 'ui';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { formatAmount } from '@/utils/formatters';
import { Currencies } from 'kennek/interfaces/loans';

export interface SkipInvestorWarningModalProps {
  type: 'skip' | 'partialAllocation';
  nonAllocatedAmount: number;
  onClose: () => void;
  onConfirm: () => void;
  currency: Currencies;
}

const SkipInvestorWarningModal = ({
  type,
  nonAllocatedAmount,
  onConfirm,
  onClose,
  currency,
}: SkipInvestorWarningModalProps) => {
  const amount = formatAmount(nonAllocatedAmount ?? 0, true, currency);
  const { loanLower } = useGetLabelsConfig();
  const skipText = `You have not allocated this ${loanLower} to an investor.
   ${amount} will be allocated to your organisation.`;
  const incompleteAllocationText = `${amount} is not allocated to any investor. This amount will be allocated to your organisation.`;

  return (
    <div className="flex flex-col w-96 mt-2">
      <div className="heading-300 whitespace-pre-line">
        {type === 'skip' ? skipText : incompleteAllocationText}
      </div>
      <div className="flex justify-around gap-2 mt-4">
        <Button layout="ghost" onClick={onClose}>
          Edit allocation
        </Button>
        <Button layout="ghost" onClick={onConfirm}>
          I agree
        </Button>
      </div>
    </div>
  );
};

export { SkipInvestorWarningModal };
