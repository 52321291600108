import CurrencyInput from 'react-currency-input-field';
import { Cell } from 'react-table';
import classNames from 'classnames';
import { currencySymbol } from '../FormInput';
import Input from '../Input';
import { Currencies } from 'kennek/interfaces/loans';

export interface EditableCell {
  onValueChangeHandler?: (rowId: number, newValue: string) => void;
  name: string;
  type?: string;
  currency?: {
    symbol: Currencies;
    allowNegativeValue: boolean;
  };
  canEdit?: (rowId: number) => boolean;
}

export type EditCellProps = {
  cell: Cell<{}, any>;
  editableCell: EditableCell;
  isLoading: boolean;
  index: number;
  disabled: boolean;
};

export const EditCell = ({
  cell,
  editableCell,
  isLoading,
  index,
  disabled,
}: EditCellProps) => {
  const sharedClasses = [
    'mr-2',
    `min-w-[${cell.column.minWidth}px]`,
    `max-w-[${cell.column.maxWidth}px]`,
  ];

  return editableCell.currency ? (
    <div className="relative">
      <CurrencyInput
        allowNegativeValue={editableCell.currency.allowNegativeValue ?? false}
        decimalSeparator="."
        groupSeparator=","
        decimalScale={2}
        decimalsLimit={2}
        disabled={isLoading || disabled}
        name={`${cell.column?.id}-${index}`}
        id={`${cell.column?.id}-${index}`}
        defaultValue={cell.value}
        onValueChange={(value) => {
          if (editableCell.onValueChangeHandler)
            editableCell.onValueChangeHandler(
              cell?.row?.index ?? 0,
              value ?? ''
            );
        }}
        className={classNames(
          'body-400 rounded-inputs border border-neutral-400 focus:outline-none focus:ring-0 focus:border-neutral-400 focus:outline-primary-500 outline-offset-2 outline-1 h-10 mt-[5px]',
          {
            'pl-10': editableCell.currency.symbol,
            'cursor-not-allowed bg-neutral-200 border-neutral-400':
              isLoading || disabled,
          },
          sharedClasses
        )}
        step={1}
      />

      {editableCell.currency.symbol && (
        <span className="absolute bottom-3 left-3 heading-100 text-neutral-900">
          {currencySymbol[editableCell.currency.symbol]}
        </span>
      )}
    </div>
  ) : (
    <Input
      name={`${cell.column?.id}-${index}`}
      id={`${cell.column?.id}-${index}`}
      defaultValue={cell.value}
      className={classNames(`w-[${cell.column.width}px]`, sharedClasses)}
      onChange={(event) => {
        if (editableCell.onValueChangeHandler)
          editableCell.onValueChangeHandler(
            cell?.row?.index ?? 0,
            event?.target?.value
          );
      }}
      disabled={isLoading || disabled}
      type={editableCell.type}
    />
  );
};
