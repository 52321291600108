import React from 'react';
import { Button, Dialog } from 'ui';
import { ExclamationIcon } from '@heroicons/react/outline';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';

interface DirectDebitEditLoanAlertProps {
  onContinue: () => void;
  onClose: () => void;
  open: boolean;
}

const DirectDebitEditLoanAlert = ({
  onContinue,
  onClose,
  open,
}: DirectDebitEditLoanAlertProps) => {
  const { loanLower } = useGetLabelsConfig();
  const alertMessage = `By restructuring the ${loanLower}, the existing debit schedule will be modified.\n
  If there are less than 3 business days until next payment the debit cannot be stopped.`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      dialogIcon={<ExclamationIcon />}
      dialogIconColor="destructive"
    >
      <div className="flex flex-col w-96 mt-2">
        <div className="heading-300 whitespace-pre-line">{alertMessage}</div>
        <div className="flex justify-around gap-2 mt-4">
          <Button layout="ghost" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button layout="ghost" onClick={onContinue}>
            Yes, I agree
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { DirectDebitEditLoanAlert };
