import * as yup from 'yup';
import { LoanInvestment } from '@/interfaces/loans/queries';

export const sortInvestments = (investments: LoanInvestment[] = []) => {
  return investments.slice().sort((a, b) => {
    return a.investor.default === b.investor.default
      ? 0
      : a.investor.default
        ? 1
        : -1;
  });
};

export const investmentsEffectiveDateFormSchema = yup
  .object({
    effectiveDate: yup
      .date()
      .nullable()
      .transform((v) => (v instanceof Date && !isNaN(+v) ? v : null))
      .test('is-required', 'Effective date cannot be empty', (value) => {
        return (
          !!value &&
          !!new Date(value).getFullYear() &&
          new Date(value).getMonth() !== undefined &&
          !!new Date(value).getDate()
        );
      }),
  })
  .required();
