import React, { useEffect } from 'react';
import { colorDefault } from 'ui/theme/defaultColors';
import { XIcon } from '@heroicons/react/outline';
import type { Severity } from '../../types';

export interface SnackBarProps {
  duration?: number;
  onClose: () => void;
  severity?: Severity;
  children: React.ReactNode;
}

const SnackBar: React.FC<SnackBarProps> = (props) => {
  const { children, duration, onClose, severity = 'primary' } = props;

  useEffect(() => {
    let timeoutRef: ReturnType<typeof setTimeout>;
    if (duration !== 0) {
      timeoutRef = setTimeout(() => {
        onClose();
      }, duration || 6000);
    }

    return () => {
      clearTimeout(timeoutRef);
    };
  });

  return (
    <div className="relative flex py-4 w-96 bg-white shadow-snackbar rounded-lg z-[110] ml-3 mt-2">
      <div
        className="w-2 rounded-r-2sm"
        style={{ backgroundColor: severities[severity] }}
      />
      <div className="px-5">{children}</div>

      <XIcon
        className=" text-neutral-700 w-4 h-4 absolute top-2 right-2 z-[110] hover:text-neutral-900 cursor-pointer"
        onClick={onClose}
        aria-label="snackbar-button"
      />
    </div>
  );
};

// Severities colors are hardcoded because customize colors shouldn't affect the snackbar bg color
const severities: Record<Severity, string> = {
  primary: colorDefault.primary[500],
  secondary: colorDefault.secondary[500],
  info: colorDefault.info[500],
  success: colorDefault.primary[500],
  warning: colorDefault.warning[500],
  error: colorDefault.error[500],
  neutral: colorDefault.neutral[500],
};

export default SnackBar;
