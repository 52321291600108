import React from 'react';
import { IconProps } from '../iconTypes';

const CodatWarningIcon: React.FC<IconProps> = (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="48" height="48" rx="24" fill="#FEEEEE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9085 15.7185C22.826 14.0873 25.1745 14.0873 26.092 15.7185L32.7884 27.6231C33.6883 29.223 32.5322 31.1998 30.6966 31.1998H17.3039C15.4683 31.1998 14.3122 29.223 15.2121 27.6231L21.9085 15.7185ZM25.2001 27.5999C25.2001 28.2626 24.6629 28.7999 24.0001 28.7999C23.3374 28.7999 22.8001 28.2626 22.8001 27.5999C22.8001 26.9372 23.3374 26.3999 24.0001 26.3999C24.6629 26.3999 25.2001 26.9372 25.2001 27.5999ZM24.0001 17.9999C23.3374 17.9999 22.8001 18.5372 22.8001 19.1999V22.7999C22.8001 23.4626 23.3374 23.9999 24.0001 23.9999C24.6629 23.9999 25.2001 23.4626 25.2001 22.7999V19.1999C25.2001 18.5372 24.6629 17.9999 24.0001 17.9999Z"
      fill="#F15656"
    />
  </svg>
);

export default CodatWarningIcon;
