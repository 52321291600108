import React, { useContext } from 'react';
import { RootContext } from './Root';

type ContentProps<T extends string> = {
  id: T;
  children: React.ReactNode;
};

const Content = <T extends string>({ children, id }: ContentProps<T>) => {
  const { control } = useContext(RootContext);

  if (control.activeStepId === id) return children;

  return null;
};

export { Content };

export type { ContentProps };
