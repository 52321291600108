import { useEffect, useMemo, useState } from 'react';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useGetInvestmentFundingMethodsFeesSettingsQuery,
  useGetInvestmentFundingMethodsQuery,
} from '@/services/loans/investment-funding-methods';
import { isEmpty, isNotEmpty } from '@/utils/validation';
import { InvestmentFundingMethod } from '../InvestmentFundingMethod.types';
import {
  FundingMethodSnackbarId,
  combineCustomFundingMethodsWithPredefined,
  displayInvestmentFundingMethodsSnackbar,
  getCustomFundingMethodBasedOnLoan,
  getDefaultInvestmentFundingMethod,
} from '../InvestmentFundingMethod.utils';
import { Loan } from '@/interfaces/loans';

type UseInvestmentFundingMethodStateParams = {
  loan: Loan;
  loanHasFees: boolean;
};

export const useInvestmentFundingMethodState = (
  params: UseInvestmentFundingMethodStateParams
) => {
  const labelsConfig = useGetLabelsConfig();
  const snackbar = useSnackbar();

  const defaultInvestmentFundingMethod = useMemo(
    () => getDefaultInvestmentFundingMethod(labelsConfig.loanUpper),
    [labelsConfig]
  );

  const [selectedInvestmentFundingMethod, setSelectedInvestmentFundingMethod] =
    useState<InvestmentFundingMethod>(null);

  const [
    investmentFundingMethodListDialogOpen,
    setInvestmentFundingMethodListDialogOpen,
  ] = useState(false);

  const [
    investmentFundingMethodCreateFormDialogOpen,
    setInvestmentFundingMethodCreateFormDialogOpen,
  ] = useState(false);

  const getInvestmentFundingMethodsQuery = useGetInvestmentFundingMethodsQuery(
    { branchEncodedKey: params.loan?.assignedBranchKey },
    { skip: !params.loan }
  );

  if (getInvestmentFundingMethodsQuery.isError) {
    displayInvestmentFundingMethodsSnackbar(
      snackbar,
      FundingMethodSnackbarId.CANNOT_FETCH_FUNDING_METHODS
    );
  }

  const investmentFundingMethods = useMemo(
    () =>
      combineCustomFundingMethodsWithPredefined(
        getInvestmentFundingMethodsQuery.data ?? [],
        labelsConfig.loanUpper,
        params.loanHasFees
      ),
    [
      getInvestmentFundingMethodsQuery.data,
      labelsConfig.loanUpper,
      params.loanHasFees,
    ]
  );

  const getInvestmentFundingMethodsFeesSettingsQuery =
    useGetInvestmentFundingMethodsFeesSettingsQuery(
      { branchEncodedKey: params.loan?.assignedBranchKey },
      { skip: !params.loan }
    );

  if (getInvestmentFundingMethodsFeesSettingsQuery.isError) {
    displayInvestmentFundingMethodsSnackbar(
      snackbar,
      FundingMethodSnackbarId.CANNOT_FETCH_FUNDING_METHODS_FEES_SETTINGS
    );
  }

  useEffect(() => {
    if (isEmpty(selectedInvestmentFundingMethod) && isNotEmpty(params.loan)) {
      setSelectedInvestmentFundingMethod(
        getCustomFundingMethodBasedOnLoan(params.loan, investmentFundingMethods)
      );
    }
  }, [investmentFundingMethods, params.loan, selectedInvestmentFundingMethod]);

  return {
    investmentFundingMethodListDialogOpen,
    setInvestmentFundingMethodListDialogOpen,
    investmentFundingMethodCreateFormDialogOpen,
    setInvestmentFundingMethodCreateFormDialogOpen,
    selectedInvestmentFundingMethod,
    setSelectedInvestmentFundingMethod,
    investmentFundingMethods,
    defaultInvestmentFundingMethod,
    hasAnyQueryErrors:
      getInvestmentFundingMethodsFeesSettingsQuery.isError ||
      getInvestmentFundingMethodsQuery.isError,
    refetchQueries: () => {
      getInvestmentFundingMethodsFeesSettingsQuery.refetch();
      getInvestmentFundingMethodsQuery.refetch();
    },
  };
};
