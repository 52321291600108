import React from 'react';
import { IconProps } from '../iconTypes';

const UnderwritingIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 15 21"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M9.29289 1.29302L8.58579 2.00012L8.58579 2.00012L9.29289 1.29302ZM14.7071 6.70723L15.4142 6.00012L14.7071 6.70723ZM6 18.0001C5.44772 18.0001 5 18.4478 5 19.0001C5 19.5524 5.44772 20.0001 6 20.0001V18.0001ZM0 14.0001C0 14.5524 0.447715 15.0001 1 15.0001C1.55228 15.0001 2 14.5524 2 14.0001H0ZM0.292893 18.293C-0.0976311 18.6835 -0.0976311 19.3167 0.292893 19.7072C0.683417 20.0978 1.31658 20.0978 1.70711 19.7072L0.292893 18.293ZM3 2.00012H8.58579V0.00012207H3V2.00012ZM14 7.41434V17.0001H16V7.41434H14ZM8.58579 2.00012L14 7.41434L15.4142 6.00012L10 0.585909L8.58579 2.00012ZM16 7.41434C16 6.8839 15.7893 6.3752 15.4142 6.00012L14 7.41434H14H16ZM8.58579 2.00012V2.00012L10 0.585909C9.62493 0.210835 9.11622 0.00012207 8.58579 0.00012207V2.00012ZM13 20.0001C14.6569 20.0001 16 18.657 16 17.0001H14C14 17.5524 13.5523 18.0001 13 18.0001V20.0001ZM2 3.00012C2 2.44784 2.44772 2.00012 3 2.00012V0.00012207C1.34315 0.00012207 0 1.34327 0 3.00012H2ZM13 18.0001H6V20.0001H13V18.0001ZM2 14.0001V3.00012H0V14.0001H2ZM10 12.0001C10 13.1047 9.10457 14.0001 8 14.0001V16.0001C10.2091 16.0001 12 14.2093 12 12.0001H10ZM6 12.0001C6 10.8956 6.89543 10.0001 8 10.0001V8.00012C5.79086 8.00012 4 9.79098 4 12.0001H6ZM8 10.0001C9.10457 10.0001 10 10.8956 10 12.0001H12C12 9.79098 10.2091 8.00012 8 8.00012V10.0001ZM5.17157 13.4143L0.292893 18.293L1.70711 19.7072L6.58579 14.8285L5.17157 13.4143ZM8 14.0001C7.44744 14.0001 6.94881 13.7774 6.58579 13.4143L5.17157 14.8285C5.89434 15.5513 6.89571 16.0001 8 16.0001V14.0001ZM6.58579 13.4143C6.22276 13.0513 6 12.5527 6 12.0001H4C4 13.1044 4.44881 14.1058 5.17157 14.8285L6.58579 13.4143Z"
      fill="currentColor"
    />
  </svg>
);

export default UnderwritingIcon;
