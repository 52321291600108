import { labelsConfigDefault } from 'kennek/constants';
import { TableHeadersConfiguration } from '../kennek';

export type HeaderName = (typeof HEADERS_CHECK)[number];

export const HEADERS_CHECK = [
  'loanAmount',
  'startDate',
  'capitalizedFees',
  'deductedInterest',
  'onDisbursementFees',
  'firstPayment',
  'remainingInstallmentsCount',
  'interestRate',
  'totalRepaymentAmount',
  'leftToPay',
  'redemptionAmount',
] as const;

export const getHeadersLabels = ({ loanUpper } = labelsConfigDefault) => {
  return {
    loanAmount: `${loanUpper} Amount`,
    startDate: 'Disbursement date',
    capitalizedFees: 'Capitalised fees',
    deductedInterest: 'Deducted Interest',
    onDisbursementFees: 'Deducted fees',
    firstPayment: 'First Payment',
    remainingInstallmentsCount: 'Instalments Remaining',
    interestRate: 'Interest Rate',
    totalRepaymentAmount: 'Total Repayment Amount',
    leftToPay: 'Left to Pay',
    redemptionAmount: 'Redemption amount',
  };
};

export const getHeadersTooltips = ({ loanLower } = labelsConfigDefault) => {
  return {
    deductedInterest: `Deducted Interest header value and label will depend on repayment schedule values for specific ${loanLower}. It will display:\\n
  Capitalised fees  when  capitalizedFees > 0\\n
  Deducted Interest  when  capitalizedFees == 0 && deductedFees > 0\\n
  Deducted fees  when  capitalizedFees == 0 && deductedFees == 0 && onDisbursementFees > 0\\n
  First Payment  when all condition above are not met`,
    interestRate: `Interest rate header will be visible only when factor ${loanLower} is disabled`,
    totalRepaymentAmount: `Total repayment amount header will be visible only when factor ${loanLower} is enabled`,
  };
};

export const defaultActivatedAndEditable = {
  active: true,
  editable: true,
};

export type HeaderConfiguration = TableHeadersConfiguration<HeaderName>;
export const MAX_NUMBER_OF_SELECTED_HEADERS = 6;
export const MIN_NUMBER_OF_SELECTED_HEADERS = 1;

export const DEFAULT_HEADERS: HeaderConfiguration[] = [
  {
    name: 'loanAmount',
    ...defaultActivatedAndEditable,
  },
  {
    name: 'startDate',
    ...defaultActivatedAndEditable,
  },
  {
    name: 'deductedInterest',
    ...defaultActivatedAndEditable,
  },
  {
    name: 'remainingInstallmentsCount',
    ...defaultActivatedAndEditable,
  },
  {
    name: 'interestRate',
    ...defaultActivatedAndEditable,
  },
  {
    name: 'totalRepaymentAmount',
    active: false,
    editable: true,
  },
  {
    name: 'leftToPay',
    ...defaultActivatedAndEditable,
  },
  {
    name: 'redemptionAmount',
    active: false,
    editable: true,
  },
];
