import React from 'react';
import { IconProps } from '../iconTypes';

const TooltipIcon: React.FC<IconProps> = (props) => (
  <svg width="8" height="4" {...props}>
    <rect
      x="12"
      y="-12"
      width="8"
      height="8"
      transform="rotate(45)"
      fill="rgb(64 64 64)"
    />
  </svg>
);

export default TooltipIcon;
