import React from 'react';
import { Loading } from 'ui/components/Dialog/DialogLoading';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import {
  DELETE_FUNDING_METHOD_CONFIRMATION_TEXT,
  DELETE_FUNDING_METHOD_CONFIRM_LABEL,
  DELETE_FUNDING_METHOD_SIDE_EFFECTS_TEXT,
} from './InvestmentFundingMethod.constants';
import { InvestmentFundingMethod } from './InvestmentFundingMethod.types';

type InvestmentFundingMethodDeleteConfirmationDialogProps = {
  fundingMethodToDelete: InvestmentFundingMethod | null;
  onIsOpenChange: (isOpen: boolean) => void;
  onConfirm: () => void;
  isLoading?: boolean;
};

export const InvestmentFundingMethodDeleteConfirmationDialog: React.FC<
  InvestmentFundingMethodDeleteConfirmationDialogProps
> = (props) => {
  return (
    <ConfirmationModal
      isVisible={!!props.fundingMethodToDelete}
      onConfirm={props.onConfirm}
      setIsVisible={props.onIsOpenChange}
      destructive
      buttonLabel={DELETE_FUNDING_METHOD_CONFIRM_LABEL}
    >
      <div className="w-[350px] relative">
        {props.isLoading && (
          <div className="absolute w-full h-full">
            <Loading />
          </div>
        )}
        <p className="mb-2">
          {DELETE_FUNDING_METHOD_CONFIRMATION_TEXT(
            props.fundingMethodToDelete?.name
          )}
        </p>
        <p>{DELETE_FUNDING_METHOD_SIDE_EFFECTS_TEXT}</p>
      </div>
    </ConfirmationModal>
  );
};
