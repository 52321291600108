import React, { useContext } from 'react';
import classNames from 'classnames';
import { ThemeContext } from 'ui/theme';

export type InputLayoutType = 'primary' | 'dark';

export interface InputProps extends React.ComponentPropsWithRef<'input'> {
  layout?: InputLayoutType;
  valid?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const NumberInput = React.forwardRef<HTMLInputElement, InputProps>(
  function Input(props, ref) {
    const {
      valid,
      error,
      disabled,
      className,
      layout = 'primary',
      ...other
    } = props;

    const {
      theme: { input },
    } = useContext(ThemeContext);

    const baseStyle = input.base;
    const activeStyle = input.active;
    const activeDarkStyle = input.activeDark;
    const disabledStyle = input.disabled;
    const validStyle = input.valid;
    const invalidStyle = input.invalid;

    function hasValidation(valid: boolean | undefined): boolean {
      return valid !== undefined;
    }

    function validationStyle(valid: boolean | undefined): string {
      if (hasValidation(valid)) {
        return valid ? validStyle : invalidStyle;
      }
      return '';
    }

    function getActiveStyleBaseColorType() {
      return layout === 'dark' ? activeDarkStyle : activeStyle;
    }

    const cls = classNames(
      baseStyle,
      // don't apply activeStyle if has valid or disabled
      !hasValidation(valid) && !disabled && getActiveStyleBaseColorType(),
      // don't apply invalidStyle if has valid or disabled
      !hasValidation(valid) && !disabled && error && invalidStyle,
      // don't apply disabledStyle if has valid
      !hasValidation(valid) && disabled && disabledStyle,
      validationStyle(valid),
      className
    );

    const onKeyPress = other.onKeyPress;
    other.onKeyPress = (e) => {
      const target = e.target as HTMLInputElement;
      const currentValue = target.value + e.key;
      if (e.key === '-' && !target.value) return;
      if (isNaN(+currentValue)) e.preventDefault();
      if (onKeyPress) onKeyPress(e);
    };

    return (
      <input
        className={cls}
        type="text"
        ref={ref}
        disabled={disabled}
        {...other}
      />
    );
  }
);

export default NumberInput;
