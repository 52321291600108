import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, FormInput, Modal } from 'ui';
import { DocumentDuplicateIcon } from '@heroicons/react/outline';
import { SNACKBAR_DOCUMENT_MANAGEMENT } from '@/constants/snackbar-messages';
import { DMTableRow } from '@/hooks/useBrowseDocuments';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useUpdateDocumentMutation } from '@/services/documentManagement';
import { removeFileExtension } from '@/utils/documentManagement';
import {
  getDocumentNameKey,
  getDocumentNameValue,
} from '../EditDocumentsTable.utils';
import { UpdateDocumentPayload } from 'kennek/interfaces/documentManagement';

interface Props {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  documentData: DMTableRow;
  folderId: string;
  refreshData: () => void;
  isEditLoan: boolean;
}

const EditFileNameModal = ({
  show,
  setShow,
  documentData,
  folderId,
  refreshData,
  isEditLoan,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [extension, setExtension] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<{ fileName: string }>();
  const [updateDocument] = useUpdateDocumentMutation();
  const snackbar = useSnackbar();

  useEffect(() => {
    const name = getDocumentNameValue(
      isEditLoan,
      documentData?.name,
      documentData?.draftName
    );
    if (!name) return;
    const nameData = removeFileExtension(name);
    setExtension(nameData.extension);
    setValue('fileName', nameData.name);
  }, [documentData]);

  const onSubmit = ({ fileName }) => {
    setIsSubmitting(true);
    const nameKey = getDocumentNameKey(isEditLoan);
    const payload: { id: string; body: UpdateDocumentPayload } = {
      id: documentData?.id,
      body: {
        [nameKey]: fileName + extension,
        folderId,
      },
    };

    updateDocument(payload)
      .unwrap()
      .then(() => {
        snackbar.show({
          severity: 'success',
          title: SNACKBAR_DOCUMENT_MANAGEMENT.FILE_EDIT_NAME_SUCCESS,
        });
        closeDialog();
        refreshData();
      })
      .catch((error) => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_DOCUMENT_MANAGEMENT.FILE_EDIT_NAME_FAILED,
          content: error?.data?.message,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const validateFileName = (value: string) => {
    if (value.includes('/')) return 'Document name cannot contain slashes (/).';
  };

  const closeDialog = () => {
    setShow(false);
    reset();
  };

  return (
    <Modal
      isVisible={show}
      closeOnClickOutside={false}
      includeHeader="withCloseButton"
      setIsVisible={closeDialog}
      className="bg-opacity-40 top-0 bg-neutral-800 overflow-y-auto z-[101]"
      paddingBodyX="px-8 mt-0 mb-0 pt-0"
      paddingHeaderX="px-8 pt-4 pb-0"
      width="w-full max-w-[450px]"
      marginY="my-2"
    >
      <>
        <div className="flex justify-center pt-4">
          <span className="rounded-full w-12 h-12 bg-primary-100 flex justify-center items-center">
            <DocumentDuplicateIcon className="h-6 w-6 text-primary-900" />
          </span>
        </div>

        <p className="pt-4 text-center text-neutral-900 heading-400 mb-4">
          Edit file name
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            label="File name"
            placeholder="Name"
            errors={errors?.fileName?.message}
            {...register('fileName', {
              required: 'File name is required',
              validate: validateFileName,
            })}
          />

          <div className="flex flex-col">
            <div className="w-full flex justify-around mt-5">
              <Button
                className="w-40"
                layout="ghost"
                onClick={closeDialog}
                disabled={isSubmitting}
              >
                Cancel
              </Button>

              <Button
                className="w-40 px-0"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </>
    </Modal>
  );
};

export default EditFileNameModal;
