import React, { useContext } from 'react';
import classNames from 'classnames';
import { ThemeContext } from 'ui/theme';

export interface TextareaProps extends React.ComponentPropsWithRef<'textarea'> {
  /**
   * Defines the color of the textarea
   */
  valid?: boolean;
  error?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  function Textarea(props, ref) {
    const { error, valid, disabled, className, children, ...other } = props;

    const {
      theme: { textarea },
    } = useContext(ThemeContext);

    const baseStyle = textarea.base;
    const activeStyle = textarea.active;
    const disabledStyle = textarea.disabled;
    const validStyle = textarea.valid;
    const invalidStyle = textarea.invalid;

    function hasValidation(valid: boolean | undefined) {
      return valid !== undefined;
    }

    function validationStyle(valid: boolean | undefined): string {
      if (hasValidation(valid)) {
        return valid ? validStyle : invalidStyle;
      }
      return '';
    }

    const cls = classNames(
      baseStyle,
      // don't apply activeStyle if has valid or disabled
      !hasValidation(valid) && !disabled && activeStyle,
      // don't apply disabledStyle if has valid
      !hasValidation(valid) && disabled && disabledStyle,
      // don't apply invalidStyle if has valid or disabled
      !hasValidation(valid) && !disabled && error && invalidStyle,
      validationStyle(valid),
      className
    );

    return (
      <textarea className={cls} ref={ref} disabled={disabled} {...other}>
        {children}
      </textarea>
    );
  }
);

export default Textarea;
