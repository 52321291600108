import { formatAmount } from '@/utils/formatters';
import { Currencies } from 'kennek/interfaces/loans';

export const RepaymentScheduleBalanceSummary = ({
  pendingBalance,
  currency,
}: {
  pendingBalance: {
    balance: number;
    exceeded: number | null;
    short: number | null;
  };
  currency: Currencies;
}) => {
  const differenceLabel = pendingBalance?.exceeded
    ? `+${formatAmount(pendingBalance?.exceeded, true, currency)} Exceeded`
    : pendingBalance?.short
      ? `${formatAmount(pendingBalance?.short, true, currency)} Short`
      : '';
  return (
    <div className="py-2">
      <div className="py-2 text-4xl font-semibold">
        {formatAmount(pendingBalance?.balance, true, currency)}
      </div>
      <div className="text-xs font-normal text-[#dc2626] h-[20px]">
        {differenceLabel}
      </div>
    </div>
  );
};
