import React from 'react';

interface Props {
  title?: any;
  action?: any;
}

const CardHeader: React.FC<Props> = ({ title, action }) => {
  return (
    <div className="bg-white px-4 py-4 border-t border-b border-neutral-400 first:border-t-0 first:rounded-t-lg relative">
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div className="ml-4 mt-2">
          {typeof title === 'string' ? (
            <h2 className="text-lg leading-6 font-bold text-900">{title}</h2>
          ) : (
            title
          )}
        </div>
        <div className="ml-4 mt-2 flex items-center">{action || null}</div>
      </div>
    </div>
  );
};

export default CardHeader;
