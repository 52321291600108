import React from 'react';
import BanknotesIcon from 'kennek/icons/svg/BanknotesIcon';
import { Dialog } from 'ui';
import { EDIT_FUNDING_METHOD_DIALOG_TITLE } from './InvestmentFundingMethod.constants';
import {
  InvestmentFundingMethod,
  InvestmentFundingMethodEditFormData,
} from './InvestmentFundingMethod.types';
import { InvestmentFundingMethodEditForm } from './InvestmentFundingMethodEditForm';

type InvestmentFundingMethodEditFormDialogProps = {
  fundingMethod: InvestmentFundingMethod;
  isOpen: boolean;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onClose: () => void;
  onBack: () => void;
  onSubmit: (data: InvestmentFundingMethodEditFormData) => void;
};

export const InvestmentFundingMethodEditFormDialog: React.FC<
  InvestmentFundingMethodEditFormDialogProps
> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.onClose()}
      dialogIconColor="success"
      dialogIcon={<BanknotesIcon />}
      dialogIconTitle={EDIT_FUNDING_METHOD_DIALOG_TITLE}
      onBack={() => props.onBack()}
      isLoading={props.isLoading}
    >
      <InvestmentFundingMethodEditForm
        fundingMethod={props.fundingMethod}
        isSubmitting={props.isSubmitting}
        onSubmit={(data) => props.onSubmit(data)}
        onCancel={() => props.onBack()}
      />
    </Dialog>
  );
};
