import React from 'react';
import { IconProps } from '../iconTypes';

const UnderwritingApplicationsIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 17"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M4.66659 4.33333V11C4.66659 11.9205 5.41278 12.6667 6.33325 12.6667H11.3333M4.66659 4.33333V2.66667C4.66659 1.74619 5.41278 1 6.33325 1H10.1547C10.3758 1 10.5877 1.0878 10.744 1.24408L14.4225 4.92259C14.5788 5.07887 14.6666 5.29083 14.6666 5.51184V11C14.6666 11.9205 13.9204 12.6667 12.9999 12.6667H11.3333M4.66659 4.33333H3.33325C2.22868 4.33333 1.33325 5.22876 1.33325 6.33333V14.3333C1.33325 15.2538 2.07944 16 2.99992 16H9.33325C10.4378 16 11.3333 15.1046 11.3333 14V12.6667"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default UnderwritingApplicationsIcon;
