import { LoadingAnimation } from 'kennek/components';

interface Props {
  loaderStandardColor?: boolean;
  loadingHeight?: string;
}

const Loading = ({ loaderStandardColor, loadingHeight = 'h-72' }: Props) => {
  return (
    <div
      data-testid="table-loader"
      className={`w-full flex justify-center ${loadingHeight} my-8 items-center`}
    >
      <LoadingAnimation
        color={loaderStandardColor ? 'standard' : 'primary'}
        fitBox
      />
    </div>
  );
};

export { Loading };
