import React from 'react';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { Button, ProgressBar, StatusPill, Surface, Title } from 'ui';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { formatAmount } from '@/utils/formatters';
import { BalanceSummary, BalanceWidgetProps } from '../BalanceWidget.props';
import {
  createPercentageLabel,
  isBadgeSectionVisible,
} from '../BalanceWidget.utils';
import BadgeFactory from '../fragments/badge-factory/BadgeFactory';
import SummaryDetail from '../fragments/summary-detail/SummaryDetail';

const SimpleBalanceWidget: React.FC<BalanceWidgetProps> = ({
  children,
  currency,
  isLoading,
  isViewRepaymentScheduleDisabled,
  openRepaymentSchedule,
  percentage,
  totalPaidAmount,
  totalValue,
  state,
  summary,
}) => {
  return (
    <Surface padding="sm" border="light">
      <div className="w-full bg-white flex gap-4">
        <div className={classNames('flex-grow flex flex-col justify-between')}>
          <div className="flex justify-between">
            <Title
              icon={
                <SvgIcon
                  name="MoneyIcon"
                  height={24}
                  width={24}
                  className="-left-6 top-8"
                />
              }
              title="Balance"
              className="mt-0 mb-[25px]"
              titleSize="lg"
            />
            <div className="flex mb-[25px]">
              <Button
                layout="ghost"
                size="small"
                onClick={openRepaymentSchedule}
                disabled={isViewRepaymentScheduleDisabled}
              >
                View Repayment schedule
              </Button>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center">
              <LoadingAnimationPlat fitBox />
            </div>
          ) : (
            <div className="flex flex-col gap-[11px]">
              <div className="flex justify-between w-full">
                <div className="flex flex-col gap-[11px] w-full max-w-xs pb-5">
                  <div className="flex justify-between max-w-sm">
                    <p className="heading-500">
                      {formatAmount(totalPaidAmount, false, currency)}
                      <span className="body-600">{` of ${formatAmount(
                        totalValue,
                        false,
                        currency
                      )}`}</span>
                    </p>
                    <StatusPill
                      type="light"
                      className="h-6 w-20 flex justify-center"
                      startCase
                    >
                      {createPercentageLabel(percentage)}
                    </StatusPill>
                  </div>
                  <div className="max-w-xs">
                    <ProgressBar percentage={percentage} />
                  </div>
                </div>
                {!!summary.length && (
                  <div className="flex w-full gap-2">
                    {summary.map(
                      (balanceSummary: BalanceSummary, index: number) => (
                        <SummaryDetail
                          key={`summary-${index + 1}`}
                          title={balanceSummary.title}
                          detail={balanceSummary.detail}
                        />
                      )
                    )}
                  </div>
                )}
              </div>

              {isBadgeSectionVisible(state) && (
                <div className="border-t border-neutral-300 pt-5">
                  <BadgeFactory state={state} />
                </div>
              )}

              {children}
            </div>
          )}
        </div>
      </div>
    </Surface>
  );
};

export default SimpleBalanceWidget;
