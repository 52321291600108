import { TypedUseSelectorHook, shallowEqual, useSelector } from 'react-redux';
import { RootState } from '..';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

// User
export function useSelectUser() {
  return useTypedSelector(({ user }) => user?.data, shallowEqual);
}

export function useSelectSideBarPref() {
  return useTypedSelector(({ user }) => user.preferences.sidebar, shallowEqual);
}

export function useSelectLoanTable() {
  return useTypedSelector(
    ({ user }) => user.preferences.loanTable,
    shallowEqual
  );
}

export function useSelectTasksTable() {
  return useTypedSelector(
    ({ user }) => user.preferences.tasksTable,
    shallowEqual
  );
}

export function useSelectCodatKey() {
  return useTypedSelector(({ user }) => user.keys.codatKey, shallowEqual);
}

export function useSelectUiSettingsIsMainColorSet() {
  return useTypedSelector(({ user }) => user.uiSettings.isMainPrimaryColorSet);
}

export function useGetHideKennekBranding() {
  return useTypedSelector(({ user }) => user.hideKennekBranding, shallowEqual);
}

export function useSelectUiSettingsAnonymousLogo() {
  return useTypedSelector(({ user }) => user.uiSettings.anonymousLogo);
}

export function useIsUserLoggedIn() {
  return useTypedSelector(
    ({ user }) => !!user.data?.phoneVerified,
    shallowEqual
  );
}

export function useGetAnonymousBranchEncodedKey() {
  return useTypedSelector(({ user }) => user.anonymousBranchEncodedKey);
}
