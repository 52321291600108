import type { DropDownTableItem } from './DropDownActions';
import type { FiltersType } from './TableBody/TableBody';
import { Body, Empty, ExtendableBody } from './TableBody/TableBody';
import { Container } from './TableContainer';
import { Header } from './TableHeader';
import { Name } from './TableName';
import { PAGE_SIZES, Pagination } from './TablePagination';
import type { PageSize } from './TablePagination';
import { Subheader } from './TableSubheader';

const Table = {
  Container,
  Header,
  Name,
  Subheader,
  Body,
  Empty,
  Pagination,
  PAGE_SIZES,
  ExtendableBody,
};

export { Table };

export type { DropDownTableItem, FiltersType, PageSize };
