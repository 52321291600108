import { ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

type TrancheContainerProps = {
  children: ReactNode;
  disbursed?: boolean;
};

const TranchesContainer = ({ children }) => {
  return <div>{children}</div>;
};

const TrancheContainer = ({ children }) => {
  return <div className="relative">{children}</div>;
};

const TrancheLabel = ({
  children,
  disbursed = true,
}: TrancheContainerProps) => {
  return (
    <div className="flex items-center relative">
      <div
        className={classNames(
          'w-2 h-2 rounded-full bg-neutral-400 absolute -left-1',
          {
            'bg-neutral-500': !disbursed,
          }
        )}
      />

      <p
        className={classNames('heading-100 text-neutral-500 pl-8', {
          'text-neutral-800': !disbursed,
        })}
      >
        {children}
      </p>
    </div>
  );
};

const TrancheContent = ({
  children,
  disbursed = true,
}: TrancheContainerProps) => {
  return (
    <div className="pt-2.5 pb-2.5">
      <div
        className={classNames(
          'grid gap-4 border-l border-neutral-400 pl-8 grid-cols-2 [&>:nth-child(n+3)]:col-span-2',
          {
            'border-neutral-500': !disbursed,
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};

const TrancheDeleteButton = ({ children }) => {
  return (
    <div className="absolute h-10 pt-9 flex -right-10 top-6">{children}</div>
  );
};

const AddTrancheContainer = ({ children }) => {
  return (
    <div className="pl-8 h-[56px] flex items-center border-l border-neutral-300 border-dashed">
      {children}
    </div>
  );
};

const TrancheWithSideElement = ({ children }) => {
  return <div className="relative">{children}</div>;
};

const TrancheSideElement = ({ children }: { children: ReactNode }) => (
  <span className="absolute -right-12 top-8">{children}</span>
);

const TranchesSummaryContainer = ({ children }) => {
  const summaryRef = useRef<HTMLDivElement>();

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const tableObserver = new IntersectionObserver(
      ([entry]) => {
        setIsFixed(!entry.isIntersecting);
      },
      { threshold: 0 }
    );

    const summary = summaryRef.current;

    tableObserver.observe(summary);

    return () => {
      tableObserver.unobserve(summary);
    };
  }, []);

  const child = (
    <div className="relative">
      <div className="flex items-center absolute -left-[2px] h-full">
        <div className="w-[5px] h-[5px] rounded-full bg-neutral-400" />
      </div>

      <div className="grid grid-cols-[repeat(2,minmax(0,1fr))] gap-4 pl-8 mt-2.5 mb-2.5">
        {children}
      </div>
    </div>
  );

  return (
    <>
      {child}

      <div ref={summaryRef} />

      <div
        className={classNames(
          'w-[460px] bottom-0 translate-x-[-10px] pl-[10px] pr-[50px] bg-white',
          {
            fixed: isFixed,
            hidden: !isFixed,
          }
        )}
      >
        {child}
      </div>
    </>
  );
};

const Tranches = {
  AddTrancheContainer,
  TrancheContainer,
  TrancheDeleteButton,
  TrancheContent,
  TrancheLabel,
  TranchesContainer,
  TrancheWithSideElement,
  TrancheSideElement,
  TranchesSummaryContainer,
};

export { Tranches };
