import { useCallback, useState } from 'react';

export interface UseConfirmDialogResult<T extends object = never> {
  confirmed: boolean;
  result?: T;
}

/**
 * Provides method to manage dialog state and result.
 * @returns {{isOpen: boolean; dialogOptions: TDialogOptions | null; openDialog: (options: TDialogOptions) => Promise<UseConfirmDialogResult<TResult>>; closeDialog: (result: UseConfirmDialogResult<TResult>) => void}}
 * @property openDialog - open dialogs and returns promise with dialog result.
 * @property closeDialog - closes dialog and resolves promise with result.
 * @property isOpen - true if dialog is opened.
 * @property dialogOptions - options passed to the dialog.
 */
export const useConfirmDialog = <
  TDialogOptions extends object = never,
  TResult extends object = never,
>() => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogOptions, setDialogOptions] = useState<TDialogOptions | null>(
    null
  );
  const [resolvePromise, setResolvePromise] =
    useState<(result: UseConfirmDialogResult<TResult>) => void>();

  const openDialog = useCallback(
    (options: TDialogOptions): Promise<UseConfirmDialogResult<TResult>> => {
      setDialogOptions(options);
      setIsOpen(true);

      return new Promise<UseConfirmDialogResult<TResult>>((resolve) => {
        setResolvePromise(() => resolve);
      });
    },
    []
  );

  const closeDialog = useCallback(
    (result: UseConfirmDialogResult<TResult>) => {
      setIsOpen(false);
      setDialogOptions(null);
      if (resolvePromise) {
        resolvePromise(result);
      }
    },
    [resolvePromise]
  );

  return {
    isOpen,
    dialogOptions,
    openDialog,
    closeDialog,
  };
};
