import { useMemo } from 'react';
import {
  InterestCapitalisationFrequency,
  Loan,
  LoanProductRules,
  RepaymentPeriodUnits,
} from '@/interfaces/loans';

export const repaymentPeriodUnitsOptions: Record<RepaymentPeriodUnits, string> =
  Object.freeze({
    DAYS: 'Daily',
    WEEKS: 'Weekly',
    MONTHS: 'Monthly',
    YEARS: 'Yearly',
  });

const useInterestCapitalisationFrequencyValue = (
  loan: Loan,
  loanProductRules?: LoanProductRules
) => {
  return useMemo(() => {
    const interestCapitalisationFrequency =
      loan?.interestSettings?.interestCapitalisationFrequency;
    const unit = loan?.scheduleSettings?.repaymentPeriodUnits;

    if (!loanProductRules?.rollUp || !interestCapitalisationFrequency) {
      return '';
    }

    if (
      interestCapitalisationFrequency ===
        InterestCapitalisationFrequency.AT_THE_INSTALLMENT_DUE_DATE &&
      unit
    ) {
      return repaymentPeriodUnitsOptions[unit];
    }

    return 'Monthly';
  }, [loanProductRules, loan]);
};

export default useInterestCapitalisationFrequencyValue;
