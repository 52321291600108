import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const CardFooter: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        'px-4 py-3 border-t border-neutral-400 sm:px-6 rounded-b-md relative',
        className
      )}
    >
      {children}
    </div>
  );
};

export default CardFooter;
