import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { TableContext } from './TableContext';

type SubheaderProps = {
  children: React.ReactNode;
  dashboard?: boolean;
};

const Subheader = ({ children, dashboard }: SubheaderProps) => {
  const { isLoading, setHasSubheader } = useContext(TableContext) ?? {};

  useEffect(() => {
    setHasSubheader?.();
  }, [setHasSubheader]);

  if (isLoading) return null;

  return (
    <div
      className={classNames(
        'flex justify-between w-full items-center sticky top-[calc(var(--nav-header-height)+var(--table-header-height))] z-[5] bg-white h-[var(--table-subheader-height)]',
        { 'h-[80px]': dashboard }
      )}
    >
      {children}
    </div>
  );
};

export { Subheader };
