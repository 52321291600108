import React from 'react';
import { IconProps } from '../iconTypes';

const DocumentsIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 21"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M7 2.00024C6.44772 2.00024 6 2.44796 6 3.00024V13.0002C6 13.5525 6.44772 14.0002 7 14.0002H15C15.5523 14.0002 16 13.5525 16 13.0002V6.41446L11.5858 2.00024H7ZM4 3.00024C4 1.34339 5.34315 0.000244141 7 0.000244141H11.5858C12.1162 0.000244141 12.6249 0.210959 13 0.58603L17.4142 5.00024C17.7893 5.37532 18 5.88403 18 6.41446V13.0002C18 14.6571 16.6569 16.0002 15 16.0002H14V17.0002C14 18.6571 12.6569 20.0002 11 20.0002H3C1.34315 20.0002 0 18.6571 0 17.0002V7.00024C0 5.34339 1.34315 4.00024 3 4.00024H4V3.00024ZM4 6.00024H3C2.44772 6.00024 2 6.44796 2 7.00024V17.0002C2 17.5525 2.44772 18.0002 3 18.0002H11C11.5523 18.0002 12 17.5525 12 17.0002V16.0002H7C5.34315 16.0002 4 14.6571 4 13.0002V6.00024Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M7.5 5.00024C7.5 4.7241 7.72386 4.50024 8 4.50024H12C12.2761 4.50024 12.5 4.7241 12.5 5.00024C12.5 5.27639 12.2761 5.50024 12 5.50024H8C7.72386 5.50024 7.5 5.27639 7.5 5.00024ZM7.5 8.00024C7.5 7.7241 7.72386 7.50024 8 7.50024H14C14.2761 7.50024 14.5 7.7241 14.5 8.00024C14.5 8.27639 14.2761 8.50024 14 8.50024H8C7.72386 8.50024 7.5 8.27639 7.5 8.00024ZM7.5 11.0002C7.5 10.7241 7.72386 10.5002 8 10.5002H14C14.2761 10.5002 14.5 10.7241 14.5 11.0002C14.5 11.2764 14.2761 11.5002 14 11.5002H8C7.72386 11.5002 7.5 11.2764 7.5 11.0002Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default DocumentsIcon;
