import React from 'react';
import { isEmpty } from 'lodash';
import { OnboardingSummaryItem as SummaryItem } from 'ui';
import { PencilAltIcon } from '@heroicons/react/outline';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { formatAmount } from '@/utils/formatters';
import { Currencies } from 'kennek/interfaces/loans';
import { LoanFacility } from '@/interfaces/loans/facility';

interface Props {
  loanFacilities: LoanFacility[];
  currency: Currencies;
  editable: boolean;
  onEdit: () => void;
}

const FacilitiesSummary = ({
  loanFacilities,
  currency,
  editable,
  onEdit,
}: Props) => {
  const { loanLower } = useGetLabelsConfig();

  return (
    <div className="flex flex-col mt-8 pl-7">
      <div className="flex justify-between">
        <span className="body-200 text-neutral-700">FACILITIES</span>
        {editable && (
          <button onClick={onEdit}>
            <PencilAltIcon height={20} className="text-secondary-500" />
          </button>
        )}
      </div>

      {loanFacilities?.map((loanFacility) => (
        <SummaryItem
          topic={`${loanFacility.name}: `}
          content={formatAmount(loanFacility.amount, true, currency)}
          key={loanFacility.id}
        />
      ))}

      {isEmpty(loanFacilities) && (
        <SummaryItem
          topic={`There are no facilities created for this ${loanLower}.`}
        />
      )}
    </div>
  );
};

export default FacilitiesSummary;
