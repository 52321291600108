import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Alert, Button, FormInput } from 'ui';
import ToggleInput from 'ui/components/ToggleInput';
import { validationStandardMessages } from '@/components/uaas/const/validationStandardMessages';
import {
  EDIT_FUNDING_METHOD_ALERT_TEXT,
  FORM_CANCEL_LABEL,
  FORM_FEES_SECTION_HEADING,
  FORM_NAME_PLACEHOLDER,
  FORM_NAME_SECTION_HEADING,
  FORM_NO_FEES_TEXT,
  FORM_SUBMIT_LABEL,
} from './InvestmentFundingMethod.constants';
import {
  InvestmentFundingMethod,
  InvestmentFundingMethodEditFormData,
} from './InvestmentFundingMethod.types';

type InvestmentFundingMethodCreateEditProps = {
  fundingMethod: Omit<InvestmentFundingMethod, 'isPredefined'>;
  onSubmit: (data: InvestmentFundingMethodEditFormData) => void;
  onCancel: () => void;
  isSubmitting?: boolean;
};

export const InvestmentFundingMethodEditForm: React.FC<
  InvestmentFundingMethodCreateEditProps
> = (props) => {
  const {
    reset: formReset,
    control: formControl,
    setValue: formSetValue,
    handleSubmit: formHandleSubmit,
  } = useForm<InvestmentFundingMethodEditFormData>({
    defaultValues: props.fundingMethod,
  });

  const { fields: fees } = useFieldArray({
    control: formControl,
    name: 'fees',
  });

  return (
    <form
      className="w-[340px] relative"
      onSubmit={formHandleSubmit((data) => props.onSubmit(data))}
      noValidate
      autoComplete="off"
    >
      <Alert type="info" className="my-4 text-left">
        {EDIT_FUNDING_METHOD_ALERT_TEXT}
      </Alert>
      <h2 className="heading-200 text-left py-2 -mb-2">
        {FORM_NAME_SECTION_HEADING}
      </h2>
      <Controller
        control={formControl}
        name="name"
        render={({ field, fieldState }) => (
          <FormInput
            {...field}
            errors={fieldState.error?.message}
            placeholder={FORM_NAME_PLACEHOLDER}
          />
        )}
        rules={{
          required: {
            message: validationStandardMessages.isRequired,
            value: true,
          },
        }}
      />
      <h3 className="heading-200 text-left py-2 -mt-4">
        {FORM_FEES_SECTION_HEADING}
      </h3>
      <div className="px-2 max-h-[400px] overflow-y-auto">
        {fees.length > 0 ? (
          fees.map((fee, index) => (
            <div key={fee.feeId} className="py-1">
              <Controller
                control={formControl}
                name={`fees.${index}.enabled`}
                render={({ field }) => (
                  <ToggleInput
                    label={fee.name}
                    labelClassName="text-lg"
                    enabled={field.value}
                    onChange={(value) => formSetValue(field.name, value)}
                  />
                )}
              />
            </div>
          ))
        ) : (
          <p className="italic text-left text-neutral-700 pt-2 pb-4">
            {FORM_NO_FEES_TEXT}
          </p>
        )}
      </div>
      <div className="flex gap-4 items-center justify-center mt-4">
        <Button
          className="w-[150px]"
          layout="ghost"
          onClick={() => {
            props.onCancel();
            formReset();
          }}
        >
          {FORM_CANCEL_LABEL}
        </Button>
        <Button
          name="submit"
          type="submit"
          className="w-[150px]"
          loading={props.isSubmitting}
          disabled={fees.length <= 0}
        >
          {FORM_SUBMIT_LABEL}
        </Button>
      </div>
    </form>
  );
};
