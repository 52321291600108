import React, { HTMLAttributes, forwardRef, useState } from 'react';
import classNames from 'classnames';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

interface Props extends HTMLAttributes<HTMLLabelElement> {
  className?: string;
  htmlFor?: string;
  tooltipText?: string;
  tooltipClassName?: string;
  children: React.ReactNode;
}

const Label = forwardRef<HTMLLabelElement, Props>(function Label(props, ref) {
  const { children, htmlFor, tooltipText, tooltipClassName } = props;
  const [hover, setHover] = useState<boolean>(false);

  return (
    <div
      className={classNames(props.className, 'flex justify-start items-center')}
    >
      <label
        htmlFor={htmlFor}
        className="font-semibold text-xs text-neutral-900"
        ref={ref}
      >
        {children}
      </label>
      {tooltipText && (
        <div
          className="relative"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <QuestionMarkCircleIcon className="w-4 h-4 ml-2" />
          <div
            className={classNames(
              tooltipClassName,
              'bg-neutral-300 p-4 rounded-lg shadow-lg leading-5 absolute left-4 bottom-7',
              hover ? 'block' : 'hidden'
            )}
          >
            <p className="text-sm">{tooltipText}</p>
          </div>
        </div>
      )}
    </div>
  );
});

export default Label;
