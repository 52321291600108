import { labelsConfigDefault } from 'kennek/constants';
import { useGetLabelConfigQuery } from '@/services/kennek';
import { useGetAnonymousBranchEncodedKey } from '@/store/user/selectors';
import { useBranchEncodedKey } from './useBranchEncodedKey';
import { LabelType } from 'kennek/interfaces/kennek';

const useGetLabelsConfig = () => {
  const { branchEncodedKey } = useBranchEncodedKey();
  const anonymousBranchEncodedKey = useGetAnonymousBranchEncodedKey();
  const branchKey = branchEncodedKey ?? anonymousBranchEncodedKey;
  const { currentData, isLoading } = useGetLabelConfigQuery(
    { branchEncodedKey: branchKey },
    {
      skip: !branchKey,
    }
  );
  const borrowerLabel = currentData?.find(
    (x) => x.labelType === LabelType.BORROWER
  );
  const loanLabel = currentData?.find((x) => x.labelType === LabelType.LOAN);

  if (isLoading)
    return {
      borrowerLower: '',
      borrowerUpper: '',
      borrowersLower: '',
      borrowersUpper: '',
      loanLower: '',
      loanUpper: '',
      loansLower: '',
      loansUpper: '',
    };

  return {
    borrowerLower:
      borrowerLabel?.singular?.toLowerCase() ||
      labelsConfigDefault?.borrowerLower,
    borrowerUpper:
      borrowerLabel?.singular || labelsConfigDefault?.borrowerUpper,
    borrowersLower:
      borrowerLabel?.plural?.toLowerCase() ||
      labelsConfigDefault?.borrowersLower,
    borrowersUpper:
      borrowerLabel?.plural || labelsConfigDefault?.borrowersUpper,
    loanLower:
      loanLabel?.singular?.toLowerCase() || labelsConfigDefault?.loanLower,
    loanUpper: loanLabel?.singular || labelsConfigDefault?.loanUpper,
    loansLower:
      loanLabel?.plural?.toLowerCase() || labelsConfigDefault?.loansLower,
    loansUpper: loanLabel?.plural || labelsConfigDefault?.loansUpper,
  };
};

export default useGetLabelsConfig;
