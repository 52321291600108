import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

const useDebouncedIsLoading = (values: boolean[], delay = 200) => {
  const [isLoading, setIsLoading] = useState(false);

  const onChangeLoader = useCallback(
    debounce((val) => {
      setIsLoading(val);
    }, delay),
    []
  );

  useEffect(() => {
    const isLoadingData = values.some((x) => x);
    isLoadingData ? setIsLoading(true) : onChangeLoader(isLoadingData);
  }, [onChangeLoader, values]);

  return isLoading;
};

export default useDebouncedIsLoading;
