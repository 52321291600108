import React from 'react';
import { IconProps } from '../iconTypes';

const BanknotesIcon: React.FC<IconProps> = (props) => {
  return (
    <svg
      width="21"
      height="12"
      viewBox="0 0 21 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.96641 0.33125C4.10805 0.142396 4.33034 0.03125 4.56641 0.03125H19.5664C19.8353 0.03125 20.0837 0.175218 20.2173 0.408582C20.3509 0.641947 20.3493 0.928988 20.2132 1.1609L17.8654 5.1609C17.7307 5.39033 17.4846 5.53125 17.2186 5.53125H1.56641C1.28233 5.53125 1.02263 5.37075 0.89559 5.11666C0.768546 4.86257 0.795963 4.55851 0.966411 4.33125L3.96641 0.33125ZM4.94141 1.53125L3.06641 4.03125H16.7892L18.2565 1.53125H4.94141Z"
        fill="#0CB676"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5464 2.03125H19.5664C19.8231 2.03125 20.0619 2.16248 20.1995 2.37912C20.3371 2.59576 20.3544 2.86772 20.2453 3.10003L17.8975 8.10003C17.7739 8.36321 17.5093 8.53125 17.2186 8.53125H1.56641C1.29786 8.53125 1.04981 8.38767 0.916054 8.1548C0.7823 7.92193 0.783262 7.63531 0.918576 7.40335L2.58524 4.5462L3.88091 5.30201L2.87219 7.03125H16.7422L18.5464 3.18893V2.03125Z"
        fill="#0CB676"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5464 5.03125H19.5664C19.8231 5.03125 20.0619 5.16248 20.1995 5.37912C20.3371 5.59576 20.3544 5.86772 20.2453 6.10003L17.8975 11.1C17.7739 11.3632 17.5093 11.5312 17.2186 11.5312H1.56641C1.29786 11.5312 1.04981 11.3877 0.916054 11.1548C0.7823 10.9219 0.783262 10.6353 0.918576 10.4033L2.58524 7.5462L3.88091 8.30201L2.87219 10.0312H16.7422L18.5464 6.18893V5.03125Z"
        fill="#0CB676"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1419 3.85311C12.3106 3.84617 13.2554 3.38715 13.2521 2.82787C13.2488 2.26859 12.2986 1.82083 11.1299 1.82777C9.96113 1.83471 9.01637 2.29372 9.01969 2.853C9.02301 3.41228 9.97316 3.86005 11.1419 3.85311Z"
        fill="#0CB676"
      />
      <path
        d="M17.3447 2.3333C18.0783 2.65624 18.4899 2.62589 18.8188 2.58744L19.9296 0.722981L17.3447 0.723088C16.5913 1.05874 16.4174 1.92506 17.3447 2.3333Z"
        fill="#0CB676"
      />
      <path
        d="M4.68665 3.4834C3.95272 3.16127 3.54117 3.19207 3.21235 3.23088L2.10355 5.09655L4.68841 5.09361C5.44153 4.75713 5.61441 3.89062 4.68665 3.4834Z"
        fill="#0CB676"
      />
      <path
        d="M6.30544 1.81523C6.98247 1.47672 7.32099 0.799684 7.32131 0.175994L5.14285 0.17615L3.93595 1.81529C4.61285 2.15375 5.3992 2.26835 6.30544 1.81523Z"
        fill="#0CB676"
      />
      <path
        d="M15.8045 3.81838C15.1777 4.2427 15.1581 4.63297 15.2395 5.25133L17.3992 4.96568L18.1535 3.5078C17.4381 3.26096 16.6435 3.2504 15.8045 3.81838Z"
        fill="#0CB676"
      />
    </svg>
  );
};

export default BanknotesIcon;
