import React from 'react';
import {
  CircleSvgIconProps,
  CovenantSvgIconProps,
  IconProps,
  ProgressBarThumbSvgIconProps,
  SvgIconProps,
} from './iconTypes';
import { icons } from './svg';
import { CircleIconProps } from './svg/CircleIcon';
import { CovenantIconProps } from './svg/CovenantIcon';
import { ProgressBarThumbIconProps } from './svg/ProgressBarThumbIcon';

type Props =
  | SvgIconProps
  | CircleSvgIconProps
  | CovenantSvgIconProps
  | ProgressBarThumbSvgIconProps;

const SvgIcon: React.FC<Props> = ({ name, ...attrs }) => {
  const render = () => {
    switch (name) {
      case 'ProgressBarThumbIcon':
        return React.createElement(
          icons[name],
          attrs as ProgressBarThumbIconProps
        );
      case 'CircleIcon':
        return React.createElement(icons[name], attrs as CircleIconProps);
      case 'CovenantIcon':
        return React.createElement(icons[name], attrs as CovenantIconProps);
      default:
        return React.createElement(icons[name], attrs as IconProps);
    }
  };

  return render();
};

export default SvgIcon;
