import React, { useState } from 'react';
import { SvgIcon } from 'kennek/icons';
import { Tooltip } from 'ui';

interface Props {
  valueToCopy: string;
}

const CopyButton = ({ valueToCopy }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const copyText = () => {
    navigator.clipboard.writeText(valueToCopy);
    if (showTooltip) return;

    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };

  return (
    <button
      className="ml-[15px] hover:bg-neutral-300 hover:bg-opacity-40 relative"
      onClick={() => copyText()}
    >
      <SvgIcon name="CopyIcon" />
      {showTooltip && (
        <Tooltip
          position="custom"
          customPosition="-top-8 -right-4"
          withoutArrow
        >
          <div>Copied!</div>
        </Tooltip>
      )}
    </button>
  );
};

export { CopyButton };
