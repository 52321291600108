import { loansBaseApi } from '.';
import { rootApi } from '../rootApi';
import { CounterpartyDto, CreateCounterpartyDto } from '@/interfaces/loans';

export const counterpartyApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createCounterparty: builder.mutation<
      CounterpartyDto,
      CreateCounterpartyDto
    >({
      query: (payload) => ({
        url: loansBaseApi + '/counterparties',
        method: 'POST',
        body: payload,
      }),
    }),
    getOriginatorCounterparties: builder.query<CounterpartyDto[], unknown>({
      query: () => ({
        url: loansBaseApi + '/counterparties/all',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateCounterpartyMutation,
  useGetOriginatorCounterpartiesQuery,
} = counterpartyApi;
