import { Dispatch, SetStateAction } from 'react';
import { Button, Dialog } from 'ui';
import { ExclamationIcon } from '@heroicons/react/solid';
import { SNACKBAR_DOCUMENT_MANAGEMENT } from '@/constants/snackbar-messages';
import { DMTableRow } from '@/hooks/useBrowseDocuments';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useDeleteDocumentMutation,
  useDeleteFolderMutation,
  useUpdateDocumentMutation,
  useUpdateFolderMutation,
} from '@/services/documentManagement';
import { DocumentManagementRowType } from '@/utils/documentManagement';

interface Props {
  deleteModalVisible: boolean;
  setDeleteModalVisible: Dispatch<SetStateAction<boolean>>;
  documentDeleteData: DMTableRow;
  setIsPendingDelete: Dispatch<SetStateAction<boolean>>;
  refreshData: () => void;
}

const DeleteDocumentDialog = ({
  deleteModalVisible,
  setDeleteModalVisible,
  documentDeleteData,
  setIsPendingDelete,
  refreshData,
}: Props) => {
  const [deleteDocument] = useDeleteDocumentMutation();
  const [deleteFolder] = useDeleteFolderMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [updateFolder] = useUpdateFolderMutation();
  const snackbar = useSnackbar();

  const onDeleteFileSuccess = () => {
    snackbar.show({
      severity: 'success',
      title: SNACKBAR_DOCUMENT_MANAGEMENT.FILE_DELETED,
    });
    refreshData();
  };

  const onDeleteFolderSuccess = () => {
    snackbar.show({
      severity: 'success',
      title: SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_DELETE_SUCCESS,
    });
    refreshData();
  };

  const handleDeleteFileError = (error) => {
    const errMsg =
      error?.status === 404
        ? SNACKBAR_DOCUMENT_MANAGEMENT.FILE_DELETE_NOT_FOUND
        : SNACKBAR_DOCUMENT_MANAGEMENT.FILE_DELETED_FAILED;

    snackbar.show({
      severity: 'error',
      title: errMsg,
      content: error?.data?.message,
    });
  };

  const handleDeleteFolderError = (error) => {
    const errMsg =
      error?.status === 404
        ? SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_DELETE_NOT_FOUND
        : SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_DELETED_FAILED;
    snackbar.show({
      severity: 'error',
      title: errMsg,
      content: error?.data?.message,
    });
  };

  const changeFileToDraftDelete = () => {
    updateDocument({
      id: documentDeleteData?.id,
      body: {
        ...documentDeleteData?.documentData,
        name: documentDeleteData?.name,
        isDraftDeleted: true,
      },
    })
      .then(() => onDeleteFileSuccess())
      .catch((error) => handleDeleteFileError(error))
      .finally(() => setIsPendingDelete(false));
  };

  const changeFolderToDraftDelete = () => {
    updateFolder({
      id: documentDeleteData?.id,
      body: {
        ...documentDeleteData?.documentData,
        name: documentDeleteData?.name,
        isDraftDeleted: true,
      },
    })
      .then(() => onDeleteFolderSuccess())
      .catch((error) => handleDeleteFolderError(error))
      .finally(() => setIsPendingDelete(false));
  };

  const deleteDocumentPermanent = () => {
    deleteDocument({ id: documentDeleteData?.id })
      .unwrap()
      .then(() => onDeleteFileSuccess())
      .catch((error) => handleDeleteFileError(error))
      .finally(() => setIsPendingDelete(false));
  };

  const deleteFolderPermanent = () => {
    deleteFolder({ id: documentDeleteData?.id })
      .unwrap()
      .then(() => onDeleteFolderSuccess())
      .catch((error) => handleDeleteFolderError(error))
      .finally(() => setIsPendingDelete(false));
  };

  const handleDelete = () => {
    const rowType = documentDeleteData?.rowType;
    setIsPendingDelete(true);
    setDeleteModalVisible(false);
    if (
      [
        DocumentManagementRowType.FILE,
        DocumentManagementRowType.FILE_EDIT,
      ].includes(rowType)
    ) {
      deleteDocumentPermanent();
    }

    if (rowType === DocumentManagementRowType.FILE_EDIT_CORE) {
      changeFileToDraftDelete();
    }

    if (
      [
        DocumentManagementRowType.USER_FOLDER,
        DocumentManagementRowType.USER_FOLDER_EDIT,
      ].includes(rowType)
    ) {
      deleteFolderPermanent();
    }

    if (rowType === DocumentManagementRowType.USER_FOLDER_EDIT_CORE) {
      changeFolderToDraftDelete();
    }
  };
  return (
    <Dialog
      open={deleteModalVisible}
      onClose={setDeleteModalVisible}
      dialogIconColor="destructive"
      dialogIconTitle={`Are you sure you want to delete ${documentDeleteData?.name}?`}
      dialogIcon={<ExclamationIcon className="w-6 h-6 text-error-500" />}
    >
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-row justify-between mt-6 w-full">
          <Button
            layout="outline"
            className="w-40"
            onClick={() => setDeleteModalVisible(false)}
          >
            Cancel
          </Button>
          <Button
            className="w-40 ml-2"
            layout="destructive"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteDocumentDialog;
