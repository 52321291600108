import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'react-table';
import classNames from 'classnames';

interface AccordionRowProps {
  children?: React.ReactElement;
  row: Row;
  show: boolean;
  fullWidth: boolean;
}

const AccordionRow = ({
  children,
  row,
  show = false,
  fullWidth,
}: AccordionRowProps) => {
  const details = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(details?.current?.offsetHeight ?? 0);
  }, [details]);

  return (
    <tr role="row" className={classNames('border-none w-full relative')}>
      <div
        style={{ height: show ? height : 0 }}
        className={classNames(
          'w-full transition-[height] ease-out duration-200 relative mb-1'
        )}
      />

      <div
        className={classNames(
          'absolute bg-neutral-200 rounded-md top-0 transition-opacity duration-150',
          fullWidth ? 'w-full' : 'w-[96%]',
          { 'opacity-0': !show, 'opacity-1': show }
        )}
        ref={details}
      >
        {children &&
          React.Children.map(children, (child) =>
            React.cloneElement(child, { row })
          )}
      </div>
    </tr>
  );
};

export { AccordionRow };
