import React from 'react';
import classNames from 'classnames';
import CardBody from './CardBody';
import CardFooter from './CardFooter';
import CardHeader from './CardHeader';

export interface CardProps {
  action?: any;
  className?: string;
  clean?: boolean;
  footer?: any;
  title?: any;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
  action,
  children,
  className,
  clean,
  footer,
  title,
}) => {
  const rootClassName = classNames(
    className,
    !clean && 'bg-white shadow rounded-xl flex flex-col relative'
  );

  return (
    <section className={rootClassName}>
      {(action || title) && <CardHeader title={title} action={action} />}
      <CardBody>{children}</CardBody>
      {footer && <CardFooter>{footer}</CardFooter>}
    </section>
  );
};

export default Card;
