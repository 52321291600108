import { loansBaseApi } from '.';
import { rootApi } from '../rootApi';
import { Loan } from 'kennek/interfaces/loans';
import { Asset, AssetDetails } from '@/interfaces/loans';

const loanEditsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAssets: builder.query<Asset[], Loan['encodedKey']>({
      query: (loanEncodedKey) => ({
        url: `${loansBaseApi}/securities/${loanEncodedKey}/all`,
      }),
      providesTags: ['Loans'],
    }),
    getSupportedAssets: builder.query<AssetDetails[], Loan['encodedKey']>({
      query: (loanEncodedKey) => ({
        url: `${loansBaseApi}/securities/${loanEncodedKey}/supported-assets`,
      }),
      providesTags: ['Loans'],
    }),
  }),
});

export const { useGetAssetsQuery, useGetSupportedAssetsQuery } = loanEditsApi;
