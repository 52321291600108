import { SelectOption } from '..';

export const sortSelectOptions = (option: SelectOption[]): SelectOption[] => {
  if (!option) return [];
  return option.sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  });
};
