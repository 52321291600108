import { CurrencyEquivalentValue } from 'ui/components/FormInput';
import { kennekDemoCustomInformationOrdered } from '@/components/loans/custom-information/kennek-demo.utils';
import {
  leaseEstateCustomInformationOrdered_EUR,
  leaseEstateCustomInformationOrdered_GBP,
  leaseEstateLabelUnits_EUR,
  leaseEstateLabelUnits_GBP,
} from '@/components/loans/custom-information/lease-state.utils';
import { UserTypes } from 'kennek/interfaces/accounts';
import { CustomInformationPageIds } from 'kennek/interfaces/kennek';
import { Loan } from 'kennek/interfaces/loans';

export interface CustomInformationFields {
  key: string;
  value: string;
  visibility: UserTypes[];
}

export interface CustomInformationResponse {
  data: CustomInformationFields[];
  loanId?: string;
}

export interface CustomInformationProps {
  onContinue: () => void;
  onBack: () => void;
  loanId: Loan['id'];
  branchEncodedKey: string;
  isRestructure?: boolean;
}

export interface CustomInformationChildProps {
  loanId: string;
  initialValues: CustomInformationFields[];
  isEdit?: boolean;
  isRestructure?: boolean;
  isPatchingTaskStatus?: boolean;
  currencyInput?: CurrencyEquivalentValue;
  originatorId?: string;
  onBack?: () => void;
  refetch?: () => void;
  handleContinue?: () => void;
  saveCustomInformation?: (data: CustomInformationFields[]) => void;
}

export interface CustomFieldsVisibility {
  key: string;
  visibility: UserTypes[];
}

export const convertToNumber = (value: string): number =>
  +value?.replace(/,/g, '');

export const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export const customInformationLabelUnits = (
  pageId: CustomInformationPageIds
): { [key: string]: string } => {
  if (pageId === CustomInformationPageIds.LeaseEstateGBP) {
    return leaseEstateLabelUnits_GBP;
  }
  if (pageId === CustomInformationPageIds.LeaseEstateEUR) {
    return leaseEstateLabelUnits_EUR;
  }
  return {};
};

const sortCustomInformationFunction = (
  customInformation: CustomInformationFields[],
  orderedFields: string[]
) => {
  return [...customInformation].sort((a, b) => {
    const indexA = orderedFields.indexOf(a.key);
    const indexB = orderedFields.indexOf(b.key);
    return indexA - indexB;
  });
};
export const sortCustomInformation = (
  customInformationData: CustomInformationFields[],
  pageId: CustomInformationPageIds
) => {
  if (pageId === CustomInformationPageIds.LeaseEstateGBP) {
    return sortCustomInformationFunction(
      customInformationData,
      leaseEstateCustomInformationOrdered_GBP
    );
  }
  if (pageId === CustomInformationPageIds.LeaseEstateEUR) {
    return sortCustomInformationFunction(
      customInformationData,
      leaseEstateCustomInformationOrdered_EUR
    );
  }
  if (pageId === CustomInformationPageIds.KennekDemo) {
    return sortCustomInformationFunction(
      customInformationData,
      kennekDemoCustomInformationOrdered
    );
  }

  return [];
};

export const formatKeyIntoLabel = (
  key: string,
  pageId: CustomInformationPageIds
) => {
  const unit = customInformationLabelUnits(pageId)[key];

  if (unit) key += ` ${unit}`;
  return (
    key
      // Insert a space before any capital letter that is preceded by a lowercase letter or another capital letter.
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
      // Insert a space before a sequence of digits that is preceded by a letter.
      .replace(/([a-zA-Z])(\d+)/g, '$1 $2')
      .toLowerCase()
      // Capitalize the very first letter and make the rest lowercase.
      .replace(/^./, function (char) {
        return char.toUpperCase();
      })
  );
};
