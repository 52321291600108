import React, { useState } from 'react';
import { Tooltip } from 'ui';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

interface DropBoxLabelProps {
  children?: JSX.Element;
  labelText: string;
  className?: string;
  position?: 'right' | 'left' | 'center' | 'top' | 'custom' | 'top-center';
  customPosition?: string;
}

const TooltipLabel = ({
  children,
  labelText,
  className,
  position = 'right',
  customPosition,
}: DropBoxLabelProps) => {
  return (
    <BooleanWrapper>
      {({ value: isMouseOver, setValue: setMouseIsOver }) => (
        <label
          className={
            className
              ? className
              : 'heading-100 text-neutral-900 pb-1 relative flex'
          }
          onMouseOver={() => setMouseIsOver(true)}
          onMouseLeave={() => setMouseIsOver(false)}
        >
          {labelText}&nbsp;
          {children && (
            <div className="relative flex items-center">
              <QuestionMarkCircleIcon width={14} className="cursor-pointer" />
              {isMouseOver && (
                <Tooltip position={position} customPosition={customPosition}>
                  {children}
                </Tooltip>
              )}
            </div>
          )}
        </label>
      )}
    </BooleanWrapper>
  );
};

export { TooltipLabel };

type BooleanWrapperProps = {
  children: (input: {
    value: boolean;
    setValue: React.Dispatch<React.SetStateAction<boolean>>;
  }) => JSX.Element;
};

const BooleanWrapper = ({ children }: BooleanWrapperProps) => {
  const [value, setValue] = useState(false);
  return children({ value, setValue });
};
