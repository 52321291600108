import React, { FC } from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import Tooltip from './Tooltip';

type TextWithTooltipProps = React.PropsWithChildren<{
  tooltipText?: string;
}>;

const TextWithTooltip: FC<TextWithTooltipProps> = ({
  children,
  tooltipText,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  return (
    <div className="flex">
      {children}
      {tooltipText && (
        <div
          className="flex items-center gap-x-1 ml-2"
          onMouseOver={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <div className="relative flex items-center">
            <QuestionMarkCircleIcon width={14} className="cursor-pointer " />
            {showTooltip && (
              <Tooltip
                position="right"
                customClasses="!w-[400px] whitespace-pre-wrap"
              >
                {tooltipText}
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default TextWithTooltip;
