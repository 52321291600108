const AssetDetail = ({
  title,
  detail,
}: {
  title: string;
  detail: string | number;
}) => {
  return (
    <div className="flex-grow flex flex-col mt-4">
      <span className="heading-200 mr-2 break-all">{detail}</span>
      <span className="heading-100 text-neutral-700">{title}</span>
    </div>
  );
};

export default AssetDetail;
