import React from 'react';
import { IconProps } from '../iconTypes';

const DisburseIcon: React.FC<IconProps> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 9L21 12M21 12L18 15M21 12L15 12"
      stroke="#1E293B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6C3.34315 6 2 7.34315 2 9V15C2 16.6569 3.34315 18 5 18H15C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16H6.44821C6.35654 15.4403 6.09499 14.9027 5.66269 14.4704C5.19624 14.0039 4.60711 13.7362 4 13.6663V10.4668C4.60711 10.3968 5.19624 10.1292 5.66269 9.6627C6.12915 9.19624 6.39681 8.60711 6.46676 8H15C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6H5ZM10.7207 12.4755C10.5069 11.9921 10.7255 11.427 11.2089 11.2132C11.4483 11.1073 11.7057 11.107 11.9337 11.193C12.4504 11.388 13.0274 11.1272 13.2224 10.6105C13.4173 10.0937 13.1565 9.51679 12.6398 9.32182C11.9408 9.05805 11.1385 9.05753 10.4001 9.38406C8.90646 10.0445 8.23107 11.7907 8.89153 13.2843C9.552 14.778 11.2982 15.4534 12.7918 14.7929C13.2969 14.5695 13.5253 13.979 13.302 13.4739C13.0786 12.9688 12.4881 12.7404 11.983 12.9637C11.4996 13.1775 10.9344 12.9589 10.7207 12.4755Z"
      fill="#1E293B"
    />
  </svg>
);

export default DisburseIcon;
