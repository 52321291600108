import React, { useContext } from 'react';
import classNames from 'classnames';
import { ThemeContext } from 'ui/theme';

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  page?: boolean;
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  function Container(props, ref) {
    const { children, className, page, ...other } = props;
    const {
      theme: { container },
    } = useContext(ThemeContext);

    const baseStyle = container.base;
    const pageStyle = container.page;

    const cls = classNames(baseStyle, page && pageStyle, className);

    return (
      <div className={cls} ref={ref} {...other}>
        {children}
      </div>
    );
  }
);

export default Container;
