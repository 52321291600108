import { Footer } from 'kennek/components';
import { SideBar } from 'ui';
import Header from '@/components/common/Header';
import { MetaHeader } from '@/components/common/MetaHeader';
import SnackbarHandler from '@/components/snackbar/SnackbarHandler';
import { env } from '@/env/public';
import { useSideBar } from '@/hooks/useSideBar';
import { useIsUserLoggedIn } from '@/store/user/selectors';
import type { LayoutProps } from '@/types/pages';

const MainLayout: LayoutProps = ({ children }) => {
  const { items, disabledRoute, collapsed, onCollapsedChange, hidden } =
    useSideBar();
  const isUserLoggedIn = useIsUserLoggedIn();

  return (
    <>
      <MetaHeader />
      <div className="min-h-screen">
        <Header sticky />

        <div className="flex grow">
          <SideBar
            items={items}
            disabledRoute={disabledRoute}
            collapsed={collapsed}
            onCollapsedChange={onCollapsedChange}
            hidden={hidden}
            sticky
          />

          <main className="bg-neutral-200 pt-4 px-7 flex flex-col items-center grow min-w-0">
            <div className="grow h-full w-full flex justify-center">
              {children}
            </div>
            <SnackbarHandler sidebarStatus={collapsed} />
            <Footer
              modulrDisclaimerUrl={
                isUserLoggedIn ? env.NEXT_PUBLIC_MODULR_DISCLAIMER_URL : null
              }
              footerMode={isUserLoggedIn ? 'simplified' : 'full'}
            />
          </main>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
