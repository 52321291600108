import { useGetApprovedLoansQuery } from '@/services/loans';
import { useSelectUser } from '@/store/user/selectors';
import { useRole } from './useRole';
import { ApprovedLoansQuery } from '@/interfaces/loans/queries';

export const useGetCurrentUserApprovedLoans = (
  params?: Omit<
    ApprovedLoansQuery,
    'borrowerEmail' | 'originatorEmail' | 'investorEmail'
  >,
  queryOptions?: {
    refetchOnMountOrArgChange?: boolean;
  },
  skip?: boolean
) => {
  const user = useSelectUser();
  const { role } = useRole();
  return useGetApprovedLoansQuery(
    {
      borrowerEmail: role === 'BORROWER' ? user.email : null,
      originatorEmail:
        role === 'ORIGINATOR_ADMIN' || role === 'ORIGINATOR_ANALYST'
          ? user.email
          : null,
      investorEmail: role === 'INVESTOR' ? user.email : null,
      ...(params ? params : {}),
    },
    { skip: !user?.email || skip, ...(queryOptions ? queryOptions : {}) }
  );
};
