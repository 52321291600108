import { rootApi } from '../rootApi';
import type { Deadline, Schedule } from '@/interfaces/schedules';
import type { ScheduleQuery } from '@/interfaces/scheduling';

export const baseSchedulingApi = '/api/scheduling';

export const schedulingApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getSchedules: builder.query<Schedule[], ScheduleQuery>({
      query: ({ borrowerCompanyExternalId, branchEncodedKey, loanId }) => ({
        url: baseSchedulingApi + '/report',
        params: {
          borrowerCompanyExternalId,
          branchEncodedKey,
          loanId,
        },
      }),
      providesTags: ['Scheduling'],
    }),
    createReport: builder.mutation<Schedule, Schedule>({
      query: (schedule: Schedule) => ({
        url: baseSchedulingApi + '/report',
        method: 'POST',
        body: schedule,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Scheduling']),
    }),
    updateMultipleReports: builder.mutation<
      Schedule[],
      Partial<Schedule & { deadline?: Partial<Deadline> }>[]
    >({
      query: (
        schedules: Partial<Schedule & { deadline?: Partial<Deadline> }>[]
      ) => ({
        url: baseSchedulingApi + '/report/update-multiple',
        method: 'PATCH',
        body: schedules,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Scheduling']),
    }),
    deleteReport: builder.mutation<Schedule, string>({
      query: (id) => ({
        url: baseSchedulingApi + `/report/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Scheduling']),
    }),
  }),
});

export const {
  useGetSchedulesQuery,
  useCreateReportMutation,
  useDeleteReportMutation,
  useUpdateMultipleReportsMutation,
} = schedulingApi;

export const { useQuerySubscription: useGetSchedulesQuerySubscription } =
  schedulingApi.endpoints.getSchedules;
