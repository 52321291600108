import * as yup from 'yup';
import { zonedStringDate } from '@/utils/datetime';
import { calculateLoanAmountWithCapFees } from '../utils';
import { LabelsConfig } from 'kennek/interfaces/labelsConfig';
import { Product } from 'kennek/interfaces/products';
import {
  Asset,
  Loan,
  LoanProductRules,
  OccupancyStatus,
  UpdateLoan,
} from '@/interfaces/loans';
import { LoanFacility } from '@/interfaces/loans/facility';

export interface AssetsFormInput {
  borrowerType: string;
  assetType: string;
  assetValuation: string;
  assetDescription: string;
  securityAddress?: string; //only for asset type of type property
  occupancyStatus?: OccupancyStatus; //only for asset type of type property
  loanEncodedKey?: string;
}

export const mapAssetsFormInputToAssetDto = (
  input: AssetsFormInput
): Asset => ({
  assetDescription: input.assetDescription,
  assetType: input.assetType,
  borrowerType: input.borrowerType,
  occupancyStatus: input.occupancyStatus,
  securityAddress: input.securityAddress,
  assetValuation: parseFloat(input.assetValuation),
});

const mapAssetDtoToAssetsFormInput = (asset: Asset): AssetsFormInput => ({
  assetDescription: asset.assetDescription,
  assetType: asset.assetType,
  borrowerType: asset.borrowerType,
  loanEncodedKey: asset.loanEncodedKey,
  occupancyStatus: asset.occupancyStatus,
  securityAddress: asset.securityAddress,
  assetValuation: asset.assetValuation?.toFixed(2) ?? '',
});

export const calculateLoanToValuePercentage = (
  loanAmount: number,
  assetValuation: number
): number => {
  if (loanAmount <= 0) return 0;
  if (assetValuation <= 0) return 0;
  return parseFloat(((loanAmount / assetValuation) * 100).toFixed(2));
};

export interface AssetsFormInputs {
  assets: AssetsFormInput[];
}

export interface AssetToggle {
  [index: number]: boolean;
}

export const isAssetFilled = (
  asset: AssetsFormInput,
  defaultAssetType: string
): boolean => {
  const {
    assetType,
    borrowerType,
    securityAddress,
    occupancyStatus,
    assetValuation,
    assetDescription,
  } = asset;

  return (
    !!assetType ||
    (borrowerType && borrowerType != defaultAssetType) ||
    !!securityAddress ||
    !!occupancyStatus ||
    !!assetValuation ||
    !!assetDescription
  );
};

const lazyValidation = (
  requiredMessage: string,
  defaultAssetType: string,
  nullable: boolean = false
) => {
  return yup.lazy((_, ctx) => {
    const hasAnyValueFilled = isAssetFilled(ctx.parent, defaultAssetType);

    const baseValidator = nullable ? yup.string().nullable() : yup.string();
    if (hasAnyValueFilled) {
      return baseValidator.required(requiredMessage);
    }

    return baseValidator;
  });
};

export const schema = (
  { borrowerUpper }: LabelsConfig,
  defaultAssetType: string
) =>
  yup.object().shape({
    assets: yup.array().of(
      yup.object().shape({
        borrowerType: lazyValidation(
          `${borrowerUpper} Type is required`,
          defaultAssetType
        ),
        assetType: lazyValidation(
          'Asset Type is required',
          defaultAssetType,
          true
        ),
        assetDescription: lazyValidation(
          `Description is required`,
          defaultAssetType
        ),
        securityAddress: lazyValidation(
          'Security address is required',
          defaultAssetType
        ),
        occupancyStatus: lazyValidation(
          'Tenanted status is required',
          defaultAssetType,
          true
        ),
        assetValuation: lazyValidation(
          'Asset valuation is required',
          defaultAssetType
        ),
      })
    ),
  });

export const occupancyStatusOptions = [
  { label: 'Vacant', value: 'VACANT' },
  { label: 'Tenanted', value: 'TENANTED' },
  { label: 'Owner Occupied', value: 'OWNER_OCCUPIED' },
];

export const mapFormInitialValues = (assets: Asset[]): AssetsFormInputs => ({
  assets: assets.map(mapAssetDtoToAssetsFormInput),
});

export const EMPTY_FORM: AssetsFormInputs = {
  assets: [
    {
      borrowerType: '',
      assetType: null,
      assetDescription: '',
      securityAddress: '',
      occupancyStatus: null,
      assetValuation: '',
    },
  ],
};

export const getEmptyAsset = (
  borrowerContactFullName = ''
): AssetsFormInput => ({
  borrowerType: borrowerContactFullName ?? '',
  assetType: null,
  assetDescription: '',
  assetValuation: '',
});

export const loanToUpdateLoanDTO = (
  loan: Loan,
  loanProductRules: LoanProductRules,
  assets: Asset[],
  facilities: LoanFacility[],
  products: Product[]
): UpdateLoan => {
  const hasTranches = loanProductRules?.maxNumberOfTranches > 0;
  const loanFacilities =
    facilities?.map(({ facilityId, amount }) => ({
      facilityId,
      amount,
    })) || [];
  const disbursementDate = loan?.disbursementDetails?.expectedDisbursementDate
    ? zonedStringDate(loan?.disbursementDetails?.expectedDisbursementDate)
    : undefined;
  const selectedProduct = products?.find(
    (p) => p.encodedKey === loan?.productTypeKey
  );
  return {
    id: loan.encodedKey,
    accountHolderKey: loan.accountHolderKey,
    assignedBranchKey: loan.assignedBranchKey,
    assignedCentreKey: loan.assignedCentreKey,
    disbursementDate: hasTranches ? undefined : disbursementDate,
    externalId: loan.externalId,
    fees: loan.disbursementDetails?.fees,
    firstRepaymentDate: loan.firstRepaymentDate
      ? zonedStringDate(loan.firstRepaymentDate)
      : undefined,
    graceAmount: loan.graceAmount,
    gracePeriod: loan.gracePeriod,
    interestRate:
      loan.interestSettings?.interestSpread ??
      loan.interestSettings?.interestRate,
    defaultInterestRate: loan.defaultInterestRate,
    loanName: loan.loanName,
    loanAmount: calculateLoanAmountWithCapFees(
      loan,
      selectedProduct?.feesSettings?.fees
    ),
    paymentHoliday: loan.paymentHoliday,
    rollUpDuration: loan.rollUpDuration,
    paymentPlan: loan.paymentPlan,
    penaltyRate: loan.penaltyRate,
    interestGraceInstallments:
      loan?.interestSettings?.interestGraceInstallments,
    periodicPayment: loan.scheduleSettings.periodicPaymentAmount,
    balloonPaymentAmount: loan.scheduleSettings.balloonPaymentAmount,
    productTypeKey: loan.productTypeKey,
    repaymentInstallments: loan.scheduleSettings?.repaymentInstallments,
    payCurrentInstallment: loan.payCurrentInstallment,
    minimumInterestPeriod: loan.minimumInterestPeriod,
    repaymentPeriodCount: loan.scheduleSettings?.repaymentPeriodCount,
    repaymentPeriodUnits: loan.scheduleSettings?.repaymentPeriodUnits,
    tranches: loan.tranches,
    netLoanCalculationMethod: loan.netLoanCalculationMethod,
    ltv: loan.ltv,
    gdv: loan.gdv,
    ltc: loan.ltc,
    gdc: loan.gdc,
    interestGraceHandleMethod:
      loan?.interestSettings?.interestGraceHandleMethod,
    exitFeeAmount: loan.exitFeeAmount,
    factorAmount: loan.factorAmount,
    interestCapitalisationFrequency:
      loan?.interestSettings?.interestCapitalisationFrequency,
    loanFacilities,
    monthlyRepaymentDay: loan.scheduleSettings?.monthlyRepaymentDay,
    assets,
    feeSettings: loan.feeSettings,
    interestCeiling: loan?.interestSettings?.interestCeiling,
    interestFloor: loan?.interestSettings?.interestFloor,
    useInitialInterestRateAsCeiling:
      loan?.interestSettings?.initialInterestRateAsCeiling,
    useInitialInterestRateAsFloor:
      loan?.interestSettings?.initialInterestRateAsFloor,
  };
};
