import { Company } from '../originator';

interface BaseInvestor {
  active?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  _id?: string;
}

export interface OriginatorInvestor extends BaseInvestor {
  details?: InvestorDetails;
  default?: boolean;
  originatorEncodedKey?: string;
  users?: string[];
}

export interface InvestorDetails {
  address?: InvestorAddress;
  companyName?: string;
  companyNumber?: string;
  contactFirstName?: string;
  contactLastName?: string;
  email?: string;
  firstName?: string;
  groupName?: string;
  lastName?: string;
  nameOfContracting?: string;
  phone?: string;
  tradingName?: string;
  type?: InvestorType;
}

export type InvestorType =
  | 'COMPANY'
  | 'FAMILY_OFFICE'
  | 'INDIVIDUAL'
  | 'SOLE_TRADER'
  | 'PARTNERSHIP'
  | 'GROUP'
  | '';

export enum InvestmentType {
  SENIOR = 'SENIOR',
  MEZZANINE = 'MEZZANINE',
  JUNIOR = 'JUNIOR',
  NONE = 'NONE',
}

export interface InvestorAddress {
  line1?: string;
  line2?: string;
  postTown?: string;
  postCode?: string;
  countryCode?: string;
}
export interface InvestorForm {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  companyName?: string;
  companyNumber?: number;
  contactFirstName?: string;
  contactLastName?: string;
  country?: string;
  email?: string;
  firstName?: string;
  groupName?: string;
  lastName?: string;
  investorType: InvestorType;
  nameOfContracting?: string;
  phone?: number;
  postalCode?: string;
  tradingName?: string;
  sicCode?: number;
}

export interface InvestorRow {
  amount?: number;
  percentage?: number;
  amountString?: string;
  investorInterest?: number;
  investorInterestString?: string;
  investmentId?: string;
  investorId?: OriginatorInvestor['_id'];
  investorName?: InvestorDetails['firstName'];
  lenderOfRecordId?: LenderOfRecord['_id'];
  selectId: string;
  rowLoaded?: boolean;
  investmentType?: InvestmentType;
}

export interface InvestorMutationPayload {
  address?: InvestorAddress;
  company?: Company;
  contactFirstName?: string;
  contactLastName?: string;
  email?: string;
  firstName?: string;
  groupName?: string;
  lastName?: string;
  mobilePhone?: string;
  nameOfContracting?: string;
  tradingName?: string;
  usersId?: string[];
  type?: InvestorType;
  default?: boolean;
  originatorEmail?: string;
}

export interface InvestorMutationResponse extends BaseInvestor {
  company?: Company;
  details?: InvestorDetails;
  originatorEncodedKey: OriginatorInvestor['originatorEncodedKey'];
  users?: string[];
  default?: boolean;
}

export interface LenderOfRecord extends BaseInvestor {
  jurisdiction?: string;
  name?: string;
  default?: boolean;
  originatorEncodedKey?: OriginatorInvestor['originatorEncodedKey'];
}

export interface LenderOfRecordOptions {
  value: LenderOfRecord['_id'];
  label: LenderOfRecord['name'];
}

export interface LenderOfRecordForm {
  jurisdiction: string;
  name: string;
}
export interface LenderOfRecordMutationPayload extends LenderOfRecordForm {
  originatorEncodedKey: OriginatorInvestor['originatorEncodedKey'];
  default?: boolean;
}

export interface GetLenderOfRecordsParams {
  id?: LenderOfRecord['_id'];
  originatorEncodedKey?: OriginatorInvestor['originatorEncodedKey'];
}
export interface GetOriginatorParams {
  brachEncodedKey?: string;
}
