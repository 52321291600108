import { ProductComponentVisibility } from '@/hooks/useProductComponentConfiguration';
import { useRole } from '@/hooks/useRole';
import { formatAmount, formatPercent } from '@/utils/formatters';
import SummaryDetail from './Summary';
import { type LoanSummary } from '@/interfaces/loans/queries';

interface InvestorDetailsProps {
  loanSummary: LoanSummary;
  loanDetailsConfig?: ProductComponentVisibility;
}
export const InvestorDetails = ({
  loanSummary,
  loanDetailsConfig,
}: InvestorDetailsProps) => {
  const { role } = useRole();
  if (role !== 'INVESTOR') return;
  const investedAmount =
    loanSummary.investedAmount !== undefined
      ? formatAmount(loanSummary.investedAmount, true, loanSummary?.currency)
      : 0;
  return (
    <>
      <SummaryDetail
        title="Invested amount"
        detail={formatAmount(investedAmount, true, loanSummary?.currency)}
        show={loanDetailsConfig?.showInvestedAmount}
      />
      <SummaryDetail
        title="Investor interest"
        detail={formatPercent(loanSummary?.investorInterest, 3)}
        show={
          loanDetailsConfig?.showInvestorInterestCommission &&
          loanSummary?.investorInterest !== undefined
        }
      />
    </>
  );
};
