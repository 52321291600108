import { ApprovedLoan } from '@/interfaces/loans/queries';

/**
 * Filter loans locally, since the backend doesn't seem to support searching
 * loans by name or partial ID.
 */
export const filterLoans = (
  loans: ApprovedLoan[] | undefined,
  borrowerEmail: string | undefined,
  searchTerm: string
): ApprovedLoan[] | undefined => {
  if (!borrowerEmail) return;
  if (!loans) return;
  return loans.filter(
    (loan) =>
      loan.borrowerEmail === borrowerEmail &&
      !loan.groupId &&
      (loan.id?.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
        loan.name?.toLowerCase().includes(searchTerm.trim().toLowerCase()))
  );
};
