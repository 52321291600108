import { SelectOption } from 'ui';
import { useGetLenderOfRecordQuery } from '@/services/accounts/originator';

export const useOriginatorLenderOfRecordsOptions = (
  originatorEncodedKey: string | undefined
) => {
  const { data: lendersOfRecords = [], isLoading } = useGetLenderOfRecordQuery(
    { originatorEncodedKey },
    { skip: !originatorEncodedKey }
  );

  const lenderOfRecordOptions: SelectOption[] = lendersOfRecords
    .filter((item) => item?.default === false)
    .map((o) => ({
      value: o?._id,
      label: o?.name,
    }));

  return {
    lenderOfRecordOptions,
    isLoading,
  };
};
