import { ReactElement } from 'react';
import classNames from 'classnames';
import { formatAmount } from '@/utils/formatters';
import { Currencies } from 'kennek/interfaces/loans';

interface InvestmentsAmountDetailsProps {
  total: number;
  currency: Currencies;
  amountItems?: ReactElement<ListItemProps>[];
}

const InvestmentsAmountDetailsDialog = ({
  total,
  currency,
  amountItems = [],
}: InvestmentsAmountDetailsProps) => {
  return (
    <div className="relative w-[543px]">
      <div className="heading-400  text-left absolute -top-4 left-0">
        Amount to be funded
      </div>
      <div className="bg-neutral-100 rounded-lg w-full mt-7 -mb-3 p-4">
        <div
          className={classNames(
            'flex flex-col heading-200 text-neutral-800 border-b-neutral-400 border-b',
            amountItems?.length > 0 ? 'pb-5' : 'pb-2'
          )}
        >
          {amountItems.filter((amountItem) => amountItem.props.visible)}
        </div>

        <div className="flex justify-between mt-5">
          <span className="heading-300">Total Amount</span>
          <span className="heading-500">
            {formatAmount(total, true, currency)}
          </span>
        </div>
      </div>
    </div>
  );
};

interface ListItemProps {
  title: string;
  amount: number;
  currency: Currencies;
  negative?: boolean;
  visible: boolean;
}

const ListItem = ({
  title,
  amount,
  currency,
  negative = false,
}: ListItemProps) => (
  <div className="flex justify-between my-1">
    <span>{title}</span>
    <span>{formatAmount(negative ? amount * -1 : amount, true, currency)}</span>
  </div>
);

const InvestmentsAmountDetails = {
  Dialog: InvestmentsAmountDetailsDialog,
  ListItem,
};

export { InvestmentsAmountDetails };
