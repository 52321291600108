import { GetConfigurationProductResponse } from 'kennek/interfaces/kennek/queries';
import { LoanProductRules } from '@/interfaces/loans';

/**
 * Check whether a given loan product configuration is for a tranche-based loan.
 */
export const productHasTranches = (
  configurationProductData: GetConfigurationProductResponse,
  loanProductRules: LoanProductRules
): boolean =>
  !configurationProductData?.otherConfigurations?.revenueBasedFactorLoan &&
  loanProductRules?.maxNumberOfTranches > 0;
