/**
 * Alert v.01
 * Check the latest version at:
 *  https://mate-ui.truenorth.co
 */
import React, { forwardRef, useContext } from 'react';
import classNames from 'classnames';
import { ThemeContext } from 'ui/theme';
import { Severity } from '../types';

export interface BadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * The type of the badge
   */
  type?: Severity;
}

const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  function Badge(props, ref) {
    const { className, children, type = 'primary', ...other } = props;

    const {
      theme: { badge },
    } = useContext(ThemeContext);

    const baseStyle = badge.base;
    const typeStyle = {
      success: badge.success,
      error: badge.error,
      warning: badge.warning,
      info: badge.info,
      neutral: badge.neutral,
      primary: badge.primary,
      secondary: badge.secondary,
    };

    const cls = classNames(baseStyle, typeStyle[type], className);

    return (
      <span className={cls} ref={ref} {...other}>
        {children}
      </span>
    );
  }
);

export default Badge;
