import { forwardRef } from 'react';
import classNames from 'classnames';
import { Severity } from '../types';

interface BarProps {
  severity?: Severity;
  height?: string;
  percentage: number;
}

const ProgressBar = forwardRef<HTMLDivElement, BarProps>(
  function ProgressBar(props, ref) {
    const { percentage, severity = 'success', height = 'h-2.5' } = props;

    const progressWidth = getProgress(percentage);

    return (
      <div className={classNames('w-full bg-neutral-200 rounded-full', height)}>
        <div
          ref={ref}
          className={classNames('rounded-full', bgColor[severity], height)}
          style={{ width: `${progressWidth}%` }}
        ></div>
      </div>
    );
  }
);

export default ProgressBar;

const bgColor: Record<Severity, string> = {
  primary: 'bg-primary-500',
  secondary: 'bg-secondary-500',
  success: 'bg-success-500',
  error: 'bg-error-500',
  info: 'bg-info-500',
  warning: 'bg-warning-500',
  neutral: 'bg-neutral-500',
};

const getProgress = (percentage: number) => {
  if (percentage > 100) return 100;
  if (percentage < 0) return 0;
  return percentage;
};
