import React from 'react';
import { IconProps } from '../iconTypes';

const MoneyIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 14"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M3.9669 0.331983C4.10854 0.143129 4.33083 0.031983 4.5669 0.0319831L19.5669 0.0319857C19.8358 0.0319857 20.0841 0.175954 20.2178 0.409318C20.3514 0.642683 20.3498 0.929723 20.2137 1.16164L17.8659 5.16164C17.7312 5.39106 17.4851 5.53199 17.2191 5.53199L1.5669 5.53198C1.28282 5.53198 1.02312 5.37148 0.896078 5.11739C0.769034 4.8633 0.796451 4.55925 0.966898 4.33198L3.9669 0.331983ZM4.9419 1.53198L3.0669 4.03198L16.7896 4.03199L18.257 1.53199L4.9419 1.53198Z"
      fill="#334155"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M18.5469 2.03199L19.5669 2.03199C19.8235 2.03199 20.0624 2.16322 20.2 2.37986C20.3376 2.59649 20.3549 2.86845 20.2458 3.10076L17.898 8.10076C17.7744 8.36394 17.5098 8.53199 17.2191 8.53199L1.5669 8.53198C1.29835 8.53198 1.05029 8.3884 0.916541 8.15553C0.782787 7.92266 0.783749 7.63605 0.919063 7.40408L2.58573 4.54694L3.8814 5.30274L2.87268 7.03198L16.7427 7.03199L18.5469 3.18967L18.5469 2.03199Z"
      fill="#334155"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M18.5469 5.03199L19.5669 5.03199C19.8235 5.03199 20.0624 5.16322 20.2 5.37986C20.3376 5.59649 20.3549 5.86845 20.2458 6.10076L17.898 11.1008C17.7744 11.3639 17.5098 11.532 17.2191 11.532L1.5669 11.532C1.29835 11.532 1.05029 11.3884 0.91654 11.1555C0.782787 10.9227 0.783749 10.636 0.919063 10.4041L2.58573 7.54694L3.8814 8.30274L2.87267 10.032L16.7427 10.032L18.5469 6.18967L18.5469 5.03199Z"
      fill="#334155"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M11.1424 3.85384C12.3111 3.8469 13.2559 3.38789 13.2526 2.8286C13.2493 2.26932 12.2991 1.82156 11.1304 1.8285C9.96162 1.83544 9.01686 2.29445 9.02018 2.85374C9.0235 3.41302 9.97364 3.86078 11.1424 3.85384Z"
      fill="#334155"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M17.3452 2.33403C18.0788 2.65697 18.4904 2.62662 18.8193 2.58818L19.9301 0.723717L17.3452 0.723823C16.5917 1.05947 16.4179 1.9258 17.3452 2.33403Z"
      fill="#334155"
    />
    <path
      d="M4.68713 3.48413C3.95321 3.162 3.54166 3.1928 3.21284 3.23161L2.10404 5.09729L4.6889 5.09434C5.44202 4.75787 5.6149 3.89135 4.68713 3.48413Z"
      fill="#334155"
    />
    <path
      d="M6.30592 1.81597C6.98296 1.47745 7.32148 0.800418 7.32179 0.176728L5.14334 0.176883L3.93644 1.81602C4.61334 2.15448 5.39969 2.26909 6.30592 1.81597Z"
      fill="#334155"
    />
    <path
      d="M15.805 3.81911C15.1782 4.24344 15.1586 4.63371 15.24 5.25206L17.3997 4.96641L18.154 3.50853C17.4386 3.2617 16.644 3.25114 15.805 3.81911Z"
      fill="#334155"
    />
  </svg>
);

export default MoneyIcon;
