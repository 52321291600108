import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { TableContext } from './TableContext';

const Button = ({
  children,
  isActive,
  onClick,
}: {
  children: React.ReactNode;
  isActive: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <button
      className={classNames(
        'flex flex-row justify-center items-center rounded px-2 py-1 h-8 body-400',
        { 'bg-neutral-400': isActive, 'hover:bg-neutral-300': !isActive }
      )}
      onClick={onClick}
    >
      <span className="body-400">{children}</span>
    </button>
  );
};

const ItemsCounter = ({
  currentPage,
  totalPages,
  totalItems,
}: {
  currentPage: number;
  totalPages: number;
  totalItems: number;
}) => {
  return (
    <span className="body-400 h-8 w-44 flex items-center cursor-default justify-end justify-self-end">
      {`Page ${currentPage + 1} of ${totalPages} (${totalItems} items)`}
    </span>
  );
};

const PageSelector = ({
  currentPage,
  totalPages,
  onSetPage,
  onPrevPage,
  onNextPage,
  canPreviousPage,
  canNextPage,
}: {
  currentPage: number;
  totalPages: number;
  onSetPage: (page: number) => void;
  onPrevPage: () => void;
  onNextPage: () => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
}) => {
  return (
    <div className="flex gap-2 items-center justify-self-center">
      {totalPages > 0 && (
        <button
          className="h-8 cursor-pointer hover:bg-neutral-300 disabled:bg-neutral-100 disabled:cursor-auto"
          onClick={onPrevPage}
          disabled={!canPreviousPage}
        >
          <ChevronLeftIcon height={18} width={20} />
        </button>
      )}

      {[...Array(totalPages)].map((_, pageIndex) => {
        const visiblePages = [
          0, // first page
          totalPages - 1, // last page
          currentPage - 1, // previous page
          currentPage,
          currentPage + 1, // next page
        ];

        const dottedPages = [currentPage - 2, currentPage + 2];

        if (visiblePages.includes(pageIndex))
          return (
            <Button
              key={`pageOpts-${pageIndex}`}
              isActive={currentPage === pageIndex}
              onClick={() => onSetPage(pageIndex)}
            >
              {pageIndex + 1}
            </Button>
          );

        if (dottedPages.includes(pageIndex))
          return (
            <span
              className="cursor-default px-2 py-1 body-400"
              key={`pageOpts-${pageIndex}`}
            >
              . . .
            </span>
          );

        return null;
      })}

      {totalPages > 0 && (
        <button
          className="h-8 cursor-pointer hover:bg-neutral-300 disabled:bg-neutral-100 disabled:cursor-auto"
          onClick={onNextPage}
          disabled={!canNextPage}
        >
          <ChevronRightIcon height={18} width={20} />
        </button>
      )}
    </div>
  );
};

const Resizer = ({
  pagesSizes,
  currentPageSize,
  onChangePageSize,
}: {
  pagesSizes: number[];
  currentPageSize: number;
  onChangePageSize: (pageSize: number) => void;
}) => {
  return (
    <div className="flex gap-2 justify-self-start">
      {pagesSizes.map((pageSize) => (
        <Button
          key={pageSize}
          isActive={currentPageSize === pageSize}
          onClick={() => onChangePageSize(pageSize)}
        >
          {pageSize}
        </Button>
      ))}
    </div>
  );
};

type PageSize = (typeof PAGE_SIZES)[number];

type PaginationProps = {
  currentPage?: number;
  onChangeCurrentPage?: (value: number | ((value: number) => number)) => void;
  pageSize?: PageSize;
  onChangePageSize?: (value: number) => void;
  totalItems?: number;
};

const Pagination = (props: PaginationProps) => {
  const {
    pagination: contextPagination,
    isLoading,
    setHasPagination,
    setInitialPageSize,
  } = useContext(TableContext) ?? {};

  // TODO: move pagination to server side
  const {
    currentPage = 0,
    pageSize = 1,
    totalItems = 0,
    onChangeCurrentPage = () => {},
    onChangePageSize: onChangePageSizeProps = () => {},
  } = contextPagination
    ? contextPagination
    : (props as Required<PaginationProps>);

  const totalPages = Math.ceil(totalItems / pageSize);
  const onSetPage = (value: number) => onChangeCurrentPage(value);
  const onPrevPage = () => onChangeCurrentPage((v) => v - 1);
  const onNextPage = () => onChangeCurrentPage((v) => v + 1);
  const canPreviousPage = currentPage > 0;
  const canNextPage = currentPage < totalPages - 1;
  const onChangePageSize = (newPageSize: number) => {
    onChangePageSizeProps(newPageSize);
    onChangeCurrentPage(Math.floor((pageSize / newPageSize) * currentPage));
  };

  const pagesSizes = PAGE_SIZES.filter(
    (pageSize) => totalItems > pageSize - 10
  );

  useEffect(() => {
    setHasPagination?.();
    setInitialPageSize?.(PAGE_SIZES[0]);
  }, [setHasPagination, setInitialPageSize]);

  useEffect(() => {
    if (!props?.pageSize) return;
    setInitialPageSize?.(props.pageSize);
  }, [setInitialPageSize, props?.pageSize]);

  if (!totalItems) return null;

  if (isLoading) return null;

  return (
    <div
      className="bg-neutral-100 px-3 py-3 grid grid-cols-3 justify-between rounded-br-lg rounded-bl-lg"
      data-testid="table-pagination"
    >
      <Resizer
        currentPageSize={pageSize}
        onChangePageSize={onChangePageSize}
        pagesSizes={pagesSizes}
      />

      <PageSelector
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        currentPage={currentPage}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onSetPage={onSetPage}
        totalPages={totalPages}
      />

      <ItemsCounter
        currentPage={currentPage}
        totalPages={totalPages}
        totalItems={totalItems}
      />
    </div>
  );
};

const PAGE_SIZES = [10, 20, 30] as const;

export { Pagination, PAGE_SIZES };

export type { PaginationProps, PageSize };
