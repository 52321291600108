import React, { createContext } from 'react';
import { UseStepperControlReturn } from './useStepperControl';

type RootProps = {
  control: UseStepperControlReturn;
  children: React.ReactNode;
  disableSkip?: boolean;
};

type RootContext = Omit<RootProps, 'children'>;

const RootContext = createContext<RootContext>({} as RootContext);

const Root = ({ children, ...props }: RootProps) => {
  return <RootContext.Provider value={props}>{children}</RootContext.Provider>;
};

export { Root, RootContext };

export type { RootProps };
