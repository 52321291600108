import React from 'react';
import { Control, Controller } from 'react-hook-form';
import FormInput from 'ui/components/FormInput';
import { cn } from 'ui/utils/cn';
import { isEmpty, isEmptyString } from '@/utils/validation';
import { InvestmentsFormData } from '../InvestmentsForm.types';

type InvestmentsFormInterestRateInputProps = {
  control: Control<InvestmentsFormData, unknown>;
  fixedInterestRate: number | null;
  index: number;
  error?: string;
  disabled?: boolean;
};

export const InvestmentsFormInterestRateInput: React.FC<
  InvestmentsFormInterestRateInputProps
> = (props) => {
  return (
    <Controller
      control={props.control}
      name={`investments.${props.index}.interestRate`}
      rules={{
        validate: {
          required: (value: string) => {
            if (props.fixedInterestRate) {
              return;
            }

            if (!isEmpty(value) && !isEmptyString(value)) {
              return;
            }

            return 'Interest rate is required.';
          },
          min: (value: string) => {
            if (parseFloat(value) >= 0) {
              return;
            }

            return 'Interest rate cannot be less than 0%';
          },
        },
      }}
      render={({ field }) => (
        <FormInput
          {...field}
          leftSymbol="%"
          customLeftPadding="7"
          type="decimalNumber"
          containerClassName={cn('w-[94px] mt-[0px]', {
            'mb-[24px]': props.error,
          })}
          errors={props.error}
          disabled={props.disabled}
        />
      )}
    />
  );
};
