import type { LoanTableTabs } from 'pages/servicing/loans';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TasksTableTabs } from '@/components/sections/tasks/tasksSection';
import { User } from 'kennek/interfaces/accounts';
import type { SideBarStatus } from 'ui/types';
import type { Notification } from '@/interfaces/notifications';

export interface UserState {
  data: User;
  notifications: {
    status: 'loading' | 'done';
    data: Notification[];
  };
  preferences: {
    sidebar: SideBarStatus | null;
    loanTable: LoanTableTabs;
    tasksTable: TasksTableTabs;
  };
  keys: {
    codatKey: string;
  };
  uiSettings: {
    isMainPrimaryColorSet: boolean;
    anonymousLogo?: string;
  };
  anonymousBranchEncodedKey?: string;
  hideKennekBranding: boolean;
}

const initialData = {
  _id: '',
  active: false,
  documents: [],
  mambuUser: [],
  roles: [],
  cognitoId: '',
  email: '',
  userName: '',
  createdAt: '',
  updatedAt: '',
  phone: '',
  enabled2fa: false,
  company: { name: '' },
  borrowerCompanyIds: [],
};

const initialState: UserState = {
  data: initialData,
  notifications: { status: 'loading', data: [] },
  preferences: {
    sidebar: null,
    loanTable: 'active-loans',
    tasksTable: 'open',
  },
  keys: {
    codatKey: '',
  },
  uiSettings: {
    isMainPrimaryColorSet: false,
  },
  anonymousBranchEncodedKey: null,
  hideKennekBranding: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>): void {
      state.data = action.payload;
    },
    setPhoneVerified(state, action: PayloadAction<boolean>): void {
      state.data.phoneVerified = action.payload;
    },
    onSetSideBarPreference(
      state,
      action: PayloadAction<SideBarStatus | null>
    ): void {
      state.preferences.sidebar = action.payload;
    },
    onLoanTableChanged(state, action: PayloadAction<LoanTableTabs>): void {
      state.preferences.loanTable = action.payload;
    },
    onTasksTableChanged(state, action: PayloadAction<TasksTableTabs>) {
      state.preferences.tasksTable = action.payload;
    },
    setCodatKey(state, action: PayloadAction<string>) {
      state.keys.codatKey = action.payload;
    },
    setUiSettingsMainPrimaryColor(state, action: PayloadAction<boolean>) {
      state.uiSettings.isMainPrimaryColorSet = action.payload;
    },
    setHideKennekBranding(state, action: PayloadAction<boolean>) {
      state.hideKennekBranding = action.payload;
    },
    setAnonymousLogo(state, action: PayloadAction<string>) {
      state.uiSettings.anonymousLogo = action.payload;
    },
    setAnonymousBranchEncodedKey(state, action: PayloadAction<string>) {
      state.anonymousBranchEncodedKey = action.payload;
    },
  },
});

export const {
  setUser,
  setPhoneVerified,
  onSetSideBarPreference,
  onLoanTableChanged,
  onTasksTableChanged,
  setCodatKey,
  setUiSettingsMainPrimaryColor,
  setHideKennekBranding,
  setAnonymousLogo,
  setAnonymousBranchEncodedKey,
} = userSlice.actions;

export default userSlice.reducer;
