export type ModuleBaseConfig = {
  name: string;
  detailedDob?: boolean;
  editable?: boolean;
};

export enum UAAS_PAGE_TITLE {
  DEFAULT = 'Complete your loan application',
  BORROWER_PAGE_EDIT_BY_ORIGINATOR = 'Edit loan application',
  DASHBOARD = 'Dashboard',
  REVIEW = 'Review details',
  SUBTITLE = 'Request additional information or documentation to the borrower.',
}

export type DefaultFileTypes =
  | 'pdf'
  | 'jpg'
  | 'jpeg'
  | 'csv'
  | 'doc'
  | 'xls'
  | 'docx'
  | 'xlsx'
  | 'png';

export type DefaultInputTypes =
  | 'text'
  | 'number'
  | 'date'
  | 'select'
  | 'email'
  | 'checkbox'
  | 'ratio'
  | 'file';

export const PAGE_WAITING_ID = '00000000-0000-0000-0000-000000000000';
