import React from 'react';
import { IconProps } from '../iconTypes';

const SendIcon: React.FC<IconProps> = (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="48" height="48" rx="24" fill="#ECF5EE" />
    <path
      d="M24 31L33 33L24 15L15 33L24 31ZM24 31V23"
      stroke="#5FB164"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SendIcon;
