import { rootApi } from '../rootApi';
import {
  Company,
  CompanyByIdQueryResponse,
} from 'kennek/interfaces/originator';

export const baseOriginatorApi = '/api/originator';

export const originatorApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getCompany: builder.query<CompanyByIdQueryResponse[], string>({
      query: (filter) => ({
        url: `${baseOriginatorApi}/search-company`,
        params: {
          q: filter,
        },
      }),
      providesTags: ['Companies'],
    }),
    getCompanyById: builder.query<CompanyByIdQueryResponse, Company['number']>({
      query: (id) => ({
        url: `${baseOriginatorApi}/company-details`,
        params: {
          companyNumber: id,
        },
      }),
      providesTags: ['Companies'],
    }),
  }),
});

export const {
  useGetCompanyQuery,
  useGetCompanyByIdQuery,
  useLazyGetCompanyByIdQuery,
} = originatorApi;

export const { useQuerySubscription: useGetCompanyByIdQuerySubscription } =
  originatorApi.endpoints.getCompanyById;
