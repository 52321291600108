import { useMemo, useState } from 'react';
import { countryList } from '@/constants/countryList';
import { UK_PREFIX } from '@/constants/numeric';

const usePhonePrefixes = (defaultPrefix = UK_PREFIX) => {
  const [selectedPrefix, setSelectedPrefix] = useState(defaultPrefix);

  const phonePrefixOptions = useMemo(() => {
    return countryList.map(({ countryName, prefix, shortName }) => {
      return {
        label: shortName,
        value: prefix,
        additionalSearchValue: countryName,
      };
    });
  }, []);

  const phoneMinLength = useMemo(() => {
    const countryData = countryList.find((x) => x.prefix === selectedPrefix);
    return countryData?.phoneLengthMin ?? null;
  }, [selectedPrefix]);

  const phoneMaxLength = useMemo(() => {
    const countryData = countryList.find((x) => x.prefix === selectedPrefix);
    return countryData?.phoneLengthMax ?? null;
  }, [selectedPrefix]);

  return {
    phonePrefixOptions,
    selectedPrefix,
    setSelectedPrefix,
    phoneMaxLength,
    phoneMinLength,
  };
};

export { usePhonePrefixes };
