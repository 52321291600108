export const LOGIN = {
  EMAIL_REQUIRED: 'Email cannot be empty',
  EMAIL_WRONG_FORMAT: 'Enter correct email',
  EMAIL_MAX_CHARACTERS: (max: number) =>
    `Can not contain more than ${max} characters`,
  PASSWORD_REQUIRED: 'Password cannot be empty',
  PASSWORD_COMPOSITION:
    'Password must contain at least one:\n uppercase character (A-Z), lowercase\n character (a-z), digit (0-9), special\n character (~!@#$%^&*_-+=`|\\(){}[]:;\'"<>,.?/)',
  PASSWORD_MAX_CHARACTERS: (max: number) =>
    `Can not contain more than ${max} characters`,
  PASSWORD_MIN_CHARACTERS: (min: number) =>
    `Must contain at least ${min} characters`,
  PASSWORD_REPEAT_REQUIRED: 'Repeat password',
  PASSWORD_MISMATCH: "Passwords doesn't match",
  MFA_FAILED: 'The code is invalid',
  MFA_REQUIRED: 'Verification code cannot be empty',
};
