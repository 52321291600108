import React, { useEffect, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

type ExpandableContainerProps = {
  expanded?: boolean;
  title: React.ReactNode;
  children: React.ReactNode;
};

export const ExpandableContainer: React.FC<ExpandableContainerProps> = (
  props
) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState<boolean>(props.expanded ?? false);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    if (expanded) {
      container.style.height = `${container.scrollHeight}px`;
    } else {
      container.style.height = `0px`;
    }
  }, [expanded]);

  return (
    <div className="px-4 py-4">
      <div
        className="flex justify-between cursor-pointer"
        onClick={() => setExpanded((expanded) => !expanded)}
      >
        <div className="flex-1">{props.title}</div>
        <div>
          {expanded ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>
      </div>
      <div className="overflow-hidden transition-all" ref={containerRef}>
        {props.children}
      </div>
    </div>
  );
};
