export const FREQUENCY_LABELS = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  ANNUAL: 'Annual',
};

export const SCHEDULE_ANCHOR_LABELS = {
  DISBURSEMENT_DATE: (loanUpper: string) => `${loanUpper} disbursement date`,
  MATURITY_DATE: 'Maturity date',
  CUSTOM_DATE: 'Custom date',
};

export const ANNUAL_CALCULATION_DATES = {
  PRE_FINANCIAL_YEAR_END: 'Before the end of the financial year',
  POST_FINANCIAL_YEAR_END: 'After the end of the financial year',
};

export const ANNUAL_CALCULATION_DATES_SHORT = {
  PRE_FINANCIAL_YEAR_END: 'Pre Financial Year End',
  POST_FINANCIAL_YEAR_END: 'Post Financial Year End',
};

export const MONTHLY_CALCULATION_DATES = {
  PRE_MONTH_END: 'Before the month end',
  POST_MONTH_END: 'After the month end',
};
