import { LoanInvestment } from '@/interfaces/loans/queries';

export const getDefaultInvestorName = (defaultInvestor: LoanInvestment) => {
  const investorDetails = defaultInvestor?.investor?.details;
  if (investorDetails?.type === 'INDIVIDUAL') {
    return `${investorDetails.firstName} ${investorDetails.lastName}`;
  } else if (investorDetails?.type === 'COMPANY') {
    return investorDetails?.companyName;
  }
  return investorDetails?.groupName ?? 'Default investor';
};
