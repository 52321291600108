import { ProductComponentVisibility } from '@/hooks/useProductComponentConfiguration';
import { formatAmount, formatPercent } from '@/utils/formatters';
import SummaryDetail from './Summary';
import { type LoanSummary } from '@/interfaces/loans/queries';

interface CalculationMethodDetailsProps {
  loanSummary: LoanSummary;
  loanDetailsConfig?: ProductComponentVisibility;
}
export const CalculationMethodDetails = ({
  loanSummary,
  loanDetailsConfig,
}: CalculationMethodDetailsProps) => {
  if (
    loanSummary?.netLoanCalculationMethod === 'STATED' ||
    !loanSummary?.netLoanCalculationMethod
  )
    return;
  const isNextCalculationMethodLTV =
    loanSummary?.netLoanCalculationMethod === 'LTV';
  return (
    <>
      {loanSummary.ltv !== undefined && ( // It's idiotic, but better doesn't understand that it's enough to get it on the show
        <SummaryDetail
          title={isNextCalculationMethodLTV ? 'LTV' : 'LTC'}
          detail={formatPercent(loanSummary.ltv, 3)}
          show={!isNextCalculationMethodLTV || loanDetailsConfig?.showLtv}
        />
      )}
      {loanSummary.gdv !== undefined && (
        <SummaryDetail
          title={isNextCalculationMethodLTV ? 'GDV' : 'GDC'}
          detail={formatAmount(loanSummary.gdv, true, loanSummary.currency)}
          show={!isNextCalculationMethodLTV || loanDetailsConfig?.showLtv}
        />
      )}
    </>
  );
};
