import React from 'react';
import { useDispatch } from 'react-redux';
import { SnackBar, SnackBarContainer } from 'ui';
import { removeSnackbar } from '@/store/alerts';
import { useSelectSnackBar } from '@/store/alerts/selectors';
import type { SideBarStatus } from 'ui/types';

const SnackbarHandler = ({
  sidebarStatus,
}: {
  sidebarStatus?: SideBarStatus;
}) => {
  const dispatch = useDispatch();
  const snackbar = useSelectSnackBar();

  return (
    <SnackBarContainer
      xPosition="left"
      yPosition="bottom"
      sidebarStatus={sidebarStatus}
      open={!!snackbar.length}
    >
      <>
        {snackbar.map((snackbar) => {
          return (
            <SnackBar
              key={snackbar.id}
              onClose={() => dispatch(removeSnackbar({ id: snackbar.id }))}
              duration={snackbar.duration}
              severity={snackbar.severity}
            >
              <div className="flex flex-col">
                <span className="body-400-semibold">{snackbar.title}</span>
                {snackbar.content && (
                  <span className="body-400 mt-1">{snackbar.content}</span>
                )}
              </div>
            </SnackBar>
          );
        })}
      </>
    </SnackBarContainer>
  );
};

export default SnackbarHandler;
