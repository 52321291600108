import { useState } from 'react';
import axios from 'axios';
import { uniqueId } from 'lodash';
import {
  SNACKBAR_DOCUMENT_MANAGEMENT,
  SNACKBAR_TASKS,
} from '@/constants/snackbar-messages';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useDeleteDocumentMutation,
  useGetDocumentUrlMutation,
} from '@/services/documentManagement';

export interface DownloadFileSettingDocumentsManagement {
  fileName: string;
  fileType?: string;
  removeSnackbarId?: string;
  onErrorMessage?: string;
  onSuccessMessage?: string;
}

export interface DeleteFileSettingDocumentsManagement {
  callback?: (success: boolean) => void;
  skipSnackbarSuccess?: boolean;
}

export const useHandleDocumentManagementFiles = () => {
  const snackbar = useSnackbar();
  const [isPendingFile, setIsPendingFile] = useState(false);
  const [isPendingDelete, setIsPendingDelete] = useState(false);
  const [getDocumentUrl] = useGetDocumentUrlMutation();
  const [onDeleteFile] = useDeleteDocumentMutation();

  const deleteFile = (
    fileId: string,
    settings?: DeleteFileSettingDocumentsManagement
  ) => {
    setIsPendingDelete(true);
    onDeleteFile({ id: fileId })
      .unwrap()
      .then(() => {
        if (settings?.callback) settings?.callback(true);
        if (!settings?.skipSnackbarSuccess) {
          snackbar.show({
            severity: 'success',
            title: SNACKBAR_DOCUMENT_MANAGEMENT.FILE_DELETED,
          });
        }
      })
      .catch((error) => {
        if (settings?.callback) settings.callback(false);
        const errMsg =
          error?.status === 404
            ? SNACKBAR_DOCUMENT_MANAGEMENT.FILE_DELETE_NOT_FOUND
            : SNACKBAR_DOCUMENT_MANAGEMENT.FILE_DELETED_FAILED;

        snackbar.show({
          severity: 'error',
          title: errMsg,
        });
      })
      .finally(() => {
        setIsPendingDelete(false);
      });
  };

  const handleDownloadError = (
    err,
    snackbarId: string,
    settings: DownloadFileSettingDocumentsManagement
  ) => {
    snackbar.remove(snackbarId);
    const errorMessage =
      err?.response?.status === 404
        ? SNACKBAR_TASKS.DOWNLOAD_NOT_FOUND
        : SNACKBAR_TASKS.DOWNLOAD_FAILED;

    setTimeout(() => {
      snackbar.show({
        severity: 'error',
        title: settings.onErrorMessage ? settings.onErrorMessage : errorMessage,
      });
    }, 250);
  };

  const downloadFile = (
    fileId: string,
    settings: DownloadFileSettingDocumentsManagement
  ) => {
    const id = settings.removeSnackbarId || uniqueId('download-snackbar');
    snackbar.show({
      id: id,
      severity: 'success',
      title: 'Downloading, please wait...',
      duration: 0,
    });
    setIsPendingFile(true);
    getDocumentUrl({ id: fileId })
      .unwrap()
      .then(({ url }) => {
        axios
          .request({
            url: url,
            method: 'GET',
            responseType: 'blob',
          })
          .then((response) => {
            const fileUrl = window.URL.createObjectURL(
              new Blob([response.data])
            );
            const link = document.createElement('a');
            const linkNameAttribute = settings.fileType
              ? `${settings.fileName}.${settings.fileType}`
              : settings.fileName;
            link.href = fileUrl;
            link.setAttribute('download', linkNameAttribute);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            snackbar.remove(id);
            setTimeout(() => {
              snackbar.show({
                severity: 'success',
                title: settings.onSuccessMessage
                  ? settings.onSuccessMessage
                  : SNACKBAR_DOCUMENT_MANAGEMENT.DOWNLOAD_FILE_SUCCESS,
              });
            }, 250);
          })
          .catch((err) => {
            handleDownloadError(err, id, settings);
          });
      })
      .catch((err) => {
        handleDownloadError(err, id, settings);
      })
      .finally(() => {
        setIsPendingFile(false);
      });
  };

  return { downloadFile, isPendingFile, deleteFile, isPendingDelete };
};
