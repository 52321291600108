import React, { ReactElement } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import * as Tooltip from '@radix-ui/react-tooltip';

export interface SideBarItemType extends ItemType {
  title?: string;
  items?: ItemType[];
  hide?: boolean;
}

export interface ItemType {
  icon?: ReactElement;
  text?: string;
  route?: string;
  active?: boolean;
}

interface Props extends ItemType {
  collapsed: boolean;
  iconSize?: 'sm' | 'md';
  specialAlignment?: 'individualIcon' | 'loansIcon' | 'repaymentsIcon' | '';
  hide?: boolean;
}

const SideBarItem: React.FC<Props> = (props) => {
  const {
    icon,
    iconSize = 'md',
    text,
    route = '',
    active,
    collapsed,
    specialAlignment = '',
    hide = false,
  } = props;

  const iconClassNames = classNames(
    collapsed ? iconSizes[iconSize].collapsed : iconSizes[iconSize].expanded,
    alignments[specialAlignment]
  );

  const iconWithProps = icon
    ? icon.props && icon.props.name
      ? React.cloneElement(icon, {
          name: icon.props.name,
          className: iconClassNames,
        })
      : React.cloneElement(icon, {
          className: iconClassNames,
        })
    : null;

  if (hide) return null;

  return (
    <Link
      href={route}
      className={classNames(
        'flex items-center focus:outline-none w-full',
        active ? 'text-primary-700' : 'text-black',
        collapsed
          ? 'my-4 h-6'
          : 'pl-6 py-2 my-2 w-full hover:bg-neutral-100 rounded-full'
      )}
    >
      {collapsed ? (
        <Tooltip.Root disableHoverableContent delayDuration={300}>
          <Tooltip.Trigger asChild>
            <span className="flex p-3 h-13 w-13 hover:bg-neutral-100 hover:rounded-full">
              {iconWithProps}
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className="z-[100] fixed flex justify-center items-center text-neutral-800 text-center body-500 rounded-[46px] px-5 min-w-[117px] bg-neutral-100 h-[54px] whitespace-nowrap sidebar-tooltip-shadow -translate-y-1/2"
              side="right"
              sideOffset={29}
            >
              {text}
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      ) : (
        <>
          {iconWithProps}
          <span className="flex-1 body-500 ml-4 select-none">{text}</span>
        </>
      )}
    </Link>
  );
};

export default SideBarItem;

const iconSizes = {
  sm: { collapsed: 'w-6 h-6', expanded: 'w-4 h-4' },
  md: { collapsed: 'w-7 h-7', expanded: 'w-5 h-5' },
};

const alignments = {
  individualIcon: 'ml-[2px]',
  loansIcon: 'ml-[2px]',
  repaymentsIcon: 'ml-[2px]',
  '': '',
};
