export const FORM_NAME_SECTION_HEADING = 'Funding method name';
export const FORM_NAME_PLACEHOLDER = 'Enter a name';
export const FORM_FEES_SECTION_HEADING = 'Select which fees should be included';
export const FORM_NO_FEES_TEXT = 'No fees to configure.';
export const FORM_CANCEL_LABEL = 'Cancel';
export const FORM_SUBMIT_LABEL = 'Save';

export const NO_FUNDING_METHODS_TEXT = 'No funding methods.';
export const ADD_FUNDING_METHOD_TEXT = '+ Add new funding method';

export const FUNDING_METHODS_LIST_HEADING = 'Funding methods';
export const EDIT_FUNDING_METHOD_DIALOG_TITLE = 'Edit funding method';
export const EDIT_FUNDING_METHOD_ALERT_TEXT =
  'Any active loans with this method will continue using the settings configured at the time.';
export const CREATE_FUNDING_METHOD_DIALOG_TITLE = 'New funding method';

export const DELETE_FUNDING_METHOD_SIDE_EFFECTS_TEXT =
  'Any existing loans that have this method assigned will continue to use it and will not be impacted.';

export const DELETE_FUNDING_METHOD_CONFIRMATION_TEXT = (
  fundingMethodName: string
) => {
  return (
    <>
      Would you like to delete
      <br />
      {fundingMethodName}?
    </>
  );
};

export const DELETE_FUNDING_METHOD_CONFIRM_LABEL = 'Yes, delete';
