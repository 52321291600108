import React, {
  Dispatch,
  ReactFragment,
  SetStateAction,
  forwardRef,
  useEffect,
} from 'react';
import classNames from 'classnames';

interface DottedBoxProps {
  children: ReactFragment;
  layout: 'onboarding' | 'tasks' | 'review';
  isDragging: boolean;
  setIsMounted?: Dispatch<SetStateAction<boolean>>;
}

const DottedBox = forwardRef<HTMLDivElement, DottedBoxProps>(
  ({ children, layout, isDragging, setIsMounted }, ref) => {
    useEffect(() => {
      // useEffect does not respond to useRef changes if you wanna force rerender your parent component use setIsMounted
      if (setIsMounted) setIsMounted(true);
      return () => {
        if (setIsMounted) setIsMounted(false);
      };
    }, []);

    const layoutSelect = {
      onboarding: 'relative heading-400 w-full mt-8',
      tasks: 'relative heading-300 w-[415px]',
      review: 'relative heading-300 w-full mt-8',
    };
    return (
      <div
        ref={ref}
        className={classNames(
          'flex flex-col items-center justify-center border-dashed border-[3px] border-neutral-300 h-[185px] cursor-pointer',
          layoutSelect[layout],
          { 'bg-primary-200 border-primary-500': isDragging }
        )}
      >
        {children}
      </div>
    );
  }
);

DottedBox.displayName = 'DottedBox';

export { DottedBox };
