import React from 'react';
import { IconProps } from '../iconTypes';

const CloseIcon: React.FC<IconProps> = (props) => (
  <svg
    width="10"
    height="9"
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 7.5L8 1.5M2 1.5L8 7.5"
      stroke="#334155"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseIcon;
