import {
  InvestorType,
  OriginatorInvestor,
} from 'kennek/interfaces/accounts/investor';

export const splitUserName = (userName: string) => {
  let firstName = '';
  let lastName = '';

  const arr = userName.split(' ');

  for (let i = 0; i < arr.length; i++) {
    if (i + 1 === arr.length || i == arr.length) {
      lastName = arr[i];
    } else {
      firstName += arr[i] + ' ';
    }
  }

  return {
    firstName,
    lastName,
  };
};

/**
 *
 * @param name1 The first string to concat, meant to be the firstName
 * @param name2 The second string to concat, meant to be the lastName
 * @returns a single string that represents the fullName
 */
export const concatNames = (name1?: string, name2?: string) => {
  if (!name1 && !name2) return '';

  if (!name2) return name1 || '';
  return name1?.concat(' ').concat(name2) || '';
};

export const showInvestorName = (investor: OriginatorInvestor['details']) => {
  const ob: Record<InvestorType | '', string> = {
    COMPANY: investor.companyName,
    PARTNERSHIP: investor.companyName,
    GROUP: investor.groupName,
    INDIVIDUAL: concatNames(investor?.firstName, investor.lastName),
    FAMILY_OFFICE: investor.nameOfContracting,
    SOLE_TRADER: investor.tradingName,
    '':
      investor?.email ||
      investor?.companyName ||
      concatNames(investor?.firstName, investor?.lastName) ||
      investor?.companyName ||
      investor?.nameOfContracting ||
      investor?.tradingName ||
      investor?.groupName ||
      concatNames(investor?.contactFirstName, investor?.contactLastName),
  };
  return ob[investor.type];
};
