import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { Button, FormInput, Modal, Select, ToggleSwitch } from 'ui';
import { EyeIcon, FolderIcon } from '@heroicons/react/outline';
import { TooltipLabel } from '@/components/widgets/TooltipLabel';
import { SNACKBAR_DOCUMENT_MANAGEMENT } from '@/constants/snackbar-messages';
import { DMTableRow } from '@/hooks/useBrowseDocuments';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useCreateFolderMutation,
  useUpdateFolderMutation,
} from '@/services/documentManagement';
import { PathData, createPathString } from '@/utils/documentManagement';
import {
  getDocumentNameKey,
  getDocumentNameValue,
} from '../EditDocumentsTable.utils';
import {
  AccessType,
  CreateFolderPayload,
  DocumentManagementAccess,
  DocumentManagementRoles,
} from 'kennek/interfaces/documentManagement';

const permissionOptionsInvestor = [
  {
    value: 'browse',
    label: 'View only',
  },
];

const permissionOptionsBorrower = [
  ...permissionOptionsInvestor,
  {
    value: 'upload',
    label: 'Upload and view',
  },
];

export type FolderModalType = 'new' | 'rename' | 'manageAccess';

interface Props {
  mode: FolderModalType;
  folderData?: DMTableRow;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  loanEncodedKey: string;
  path: PathData[];
  refreshData: () => void;
  manageAccessFolderName: string;
  isEditLoan: boolean;
}

const FolderModal = ({
  mode,
  folderData,
  show,
  setShow,
  loanEncodedKey,
  path,
  refreshData,
  manageAccessFolderName,
  isEditLoan,
}: Props) => {
  const [onlyMe, setOnlyMe] = useState(false);
  const [borrower, setBorrower] = useState(true);
  const [investor, setInvestor] = useState(true);
  const [borrowerPermission, setBorrowerPermission] =
    useState<AccessType>('upload');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { loanLower, borrowerUpper } = useGetLabelsConfig();

  const showNameInput = mode !== 'manageAccess';
  const showAccessFields = mode !== 'rename';
  const editMode = mode === 'manageAccess' || mode === 'rename';

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<{ folderName: string }>();

  const snackbar = useSnackbar();
  const [createFolder] = useCreateFolderMutation();
  const [updateFolder] = useUpdateFolderMutation();

  useEffect(() => {
    const setData = folderData && editMode;
    if (!show || !setData) return;
    const currentFolderName = getDocumentNameValue(
      isEditLoan,
      folderData?.name,
      folderData?.draftName
    );
    setValue('folderName', currentFolderName);
    if (folderData?.accessData?.length) {
      const borrowerSwitch = folderData.accessData.some(
        (x) => x.role === DocumentManagementRoles.BORROWER && x.allow
      );
      const investorSwitch = folderData.accessData.some(
        (x) => x.role === DocumentManagementRoles.INVESTOR && x.allow
      );
      setBorrower(borrowerSwitch);
      setInvestor(investorSwitch);
      setOnlyMe(!borrowerSwitch && !investorSwitch);

      const uploadBorrowerPermission = folderData.accessData.some(
        (x) =>
          x.role === DocumentManagementRoles.BORROWER &&
          x.allow &&
          x.action === 'upload'
      );

      setBorrowerPermission(uploadBorrowerPermission ? 'upload' : 'browse');
    }
  }, [mode, folderData, show]);

  useEffect(() => {
    if (!show) resetToDefaultFormData();
  }, [show]);

  const onSubmit = ({ folderName }) => {
    const pathString = createPathString(path);
    const accessData = getAccessData();

    const data: CreateFolderPayload = {
      access: accessData,
    };
    const nameKey = getDocumentNameKey(isEditLoan, editMode);
    if (mode !== 'manageAccess') {
      data[nameKey] = folderName;
      data.path = pathString;
    }
    if (mode === 'new') data.isDraft = isEditLoan;

    if (editMode) {
      handleEditFolder(data);
      return;
    }

    data.loanEncodedKey = loanEncodedKey;
    handleCreateFolder(data);
  };

  const getAccessData = () => {
    const accessData: DocumentManagementAccess[] = [
      {
        role: DocumentManagementRoles.BORROWER,
        allow: borrower,
        action: 'browse',
      },
      {
        role: DocumentManagementRoles.BORROWER,
        allow: borrower && borrowerPermission === 'upload',
        action: 'upload',
      },
      {
        role: DocumentManagementRoles.INVESTOR,
        allow: investor,
        action: 'browse',
      },
    ];
    if (!editMode) return accessData;

    const editFolderAccessData = folderData.accessData;

    return accessData.map((x) => {
      const currentData = editFolderAccessData.find(
        (curr) => curr.role === x.role && curr.action === x.action
      );
      if (!currentData) return x;
      return {
        ...x,
        id: currentData.id,
      };
    });
  };

  const handleEditFolder = (data: CreateFolderPayload) => {
    setIsSubmitting(true);
    const payload = {
      body: data,
      id: folderData.id,
    };
    updateFolder(payload)
      .unwrap()
      .then(() => {
        snackbar.show({
          severity: 'success',
          title:
            mode === 'manageAccess'
              ? SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_EDIT_ACCESS_SUCCESS
              : SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_EDIT_NAME_SUCCESS,
        });
        refreshData();
        closeDialog();
      })
      .catch((error) => {
        snackbar.show({
          severity: 'error',
          title:
            mode === 'manageAccess'
              ? SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_EDIT_ACCESS_FAILED
              : SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_EDIT_NAME_FAILED,
          content: error?.data?.message,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleCreateFolder = (data: CreateFolderPayload) => {
    setIsSubmitting(true);
    createFolder(data)
      .unwrap()
      .then(() => {
        snackbar.show({
          severity: 'success',
          title: SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_CREATE_SUCCESS,
        });
        refreshData();
        closeDialog();
      })
      .catch((error) => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_CREATE_FAILED,
          content: error?.data?.message,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const modalTitle = useMemo(() => {
    if (mode === 'manageAccess')
      return `Manage access to\n'${manageAccessFolderName}'`;
    if (mode === 'rename') return 'Rename folder';
    const createBaseTitle = 'Create new folder';
    const currentFolderName = path?.length > 1 ? path.at(-1)?.label : null;
    if (currentFolderName)
      return createBaseTitle + ` in '${currentFolderName}'`;
    return createBaseTitle;
  }, [mode, manageAccessFolderName, isEditLoan, folderData, path]);

  const resetToDefaultFormData = () => {
    setOnlyMe(false);
    setBorrower(true);
    setInvestor(true);

    setBorrowerPermission('upload');
    setValue('folderName', '');
    reset();
  };

  const closeDialog = () => {
    resetToDefaultFormData();

    setShow(false);
  };

  const validateFolderName = (value: string) => {
    if (value.includes('/')) return 'Folder name cannot contain slashes (/).';
  };

  const isDisabledBorrowerPermission = onlyMe || !borrower || isSubmitting;

  return (
    <Modal
      isVisible={show}
      closeOnClickOutside={false}
      includeHeader="withCloseButton"
      setIsVisible={closeDialog}
      className="bg-opacity-40 top-0 bg-neutral-800 overflow-y-auto z-[101]"
      paddingBodyX="px-8 mt-0 mb-0 pt-0"
      paddingHeaderX="px-8 pt-4 pb-0"
      width="w-full max-w-[450px]"
      marginY="my-2"
    >
      <>
        <div className="flex justify-center pt-4">
          <span className="rounded-full w-12 h-12 bg-primary-100 flex justify-center items-center">
            {(mode === 'new' || mode === 'rename') && (
              <FolderIcon className="h-6 w-6 text-primary-900" />
            )}
            {mode === 'manageAccess' && (
              <EyeIcon className="h-6 w-6 text-primary-900" />
            )}
          </span>
        </div>

        <p className="pt-4 text-center text-neutral-900 heading-400 mb-4 whitespace-pre-wrap truncate leading-6">
          {modalTitle}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          {showNameInput && (
            <FormInput
              label="Folder name"
              placeholder="Name"
              disabled={isSubmitting}
              errors={errors?.folderName?.message}
              {...register('folderName', {
                required: 'Folder name is required',
                validate: validateFolderName,
              })}
            />
          )}

          {showAccessFields && (
            <>
              <div
                className={classNames('mb-4 mt-2 flex justify-between', {
                  'pt-4': !showNameInput,
                })}
              >
                <TooltipLabel
                  labelText="Who can access"
                  className="heading-200 flex"
                  position="right"
                >
                  <p className="heading-200">
                    Select which users associated to this {loanLower} can access
                    this folder
                  </p>
                </TooltipLabel>

                <p className="heading-200">Permission</p>
              </div>

              <label
                htmlFor="onlyMe"
                className="flex justify-between max-w-[120px] mb-2"
              >
                <span className="heading-100 py-2">Only Me</span>
                <div className="pt-1">
                  <ToggleSwitch
                    id="onlyMe"
                    enabled={onlyMe}
                    disabled={isSubmitting}
                    onChange={(val: boolean) => {
                      setOnlyMe(val);
                      if (val) {
                        setInvestor(false);
                        setBorrower(false);
                      }
                    }}
                  />
                </div>
              </label>
              <div className="flex justify-between mb-2">
                <label
                  htmlFor="borrower"
                  className="flex justify-between items-center w-[120px]"
                >
                  <span className="heading-100 py-2">{borrowerUpper}</span>
                  <div className="pt-1">
                    <ToggleSwitch
                      id="borrower"
                      enabled={borrower}
                      disabled={isSubmitting}
                      onChange={(val: boolean) => {
                        setBorrower(val);
                        setOnlyMe(!val && !investor);
                      }}
                    />
                  </div>
                </label>

                <Select
                  disabled={isDisabledBorrowerPermission}
                  options={permissionOptionsBorrower}
                  value={borrowerPermission}
                  onChange={(e) => setBorrowerPermission(e.target.value)}
                  className="w-[175px] max-h-[40px]"
                />
              </div>
              <div className="flex justify-between mb-2">
                <label
                  htmlFor="investor"
                  className="flex justify-between items-center w-[120px]"
                >
                  <span className="heading-100 py-2">Investor</span>
                  <div className="pt-1">
                    <ToggleSwitch
                      id="investor"
                      disabled={isSubmitting}
                      enabled={investor}
                      onChange={(val: boolean) => {
                        setInvestor(val);
                        setOnlyMe(!val && !borrower);
                      }}
                    />
                  </div>
                </label>
                <Select
                  disabled
                  options={permissionOptionsInvestor}
                  value={'browse'}
                  className="w-[175px] max-h-[40px]"
                />
              </div>
            </>
          )}

          <div className="flex flex-col">
            <div className="w-full flex justify-around mt-5">
              <Button
                className="w-50"
                layout="ghost"
                onClick={closeDialog}
                disabled={isSubmitting}
              >
                Cancel
              </Button>

              <Button
                className="w-50 px-0"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {editMode ? 'Save' : 'Create folder'}
              </Button>
            </div>
          </div>
        </form>
      </>
    </Modal>
  );
};

export default FolderModal;
