import React from 'react';
import { ToggleInput } from 'ui';
import { ExpandableContainer } from 'ui/components/ExpandableContainer';

type AdvancedInvestmentsSettingsFormProps = {
  className?: string;
  initiallyExpanded: boolean;
  investmentTypeSetting: {
    enabled?: boolean;
    onInvestmentTypeEnabledChange: (enabled: boolean) => void;
  };
};

export const AdvancedInvestmentsSettingsForm: React.FC<
  AdvancedInvestmentsSettingsFormProps
> = (props) => {
  return (
    <ExpandableContainer
      expanded={props.initiallyExpanded}
      title="Advanced settings"
    >
      <div className="w-[250px]">
        <ToggleInput
          enabled={props.investmentTypeSetting.enabled}
          label="Investment type"
          onChange={(enabled) => {
            props.investmentTypeSetting.onInvestmentTypeEnabledChange(enabled);
          }}
        />
      </div>
    </ExpandableContainer>
  );
};
