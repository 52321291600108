import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { colorDefault, setAppPalette } from 'ui/theme/defaultColors';
import { useBranchEncodedKey } from '@/hooks/useBranchEncodedKey';
import useGetBrandData from '@/hooks/useGetBrandData';
import { setUiSettingsMainPrimaryColor } from '@/store/user';
import LoadingAnimationPlat from '../common/LoadingAnimationPlat';

const ThemeProvider = ({ children }) => {
  const { branchEncodedKey, isLoading: isLoadingKey } = useBranchEncodedKey();
  const dispatch = useDispatch();
  // Handle reset the state, after dispatch reset state, data is lost and it will be handled in the children  otherwise it will be forever loading
  const [isFetchedBranding, setIsFetchedBranding] = useState(false);

  const { brandData, isLoadingBranding } = useGetBrandData(branchEncodedKey);

  useEffect(() => {
    if (isLoadingBranding) return;
    setIsFetchedBranding(true);

    const secondary = brandData?.colors?.secondary;
    const primary = brandData?.colors?.primary;

    setAppPalette(primary, secondary);

    const primaryMainColorSettings = primary?.C500?.toLowerCase();
    const primaryMainColorDefault =
      colorDefault.primary[500].toLocaleLowerCase();

    const isMainPrimaryColorDifferent = primaryMainColorSettings
      ? primaryMainColorSettings !== primaryMainColorDefault
      : false;

    dispatch(setUiSettingsMainPrimaryColor(isMainPrimaryColorDifferent));
  }, [brandData]);

  if ((isLoadingBranding || isLoadingKey) && !isFetchedBranding)
    return <LoadingAnimationPlat />;

  return children;
};

export default ThemeProvider;
