import * as React from 'react';
import { formatAmount } from '@/utils/formatters';

type Props = {
  interestBalance?: number | string;
  currency?: string;
};

const AccruedInterest: React.FC<Props> = ({ interestBalance, currency }) => {
  const formattedInterestBalance = formatAmount(
    interestBalance,
    false,
    currency
  );

  return (
    <div className="flex flex-col mb-4">
      <span className="heading-500 mr-2">{formattedInterestBalance}</span>
      <span className="body-200 mt-1">Accrued so far</span>
    </div>
  );
};

export default AccruedInterest;
