/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  SelectHTMLAttributes,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { LoadingAnimation } from 'kennek/components';
import { formatLineBreaks } from 'ui/utils/FormatText.utils';
import { Listbox } from '@headlessui/react';
import {
  ChevronDownIcon,
  QuestionMarkCircleIcon,
  SelectorIcon,
} from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import ThemeContext from '../../theme/ThemeContext';
import { sortSelectOptions } from '../../utils/helpers';
import Input from '../Input';
import Tooltip from '../Tooltip';
import SelectOptions from './components/SelectOptions';
import {
  MAX_OPTIONS_WITHOUT_SCROLL,
  SCROLL_WIDTH,
  SELECT_OPTIONS_HEIGHT,
  SELECT_OPTIONS_HEIGHT_PAGINATION,
} from './configConst';

export type Option = {
  value: SelectHTMLAttributes<any>['value'];
  label: string;
};

export type OptionsButton = {
  text: string;
  onClick: () => void;
};

export type PaginationSettings = {
  fetchMoreOptions: () => void;
  isLoadingSettings: boolean;
  onChangeSearch: (val: string) => void;
  searchValue: string;
  total: number | null;
  defaultOptions: number;
};

export type SelectProps = {
  label?: string;
  disabled?: boolean;
  name?: string;
  placeholder?: string;
  variant?: 'selector' | 'dropdown';
  options: Option[];
  value?: string | number;
  error?: string;
  className?: string;
  onErrorClassName?: string;
  optionClassName?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  valid?: boolean;
  optionsButton?: OptionsButton;
  height?: string;
  errorBottomPosition?: string;
  alignOptionsLeft?: boolean;
  questionTooltip?: string;
  dashboard?: boolean;
  bottomLabel?: string;
  handleErrorSpacing?: boolean;
  sortOptions?: boolean;
  pagination?: PaginationSettings;
};

const Select = forwardRef<HTMLButtonElement, SelectProps>(function Select(
  {
    label,
    disabled,
    name,
    options,
    value,
    onChange,
    onBlur,
    placeholder,
    variant,
    error,
    className,
    onErrorClassName = '',
    optionClassName,
    valid,
    optionsButton,
    height = 'h-[82px]',
    errorBottomPosition = 'bottom-auto mt-2',
    alignOptionsLeft,
    questionTooltip,
    dashboard,
    bottomLabel,
    handleErrorSpacing = false,
    sortOptions,
    pagination,
  }: SelectProps,
  ref
) {
  const {
    theme: { select },
  } = useContext(ThemeContext);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [selectWidth, setSelectWidth] = useState<number | undefined>();
  const [selectedStoredValue, setSelectedStoredValue] = useState<
    Option | undefined
  >();

  const selectRef = useRef<HTMLDivElement>(null);
  const selectOptionsHeight = pagination
    ? SELECT_OPTIONS_HEIGHT_PAGINATION
    : SELECT_OPTIONS_HEIGHT;

  const baseStyle = select.base;
  const activeStyle = select.active;
  const disabledStyle = select.disabled;
  const optionStyle = classNames(
    select.option,
    'overflow-hidden',
    `max-h-[${selectOptionsHeight}px]`,
    {
      'text-left': alignOptionsLeft,
    }
  );

  const selectedStyle = select.selected;
  const iconStyle = select.icon;
  const invalidStyle = select.invalid;
  const validStyle = select.valid;

  const selectOptions = useMemo(() => {
    if (!sortOptions) return options;
    return sortSelectOptions([...options]);
  }, [options]);

  const hasValidation = (valid: boolean | undefined) => {
    return valid !== undefined;
  };

  const validationStyle = (valid: boolean | undefined): string => {
    if (hasValidation(valid)) {
      return valid ? validStyle : invalidStyle;
    }
    return '';
  };

  useEffect(() => {
    const valueToSubtractFormWidth =
      options.length > MAX_OPTIONS_WITHOUT_SCROLL ? SCROLL_WIDTH : 0;
    if (!selectRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (selectRef.current?.offsetWidth)
        setSelectWidth(
          selectRef?.current.offsetWidth - valueToSubtractFormWidth
        );
    });
    resizeObserver.observe(selectRef.current);
    return () => resizeObserver.disconnect();
  }, [options]);

  const cls = classNames(
    baseStyle,
    // don't apply activeStyle if has valid or disabled
    !hasValidation(valid) && !disabled && activeStyle,
    // don't apply invalidStyle if has valid or disabled
    !hasValidation(valid) && !disabled && !!error && invalidStyle,
    // don't apply disabledStyle if has valid
    !hasValidation(valid) && disabled && disabledStyle,
    validationStyle(valid),
    { 'bg-white': !disabled }
  );

  const optionMap = useMemo(
    () => new Map<Option['value'], Option>(options.map((o) => [o.value, o])),
    [options]
  );

  const selected = optionMap.get(value);

  useEffect(() => {
    // In the case of search, we need to save the value so that when the option is selected, the value does not disappear from the select
    if (selected && pagination) setSelectedStoredValue(selected);
  }, [selected]);

  const currentValue = selected || selectedStoredValue;
  return (
    <div
      className={classNames(
        'relative',
        className,
        handleErrorSpacing && error ? 'h-[95px]' : height,
        {
          [onErrorClassName]: !!error,
        }
      )}
      ref={selectRef}
    >
      {!!label && (
        <div
          className="flex items-center gap-x-1 mb-1.5"
          onMouseOver={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <div className="heading-100 select-none text-left">{label}</div>

          {questionTooltip && (
            <div className="relative flex items-center">
              <QuestionMarkCircleIcon width={14} className="cursor-pointer" />
              {showTooltip && (
                <Tooltip position="right">
                  <div className="whitespace-normal w-96 text-left">
                    {formatLineBreaks(questionTooltip)}
                  </div>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      )}

      <Listbox
        disabled={disabled}
        value={currentValue}
        onChange={(value) =>
          onChange &&
          onChange({
            target: {
              name: name,
              id: name,
              value: value?.value,
              label: value?.label,
            },
          } as React.ChangeEvent<any>)
        }
      >
        <Listbox.Button
          name={name}
          id={name}
          ref={ref}
          onBlur={onBlur}
          className={classNames('flex items-center relative', cls, {
            'h-[38px]': dashboard,
          })}
          onKeyDown={(e) => e.preventDefault()}
          data-testid={`${name}-listbox-button`}
        >
          <span
            className={classNames(
              'whitespace-nowrap w-full overflow-x-hidden over text-ellipsis',
              !currentValue && 'text-neutral-600'
            )}
          >
            {currentValue?.label || placeholder || 'Select'}
          </span>
          <span className={classNames('ml-3', iconStyle)}>
            {variant === 'selector' ? (
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            )}
          </span>
          {!!error && (
            <ExclamationCircleIcon className="absolute text-error-500 w-5 h-5 right-8" />
          )}
        </Listbox.Button>
        <Listbox.Options className={classNames(optionStyle, optionClassName)}>
          {selectWidth && pagination && (
            <div className="p-1">
              <Input
                value={pagination.searchValue}
                onChange={(e) => pagination.onChangeSearch(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                type="search"
                autoComplete="off"
                placeholder="Search"
                autoFocus={true}
                className={`p-1 border-neutral-400 rounded-md body-400 h-[40px]`}
              />
            </div>
          )}

          <SelectOptions
            selectOptionsHeight={selectOptionsHeight}
            pagination={pagination}
            selectOptions={selectOptions}
            selected={currentValue}
            activeStyle={activeStyle}
            selectedStyle={selectedStyle}
            selectWidth={selectWidth}
          />
          {optionsButton && (
            <Listbox.Option key={'optionsButton'} value={'OptionsButton'}>
              <div
                onClick={optionsButton.onClick}
                className="body-400 text-secondary-400 pl-3 py-2 hover:bg-primary-100 select-none"
              >
                {optionsButton.text}
              </div>
            </Listbox.Option>
          )}
          {pagination?.isLoadingSettings && (
            <div>
              <LoadingAnimation size="sm" color="standard" />
            </div>
          )}
        </Listbox.Options>
      </Listbox>

      {(!!error || bottomLabel) && (
        <div
          className={classNames({
            'absolute body-200 bottom-1': error,
            [errorBottomPosition]: error,
            'mb-4 mt-2': bottomLabel,
          })}
        >
          <div className="flex flex-col">
            {bottomLabel && (
              <div className="text-neutral-700 body-300 mr-10 whitespace-nowrap">
                {bottomLabel}
              </div>
            )}
            {!!error && <span className="text-error-700">{error}</span>}
          </div>
        </div>
      )}
    </div>
  );
});

export default Select;
