import React from 'react';
import { IconProps } from '../iconTypes';

const LockClosedIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 20"
    aria-hidden="true"
    {...props}
  >
    <g id="Fill / Lock closed">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 8.80039V6.40039C3 3.08668 5.68629 0.400391 8.99999 0.400391C12.3137 0.400391 15 3.08668 15 6.40039V8.80039C16.3255 8.80039 17.4 9.87491 17.4 11.2004V17.2004C17.4 18.5259 16.3255 19.6004 15 19.6004H3C1.67451 19.6004 0.599998 18.5259 0.599998 17.2004V11.2004C0.599998 9.87491 1.67451 8.80039 3 8.80039ZM12.6 6.40039V8.80039H5.39999V6.40039C5.39999 4.41217 7.01177 2.80039 8.99999 2.80039C10.9882 2.80039 12.6 4.41217 12.6 6.40039Z"
        fill="#64748B"
      />
    </g>
  </svg>
);

export default LockClosedIcon;
