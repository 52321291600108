import { Button } from 'ui';
import { RefreshIcon } from '@heroicons/react/outline';

type ErrorRefresherProps = {
  onRefresh: () => void;
};

const ErrorRefresher = ({ onRefresh }: ErrorRefresherProps) => {
  return (
    <div className="min-h-full min-w-full flex flex-col flex-grow justify-around text-center">
      <div>
        <p className="heading-300 text-error-500 mb-3">Something went wrong</p>
        <Button layout="destructive" className="py-2 px-6" onClick={onRefresh}>
          <span className="heading-300 mr-2">Refresh</span>
          <RefreshIcon width={16} height={16} />
        </Button>
      </div>
    </div>
  );
};

export default ErrorRefresher;
