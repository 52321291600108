import { loansBaseApi } from '.';
import { rootApi } from '../rootApi';
import type {
  PaymentAllocationStrategyDecision,
  ReconcileDecision,
  TransactionAdjust,
  TransactionsQuery,
  TransactionsResult,
} from '@/interfaces/loans/transactions';

const transactionsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getTransactions: builder.query<TransactionsResult, TransactionsQuery>({
      query: ({ loanEncodedKey, branchEncodedKey, filter }) => ({
        url: loansBaseApi + '/transaction/all',
        params: {
          loanEncodedKey,
          branchEncodedKey,
          type: filter?.type,
          performedBy: filter?.performedBy
            ? JSON.stringify(filter.performedBy)
            : undefined,
        },
      }),
      providesTags: ['Loans'],
    }),
    transactionAdjust: builder.mutation({
      query: (body: TransactionAdjust) => ({
        url: loansBaseApi + '/transaction/adjust',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Loans'],
    }),
    reconcileOnDecision: builder.mutation<void, ReconcileDecision>({
      query: (reconcileDecision) => ({
        url: loansBaseApi + '/transaction/reconcile-on-decision',
        method: 'POST',
        body: reconcileDecision,
      }),
    }),
    setAllocationStrategy: builder.mutation<
      void,
      PaymentAllocationStrategyDecision
    >({
      query: (allocationStrategy) => ({
        url: loansBaseApi + '/transaction/set-allocation-strategy',
        method: 'POST',
        body: allocationStrategy,
      }),
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useLazyGetTransactionsQuery,
  useTransactionAdjustMutation,
  useReconcileOnDecisionMutation,
  useSetAllocationStrategyMutation,
} = transactionsApi;

export const { useQuerySubscription: useGetTransactionsQuerySubscription } =
  transactionsApi.endpoints.getTransactions;
