import { useState } from 'react';
import axios from 'axios';
import { uniqueId } from 'lodash';
import { SNACKBAR_TASKS } from '@/constants/snackbar-messages';
import { useSnackbar } from '@/hooks/useSnackbar';

export interface DownloadFileSetting {
  onErrorMessage?: string;
  onSuccessMessage?: string;
  params?: Record<string, unknown>;
  fileName: string;
  fileType?: string;
  noWaitMessage?: boolean;
  removeSnackbarId?: string;
  externalUrl?: boolean;
}

export const useDownloadFile = () => {
  const snackbar = useSnackbar();
  const [isPendingFile, setIsPendingFile] = useState(false);

  const onDownloadDocument = (url: string, settings: DownloadFileSetting) => {
    const id = settings.removeSnackbarId || uniqueId('download-snackbar');

    if (!settings.noWaitMessage) {
      snackbar.show({
        id: id,
        severity: 'success',
        title: SNACKBAR_TASKS.DOWNLOAD_IN_PROGRESS,
        duration: 0,
      });
    }

    setIsPendingFile(true);
    axios
      .request({
        url: settings.externalUrl ? url : `/api/${url}`,
        method: 'GET',
        params: settings?.params || null,
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const linkNameAttribute = settings.fileType
          ? `${settings.fileName}.${settings.fileType}`
          : settings.fileName;
        link.href = url;
        link.setAttribute('download', linkNameAttribute);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);

        const successMessage =
          settings.onSuccessMessage || SNACKBAR_TASKS.DOWNLOAD_SUCCESS;
        snackbar.remove(id);
        setTimeout(() => {
          snackbar.show({
            severity: 'success',
            title: successMessage,
          });
        }, 250);
      })
      .catch((err) => {
        snackbar.remove(id);
        const errorMessage =
          settings.onErrorMessage || SNACKBAR_TASKS.DOWNLOAD_FAILED;
        setTimeout(() => {
          snackbar.show({
            severity: 'error',
            title:
              err?.response?.status === 404
                ? SNACKBAR_TASKS.DOWNLOAD_NOT_FOUND
                : errorMessage,
          });
        }, 250);
      })
      .finally(() => {
        setIsPendingFile(false);
      });
  };

  return { onDownloadDocument, isPendingFile };
};
