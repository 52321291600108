import { DayType } from 'kennek/interfaces/scheduling';

export interface Deadline {
  days: number;
  dayType: DayType;
  calculationDate: CalculationDate;
  financialYearEnd?: Date;
  scheduleAnchor?: ScheduleAnchor;
  customDate?: Date;
}
export interface Schedule {
  _id?: string;
  borrowerCompanyExternalId: string;
  mambu_branch_encoded_key: string;
  name: string;
  description: string;
  frequency: string;
  deadline: Deadline;
  remindDaysBeforeDeadline: number;
  documents: any[];
  active: boolean;
  loanEncodedKey: string;
  loanId: string;
  financialYearEnd?: Date;
}

//this interface is used at refinance/reschedule process
export interface EditSchedule extends Schedule {
  editId?: string;
}

export enum ScheduleAnchor {
  DISBURSEMENT_DATE = 'DISBURSEMENT_DATE',
  MATURITY_DATE = 'MATURITY_DATE',
  CUSTOM_DATE = 'CUSTOM_DATE',
}

export enum SamplingFrequency {
  MONTHS_3 = '3 months',
  MONTHS_6 = '6 months',
  MONTHS_12 = '12 months',
}

export enum CalculationDate {
  PRE_MONTH_END = 'PRE_MONTH_END',
  POST_MONTH_END = 'POST_MONTH_END',
  PRE_FINANCIAL_YEAR_END = 'PRE_FINANCIAL_YEAR_END',
  POST_FINANCIAL_YEAR_END = 'POST_FINANCIAL_YEAR_END',
}
