export interface ReportingScheduleTemplate {
  branchEncodedKey: string;
  description: string;
  name: string;
  frequency: Frequency;
  remindDaysBeforeDeadline: number;
  deadline: {
    days: number;
    dayType: DayType;
    calculationDate: CalculationDate;
    financialYearEnd?: string;
    scheduleAnchor?: string;
    customDate?: string;
  };
}

export interface ReportingSchedule {
  _id: string;
  name: string;
  description: string;
  deadline: {
    calculationDate: CalculationDate;
    dayType: DayType;
    days: number;
  };
  frequency: Frequency;
  remindDaysBeforeDeadline: number;
  startsWithLoan: boolean;
  branchEncodedKey: string;
}

export enum Frequency {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUAL = 'ANNUAL',
}

export enum DayType {
  BUSINESS = 'BUSINESS',
  CALENDAR = 'CALENDAR',
}

export enum ScheduleAnchor {
  DISBURSEMENT_DATE = 'DISBURSEMENT_DATE',
  MATURITY_DATE = 'MATURITY_DATE',
  CUSTOM_DATE = 'CUSTOM_DATE',
}

export enum SamplingFrequency {
  MONTHS_3 = '3 months',
  MONTHS_6 = '6 months',
  MONTHS_12 = '12 months',
}

export enum CalculationDate {
  PRE_MONTH_END = 'PRE_MONTH_END',
  POST_MONTH_END = 'POST_MONTH_END',
  PRE_FINANCIAL_YEAR_END = 'PRE_FINANCIAL_YEAR_END',
  POST_FINANCIAL_YEAR_END = 'POST_FINANCIAL_YEAR_END',
}
