import {
  FieldValues,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form';

const Form = <TFieldValues extends FieldValues = FieldValues, TContext = any>({
  children,
  ...props
}: {
  children: (props: {
    form: UseFormReturn<TFieldValues, TContext>;
  }) => JSX.Element;
} & UseFormProps<TFieldValues, TContext>) => {
  const form = useForm<TFieldValues, TContext>(props);
  return <>{children({ form })}</>;
};

export { Form };
