import { LoadingAnimation } from 'kennek/components';

const Loading = () => {
  return (
    <div className="flex justify-center items-center h-full w-full">
      <LoadingAnimation fitBox />
    </div>
  );
};

export { Loading };
