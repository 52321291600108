export const LABELS = {
  blockedUntilMatured: {
    firstRepaymentDate: 'First accrual date',
    repaymentInstallments: 'Number of interest periods',
    monthlyRepaymentDay: 'Accrual day',
    dueDate: 'Accrual date',
    repaymentPeriodCount: 'Period Count',
    repaymentPeriodUnits: 'Select a repayment period',
    remainingInstallmentsCount: 'Interest periods remaining',
  },
  default: {
    repaymentInstallments: 'Repayment Instalments',
    monthlyRepaymentDay: 'Monthly repayment day',
    repaymentPeriodCount: 'Period Count',
    repaymentPeriodUnits: 'Select a repayment period',
    firstRepaymentDate: 'First repayment date',
    dueDate: 'Due date',
    remainingInstallmentsCount: 'Instalments Remaining',
  },
};
