import { rootApi } from './rootApi';
import {
  CreateFolderPayload,
  DocumentUrl,
  GetFolderPayload,
  GetFolderResponse,
  UpdateDocumentPayload,
} from 'kennek/interfaces/documentManagement';

const baseDocumentManagementApi = '/api/document-management';

export const documentManagement = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getFolders: builder.mutation<GetFolderResponse, GetFolderPayload>({
      query: (body) => ({
        url: baseDocumentManagementApi + '/folder/browse',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Document-Management'],
    }),
    createFolder: builder.mutation({
      query: (body: CreateFolderPayload) => ({
        url: baseDocumentManagementApi + '/folder',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Document-Management'],
    }),
    updateFolder: builder.mutation({
      query: (data: { body: CreateFolderPayload; id: string }) => ({
        url: baseDocumentManagementApi + `/folder/${data.id}`,
        method: 'PATCH',
        body: data.body,
      }),
      invalidatesTags: ['Document-Management'],
    }),
    deleteFolder: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: baseDocumentManagementApi + `/folder/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Document-Management'],
    }),
    updateDocument: builder.mutation({
      query: (data: { body: UpdateDocumentPayload; id: string }) => ({
        url: baseDocumentManagementApi + `/document/${data.id}`,
        method: 'PATCH',
        body: data.body,
      }),
      invalidatesTags: ['Document-Management'],
    }),
    deleteDocument: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: baseDocumentManagementApi + `/document/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Document-Management'],
    }),
    getDocumentUrl: builder.mutation<DocumentUrl, { id: string }>({
      query: (params) => ({
        url: baseDocumentManagementApi + `/document/${params.id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetFoldersMutation,
  useCreateFolderMutation,
  useUpdateFolderMutation,
  useDeleteFolderMutation,
  useUpdateDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentUrlMutation,
} = documentManagement;
