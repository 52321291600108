import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { SNACKBAR_DOCUMENT_MANAGEMENT } from '@/constants/snackbar-messages';
import { leaseEstateOrderedFolderNames } from '@/features/document-management/EditDocumentsTable.utils';
import { useGetFoldersMutation } from '@/services/documentManagement';
import {
  DocumentManagementRowType,
  PathData,
  createPathString,
} from '@/utils/documentManagement';
import { useRole } from './useRole';
import { useSnackbar } from './useSnackbar';
import {
  DocumentData,
  DocumentManagementAccess,
  Folder,
  MetaData,
} from 'kennek/interfaces/documentManagement';
import { LabelsConfig } from 'kennek/interfaces/labelsConfig';

export interface DMTableRow {
  id?: string;
  rowType: DocumentManagementRowType;
  name: string;
  draftName?: string;
  dateAdded?: string;
  downloadId?: string;
  lastModified?: string;
  access?: string;
  accessData?: DocumentManagementAccess[];
  fullPath?: string;
  pathSplitted?: string[];
  documentData?: {
    folderId?: string;
    tags?: string[];
    isDraft: boolean;
    isDraftDeleted: boolean;
    metadata?: MetaData;
  };
}

enum DMRolesToName {
  LENDX_ADMIN = 'Lendx admin',
  BORROWER = 'Borrower',
  ORIGINATOR_ANALYST = 'Originator analyst',
  ORIGINATOR_ADMIN = 'Originator admin',
  INVESTOR = 'Investor',
  JOB_RUNNER = 'Job runner',
  unauthenticated = 'Unauthenticated',
}

export const useBrowseDocuments = (
  path: PathData[],
  loanEncodedKey: string,
  labelsConfig: LabelsConfig,
  originatorId = null,
  search = '',
  editLoan = false
) => {
  const [currentFolderId, setCurrentFolderId] = useState('loan');
  const [debouncesSearchValue, setDebouncesSearchValue] = useState('');
  const [searchItemsFound, setSearchItemsFound] = useState(0);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [rows, setRows] = useState<DMTableRow[]>([]);
  const [folderAccess, setFolderAccess] = useState<'read' | 'readUpload'>(
    'read'
  );
  const [browseFolder] = useGetFoldersMutation();
  const { role } = useRole();
  const snackbar = useSnackbar();
  const isPendingRequest = useRef(false);
  const onMountRequestDone = useRef(false);
  //isPendingRequest is used to check whether the same requests are not being executed all the time. Do not use for loader because the change does not cause re-rendering

  const onChangeSearchValue = useCallback(
    debounce((search) => {
      setDebouncesSearchValue(search);
    }, 1000),
    []
  );

  useEffect(() => {
    onChangeSearchValue(search);
  }, [search]);

  useEffect(() => {
    if (!onMountRequestDone.current) return;
    isPendingRequest.current = false;
    fetchData();
  }, [debouncesSearchValue]);

  useEffect(() => {
    if (!loanEncodedKey) return;
    fetchData();
  }, [loanEncodedKey, path, originatorId]);

  const pathString = useMemo(() => createPathString(path), [path]);

  const fetchData = () => {
    if (!(loanEncodedKey || originatorId) || isPendingRequest.current) return;
    isPendingRequest.current = true;
    setIsLoadingDocuments(true);
    browseFolder({
      path: pathString,
      loanEncodedKey,
      originatorId,
      search: debouncesSearchValue,
    })
      .unwrap()
      .then(({ folders, documents, currentFolderId, currentFolderAccess }) => {
        setAccessByRole(currentFolderAccess);
        setCurrentFolderId(currentFolderId);
        createRows(folders, documents);
      })
      .catch(() => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_DOCUMENT_MANAGEMENT.FOLDER_FAILED,
        });
      })
      .finally(() => {
        isPendingRequest.current = false;
        onMountRequestDone.current = true;
        setIsLoadingDocuments(false);
      });
  };

  const setAccessByRole = (data?: DocumentManagementAccess[]) => {
    const isOriginator =
      role === 'ORIGINATOR_ADMIN' || role === 'ORIGINATOR_ANALYST';
    if (isOriginator || !data || !data?.length) {
      setFolderAccess('readUpload');
      return;
    }
    // Don't check read permission because without read permission user won't fetch data
    const hasUploadPermission = data.some(
      ({ role: accessRole, allow, action }) =>
        accessRole === role && action === 'upload' && allow
    );

    setFolderAccess(hasUploadPermission ? 'readUpload' : 'read');
  };

  const getFileRowType = (
    isDraftDeleted: boolean,
    editLoan: boolean,
    isDraft: boolean
  ): DocumentManagementRowType => {
    if (editLoan) {
      if (isDraftDeleted) return DocumentManagementRowType.FILE_DRAFT_DELETE;
      if (isDraft) return DocumentManagementRowType.FILE_EDIT;
      return DocumentManagementRowType.FILE_EDIT_CORE;
    }
    return DocumentManagementRowType.FILE;
  };

  const getFolderRowType = (
    isUserCreated: boolean,
    isDraftDeleted: boolean,
    isDraft: boolean,
    editLoan: boolean
  ): DocumentManagementRowType => {
    if (editLoan && isUserCreated) {
      if (isDraftDeleted)
        return DocumentManagementRowType.USER_FOLDER_DRAFT_DELETE;
      if (isDraft) return DocumentManagementRowType.USER_FOLDER_EDIT;
      return DocumentManagementRowType.USER_FOLDER_EDIT_CORE;
    }
    if (isUserCreated) return DocumentManagementRowType.USER_FOLDER;
    return DocumentManagementRowType.FOLDER;
  };

  const createRows = (folders: Folder[], documents: DocumentData[]) => {
    const filteredDocuments = editLoan
      ? documents
      : documents.filter((x) => !x.isDraft);

    const filteredFolder = editLoan
      ? folders
      : folders.filter((x) => !x.isDraft);

    const documentsRows: DMTableRow[] = filteredDocuments.map(
      ({
        name,
        draftName,
        createdAt,
        id,
        fullPath,
        isDraft,
        isDraftDeleted,
        folderId,
        metadata,
        tags,
      }) => {
        const pathSplitted = fullPath
          ? fullPath.split('/').filter((x) => !!x)
          : [];
        pathSplitted.pop();
        const rowType = getFileRowType(isDraftDeleted, editLoan, isDraft);
        return {
          id,
          name,
          draftName,
          rowType,
          downloadId: id,
          dateAdded: createdAt,
          fullPath,
          pathSplitted: pathSplitted,
          documentData: {
            isDraft,
            isDraftDeleted,
            folderId,
            metadata,
            tags,
          },
        };
      }
    );
    const foldersRows: DMTableRow[] = filteredFolder.map(
      ({
        id,
        name,
        draftName,
        createdAt,
        updatedAt,
        access,
        isUserCreated,
        fullPath,
        isDraftDeleted,
        isDraft,
      }) => {
        const accessString = getAccessString(access);
        const type = getFolderRowType(
          isUserCreated,
          isDraftDeleted,
          isDraft,
          editLoan
        );

        const pathSplitted = fullPath
          ? fullPath.split('/').filter((x) => !!x)
          : [];
        pathSplitted.pop();

        return {
          id,
          name,
          draftName,
          rowType: type,
          dateAdded: createdAt,
          lastModified: updatedAt,
          accessData: access,
          access: accessString,
          fullPath,
          pathSplitted: pathSplitted,
          documentData: {
            isDraft,
            isDraftDeleted,
          },
        };
      }
    );

    const sortedLeaseEstateFolders = foldersRows
      .filter((folder) => leaseEstateOrderedFolderNames.includes(folder.name))
      .sort((a, b) => {
        const indexA = leaseEstateOrderedFolderNames.indexOf(a.name);
        const indexB = leaseEstateOrderedFolderNames.indexOf(b.name);
        return indexA - indexB;
      });

    const finalFoldersRows = [
      ...sortedLeaseEstateFolders,
      ...foldersRows.filter(
        (folder) => !leaseEstateOrderedFolderNames.includes(folder.name)
      ),
    ];

    const itemsFound = debouncesSearchValue
      ? foldersRows.length + documentsRows.length
      : 0;
    setSearchItemsFound(itemsFound);
    setRows([...finalFoldersRows, ...documentsRows]);
  };

  const getAccessString = (data: DocumentManagementAccess[]) => {
    // Empty table = full access
    if (!data.length)
      return `${labelsConfig.borrowerUpper}, ${DMRolesToName.INVESTOR}`;
    const addedAccess = [];
    let accessString = '';

    data.forEach(({ role, allow }) => {
      if (allow && !addedAccess.includes(role)) {
        const roleValue =
          DMRolesToName[role] === DMRolesToName.BORROWER
            ? labelsConfig.borrowerUpper
            : DMRolesToName[role];

        accessString += accessString ? `, ${roleValue}` : roleValue;
        addedAccess.push(role);
      }
    });

    return accessString ? accessString : 'Only me';
  };

  return {
    isLoadingDocuments,
    rows,
    refetchDocuments: fetchData,
    debouncesSearchValue,
    immediatelyChangeSearch: setDebouncesSearchValue,
    searchItemsFound,
    currentFolderId,
    folderAccess,
  };
};
