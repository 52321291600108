import React from 'react';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import * as Dialog from '@radix-ui/react-dialog';
import { Slot } from '@radix-ui/react-slot';

type ModalContentProps = {
  children: React.ReactNode;
};

// TODO: merge with `Dialog` component
const ModalContent = ({ children }: ModalContentProps) => {
  return (
    <Dialog.Portal>
      <Dialog.Overlay
        className={classNames(
          'fixed',
          'inset-0',
          'z-[100]',
          'bg-neutral-800',
          'bg-opacity-60',
          'overflow-y-auto',
          'flex',
          'justify-center',
          'items-start',
          'py-16'
        )}
      >
        <Dialog.Content
          className="w-[543px] bg-white p-8 rounded relative"
          onPointerDownOutside={(e) => {
            const currentTarget = e.currentTarget as HTMLElement;

            if (e.detail.originalEvent.offsetX > currentTarget.clientWidth) {
              e.preventDefault();
            }
          }}
        >
          {children}
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  );
};

const TopLeftModalButton = ({
  children,
  asChild,
}: {
  children: React.ReactNode;
  asChild?: boolean;
}) => {
  const Comp = asChild ? Slot : 'button';
  return (
    <Comp className="absolute top-[22px] left-[22px] h-6 w-6 flex justify-center items-center">
      {children}
    </Comp>
  );
};

const TopRightModalButton = ({
  children,
  asChild,
}: {
  children: React.ReactNode;
  asChild?: boolean;
}) => {
  const Comp = asChild ? Slot : 'button';
  return (
    <Comp className="absolute top-[22px] right-[22px] h-6 w-6 flex justify-center items-center">
      {children}
    </Comp>
  );
};

const CloseModalButton = () => {
  return (
    <Dialog.Close className="absolute top-[22px] right-[22px] h-6 w-6 flex justify-center items-center">
      <SvgIcon name="CloseModalIcon" />
    </Dialog.Close>
  );
};

const ModalTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Dialog.Title className="heading-400 text-neutral-900 mb-2">
      {children}
    </Dialog.Title>
  );
};

const Modal = {
  Root: Dialog.Root,
  Title: ModalTitle,
  Content: ModalContent,
  TopLeftButton: TopLeftModalButton,
  TopRightButton: TopRightModalButton,
  CloseButton: CloseModalButton,
  Close: Dialog.Close,
  Trigger: Dialog.Trigger,
};

export { Modal };
