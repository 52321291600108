import React from 'react';
import classNames from 'classnames';

type Border = 'dark' | 'light' | 'none';

type Padding = 'none' | 'sm' | 'md' | 'lg';

export type SurfaceProps = {
  children: React.ReactNode;
  border: Border;
  padding?: Padding;
  paddingX?: Padding;
  dashboard?: boolean;
};
const Surface: React.FC<SurfaceProps> = ({
  children,
  border,
  padding,
  paddingX,
  dashboard = false,
}) => {
  return (
    <section
      className={classNames(
        'rounded-lg bg-white',
        paddingX && paddingsX[paddingX],
        padding && paddings[padding],
        dashboard && !paddingX && 'px-[8px]',
        borders[border]
      )}
    >
      {children}
    </section>
  );
};

const borders: Record<Border, string> = {
  light: 'border border-neutral-300',
  dark: 'border border-neutral-400',
  none: '',
};

const paddings: Record<Padding, string> = {
  none: '',
  sm: 'p-4',
  md: 'p-7',
  lg: 'p-11',
};

const paddingsX: Record<Padding, string> = {
  none: '',
  sm: 'px-4',
  md: 'px-7',
  lg: 'px-11',
};

export { Surface };
