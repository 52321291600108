import { loansBaseApi } from '.';
import { rootApi } from '../rootApi';
import { OriginatorInvestor } from 'kennek/interfaces/accounts/investor';
import type { Loan } from '@/interfaces/loans';
import type {
  CashFlowRow,
  LoanInvestment,
  LoanInvestorsMutationPayload,
} from '@/interfaces/loans/queries';

const loanInvestorsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    addInvestments: builder.mutation<void, LoanInvestorsMutationPayload>({
      query: (payload) => ({
        url: loansBaseApi + '/investment',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Loans'],
    }),
    getInvestments: builder.query<LoanInvestment[], Loan['encodedKey']>({
      query: (loanEncodedKey) => ({
        url: `${loansBaseApi}/${loanEncodedKey}/investment`,
      }),
      providesTags: ['Loans'],
    }),
    getInvestmentsInvestors: builder.query<
      OriginatorInvestor[],
      Loan['encodedKey']
    >({
      query: (loanEncodedKey) => ({
        url: `${loansBaseApi}/${loanEncodedKey}/investments-investors`,
      }),
      providesTags: ['Loans'],
    }),
    getInvestorsCashFlow: builder.query<CashFlowRow[], Loan['id']>({
      query: (loanId) => ({
        url: `${loansBaseApi}/investor-cash-flow/${loanId}`,
      }),
      providesTags: ['Loans'],
    }),
    deleteInvestment: builder.mutation<string, string>({
      query: (id) => ({
        url: `${loansBaseApi}/investment/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Loans'],
    }),
  }),
});

export const {
  useAddInvestmentsMutation,
  useGetInvestmentsQuery,
  useGetInvestmentsInvestorsQuery,
  useGetInvestorsCashFlowQuery,
  useDeleteInvestmentMutation,
} = loanInvestorsApi;

export const { useQuerySubscription: useGetInvestmentsQuerySubscription } =
  loanInvestorsApi.endpoints.getInvestments;
