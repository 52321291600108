import React from 'react';
import BanknotesIcon from 'kennek/icons/svg/BanknotesIcon';
import { Dialog } from 'ui';
import { CREATE_FUNDING_METHOD_DIALOG_TITLE } from './InvestmentFundingMethod.constants';
import {
  InvestmentFundingMethodCreateFormData,
  InvestmentFundingMethodFeeSettings,
} from './InvestmentFundingMethod.types';
import { InvestmentFundingMethodCreateForm } from './InvestmentFundingMethodCreateForm';

type InvestmentFundingMethodCreateFormDialogProps = {
  isOpen: boolean;
  isLoading?: boolean;
  feesSettings: InvestmentFundingMethodFeeSettings[];
  isSubmitting?: boolean;
  onClose: () => void;
  onBack: () => void;
  onSubmit: (data: InvestmentFundingMethodCreateFormData) => void;
};

export const InvestmentFundingMethodCreateFormDialog: React.FC<
  InvestmentFundingMethodCreateFormDialogProps
> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.onClose()}
      dialogIconColor="success"
      dialogIcon={<BanknotesIcon />}
      dialogIconTitle={CREATE_FUNDING_METHOD_DIALOG_TITLE}
      onBack={() => props.onBack()}
      isLoading={props.isLoading}
    >
      <InvestmentFundingMethodCreateForm
        feesSettings={props.feesSettings}
        onSubmit={(data) => props.onSubmit(data)}
        onCancel={() => props.onBack()}
        isSubmitting={props.isSubmitting}
      />
    </Dialog>
  );
};
