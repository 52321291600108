import React from 'react';
import { IconProps } from '../iconTypes';

const PayOffIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M0 14.8574C-7.3177e-09 14.2262 0.497462 13.7145 1.11111 13.7145H3.33333L3.33333 3.42881C3.33333 1.53527 4.82572 0.000244245 6.66667 0.000244222L13.3333 0.000244141C15.1743 0.000244118 16.6667 1.53527 16.6667 3.42881V13.7145H18.8889C19.5025 13.7145 20 14.2262 20 14.8574C20 15.4886 19.5025 16.0002 18.8889 16.0002L1.11111 16.0002C0.497462 16.0002 7.31769e-09 15.4886 0 14.8574ZM7.51544 13.7145H5.55556V5.08391C6.17743 4.97915 6.77483 4.68023 7.25516 4.18618C7.77344 3.65308 8.07085 2.9798 8.14857 2.28596H11.7036C11.7813 2.9798 12.0787 3.65308 12.597 4.18618C13.1153 4.71927 13.7699 5.02517 14.4444 5.10511V13.7145H12.4846C13.0124 13.1079 13.3333 12.3069 13.3333 11.4288C13.3333 9.53527 11.841 8.00024 10 8.00024C8.15905 8.00024 6.66667 9.53527 6.66667 11.4288C6.66667 12.3069 6.98762 13.1079 7.51544 13.7145ZM11.1111 11.4288C11.1111 12.06 10.6136 12.5717 10 12.5717C9.38635 12.5717 8.88889 12.06 8.88889 11.4288C8.88889 10.7976 9.38635 10.286 10 10.286C10.6136 10.286 11.1111 10.7976 11.1111 11.4288Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default PayOffIcon;
