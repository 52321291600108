import React from 'react';
import { IconProps } from '../iconTypes';

const EditColumnsIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 18"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M6.91176 15.5V2.5H2.5V15.5H6.91176ZM0.5 17.5V0.5H21.5V17.5H0.5ZM13.0882 15.5H8.91176V2.5H13.0882V15.5ZM15.0882 15.5H19.5V2.5H15.0882V15.5Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default EditColumnsIcon;
