import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { SvgIcon } from 'kennek/icons';
import { Button, FormInput, Select, Title } from 'ui';
import { ROUTES } from '@/constants/routes';
import { SNACKBAR_ONBOARDING } from '@/constants/snackbar-messages';
import {
  CustomInformationChildProps,
  CustomInformationResponse,
} from '@/features/onboarding/custom-information/utils';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useGetUsersByRoleQuery } from '@/services/accounts';
import { useUpdateCustomInformationMutation } from '@/services/loans';
import { getRouterQuery } from '@/utils/helpers';
import { isEmpty } from '@/utils/validation';
import {
  KennekDemoCustomInfoForm,
  brokenRegionOptions,
  chargeOptions,
  emptyKennekDemoCustomInformation,
  kennekDemoFieldsVisibility,
  valuationCompanyOptions,
} from './kennek-demo.utils';
import { UserTypes } from 'kennek/interfaces/accounts';

export const KennekDemo = ({
  loanId,
  initialValues,
  isEdit = false,
  isRestructure = false,
  originatorId,
  isPatchingTaskStatus,
  onBack,
  refetch,
  handleContinue,
  saveCustomInformation,
}: CustomInformationChildProps) => {
  const mappedDataToKeyValues = Object.fromEntries(
    initialValues?.map(({ key, value }) => [key, value])
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<KennekDemoCustomInfoForm>({
    defaultValues: !isEmpty(mappedDataToKeyValues)
      ? mappedDataToKeyValues
      : emptyKennekDemoCustomInformation,
  });

  const router = useRouter();
  const snackbar = useSnackbar();

  const [
    updateCustomInformationData,
    { isLoading: isUpdatingCustomInformationData },
  ] = useUpdateCustomInformationMutation();

  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: isUsersGetError,
  } = useGetUsersByRoleQuery({
    role: 'ORIGINATOR_ADMIN',
    branchEncodedKey: originatorId,
  });

  const businessDevelopmentManagerOptions = users?.map((user) => {
    return { label: user.userName, value: user.userName };
  });

  useEffect(() => {
    if (isUsersGetError) {
      snackbar.show({
        severity: 'error',
        title: SNACKBAR_ONBOARDING.CUSTOM_INFORMATION_FAILED_GET_USERS,
      });
    }
  }, [isUsersGetError]);
  const onSubmit: SubmitHandler<KennekDemoCustomInfoForm> = (
    values: KennekDemoCustomInfoForm
  ) => {
    const data = Object.entries(values).map(([key, value]) => {
      const visibility: UserTypes[] =
        kennekDemoFieldsVisibility.find((item) => item.key === key)
          ?.visibility || [];
      return {
        key,
        value,
        visibility: visibility,
      };
    });

    const customInformation: CustomInformationResponse = {
      loanId,
      data,
    };

    const path = getRouterQuery(ROUTES.LOAN_DETAILS, {
      loanId,
    });

    if (isRestructure) {
      saveCustomInformation(data);
      return;
    }

    updateCustomInformationData({
      loanId: loanId,
      payload: customInformation,
    })
      .unwrap()
      .then(() => {
        snackbar.show({
          severity: 'success',
          title: SNACKBAR_ONBOARDING.CUSTOM_INFORMATION_SUCCESS,
        });
        if (!isEdit) handleContinue();
        else router.push(path);
        refetch();
      })
      .catch(() => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_ONBOARDING.CUSTOM_INFORMATION_FAILED_SAVE,
        });
      });
  };

  return (
    <div className="flex flex-col gap-8">
      <Title
        title="Additional information"
        icon={<SvgIcon name="StatementIcon" />}
        titleSize="lg"
        className="mt-2 mb-1"
      />
      <form
        className="form stepper-form"
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
      >
        <div className="form-input">
          <span className="mb-4 font-semibold">Visible to borrower</span>
          <div className="mb-4">
            <FormInput
              label="Broker company"
              errors={errors?.brokerCompany?.message}
              bottomLabel="Optional"
              placeholder="Broker company"
              {...register('brokerCompany')}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="brokerRegion"
              control={control}
              render={({ field }) => (
                <Select
                  label="Broker Region"
                  ref={field.ref}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  options={brokenRegionOptions}
                  error={errors?.charge?.message}
                  bottomLabel="Optional"
                />
              )}
            />
          </div>
          <div className="mb-4">
            <FormInput
              label="Broker individual"
              placeholder="Broker individual"
              errors={errors?.brokerIndividual?.message}
              bottomLabel="Optional"
              {...register('brokerIndividual')}
            />
          </div>

          <div className="mb-4">
            <FormInput
              label="Solicitor name"
              errors={errors?.solicitorName?.message}
              bottomLabel="Optional"
              placeholder="Solicitor name"
              {...register('solicitorName')}
            />
          </div>
        </div>
        <div className="form-input">
          <span className="mb-4 font-semibold">Only visible to you</span>
          <div className="mb-4">
            <Controller
              name="businessDevelopmentManager"
              control={control}
              render={({ field }) => (
                <Select
                  label="Business development manager"
                  ref={field.ref}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  options={businessDevelopmentManagerOptions ?? []}
                  placeholder="Select one..."
                  error={errors?.businessDevelopmentManager?.message}
                  bottomLabel="Optional"
                />
              )}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="valuationCompany"
              control={control}
              render={({ field }) => (
                <Select
                  label="Valuation company"
                  ref={field.ref}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  options={valuationCompanyOptions}
                  placeholder="Select one..."
                  error={errors?.valuationCompany?.message}
                  bottomLabel="Optional"
                />
              )}
            />
          </div>
          <div className="mb-4">
            <FormInput
              label="Date of valuation"
              type="date"
              errors={errors?.dateOfValuation?.message}
              bottomLabel="Optional"
              {...register('dateOfValuation')}
            />
          </div>

          <div className="mb-4">
            <Controller
              name="charge"
              control={control}
              defaultValue={chargeOptions[0].value}
              render={({ field }) => (
                <Select
                  label="Charge"
                  ref={field.ref}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  options={chargeOptions}
                  error={errors?.charge?.message}
                  bottomLabel="Optional"
                />
              )}
            />
          </div>
          <div className="mb-4">
            <FormInput
              label="Final risk assessment score"
              type="number"
              errors={errors?.finalRiskAssessmentScore?.message}
              placeholder="Final risk assessment score"
              bottomLabel="Optional"
              {...register('finalRiskAssessmentScore')}
            />
          </div>
        </div>
        <div className={`flex ${isEdit ? 'justify-end' : 'justify-between'}`}>
          {!isEdit && (
            <Button layout="ghost" className="mt-6" onClick={onBack}>
              Back
            </Button>
          )}
          <Button
            className="mt-6 items-end"
            loading={
              isUpdatingCustomInformationData ||
              isLoadingUsers ||
              isPatchingTaskStatus
            }
            onClick={handleSubmit(onSubmit)}
          >
            {isEdit ? 'Save Changes' : 'Save and continue'}
          </Button>
        </div>
      </form>
    </div>
  );
};
