import { FileData } from '@/components/loans/import-schedule/ImportSchedule';
import { checkDateIsInThePast } from '@/utils/dates';
import { zonedDate } from '@/utils/datetime';
import { toFloatOrDefault } from '@/utils/helpers';
import { findFeeInRules } from '../utils';
import { ScheduleFormType } from './ScheduleForm';
import { RawScheduleForm } from './raw-schedule-form';
import {
  InterestGraceHandleMethod,
  LoanProductRules,
} from '@/interfaces/loans';

export function setDefaultFormFields(
  data: RawScheduleForm,
  interestGrace: boolean
): void {
  if (!data) {
    return;
  }

  if (interestGrace) {
    data.interestGraceHandleMethod =
      InterestGraceHandleMethod.DEDUCT_ON_DISBURSEMENT;
  }
}

export function mapFromRawForm(
  { tranches, ...rest }: RawScheduleForm,
  importedSchedule?: FileData
): ScheduleFormType {
  return {
    ...rest,
    importedSchedule,
    tranches: tranches?.map((tranche) => {
      return {
        ...tranche,
        facilities: tranche?.facilities
          ? Object.values(tranche.facilities)
          : [],
        disbursementAmount: toFloatOrDefault(tranche.disbursementAmount),
        fees: Object.entries(tranche?.fees ?? {}).reduce(
          (acc, [predefinedFeeEncodedKey, rawContent]) => {
            const amount = toFloatOrDefault(rawContent.amount);
            return rawContent.enabled &&
              checkDateIsInThePast(zonedDate(tranche.disbursementDate))
              ? { ...acc, [predefinedFeeEncodedKey]: { amount } }
              : acc;
          },
          {}
        ),
      };
    }),
  };
}

export function mapToRawForm({
  scheduleForm: { tranches, ...rest },
  loanProductRules,
}: {
  scheduleForm: ScheduleFormType;
  loanProductRules: LoanProductRules;
}): RawScheduleForm {
  // fill default values as empty string in order to override previous values when adding/removing tranche
  const emptyFees: Record<string, { amount: string; enabled: boolean }> =
    loanProductRules?.fees?.reduce(
      (acc, fee) => ({
        ...acc,
        [fee.id]: {
          amount: '',
          enabled: false,
        },
      }),
      {}
    );

  return {
    ...rest,
    tranches: tranches?.map((tranche) => ({
      ...tranche,
      facilities: tranche?.facilities ? Object.values(tranche.facilities) : [],
      disbursementAmount: tranche?.disbursementAmount?.toString(),
      fees: Object.entries(emptyFees ?? {}).reduce(
        (acc, [predefinedFeeEncodedKey]) =>
          Object.hasOwnProperty.call(tranche?.fees, predefinedFeeEncodedKey) ||
          !!findFeeInRules(loanProductRules, predefinedFeeEncodedKey).required
            ? {
                ...acc,
                [predefinedFeeEncodedKey]: {
                  amount:
                    tranche?.fees[predefinedFeeEncodedKey]?.amount?.toString(),
                  enabled: true,
                },
              }
            : acc,
        emptyFees
      ),
    })),
  };
}
