import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  FieldArrayWithId,
  useFieldArray,
  useForm,
  useFormState,
} from 'react-hook-form';
import classNames from 'classnames';
import { Button, FormInput, KebabButton } from 'ui';
import { DevTool } from '@hookform/devtools';
import {
  AddInvestorDialog,
  AddInvestorDialogResult,
} from '@/features/onboarding/investments/dialogs/AddInvestorDialog';
import {
  AddILenderOfRecordDialog,
  AddILenderOfRecordDialogResult,
} from '@/features/onboarding/investments/dialogs/AddLenderOfRecordDialog';
import { useConfirmDialog } from '@/hooks/useConfirmDialog';
import { formatNumberWithDecimalsIfPresent } from '@/utils/helpers';
import { sum } from '@/utils/math';
import { isNotEmpty } from '@/utils/validation';
import { getDefaultInvestorName } from '../utils/defaultInvestor';
import {
  InvestmentFormProps,
  InvestmentsFormData,
  InvestmentsFormRef,
} from './InvestmentsForm.types';
import {
  InvestmentsFormColumName,
  createEmptyInvestment,
  getAddInvestorButtonLabel,
  getLabelByValue,
} from './InvestmentsForm.utils';
import {
  InvestmentsFormAmountInput,
  InvestmentsFormColumnsHeading,
  InvestmentsFormInterestRateInput,
  InvestmentsFormInvestorInput,
  InvestmentsFormLenderOfRecordInput,
  InvestmentsFromDefaultInvestorInputs,
} from './components';
import { InvestmentsFormPercentageInput } from './components/InvestmentsFormPercentageInput';
import { InvestmentsFormTypeInput } from './components/InvestmentsFormTypeInput';
import { InvestmentAmountType } from '@/interfaces/loans';

const InvestmentsForm = forwardRef<InvestmentsFormRef, InvestmentFormProps>(
  function InvestmentsForm(
    {
      investments,
      investors,
      lenderOfRecords,
      maxInvestmentsAmount = 0,
      fixedInterestRate,
      disabled,
      currency,
      originatorEncodedKey,
      showInterestRateField = true,
      onFormStateChange,
      onBeforeInvestmentDelete,
      onAfterInvestmentDelete,
      defaultInvestor,
      frequency,
      isEditing,
      showInvestmentTypeField,
      investmentAmountType,
    }: InvestmentFormProps,
    ref
  ) {
    const {
      control,
      handleSubmit,
      formState: { errors },
      watch,
      setValue,
      trigger,
      getValues,
      clearErrors,
    } = useForm<InvestmentsFormData>({
      mode: 'onChange',
      defaultValues: {
        investments: investments.filter(Boolean).map((investment) => ({
          ...investment,
          amount:
            investmentAmountType === InvestmentAmountType.AMOUNT
              ? investment.amount
              : '',
          percentage:
            investmentAmountType === InvestmentAmountType.PERCENTAGE
              ? investment.percentage
              : '',
          interestRate:
            // Initialize with fixedInterestRate if provided
            fixedInterestRate?.toString() ?? investment.interestRate.toString(),
        })),
      },
    });

    const allInvestments = watch('investments');

    const maxInvestmentAmountFixed =
      formatNumberWithDecimalsIfPresent(maxInvestmentsAmount);

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'investments',
      keyName: 'rowId',
    });

    const { isDirty, isValid } = useFormState({ control });

    useEffect(() => {
      getValues('investments').forEach((investment, index) => {
        switch (investmentAmountType) {
          case InvestmentAmountType.AMOUNT:
            if (
              investment?.percentage &&
              !isNaN(parseFloat(investment.percentage))
            ) {
              setValue(
                `investments.${index}.amount`,
                (
                  (parseFloat(investment.percentage) * maxInvestmentsAmount) /
                  100
                ).toString()
              );
            }
            break;
          case InvestmentAmountType.PERCENTAGE:
            if (investment?.amount && !isNaN(parseFloat(investment.amount))) {
              setValue(
                `investments.${index}.percentage`,
                (
                  (parseFloat(investment.amount) / maxInvestmentsAmount) *
                  100
                ).toString()
              );
            }
            break;
        }
      });
      validateAmount();
    }, [setValue, getValues, investmentAmountType, maxInvestmentsAmount]);

    const validateAmount = async () => {
      const prevInvestment =
        investmentAmountType === InvestmentAmountType.AMOUNT
          ? InvestmentAmountType.PERCENTAGE
          : InvestmentAmountType.AMOUNT;

      fields.forEach((_, index) => {
        const fieldName: `investments.${number}.${InvestmentAmountType}` = `investments.${index}.${investmentAmountType}`;
        const prevFieldName: `investments.${number}.${InvestmentAmountType}` = `investments.${index}.${prevInvestment}`;
        const prevErr = errors.investments?.find(
          (x) => x && x[prevInvestment]?.ref?.name === prevFieldName
        );
        prevErr ? trigger(fieldName) : clearErrors(fieldName);
      });
    };

    // Use useEffect to call onFormStateChange when formState changes
    useEffect(() => {
      if (isValid || isDirty) {
        onFormStateChange(isValid ? 'Valid' : 'Invalid');
      }
    }, [isDirty, isValid, onFormStateChange]);

    const defaultInvestorAmount =
      maxInvestmentsAmount -
      sum(allInvestments, (investment) =>
        investment.amount ? parseFloat(investment.amount) : 0
      );

    const defaultInvestorPercentage =
      100 -
      sum(allInvestments, (investment) =>
        investment.percentage ? parseFloat(investment.percentage) : 0
      );

    // Trigger amounts validation each time max investments amount changes
    useEffect(() => {
      fields.forEach((investmentRow, index) => {
        if (investmentRow.amount && parseFloat(investmentRow.amount) > 0) {
          trigger(`investments.${index}.amount`);
        }
      });
    }, [maxInvestmentsAmount, fields, trigger]);

    // Ensure all interest rates are set to fixedInterestRate if it's provided or changed
    useEffect(() => {
      if (isNotEmpty(fixedInterestRate)) {
        fields.forEach((_field, index) => {
          setValue(
            `investments.${index}.interestRate`,
            fixedInterestRate.toString()
          );
        });
      }
    }, [fixedInterestRate, fields, setValue]);

    // new investment row can be added if all previous one have investor field fulfilled
    const lastInvestment = allInvestments.at(-1);
    const canAddMoreInvestments =
      !!lastInvestment?.investorId || !allInvestments.length;

    // appends new empty row to the form
    const onAddNewInvestment = () => {
      if (!canAddMoreInvestments) return;
      append(createEmptyInvestment());
    };

    const onDeleteInvestment = async (
      row: FieldArrayWithId<InvestmentFormProps, 'investments', 'rowId'>,
      index: number
    ) => {
      // call onBeforeInvestmentDelete if provided, otherwise continue
      const beforeInvestmentDeleteActionResult = onBeforeInvestmentDelete
        ? await onBeforeInvestmentDelete(row, row.id)
        : true;

      if (beforeInvestmentDeleteActionResult) {
        remove(index);
      }

      if (onAfterInvestmentDelete) {
        await onAfterInvestmentDelete(getValues('investments'));
      }
    };

    // creates submit method witch return form value if form is valid, otherwise returns null
    useImperativeHandle(ref, () => ({
      submit: () =>
        new Promise((resolve) => {
          handleSubmit(
            (data: InvestmentsFormData) => {
              resolve(data?.investments ?? []);
            },
            () => resolve(undefined)
          )();
        }),
    }));

    const {
      closeDialog: closeInvestorDialog,
      isOpen: isInvestorDialogOpen,
      openDialog: openInvestorDialog,
    } = useConfirmDialog<Record<string, never>, AddInvestorDialogResult>();

    const {
      closeDialog: closeLenderOfRecordDialog,
      isOpen: isLenderOfRecordDialogOpen,
      openDialog: openLenderOfRecordDialog,
    } = useConfirmDialog<
      Record<string, never>,
      AddILenderOfRecordDialogResult
    >();

    // adds new investor and set it as picked
    const handleAddNewInvestorClick = async (index: number) => {
      const dialogResult = await openInvestorDialog(null);
      if (!dialogResult.confirmed) return;
      investors.push({
        label: dialogResult.result.investorName,
        value: dialogResult.result.investorId,
      });
      setValue(
        `investments.${index}.investorId`,
        dialogResult.result.investorId
      );
      trigger(`investments.${index}.investorId`);
    };

    // adds new lender of record and set it as picked
    const handleAddNewLenderOfRecordClick = async (index: number) => {
      const dialogResult = await openLenderOfRecordDialog(null);
      if (!dialogResult.confirmed) return;
      lenderOfRecords.push({
        label: dialogResult.result.lenderOfRecord.name,
        value: dialogResult.result.lenderOfRecord._id,
      });
      setValue(
        `investments.${index}.lenderOfRecordId`,
        dialogResult.result.lenderOfRecord._id
      );
      trigger(`investments.${index}.lenderOfRecordId`);
    };

    return (
      <>
        <AddInvestorDialog
          closeDialog={closeInvestorDialog}
          isOpen={isInvestorDialogOpen}
        />
        <AddILenderOfRecordDialog
          closeDialog={closeLenderOfRecordDialog}
          isOpen={isLenderOfRecordDialogOpen}
          originatorEncodedKey={originatorEncodedKey}
        />
        <div className="w-full">
          <div className="flex flex-col mb-5">
            <InvestmentsFormColumnsHeading
              visibleColumns={{
                [InvestmentsFormColumName.INVESTOR_NAME]: true,
                [InvestmentsFormColumName.LENDER_OF_RECORDS]: true,
                [InvestmentsFormColumName.AMOUNT_INVESTED]: true,
                [InvestmentsFormColumName.INVESTOR_RATE]: showInterestRateField,
                [InvestmentsFormColumName.FREQUENCY]: !!frequency,
              }}
            />
            <div>
              {isNotEmpty(defaultInvestor) && (
                <InvestmentsFromDefaultInvestorInputs
                  loanCurrency={currency}
                  visibleInputs={{ INVESTOR_RATE: showInterestRateField }}
                  investment={{
                    frequency,
                    investorName: getDefaultInvestorName(defaultInvestor),
                    lenderOfRecordsName: defaultInvestor?.lenderOfRecord?.name,
                    investorInvestedAmount: defaultInvestorAmount,
                    investorInvestedPercentage: defaultInvestorPercentage,
                    investorCurrentInterest: defaultInvestor?.currentInterest,
                  }}
                  investmentAmountType={investmentAmountType}
                />
              )}
              {fields.map((row, index) => (
                <div
                  key={row?.rowId}
                  className="border-b border-neutral-300 mb-5 mx-3"
                >
                  <div className="flex items-top justify-between">
                    <div className="flex gap-6">
                      {isEditing && investments?.[index]?.investorId ? (
                        <div className="w-[180px] mt-[18px] heading-200">
                          {getLabelByValue(
                            investors,
                            investments[index].investorId
                          )}
                        </div>
                      ) : (
                        <InvestmentsFormInvestorInput
                          control={control}
                          index={index}
                          onAddInvestorClick={(index) =>
                            handleAddNewInvestorClick(index)
                          }
                          onChange={(index) =>
                            trigger(`investments.${index}.investorId`)
                          }
                          error={
                            errors.investments?.at(index)?.investorId?.message
                          }
                          disabled={disabled}
                          options={investors ?? []}
                        />
                      )}
                      {isEditing && investments?.[index]?.lenderOfRecordId ? (
                        <div className="w-[232px] mt-[18px] heading-200">
                          {getLabelByValue(
                            lenderOfRecords,
                            investments[index].lenderOfRecordId
                          )}
                        </div>
                      ) : (
                        <InvestmentsFormLenderOfRecordInput
                          control={control}
                          index={index}
                          onAddNewClick={(index) =>
                            handleAddNewLenderOfRecordClick(index)
                          }
                          options={lenderOfRecords ?? []}
                          disabled={disabled}
                          error={
                            errors.investments?.at(index)?.lenderOfRecordId
                              ?.message
                          }
                        />
                      )}

                      {investmentAmountType === InvestmentAmountType.AMOUNT ? (
                        <InvestmentsFormAmountInput
                          control={control}
                          rules={{ maxAmount: maxInvestmentAmountFixed }}
                          currency={currency}
                          index={index}
                          disabled={disabled}
                          error={errors.investments?.at(index)?.amount?.message}
                        />
                      ) : (
                        <InvestmentsFormPercentageInput
                          control={control}
                          index={index}
                          disabled={disabled}
                          error={
                            errors.investments?.at(index)?.percentage?.message
                          }
                        />
                      )}

                      {showInterestRateField && (
                        <InvestmentsFormInterestRateInput
                          control={control}
                          fixedInterestRate={fixedInterestRate}
                          index={index}
                          disabled={disabled || fixedInterestRate !== null}
                          error={
                            fixedInterestRate === null
                              ? errors.investments?.at(index)?.interestRate
                                  ?.message
                              : null
                          }
                        />
                      )}

                      {!!frequency && (
                        <FormInput
                          disabled={true}
                          containerClassName={classNames('w-[192x] mt-[0px]')}
                          value={frequency}
                        />
                      )}
                    </div>
                    <div className={'mt-[5px]'}>
                      <KebabButton
                        dropDownItems={[
                          {
                            text: 'Delete',
                            onClickHandler: () =>
                              onDeleteInvestment(row, index),
                          },
                        ]}
                      />
                    </div>
                  </div>
                  {showInvestmentTypeField && (
                    <div className="bg-neutral-200 px-4 py-4 rounded-lg mb-4 mt-[-12px]">
                      <InvestmentsFormTypeInput
                        index={index}
                        control={control}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <Button
            layout="ghost"
            onClick={onAddNewInvestment}
            disabled={!canAddMoreInvestments}
            className="ml-1"
          >
            {getAddInvestorButtonLabel(watch('investments')?.length)}
          </Button>
        </div>
        <DevTool control={control} />
      </>
    );
  }
);

export { InvestmentsForm };
