import React from 'react';
import classNames from 'classnames';
import { Switch } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';

interface ToggleSwitchProps {
  id?: string;
  enabled: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  size?: 'sm' | 'md';
  title?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  disabled = false,
  enabled,
  onChange,
  size = 'md',
  title,
}) => {
  return (
    <Switch
      id={id}
      checked={enabled}
      onChange={disabled ? () => {} : onChange}
      title={title}
      className={classNames(
        'relative inline-flex items-center rounded-full hover:drop-shadow-md focus:outline-none focus:ring-primary-100 focus:border-primary-100 focus:ring-0 focus:outline-primary-500 focus:ring',
        toggleSizes[size],
        {
          'bg-neutral-300': disabled,
          'bg-primary-500': !disabled && enabled,
          'bg-neutral-400': !enabled && !disabled,
        }
      )}
    >
      {enabled && (
        <CheckIcon
          className={classNames(
            'absolute left-2',
            checkMarkSizes[size],
            disabled ? 'text-neutral-500' : 'text-white'
          )}
        />
      )}
      <span
        className={classNames(
          'inline-block transform ease-in-out duration-200 rounded-full border-[3px] border-',
          buttonCircle[size],
          {
            'translate-x-6': enabled,
            'translate-x-0': !enabled,
            'bg-neutral-500 border-neutral-300': disabled,
            'bg-white border-neutral-400': !enabled && !disabled,
            'bg-white border-primary-500': !disabled && enabled,
          }
        )}
      />
    </Switch>
  );
};

export default ToggleSwitch;

const checkMarkSizes = {
  sm: 'w-3.5',
  md: 'w-4',
};

const buttonCircle = {
  sm: 'h-5 w-5',
  md: 'w-6 h-6',
};

const toggleSizes = {
  sm: 'h-5 w-10',
  md: 'h-6 w-11',
};
