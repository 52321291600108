import classNames from 'classnames';

interface Props {
  color?: string;
  colorWeight?: '100' | '300' | '500' | '700';
  fitBox?: boolean;
  size?: string;
  lineWidth?: string;
}

const Spinner = ({
  color = 'primary',
  colorWeight = '300',
  fitBox = false,
  size = '16',
  lineWidth = '4',
}: Props): JSX.Element => {
  return (
    <div
      className={classNames({
        'absolute left-1/2 top-1/2': !fitBox,
        relative: fitBox,
      })}
    >
      <div
        className={classNames(
          `w-${size} h-${size} border-${lineWidth} border-solid rounded-full border-t-transparent animate-spin`,
          `border-${color}-${colorWeight}`
        )}
      ></div>
    </div>
  );
};

export default Spinner;
