import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Select, SelectOption } from 'ui';
import { InvestmentsFormData } from '../InvestmentsForm.types';

type InvestmentsFormLenderOfRecordInputProps = {
  control: Control<InvestmentsFormData, unknown>;
  index: number;
  options: SelectOption[];
  onAddNewClick: (index: number) => void;
  disabled?: boolean;
  error?: string;
};
export const InvestmentsFormLenderOfRecordInput: React.FC<
  InvestmentsFormLenderOfRecordInputProps
> = (props) => {
  return (
    <Controller
      name={`investments.${props.index}.lenderOfRecordId`}
      control={props.control}
      rules={{ required: 'Lender of Record is required' }}
      render={({ field }) => {
        return (
          <Select
            {...field}
            value={field.value}
            placeholder="Select a Lender of record"
            options={props.options}
            optionsButton={{
              text: '+ Add Lender of record',
              onClick: () => props.onAddNewClick(props.index),
            }}
            disabled={props.disabled}
            error={props.error}
            className="w-[232px] mt-[6px]"
            sortOptions
          />
        );
      }}
    />
  );
};
