import { useSelectUser } from '@/store/user/selectors';
import { UserIdentity } from '@/interfaces';

const useReturnUserRole = (): UserIdentity => {
  const user = useSelectUser();
  return {
    isBorrower: user?.roles[0]?.name === 'BORROWER',
    isOriginator: user?.roles[0]?.name === 'ORIGINATOR_ADMIN',
  };
};

export default useReturnUserRole;
