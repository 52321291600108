import { SvgIcon } from 'kennek/icons';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { FolderIcon } from '@heroicons/react/solid';
import { DocumentManagementRowType } from '@/utils/documentManagement';
import { allFileType, allFolderType } from '../EditDocumentsTable.utils';

const DocumentManagementRowIcon = ({
  rowType,
}: {
  rowType: DocumentManagementRowType;
}) => {
  if (rowType === DocumentManagementRowType.FOLDER)
    return (
      <FolderIcon className="w-5 h-5 mr-2 text-neutral-600 min-w-[20px]" />
    );
  if (allFolderType.includes(rowType))
    return (
      <SvgIcon
        name="MyFolder"
        width={21}
        height={21}
        className="mr-[9px] ml-[-1px] min-w-[21px]"
      />
    );
  if (allFileType.includes(rowType))
    return (
      <DocumentTextIcon className="w-5 h-5 text-neutral-600 mr-2 min-w-[20px]" />
    );
  return null;
};

export default DocumentManagementRowIcon;
