import React, { useState } from 'react';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useCreateInvestmentFundingMethodMutation,
  useDeleteInvestmentFundingMethodMutation,
  useGetInvestmentFundingMethodsFeesSettingsQuery,
  useGetInvestmentFundingMethodsQuery,
  useUpdateInvestmentFundingMethodMutation,
} from '@/services/loans/investment-funding-methods';
import {
  InvestmentFundingMethod,
  InvestmentFundingMethodCreateFormData,
  InvestmentFundingMethodEditFormData,
} from './InvestmentFundingMethod.types';
import {
  FundingMethodSnackbarId,
  displayInvestmentFundingMethodsSnackbar,
} from './InvestmentFundingMethod.utils';
import { InvestmentFundingMethodCreateFormDialog } from './InvestmentFundingMethodCreateFormDialog';
import { InvestmentFundingMethodDeleteConfirmationDialog } from './InvestmentFundingMethodDeleteConfirmationDialog';
import { InvestmentFundingMethodEditFormDialog } from './InvestmentFundingMethodEditFromDialog';
import { InvestmentFundingMethodListDialog } from './InvestmentFundingMethodListDialog';
import { Loan } from '@/interfaces/loans';

type InvestmentFundingMethodDialogsContainerProps = {
  loan: Loan;
  investmentFundingMethods: InvestmentFundingMethod[];
  selectedInvestmentFundingMethod: InvestmentFundingMethod;
  defaultInvestmentFundingMethod: InvestmentFundingMethod;
  onInvestmentFundingMethodChange: (
    investmentFundingMethod: InvestmentFundingMethod
  ) => void;
  listDialogOpen: {
    value: boolean;
    onChange: (value: boolean) => void;
  };
  createDialogOpen: {
    value: boolean;
    onChange: (value: boolean) => void;
  };
};

export const InvestmentFundingMethodDialogsContainer: React.FC<
  InvestmentFundingMethodDialogsContainerProps
> = (props) => {
  const snackbar = useSnackbar();

  const [investmentFundingMethodToDelete, setInvestmentFundingMethodToDelete] =
    useState<InvestmentFundingMethod>(null);

  const [investmentFundingMethodToEdit, setInvestmentFundingMethodToEdit] =
    useState<InvestmentFundingMethod>(null);

  const getInvestmentFundingMethodsQuery = useGetInvestmentFundingMethodsQuery(
    { branchEncodedKey: props.loan?.assignedBranchKey },
    { skip: !props.loan }
  );

  if (getInvestmentFundingMethodsQuery.isError) {
    displayInvestmentFundingMethodsSnackbar(
      snackbar,
      FundingMethodSnackbarId.CANNOT_FETCH_FUNDING_METHODS
    );
  }

  const getInvestmentFundingMethodsFeesSettingsQuery =
    useGetInvestmentFundingMethodsFeesSettingsQuery(
      { branchEncodedKey: props.loan?.assignedBranchKey },
      { skip: !props.loan }
    );

  if (getInvestmentFundingMethodsFeesSettingsQuery.isError) {
    displayInvestmentFundingMethodsSnackbar(
      snackbar,
      FundingMethodSnackbarId.CANNOT_FETCH_FUNDING_METHODS_FEES_SETTINGS
    );
  }

  const [createInvestmentFundingMethod, createInvestmentFundingMethodMutation] =
    useCreateInvestmentFundingMethodMutation();

  const [updateInvestmentFundingMethod, updateInvestmentFundingMethodMutation] =
    useUpdateInvestmentFundingMethodMutation();

  const [deleteInvestmentFundingMethod, deleteInvestmentFundingMethodMutation] =
    useDeleteInvestmentFundingMethodMutation();

  const investmentFundingMethodsFeesSettings =
    getInvestmentFundingMethodsFeesSettingsQuery.data ?? [];

  const handleCreateInvestmentFundingMethodFormSubmit = async (
    data: InvestmentFundingMethodCreateFormData
  ) => {
    try {
      await createInvestmentFundingMethod({
        branchEncodedKey: props.loan?.assignedBranchKey,
        dto: data,
      }).unwrap();
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.CREATE_SUCCESS
      );
      props.createDialogOpen.onChange(false);
      props.listDialogOpen.onChange(true);
    } catch (_) {
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.CREATE_ERROR
      );
    }
  };

  const handleUpdateInvestmentFundingMethodFormSubmit = async (
    data: InvestmentFundingMethodEditFormData
  ) => {
    try {
      const updatedInvestmentFundingMethod =
        await updateInvestmentFundingMethod({
          investmentFundingMethodId: data.id,
          branchEncodedKey: props.loan?.assignedBranchKey,
          dto: data,
        }).unwrap();
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.UPDATE_SUCCESS
      );
      setInvestmentFundingMethodToEdit(null);
      props.listDialogOpen.onChange(true);

      if (props.selectedInvestmentFundingMethod?.id === data.id) {
        props.onInvestmentFundingMethodChange(updatedInvestmentFundingMethod);
      }
    } catch (_) {
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.UPDATE_ERROR
      );
    }
  };

  const handleDeleteInvestmentFundingMethodFormSubmit = async () => {
    if (!investmentFundingMethodToDelete) return;

    try {
      await deleteInvestmentFundingMethod({
        investmentFundingMethodId: investmentFundingMethodToDelete.id,
        branchEncodedKey: props.loan?.assignedBranchKey,
      }).unwrap();

      if (
        props.selectedInvestmentFundingMethod?.id ===
        investmentFundingMethodToDelete?.id
      ) {
        props.onInvestmentFundingMethodChange(
          props.defaultInvestmentFundingMethod
        );
      }

      setInvestmentFundingMethodToDelete(null);
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.DELETE_SUCCESS
      );
    } catch (_) {
      displayInvestmentFundingMethodsSnackbar(
        snackbar,
        FundingMethodSnackbarId.DELETE_ERROR
      );
    }
  };

  return (
    <>
      <InvestmentFundingMethodCreateFormDialog
        feesSettings={investmentFundingMethodsFeesSettings}
        isOpen={props.createDialogOpen.value}
        onBack={() => {
          props.createDialogOpen.onChange(false);
          props.listDialogOpen.onChange(true);
        }}
        onClose={() => {
          props.createDialogOpen.onChange(false);
          props.listDialogOpen.onChange(true);
        }}
        onSubmit={(data) => {
          handleCreateInvestmentFundingMethodFormSubmit(data);
        }}
        isLoading={getInvestmentFundingMethodsFeesSettingsQuery.isLoading}
        isSubmitting={createInvestmentFundingMethodMutation.isLoading}
      />
      <InvestmentFundingMethodEditFormDialog
        fundingMethod={investmentFundingMethodToEdit}
        isOpen={!!investmentFundingMethodToEdit}
        onBack={() => {
          setInvestmentFundingMethodToEdit(null);
          props.listDialogOpen.onChange(true);
        }}
        onClose={() => {
          setInvestmentFundingMethodToEdit(null);
          props.listDialogOpen.onChange(false);
        }}
        onSubmit={(data) => {
          handleUpdateInvestmentFundingMethodFormSubmit(data);
        }}
        isSubmitting={updateInvestmentFundingMethodMutation.isLoading}
      />
      <InvestmentFundingMethodListDialog
        investmentFundingMethods={props.investmentFundingMethods}
        isOpen={props.listDialogOpen.value}
        onAddNewClicked={() => {
          props.listDialogOpen.onChange(false);
          props.createDialogOpen.onChange(true);
        }}
        onClose={() => props.listDialogOpen.onChange(false)}
        onDeleteClicked={(fundingMethod) =>
          setInvestmentFundingMethodToDelete(fundingMethod)
        }
        onEditClicked={(fundingMethod) => {
          props.listDialogOpen.onChange(false);
          setInvestmentFundingMethodToEdit(fundingMethod);
        }}
        onSelectClicked={(investmentFundingMethod) => {
          props.onInvestmentFundingMethodChange(investmentFundingMethod);
          props.listDialogOpen.onChange(false);
        }}
        isLoading={getInvestmentFundingMethodsQuery.isFetching}
      />
      <InvestmentFundingMethodDeleteConfirmationDialog
        onConfirm={() => handleDeleteInvestmentFundingMethodFormSubmit()}
        fundingMethodToDelete={investmentFundingMethodToDelete}
        onIsOpenChange={() => setInvestmentFundingMethodToDelete(null)}
        isLoading={deleteInvestmentFundingMethodMutation.isLoading}
      />
    </>
  );
};
