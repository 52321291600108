import { PageConfig } from './types';

export const getCompletedPagesUntilNow = (
  currentPageId: string,
  pages: PageConfig[]
): string[] => {
  if (!currentPageId) return [];
  const currentPage = pages.find((p) => p.id === currentPageId);
  const completedPages = pages.filter((p) => p.order < currentPage?.order);
  return completedPages.map((x) => x.id);
};
