export interface MetaData {
  [key: string]: any;
}

export enum DocumentManagementRoles {
  LENDX_ADMIN = 'LENDX_ADMIN',
  BORROWER = 'BORROWER',
  ORIGINATOR_ANALYST = 'ORIGINATOR_ANALYST',
  ORIGINATOR_ADMIN = 'ORIGINATOR_ADMIN',
  INVESTOR = 'INVESTOR',
  JOB_RUNNER = 'JOB_RUNNER',
  unauthenticated = 'unauthenticated',
}

export interface DocumentUrl {
  url: string;
}

export interface DocumentData {
  id?: string;
  loanEncodedKey?: string;
  executionId?: string;
  originatorId?: string;
  borrowerId?: string;
  storageKey?: string;
  bucket?: string;
  folderId?: string;
  name?: string;
  originalFilename?: string;
  mimeType?: string;
  size?: number;
  tags?: string[];
  isDraft?: boolean;
  isDraftDeleted?: boolean;
  draftName?: string;
  createdAt?: string;
  updatedAt?: string;
  metadata?: MetaData;
  fullPath?: string;
}

export type AccessType = 'upload' | 'browse';

export interface DocumentManagementAccess {
  id?: string;
  role: DocumentManagementRoles;
  action: AccessType;
  allow: boolean;
}

export interface UpdateDocumentPayload {
  folderId?: string;
  name?: string;
  draftName?: string;
  tags?: string[];
  isDraft?: boolean;
  isDraftDeleted?: boolean;
  metadata?: MetaData;
}

export interface CreateFolderPayload {
  name?: string;
  path?: string;
  access?: DocumentManagementAccess[];
  borrowerId?: string;
  loanEncodedKey?: string;
  executionId?: string;
  isDraft?: boolean;
  isDraftDeleted?: boolean;
  draftName?: string;
}

export interface GetFolderPayload {
  path: string;
  search?: string;
  originatorId?: string;
  borrowerId?: string;
  loanEncodedKey?: string;
  executionId?: string;
}

export interface GetFolderResponse {
  folders: Folder[];
  documents: DocumentData[];
  currentFolderId?: string;
  currentFolderAccess?: DocumentManagementAccess[];
}

export interface Folder {
  id: string;
  path: string;
  name: string;
  originatorId: string;
  borrowerId: string;
  loanEncodedKey: string;
  executionId: string;
  updatedAt: string;
  createdAt: string;
  isUserCreated: boolean;
  access: DocumentManagementAccess[];
  fullPath?: string;
  isDraftDeleted?: boolean;
  isDraft?: boolean;
  draftName?: string;
}
