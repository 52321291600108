import { useSession } from 'next-auth/react';
import { UserTypes } from 'kennek/interfaces/accounts';

export const ORIGINATOR_ROLES: UserTypes[] = [
  'ORIGINATOR_ADMIN',
  'ORIGINATOR_ANALYST',
];

type UseRoleReturnType = {
  role: UserTypes;
  isOriginator: boolean;
  isOriginatorAdmin: boolean;
  isOriginatorAnalyst: boolean;
  isBorrower: boolean;
  isInvestor: boolean;
  isAdmin: boolean;
  isLoading: boolean;
};

function useRole(): UseRoleReturnType {
  const session = useSession();

  const role = session.data?.accessRole as UserTypes;

  return {
    role,
    isOriginator: ORIGINATOR_ROLES.includes(role),
    isOriginatorAdmin: role === 'ORIGINATOR_ADMIN',
    isOriginatorAnalyst: role === 'ORIGINATOR_ANALYST',
    isBorrower: role === 'BORROWER',
    isInvestor: role === 'INVESTOR',
    isAdmin: role === 'LENDX_ADMIN',
    isLoading: !session.data?.accessRole,
  };
}

export { useRole };
