import React from 'react';
import { IconProps } from '../iconTypes';

const ReplyIcon: React.FC<IconProps> = (props) => (
  <svg
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    width="2"
    height="2"
    {...props}
  >
    <path
      d="M1.625 4.91667H7.04167C9.4349 4.91667 11.375 6.85677 11.375 9.25V10.3333M1.625 4.91667L4.875 8.16667M1.625 4.91667L4.875 1.66667"
      stroke="#184083"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ReplyIcon;
