import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ANNUAL_CALCULATION_DATES_SHORT } from 'kennek/constants/reports';
import { Button, FormInput, Modal, ModalTitleWithIcon } from 'ui';
import * as yup from 'yup';
import { ExclamationIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { ABSOLUTE_MAX_DATE } from '@/constants/numeric';
import { LOAN_MESSAGES } from '@/constants/onboarding';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { formatDate } from '@/utils/formatters';
import { Schedule } from '@/interfaces/schedules';

interface FillMissingFinancialYearEndFormProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  reports?: Schedule[];
  isSubmitting: boolean;
  onSubmit: (report: Partial<Schedule>) => Promise<void> | void;
}

const schema = yup
  .object({
    financialYearEnd: yup
      .date()
      .nullable()
      .transform((v) => (v instanceof Date && !isNaN(+v) ? v : null))
      .test(
        'is-required',
        'Borrower next financial year end date cannot be empty',
        (value) => {
          return (
            !!value &&
            !!new Date(value).getFullYear() &&
            new Date(value).getMonth() !== undefined &&
            !!new Date(value).getDate()
          );
        }
      )
      .test(
        'is-current-day-or-later',
        'Borrower next financial year end date cannot be past date',
        (value) => {
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);

          return new Date(value) >= currentDate;
        }
      ),
  })
  .required();

function FillMissingFinancialYearEndForm({
  showModal,
  setShowModal,
  onSubmit,
  isSubmitting,
  reports,
}: FillMissingFinancialYearEndFormProps) {
  const { borrowerUpper, borrowerLower } = useGetLabelsConfig();
  const {
    register,
    formState: { errors, isValid },
    getValues,
    reset,
    clearErrors,
    trigger,
  } = useForm({
    shouldFocusError: false,
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const saveReport = async () => {
    clearErrors();
    await trigger();
    if (isValid) {
      onSubmit(getValues());
    }
  };

  useEffect(() => {
    if (!showModal) {
      reset();
    }
  }, [showModal]);

  return (
    <Modal
      isVisible={showModal}
      closeOnClickOutside={false}
      includeHeader="withCloseButton"
      setIsVisible={setShowModal}
      className="bg-opacity-40 top-0 bg-neutral-800 overflow-y-auto z-[101]"
      paddingBodyX="px-8 py-4 pb-4 mt-0 mb-0 pt-0"
      width="w-full max-w-[384px]"
    >
      <div className="flex flex-col">
        <ModalTitleWithIcon
          color="destructive"
          title={`${borrowerUpper}'s next financial year end`}
          icon={<ExclamationIcon className="w-6 h-6" />}
        />
        <p className="text-center mb-4 body-400">
          Please provide the {borrowerLower}&apos;s next financial year end in
          order to schedule the below report requests
        </p>

        <FormInput
          label={`${borrowerUpper}'s next financial year end`}
          placeholder="Pick a date"
          {...register('financialYearEnd', {
            min: {
              value: formatDate(new Date(), 'yyyy-MM-dd'),
              message: LOAN_MESSAGES.MIN_DATE_NEXT_FINANCIAL_YEAR_END(
                formatDate(new Date(), 'yyyy-MM-dd')
              ),
            },
            max: {
              value: ABSOLUTE_MAX_DATE,
              message: LOAN_MESSAGES.MAX_DATE_ABSOLUTE(
                formatDate(ABSOLUTE_MAX_DATE, 'yyyy-MM-dd')
              ),
            },
          })}
          type="date"
          questionTooltip={`Last date of ${borrowerLower}'s fiscal year`}
          errors={(errors as any)?.financialYearEnd?.message}
          min={formatDate(new Date(), 'yyyy-MM-dd')}
          max="3000-12-31"
        />
        <div className="flex flex-col p-4 mb-6 bg-neutral-100 rounded-md">
          <div className="flex items-center font-bold justify-between">
            <span className="heading-100">Name</span>
            <span className="heading-100">Calculation date</span>
          </div>
          {reports.map((report) => (
            <div
              className="flex items-center justify-between pt-4"
              key={report._id}
            >
              <span className="body-200">{report.name}</span>
              <span className="body-200">
                {report?.deadline?.calculationDate
                  ? ANNUAL_CALCULATION_DATES_SHORT[
                      report?.deadline?.calculationDate
                    ]
                  : ''}
              </span>
            </div>
          ))}
        </div>

        <div className="flex mt-2">
          <Button
            layout="ghost"
            className="mr-4 w-full h-8"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="w-full h-8"
            onClick={saveReport}
            layout="ghost"
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default FillMissingFinancialYearEndForm;
