import { useCallback, useLayoutEffect, useRef } from 'react';
import { useImmer } from 'use-immer';
import { StepsSet } from './steps-set';

type UseStepperControlReturn = ReturnType<typeof useStepperControl>;

const useStepperControl = <T extends string>({
  initialStep,
}: {
  initialStep: T;
}) => {
  const [activeStepId, updateActiveStepId] = useImmer(initialStep);
  const [visited, updateVisited] = useImmer<Set<string>>(
    new Set([initialStep])
  );

  const setActiveStepId = useCallback(
    (step: T) => {
      updateActiveStepId(step);
      updateVisited((value) => {
        value.add(step);
      });
    },
    [updateActiveStepId, updateVisited]
  );

  const activeStepIdRef = useRef<string>('');

  useLayoutEffect(() => {
    activeStepIdRef.current = activeStepId;
  }, [activeStepId]);

  const structureRef = useRef<StepsSet<T>>(new StepsSet());

  const [completed, updateCompleted] = useImmer<Set<string>>(new Set());

  const completedRef = useRef<Set<string>>(new Set());

  useLayoutEffect(() => {
    completedRef.current = completed;
  }, [completed]);

  const getSubsteps = ({ stepId }: { stepId: string }) => {
    return Array.from(structureRef.current)
      .map((step) => step.stepId)
      .filter((e) => {
        const keys = e?.split('.');

        if (keys && keys[0] !== stepId) return false;
        return !(keys && keys[1] === undefined);
      });
  };

  const checkAssetsOrder = (newList: { stepId: T; order: number }[]) => {
    return newList.sort((stepA, stepB) => stepA.order - stepB.order);
  };

  const getPrevious = ({ stepId }: { stepId: string }) => {
    const list = Array.from(structureRef.current);
    const orderedList = checkAssetsOrder(list);
    const index = orderedList.findIndex((value) => stepId === value?.stepId);

    return orderedList[index - 1]?.stepId;
  };

  const getNext = ({ stepId }: { stepId: string }) => {
    const list = Array.from(structureRef.current);
    const orderedList = checkAssetsOrder(list);
    const index = orderedList.findIndex((value) => stepId === value?.stepId);

    return orderedList[index + 1]?.stepId;
  };

  const onPrevious = useCallback(() => {
    const prevValue = getPrevious({ stepId: activeStepIdRef.current });
    if (prevValue) {
      setActiveStepId(prevValue);
    }
  }, [setActiveStepId]);

  const onNext = useCallback(() => {
    const nextValue = getNext({ stepId: activeStepIdRef.current });
    if (nextValue) {
      setActiveStepId(nextValue);
    }
  }, [setActiveStepId]);

  const isStepButtonEnabled = (stepId: T) => {
    const substeps = getSubsteps({ stepId });
    const hasSubsteps = substeps.length > 0;
    const accessible = Array.from(structureRef.current).find(
      (step) => step.stepId === stepId
    )?.accessible;

    return (
      accessible &&
      !hasSubsteps &&
      (completed.has(stepId) || completed.has(getPrevious({ stepId })))
    );
  };

  const isSubstepButtonEnabled = (stepId: T) => {
    const substeps = getSubsteps({ stepId });
    const hasSubsteps = substeps.length > 0;

    return (
      hasSubsteps &&
      (substeps.some((substepId) => completed.has(substepId)) ||
        completed.has(getPrevious({ stepId: substeps[0] })))
    );
  };

  const isButtonEnabled = (stepId: T) => {
    return isStepButtonEnabled(stepId) || isSubstepButtonEnabled(stepId);
  };

  const onClick = (stepId: T) => {
    if (isStepButtonEnabled(stepId)) {
      setActiveStepId(stepId);
    } else if (isSubstepButtonEnabled(stepId)) {
      const substeps = getSubsteps({ stepId });
      setActiveStepId(substeps[0]); // set first substep as next
    }
  };

  const setActiveStep = useCallback(
    (value: T) => {
      if (
        Array.from(structureRef.current)
          .map((step) => step.stepId)
          .includes(value)
      ) {
        setActiveStepId(value);
      }
    },
    [setActiveStepId]
  );

  const markAsCompletedUpTo = useCallback(
    ({ stepId }: { stepId: T }) => {
      const steps = checkAssetsOrder(Array.from(structureRef.current));

      const lastStepCompletedIndex = steps.findIndex(
        (value) => value?.stepId === stepId
      );

      steps.forEach((step, index) => {
        if (index <= lastStepCompletedIndex) {
          updateCompleted((value) => {
            value.add(step.stepId);
          });
        }
      });
    },
    [updateCompleted]
  );

  const markCurrentAsCompleted = useCallback(() => {
    updateCompleted((value) => {
      value.add(activeStepIdRef.current);
    });
  }, [updateCompleted]);

  const markAsCompleted = useCallback(
    ({ stepId }: { stepId: T }) => {
      updateCompleted((value) => {
        value.add(stepId);
      });
    },
    [updateCompleted]
  );

  const isCompleted = useCallback((stepId: T) => {
    return completedRef.current.has(stepId);
  }, []);

  const isVisited = useCallback(
    (stepId: T) => {
      return visited.has(stepId);
    },
    [visited]
  );

  return {
    internal: {
      structureRef,
      getSubsteps,
      onClick,
      completed,
      isButtonEnabled,
    },
    isVisited,
    isCompleted,
    markCurrentAsCompleted,
    markAsCompletedUpTo,
    markAsCompleted,
    activeStepId,
    onPrevious,
    onNext,
    setActiveStep,
  };
};

export { useStepperControl };
export type { UseStepperControlReturn };
