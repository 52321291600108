import { baseOriginatorApi } from '.';
import { rootApi } from '../rootApi';
import {
  BorrowerCompany,
  BorrowerCompanyByIdResponse,
  BorrowerCompanyPayload,
  UpdateBorrowerCompanyPayload,
} from '@/interfaces/originator/borrower-company';

const baseBorrowerCompanyApi = `${baseOriginatorApi}/borrower-company`;

export const borrowerCompanyApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createBorrowerCompany: builder.mutation<
      BorrowerCompany,
      BorrowerCompanyPayload
    >({
      query: (payload: BorrowerCompanyPayload) => ({
        url: baseBorrowerCompanyApi,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Users', 'Companies', 'Loans'],
    }),
    updateBorrowerCompany: builder.mutation({
      query: (payload: UpdateBorrowerCompanyPayload) => ({
        url: `${baseBorrowerCompanyApi}/${payload.id}`, //borrowerCompanyIds
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Companies', 'Loans']),
    }),
    deleteBorrowerCompany: builder.mutation({
      query: (id) => ({
        url: `${baseBorrowerCompanyApi}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Users', 'Companies', 'Loans'],
    }),
    getBorrowerCompanyById: builder.query<
      BorrowerCompanyByIdResponse,
      { id: BorrowerCompany['_id']; details?: boolean }
    >({
      query: ({ id, details = true }) => ({
        url: `${baseBorrowerCompanyApi}/${id}`,
        params: {
          details,
        },
      }),
      providesTags: ['Users', 'Companies'],
    }),
    getBorrowerCompanyByCompanyExternalId: builder.query<
      BorrowerCompany,
      { borrowerCompanyExternalId: string }
    >({
      query: ({ borrowerCompanyExternalId }) => ({
        url: `${baseBorrowerCompanyApi}/external/${borrowerCompanyExternalId}`,
      }),
      providesTags: ['Users', 'Companies'],
    }),
  }),
});

export const {
  useCreateBorrowerCompanyMutation,
  useUpdateBorrowerCompanyMutation,
  useDeleteBorrowerCompanyMutation,
  useGetBorrowerCompanyByIdQuery,
  useLazyGetBorrowerCompanyByIdQuery,
  useGetBorrowerCompanyByCompanyExternalIdQuery,
} = borrowerCompanyApi;

export const {
  useQuerySubscription: useGetBorrowerCompanyByNumberSubscription,
} = borrowerCompanyApi.endpoints.getBorrowerCompanyById;
