import React from 'react';
import { IconProps } from '../iconTypes';

const ArrowIcon: React.FC<IconProps> = (props) => (
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.674695 0.574598C1.24095 0.00833732 2.15904 0.00833732 2.7253 0.574598L7.49999 5.34929L12.2747 0.574598C12.8409 0.00833724 13.759 0.00833724 14.3253 0.574598C14.8915 1.14086 14.8915 2.05895 14.3253 2.62521L8.52529 8.42521C7.95903 8.99147 7.04094 8.99147 6.47469 8.42521L0.674695 2.62521C0.108435 2.05895 0.108435 1.14086 0.674695 0.574598Z"
      fill={props?.fill ?? '#1E293B'}
    />
  </svg>
);

export default ArrowIcon;
