import React from 'react';
import { useSelectUser } from '@/store/user/selectors';
import { useRole } from './useRole';

export function useNotifications() {
  const user = useSelectUser();
  const { role } = useRole();

  const withNotifications = React.useMemo(
    () =>
      role === 'BORROWER' ||
      role === 'ORIGINATOR_ADMIN' ||
      role === 'ORIGINATOR_ANALYST' ||
      role === 'INVESTOR',
    [role]
  );

  return { email: user?.email, withNotifications };
}
