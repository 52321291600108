import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, FormInput } from 'ui';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import {
  ABSOLUTE_MAX_CHARACTERS_MSG,
  FACILITY_MESSAGE,
} from '@/constants/messages';
import { ABSOLUTE_MAX_CHARACTERS } from '@/constants/numeric';
import { SNACKBAR_FACILITY } from '@/constants/snackbar-messages';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useCreateFacilityMutation } from '@/services/loans/facility';
import {
  Facility,
  FacilityForm,
  Facility as FacilityInterface,
} from '@/interfaces/loans/facility';

interface Props {
  branchEncodedKey: Facility['branchEncodedKey'];
  productEncodedKey: Facility['productEncodedKey'];
  existingFacilitiesNames?: string[];
  onClose: () => void;
  setOnSuccess: (
    facilityId: FacilityInterface['_id'],
    name: FacilityInterface['name']
  ) => void;
}

const AddFacilityForm: React.FC<Props> = ({
  onClose,
  setOnSuccess,
  branchEncodedKey,
  productEncodedKey,
  existingFacilitiesNames,
}) => {
  const snackbar = useSnackbar();
  const [createFacility, { isLoading: isSubmitting }] =
    useCreateFacilityMutation();

  const { setError, clearErrors, register, formState, getValues } =
    useForm<FacilityForm>({
      shouldFocusError: false,
      mode: 'onChange',
    });

  const onSubmit = async () => {
    const payload = {
      name: getValues('name'),
      branchEncodedKey,
      productEncodedKey,
    };
    createFacility(payload)
      .unwrap()
      .then((data) => {
        setOnSuccess(data?._id, data?.name);
        onClose();
      })
      .catch(() => {
        snackbar.show({
          id: 'CREATE_FACILITY_FAILED',
          severity: 'error',
          title: SNACKBAR_FACILITY.CREATE_FAILED,
          content: SNACKBAR_FACILITY.CREATE_FAILED_CONTENT,
        });
      });
  };

  const handleNameChange = (name: string) => {
    if (existingFacilitiesNames.includes(name)) {
      setError('name', {
        type: 'value',
        message: FACILITY_MESSAGE.NAME_ALREADY_EXIST,
      });
      return;
    }
    clearErrors('name');
  };

  return (
    <div>
      <form
        className="flex flex-col self-start text-left w-full grow"
        autoComplete="off"
        noValidate
      >
        <label htmlFor="name" className="relative block">
          <FormInput
            label="Facility name"
            type="text"
            errors={formState.errors?.name?.message}
            {...register('name', {
              required: 'Facility name cannot be empty',
              maxLength: {
                value: ABSOLUTE_MAX_CHARACTERS,
                message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
              },
            })}
            onChange={(e) => handleNameChange(e?.target?.value)}
          />
          {formState.errors?.name?.message && (
            <ExclamationCircleIcon className="absolute text-error-500 w-5 h-5 bottom-2 right-3" />
          )}
        </label>
      </form>
      <div className="flex justify-around mt-4">
        <Button layout="ghost" className="w-40" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="w-40"
          onClick={onSubmit}
          disabled={isSubmitting || !!formState.errors?.name}
          loading={isSubmitting}
          type="button"
        >
          Save
        </Button>
      </div>
      <div></div>
    </div>
  );
};

export { AddFacilityForm };
