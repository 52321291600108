import { usePhonePrefixes } from '@/./hooks/usePhonePrefixes';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { EMAIL_REGEX, POSTAL_CODE_REGEX } from 'kennek/constants/regexp';
import { debounce } from 'lodash';
import { Autocomplete, Button, FormInput, Select } from 'ui';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import {
  ABSOLUTE_MAX_CHARACTERS_MSG,
  INVESTOR_MESSAGE,
} from '@/constants/messages';
import { ABSOLUTE_MAX_CHARACTERS, COMPANY } from '@/constants/numeric';
import { SNACKBAR_INVESTOR } from '@/constants/snackbar-messages';
import { useSnackbar } from '@/hooks/useSnackbar';
import {
  useCreateInvestorMutation,
  useLazyIsEmailAddressInUseQuery,
} from '@/services/accounts/investor';
import {
  useGetCompanyQuery,
  useLazyGetCompanyByIdQuery,
} from '@/services/originator';
import type {
  InvestorForm,
  InvestorMutationPayload,
  InvestorType,
  OriginatorInvestor,
} from 'kennek/interfaces/accounts/investor';
import { Company } from 'kennek/interfaces/originator';

interface Props {
  onClose: () => void;
  setOnSuccess: (
    investorId: OriginatorInvestor['_id'],
    investorName: OriginatorInvestor['details']['firstName']
  ) => void;
}

interface PreFilledFields {
  companyNumber: boolean;
  addressLine1: boolean;
  city: boolean;
  postalCode: boolean;
}

const initialPreFilledFields: PreFilledFields = {
  companyNumber: false,
  addressLine1: false,
  city: false,
  postalCode: false,
};

const investorTypes: { value: InvestorType; label: string }[] = [
  { value: 'COMPANY', label: 'Company' },
  { value: 'FAMILY_OFFICE', label: 'Family Office' },
  { value: 'INDIVIDUAL', label: 'Individual' },
  { value: 'SOLE_TRADER', label: 'Sole trader' },
  { value: 'PARTNERSHIP', label: 'Partnership' },
  { value: 'GROUP', label: 'Group' },
];

const AddInvestorForm: React.FC<Props> = ({ onClose, setOnSuccess }) => {
  const snackbar = useSnackbar();
  const [createInvestor, { isLoading: isSubmitting }] =
    useCreateInvestorMutation();
  const [investorType, setInvestorType] = useState<InvestorType | ''>('');
  const [queryCompany, setQueryCompany] = useState<string>('');
  const { data, isFetching } = useGetCompanyQuery(queryCompany);
  const [getCompanyById, { isFetching: isFetchingCompany }] =
    useLazyGetCompanyByIdQuery();
  const [results, setResults] = useState<Company[]>([]);
  const [preFilledFields, setPreFilledFields] = useState<PreFilledFields>(
    initialPreFilledFields
  );

  const [isEmailAddressInUse] = useLazyIsEmailAddressInUseQuery();

  const {
    phonePrefixOptions,
    selectedPrefix,
    setSelectedPrefix,
    phoneMaxLength,
    phoneMinLength,
  } = usePhonePrefixes();

  const {
    control,
    setValue,
    register,
    unregister,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm<InvestorForm>({
    shouldFocusError: false,
    mode: 'onChange',
  });

  const handleInvestorTypeChange = (value: InvestorType) => {
    setInvestorType(value);
    setValue('investorType', value);
  };

  const onQueryChange = debounce((query) => {
    setQueryCompany(query);
  }, 500);

  useEffect(() => {
    if (investorType === 'COMPANY') {
      register('companyName', {
        required: INVESTOR_MESSAGE.COMPANY_NAME_REQUIRED,
        max: {
          value: ABSOLUTE_MAX_CHARACTERS,
          message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
        },
      });
    } else {
      unregister('companyName');
    }
    setValue('country', 'United Kingdom');
  }, [investorType]);

  useEffect(() => {
    if (data && data.length) {
      setResults(data);
    }
  }, [data, setResults]);

  const onCompanySelectedChange = useCallback(
    (number: string) => {
      getCompanyById(number).then((response) => {
        const company = response.data;
        let preFilled: PreFilledFields;
        setQueryCompany(company?.name);
        setValue('companyName', company?.name);

        if (company?.number) {
          setValue('companyNumber', Number(company?.number));
          preFilled = { ...preFilled, companyNumber: true };
        }
        if (company?.address) {
          setValue('addressLine1', company?.address);
          preFilled = { ...preFilled, addressLine1: true };
        }
        if (company?.city) {
          setValue('city', company?.city);
          preFilled = { ...preFilled, city: true };
        }
        if (company?.postal_code) {
          setValue('postalCode', company?.postal_code);
          preFilled = { ...preFilled, postalCode: true };
        }
        setPreFilledFields(preFilled);
        clearErrors();
      });
    },
    [clearErrors, getCompanyById, setValue]
  );

  const onSubmit: SubmitHandler<InvestorForm> = async (data) => {
    const payload = mapPayload(data, selectedPrefix);

    createInvestor(payload)
      .unwrap()
      .then((response) => {
        snackbar.show({
          severity: 'primary',
          title: SNACKBAR_INVESTOR.CREATE_SUCCESS,
        });
        setOnSuccess(
          response?._id,
          response?.details?.firstName
            ?.concat(' ')
            .concat(response?.details?.lastName) ||
            response?.details?.companyName ||
            response?.details?.contactFirstName
        );
        onClose();
      })
      .catch(() => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_INVESTOR.CREATE_FAILED,
          content: SNACKBAR_INVESTOR.CREATE_FAILED_CONTENT,
        });
      });
  };

  return (
    <form
      className="flex flex-col self-start text-left w-full grow"
      autoComplete="off"
      noValidate
    >
      <Select
        label="Investor type"
        placeholder="Select an investor type"
        options={investorTypes}
        {...register('investorType', {})}
        onChange={(e) => handleInvestorTypeChange(e.target.value)}
        value={investorType}
        className="w-[372px]"
      />

      {(investorType === 'INDIVIDUAL' ||
        investorType === 'SOLE_TRADER' ||
        investorType === 'PARTNERSHIP') && (
        <div className="flex gap-3">
          <FormInput
            label="First Name"
            type="text"
            errors={errors?.firstName?.message}
            {...register('firstName', {
              required: 'First name cannot be empty',
              maxLength: {
                value: ABSOLUTE_MAX_CHARACTERS,
                message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
              },
            })}
          />

          <FormInput
            label="Last Name"
            type="text"
            errors={errors?.lastName?.message}
            {...register('lastName', {
              required: 'Last name cannot be empty',
              maxLength: {
                value: ABSOLUTE_MAX_CHARACTERS,
                message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
              },
            })}
          />
        </div>
      )}

      {(investorType === 'COMPANY' || investorType === 'PARTNERSHIP') && (
        <>
          <label className="relative heading-100 select-none">
            Company name or number
            <Autocomplete
              value={queryCompany}
              onChange={onCompanySelectedChange}
              onSearch={onQueryChange}
              results={
                results.length > 0
                  ? results.map((company) => ({
                      id: company.number,
                      label: `${company.name} (${company.number})`,
                    }))
                  : isFetching
                    ? [{ id: '0', label: 'Loading...' }]
                    : []
              }
              placeholder="Company name or number"
            />
            {errors?.companyName && (
              <ExclamationCircleIcon className="absolute text-error-500 w-5 h-5 bottom-[0.55rem] right-3" />
            )}
          </label>
          <div className="body-200 text-error-700 h-3 mt-1 mb-2">
            {errors?.companyName?.message}
          </div>

          <FormInput
            label="Company number"
            type="number"
            errors={errors?.companyNumber?.message}
            className={
              preFilledFields?.companyNumber &&
              'bg-neutral-200 border-neutral-400'
            }
            {...register('companyNumber', {
              valueAsNumber: true,
              required: INVESTOR_MESSAGE.COMPANY_NUMBER_REQUIRED,
              maxLength: {
                value: COMPANY.COMPANY_NUMBER_DIGITS,
                message: INVESTOR_MESSAGE.COMPANY_NUMBER_DIGITS(
                  COMPANY.COMPANY_NUMBER_DIGITS
                ),
              },
              minLength: {
                value: COMPANY.COMPANY_NUMBER_DIGITS,
                message: INVESTOR_MESSAGE.COMPANY_NUMBER_DIGITS(
                  COMPANY.COMPANY_NUMBER_DIGITS
                ),
              },
            })}
          />
        </>
      )}

      {investorType === 'FAMILY_OFFICE' && (
        <FormInput
          label="Name of contracting entity/person"
          type="text"
          errors={errors?.nameOfContracting?.message}
          {...register('nameOfContracting', {
            required: INVESTOR_MESSAGE.NAME_OF_CONTRACTING,
            maxLength: {
              value: ABSOLUTE_MAX_CHARACTERS,
              message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
            },
          })}
        />
      )}

      {(investorType === 'SOLE_TRADER' || investorType === 'PARTNERSHIP') && (
        <FormInput
          label="Trading name"
          type="text"
          errors={errors?.tradingName?.message}
          {...register('tradingName', {
            required: INVESTOR_MESSAGE.TRADING_NAME,
            maxLength: {
              value: ABSOLUTE_MAX_CHARACTERS,
              message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
            },
          })}
        />
      )}

      {investorType === 'GROUP' && (
        <FormInput
          label="Group Name"
          type="text"
          errors={errors?.groupName?.message}
          {...register('groupName', {
            required: 'Group name cannot be empty',
            maxLength: {
              value: ABSOLUTE_MAX_CHARACTERS,
              message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
            },
          })}
        />
      )}

      {!!investorType && investorType !== 'GROUP' && (
        <>
          <FormInput
            label="Address line 1"
            type="text"
            errors={errors?.addressLine1?.message}
            className={
              preFilledFields?.addressLine1 &&
              'bg-neutral-200 border-neutral-400'
            }
            {...register('addressLine1', {
              required: INVESTOR_MESSAGE.ADDRESS_REQUIRED,
              maxLength: {
                value: ABSOLUTE_MAX_CHARACTERS,
                message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
              },
            })}
          />
          <div className="flex justify-between gap-2">
            <FormInput
              label="City town"
              type="text"
              errors={errors?.city?.message}
              className={classNames('w-[220px]', {
                'bg-neutral-200 border-neutral-400': preFilledFields?.city,
              })}
              {...register('city', {
                required: INVESTOR_MESSAGE.CITY_REQUIRED,
                maxLength: {
                  value: ABSOLUTE_MAX_CHARACTERS,
                  message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
                },
              })}
            />
            <FormInput
              label="Postal code"
              type="text"
              errors={errors?.postalCode?.message}
              className={classNames('w-[140px]', {
                'bg-neutral-200 border-neutral-400':
                  preFilledFields?.postalCode,
              })}
              {...register('postalCode', {
                required: INVESTOR_MESSAGE.POSTAL_CODE_REQUIRED,
                pattern: {
                  value: POSTAL_CODE_REGEX,
                  message: INVESTOR_MESSAGE.POSTAL_CODE_INVALID,
                },
              })}
            />
          </div>
          <Controller
            name="country"
            control={control}
            {...register('country', {
              required: INVESTOR_MESSAGE.COUNTRY_REQUIRED,
            })}
            render={({ field }) => {
              return (
                <Select
                  label="Country"
                  name="country"
                  error={errors?.country?.message}
                  {...field}
                  options={[
                    { value: 'United Kingdom', label: 'United Kingdom' },
                  ]}
                />
              );
            }}
          />

          <div className="flex gap-3">
            <FormInput
              label="Contact First Name"
              type="text"
              errors={errors?.contactFirstName?.message}
              className="w-44"
              {...register('contactFirstName', {
                required: 'First name cannot be empty',
                maxLength: {
                  value: ABSOLUTE_MAX_CHARACTERS,
                  message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
                },
              })}
            />

            <FormInput
              label="Contact Last Name"
              type="text"
              errors={errors?.contactLastName?.message}
              className="w-44"
              {...register('contactLastName', {
                required: 'Last name cannot be empty',
                maxLength: {
                  value: ABSOLUTE_MAX_CHARACTERS,
                  message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
                },
              })}
            />
          </div>

          <FormInput
            label="Email"
            type="text"
            errors={errors?.email?.message}
            {...register('email', {
              required: INVESTOR_MESSAGE.EMAIL_REQUIRED,
              validate: {
                isAlreadyTaken: async (email) => {
                  const { data: result } = await isEmailAddressInUse({ email });
                  return result?.isEmailAddressInUse
                    ? INVESTOR_MESSAGE.EMAIL_IN_USE
                    : true;
                },
              },
              pattern: {
                value: EMAIL_REGEX,
                message: INVESTOR_MESSAGE.EMAIL_INVALID,
              },
              maxLength: {
                value: ABSOLUTE_MAX_CHARACTERS,
                message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
              },
            })}
          />
          <FormInput
            label="Phone number"
            selectedSymbol={selectedPrefix}
            setSelectedSymbol={setSelectedPrefix}
            symbolSelectOptions={phonePrefixOptions}
            type="number"
            errors={errors?.phone?.message}
            {...register('phone', {
              required: INVESTOR_MESSAGE.PHONE_REQUIRED,
              maxLength: {
                value: phoneMaxLength,
                message: INVESTOR_MESSAGE.PHONE_DIGITS(phoneMaxLength),
              },
              minLength: {
                value: phoneMinLength,
                message: INVESTOR_MESSAGE.PHONE_DIGITS(phoneMinLength),
              },
            })}
          />
        </>
      )}

      <div className="flex justify-around mt-4">
        <Button layout="ghost" className="w-40" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="w-40"
          onClick={handleSubmit(onSubmit)}
          disabled={!investorType || isSubmitting || isFetchingCompany}
          loading={isSubmitting}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export { AddInvestorForm };

const mapPayload = (
  data: InvestorForm,
  selectedPrefix: string
): InvestorMutationPayload => {
  let payload: InvestorMutationPayload = {
    email: data?.email,
    mobilePhone: `${selectedPrefix} ${data?.phone}`,
    type: data?.investorType,
  };

  if (data.investorType === 'COMPANY') {
    payload.contactFirstName = data?.contactFirstName;
    payload.contactLastName = data?.contactLastName;
    payload.company = {
      addressLine1: data?.addressLine1,
      city: data?.city,
      country: data?.country,
      name: data?.companyName,
      number: data?.companyNumber?.toString(),
      postalCode: data?.postalCode,
    };
  }

  if (data.investorType === 'FAMILY_OFFICE') {
    (payload.address = {
      line1: data?.addressLine1,
      postTown: data?.city,
      postCode: data?.postalCode,
      countryCode: data?.country,
    }),
      (payload.contactFirstName = data?.contactFirstName);
    payload.contactLastName = data?.contactLastName;
    payload.nameOfContracting = data?.nameOfContracting;
  }

  if (data.investorType === 'INDIVIDUAL') {
    payload.address = {
      line1: data?.addressLine1,
      postTown: data?.city,
      postCode: data?.postalCode,
      countryCode: data?.country,
    };
    payload.contactFirstName = data?.contactFirstName;
    payload.contactLastName = data?.contactLastName;
    payload.firstName = data?.firstName;
    payload.lastName = data?.lastName;
  }

  if (data.investorType === 'SOLE_TRADER') {
    payload.address = {
      line1: data?.addressLine1,
      postTown: data?.city,
      postCode: data?.postalCode,
      countryCode: data?.country,
    };
    payload.firstName = data?.firstName;
    payload.lastName = data?.lastName;
    payload.tradingName = data?.tradingName;
  }

  if (data.investorType === 'PARTNERSHIP') {
    payload.company = {
      addressLine1: data?.addressLine1,
      city: data?.city,
      country: data?.country,
      name: data?.companyName,
      number: data?.companyNumber.toString(),
      postalCode: data?.postalCode,
    };
    payload.firstName = data?.firstName;
    payload.lastName = data?.lastName;
    payload.tradingName = data?.tradingName;
  }

  if (data.investorType === 'GROUP') {
    payload = {
      groupName: data?.groupName,
      type: data?.investorType,
    };
  }

  return payload;
};
