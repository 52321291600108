import React from 'react';
import { IconProps } from '../iconTypes';

const DeleteIcon: React.FC<IconProps> = (props) => (
  <svg
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.29297 3.79167L8.82317 10.3689C8.78268 10.9358 8.31095 11.375 7.74259 11.375H3.26001C2.69166 11.375 2.21993 10.9358 2.17943 10.3689L1.70964 3.79167M4.41797 5.95833V9.20833M6.58464 5.95833V9.20833M7.1263 3.79167V2.16667C7.1263 1.86751 6.88379 1.625 6.58464 1.625H4.41797C4.11881 1.625 3.8763 1.86751 3.8763 2.16667V3.79167M1.16797 3.79167H9.83463"
      stroke="#184083"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DeleteIcon;
