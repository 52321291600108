import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

const useDebouncedData = <T>(data: T, delay = 200) => {
  const [debouncedData, setDebouncedData] = useState(data);
  const onChangeData = useCallback(
    debounce((val) => {
      setDebouncedData(val);
    }, delay),
    []
  );

  useEffect(() => {
    onChangeData(data);
  }, [onChangeData, data]);

  return debouncedData;
};

export default useDebouncedData;
