import React from 'react';
import classNames from 'classnames';

type NavigationProps = {
  children: React.ReactNode;
  hidden?: boolean;
};

type NavigationContext = {
  index: number;
};

const NavigationContext = React.createContext<NavigationContext>(
  {} as NavigationContext
);

const Navigation = ({ children, hidden }: NavigationProps) => {
  let indexValue = 0;
  const elements = React.Children.map(children, (element) => {
    if (element) ++indexValue;
    return (
      <NavigationContext.Provider value={{ index: indexValue }}>
        {element}
      </NavigationContext.Provider>
    );
  });
  return (
    <div
      className={classNames('bg-neutral-200 rounded-[10px] w-[241px] pb-5', {
        hidden,
      })}
    >
      {elements}
    </div>
  );
};

export { Navigation, NavigationContext };
export type { NavigationProps };
