import React from 'react';

interface Props {
  children?: React.ReactNode;
  mainColorClass?: string;
  hoverColorClass?: string;
}

function CircleButton({ children, mainColorClass, hoverColorClass }: Props) {
  function getButtonClassName() {
    const defaultStyles = [
      mainColorClass ?? 'bg-neutral-200',
      hoverColorClass ?? 'hover:bg-neutral-300',
      'align-bottom',
      'inline-flex',
      'items-center',
      'justify-center',
      'rounded-[50%]',
      'h-6',
      'w-6',
      '-right-3',
      'top-9',
    ];

    return defaultStyles.join(' ');
  }

  return (
    <div className={getButtonClassName()}>
      <div className="text-white w-3.5 h-3.5">{children}</div>
    </div>
  );
}

export default CircleButton;
