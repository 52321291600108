import { useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Button } from 'ui';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { LABELS } from '@/constants/productTypeDictionary';
import { SNACKBAR_TRANCHES } from '@/constants/snackbar-messages';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useGetConfigurationProductQuery } from '@/services/kennek';
import { useUpdateLoanTranchesMutation } from '@/services/loans';
import { useGetLoanFacilitiesForLoanQuery } from '@/services/loans/facility';
import { useGetProductsByOriginatorIdQuery } from '@/services/products';
import { useSelectUser } from '@/store/user/selectors';
import EditTranchesContent from './editTranches';
import { useTranchesValidator } from './useTranchesValidator';
import {
  RawTranche,
  getMambuProductType,
  tranchesToUpdateDataTransform,
  transformTranchesToRaw,
} from './utils';
import { Loan, LoanProductRules, Tranche } from '@/interfaces/loans';

const emptyTranche: RawTranche = {
  disbursementAmount: '',
  disbursementDate: '',
  fees: {},
};

interface Props {
  loan?: Loan;
  tranchesList?: Tranche[];
  rules?: LoanProductRules;
  setEditTranches?: (val: boolean) => void;
  refreshTranchesData?: () => void;
}

const EditTranchesContainer = ({
  loan,
  tranchesList,
  rules,
  setEditTranches,
  refreshTranchesData,
}: Props) => {
  const loanEncodedKey = loan.encodedKey;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const snackbar = useSnackbar();
  const user = useSelectUser();
  const [updateTranches] = useUpdateLoanTranchesMutation();

  const { data: products, ...productsQuery } =
    useGetProductsByOriginatorIdQuery(
      { id: user?.mambuUser?.[0]?.mambuBranchEncodedKey },
      { skip: !user?.mambuUser?.[0]?.mambuBranchEncodedKey }
    );

  const { data: configurationProductData, ...configurationProductQuery } =
    useGetConfigurationProductQuery(
      {
        branchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
        productEncodedKey: loan?.productTypeKey,
      },
      {
        skip:
          !user?.mambuUser?.[0]?.mambuBranchEncodedKey || !loan?.productTypeKey,
      }
    );

  const { data: loanFacilities } = useGetLoanFacilitiesForLoanQuery(
    loanEncodedKey,
    {
      skip: !loanEncodedKey,
    }
  );

  const mambuProductType = useMemo(
    () =>
      getMambuProductType(
        products,
        configurationProductData?.productEncodedKey
      ),
    [products, configurationProductData?.productEncodedKey]
  );

  const form = useForm<{ tranches: RawTranche[] }>({
    shouldFocusError: false,
    defaultValues: { tranches: transformTranchesToRaw(tranchesList) },
    mode: 'onSubmit',
  });
  const tranchesValidator = useTranchesValidator(mambuProductType);

  const fields = useFieldArray({
    control: form.control,
    name: 'tranches',
    rules:
      rules?.maxNumberOfTranches > 0
        ? {
            validate: (value: RawTranche[]): string | true =>
              tranchesValidator.validateTranchesSummaryFields(
                value,
                loan?.loanAmount,
                rules?.currency,
                loan?.maturityDate
              ),
          }
        : undefined,
  });

  const onSubmit = (data: { tranches: RawTranche[] }) => {
    setIsSubmitting(true);
    const updateData = tranchesToUpdateDataTransform(data.tranches);
    updateTranches({ tranches: updateData, loanId: loan?.id })
      .unwrap()
      .then(() => {
        snackbar.show({
          severity: 'success',
          title: SNACKBAR_TRANCHES.EDIT_SUCCESS,
        });
        refreshTranchesData && refreshTranchesData();
        setEditTranches(false);
      })
      .catch((error) => {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_TRANCHES.EDIT_FAILED,
          content: error?.data?.message,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (configurationProductQuery.isLoading || productsQuery.isLoading)
    return <LoadingAnimationPlat />;

  return (
    <div className="px-8">
      <div className="max-w-[450px]">
        <EditTranchesContent
          firstRepaymentDayLabel={LABELS.default.firstRepaymentDate}
          firstRepaymentDate={loan?.firstRepaymentDate}
          mambuProductType={mambuProductType}
          form={form}
          loanProductRules={rules}
          loanAmount={loan?.loanAmount}
          emptyTranche={emptyTranche}
          fields={fields}
          variant="tranches"
          facilitiesOptions={loanFacilities}
          originalLoan={loan}
        />
      </div>
      <div className="flex justify-between my-8 max-w-[430px]">
        <Button
          layout="ghost"
          className="w-40"
          onClick={() => {
            setEditTranches && setEditTranches(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className="w-40"
          onClick={form.handleSubmit(onSubmit)}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditTranchesContainer;
