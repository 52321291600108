import React from 'react';
import classNames from 'classnames';
import { Button, Dialog } from 'ui';
import { ExclamationIcon } from '@heroicons/react/solid';

export type ConfirmTheme = 'success' | 'warning' | 'destructive';

interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel?: () => void;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  text?: string;
  destructive?: boolean;
  buttonLabel?: string;
  cancelLabel?: string;
  theme?: ConfirmTheme;
  children?: React.ReactNode;
  icon?: JSX.Element;
}

const ConfirmationModal = ({
  text,
  destructive,
  buttonLabel,
  cancelLabel,
  onConfirm: onAction,
  onCancel,
  isVisible,
  setIsVisible,
  theme,
  children,
  icon,
}: ConfirmationModalProps) => {
  return (
    <Dialog
      open={isVisible}
      onClose={setIsVisible}
      dialogIconColor={theme ?? 'destructive'}
      dialogIcon={icon ?? <ExclamationIcon />}
      dialogIconTitle={text}
    >
      <section className="flex flex-col items-center">
        {children && (
          <div className="flex flex-row justify-between mt-2 w-full">
            {children}
          </div>
        )}

        <footer className="flex flex-row justify-between mt-7 w-full">
          <Button
            layout="ghost"
            className="w-40"
            onClick={() => (onCancel ? onCancel() : setIsVisible(false))}
          >
            {cancelLabel ?? 'Cancel'}
          </Button>
          <Button
            className={classNames('ml-2 min-w-40', {
              'w-40': !buttonLabel,
              'px-4': buttonLabel,
            })}
            layout={destructive ? 'destructive' : 'ghost'}
            onClick={() => {
              onAction();
              setIsVisible(false);
            }}
          >
            {buttonLabel ?? 'Confirm'}
          </Button>
        </footer>
      </section>
    </Dialog>
  );
};
export default ConfirmationModal;
