import React from 'react';
import Image from 'next/image';
import { kennekLogoGreen } from 'kennek/assets';
import { useBranchEncodedKey } from '@/hooks/useBranchEncodedKey';
import { useGetBrandQueryState } from '@/services/kennek';

export type MaintenancePageProps = {
  outsideLayout?: boolean;
};

const MaintenancePage: React.FC<MaintenancePageProps> = ({
  outsideLayout = false,
}) => {
  const { branchEncodedKey } = useBranchEncodedKey();
  const brandQuery = useGetBrandQueryState({ branchEncodedKey });
  const logoUrl = brandQuery?.currentData?.logo?.url ?? kennekLogoGreen;

  return (
    <div
      className={
        (outsideLayout ? 'h-screen' : 'h3/4') +
        ' flex flex-col justify-center items-center gap-6'
      }
    >
      {outsideLayout && (
        <Image src={logoUrl} alt="kennekLogo" width={120} height={38}></Image>
      )}
      <div className="flex flex-col items-center py-2 text-neutral-700">
        <div className="heading-700">Site is under maintenance</div>
        <div className="heading-400 pt-4">
          Please try again, or refresh the page.
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
