import { CustomFieldsVisibility } from '@/features/onboarding/custom-information/utils';

type ValuationCompany =
  | 'Savills'
  | 'Knight Frank'
  | 'JLL'
  | 'CBRE'
  | 'Kennek'
  | '';
export interface KennekDemoCustomInfoForm {
  businessDevelopmentManager?: string;
  valuationCompany?: ValuationCompany;
  dateOfValuation?: string;
  charge?: string;
  finalRiskAssessmentScore?: number;
  brokerCompany?: string;
  brokerRegion?: string;
  brokerIndividual?: string;
  solicitorName?: string;
  loremIpsumIsSimplyDummyTextOfThePrintingAndTypesettingIndustry?: string;
}

export const kennekDemoFieldsVisibility: CustomFieldsVisibility[] = [
  {
    key: 'businessDevelopmentManager',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'valuationCompany',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'dateOfValuation',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'charge',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'finalRiskAssessmentScore',
    visibility: ['ORIGINATOR_ADMIN'],
  },
  {
    key: 'brokerCompany',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'brokerRegion',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'brokerIndividual',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
  {
    key: 'solicitorName',
    visibility: ['ORIGINATOR_ADMIN', 'BORROWER'],
  },
];

export const valuationCompanyOptions = [
  {
    value: 'Savilis',
    label: 'Savilis',
  },
  {
    value: 'Knight Frank',
    label: 'Knight Frank',
  },
  {
    value: 'JLL',
    label: 'JLL',
  },
  {
    value: 'CBRE',
    label: 'CBRE',
  },
  {
    value: 'Kennek',
    label: 'Kennek',
  },
  {
    value: 'Empty',
    label: 'Empty',
  },
];

export const chargeOptions = [
  {
    value: '1st charge',
    label: '1st charge',
  },
  {
    value: '2nd charge',
    label: '2nd charge',
  },
];

export const brokenRegionOptions = [
  {
    value: 'Empty',
    label: 'Empty',
  },
  {
    value: 'North East',
    label: 'North East',
  },
  {
    value: 'North West',
    label: 'North West',
  },
  {
    value: 'Yorkshire and the Humber',
    label: 'Yorkshire and the Humber',
  },
  {
    value: 'East Midlands',
    label: 'East Midlands',
  },
  {
    value: 'West Midlands',
    label: 'West Midlands',
  },
  {
    value: 'East of England',
    label: 'East of England',
  },
  {
    value: 'London',
    label: 'London',
  },
  {
    value: 'East of England',
    label: 'East of England',
  },
  {
    value: 'South East',
    label: 'South East',
  },
  {
    value: 'South West',
    label: 'South West',
  },
];

export const emptyKennekDemoCustomInformation: KennekDemoCustomInfoForm = {
  businessDevelopmentManager: '',
  valuationCompany: '',
  dateOfValuation: '',
  charge: '1st charge',
  finalRiskAssessmentScore: 0,
  brokerCompany: '',
  brokerRegion: '',
  brokerIndividual: '',
  solicitorName: '',
};

export const kennekDemoCustomInformationOrdered = [
  'Business development manager',
  'Valuation company',
  'Date of valuation',
  'Charge',
  'Final risk assessment score',
  'Broker company',
  'Broker region',
  'Broker individual',
  'Solicitor name',
];
