import { UK_PREFIX } from './numeric';

export const countryList = [
  {
    shortName: 'AD',
    prefix: '+376',
    countryName: 'Andorra',
    phoneLengthMin: 6,
    phoneLengthMax: 6,
  },
  {
    shortName: 'AE',
    prefix: '+971',
    countryName: 'United Arab Emirates',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'AF',
    prefix: '+93',
    countryName: 'Afghanistan',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'AG',
    prefix: '+1-268',
    countryName: 'Antigua and Barbuda',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'AI',
    prefix: '+1-264',
    countryName: 'Anguilla',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'AL',
    prefix: '+355',
    countryName: 'Albania',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'AM',
    prefix: '+374',
    countryName: 'Armenia',
    phoneLengthMin: 6,
    phoneLengthMax: 6,
  },
  {
    shortName: 'AO',
    prefix: '+244',
    countryName: 'Angola',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'AQ',
    prefix: '+672',
    countryName: 'Antarctica',
    phoneLengthMin: 6,
    phoneLengthMax: 6,
  },
  {
    shortName: 'AR',
    prefix: '+54',
    countryName: 'Argentina',
    phoneLengthMin: 6,
    phoneLengthMax: 10,
  },
  {
    shortName: 'AS',
    prefix: '+1-684',
    countryName: 'American Samoa',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'AT',
    prefix: '+43',
    countryName: 'Austria',
    phoneLengthMin: 5,
    phoneLengthMax: 13,
  },
  {
    shortName: 'AU',
    prefix: '+61',
    countryName: 'Australia',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'AW',
    prefix: '+297',
    countryName: 'Aruba',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'AX',
    prefix: '+358',
    countryName: 'Alland Islands',
    phoneLengthMin: 7,
    phoneLengthMax: 10,
  },
  {
    shortName: 'AZ',
    prefix: '+994',
    countryName: 'Azerbaijan',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'BA',
    prefix: '+387',
    countryName: 'Bosnia and Herzegovina',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'BB',
    prefix: '+1-246',
    countryName: 'Barbados',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'BD',
    prefix: '+880',
    countryName: 'Bangladesh',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'BE',
    prefix: '+32',
    countryName: 'Belgium',
    phoneLengthMin: 8,
    phoneLengthMax: 9,
  },
  {
    shortName: 'BF',
    prefix: '+226',
    countryName: 'Burkina Faso',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'BG',
    prefix: '+359',
    countryName: 'Bulgaria',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'BH',
    prefix: '+973',
    countryName: 'Bahrain',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'BI',
    prefix: '+257',
    countryName: 'Burundi',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'BJ',
    prefix: '+229',
    countryName: 'Benin',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'BL',
    prefix: '+590',
    countryName: 'Saint Barthelemy',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'BM',
    prefix: '+1-441',
    countryName: 'Bermuda',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'BN',
    prefix: '+673',
    countryName: 'Brunei Darussalam',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'BO',
    prefix: '+591',
    countryName: 'Bolivia',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'BR',
    prefix: '+55',
    countryName: 'Brazil',
    phoneLengthMin: 11,
    phoneLengthMax: 11,
  },
  {
    shortName: 'BS',
    prefix: '+1-242',
    countryName: 'Bahamas',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'BT',
    prefix: '+975',
    countryName: 'Bhutan',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'BV',
    prefix: '+47',
    countryName: 'Bouvet Island',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'BW',
    prefix: '+267',
    countryName: 'Botswana',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'BY',
    prefix: '+375',
    countryName: 'Belarus',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'BZ',
    prefix: '+501',
    countryName: 'Belize',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'CA',
    prefix: '+1',
    countryName: 'Canada',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'CC',
    prefix: '+61',
    countryName: 'Cocos (Keeling) Islands',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'CD',
    prefix: '+243',
    countryName: 'Congo, Democratic Republic of the',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'CF',
    prefix: '+236',
    countryName: 'Central African Republic',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'CG',
    prefix: '+242',
    countryName: 'Congo, Republic of the',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'CH',
    prefix: '+41',
    countryName: 'Switzerland',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'CI',
    prefix: '+225',
    countryName: "Cote d'Ivoire",
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'CK',
    prefix: '+682',
    countryName: 'Cook Islands',
    phoneLengthMin: 5,
    phoneLengthMax: 5,
  },
  {
    shortName: 'CL',
    prefix: '+56',
    countryName: 'Chile',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'CM',
    prefix: '+237',
    countryName: 'Cameroon',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'CN',
    prefix: '+86',
    countryName: 'China',
    phoneLengthMin: 11,
    phoneLengthMax: 11,
  },
  {
    shortName: 'CO',
    prefix: '+57',
    countryName: 'Colombia',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'CR',
    prefix: '+506',
    countryName: 'Costa Rica',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'CU',
    prefix: '+53',
    countryName: 'Cuba',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'CV',
    prefix: '+238',
    countryName: 'Cape Verde',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'CW',
    prefix: '+599',
    countryName: 'Curacao',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'CX',
    prefix: '+61',
    countryName: 'Christmas Island',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'CY',
    prefix: '+357',
    countryName: 'Cyprus',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'CZ',
    prefix: '+420',
    countryName: 'Czech Republic',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'DE',
    prefix: '+49',
    countryName: 'Germany',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'DJ',
    prefix: '+253',
    countryName: 'Djibouti',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'DK',
    prefix: '+45',
    countryName: 'Denmark',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'DM',
    prefix: '+1-767',
    countryName: 'Dominica',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'DO',
    prefix: '+1-809',
    countryName: 'Dominican Republic',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'DZ',
    prefix: '+213',
    countryName: 'Algeria',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'EC',
    prefix: '+593',
    countryName: 'Ecuador',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'EE',
    prefix: '+372',
    countryName: 'Estonia',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'EG',
    prefix: '+20',
    countryName: 'Egypt',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'EH',
    prefix: '+212',
    countryName: 'Western Sahara',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'ER',
    prefix: '+291',
    countryName: 'Eritrea',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'ES',
    prefix: '+34',
    countryName: 'Spain',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'ET',
    prefix: '+251',
    countryName: 'Ethiopia',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'FI',
    prefix: '+358',
    countryName: 'Finland',
    phoneLengthMin: 9,
    phoneLengthMax: 11,
  },
  {
    shortName: 'FJ',
    prefix: '+679',
    countryName: 'Fiji',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'FK',
    prefix: '+500',
    countryName: 'Falkland Islands (Malvinas)',
    phoneLengthMin: 5,
    phoneLengthMax: 5,
  },
  {
    shortName: 'FM',
    prefix: '+691',
    countryName: 'Micronesia, Federated States of',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'FO',
    prefix: '+298',
    countryName: 'Faroe Islands',
    phoneLengthMin: 5,
    phoneLengthMax: 5,
  },
  {
    shortName: 'FR',
    prefix: '+33',
    countryName: 'France',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'GA',
    prefix: '+241',
    countryName: 'Gabon',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'GB',
    prefix: '+44',
    countryName: 'United Kingdom',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'GD',
    prefix: '+1-473',
    countryName: 'Grenada',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'GE',
    prefix: '+995',
    countryName: 'Georgia',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'GF',
    prefix: '+594',
    countryName: 'French Guiana',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'GG',
    prefix: '+44',
    countryName: 'Guernsey',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'GH',
    prefix: '+233',
    countryName: 'Ghana',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'GI',
    prefix: '+350',
    countryName: 'Gibraltar',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'GL',
    prefix: '+299',
    countryName: 'Greenland',
    phoneLengthMin: 6,
    phoneLengthMax: 6,
  },
  {
    shortName: 'GM',
    prefix: '+220',
    countryName: 'Gambia',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'GN',
    prefix: '+224',
    countryName: 'Guinea',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'GP',
    prefix: '+590',
    countryName: 'Guadeloupe',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'GQ',
    prefix: '+240',
    countryName: 'Equatorial Guinea',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'GR',
    prefix: '+30',
    countryName: 'Greece',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'GS',
    prefix: '+500',
    countryName: 'South Georgia and the South Sandwich Islands',
    phoneLengthMin: 5,
    phoneLengthMax: 5,
  },
  {
    shortName: 'GT',
    prefix: '+502',
    countryName: 'Guatemala',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'GU',
    prefix: '+1-671',
    countryName: 'Guam',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'GW',
    prefix: '+245',
    countryName: 'Guinea-Bissau',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'GY',
    prefix: '+592',
    countryName: 'Guyana',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'HK',
    prefix: '+852',
    countryName: 'Hong Kong',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'HM',
    prefix: '+672',
    countryName: 'Heard Island and McDonald Islands',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'HN',
    prefix: '+504',
    countryName: 'Honduras',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'HR',
    prefix: '+385',
    countryName: 'Croatia',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'HT',
    prefix: '+509',
    countryName: 'Haiti',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'HU',
    prefix: '+36',
    countryName: 'Hungary',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'ID',
    prefix: '+62',
    countryName: 'Indonesia',
    phoneLengthMin: 11,
    phoneLengthMax: 11,
  },
  {
    shortName: 'IE',
    prefix: '+353',
    countryName: 'Ireland',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'IL',
    prefix: '+972',
    countryName: 'Israel',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'IM',
    prefix: '+44',
    countryName: 'Isle of Man',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'IN',
    prefix: '+91',
    countryName: 'India',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'IO',
    prefix: '+246',
    countryName: 'British Indian Ocean Territory',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'IQ',
    prefix: '+964',
    countryName: 'Iraq',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'IR',
    prefix: '+98',
    countryName: 'Iran, Islamic Republic of',
    phoneLengthMin: 11,
    phoneLengthMax: 11,
  },
  {
    shortName: 'IS',
    prefix: '+354',
    countryName: 'Iceland',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'IT',
    prefix: '+39',
    countryName: 'Italy',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'JE',
    prefix: '+44',
    countryName: 'Jersey',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'JM',
    prefix: '+1-876',
    countryName: 'Jamaica',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'JO',
    prefix: '+962',
    countryName: 'Jordan',
    phoneLengthMin: 8,
    phoneLengthMax: 9,
  },
  {
    shortName: 'JP',
    prefix: '+81',
    countryName: 'Japan',
    phoneLengthMin: 2,
    phoneLengthMax: 11,
  },
  {
    shortName: 'KE',
    prefix: '+254',
    countryName: 'Kenya',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'KG',
    prefix: '+996',
    countryName: 'Kyrgyzstan',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'KH',
    prefix: '+855',
    countryName: 'Cambodia',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'KI',
    prefix: '+686',
    countryName: 'Kiribati',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'KM',
    prefix: '+269',
    countryName: 'Comoros',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'KN',
    prefix: '+1-869',
    countryName: 'Saint Kitts and Nevis',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'KP',
    prefix: '+850',
    countryName: "Korea, Democratic People's Republic of",
    phoneLengthMin: 4,
    phoneLengthMax: 6,
  },
  {
    shortName: 'KR',
    prefix: '+82',
    countryName: 'Korea, Republic of',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'KW',
    prefix: '+965',
    countryName: 'Kuwait',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'KY',
    prefix: '+1-345',
    countryName: 'Cayman Islands',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'KZ',
    prefix: '+7',
    countryName: 'Kazakhstan',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'LA',
    prefix: '+856',
    countryName: "Lao People's Democratic Republic",
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'LB',
    prefix: '+961',
    countryName: 'Lebanon',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'LC',
    prefix: '+1-758',
    countryName: 'Saint Lucia',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'LI',
    prefix: '+423',
    countryName: 'Liechtenstein',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'LK',
    prefix: '+94',
    countryName: 'Sri Lanka',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'LR',
    prefix: '+231',
    countryName: 'Liberia',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'LS',
    prefix: '+266',
    countryName: 'Lesotho',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'LT',
    prefix: '+370',
    countryName: 'Lithuania',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'LU',
    prefix: '+352',
    countryName: 'Luxembourg',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'LV',
    prefix: '+371',
    countryName: 'Latvia',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'LY',
    prefix: '+218',
    countryName: 'Libya',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'MA',
    prefix: '+212',
    countryName: 'Morocco',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'MC',
    prefix: '+377',
    countryName: 'Monaco',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'MD',
    prefix: '+373',
    countryName: 'Moldova, Republic of',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'ME',
    prefix: '+382',
    countryName: 'Montenegro',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'MF',
    prefix: '+590',
    countryName: 'Saint Martin (French part)',
    phoneLengthMin: 6,
    phoneLengthMax: 6,
  },
  {
    shortName: 'MG',
    prefix: '+261',
    countryName: 'Madagascar',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'MH',
    prefix: '+692',
    countryName: 'Marshall Islands',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'MK',
    prefix: '+389',
    countryName: 'Macedonia, the Former Yugoslav Republic of',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'ML',
    prefix: '+223',
    countryName: 'Mali',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'MM',
    prefix: '+95',
    countryName: 'Myanmar',
    phoneLengthMin: 7,
    phoneLengthMax: 10,
  },
  {
    shortName: 'MN',
    prefix: '+976',
    countryName: 'Mongolia',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'MO',
    prefix: '+853',
    countryName: 'Macao',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'MP',
    prefix: '+1-670',
    countryName: 'Northern Mariana Islands',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'MQ',
    prefix: '+596',
    countryName: 'Martinique',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'MR',
    prefix: '+222',
    countryName: 'Mauritania',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'MS',
    prefix: '+1-664',
    countryName: 'Montserrat',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'MT',
    prefix: '+356',
    countryName: 'Malta',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'MU',
    prefix: '+230',
    countryName: 'Mauritius',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'MV',
    prefix: '+960',
    countryName: 'Maldives',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'MW',
    prefix: '+265',
    countryName: 'Malawi',
    phoneLengthMin: 7,
    phoneLengthMax: 9,
  },
  {
    shortName: 'MX',
    prefix: '+52',
    countryName: 'Mexico',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'MY',
    prefix: '+60',
    countryName: 'Malaysia',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'MZ',
    prefix: '+258',
    countryName: 'Mozambique',
    phoneLengthMin: 12,
    phoneLengthMax: 12,
  },
  {
    shortName: 'NA',
    prefix: '+264',
    countryName: 'Namibia',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'NC',
    prefix: '+687',
    countryName: 'New Caledonia',
    phoneLengthMin: 6,
    phoneLengthMax: 6,
  },
  {
    shortName: 'NE',
    prefix: '+227',
    countryName: 'Niger',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'NF',
    prefix: '+672',
    countryName: 'Norfolk Island',
    phoneLengthMin: 6,
    phoneLengthMax: 6,
  },
  {
    shortName: 'NG',
    prefix: '+234',
    countryName: 'Nigeria',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'NI',
    prefix: '+505',
    countryName: 'Nicaragua',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'NL',
    prefix: '+31',
    countryName: 'Netherlands',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'NO',
    prefix: '+47',
    countryName: 'Norway',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'NP',
    prefix: '+977',
    countryName: 'Nepal',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'NR',
    prefix: '+674',
    countryName: 'Nauru',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'NU',
    prefix: '+683',
    countryName: 'Niue',
    phoneLengthMin: 4,
    phoneLengthMax: 4,
  },
  {
    shortName: 'NZ',
    prefix: '+64',
    countryName: 'New Zealand',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'OM',
    prefix: '+968',
    countryName: 'Oman',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'PA',
    prefix: '+507',
    countryName: 'Panama',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'PE',
    prefix: '+51',
    countryName: 'Peru',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'PF',
    prefix: '+689',
    countryName: 'French Polynesia',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'PG',
    prefix: '+675',
    countryName: 'Papua New Guinea',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'PH',
    prefix: '+63',
    countryName: 'Philippines',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'PK',
    prefix: '+92',
    countryName: 'Pakistan',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'PL',
    prefix: '+48',
    countryName: 'Poland',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'PM',
    prefix: '+508',
    countryName: 'Saint Pierre and Miquelon',
    phoneLengthMin: 6,
    phoneLengthMax: 6,
  },
  {
    shortName: 'PN',
    prefix: '+870',
    countryName: 'Pitcairn',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'PR',
    prefix: '+1',
    countryName: 'Puerto Rico',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'PS',
    prefix: '+970',
    countryName: 'Palestine, State of',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'PT',
    prefix: '+351',
    countryName: 'Portugal',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'PW',
    prefix: '+680',
    countryName: 'Palau',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'PY',
    prefix: '+595',
    countryName: 'Paraguay',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'QA',
    prefix: '+974',
    countryName: 'Qatar',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'RE',
    prefix: '+262',
    countryName: 'Reunion',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'RO',
    prefix: '+40',
    countryName: 'Romania',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'RS',
    prefix: '+381',
    countryName: 'Serbia',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'RU',
    prefix: '+7',
    countryName: 'Russian Federation',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'RW',
    prefix: '+250',
    countryName: 'Rwanda',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'SA',
    prefix: '+966',
    countryName: 'Saudi Arabia',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'SB',
    prefix: '+677',
    countryName: 'Solomon Islands',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'SC',
    prefix: '+248',
    countryName: 'Seychelles',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'SD',
    prefix: '+249',
    countryName: 'Sudan',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'SE',
    prefix: '+46',
    countryName: 'Sweden',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'SG',
    prefix: '+65',
    countryName: 'Singapore',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'SH',
    prefix: '+290',
    countryName: 'Saint Helena',
    phoneLengthMin: 4,
    phoneLengthMax: 4,
  },
  {
    shortName: 'SI',
    prefix: '+386',
    countryName: 'Slovenia',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'SJ',
    prefix: '+47',
    countryName: 'Svalbard and Jan Mayen',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'SK',
    prefix: '+421',
    countryName: 'Slovakia',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'SL',
    prefix: '+232',
    countryName: 'Sierra Leone',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'SM',
    prefix: '+378',
    countryName: 'San Marino',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'SN',
    prefix: '+221',
    countryName: 'Senegal',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'SO',
    prefix: '+252',
    countryName: 'Somalia',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'SR',
    prefix: '+597',
    countryName: 'Suriname',
    phoneLengthMin: 6,
    phoneLengthMax: 6,
  },
  {
    shortName: 'SS',
    prefix: '+211',
    countryName: 'South Sudan',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'ST',
    prefix: '+239',
    countryName: 'Sao Tome and Principe',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'SV',
    prefix: '+503',
    countryName: 'El Salvador',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'SX',
    prefix: '+1-721',
    countryName: 'Sint Maarten (Dutch part)',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'SY',
    prefix: '+963',
    countryName: 'Syrian Arab Republic',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'SZ',
    prefix: '+268',
    countryName: 'Swaziland',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'TC',
    prefix: '+1-649',
    countryName: 'Turks and Caicos Islands',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'TD',
    prefix: '+235',
    countryName: 'Chad',
    phoneLengthMin: 6,
    phoneLengthMax: 6,
  },
  {
    shortName: 'TF',
    prefix: '+262',
    countryName: 'French Southern Territories',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'TG',
    prefix: '+228',
    countryName: 'Togo',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'TH',
    prefix: '+66',
    countryName: 'Thailand',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'TJ',
    prefix: '+992',
    countryName: 'Tajikistan',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'TK',
    prefix: '+690',
    countryName: 'Tokelau',
    phoneLengthMin: 5,
    phoneLengthMax: 5,
  },
  {
    shortName: 'TL',
    prefix: '+670',
    countryName: 'Timor-Leste',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'TM',
    prefix: '+993',
    countryName: 'Turkmenistan',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'TN',
    prefix: '+216',
    countryName: 'Tunisia',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'TO',
    prefix: '+676',
    countryName: 'Tonga',
    phoneLengthMin: 5,
    phoneLengthMax: 5,
  },
  {
    shortName: 'TR',
    prefix: '+90',
    countryName: 'Turkey',
    phoneLengthMin: 11,
    phoneLengthMax: 11,
  },
  {
    shortName: 'TT',
    prefix: '+1-868',
    countryName: 'Trinidad and Tobago',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'TV',
    prefix: '+688',
    countryName: 'Tuvalu',
    phoneLengthMin: 5,
    phoneLengthMax: 5,
  },
  {
    shortName: 'TW',
    prefix: '+886',
    countryName: 'Taiwan, Province of China',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'TZ',
    prefix: '+255',
    countryName: 'United Republic of Tanzania',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'UA',
    prefix: '+380',
    countryName: 'Ukraine',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'UG',
    prefix: '+256',
    countryName: 'Uganda',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'US',
    prefix: '+1',
    countryName: 'United States',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'UY',
    prefix: '+598',
    countryName: 'Uruguay',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'UZ',
    prefix: '+998',
    countryName: 'Uzbekistan',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'VA',
    prefix: '+379',
    countryName: 'Holy See (Vatican City State)',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'VC',
    prefix: '+1-784',
    countryName: 'Saint Vincent and the Grenadines',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'VE',
    prefix: '+58',
    countryName: 'Venezuela',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'VG',
    prefix: '+1-284',
    countryName: 'British Virgin Islands',
    phoneLengthMin: 7,
    phoneLengthMax: 7,
  },
  {
    shortName: 'VI',
    prefix: '+1-340',
    countryName: 'US Virgin Islands',
    phoneLengthMin: 10,
    phoneLengthMax: 10,
  },
  {
    shortName: 'VN',
    prefix: '+84',
    countryName: 'Vietnam',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'VU',
    prefix: '+678',
    countryName: 'Vanuatu',
    phoneLengthMin: 5,
    phoneLengthMax: 5,
  },
  {
    shortName: 'WF',
    prefix: '+681',
    countryName: 'Wallis and Futuna',
    phoneLengthMin: 6,
    phoneLengthMax: 6,
  },
  {
    shortName: 'WS',
    prefix: '+685',
    countryName: 'Samoa',
    phoneLengthMin: 5,
    phoneLengthMax: 5,
  },
  {
    shortName: 'XK',
    prefix: '+383',
    countryName: 'Kosovo',
    phoneLengthMin: 8,
    phoneLengthMax: 8,
  },
  {
    shortName: 'YE',
    prefix: '+967',
    countryName: 'Yemen',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'YT',
    prefix: '+262',
    countryName: 'Mayotte',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'ZA',
    prefix: '+27',
    countryName: 'South Africa',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'ZM',
    prefix: '+260',
    countryName: 'Zambia',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
  {
    shortName: 'ZW',
    prefix: '+263',
    countryName: 'Zimbabwe',
    phoneLengthMin: 9,
    phoneLengthMax: 9,
  },
];

const ukPhone = countryList.find((x) => x.prefix === UK_PREFIX);
const ukPhoneMaxLength = ukPhone.phoneLengthMax;
const ukPhoneMinLength = ukPhone.phoneLengthMin;
const ukPrefix = ukPhone.prefix;
const ukCountryName = ukPhone.countryName;
const ukShortName = ukPhone.shortName;

export const ukPhoneData = {
  ukPhoneMaxLength,
  ukPhoneMinLength,
  ukPrefix,
  ukCountryName,
  ukShortName,
};
