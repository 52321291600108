import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { usePathname } from 'next/navigation';
import { resetError } from '@/store/alerts';
import { useSelectError } from '@/store/alerts/selectors';

function useErrorHandler() {
  const dispatch = useDispatch();
  const error = useSelectError();
  const pathname = usePathname();
  const savedPathNameRef = useRef(pathname);

  const handleRouting = useCallback(() => {
    dispatch(resetError());
  }, [dispatch]);

  useEffect(() => {
    if (savedPathNameRef.current !== pathname) {
      handleRouting();
      savedPathNameRef.current = pathname;
    }
  }, [pathname]);

  return {
    data: {
      error,
    },
  };
}

export default useErrorHandler;
