import { AssetDetails, OccupancyStatus } from '@/interfaces/loans';

export const ASSET_OCCUPANCY_STATUS_NAMES: Record<OccupancyStatus, string> = {
  OWNER_OCCUPIED: 'Owner Occupied',
  TENANTED: 'Tenanted',
  VACANT: 'Vacant',
};

export function getAssetLabelByType(
  assetType: string,
  supportedAssets: AssetDetails[]
) {
  return supportedAssets?.find((asset) => asset.assetType === assetType)
    ?.assetLabel;
}
