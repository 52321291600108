import { useRouter } from 'next/navigation';
import classNames from 'classnames';
import { Button } from 'ui';
import { ROUTES } from '@/constants/routes';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { formatAmount, formatPercent } from '@/utils/formatters';
import { getRouterQuery } from '@/utils/helpers';
import { LoanSummary } from '@/interfaces/loans/queries';

interface Props {
  loanSummary: LoanSummary;
  index?: number;
}

export const LoanDetailsRow = ({ loanSummary, index }: Props) => {
  const router = useRouter();
  const { loanLower } = useGetLabelsConfig();

  const totalAmount = formatAmount(
    loanSummary.totalAmount,
    false,
    loanSummary?.currency
  );

  const totalPaid = formatAmount(
    loanSummary?.totalPaidAmount,
    false,
    loanSummary?.currency
  );

  const principalOutstanding = formatAmount(loanSummary.principalExpected);
  return (
    <div
      className={classNames('flex flex-col w-full bg-neutral-100 py-3 px-6', {
        'border-neutral-300 border-t': index > 0,
      })}
    >
      <div className="flex items-center">
        <p className="heading-200 mr-4">{loanSummary.borrowerCompany}</p>
        <Button
          layout="link"
          type="button"
          className="px-0"
          onClick={() => {
            const path = getRouterQuery(ROUTES.LOAN_DETAILS, {
              loanId: loanSummary.id,
            });
            router.push(path);
          }}
        >
          View {loanLower} details
        </Button>
      </div>
      <div className="grid grid-cols-3">
        <div className="mr-[10px]  flex items-center">
          <p className="heading-400">
            {totalPaid}
            <span className="body-600">{` of ${totalAmount}`}</span>
          </p>
        </div>
        <div className="col-span-2 flex justify-between items-center">
          <div>
            <p className="heading-200">{`${loanSummary.remainingInstallmentsCount}`}</p>
            <p className="body-200 mt-1">Instalments remaining</p>
          </div>
          <div>
            <p className="heading-200">
              {formatPercent(loanSummary?.displayedInterestRate, 3, 0)}
            </p>
            <p className="body-200 mt-1">Interest Rate</p>
          </div>
          <div>
            <p className="heading-200">{principalOutstanding}</p>
            <p className="body-200 mt-1">Principal Outstanding</p>
          </div>
        </div>
      </div>
    </div>
  );
};
