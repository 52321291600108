import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { uploadIcon } from 'kennek/assets';
import { Button, Modal } from 'ui';
import useGetIsDragging from 'ui/hooks/useGetIsDragging';
import { v4 as uuid } from 'uuid';
import {
  TrashIcon,
  UploadIcon as UploadIconHero,
} from '@heroicons/react/outline';
import { DottedBox } from '@/components/widgets/DottedBox';
import { MAX_FILE_SIZE } from '@/constants/numeric';
import {
  SNACKBAR_DOCUMENT_MANAGEMENT,
  SNACKBAR_ONBOARDING,
} from '@/constants/snackbar-messages';
import { useUploadFile } from '@/features/onboarding/useFiles';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useSelectUser } from '@/store/user/selectors';

interface FileData {
  file: File;
  id: string;
}

interface Props {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  loanEncodedKey: string;
  folderId: string;
  folderName: string;
  refreshData: () => void;
  isEditLoan?: boolean;
}

const UploadFileDMModal = ({
  show,
  setShow,
  loanEncodedKey,
  folderId,
  folderName,
  refreshData,
  isEditLoan,
}: Props) => {
  const dragAndDropZone = useRef<HTMLDivElement>(null);
  const [isDottedBoxMounted, setIsDottedBoxMounted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const inputFile = useRef<HTMLInputElement>(null);
  const [filesToUpload, setFilesToUpload] = useState<FileData[]>([]);

  const snackbar = useSnackbar();
  const {
    onUploadDocument,
    filesUploading,
    uploadSuccessRef,
    clearSuccessFilesCounter,
  } = useUploadFile();

  const user = useSelectUser();
  const isSubmitting = !!filesUploading.length;

  useEffect(() => {
    if (isSaving && !isSubmitting) {
      if (uploadSuccessRef?.current) {
        snackbar.show({
          severity: 'success',
          title:
            uploadSuccessRef?.current +
            SNACKBAR_DOCUMENT_MANAGEMENT.FILES_UPLOADED,
        });
      }
      refreshData();
      closeDialog();
      setFilesToUpload([]);
      clearSuccessFilesCounter();
      setIsSaving(false);
    }
  }, [isSubmitting, isSaving]);

  const isDragging = useGetIsDragging(dragAndDropZone, isDottedBoxMounted);

  const onSubmit = () => {
    clearSuccessFilesCounter();
    setIsSaving(true);
    filesToUpload.forEach(({ file }) => {
      onUploadDocument(file, user, loanEncodedKey, folderId, {
        hideSnackbarSuccess: true,
        isDraft: isEditLoan,
      });
    });
  };

  const closeDialog = () => {
    setShow(false);
  };

  const handleFileSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e?.target?.files);
    files.forEach((file) => {
      if (file?.size > MAX_FILE_SIZE) {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_ONBOARDING.FILE_UPLOAD_MAX_SIZE_EXCEED,
        });
        return;
      }
      const fileData = {
        id: uuid(),
        file,
      };
      setFilesToUpload((prev) => [...prev, fileData]);
    });
    inputFile.current.value = '';
  };

  const removeFile = (id: string) => {
    if (isSubmitting) return;
    setFilesToUpload((prev) => prev.filter((fileData) => fileData.id !== id));
  };

  return (
    <Modal
      isVisible={show}
      closeOnClickOutside={false}
      includeHeader="withCloseButton"
      setIsVisible={closeDialog}
      className="bg-opacity-40 top-0 bg-neutral-800 overflow-y-auto z-[101]"
      paddingBodyX="px-8 mt-0 mb-0 pt-0"
      paddingHeaderX="px-8 pt-4 pb-0"
      width="w-full max-w-[500px]"
      marginY="my-2"
    >
      <>
        <div className="flex justify-center pt-4">
          <span className="rounded-full w-12 h-12 bg-primary-100 flex justify-center items-center">
            <UploadIconHero className="h-6 w-6 text-primary-900" />
          </span>
        </div>

        <p className="pt-4 text-center text-neutral-900 heading-400 mb-4 truncate whitespace-break-spaces">
          Upload to &apos;{folderName}&apos;
        </p>
        <DottedBox
          ref={dragAndDropZone}
          layout="onboarding"
          isDragging={isDragging}
          setIsMounted={setIsDottedBoxMounted}
        >
          <Image src={uploadIcon} alt="uploadAreaCloud" />
          <div className="mt-4 heading-300 text-neutral-900 select-none">
            Drag and drop files, or{' '}
            <label htmlFor="file" className="heading-300 text-secondary-400">
              Browse
            </label>
          </div>
          <div className="text-neutral-600 heading-100 select-none">
            Max file size 10MB
          </div>
          <input
            ref={inputFile}
            id="file"
            type="file"
            multiple
            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
            accept=".pdf,.jpg,.jpeg,.csv,.doc,.xls,.docx,.xlsx,.png"
            onChange={(e) => handleFileSelection(e)}
          />
        </DottedBox>

        {filesToUpload.length > 0 && (
          <p className="text-neutral-600 heading-100 mt-5 mb-1">
            File to upload
          </p>
        )}

        {filesToUpload.map((data: FileData, i: number) => {
          return (
            <div
              key={data.id}
              className={classNames('flex justify-between pb-3 pt-3', {
                'border-b border-solid border-neutral-400':
                  filesToUpload.length !== i + 1,
              })}
            >
              <p className="heading-200 truncate max-w-[300px] mt-1">
                {data.file.name}
              </p>
              <div
                className={classNames('flex text-secondary-400 items-center', {
                  'cursor-pointer': !isSubmitting,
                })}
                onClick={() => removeFile(data.id)}
              >
                <TrashIcon width={18} height={18} />
                <p className="ml-2">Delete</p>
              </div>
            </div>
          );
        })}
        <div className="flex flex-col">
          <div className="w-full flex justify-around mt-5">
            <Button
              className="w-40"
              layout="ghost"
              onClick={closeDialog}
              disabled={isSubmitting}
            >
              Cancel
            </Button>

            <Button
              className="w-60 px-0"
              onClick={onSubmit}
              loading={isSubmitting}
              disabled={isSubmitting || filesToUpload.length === 0}
            >
              Upload
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default UploadFileDMModal;
