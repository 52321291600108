import React from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { Button, MultiDataProgressBar, StatusPill, Surface, Title } from 'ui';
import { ROUTES } from '@/constants/routes';
import { LoanDetailsRow } from '@/features/loan-details';
import { useSelectUser } from '@/store/user/selectors';
import { formatAmount } from '@/utils/formatters';
import { getRouterQuery } from '@/utils/helpers';
import type { LoanGroup } from '@/interfaces/loans/queries';

export interface Props extends React.ComponentProps<React.FC> {
  loanGroup: LoanGroup;
}

const CurrentBalanceWidgetGroup: React.FC<Props> = ({ loanGroup }) => {
  const searchParams = useSearchParams();
  const loanGroupId = searchParams.get('loanGroupId');
  const loanId = searchParams.get('loanId');

  const percentageForCustomBalance = Math.round(
    (loanGroup?.totalPaid * 100) / loanGroup?.totalAmount
  );

  const progressSum =
    loanGroup?.principalAmount +
    loanGroup?.interestAmount +
    loanGroup?.feesAmount;

  const totalPaid = formatAmount(
    loanGroup?.totalPaid,
    false,
    loanGroup?.currency
  );

  const totalAmount = formatAmount(
    loanGroup?.totalAmount,
    false,
    loanGroup?.currency
  );

  const principalPaid = formatAmount(
    loanGroup?.principalPaid,
    false,
    loanGroup?.currency
  );

  const principalAmount = formatAmount(
    loanGroup?.principalAmount,
    false,
    loanGroup?.currency
  );

  const interestPaid = formatAmount(
    loanGroup?.interestPaid,
    false,
    loanGroup?.currency
  );

  const interestAmount = formatAmount(
    loanGroup?.interestAmount,
    false,
    loanGroup?.currency
  );

  const feesPaid = formatAmount(
    loanGroup?.feesPaid,
    false,
    loanGroup?.currency
  );

  const fessAmount = formatAmount(
    loanGroup?.feesAmount,
    false,
    loanGroup?.currency
  );

  const router = useRouter();
  const user = useSelectUser();
  return (
    <Surface padding="sm" border="light">
      <div className="w-full bg-white flex gap-4">
        <div className={classNames('flex-grow flex flex-col justify-between')}>
          <div className="flex justify-between">
            <Title
              icon={
                <SvgIcon
                  name="MoneyIcon"
                  height={24}
                  width={24}
                  className="-left-6 top-8"
                />
              }
              title="Balance"
              className="my-0"
              titleSize="lg"
            />
            <div className="flex mb-[25px]">
              <Button
                layout="ghost"
                size="small"
                onClick={() => {
                  if (user?.roles?.[0]?.name === 'BORROWER') {
                    router.push(ROUTES.REPAYMENT_SCHEDULES);
                  } else {
                    const path = getRouterQuery(
                      ROUTES.LOAN_REPAYMENTS_GROUPED,
                      {
                        loanGroupId: loanGroupId,
                        loanId: loanId,
                      }
                    );
                    router.push(path);
                  }
                }}
              >
                View Repayment schedule
              </Button>
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className={classNames('flex flex-col gap-[11px] w-full')}>
              <div className="flex justify-between max-w-sm">
                <p className="heading-500">
                  {totalPaid}
                  <span className="body-600">{` of ${totalAmount}`}</span>
                </p>
                <StatusPill
                  type="light"
                  className="h-6 w-20 flex justify-center"
                  startCase
                >
                  {validatePercentage(percentageForCustomBalance)}
                </StatusPill>
              </div>

              <dl className="flex items-center mt-2 mb-2">
                <div className="mr-[10px]  flex items-center">
                  <div className="w-[10px] h-[10px] bg-primary-600 rounded-full text-[11px] mr-[5px]"></div>
                  <p className="text-[11px] h-[14px]">
                    <dt className="inline">Principal</dt>
                    <dd className="inline">
                      {principalPaid}
                      &nbsp;of&nbsp;
                      {principalAmount}
                    </dd>
                  </p>
                </div>

                <div className="mr-[10px]  flex items-center">
                  <div className="w-[10px] h-[10px] bg-[#0038FF] rounded-full text-[11px] mr-[5px]"></div>
                  <p className="text-[11px] h-[14px]">
                    Interest&nbsp;
                    {interestPaid}
                    &nbsp; of&nbsp;
                    {interestAmount}
                  </p>
                </div>

                {(loanGroup?.feesAmount ?? 0) !== 0 && (
                  <div className="mr-[10px] flex items-center">
                    <div className="w-[10px] h-[10px] bg-info-500 rounded-full text-[11px] mr-[5px]"></div>
                    <p className="text-[11px] h-[14px]">
                      Fees&nbsp;
                      {feesPaid}
                      &nbsp; of&nbsp;
                      {fessAmount}
                    </p>
                  </div>
                )}
              </dl>
              <div>
                <MultiDataProgressBar
                  mainSum={progressSum}
                  balances={[
                    {
                      color: '#0ED98D',
                      paid: loanGroup?.principalPaid,
                      wholeBalance: loanGroup?.principalAmount,
                    },
                    {
                      color: '#0038FF',
                      paid: loanGroup?.interestPaid,
                      wholeBalance: loanGroup?.interestAmount,
                    },
                    (loanGroup?.feesAmount ?? 0) !== 0 && {
                      color: '#F04BDC',
                      paid: loanGroup?.feesPaid,
                      wholeBalance: loanGroup?.feesAmount,
                    },
                  ]}
                />
              </div>
              <div className="rounded-2xl overflow-hidden mt-4">
                {loanGroup?.details?.map((data, i) => (
                  <LoanDetailsRow
                    key={data.loanName}
                    loanSummary={data}
                    index={i}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Surface>
  );
};

export { CurrentBalanceWidgetGroup };

const validatePercentage = (percentage: number) => {
  if (isNaN(percentage)) return `0% Paid`;
  return `${percentage > 100 ? 100 : percentage < 0 ? 0 : percentage}% Paid`;
};
