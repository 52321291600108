import React from 'react';
import classNames from 'classnames';

type TableHeaderProps = {
  children: React.ReactNode;
  noBorder?: boolean;
  dashboard?: boolean;
  className?: string;
};

const Container = ({
  children,
  noBorder = false,
  dashboard,
  className = '',
}: TableHeaderProps) => {
  const elements = React.Children.toArray(children);

  const left = elements.find((e) => React.isValidElement(e) && e.type === Left);

  const right = elements.find(
    (e) => React.isValidElement(e) && e.type === Right
  );

  const rightDashboard =
    dashboard &&
    elements.find((e) => React.isValidElement(e) && e.type === RightDashboard);

  const bottom = elements.find(
    (e) => React.isValidElement(e) && e.type === Bottom
  );

  return (
    <div
      className={classNames(
        className,
        'border-b-neutral-300 sticky top-[var(--nav-header-height)] bg-white z-10 overflow-x-clip',
        { 'border-b': !noBorder },
        { 'mb-[12px] pb-[30px] pt-[5px] h-[29px] w-[1120px]': dashboard }
      )}
    >
      <div className="flex justify-between items-center h-[var(--table-header-height)]">
        {left}
        {right}
        {rightDashboard}
      </div>

      {bottom}
    </div>
  );
};

const Left = ({ children }: { children: React.ReactNode }) => {
  return <div className="self-stretch flex">{children}</div>;
};

const Right = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex gap-4 pr-1">{children}</div>;
};

const RightDashboard = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex gap-4 pr-1 w-[1120px]">{children}</div>;
};

const Bottom = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex w-full">{children}</div>;
};

const Header = Container;

Container.Left = Left;
Container.Right = Right;
Container.RightDashboard = RightDashboard;
Container.Bottom = Bottom;

export { Header };
