import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { isEmpty } from 'lodash';
import { Surface, Title } from 'ui';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import { isNotEmpty } from '@/utils/validation';
import AssetWidgetResolver from './asset-details-widget/AssetWidgetResolver';
import { Asset } from '@/interfaces/loans';
import { BorrowerAddress, LoanSummary } from '@/interfaces/loans/queries';

export interface Props extends React.ComponentProps<React.FC> {
  address: BorrowerAddress;
  name: string;
  number: string;
  originatorName?: string;
  loanSummary?: LoanSummary;
  assets?: Asset[];
  title: string;
  loanEncodedKey?: string;
}

const CompanyInfoWidget: React.FC<Props> = ({
  address,
  name,
  number,
  originatorName,
  loanSummary,
  assets,
  title,
  loanEncodedKey,
}) => {
  const [showAsset, setShowAsset] = useState<boolean>();

  if (isEmpty(address || name || number)) {
    return <Fragment />;
  }

  const displayedAssets = isNotEmpty(assets)
    ? assets
    : loanSummary?.assetInformation;

  return (
    <Surface padding="sm" border="light">
      <div className="w-full bg-white flex gap-1 flex-col transition-[height] duration-300">
        <div className="flex items-center">
          <Title
            icon={<OfficeBuildingIcon />}
            title={title}
            titleSize="lg"
            className="mt-0 mb-4"
          />
        </div>
        <div className="flex flex-col">
          <div className="flex items-end relative">
            <span className="heading-500 mr-2 w-56 rounded break-words">
              {name}
            </span>
            <span className="heading-200">{number}</span>
          </div>
          <div className="flex flex-col py-6 w-72">
            <span className="body-400">{address?.country}</span>
            <span className="body-400">{address?.region}</span>
            <span className="body-400">{address?.city}</span>
            <span className="body-400">{address?.line1}</span>
            <span className="body-400">{address?.line2}</span>
            <span className="body-400">{address?.postcode}</span>
          </div>
          {originatorName && (
            <div className="flex flex-col pt-4 w-72 border-t border-neutral-300">
              <span className="body-400 weight-500">Originator</span>
              <span className="heading-500 mt-4 w-56 rounded break-words">
                {originatorName}
              </span>
            </div>
          )}
        </div>
        {displayedAssets?.length > 0 && (
          <>
            <div className="border-[1px] border-solid border-neutral-300"></div>

            <div className="flex justify-between items-center">
              <Title
                title="Asset information"
                className={classNames({ 'mb-[3px]': !showAsset })}
              />
              <button
                className={classNames(
                  { 'rotate-180': showAsset },
                  { 'mt-[18px]': !showAsset }
                )}
                onClick={() => setShowAsset(!showAsset)}
              >
                <SvgIcon name="ArrowIcon" />
              </button>
            </div>
            <AssetWidgetResolver
              currency={loanSummary?.currency}
              displayedAssets={displayedAssets}
              showAsset={showAsset}
              loanEncodedKey={loanEncodedKey}
            ></AssetWidgetResolver>
          </>
        )}
      </div>
    </Surface>
  );
};

export { CompanyInfoWidget };
