import * as React from 'react';
import { Dispatch, SetStateAction, useContext } from 'react';
import classNames from 'classnames';
import { Badge } from 'ui';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { formatDate } from '@/utils/formatters';
import { LoanSummaryContext } from './ContextWrapper';
import { InstallmentStates } from '@/interfaces/loans/installment';
import { NextInstallmentsProps } from '@/interfaces/next-payment';

type Props = {
  enableDetails: boolean;
  totalDueAmount: string;
  showDetails: boolean;
  datePrefix: string;
  setShowDetails: Dispatch<SetStateAction<boolean>>;
};

const nextInstallmentsProps: Record<InstallmentStates, NextInstallmentsProps> =
  {
    PARTIALLY_PAID: { severity: 'neutral', state: 'Pending' },
    OVERDUE: { severity: 'error', state: 'Overdue' },
    PAID: { severity: 'success', state: 'Paid' },
    PENDING: { severity: 'neutral', state: 'Pending' },
    GRACE: { severity: 'warning', state: 'Grace period' },
  };

const NextInstallment: React.FC<Props> = ({
  totalDueAmount,
  showDetails,
  datePrefix,
  setShowDetails,
  enableDetails,
}) => {
  const { nextPaymentData, totalGraceAmount } = useContext(LoanSummaryContext);
  const isAnyInstalmentOverdue = nextPaymentData.totalOverdueAmount > 0;

  const nextInstallmentStateOverduePrioritized = isAnyInstalmentOverdue
    ? 'OVERDUE'
    : nextPaymentData.nextInstallmentState;

  // DD processing payment has higher priority than any other state
  // Overdue has higher priority than, for example, grace. So even if the next installment is in grace,
  // we should still show the overdue badge if the loan has an overdue payment
  const nextInstallmentProp = nextPaymentData.processingPaymentAmounts
    ?.isDirectDebitProcessing
    ? nextInstallmentsProps[nextPaymentData.nextInstallmentState]
    : nextInstallmentsProps[nextInstallmentStateOverduePrioritized];

  const getTotalDueAmountLabelColor = (): string => {
    if (isAnyInstalmentOverdue) {
      return 'text-error-500';
    }

    if (totalGraceAmount > 0) {
      return 'text-warning-500';
    }

    return '';
  };

  const getTotalDueAmountLabelClass = (): string => {
    const classes = ['heading-500', 'mr-2'];

    const labelColor: string = getTotalDueAmountLabelColor();
    if (labelColor) {
      classes.push(labelColor);
    }

    return classes.join(' ');
  };

  const isPaymentDue =
    (nextPaymentData.nextInstallmentState === 'OVERDUE' ||
      nextPaymentData.nextInstallmentState === 'GRACE') &&
    nextPaymentData.nextInstallmentPaymentDue > 0;

  return (
    <div className="flex items-start">
      {isPaymentDue && enableDetails ? (
        <span
          className={classNames(
            'inline-block relative cursor-pointer transition-transform',
            { '-rotate-180': showDetails }
          )}
        >
          <ChevronDownIcon
            width={18}
            onClick={() => setShowDetails((prevValue) => !prevValue)}
            className="-bottom-1.5 inline"
          />
        </span>
      ) : null}

      <div className={classNames('flex flex-col', { 'ml-2': isPaymentDue })}>
        <span className={getTotalDueAmountLabelClass()}>{totalDueAmount}</span>
        <div className="flex items-end relative mt-1">
          <span className="body-200 text-neutral-600 mr-1 leading-[140%]">
            {datePrefix}
          </span>
          <span className="body-400 leading-[140%]">
            {formatDate(nextPaymentData.nextInstallmentDueDate, 'dd MMM, yyyy')}
          </span>
        </div>
      </div>

      {nextInstallmentProp && (
        <Badge
          type={nextInstallmentProp.severity}
          className="ml-auto capitalize rounded-full select-none"
        >
          {nextInstallmentProp.state}
        </Badge>
      )}
    </div>
  );
};

export default NextInstallment;
