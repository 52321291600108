import { useRef } from 'react';
import classNames from 'classnames';
import useGetIsDragging from '../../hooks/useGetIsDragging';
import { DropProps } from './Drop.props';

const Drop = ({
  fieldId,
  acceptFiles,
  isDropHidden,
  upload,
  children,
  customClass,
}: DropProps) => {
  const dragAndDropZone = useRef<HTMLDivElement>(null);
  const inputFile = useRef<HTMLInputElement>(null);
  const isDragging = useGetIsDragging(dragAndDropZone);

  return (
    <>
      <div
        className={classNames('flex flex-col items-start gap-1', {
          hidden: isDropHidden,
        })}
      >
        <div
          data-testid={fieldId}
          ref={dragAndDropZone}
          className={classNames(
            customClass ?? 'w-[410px]',
            'relative bg-neutral-200 rounded-lg',
            {
              'bg-primary-200 border-primary-500': isDragging,
            }
          )}
        >
          <div className="py-3 px-5 flex items-center rounded">
            <div className="text-neutral-900 select-none body-200 cursor-pointer">
              Drag and drop files, or&nbsp;
              <label className="text-[#227AB0] cursor-pointer">Browse</label>
            </div>
          </div>
          <input
            data-testid={`${fieldId}-input`}
            ref={inputFile}
            id="file"
            type="file"
            className="absolute top-0 left-0 opacity-0 cursor-pointer w-full h-full file:cursor-pointer"
            accept={acceptFiles?.map((f) => `.${f}`).join(',')}
            onChange={upload}
          />
        </div>
      </div>
      {children({ input: inputFile })}
    </>
  );
};

export default Drop;
