import { FC } from 'react';
import { Dialog } from 'ui';
import { ExclamationIcon } from '@heroicons/react/outline';
import { UseConfirmDialogResult } from '../../../../hooks/useConfirmDialog';
import {
  SkipInvestorWarningModal,
  SkipInvestorWarningModalProps,
} from '../SkipInvestorWarningModal';

export interface SkipInvestmentsWarningDialogOptions {
  type: SkipInvestorWarningModalProps['type'];
  nonAllocatedAmount: SkipInvestorWarningModalProps['nonAllocatedAmount'];
  currency: SkipInvestorWarningModalProps['currency'];
}

interface SkipInvestmentsWarningDialogProps {
  isOpen: boolean;
  dialogOptions: SkipInvestmentsWarningDialogOptions;
  closeDialog: (result: UseConfirmDialogResult) => void;
}

export const SkipInvestmentsWarningDialog: FC<
  SkipInvestmentsWarningDialogProps
> = ({ isOpen, dialogOptions, closeDialog }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => closeDialog({ confirmed: false })}
      dialogIconColor="destructive"
      dialogIcon={<ExclamationIcon />}
    >
      {dialogOptions && (
        <SkipInvestorWarningModal
          onClose={() => closeDialog({ confirmed: false })}
          nonAllocatedAmount={dialogOptions.nonAllocatedAmount}
          type={dialogOptions.type}
          currency={dialogOptions?.currency}
          onConfirm={() => closeDialog({ confirmed: true })}
        />
      )}
    </Dialog>
  );
};
