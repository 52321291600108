import { Alert } from 'ui';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';

const NoProductsBanner = () => {
  const { loanLower } = useGetLabelsConfig();
  return (
    <div className=" h-80">
      <Alert type="info" className="w-[768px]">
        <div className="heading-200">
          In order to create a {loanLower} you need to set up a new product type
        </div>
        <div className="body-400">Please contact us at help@kennek.io</div>
      </Alert>
    </div>
  );
};

export default NoProductsBanner;
