import React, { forwardRef, useContext } from 'react';
import classNames from 'classnames';
import { ThemeContext } from 'ui/theme';

export interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * The type of the statuspill
   */
  type?:
    | 'neutral'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light';
  startCase?: boolean;
}

const StatusPill = forwardRef<HTMLSpanElement, Props>(
  function StatusPill(props, ref) {
    const {
      className,
      children,
      type = 'primary',
      startCase,
      ...other
    } = props;

    const {
      theme: { statuspill },
    } = useContext(ThemeContext);

    const baseStyle = statuspill.base;
    const typeStyle = {
      success: statuspill.success,
      error: statuspill.error,
      warning: statuspill.warning,
      info: statuspill.info,
      neutral: statuspill.neutral,
      primary: statuspill.primary,
      secondary: statuspill.secondary,
      light: statuspill.light,
    };

    const cls = classNames(baseStyle, typeStyle[type], className);

    return (
      <span
        className={classNames(cls, { uppercase: !startCase })}
        ref={ref}
        {...other}
      >
        {children}
      </span>
    );
  }
);

export default StatusPill;
