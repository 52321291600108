import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, FormInput } from 'ui';
import {
  ABSOLUTE_MAX_CHARACTERS_MSG,
  INVESTOR_MESSAGE,
} from '@/constants/messages';
import { ABSOLUTE_MAX_CHARACTERS } from '@/constants/numeric';
import { SNACKBAR_INVESTOR } from '@/constants/snackbar-messages';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useCreateLenderOfRecordMutation } from '@/services/accounts/originator';
import type {
  LenderOfRecord,
  LenderOfRecordForm,
  LenderOfRecordMutationPayload,
  OriginatorInvestor,
} from 'kennek/interfaces/accounts/investor';

interface Props {
  onClose: () => void;
  originatorEncodedKey: OriginatorInvestor['originatorEncodedKey'];
  setNewLenderOfRecord: (newLender: LenderOfRecord) => void;
}
const AddLenderOfRecordForm: React.FC<Props> = ({
  onClose,
  originatorEncodedKey,
  setNewLenderOfRecord,
}) => {
  const [createLenderOfRecord, { isLoading: isSubmitting }] =
    useCreateLenderOfRecordMutation();
  const snackbar = useSnackbar();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LenderOfRecordForm>({
    shouldFocusError: false,
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<LenderOfRecordForm> = async (data) => {
    if (originatorEncodedKey) {
      const payload: LenderOfRecordMutationPayload = {
        originatorEncodedKey,
        name: data.name,
        jurisdiction: data.jurisdiction,
      };

      createLenderOfRecord(payload)
        .unwrap()
        .then((res) => {
          snackbar.show({
            severity: 'primary',
            title: SNACKBAR_INVESTOR.LENDER_SUCCESS,
          });
          setNewLenderOfRecord(res);
        })
        .catch(() =>
          snackbar.show({
            severity: 'error',
            title: SNACKBAR_INVESTOR.LENDER_FAILED,
            content: SNACKBAR_INVESTOR.CREATE_FAILED_CONTENT,
          })
        );
    }
  };

  return (
    <form
      className="flex flex-col self-start text-left w-full grow"
      autoComplete="off"
      noValidate
    >
      <FormInput
        label="Lender of record name"
        type="text"
        errors={errors?.name?.message}
        {...register('name', {
          required: INVESTOR_MESSAGE.LENDER_OF_RECORD_NAME_REQUIRED,
          maxLength: {
            value: ABSOLUTE_MAX_CHARACTERS,
            message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
          },
        })}
      />
      <FormInput
        label="Jurisdiction"
        type="text"
        errors={errors?.jurisdiction?.message}
        {...register('jurisdiction', {
          required: INVESTOR_MESSAGE.JURISDICTION_REQUIRED,
          maxLength: {
            value: ABSOLUTE_MAX_CHARACTERS,
            message: ABSOLUTE_MAX_CHARACTERS_MSG(ABSOLUTE_MAX_CHARACTERS),
          },
        })}
      />

      <div className="flex justify-around gap-2 mt-4">
        <Button layout="ghost" className="w-40" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="w-40"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export { AddLenderOfRecordForm };
