import { ProductComponentVisibility } from '@/hooks/useProductComponentConfiguration';
import { ORIGINATOR_ROLES } from '@/hooks/useRole';
import { formatAmount, formatPercent } from '@/utils/formatters';
import { adjustNullableNumber } from '@/utils/helpers';
import { sum } from '@/utils/math';
import { arrayNotEmpty } from '@/utils/validation';
import { BalanceSummary, ProgressDetails } from './BalanceWidget.props';
import { UserTypes } from 'kennek/interfaces/accounts';
import { LoanSummary } from '@/interfaces/loans/queries';

export const createPercentageLabel = (percentage: number): string => {
  if (isNaN(percentage)) {
    return `0% Paid`;
  }

  return `${percentage > 100 ? 100 : percentage < 0 ? 0 : percentage}% Paid`;
};

export const isBadgeSectionVisible = (state: string): boolean => {
  return ['RESTRUCTURED', 'RESCHEDULED', 'REPAID'].includes(state);
};

export const createProgressDetailsClassName = (color: string): string => {
  return [
    'w-[10px]',
    'h-[10px]',
    'rounded-full',
    'text-[11px]',
    'mr-[5px]',
    getBackgroundClass(color),
  ].join(' ');
};

const getBackgroundClass = (color: string): string => {
  switch (color) {
    case '#0ED98D':
      return 'bg-primary-600';
    case '#F04BDC':
      return 'bg-info-500';
    default:
      return `bg-[${color}]`;
  }
};

export const mapLoanSummary = (
  role: UserTypes,
  loanSummary: LoanSummary,
  isWrittenOffLoan: boolean,
  isFactorLoan: boolean,
  loanBalanceConfig: ProductComponentVisibility
) => {
  const totalValue: number =
    !isWrittenOffLoan && loanSummary?.principalExpected > 0
      ? loanSummary?.principalExpected
      : loanSummary?.totalAmount;

  const totalOutstanding: number =
    loanSummary?.balanceSummary?.totalOutstanding;

  let loanValueToAssetsValuationPercentage = 0;
  if (arrayNotEmpty(loanSummary?.assetInformation)) {
    const loanAssetsValuationSum = sum(
      loanSummary.assetInformation,
      (asset) => asset.assetValuation
    );

    if (loanAssetsValuationSum > 0) {
      loanValueToAssetsValuationPercentage =
        (loanSummary?.totalAmount / loanAssetsValuationSum) * 100;
    }
  }

  return {
    balance: loanSummary?.balanceSummary?.balance,
    balancePaidPercent: Math.round(
      loanSummary?.balanceSummary?.balancePaidPercent
    ),
    detailedSummary: getDetailedSummary(
      role,
      loanSummary,
      totalOutstanding,
      loanValueToAssetsValuationPercentage,
      loanBalanceConfig
    ),
    percentage: Math.round((loanSummary?.totalPaidAmount * 100) / totalValue),
    progress: getProgressItems(loanSummary),
    simpleSummary: getSimpleSummary(
      loanSummary,
      isFactorLoan,
      loanBalanceConfig
    ),
    sumOfPaid:
      loanSummary?.balanceSummary?.sumOfPaid < 0
        ? 0
        : loanSummary?.balanceSummary?.sumOfPaid,
    totalValue,
  };
};

const getSimpleSummary = (
  loanSummary: LoanSummary,
  factorLoan: boolean,
  loanBalanceConfig: ProductComponentVisibility
): BalanceSummary[] => {
  const {
    showInstalmentsRemaining,
    showLeftToPay,
    showInterestRate,
    showPrincipalOutstanding,
  } = loanBalanceConfig;

  const details = [
    {
      condition: showInstalmentsRemaining,
      title: loanSummary?.productConfiguration?.payment
        ?.blockPaymentTillLoanMatured
        ? 'Int. Periods remaining'
        : 'Instalments remaining',
      detail: loanSummary?.remainingInstallmentsCount,
    },
    {
      condition: factorLoan && showLeftToPay,
      title: 'Left to pay',
      detail: formatAmount(
        loanSummary?.leftToPay,
        false,
        loanSummary?.currency
      ),
    },
    {
      condition: showInterestRate,
      title: 'Interest rate',
      detail: formatPercent(loanSummary?.displayedInterestRate, 3),
    },
    {
      condition: showPrincipalOutstanding,
      title: 'Principal outstanding',
      detail: formatAmount(
        loanSummary?.balances?.principalBalance,
        true,
        loanSummary?.currency
      ),
    },
  ];

  return details
    .filter((x) => x.condition)
    .map(({ title, detail }) => {
      return {
        detail,
        title,
      };
    });
};

const getDetailedSummary = (
  role: UserTypes,
  loanSummary: LoanSummary,
  totalOutstanding: number,
  loanValueToAssetsValuationPercentage: number,
  loanBalanceConfig: ProductComponentVisibility
): BalanceSummary[] => {
  const { showLtv, showTotalOutstanding, showInstalmentsRemaining } =
    loanBalanceConfig;
  const isOriginator = ORIGINATOR_ROLES.includes(role);
  const details = [
    {
      title: loanSummary?.productConfiguration?.payment
        ?.blockPaymentTillLoanMatured
        ? 'Int. Periods remaining'
        : 'Instalments remaining',
      detail: loanSummary?.remainingInstallmentsCount,
      condition: showInstalmentsRemaining,
    },
    {
      title: 'Total outstanding',
      detail: formatAmount(totalOutstanding, false, loanSummary?.currency),
      condition: showTotalOutstanding,
    },
    {
      condition:
        isOriginator && loanValueToAssetsValuationPercentage > 0 && showLtv,
      title: 'LTV',
      detail: formatPercent(loanValueToAssetsValuationPercentage),
    },
  ];

  return details
    .filter((x) => x.condition)
    .map(({ title, detail }) => {
      return {
        detail,
        title,
      };
    });
};

const getProgressItems = (loanSummary: LoanSummary): ProgressDetails[] => {
  return [
    createPrincipalProgress(loanSummary),
    createInterestProgress(loanSummary),
    createFeesProgress(loanSummary),
  ].filter((progressItem: ProgressDetails) => progressItem.total);
};

const createPrincipalProgress = (loanSummary: LoanSummary): ProgressDetails => {
  const principalPaid: number = adjustNullableNumber(
    loanSummary?.balances?.principalPaid
  );

  const principal: number = adjustNullableNumber(
    loanSummary?.balanceSummary?.principal
  );

  return {
    id: 'principal',
    color: '#0ED98D',
    label: 'Principal',
    paid: principalPaid,
    total: principal,
    wholeBalance: principal,
  };
};

const createInterestProgress = (loanSummary: LoanSummary): ProgressDetails => {
  const interestPaid: number = adjustNullableNumber(
    loanSummary?.balanceSummary?.interestPaid
  );
  const interest: number = adjustNullableNumber(
    loanSummary?.balanceSummary?.interest
  );

  return {
    id: 'interest',
    color: '#0038FF',
    label: 'Interest',
    paid: interestPaid,
    total: interest,
    wholeBalance: interest,
  };
};

const createFeesProgress = (loanSummary: LoanSummary): ProgressDetails => {
  const fees: number = adjustNullableNumber(loanSummary?.balanceSummary?.fees);
  const feesPaid: number = adjustNullableNumber(
    loanSummary?.balanceSummary?.feesPaid
  );

  return {
    id: 'fees',
    color: '#F04BDC',
    label: 'Fees',
    paid: feesPaid,
    total: fees,
    wholeBalance: fees,
  };
};
