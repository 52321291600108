import React from 'react';
import classNames from 'classnames';
import { XIcon } from '@heroicons/react/outline';
import { Title } from '..';

interface Props extends React.ComponentProps<React.FC> {
  paymentModal?: boolean;
  isVisible: boolean;
  setIsVisible?: (isVisible: boolean) => void;
  onClose?: () => void;
  includeHeader: 'normal' | 'withCloseButton' | 'none';
  width?: string;
  maxHeight?: string;
  bgOpacity?: string;
  paddingBodyX?: string;
  paddingBodyY?: string;
  paddingHeaderY?: string;
  paddingHeaderX?: string;
  marginY?: string;
  className?: string;
  closeOnClickOutside?: boolean;
  headerTitle?: string;
  headerSubtitle?: string;
  wrapperClassName?: string;
  children: React.ReactNode;
}

const Modal = ({
  paymentModal,
  isVisible,
  setIsVisible,
  onClose,
  children,
  includeHeader,
  width = 'w-loginBannerWidth',
  maxHeight = 'max-h-screen',
  bgOpacity = 'bg-opacity-100',
  paddingBodyX = 'px-8',
  paddingBodyY = '',
  paddingHeaderX = 'px-8',
  paddingHeaderY = '',
  marginY = 'my-4',
  className = '',
  closeOnClickOutside = true,
  headerTitle = '',
  headerSubtitle = '',
  wrapperClassName = '',
}: Props) => {
  const onClickOutsideModalHandler = () => {
    if (closeOnClickOutside) {
      setIsVisible?.(false);
    }
  };
  return (
    <>
      {isVisible && (
        <div
          onClick={onClickOutsideModalHandler}
          className={classNames(
            'fixed inset-0 z-50 bg-neutral-200',
            bgOpacity,
            maxHeight,
            className
          )}
        >
          <div
            className={classNames(
              'flex flex-col justify-center min-h-screen mx-auto',
              paymentModal ? 'w-[550px]' : width
            )}
          >
            <div
              className={classNames(
                'relative max-h-fit border rounded-md border-neutral-300 w-full bg-white opacity-100 outline-none focus:outline-none my-2',
                wrapperClassName
              )}
              onClick={(event) => event.stopPropagation()}
            >
              {includeHeader === 'normal' && <div className="mt-4" />}
              {includeHeader === 'withCloseButton' && (
                <div
                  className={classNames(
                    'mt-4 rounded-t',
                    paddingHeaderX,
                    paddingHeaderY
                  )}
                  data-auto-container="modal-header"
                >
                  <div
                    className={classNames(
                      {
                        'justify-between': headerTitle,
                        'justify-end': !headerTitle,
                      },
                      'flex flex-1 justify-end items-center'
                    )}
                  >
                    {headerTitle && (
                      <div>
                        <Title title={headerTitle} titleSize="lg" insideCard />
                        {headerSubtitle && (
                          <Title
                            title={headerSubtitle}
                            titleSize="xs"
                            insideCard
                            className="py-2"
                          />
                        )}
                      </div>
                    )}
                    <XIcon
                      className={classNames(
                        'w-5 h-5 cursor-pointer hover:text-neutral-600',
                        !paymentModal && 'text-neutral-300'
                      )}
                      onClick={() => {
                        onClose?.();
                        setIsVisible && setIsVisible(false);
                      }}
                    />
                  </div>
                </div>
              )}

              <div
                className={classNames(
                  'pb-8 w-full',
                  paddingBodyX,
                  paddingBodyY,
                  marginY
                )}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
