import React, { useContext } from 'react';
import classNames from 'classnames';
import { TableVariant, TableVariantType } from './TableContainer';
import { TableContext } from './TableContext';

export interface NameProps {
  title: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
  asButton?: boolean;
  style?: 'heading-300';
  dashboard?: boolean;
  subtitle?: string;
}

const Container = (props: NameProps) => {
  const {
    active = true,
    onClick,
    asButton,
    leftIcon,
    rightIcon,
    title,
    style,
    dashboard,
    subtitle,
  } = props;
  const { variant } = useContext(TableContext) ?? {};

  const useStandardAppearance = (variant?: TableVariantType) => {
    return (
      variant === TableVariant.STANDARD || variant === TableVariant.DASHBOARD
    );
  };

  const className = classNames('flex items-center', {
    'border-b-primary-500 border-b-2 mt-0.5':
      // eslint-disable-next-line react-hooks/rules-of-hooks,sonarjs/rules-of-hooks
      active && useStandardAppearance(variant),
    'px-4': useStandardAppearance(variant),
  });

  const child = (
    <Title
      title={title}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      style={style}
      dashboard={dashboard}
      active={active}
      subtitle={subtitle}
    />
  );

  if (asButton)
    return (
      <button type="button" onClick={onClick} className={className}>
        {child}
      </button>
    );

  return <div className={className}>{child}</div>;
};

type IconProps = {
  children: React.ReactNode;
};

const Icon = ({ children }: IconProps) => {
  return (
    <div className="flex items-center justify-center text-secondary-600 w-5 h-5">
      {children}
    </div>
  );
};

type TitleProps = {
  title: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  style: NameProps['style'];
  dashboard?: boolean;
  active?: boolean;
  subtitle?: string;
};
const Title = ({
  title,
  leftIcon,
  rightIcon,
  style,
  dashboard,
  active,
  subtitle,
}: TitleProps) => {
  const { variant } = useContext(TableContext) ?? {};
  return (
    <div>
      <div className="flex items-center gap-2">
        {leftIcon && <Icon>{leftIcon}</Icon>}
        <div>
          <h2
            className={classNames('text-secondary-600 select-none ', {
              'heading-300': variant === TableVariant.STANDARD && !style,
              'heading-400': variant === TableVariant.COMPACT && !style,
              'pb-[10px] pt-[30px] text-[14px] font-[500] text-neutral-800':
                active && dashboard,
              'pb-[10px] pt-[33px] text-[13px] font-normal text-[#334155]':
                !active && dashboard,
              style,
            })}
          >
            {title}
          </h2>
        </div>
        {rightIcon && <Icon>{rightIcon}</Icon>}
      </div>
      {subtitle && <p className="body-400 pt-2">{subtitle}</p>}
    </div>
  );
};

export { Container as Name };
