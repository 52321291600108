import { useGetLoanDisabledFeaturesQuery } from '@/services/kennek';
import { ProductFeatures } from 'kennek/interfaces/kennek';

export const useLoanDisabledFeatures = (loanId: string) => {
  const { data: disabledFeatures = [], isLoading } =
    useGetLoanDisabledFeaturesQuery({ loanId }, { skip: !loanId });

  if (isLoading) {
    return {
      isDownloadRepaymentScheduleEnabled: false,
      isDownloadRepaymentStatementEnabled: false,
      isEditRepaymentColumnsEnabled: false,
      isEditRepaymentSettingsEnabled: false,
      isEditRepaymentScheduleEnabled: false,
      isViewTranchesEnabled: false,
      isManualDisbursementEnabled: false,
      isEditLoanEnabled: false,
      isEditInterestRateEnabled: false,
      isAddManualFeeEnabled: false,
    };
  }

  return {
    isDownloadRepaymentScheduleEnabled: !disabledFeatures.includes(
      ProductFeatures.DOWNLOAD_REPAYMENT_SCHEDULE
    ),
    isDownloadRepaymentStatementEnabled: !disabledFeatures.includes(
      ProductFeatures.DOWNLOAD_REPAYMENT_STATEMENT
    ),
    isEditRepaymentColumnsEnabled: !disabledFeatures.includes(
      ProductFeatures.EDIT_REPAYMENT_COLUMNS
    ),
    isEditRepaymentSettingsEnabled: !disabledFeatures.includes(
      ProductFeatures.EDIT_REPAYMENT_SETTINGS
    ),
    isEditRepaymentScheduleEnabled: !disabledFeatures.includes(
      ProductFeatures.EDIT_REPAYMENT_SCHEDULE
    ),
    isViewTranchesEnabled: !disabledFeatures.includes(
      ProductFeatures.VIEW_TRANCHES
    ),
    isManualDisbursementEnabled: !disabledFeatures.includes(
      ProductFeatures.MANUAL_DISBURSEMENT
    ),
    isEditLoanEnabled: !disabledFeatures.includes(ProductFeatures.EDIT_LOAN),
    isEditInterestRateEnabled: !disabledFeatures.includes(
      ProductFeatures.EDIT_INTEREST_RATE
    ),
    isAddManualFeeEnabled: !disabledFeatures.includes(
      ProductFeatures.ADD_MANUAL_FEE
    ),
    isLoadingLoanDisabledFeatures: isLoading,
  };
};
