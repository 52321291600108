/**
 * Helper function that receives a Full Name string and returns the first two initials
 * @param {string} fullName - string to parse
 */
export const obtainInitials = (fullName: string) => {
  return fullName
    ?.split(' ')
    .map((name) => name[0])
    .map((initial, index) => (index > 1 ? '' : initial))
    .join('');
};
