import React from 'react';
import classNames from 'classnames';
import { Select } from 'ui';
import { mapCounterpartiesToSelectOptions } from './CounterpartySelectInput.utils';
import { CounterpartyDto } from '@/interfaces/loans';

export type CounterpartySelectInputProps = {
  counterparties: CounterpartyDto[];
  disabled?: boolean;
  className?: string;
  error?: string;
  onAddNewClick?: (meta?: string) => void;
  onValueChange: (value: string) => void;
  value: string;
};

export const CounterpartySelectInput: React.FC<CounterpartySelectInputProps> = (
  props
) => {
  const onValueChangeHandler = (e: React.ChangeEvent<any>) => {
    if (e.target.value !== undefined) {
      props.onValueChange(e.target.value);
    }
  };

  return (
    <Select
      disabled={props.disabled}
      className={classNames(props.className)}
      optionClassName="overflow-y-scroll overflow-x-auto"
      label="Counterparty account"
      options={mapCounterpartiesToSelectOptions(props.counterparties)}
      error={props.error}
      value={props.value}
      onChange={onValueChangeHandler}
      optionsButton={
        props.onAddNewClick
          ? {
              text: '+ Add new Counterparty ',
              onClick: () => props.onAddNewClick(),
            }
          : undefined
      }
    />
  );
};
