type StepSetItem<T extends string> = {
  stepId: T;
  order: number;
  accessible?: boolean;
};

export class StepsSet<T extends string> {
  steps: Array<StepSetItem<T>> = new Array<StepSetItem<T>>();

  add(newStep: StepSetItem<T>) {
    if (this.steps.some((step) => step.order === newStep.order)) {
      let isFound = false;
      this.steps.forEach((step, index) => {
        if (step.order === newStep.order) {
          this.steps[index].order = this.steps[index].order + 100;
          isFound = true;
        } else if (isFound) {
          // If previously incremented step order that matched with newStep that means next step will has same order
          // so need to increment order the steps after
          this.steps[index].order += 100;
        }
      });
    }
    if (this.steps.some((step) => step.stepId === newStep.stepId)) {
      return;
    }
    this.steps.push(newStep);
  }

  delete(stepId: T) {
    this.steps = this.steps.filter((step) => step.stepId !== stepId);
    return this.steps;
  }

  [Symbol.iterator]() {
    let pointer = 0;
    const steps = this.steps;

    return {
      next(): IteratorResult<StepSetItem<T>> {
        if (pointer < steps.length) {
          return {
            done: false,
            value: steps[pointer++],
          };
        }
        return {
          done: true,
          value: null,
        };
      },
    };
  }
}
