import { Session } from 'next-auth';
import { getSession, signOut } from 'next-auth/react';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { env } from '@/env/public';

const SESSION_CHECK_INTERVAL = 2 * 60 * 1000; // 2 minutes
let cachedSession: Session | null = null;
let sessionLastChecked: number | null = null;

const checkAndRefreshSession = async () => {
  const now = Date.now();
  const shouldCheckSession =
    sessionLastChecked && now - sessionLastChecked > SESSION_CHECK_INTERVAL;

  if (!cachedSession || shouldCheckSession) {
    cachedSession = await getSession();
    sessionLastChecked = now;
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: '',
});

const rootApi = createApi({
  reducerPath: 'api',
  baseQuery: async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions?: { noAuth?: boolean }
  ) => {
    await checkAndRefreshSession();

    const result = await baseQuery(args, api, extraOptions);
    if (!extraOptions?.noAuth && result?.error?.status === 401) {
      const session = await getSession();
      if (!session) await signOut({ redirect: false });
    }
    return result;
  },
  endpoints: () => ({}),
  keepUnusedDataFor: env.NEXT_PUBLIC_RTK_QUERY_KEEP_UNUSED_DATA_FOR,
  tagTypes: [
    'Users',
    'Loans',
    'Loan-Groups',
    'Loan-Note',
    'Companies',
    'Originator-Investors',
    'Lender-Of-Records',
    'RepaymentSchedule',
    'Scheduling',
    'Tasks',
    'Columns',
    'Notifications',
    'Originator',
    'RiskAnalysisReports',
    'Underwriting-Request',
    'Underwriting-Execution',
    'Underwriting-Config',
    'Underwriting-Datastore',
    'Underwriting-Codat-Integration',
    'Underwriting-Integrations',
    'Underwriting-Flow',
    'Document-Management',
    'Investment-Funding-Methods',
    'Investment-Funding-Methods-Fees-Settings',
  ],
});

export { rootApi };
