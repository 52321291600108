import * as React from 'react';
import { useContext } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { Button } from 'ui';
import { ROUTES } from '@/constants/routes';
import useDirectDebitFeatures from '@/hooks/useDirectDebitFeatures';
import useGetUserIdentity from '@/hooks/useGetUserIdentity';
import { getRouterQuery } from '@/utils/helpers';
import { LoanSummaryContext } from './ContextWrapper';
import { DirectDebitMandateStatus } from '@/interfaces/loans/queries';
import { DirectDebitProps } from '@/interfaces/next-payment';

const errorColor = 'text-error-500';

const directDebitMap: Record<DirectDebitMandateStatus, DirectDebitProps> = {
  UNDER_REVIEW: { text: 'Under review', color: 'text-warning-500' },
  ACTIVE: { text: 'Active', color: 'text-neutral-600' },
  INACTIVE: { text: 'Inactive', color: 'text-neutral-700' },
  SUSPENDED: { text: 'Suspended', color: errorColor },
  REJECTED: { text: 'Declined', color: errorColor },
  CANCELLED: { text: 'Cancelled', color: errorColor },
};

const activateDDStatuses: DirectDebitMandateStatus[] = [
  'INACTIVE',
  'SUSPENDED',
  'CANCELLED',
  'REJECTED',
];

const DirectDebitStateText: React.FC = () => {
  const router = useRouter();
  const { isBorrower, isOriginator } = useGetUserIdentity();

  const { nextPaymentData, directDebitMandateStatus, loanId } =
    useContext(LoanSummaryContext);

  const canUseDirectDebitFeatures = useDirectDebitFeatures(
    nextPaymentData?.directDebitAccountId
  );
  const directDebitPath = getRouterQuery(ROUTES.DIRECT_DEBIT, { loanId });

  if (
    !canUseDirectDebitFeatures ||
    (isOriginator &&
      nextPaymentData?.customerId &&
      !nextPaymentData?.directDebitAccountId)
  ) {
    return null;
  }

  const showActiveDDButton =
    isBorrower && activateDDStatuses.includes(directDebitMandateStatus);

  return (
    <>
      {(directDebitMandateStatus !== 'INACTIVE' || !isBorrower) && (
        <p className="flex items-center mt-4">
          <span className="body-400 text-neutral-700">Direct Debit: </span>
          <span
            className={classNames(
              'heading-200 ml-1 font-medium',
              directDebitMap[directDebitMandateStatus].color
            )}
          >
            {directDebitMap[directDebitMandateStatus].text}
          </span>
        </p>
      )}
      {showActiveDDButton && (
        <Button
          className="w-[200px] mt-4"
          layout="primary"
          onClick={() => router.push(directDebitPath)}
        >
          Activate Direct Debit
        </Button>
      )}

      {directDebitMandateStatus === 'UNDER_REVIEW' && (
        <p className="text-neutral-600 body-200 mt-2">
          The review process could take up to 4 days
        </p>
      )}
    </>
  );
};

export default DirectDebitStateText;
