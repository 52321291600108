import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Button, Dialog, FormInput, Select } from 'ui';
import { v4 as uuid } from 'uuid';
import { TrashIcon, UserAddIcon } from '@heroicons/react/outline';
import { FACILITY_MESSAGE } from '@/constants/messages';
import { SNACKBAR_FACILITY } from '@/constants/snackbar-messages';
import { useSelectEditLoanFacilitiesStore } from '@/features/edit-loan/editLoanSlice';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useGetLoanProductRulesQuery, useGetLoanQuery } from '@/services/loans';
import {
  useGetFacilityForProductQuery,
  useGetLoanFacilitiesForLoanQuery,
} from '@/services/loans/facility';
import { formatAmount } from '@/utils/formatters';
import { AddFacilityForm } from './AddFacilityForm';
import { isLoanFacility } from './utils';
import { Loan } from '@/interfaces/loans';
import {
  Facility,
  LoanFacility,
  PaymentFacilityStatus,
  RowLoanFacility,
} from '@/interfaces/loans/facility';

interface FacilitiesFormProps {
  onContinue: () => void;
  onBack: () => void;
  loanId?: Loan['id']; // only for edit flow
  availableAmount: number;
  productTypeKey: Loan['productTypeKey'];
  assignedBranchKey: Loan['assignedBranchKey'];
  form: UseFormReturn<FieldValues, any, undefined>;
  availableToAllocateOnly?: boolean;
  disableAddNewFacility?: boolean;
  initialStateValues?: RowLoanFacility[];
  disablePreload?: boolean;
}

const FacilitiesForm = ({
  loanId,
  availableAmount: availableAmount = 0,
  productTypeKey,
  assignedBranchKey,
  form,
  initialStateValues = [],
  availableToAllocateOnly,
  disableAddNewFacility = false,
  disablePreload = false,
}: FacilitiesFormProps) => {
  // Check if empty row was already added.
  let emptyRowAddedFirst = false;
  const labelsConfig = useGetLabelsConfig();
  const availableAmountRef = useRef(availableAmount);

  useEffect(() => {
    availableAmountRef.current = availableAmount;
    form.trigger();
  }, [availableAmount]);

  const {
    setError,
    clearErrors,
    setValue,
    register,
    getValues,
    reset,
    unregister,
    trigger,
    formState: { errors },
  } = form;

  const formValues = form.getValues();
  const rowLoanFacilities = Object.keys(formValues.data || {}).map(
    (selectId) => ({
      ...formValues.data[selectId],
      selectId,
    })
  );

  const [lastEditedFacility, setLastEditedFacility] = useState({});

  const snackbar = useSnackbar();

  const [selectedId, setSelectedId] = useState<LoanFacility['facilityId']>('');
  const [usedFacilities, setUsedFacilities] = useState<number>(0);
  const [totalUsedAmount, setTotalUsedAmount] = useState<number>(0);

  const calculateTotalUsedAmount = (
    loanFacilitiesList: RowLoanFacility[] = []
  ) => {
    const amount = getTotalAmount(loanFacilitiesList);
    setTotalUsedAmount(amount);
  };

  const { data: loanDetails } = useGetLoanQuery(loanId, {
    skip: !loanId,
  });

  const getTotalAmount = (
    loanFacilities: RowLoanFacility[] | LoanFacility[]
  ): number => {
    return loanFacilities?.length
      ? loanFacilities
          .map((f) => parseFloat(f.amount || 0))
          .reduce((a, b = 0) => a + b, 0)
      : 0;
  };
  useEffect(() => {
    setUsedFacilities(rowLoanFacilities?.length || 0);
    calculateTotalUsedAmount(rowLoanFacilities);
  }, [rowLoanFacilities]);

  const [modal, setModal] = useState<'addFacility' | false>(false);

  const initialState = useRef<{
    hasChanged: boolean;
    numberOfLoanFacilities: number;
    loanFacilities: Pick<
      RowLoanFacility,
      'facilityId' | 'name' | 'amount' | 'productEncodedKey'
    >[];
  }>({ hasChanged: false, numberOfLoanFacilities: 0, loanFacilities: [] });

  const { data: loanProductRules, ...rulesQuery } = useGetLoanProductRulesQuery(
    { id: productTypeKey },
    { skip: !productTypeKey }
  );

  const {
    data: fetchedloanFacilities,
    isLoading: isLoadingLoanFacilities,
    isSuccess: hasFetchedLoanFacilities,
  } = useGetLoanFacilitiesForLoanQuery(loanDetails?.encodedKey, {
    skip: !loanDetails?.encodedKey || disablePreload,
  });

  const { loanFacilities: localLoanFacilities } =
    useSelectEditLoanFacilitiesStore();
  const loanFacilities = disablePreload
    ? localLoanFacilities
    : fetchedloanFacilities;

  const {
    data: productFacilities = [],
    isFetching: isFetchingGetFacilitiesForProduct,
  } = useGetFacilityForProductQuery(
    {
      branchEncodedKey: assignedBranchKey,
      productEncodedKey: productTypeKey,
    },
    {
      skip: !assignedBranchKey || !productTypeKey || availableToAllocateOnly,
    }
  );

  const availableFacilities = useMemo(() => {
    if (!availableToAllocateOnly) return productFacilities;
    if (!loanFacilities) return [];
    // map ids for availableToAllocateOnly for payments loanFacilityId as value not facilityId from template
    return loanFacilities
      .filter((facility) => {
        if (isLoanFacility(facility)) {
          const payments = facility?.payments?.length
            ? facility.payments
                .filter(
                  (payment) => payment.status === PaymentFacilityStatus.ACTIVE
                )
                .map((payment) => payment.amount)
                .reduce((acc, amount) => acc + amount, 0)
            : 0;
          return facility.amount > payments;
        }
        return parseFloat(facility.amount) > 0;
      })
      .map((f) => ({ ...f, loanFacilityId: f._id, _id: f.facilityId }));
  }, [availableToAllocateOnly, loanFacilities, productFacilities]);

  const facilitiesOptions = useMemo(() => {
    return availableFacilities.map((o) => {
      return {
        value: o?._id,
        label: o?.name,
        loanFacilityId: o?.loanFacilityId,
      };
    });
  }, [availableFacilities]);

  const availableFacilitiesOptions = useMemo(() => {
    return (
      facilitiesOptions.filter(
        (o) => !rowLoanFacilities?.map((r) => r.facilityId).includes(o.value)
      ) || []
    );
  }, [facilitiesOptions, rowLoanFacilities]);

  const editFacilitiesStore = useSelectEditLoanFacilitiesStore();

  useEffect(() => {
    if (initialStateValues && initialStateValues.length > 0) {
      // Use initialStateValues to set the initial state
      reset();
      const result = initialStateValues.map((o) => {
        const rowLoanFacility: RowLoanFacility = {
          facilityId: o.facilityId,
          productEncodedKey: o.productEncodedKey,
          amount: o.amount?.toString() || '0',
          name: o.name,
          selectId: o.selectId,
        };
        onAddFacility(rowLoanFacility, rowLoanFacility.selectId);
        return rowLoanFacility;
      });
      setTotalUsedAmount(getTotalAmount(result));
      setUsedFacilities(result.length);
    }
  }, [initialStateValues]);

  useEffect(() => {
    const isInitialState = initialStateValues && initialStateValues.length > 0;
    if (
      !isInitialState &&
      !availableToAllocateOnly &&
      availableFacilities &&
      loanFacilities &&
      loanFacilities.length !== 0
    ) {
      reset();
      setTotalUsedAmount(0);
      initialState.current.numberOfLoanFacilities = loanFacilities.length;
      initialState.current.loanFacilities = loanFacilities.map(
        (o: LoanFacility | RowLoanFacility) => {
          const rowLoanFacility: RowLoanFacility = {
            facilityId: o.facilityId,
            productEncodedKey: o.productEncodedKey,
            amount: o.amount?.toString() || '0',
            name: o.name,
            selectId: o.selectId,
          };
          onAddFacility(rowLoanFacility, rowLoanFacility.selectId);
          return rowLoanFacility;
        }
      );
    }

    if (rowLoanFacilities.length === 0) {
      setUsedFacilities(initialState.current.loanFacilities?.length || 0);
      calculateTotalUsedAmount(initialState.current.loanFacilities);
    }

    if (
      hasFetchedLoanFacilities &&
      loanFacilities?.length === 0 &&
      rowLoanFacilities?.length === 0 &&
      !emptyRowAddedFirst
    ) {
      emptyRowAddedFirst = true;
      onAddFacility();
    }
  }, [loanFacilities, hasFetchedLoanFacilities, editFacilitiesStore]);

  const onAddFacility = (loadedRow?: RowLoanFacility, selectedId?: string) => {
    const selectId = selectedId || uuid();

    if (availableToAllocateOnly) {
      register(`data.${selectId}.loanFacilityId`);
    }
    register(`data.${selectId}.name`);
    register(`data.${selectId}.amount`, {
      required: FACILITY_MESSAGE.FACILITY_AMOUNT_REQUIRED,
      pattern: {
        value: /^\d*\.?\d*$/i,
        message: FACILITY_MESSAGE.FACILITY_AMOUNT_REQUIRED,
      },
      validate: {
        greaterThanZero: (value) =>
          parseFloat(value) > 0 ||
          `Amount must be greater than ${formatAmount(
            0,
            true,
            loanDetails?.currency
          )}`,

        checkAmountExceededPerAvailableAmount: () => {
          const formValues = getValues();
          const total =
            Object.values(formValues.data).reduce(
              (acc: number, data: { amount: string }) =>
                acc + (parseFloat(data?.amount) || 0),
              0
            ) || 0;
          if (
            typeof total === 'number' &&
            +total.toFixed(2) > availableAmountRef.current
          ) {
            return availableToAllocateOnly
              ? FACILITY_MESSAGE.AMOUNT_EXCEEDED_FOR_PAYMENT
              : FACILITY_MESSAGE.AMOUNT_EXCEEDED(labelsConfig);
          }
        },
        checkAmountExceededPerFacility: (value) => {
          const newAmount = parseFloat(value);
          if (availableToAllocateOnly) {
            const rows: RowLoanFacility[] = Object.entries(
              form.getValues().data
            ).map((r) => r[1]);
            const row = rows.find((r) => r.selectId === selectId);
            if (
              newAmount >
              getAvailableToAllocateAmountForFacility(row.facilityId)
            )
              return FACILITY_MESSAGE.AMOUNT_EXCEEDED_FOR_FACILITY;
          }
        },
      },
    });

    register(`data.${selectId}.facilityId`, {
      required: FACILITY_MESSAGE.FACILITY_NAME_REQUIRED,
    });

    if (loadedRow) {
      setValue(`data.${selectId}.facilityId`, loadedRow?.facilityId);
      setValue(`data.${selectId}.name`, loadedRow?.name);
      setValue(`data.${selectId}.amount`, loadedRow?.amount);
    }
    setValue(`data.${selectId}.selectId`, selectId);
    setLastEditedFacility(selectId || lastEditedFacility);
  };

  const onDeleteLoanFacility = (row: RowLoanFacility) => {
    clearErrors(`data.${row.selectId}.facilityId`);
    clearErrors(`data.${row.selectId}.amount`);
    clearErrors(`data.${row.selectId}.name`);
    initialState.current.hasChanged = true;
    removeRow(row.selectId);
  };

  const clearExceededAmountErrors = (rows: RowLoanFacility[] = []) => {
    // Clear errors for all inputs with the 'value' type, exceeded amount
    rows.forEach((r) => {
      const error = errors?.data?.[r.selectId]?.amount;
      if (error && error.type === 'value') {
        clearErrors(`data.${r.selectId}.amount`);
      }
    });
  };

  const removeRow = (selectId: RowLoanFacility['selectId']) => {
    const filteredRows = rowLoanFacilities.filter((o: RowLoanFacility) => {
      if (o.selectId === selectId) {
        unregister(`data.${selectId}`);
      }
      return o.selectId !== selectId;
    });
    setValue(
      'data',
      Object.fromEntries(filteredRows.map((row) => [row.selectId, row]))
    );
    setUsedFacilities(filteredRows?.length || 0);
    calculateTotalUsedAmount(filteredRows);
    const updatedTotalUsedAmount = getTotalAmount(filteredRows);
    if (updatedTotalUsedAmount <= availableAmountRef.current) {
      clearExceededAmountErrors(filteredRows);
    }
  };

  const handleSetLoanFacilityValue = async (
    facilityId: Facility['_id'],
    selectId: RowLoanFacility['selectId']
  ) => {
    if (!facilityId || !selectId) {
      return;
    }
    setUsedFacilities(rowLoanFacilities?.length || 0);
    const facilityOption = facilitiesOptions.find(
      (f) => f.value === facilityId
    );
    setValue(`data.${selectId}.name`, facilityOption.label);
    setValue(`data.${selectId}.facilityId`, facilityId);
    if (availableToAllocateOnly) {
      const loanFacility = loanFacilities.find(
        (facility) => facility.facilityId === facilityId
      );
      const loanFacilityId = loanFacility?._id;
      setValue(`data.${selectId}.loanFacilityId`, loanFacilityId);
    }
    clearErrors(`data.${selectId}.facilityId`);
    await trigger();
  };

  const handleAmountChange = useCallback(
    async (row: RowLoanFacility, amount: string, rowLoanFacilities) => {
      const selectId = row.selectId;
      let amountNumber = '0';
      if (amount) {
        amountNumber = amount?.toString() ?? '0';
        setValue(`data.${selectId}.amount`, amountNumber);
      } else {
        setValue(`data.${selectId}.amount`, undefined);
      }
      const rowsNewState: RowLoanFacility[] = rowLoanFacilities.map(
        (facility: RowLoanFacility) => {
          if (facility.selectId === selectId) {
            return { ...facility, amount: amountNumber };
          }
          return facility;
        }
      );
      setValue(
        'data',
        Object.fromEntries(rowsNewState.map((row) => [row.selectId, row]))
      );
      clearErrors(`data.${selectId}.amount`);
      if (!amountNumber) {
        setError(`data.${selectId}.amount`, {
          type: 'required',
          message: FACILITY_MESSAGE.FACILITY_AMOUNT_REQUIRED,
        });
      }
      calculateTotalUsedAmount(rowsNewState);
      await trigger();
    },
    [clearErrors, availableAmount, setError, setValue, errors]
  );

  const handleAddFacility = async (
    facilityId: Facility['id'],
    name: Facility['name'],
    selectId: string
  ) => {
    if (!facilityId || !name) return;

    try {
      setValue(`data.${selectId}.facilityId`, facilityId);
      setValue(`data.${selectId}.name`, name);
      const rowsNewState: RowLoanFacility[] = rowLoanFacilities.map(
        (facility: RowLoanFacility) => {
          if (facility.selectId === selectId) {
            return { ...facility, name, facilityId };
          }
          return facility;
        }
      );
      clearErrors(`data.${selectId}.facilityId`);
      setValue(
        'data',
        Object.fromEntries(rowsNewState.map((row) => [row.selectId, row]))
      );
      snackbar.show({
        id: 'CREATE_FACILITY_SUCCESS',
        severity: 'primary',
        title: SNACKBAR_FACILITY.CREATE_SUCCESS,
      });
      setSelectedId(undefined);
    } catch (_err) {
      snackbar.show({
        id: 'CREATE_FACILITY_FAILED',
        severity: 'error',
        title: SNACKBAR_FACILITY.CREATE_FAILED,
        content: SNACKBAR_FACILITY.CREATE_FAILED_CONTENT,
      });
    }
  };

  // Function to calculate the already paid amount for a loan facility
  const calculateAlreadyPaidAmount = (
    payments: LoanFacility['payments']
  ): number => {
    return (
      payments
        .filter((payment) => payment.status === PaymentFacilityStatus.ACTIVE)
        .reduce((total, payment) => total + payment.amount, 0) || 0
    );
  };

  // Main function to get available amount to allocate for a facility
  const getAvailableToAllocateAmountForFacility = (
    loanFacilityId: string
  ): number => {
    const loanFacility = loanFacilities?.find(
      (f) => f.facilityId === loanFacilityId
    );
    if (!loanFacility) return 0;

    if (!isLoanFacility(loanFacility)) {
      return parseFloat((+loanFacility.amount).toFixed(2));
    }

    const alreadyPaidAmount = calculateAlreadyPaidAmount(loanFacility.payments);
    return parseFloat((loanFacility.amount - alreadyPaidAmount).toFixed(2));
  };

  const getAvailableToAllocateLabel = (loanFacilityId: string) => {
    if (!availableToAllocateOnly || !loanFacilityId) return '';
    return `Available to allocate ${formatAmount(
      getAvailableToAllocateAmountForFacility(loanFacilityId),
      true,
      loanDetails.currency
    )}`;
  };

  const isLoading =
    !productTypeKey ||
    (loanDetails && isLoadingLoanFacilities) ||
    rulesQuery.isLoading ||
    isFetchingGetFacilitiesForProduct;
  return (
    <div>
      {isLoading ? (
        <></>
      ) : (
        <>
          <div className="flex flex-col">
            <div className="w-full">
              {!!rowLoanFacilities?.length && (
                <div className="flex flex-col mb-3">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    noValidate
                    className="w-full"
                    autoComplete="off"
                  >
                    {rowLoanFacilities?.length > 0 && (
                      <div className="flex justify-between items-top">
                        <label className="heading-100 w-[160px] mt-1">
                          Facility
                        </label>
                        <label className="heading-100 w-[160px] mt-1">
                          Amount
                        </label>
                        <div className="w-[42px]"></div>
                      </div>
                    )}
                    {rowLoanFacilities.map((row) => (
                      <div key={row?.selectId}>
                        <div
                          className={`flex items-top justify-between  ${
                            availableToAllocateOnly ? 'h-[90px]' : 'h-[80px]'
                          }`}
                        >
                          <Select
                            placeholder="Select a facility"
                            label=" "
                            options={
                              facilitiesOptions.filter(
                                (o) =>
                                  o.value === row.facilityId ||
                                  !rowLoanFacilities
                                    .map((r) => r.facilityId)
                                    .includes(o.value)
                              ) || []
                            }
                            optionsButton={
                              !disableAddNewFacility
                                ? {
                                    text: '+ Add New facility',
                                    onClick: () => {
                                      setSelectedId(row.selectId);
                                      setModal('addFacility');
                                    },
                                  }
                                : undefined
                            }
                            value={row?.facilityId}
                            onChange={(e) =>
                              handleSetLoanFacilityValue(
                                e.target.value,
                                row.selectId
                              )
                            }
                            error={
                              errors?.data?.[row.selectId]?.facilityId?.message
                            }
                            errorBottomPosition={
                              errors?.data?.[row.selectId]?.facilityId?.type ===
                                'value' && '-bottom-3'
                            }
                            className="w-[160px] mt-1 max-h-[70px]"
                          />

                          <FormInput
                            label=" "
                            currencyInput={loanProductRules?.currency}
                            containerClassName={`w-[160px] mt-1 ${
                              availableToAllocateOnly && row.facilityId
                                ? ''
                                : 'max-h-[85px]'
                            }`}
                            bottomLabel={getAvailableToAllocateLabel(
                              row.facilityId
                            )}
                            value={row?.amount || ''}
                            onValueChange={(value) =>
                              handleAmountChange(row, value, rowLoanFacilities)
                            }
                            errors={
                              errors?.data?.[row?.selectId]?.amount?.message
                            }
                          />
                          <Button
                            icon={<TrashIcon />}
                            layout="ghost"
                            className="h-[50px]"
                            onClick={() => onDeleteLoanFacility(row)}
                            aria-label="Remove facility"
                          />
                        </div>
                      </div>
                    ))}
                  </form>
                </div>
              )}

              {((availableToAllocateOnly &&
                !!availableFacilitiesOptions?.length &&
                rowLoanFacilities?.length < facilitiesOptions?.length) ||
                !availableToAllocateOnly) && (
                <div
                  className="text-secondary-400 cursor-pointer flex items-center heading-300"
                  onClick={() => {
                    onAddFacility();
                  }}
                >
                  {`+ Add ${
                    rowLoanFacilities?.length ? 'another' : ''
                  } facility`}
                </div>
              )}
            </div>
          </div>
          <div className="w-full mt-6 mb-4">
            <div className="flex items-start">
              <div className="flex-grow flex flex-col">
                <span className="body-200 text-neutral-700 mb-2">
                  Total facilities selected
                </span>
                <span className="text-sm/[11px]  mr-2">
                  {usedFacilities} of {facilitiesOptions?.length || 0}
                </span>
              </div>
              <div className="flex-grow flex flex-col">
                <span className="body-200 text-neutral-700 mb-2">
                  Allocated amount
                </span>
                <span className="text-sm/[11px]  mr-2">
                  {formatAmount(totalUsedAmount || 0)} of{' '}
                  {formatAmount(availableAmountRef.current || 0)}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      <Dialog
        open={modal === 'addFacility'}
        onClose={() => setModal(false)}
        dialogIcon={<UserAddIcon />}
        dialogIconTitle="Add a facility"
      >
        <div>
          <AddFacilityForm
            onClose={() => setModal(false)}
            setOnSuccess={(facilityId, name) => {
              handleAddFacility(facilityId, name, selectedId);
            }}
            branchEncodedKey={
              assignedBranchKey || loanDetails?.assignedBranchKey
            }
            productEncodedKey={productTypeKey || loanDetails?.productTypeKey}
            existingFacilitiesNames={productFacilities.map((f) => f.name)}
          />
        </div>
      </Dialog>
    </div>
  );
};
FacilitiesForm.displayName = 'FacilitiesForm';

export { FacilitiesForm };
