import React from 'react';
import { IconProps } from '../iconTypes';

export interface CircleIconProps extends IconProps {
  circleR?: number;
  circleCx?: number;
  circleCy?: number;
  circleFill?: string;
}

const CircleIcon: React.FC<CircleIconProps> = ({
  circleR,
  circleCx,
  circleCy,
  circleFill,
  ...rest
}) => (
  <svg height="10" width="8" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle
      r={circleR || '4'}
      cx={circleCx || '4'}
      cy={circleCy || '5'}
      fill={circleFill || ''}
    />
  </svg>
);

export default CircleIcon;
