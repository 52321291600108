import { CustomInformationChildProps } from '@/features/onboarding/custom-information/utils';
import { LeaseEstate } from './lease-state';

export const LeaseEstateEUR = (props: CustomInformationChildProps) => {
  return (
    <LeaseEstate
      onBack={props.onBack}
      loanId={props.loanId}
      initialValues={props.initialValues}
      refetch={props.refetch}
      handleContinue={props.handleContinue}
      isPatchingTaskStatus={props.isPatchingTaskStatus}
      isEdit={props.isEdit}
      isRestructure={props.isRestructure}
      saveCustomInformation={props.saveCustomInformation}
      currencyInput="EUR"
    />
  );
};
