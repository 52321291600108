import React from 'react';
import { IconProps } from '../iconTypes';

export interface ProgressBarThumbIconProps extends IconProps {
  isPercentage?: boolean;
  progress: number;
  firstCircleR?: number;
  secondCircleR?: number;
  circleCy?: number;
  firstCircleFill?: string;
  secondCircleFill?: string;
}

const ProgressBarThumbIcon: React.FC<ProgressBarThumbIconProps> = ({
  isPercentage,
  firstCircleR,
  secondCircleR,
  circleCy,
  progress,
  firstCircleFill,
  secondCircleFill,
  ...rest
}) => {
  const calcProgress = progress
    ? isPercentage
      ? (progress * 380) / 100
      : progress
    : 0;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle
        r={firstCircleR || 9}
        cx={calcProgress}
        cy={circleCy || 8}
        fill={firstCircleFill || '#ffffff'}
      />
      <circle
        r={secondCircleR || 6}
        cx={calcProgress}
        cy={circleCy || 8}
        fill={secondCircleFill || '#1E293B'}
      />
    </svg>
  );
};

export default ProgressBarThumbIcon;
