import { Content, ContentProps } from './Content';
import { Navigation, NavigationProps } from './Navigation';
import { Root, RootProps } from './Root';
import { Step, StepProps } from './Step';
import { Substep, SubstepProps } from './Substep';
import { useStepperControl } from './useStepperControl';

type ExtractRootSteps<TStep> = TStep extends `${infer T}.${string}` ? T : TStep;

type ExtractSubSteps<TStep> = TStep extends `${string}.${string}`
  ? TStep
  : never;

const Stepper = {
  Root,
  Navigation,
  Step,
  Substep,
  Content,
};

const defineStepper = <T extends string>() => {
  return Stepper as unknown as {
    Root: (props: RootProps) => JSX.Element;
    Navigation: (props: NavigationProps) => JSX.Element;
    Step: (props: StepProps<ExtractRootSteps<T>>) => JSX.Element;
    Substep: (props: SubstepProps<ExtractSubSteps<T>>) => JSX.Element;
    Content: (props: ContentProps<T>) => JSX.Element;
  };
};

export { defineStepper, useStepperControl };
