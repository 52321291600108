import React, { forwardRef, useEffect, useState } from 'react';
import { FormInput } from 'ui';
import { LOAN } from '@/constants/numeric';
import { LOAN_MESSAGES } from '@/constants/onboarding';
import { isNotEmpty } from '@/utils/validation';
import { FeeAmortizationSectionProps } from './FeeAmortizationSection.props';
import {
  calculateFeeYield,
  isMaxEffectiveInterestRateBasedOnGrossYield,
} from './FeeAmortizationSection.utils';

const FeeAmortizationSectionForm = forwardRef(
  (
    {
      isEpcFeeAmortizationEnabled,
      form,
      isFetching,
    }: FeeAmortizationSectionProps,
    _ref
  ) => {
    const [isFeeYieldVisible, setIsFeeYieldVisible] = useState(false);

    const {
      clearErrors,
      setValue,
      register,
      watch,
      trigger,
      formState: { errors },
    } = form;

    const effectiveInterestRate = watch(
      'feeAmortizationDetails.effectiveInterestRate'
    );
    const grossYield = watch('feeAmortizationDetails.grossYield');

    useEffect(() => {
      const calculatedFeeYield = calculateFeeYield(
        effectiveInterestRate,
        grossYield
      );

      if (isNotEmpty(calculatedFeeYield)) {
        setIsFeeYieldVisible(calculatedFeeYield > 0);
        setValue('feeAmortizationDetails.feeYield', calculatedFeeYield);
        trigger();
      } else {
        setIsFeeYieldVisible(false);
      }
    }, [effectiveInterestRate, grossYield, setValue, trigger]);

    useEffect(() => {
      if (!isEpcFeeAmortizationEnabled) {
        setValue('feeAmortizationDetails', {});
      }

      trigger();
    }, [isEpcFeeAmortizationEnabled, trigger]);

    if (!isEpcFeeAmortizationEnabled) return <></>;

    return (
      <>
        <div className="flex flex-col">
          <FormInput
            name="feeAmortizationDetails.effectiveInterestRate"
            label="Effective Interest Rate"
            leftSymbol="%"
            disabled={isFetching}
            type="number"
            className="mr-1"
            onValueChange={() =>
              clearErrors('feeAmortizationDetails.effectiveInterestRate')
            }
            errors={
              errors?.feeAmortizationDetails?.effectiveInterestRate?.message
            }
            {...register('feeAmortizationDetails.effectiveInterestRate', {
              valueAsNumber: true,
              required: LOAN_MESSAGES.REQUIRED_EFFECTIVE_INTEREST_RATE,
              min: {
                value: LOAN.MIN_EFFECTIVE_INTEREST_RATE,
                message: LOAN_MESSAGES.RANGE_PERCENT_VALUE(
                  LOAN.MIN_EFFECTIVE_INTEREST_RATE,
                  'less',
                  'Effective Interest rate'
                ),
              },
              validate: (val) => {
                //dynamic error message when gross yield is greater than effective interest rate
                const isMaxBasedOnGrossYield =
                  isMaxEffectiveInterestRateBasedOnGrossYield(
                    effectiveInterestRate,
                    grossYield
                  );
                const maxValue = isMaxBasedOnGrossYield
                  ? grossYield
                  : LOAN.MAX_EFFECTIVE_INTEREST_RATE;

                return maxValue < val
                  ? isMaxBasedOnGrossYield
                    ? LOAN_MESSAGES.EFFECTIVE_INTEREST_RATE_LESS_THAN_GROSS_YIELD
                    : LOAN_MESSAGES.RANGE_PERCENT_VALUE(
                        LOAN.MAX_GROSS_YIELD,
                        'greater',
                        'Effective Interest Rate'
                      )
                  : true;
              },
            })}
          />
          <FormInput
            name="feeAmortizationDetails.grossYield"
            label="Gross Yield (not compounded)"
            leftSymbol="%"
            disabled={isFetching}
            type="number"
            className="mr-1"
            onValueChange={() =>
              clearErrors('feeAmortizationDetails.grossYield')
            }
            errors={errors?.feeAmortizationDetails?.grossYield?.message}
            {...register('feeAmortizationDetails.grossYield', {
              valueAsNumber: true,
              required: LOAN_MESSAGES.REQUIRED_GROSS_YIELD,
              min: {
                value: LOAN.MIN_GROSS_YIELD,
                message: LOAN_MESSAGES.RANGE_PERCENT_VALUE(
                  LOAN.MIN_GROSS_YIELD,
                  'less',
                  'Gross Yield'
                ),
              },
              max: {
                value: LOAN.MAX_GROSS_YIELD,
                message: LOAN_MESSAGES.RANGE_PERCENT_VALUE(
                  LOAN.MAX_GROSS_YIELD,
                  'greater',
                  'Gross Yield'
                ),
              },
            })}
          />
          {isFeeYieldVisible && (
            <FormInput
              name="feeAmortizationDetails.feeYield"
              label="Fee Yield"
              leftSymbol="%"
              disabled={true}
              type="number"
              className="mr-1"
              {...register('feeAmortizationDetails.feeYield', {
                valueAsNumber: true,
              })}
            />
          )}
        </div>
      </>
    );
  }
);

FeeAmortizationSectionForm.displayName = 'FeeAmortizationSectionForm';
export { FeeAmortizationSectionForm };
