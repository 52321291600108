import React from 'react';
import { FormInput, Input } from 'ui';
import { LOAN_MESSAGES } from '@/constants/onboarding';
import { InterestGraceSectionProps } from './InterestGraceSection.props';
import InterestPeriodRetained from './interest-period-retained/InterestPeriodRetained';
import { InterestGraceHandleMethod } from '@/interfaces/loans';

const InterestGraceSection: React.FC<InterestGraceSectionProps> = ({
  errors,
  register,
  form,
  deductedInterestLabel,
  isInterestGraceHandleMethodVisible,
  isInterestGraceHandleMethodDefaultChecked,
  isInterestGraceHandleMethodSelected,
  isEpcInterestGraceEnabled,
}) => {
  if (isEpcInterestGraceEnabled) {
    return (
      <InterestPeriodRetained errors={errors} register={register} form={form} />
    );
  }

  return (
    <>
      <FormInput
        label="Interest grace period"
        questionTooltip="Number of grace instalments"
        errors={errors?.interestGraceInstallments?.message}
        placeholder="In instalments"
        type="number"
        {...register('interestGraceInstallments', {
          valueAsNumber: true,
          required: LOAN_MESSAGES.REQUIRED_INTEREST_GRACE_PERIOD,
          min: {
            value: 0,
            message:
              LOAN_MESSAGES.MIN_VALUE_INTEREST_GRACE_REPAYMENT_INSTALLMENTS(0),
          },
          validate: (val) => {
            const maxValue = form.watch('repaymentInstallments') ?? 0;
            return maxValue < val
              ? LOAN_MESSAGES.MAX_VALUE_INTEREST_GRACE_REPAYMENT_INSTALLMENTS
              : true;
          },
          onChange: () => form.clearErrors('repaymentInstallments'),
        })}
      />

      <p className="text-[12px] font-medium mt-[-10px]">
        What do you want to do with the sum of interest grace periods?
      </p>

      {isInterestGraceHandleMethodVisible && (
        <>
          <div>
            <Input
              {...form.register('interestGraceHandleMethod', {
                validate: (current) => typeof current === 'string',
              })}
              id="isDeduct"
              type="radio"
              value={InterestGraceHandleMethod.DEDUCT_ON_DISBURSEMENT}
            />
            <label
              className="pl-2 heading-100 text-neutral-900"
              htmlFor="isDeduct"
            >
              {deductedInterestLabel}
            </label>
          </div>
        </>
      )}

      <div>
        <Input
          {...form.register('interestGraceHandleMethod', {
            validate: (current) => typeof current === 'string',
          })}
          id="isDeductNo"
          defaultChecked={isInterestGraceHandleMethodDefaultChecked}
          type="radio"
          value={InterestGraceHandleMethod.DO_NOT_SERVICE}
        />
        <label
          className="pl-2 heading-100 text-neutral-900"
          htmlFor="isDeductNo"
        >
          Do not service interest grace instalments
        </label>
      </div>
      {!isInterestGraceHandleMethodSelected && form.formState.isSubmitted && (
        <p className="text-error-700 body-200">
          Select option for the sum of interest grace period
        </p>
      )}
    </>
  );
};

export default InterestGraceSection;
