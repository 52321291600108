import { productHasTranches } from '@/utils/loans';
import { AdditionalDetailsFormType } from './loan-details/AdditionalDetailsForm';
import { LoanInformationFormType } from './loan-details/LoanInformationForm';
import { ScheduleFormType } from './loan-details/ScheduleForm';
import { GetConfigurationProductResponse } from 'kennek/interfaces/kennek/queries';
import type { CreateLoan, Loan, LoanProductRules } from '@/interfaces/loans';
import { LoanFacility, RowLoanFacility } from '@/interfaces/loans/facility';

type Fees = Record<
  string,
  {
    predefinedEncodedKey: string;
    amount: number;
  }
>;

export const mapLoanDataToPayload = ({
  borrowerCompanyExternalId,
  originatorExternalId,
  loan,
  loanInformationForm,
  scheduleForm,
  additionalDetailsForm,
  loanProductRules,
  loanFacilities,
  executionId,
  configurationProductData,
}: {
  borrowerCompanyExternalId: string;
  originatorExternalId: string;
  loan: Loan;
  loanInformationForm: LoanInformationFormType;
  scheduleForm: ScheduleFormType;
  additionalDetailsForm?: AdditionalDetailsFormType;
  loanProductRules: LoanProductRules;
  loanFacilities?: RowLoanFacility[] | LoanFacility[];
  executionId?: string;
  configurationProductData: GetConfigurationProductResponse;
}) => {
  const hasTranches = productHasTranches(
    configurationProductData,
    loanProductRules
  );

  const payload: Omit<CreateLoan, 'paymentPlan'> = {
    accountHolderKey: borrowerCompanyExternalId,
    assignedBranchKey: originatorExternalId,
    assignedCentreKey: loan?.assignedCentreKey,
    externalId: loanInformationForm?.externalId,
    firstRepaymentDate: scheduleForm?.firstRepaymentDate,
    gracePeriod: additionalDetailsForm?.gracePeriod || null,
    graceAmount:
      additionalDetailsForm?.graceAmount || loan?.graceAmount || null,
    interestRate: loanInformationForm?.interestRate || 0,
    defaultInterestRate: loanInformationForm?.defaultInterestRate,
    interestCapitalisationFrequency:
      loanInformationForm?.interestCapitalisationFrequency,
    loanName: loanInformationForm?.loanName,
    loanAmount: loanInformationForm?.loanAmount,
    paymentHoliday: scheduleForm?.paymentHoliday,
    rollUpDuration: scheduleForm?.rollUpDuration,
    productTypeKey: loanInformationForm?.productTypeKey,
    repaymentInstallments: scheduleForm.repaymentInstallments,
    payCurrentInstallment: scheduleForm.payCurrentInstallment,
    minimumInterestPeriod: scheduleForm.minimumInterestPeriod,
    interestGraceInstallments: scheduleForm?.interestGraceInstallments,
    interestGraceHandleMethod: scheduleForm?.interestGraceHandleMethod,
    repaymentPeriodCount: scheduleForm.repaymentPeriodCount,
    repaymentPeriodUnits: scheduleForm.repaymentPeriodUnits,
    netLoanCalculationMethod: loanInformationForm?.netLoanCalculationMethod,
    ltv: loanInformationForm?.ltv,
    gdv: loanInformationForm?.gdv,
    ltc: loanInformationForm?.ltc,
    gdc: loanInformationForm?.gdc,
    exitFeeAmount: loanInformationForm?.exitFeeAmount
      ? +loanInformationForm?.exitFeeAmount
      : null,
    periodicPayment: loanProductRules?.balloonPaymentEnabled
      ? parseFloat(loanInformationForm.periodicPayment)
      : undefined,
    balloonPaymentAmount:
      loanProductRules?.balloonPaymentEnabled &&
      !!loanInformationForm.balloonPaymentAmount
        ? parseFloat(loanInformationForm.balloonPaymentAmount)
        : undefined,
    penaltyRate: loanProductRules?.variableRequiredFields?.some(
      (field) => field.name === 'penaltyRate'
    )
      ? additionalDetailsForm?.penaltyRate
      : undefined,
    monthlyRepaymentDay: loanProductRules?.variableRequiredFields?.some(
      (field) => field.name === 'monthlyRepaymentDay'
    )
      ? scheduleForm.monthlyRepaymentDay
      : undefined,
    fees: mapFeesToDto({
      fees: loanInformationForm?.fees,
      loanProductRules,
    }),
    disbursementDate: hasTranches ? undefined : scheduleForm?.disbursementDate,
    loanFacilities: loanFacilities?.length
      ? loanFacilities.map((f) => ({
          facilityId: f.facilityId,
          amount: parseFloat(f.amount),
        }))
      : [],
    tranches: hasTranches
      ? scheduleForm?.tranches?.map((tranche) => ({
          facilities: Object.values(tranche?.facilities).map(
            (f: Partial<RowLoanFacility>) => {
              return {
                facilityId: f.facilityId,
                amount: parseFloat(f.amount),
                name: f.name,
              } as Partial<LoanFacility>;
            }
          ),
          amount: tranche.disbursementAmount
            ? tranche.disbursementAmount
            : undefined,
          disbursementDate: tranche.disbursementDate,
          fees: Object.entries(tranche?.fees ?? {}).map(
            ([predefinedFeeEncodedKey, content]) => ({
              predefinedFeeEncodedKey,
              amount: content.amount,
            })
          ),
          counterpartyId: tranche.counterpartyId,
        }))
      : undefined,
    factorAmount: loanInformationForm?.factorAmount
      ? parseFloat(loanInformationForm?.factorAmount as unknown as string)
      : 0,
    feeSettings: {
      feeAmortizationDetails: loanInformationForm.feeAmortizationDetails,
    },
    counterpartyId: scheduleForm.counterpartyId,
    interestCeiling: loanInformationForm?.interestCeiling,
    interestFloor: loanInformationForm?.interestFloor,
    useInitialInterestRateAsCeiling:
      loanInformationForm?.useInitialInterestRateAsCeiling,
    useInitialInterestRateAsFloor:
      loanInformationForm?.useInitialInterestRateAsFloor,
    executionId,
  };
  return payload as CreateLoan;
};

export const mapFeesToDto = ({
  fees,
  loanProductRules,
}: {
  fees: Fees;
  loanProductRules: LoanProductRules;
}) => {
  const feeById = new Map(loanProductRules?.fees?.map((fee) => [fee.id, fee]));

  return Object.entries(fees ?? {}).map(
    ([predefinedFeeEncodedKey, content]) => ({
      predefinedFeeEncodedKey,
      amount: (() => {
        const fee = feeById.get(predefinedFeeEncodedKey);
        return (fee?.calculationMethod === 'FLAT' && fee?.amount > 0) ||
          fee?.calculationMethod === 'PERCENTAGE'
          ? undefined
          : content.amount;
      })(),
    })
  );
};
