import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { ChevronUpIcon } from '@heroicons/react/outline';

export type FormInputSymbolOption = {
  label: string;
  value: string;
  additionalSearchValue?: string;
};

type SelectProps = {
  options: FormInputSymbolOption[];
  selectedValue: string | undefined;
  setSelectedValue: (val: string) => void;
};

const SymbolSelect: React.FC<SelectProps> = ({
  options,
  selectedValue,
  setSelectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen((isOpen) => !isOpen);

  const handleOptionClick = (option: FormInputSymbolOption) => {
    setSelectedValue(option.value);
    setIsOpen(false);
    setSearchValue('');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
      setSearchValue('');
    }
  };

  const currentSelectedOption = useMemo(() => {
    return options.find((x) => x.value === selectedValue);
  }, [selectedValue, options]);

  const filteredOptions = useMemo(() => {
    if (!searchValue) return options;
    const searchVal = searchValue.toLowerCase();
    return options.filter((x) => {
      const isLabelInclude = x.label.toLowerCase().includes(searchVal);
      const isValueInclude = x.value.toLowerCase().includes(searchVal);
      const isAdditionalSearchValueInclude = x.additionalSearchValue
        ? x.additionalSearchValue.toLowerCase().includes(searchVal)
        : false;
      return isLabelInclude || isValueInclude || isAdditionalSearchValueInclude;
    });
  }, [searchValue, options]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={ref}
      className="relative h-[24px] w-[65px] bg-neutral-200 rounded-lg text-neutral-900"
    >
      <div
        onClick={toggleDropdown}
        className="p-1  cursor-pointer select-none border-none relative flex items-center justify-between heading-100 text-neutral-900"
      >
        <div
          className={classNames(
            'h-[14px] w-[14px] text-xs transition-transform',
            {
              'rotate-180': isOpen,
            }
          )}
        >
          <ChevronUpIcon />
        </div>
        <span className="mt-[1px]">{selectedValue}</span>
      </div>

      {isOpen && (
        <div className="absolute min-w-[110px] top-[35px] right-[-35px] z-10 bg-white heading-100 rounded-lg border border-neutral-400 pd-1">
          <input
            onChange={(x) => setSearchValue(x.target.value)}
            className="absolute p-1 top-[0px] m-1 w-[100px] rounded-inputs border border-neutral-400 focus:outline-none dark:text-neutral-300 focus:ring-0 focus:border-neutral-400 focus:outline-primary-400"
            placeholder="Search"
          />
          <div className="relative overflow-y-auto max-h-[220px] mt-[30px]">
            {filteredOptions
              .filter((x) => x.value !== currentSelectedOption?.value)
              .map((option, index) => (
                <div
                  key={index}
                  onClick={() => handleOptionClick(option)}
                  className={`cursor-pointer p-2 hover:bg-primary-100`}
                >
                  {option.label} {option.value}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SymbolSelect;
