import React, { useMemo } from 'react';
import { Select } from 'ui';
import { currencySymbol } from 'ui/components/FormInput';
import { InvestmentAmountType } from '@/interfaces/loans';

interface InvestmentAmountTypeFormProps {
  initialInvestmentAmuntType: InvestmentAmountType;
  currency: string;
  onInitialInvestmentAmountTypeChange: (value: InvestmentAmountType) => void;
}

const InvestmentAmountTypeForm: React.FC<InvestmentAmountTypeFormProps> = ({
  initialInvestmentAmuntType = InvestmentAmountType.AMOUNT,
  currency,
  onInitialInvestmentAmountTypeChange,
}) => {
  const options = useMemo(
    () => [
      {
        value: InvestmentAmountType.AMOUNT,
        label: `Amount (${currencySymbol[currency]})`,
      },
      { value: InvestmentAmountType.PERCENTAGE, label: 'Percentage (%)' },
    ],
    [currency]
  );

  return (
    <div className="w-full">
      <div className="flex items-top">
        <div className="flex-col w-[260px] mt-[10px]">
          <p className="heading-200 ml-4 mr-5">Amount or percentage</p>
        </div>
        <Select
          value={initialInvestmentAmuntType}
          onChange={(event) =>
            onInitialInvestmentAmountTypeChange(event.target.value)
          }
          options={options}
          className="w-72"
        />
      </div>
    </div>
  );
};

export default InvestmentAmountTypeForm;
