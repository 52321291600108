import { INVESTOR_MESSAGES } from '@/constants/onboarding';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useDeleteInvestmentMutation } from '@/services/loans/investment';

export const useDeleteInvestment = () => {
  const snackbar = useSnackbar();
  const labelsConfig = useGetLabelsConfig();

  const [deleteInvestment, { isLoading: isDeleting }] =
    useDeleteInvestmentMutation();

  const handleDeleteInvestment = async (investmentId: string) =>
    new Promise<void>((resolve, reject) =>
      deleteInvestment(investmentId)
        .unwrap()
        .then(() => {
          snackbar.show({
            severity: 'primary',
            title: INVESTOR_MESSAGES.INVESTMENT_DELETED_SUCCESS,
            content: INVESTOR_MESSAGES.INVESTMENT_DELETED_CONTENT(labelsConfig),
          });
          resolve();
        })
        .catch((error) => {
          snackbar.show({
            severity: 'error',
            title: INVESTOR_MESSAGES.INVESTMENT_DELETED_FAILED,
          });
          reject(error);
        })
    );

  return {
    deleteInvestment: handleDeleteInvestment,
    isDeleting,
  };
};
