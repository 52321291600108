import React, { ReactNode, useContext } from 'react';
import classNames from 'classnames';
import { LoadingAnimation } from 'kennek/components';
import { ThemeContext } from 'ui/theme';
import warn from 'ui/utils/warning';

export interface Props {
  children?: React.ReactNode;
  /**
   * Defines if the button is disabled
   */
  disabled?: boolean;
  /**
   * The size of the button
   */
  size?:
    | 'larger'
    | 'large'
    | 'regular'
    | 'small'
    | 'pagination'
    | 'limitedPaddingX';
  /**
   * Shows only one icon inside the button; defaults to left
   */
  icon?: JSX.Element;
  /**
   * Shows an icon inside the button, left aligned
   */
  iconLeft?: JSX.Element;
  /**
   * Shows an icon inside the button, right aligned
   */
  iconRight?: JSX.Element;
  /**
   * Custom styles of the button
   */
  iconStyles?: string;
  /**
   * The style of the button
   */
  layout?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'ghost'
    | 'link'
    | 'destructive'
    | '__dropdownItem'
    | 'dark'
    | 'transparent';
  /**
   * Shows the button as a block (full width)
   */
  block?: boolean;

  /**
   * Visually indicate that the button is waiting for an operation to
   * complete. Setting this to `true` will disable the button, so you don't
   * need to set `disabled={true}` as well.
   */
  loading?: boolean;
}

export interface ButtonAsButtonProps
  extends Props,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The element that should be rendered as a button
   */
  tag?: 'button';
  /**
   * The native HTML button type
   */
  type?: 'button' | 'submit' | 'reset';
}

export interface ButtonAsAnchorProps
  extends Props,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  tag: 'a';
}

export interface ButtonAsOtherProps
  extends Props,
    React.AnchorHTMLAttributes<HTMLAnchorElement> {
  tag: string;
}

export type ButtonProps =
  | ButtonAsButtonProps
  | ButtonAsAnchorProps
  | ButtonAsOtherProps;

type Ref = ReactNode | HTMLElement | string;

const Button = React.forwardRef<Ref, ButtonProps>(function Button(props, ref) {
  const {
    tag = 'button',
    // Fix https://github.com/estevanmaito/windmill-react-ui/issues/7
    type = tag === 'button' ? 'button' : undefined,
    disabled = false,
    size = 'regular',
    layout = 'primary',
    block = false,
    icon,
    iconLeft,
    iconRight,
    iconStyles,
    className,
    children,
    loading,
    ...other
  } = props;
  const {
    theme: { button },
  } = useContext(ThemeContext);

  function hasIcon() {
    return !!icon || !!iconLeft || !!iconRight;
  }

  warn(
    hasIcon() && !other['aria-label'] && !children,
    'Button',
    'You are using an icon button, but no "aria-label" attribute was found. Add an "aria-label" attribute to work as a label for screen readers.'
  );

  const IconLeft = iconLeft || icon;
  const IconRight = iconRight;

  const baseStyle = button.base;
  const blockStyle = button.block;
  const sizeStyles = {
    larger: button.size.larger,
    large: button.size.large,
    regular: button.size.regular,
    small: button.size.small,
    /**
     * Only used in Pagination.
     * Not meant for general use.
     */
    pagination: button.size.pagination,
    limitedPaddingX: button.size.limitedPaddingX,
  };
  const iconSizeStyles = {
    larger: button.size.icon.larger,
    large: button.size.icon.large,
    regular: button.size.icon.regular,
    small: button.size.icon.small,
    pagination: button.size.icon.regular,
    limitedPaddingX: button.size.icon.limitedPaddingX,
  };
  const iconStyle = button.icon[size];
  const layoutStyles = {
    primary: button.primary.base,
    secondary: button.secondary.base,
    outline: button.outline.base,
    ghost: button.ghost.base,
    link: button.link.base,
    destructive: button.destructive.base,
    dark: button.dark.base,
    transparent: button.transparent.base,
  };
  const activeStyles = {
    primary: button.primary.active,
    secondary: button.secondary.active,
    outline: button.outline.active,
    ghost: button.ghost.active,
    link: button.link.active,
    destructive: button.destructive.active,
    dark: button.dark.active,
    transparent: button.transparent.active,
  };
  const disabledStyles = {
    primary: button.primary.disabled,
    secondary: button.secondary.disabled,
    outline: button.outline.disabled,
    ghost: button.ghost.disabled,
    link: button.link.disabled,
    destructive: button.destructive.disabled,
    dark: button.dark.disabled,
    transparent: button.transparent.disabled,
  };

  /**
   * Only used in DropdownItem.
   * Not meant for general use.
   */
  const dropdownItemStyle = button.dropdownItem.base;

  const buttonStyles =
    layout === '__dropdownItem'
      ? classNames(dropdownItemStyle, className)
      : classNames(
          baseStyle,
          // has icon but no children
          hasIcon() && !children && iconSizeStyles[size],
          // has icon and children
          hasIcon() && children && sizeStyles[size],
          // does not have icon
          !hasIcon() && sizeStyles[size],
          layoutStyles[layout],
          disabled ? disabledStyles[layout] : activeStyles[layout],
          block ? blockStyle : null,
          className
        );

  const iconLeftStyles = classNames(
    iconStyle,
    children ? button.icon.left : ''
  );
  const iconRightStyles = classNames(
    iconStyle,
    children ? button.icon.right : ''
  );

  return React.createElement(
    tag,
    {
      role: 'button',
      className: buttonStyles,
      ref,
      disabled: disabled || loading,
      type,
      ...other,
    },
    IconLeft && !loading
      ? React.cloneElement(IconLeft, {
          className: classNames(iconLeftStyles, iconStyles),
          'aria-hidden': true,
        })
      : null,
    children,
    loading ? (
      <div className="pl-2">
        <LoadingAnimation size="xs" color="secondary" fitBox />
      </div>
    ) : null,
    IconRight && !loading
      ? React.cloneElement(IconRight, {
          className: classNames(iconRightStyles, iconStyles),
          'aria-hidden': true,
        })
      : null
  );
});

export default Button;
