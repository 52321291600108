import { InaccessibleResource } from 'ui';

interface Props {
  loadedModules: JSX.Element[];
  isAccessible: boolean;
}

const UaasLoadedModulesContainer = ({ loadedModules, isAccessible }: Props) => {
  return isAccessible ? <>{loadedModules}</> : <InaccessibleResource />;
};

export default UaasLoadedModulesContainer;
