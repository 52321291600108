import React from 'react';
import { FormInput } from 'ui';
import { LOAN_MESSAGES } from '@/constants/onboarding';
import { InterestPeriodRetainedProps } from './InterestPeriodRetained.props';

const InterestPeriodRetained: React.FC<InterestPeriodRetainedProps> = ({
  errors,
  register,
  form,
}) => {
  return (
    <>
      <FormInput
        label="Interest periods retained"
        questionTooltip="Number of grace instalments"
        errors={errors?.interestGraceInstallments?.message}
        placeholder="In instalments"
        type="number"
        {...register('interestGraceInstallments', {
          valueAsNumber: true,
          required: LOAN_MESSAGES.REQUIRED_INTEREST_GRACE_PERIOD,
          min: {
            value: 0,
            message:
              LOAN_MESSAGES.MIN_VALUE_INTEREST_GRACE_REPAYMENT_INSTALLMENTS(0),
          },
          validate: (val) => {
            const maxValue = form.watch('repaymentInstallments') ?? 0;
            return maxValue < val
              ? LOAN_MESSAGES.MAX_VALUE_INTEREST_GRACE_RETAINED_REPAYMENT_INSTALLMENTS
              : true;
          },
          onChange: () => form.clearErrors('repaymentInstallments'),
        })}
      />
    </>
  );
};

export default InterestPeriodRetained;
