import React from 'react';
import type { TableVariantType } from './TableContainer';
import { PaginationProps } from './TablePagination';

export type TableContextType = {
  pagination: Required<PaginationProps> | undefined;
  registerPagination: (pagination: Required<PaginationProps>) => void;
  isLoading: boolean;
  hasPagination: boolean;
  setHasPagination: () => void;
  initialPageSize: number | undefined;
  setInitialPageSize: (value: number) => void;
  hasSubheader: boolean;
  setHasSubheader: () => void;
  variant: TableVariantType;
  withoutHeaderOffset: boolean;
  serverSideSort?: boolean;
};

export const TableContext = React.createContext<TableContextType | null>(null);
