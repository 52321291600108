import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { enableMapSet } from 'immer';
import * as Tooltip from '@radix-ui/react-tooltip';
import { AuthGuard } from '@/components/auth/AuthGuard';
import ErrorBoundary from '@/components/common/ErrorBoundary';
import ErrorHandler from '@/components/common/ErrorHandler';
import ThemeProvider from '@/components/providers/ThemeProvider';
import { env } from '@/env/public';
import DefaultPageLayout from '@/layouts/default';
import { store } from '@/store';
import type { PageWithAuthType, PageWithLayoutType } from '@/types/pages';
import '@/styles/globals.css';

enableMapSet();

type AppLayoutProps = AppProps & {
  Component: PageWithLayoutType & PageWithAuthType;
  pageProps: any;
};

function MyApp({ Component, pageProps }: AppLayoutProps) {
  const PageLayout = Component.layout || DefaultPageLayout;
  const PageSecure = Component.requireAuth || false;

  return (
    <ErrorBoundary outsideLayout>
      <SessionProvider
        session={pageProps.session}
        refetchInterval={env.NEXT_PUBLIC_REFETCH_SESSION_INTERVAL * 60}
      >
        <Tooltip.Provider>
          <Provider store={store}>
            <ThemeProvider>
              {/* If requireAuth property is present - protect the page */}
              {PageSecure ? (
                <AuthGuard
                  roles={Component.roles}
                  pageDisabledBy={Component.disabledBy}
                >
                  <PageLayout>
                    <ErrorBoundary>
                      <ErrorHandler>
                        <IntercomProvider
                          appId={env.NEXT_PUBLIC_INTERCOM_ID}
                          autoBoot
                          shouldInitialize={
                            !env.NEXT_PUBLIC_HIDE_INTERCOM_CHATBOT
                          }
                          onShow={() => true}
                        >
                          <Component {...pageProps} />
                        </IntercomProvider>
                      </ErrorHandler>
                    </ErrorBoundary>
                  </PageLayout>
                </AuthGuard>
              ) : (
                // public Page
                <PageLayout>
                  <ErrorBoundary outsideLayout>
                    <ErrorHandler>
                      <Component {...pageProps} />
                    </ErrorHandler>
                  </ErrorBoundary>
                </PageLayout>
              )}
            </ThemeProvider>
          </Provider>
        </Tooltip.Provider>
      </SessionProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
