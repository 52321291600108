import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, ModalV2 as Modal } from 'ui';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { getTrancheDate } from '@/features/loan-details';
import { useRole } from '@/hooks/useRole';
import { formatAmount, formatDate } from '@/utils/formatters';
import { UserTypes } from 'kennek/interfaces/accounts';
import { Currencies } from 'kennek/interfaces/loans';
import { CapitalizedFee, Tranche } from '@/interfaces/loans';

type TrancheRowProps = {
  tranche: Tranche;
  firstPending?: number;
  index?: number;
  isOnboardingPreview?: boolean;
  isBorrowerView?: boolean;
  paidOff?: boolean;
  currency?: Currencies;
  state?: string;
  setTrancheToDisburse?: (value: Tranche) => void;
};
const TrancheRow = ({
  tranche,
  firstPending,
  index,
  isOnboardingPreview = false,
  isBorrowerView = false,
  paidOff = false,
  state,
  currency,
  setTrancheToDisburse,
}: TrancheRowProps) => {
  const totalFees = tranche?.fees
    ?.filter(
      (fee) =>
        fee.trigger === 'CAPITALIZED_DISBURSEMENT' ||
        fee.trigger === 'UPFRONT_DISBURSEMENT'
    )
    ?.map((fee) =>
      fee.calculationMethod === 'LOAN_AMOUNT_PERCENTAGE'
        ? (tranche?.amount * fee?.amount) / 100
        : fee?.amount || 0
    )
    .reduce((total, fee) => total + fee, 0);

  const trancheTime = getTrancheDate(tranche);

  const { role } = useRole();

  const permissions = ROLES_PERMISSIONS[role];

  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <div className="flex flex-col pb-7">
      <div className="flex pb-4 pt-3 gap-x-3">
        <div
          className={classNames('flex flex-col w-36', {
            'text-neutral-600': tranche?.disbursed,
          })}
        >
          <span className="heading-400 pb-1 whitespace-nowrap">
            {formatAmount(tranche?.amount + totalFees, true, currency)}
          </span>
          <span className="body-100">Disbursement amount</span>
        </div>
        <div
          className={classNames('flex flex-col pl-5', {
            'text-neutral-600': tranche?.disbursed,
          })}
        >
          <span className="heading-200 pb-1">
            {formatDate(tranche?.disbursementDate)}
          </span>
          <span className="body-100">Date</span>
        </div>
        <div className="flex flex-col pl-5">
          <span
            className={classNames('heading-200 pb-1', {
              'text-success-500': tranche?.disbursed,
              'text-neutral-600': !tranche?.disbursed,
              'text-error-700': trancheTime === 'past',
            })}
          >
            {tranche?.disbursed ? 'Disbursed' : 'Pending'}
          </span>
          <span className="body-100">Status</span>
        </div>
        {permissions?.includes('EDIT') &&
          firstPending === index &&
          !isOnboardingPreview &&
          trancheTime !== 'future' &&
          !paidOff &&
          !isBorrowerView &&
          state !== 'RESTRUCTURED' && (
            <div className="flex flex-col pl-8">
              <Modal.Trigger asChild>
                <Button
                  layout="primary"
                  onClick={() => {
                    if (setTrancheToDisburse) setTrancheToDisburse(tranche);
                  }}
                >
                  Disburse
                </Button>
              </Modal.Trigger>
            </div>
          )}
        {tranche?.disbursed && (
          <span
            className={classNames(
              'inline-block relative px-6 mb-3 cursor-pointer transition-transform',
              { '-rotate-180': showDetails }
            )}
          >
            <ChevronDownIcon
              width={18}
              onClick={() => setShowDetails(!showDetails)}
              className="-bottom-1.5 inline"
            />
          </span>
        )}
      </div>

      {showDetails && (
        <div className="flex rounded-md p-2 bg-neutral-200 w-[420px]">
          <div className="flex flex-col w-full">
            <div className="flex justify-between items-center py-2 pl-2">
              <span className="body-200 mr-2">Loan amount</span>
              <span className="body-200 mr-2 capitalize">
                {formatAmount(tranche?.amount, true, currency)}
              </span>
            </div>
            {tranche?.fees?.map((fee) => {
              return (
                <div
                  className="flex justify-between items-center py-2 pl-2"
                  key={fee?.predefinedFeeEncodedKey}
                >
                  <span className="body-200 mr-2">{fee?.name}</span>
                  <span className="body-200 mr-2 capitalize">
                    {formatAmount(
                      getAmount(fee, tranche.amount),
                      true,
                      currency
                    )}
                  </span>
                </div>
              );
            })}
            {tranche?.counterparty && (
              <div className="flex justify-between items-center py-2 pl-2">
                <span className="body-200 mr-2">Counterparty account</span>
                <span className="body-200 mr-2 capitalize">
                  {tranche?.counterparty?.bankAccountHolderName} (
                  {tranche?.counterparty?.bankAccountNumber})
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const getAmount = (
  capitalizedFee: CapitalizedFee,
  totalAmount: number
) => {
  switch (capitalizedFee.calculationMethod) {
    case 'FLAT':
      return capitalizedFee.amount;
    case 'LOAN_AMOUNT_PERCENTAGE':
      return (capitalizedFee?.amount * totalAmount) / 100;
  }
};

export { TrancheRow };

type Permissions = 'DISBURSE' | 'EDIT';

const ROLES_PERMISSIONS: Partial<Record<UserTypes, Permissions[]>> = {
  ORIGINATOR_ADMIN: ['DISBURSE', 'EDIT'],
  ORIGINATOR_ANALYST: ['DISBURSE', 'EDIT'],
};
