import { LoanFacility, RowLoanFacility } from '@/interfaces/loans/facility';

export const isLoanFacility = (
  facility: LoanFacility | RowLoanFacility
): facility is LoanFacility => {
  return (facility as LoanFacility).payments !== undefined;
};

export const prettifyImportedScheduleValidationErrors = (
  validationError: string
): string[] => {
  if (
    !validationError.startsWith(
      'An instance of ImportRepaymentScheduleExcelModel has failed the validation'
    )
  ) {
    return [validationError];
  } else {
    const validationErrorArr = validationError
      .split('\n')
      .filter((error) => error.trim() !== '')
      .slice(1);

    const prettifiedErrors = [];

    for (const error of validationErrorArr) {
      console.error(error);
      const match = error.match(
        /property rows\[(\d+)\]\.(\w+) has failed the following constraints: (.+)/
      );
      if (match[1] && match[2] && match[3]) {
        const rowIndex = Number(match[1]) + 1;
        const variableName =
          match[2].charAt(0).toUpperCase() + match[2].slice(1);
        const constraints = match[3]
          .split(', ')
          .map((e) => e.slice(2).toLowerCase())
          .join('and must be ');

        prettifiedErrors.push(
          `The value in row ${rowIndex} of the '${variableName}' column must be a ${constraints}`
        );
      } else {
        return [
          'The imported template has failed validation. \n Ensure all the fields are populated, monetary amounts are valid numbers, dates are valid and instalment numbers are populated.',
        ];
      }
    }
    return prettifiedErrors;
  }
};
