import { useMemo } from 'react';
import { format } from 'date-fns';
import { Button, Table, Title } from 'ui';
import { CalendarIcon } from '@heroicons/react/outline';
import { DownloadIcon } from '@heroicons/react/solid';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { RepaymentScheduleHeaders } from '@/components/sections/repayment-schedule/RepaymentScheduleHeaders';
import {
  COLUMNS_CHECK,
  ColumnName,
  DEFAULT_CONFIGURATION,
  PENDING_LOAN_DEACTIVATED_COLUMNS_CHECK,
} from '@/components/sections/repayment-schedule/repaymentScheduleTable';
import {
  getColumnsView,
  setColumnsOrder,
} from '@/components/sections/repayment-schedule/utils';
import { TranchesSection } from '@/components/sections/tranches';
import { SNACKBAR_REPAYMENT_SCHEDULE } from '@/constants/snackbar-messages';
import { useDownloadFile } from '@/hooks/useDownloadFile';
import { useLoanDisabledFeatures } from '@/hooks/useLoanDisabledFeatures';
import {
  useGetConfigurationProductQuery,
  useGetViewColumnsConfigurationQuery,
} from '@/services/kennek';
import {
  useGetLoanQuery,
  useGetLoanSummaryQuery,
  useGetLoanTrancheSummaryQuery,
  useGetLoanTranchesQuery,
  useGetRepaymentScheduleQuery,
} from '@/services/loans';
import { useSelectUser } from '@/store/user/selectors';
import { OnboardingSteps } from '../Onboarding';
import {
  TableColumnsConfiguration,
  TableHeadersConfiguration,
  ViewNames,
} from 'kennek/interfaces/kennek';
import { HeaderName } from 'kennek/interfaces/loans/repaymentSchedule.utils';
import { Column } from 'ui/types';

type PreviewProps = {
  onContinue: () => void;
  onBack: () => void;
  loanId: string;
  isReview: boolean;
  setActiveStep: (step: OnboardingSteps) => void;
  companyName: string;
};

const Preview = ({
  onBack,
  onContinue,
  loanId,
  setActiveStep,
  companyName,
}: PreviewProps) => {
  const user = useSelectUser();
  const { data: loan, ...loanQuery } = useGetLoanQuery(loanId, {
    skip: !loanId,
  });

  const { onDownloadDocument } = useDownloadFile();

  const { isDownloadRepaymentScheduleEnabled } =
    useLoanDisabledFeatures(loanId);

  const repaymentScheduleQuery = useGetRepaymentScheduleQuery(
    { loanId: loan?.encodedKey },
    { skip: !loan?.encodedKey }
  );

  const installments = repaymentScheduleQuery?.data?.installments;

  const columnsQuery = useGetViewColumnsConfigurationQuery(
    {
      userId: user?._id,
      viewName: ViewNames.repaymentSchedule,
      productEncodedKey: loan?.productTypeKey,
      branchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
      loanEncodedKey: loan?.encodedKey,
    },
    {
      skip:
        !user?._id ||
        !loan?.productTypeKey ||
        !user?.mambuUser?.[0]?.mambuBranchEncodedKey,
    }
  );

  const { data: configurationProductData, ...configurationProductQuery } =
    useGetConfigurationProductQuery(
      {
        branchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
        productEncodedKey: loan?.productTypeKey,
      },
      {
        skip:
          !user?.mambuUser?.[0]?.mambuBranchEncodedKey || !loan?.productTypeKey,
      }
    );

  const productType = useMemo(() => {
    return configurationProductData?.payment?.blockPaymentTillLoanMatured
      ? 'blockedUntilMatured'
      : 'default';
  }, [configurationProductData?.payment?.blockPaymentTillLoanMatured]);

  const colsConfig = columnsQuery?.data
    ?.columns as TableColumnsConfiguration<ColumnName>[];

  const headersConfig = columnsQuery?.data
    ?.headers as TableHeadersConfiguration<HeaderName>[];

  const columnsList =
    setColumnsOrder(
      colsConfig
        ?.filter(
          (item) =>
            item.name !== 'investorInterest' &&
            item.name !== 'originatorInterest'
        )
        ?.filter((item) => COLUMNS_CHECK.includes(item.name))
        ?.filter(
          (item) => !PENDING_LOAN_DEACTIVATED_COLUMNS_CHECK.includes(item.name)
        )
    ) ?? DEFAULT_CONFIGURATION;

  const selectedColumns = getColumnsView(
    columnsList.filter((item) => COLUMNS_CHECK.includes(item.name)),
    productType,
    loan?.currency
  );

  const loanSummaryQuery = useGetLoanSummaryQuery(
    { loanId: loan?.encodedKey },
    { skip: !loan?.encodedKey }
  );

  const hasTranches = loan?.tranches?.length > 0;

  const tranchesSummaryQuery = useGetLoanTrancheSummaryQuery(loanId, {
    skip: !loanId || !hasTranches,
  });

  const { data: tranchesList, ...tranchesQuery } = useGetLoanTranchesQuery(
    loanId,
    {
      skip: !loanId || !hasTranches,
    }
  );

  const editButton = (
    <Button
      layout="link"
      onClick={() => setActiveStep('LOAN_DETAILS.SCHEDULE')}
    >
      Edit
    </Button>
  );

  const isLoading =
    loanQuery.isFetching ||
    (tranchesSummaryQuery.isFetching && !tranchesSummaryQuery.currentData) ||
    tranchesQuery.isLoading ||
    repaymentScheduleQuery.isUninitialized ||
    repaymentScheduleQuery.isFetching ||
    columnsQuery.isUninitialized ||
    columnsQuery.isFetching ||
    (configurationProductQuery.isFetching &&
      !configurationProductQuery.currentData);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <LoadingAnimationPlat fitBox />
      </div>
    );
  }

  return (
    <div>
      <Title
        title="Preview"
        icon={<CalendarIcon />}
        titleSize="lg"
        className="mt-2 mb-5"
      />
      {tranchesList ? (
        <TranchesSection
          tranchesList={tranchesList}
          tranchesSummary={tranchesSummaryQuery.currentData}
          isFetching={loanSummaryQuery.isFetching || tranchesQuery.isFetching}
          editButton={editButton}
          variant="onboarding"
        ></TranchesSection>
      ) : (
        !isLoading && (
          <Table.Container variant="compact" isLoading={isLoading}>
            <Table.Header>
              <Table.Header.Left>
                <Table.Name title="Repayment Schedule" />
              </Table.Header.Left>
              {isDownloadRepaymentScheduleEnabled && (
                <Table.Header.Right>
                  <div
                    className="text-secondary-400 cursor-pointer flex items-center heading-200"
                    onClick={() => {
                      const currentDate = format(new Date(), 'dd/MM/yyyy');
                      onDownloadDocument(
                        `loans/${loanId}/payment-schedule/pdf`,
                        {
                          onSuccessMessage:
                            SNACKBAR_REPAYMENT_SCHEDULE.REPAYMENT_SCHEDULE_DOWNLOAD_SUCCESS,
                          onErrorMessage:
                            SNACKBAR_REPAYMENT_SCHEDULE.REPAYMENT_SCHEDULE_DOWNLOAD_FAILED,
                          fileName: `Repayment Schedule - ${companyName} - ${currentDate}`,
                          fileType: 'pdf',
                        }
                      );
                    }}
                  >
                    Download
                    <DownloadIcon className="h-[18px] ml-1" />
                  </div>
                </Table.Header.Right>
              )}
            </Table.Header>

            <Table.Subheader>
              <RepaymentScheduleHeaders
                currency={loan?.currency}
                loanSummary={loanSummaryQuery.currentData}
                repaymentSchedule={repaymentScheduleQuery?.data}
                configurationProductData={configurationProductData}
                headersConfig={headersConfig}
                repaymentScheduleType={'preview'}
                productType={productType}
                disbursementDetails={loan?.disbursementDetails}
                interestSettings={loan?.interestSettings}
              />
            </Table.Subheader>

            <Table.Body
              data={installments ?? []}
              columns={(selectedColumns as unknown as Column<object>[]) || []}
              sorting
            />

            <Table.Pagination />
          </Table.Container>
        )
      )}

      {!isLoading && (
        <div className="flex justify-between mt-5">
          <Button layout="ghost" onClick={onBack}>
            Back
          </Button>

          <Button layout="primary" onClick={onContinue} disabled={isLoading}>
            Continue
          </Button>
        </div>
      )}
    </div>
  );
};

export { Preview };
