import { ReactNode, useRef } from 'react';
import classNames from 'classnames';
import * as Tooltip from '@radix-ui/react-tooltip';
import { useIsOverflow } from '../hooks/useIsOverflow';

type HoverTooltipProps = {
  children: ReactNode;
  label: ReactNode;
  zIndex?: number;
  side?: 'bottom' | 'top' | 'right' | 'left';
};

const HoverTooltip = ({
  children,
  label,
  zIndex = 1,
  side = 'left',
}: HoverTooltipProps) => {
  const ref = useRef(null);
  const isOverflow = useIsOverflow(ref);
  return (
    <Tooltip.Root>
      <Tooltip.Trigger ref={ref} asChild>
        {children}
      </Tooltip.Trigger>
      {isOverflow && (
        <Tooltip.Portal>
          <Tooltip.Content
            avoidCollisions={false}
            side={side}
            style={{ zIndex }}
            className={classNames(
              'rounded bg-neutral-900 px-3 py-1.5 leading-5 text-sm text-white-wrapper',
              side === 'left' && 'translate-x-full'
            )}
          >
            {label}
          </Tooltip.Content>
        </Tooltip.Portal>
      )}
    </Tooltip.Root>
  );
};

export default HoverTooltip;
