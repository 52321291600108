import React, { useLayoutEffect, useState } from 'react';

const useIsOverflow = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  callback?: (hasOverflow: boolean) => void
) => {
  const [isOverflow, setIsOverflow] = useState<boolean>();

  useLayoutEffect(() => {
    const { current } = ref;

    const { scrollWidth = 0, clientWidth = 0 } = current ?? {};

    const trigger = () => {
      const hasOverflow = scrollWidth > clientWidth;

      setIsOverflow(hasOverflow);

      callback?.(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};

export { useIsOverflow };
