import { Fragment } from 'react';

export function formatLineBreaks(text: string) {
  return (
    <Fragment>
      {text.split('\\n').map((line, index) => (
        <Fragment key={index}>
          {index > 0 && <br />}
          {line}
        </Fragment>
      ))}
    </Fragment>
  );
}
