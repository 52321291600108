import { getPreviousDay } from '@/utils/dates';
import { formatAmount, formatDate } from '@/utils/formatters';
import { Product } from 'kennek/interfaces/products';
import {
  LoanProductRules,
  Tranche,
  TrancheUpdate,
  TrancheUpdateFeeItem,
} from '@/interfaces/loans';
import { RowLoanFacility } from '@/interfaces/loans/facility';

export type TranchesSumErrors = 'MORE' | 'LESS' | 'LOAN_AMOUNT_UNDEFINED';

export type RawTranche = {
  id?: string;
  transactionEncodedKey?: string;
  disbursementDate: string;
  disbursementAmount: string;
  fees: Record<string, { amount: string; enabled: boolean }>;
  disbursed?: boolean;
  facilities?: Partial<RowLoanFacility>[];
  counterpartyId?: string;
};

export const getTranchesSum = (tranches: RawTranche[]): number =>
  tranches?.reduce((acc, tranche) => {
    const amount = parseFloat(tranche?.disbursementAmount);
    return acc + (isNaN(amount) ? 0 : amount);
  }, 0);

export const getMambuProductType = (
  products: Product[],
  selectedProductKey: string
): string => {
  const selectedProduct = products?.filter(
    (product) => product.encodedKey === selectedProductKey
  )?.[0];
  return selectedProduct?.type || null;
};

export const getTranchesSumValues = (
  tranches: RawTranche[],
  loanProductRules: LoanProductRules,
  loanAmount: number
) => {
  const sum = getTranchesSum(tranches ?? []);
  return `${formatAmount(
    sum,
    !Number.isInteger(sum),
    loanProductRules?.currency
  )} of ${formatAmount(
    loanAmount,
    !Number.isInteger(loanAmount),
    loanProductRules?.currency
  )}`;
};

export const transformTranchesToRaw = (
  tranchesList: Tranche[]
): RawTranche[] => {
  return tranchesList.map((x) => {
    const fees = {};
    x.fees.forEach((fee) => {
      fees[fee.predefinedFeeEncodedKey] = {
        amount: fee.amount,
        enabled: !x.disbursed,
      };
    });

    return {
      disbursementDate: x.disbursementDate,
      disbursementAmount: x.amount.toString(),
      disbursed: x.disbursed,
      fees,
      id: x.id ?? null,
      transactionEncodedKey: x.transactionEncodedKey ?? null,
      facilities: x?.facilities as unknown as Partial<RowLoanFacility>[],
      counterpartyId: x?.counterpartyId,
    };
  });
};

export const tranchesToUpdateDataTransform = (
  tranches: RawTranche[]
): TrancheUpdate[] => {
  return tranches.map((x) => {
    const fees: TrancheUpdateFeeItem[] = Object.keys(x.fees)
      .filter(
        (key) => x.fees[key].amount || typeof x.fees[key].amount === 'number'
      )
      .map((key) => {
        return {
          amount: Number(x.fees[key].amount),
          predefinedFeeEncodedKey: key,
        };
      });

    return {
      disbursementDate: x.disbursementDate,
      amount: Number(x.disbursementAmount),
      fees,
      id: x.id,
      transactionEncodedKey: x.transactionEncodedKey,
      facilities: x?.facilities ? Object.values(x.facilities) : [],
      counterpartyId: x?.counterpartyId,
    } as TrancheUpdate;
  });
};

/**
 * @function flattenAndSumFacilities
 * This function is designed to sum already allocated amount from all facilities of each kind across every given tranche.
 *
 * @param tranches - Tranches with facilities
 *
 * @returns Array of all used facilities across every tranche with already allocated amounts.
 */
export const flattenAndSumFacilities = (
  tranches: RawTranche[] = []
): { facilityId: string; amount: number }[] => {
  if (!tranches?.length) return [];
  const facilityMap: { [key: string]: number } = {};

  tranches.forEach((tranche) => {
    const facilities = tranche.facilities;

    if (facilities) {
      Object.values(facilities).forEach((facility: RowLoanFacility) => {
        if (facilityMap[facility.facilityId] === undefined) {
          facilityMap[facility.facilityId] = parseFloat(facility.amount) || 0;
        } else {
          facilityMap[facility.facilityId] += parseFloat(facility.amount) || 0;
        }
      });
    }
  });

  const result: { facilityId: string; amount: number }[] = [];

  for (const facilityId in facilityMap) {
    result.push({ facilityId, amount: facilityMap[facilityId] });
  }

  return result;
};

export const editTrancheDisabledInputEdit = (
  changeType: boolean,
  trancheIndex: number,
  formFlow: 'ONBOARDING' | 'REFINANCE',
  disbursed?: boolean
): boolean =>
  !changeType &&
  (disbursed || (trancheIndex === 0 && formFlow === 'REFINANCE'));

export const editTrancheDisabledTrancheRemoval = (
  trancheIndex: number,
  rawTranches: RawTranche[],
  disbursed?: boolean
): boolean => !(trancheIndex === 0 && rawTranches.length === 1) && !disbursed;

export const editTrancheGetMaxDisbursementDate = (
  trancheIndex: number,
  firstRepaymentDate: string
): string =>
  trancheIndex === 0 && firstRepaymentDate !== ''
    ? getPreviousDay(firstRepaymentDate)
    : '3000-12-31';

export const editTrancheGetMinDisbursementDate = (
  trancheIndex: number,
  prevDisbursementDate: string
): string =>
  trancheIndex !== 0 && !!prevDisbursementDate
    ? formatDate(new Date(prevDisbursementDate), 'yyyy-MM-dd')
    : null;
