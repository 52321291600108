import { CustomFees } from '@/enums/custom-fee.enum';
import { checkDateIsInTheFuture } from '@/utils/dates';
import { zonedDate } from '@/utils/datetime';
import { sum } from '@/utils/math';
import { Fee } from 'kennek/interfaces/products';
import {
  type CapitalizedFee,
  type FeeRule,
  InterestCapitalisationFrequency,
  type Loan,
  type LoanProductRules,
} from '@/interfaces/loans';

const getLoanFees = (loan: Loan) => {
  if (loan?.tranches?.length !== 0) {
    return loan?.tranches?.flatMap((tranche) => tranche.fees);
  }

  return loan?.disbursementDetails?.fees;
};

export const loanHasFees = (loan: Loan) => {
  const fees = getLoanFees(loan);

  return fees?.length !== 0;
};

export const addFees = (
  feesArray: CapitalizedFee[],
  amount: number,
  rules: LoanProductRules
) => {
  if (!rules?.fees || rules?.fees?.length === 0 || feesArray?.length === 0)
    return amount;
  return (
    feesArray?.reduce((acc, tranche) => {
      if (tranche?.amount) return acc + tranche.amount;
      return (
        acc +
        getFeeAmountFromRules(tranche?.predefinedFeeEncodedKey, amount, rules)
      );
    }, amount) || amount
  );
};

export const getFeeAmountFromRules = (
  feeEncodedKey: CapitalizedFee['predefinedFeeEncodedKey'],
  totalAmount: number,
  rules: LoanProductRules
): number => {
  const feeFound = findFeeInRules(rules, feeEncodedKey);

  if (feeFound?.customId === CustomFees.RepaymentAmountForFactorLoanFee) {
    return 0;
  }

  if (feeFound?.calculationMethod === 'FLAT') {
    return feeFound?.amount;
  }

  if (feeFound?.calculationMethod === 'PERCENTAGE') {
    return (feeFound?.percentageAmount * totalAmount) / 100;
  }

  return 0;
};

export const findFeeInRules = (
  rules: LoanProductRules,
  feeEncodedKey: CapitalizedFee['predefinedFeeEncodedKey']
) => rules?.fees?.find((fee) => feeEncodedKey === fee?.id);

export const findFeeInRulesByCustomId = (
  rules: LoanProductRules,
  customId: CapitalizedFee['customId']
) => rules?.fees?.find((fee) => customId === fee?.customId);

export const addFeesFromTranches = (loan: Loan, rules: LoanProductRules) =>
  // We should add fees to the loan amount and not count it from the tranches, because not always all tranches immediately cover the loan amount.
  loan?.tranches?.reduce(
    (acc, curr) =>
      acc + addOnlyCapitalizedFeesWithoutUpfront(curr.fees, curr.amount, rules),
    loan?.loanAmount
  );

export const getLoanAmountWithCapitalizedFees = (
  feesArray: CapitalizedFee[],
  amount: number,
  rules: LoanProductRules
) => {
  const fees = addOnlyCapitalizedFeesWithoutUpfront(feesArray, amount, rules);
  return fees + amount;
};

export const addOnlyCapitalizedFeesWithoutUpfront = (
  feesArray: CapitalizedFee[],
  amount: number,
  rules: LoanProductRules
) => {
  if (!rules?.fees || rules?.fees?.length === 0 || feesArray?.length === 0)
    return 0;

  return mapAndRemoveRepaymentAmountForFactorLoanFees(rules, feesArray).reduce(
    (acc, { fee: tranche, feeFound }) => {
      if (feeFound?.trigger !== 'CAPITALIZED_DISBURSEMENT') return acc;
      if (tranche?.amount) return acc + tranche.amount;
      return (
        acc +
        getFeeAmountFromRules(tranche?.predefinedFeeEncodedKey, amount, rules)
      );
    },
    0
  );
};

export const mapAndRemoveRepaymentAmountForFactorLoanFees = (
  rules: LoanProductRules,
  fees: CapitalizedFee[]
): { fee: CapitalizedFee; feeFound: FeeRule }[] =>
  fees
    ? fees
        ?.map((fee) => ({
          feeFound: findFeeInRules(rules, fee.predefinedFeeEncodedKey),
          fee,
        }))
        .filter(
          ({ feeFound }) =>
            feeFound?.customId !== CustomFees.RepaymentAmountForFactorLoanFee
        )
    : [];

export const interestCapitalizationFrequencyOptions = [
  {
    value: InterestCapitalisationFrequency.AT_THE_END_OF_THE_MONTH,
    label: 'At the end of the month',
  },
];

export const checkIsFutureDisbursement = (
  disbursementDate: string,
  productType: string,
  paymentsInAdvance: boolean
) => {
  const disbursementIsInTheFuture = checkDateIsInTheFuture(
    zonedDate(disbursementDate)
  );
  const supportedProductTypes = [
    'DYNAMIC_TERM_LOAN',
    'FIXED_TERM_LOAN',
  ].includes(productType);

  return (
    disbursementIsInTheFuture && supportedProductTypes && !paymentsInAdvance
  );
};

export const calculateLoanAmountWithCapFees = (loan: Loan, fees: Fee[]) => {
  if (!loan?.capFeesIncludedInLoanAmount) {
    return loan?.loanAmount;
  }

  const capFeesIds = fees
    ?.filter((fee) => fee.trigger === 'CAPITALIZED_DISBURSEMENT')
    .map((fee) => fee.encodedKey);

  const capFeesAmount = sum(loan?.tranches, (tranche) =>
    sum(tranche.fees, (fee) =>
      capFeesIds?.includes(fee.predefinedFeeEncodedKey) ? fee.amount : 0
    )
  );

  return loan?.loanAmount - capFeesAmount;
};
