import React, { Fragment, memo, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Input } from 'ui';
import { Menu, Transition } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/outline';
import { LabelsConfig } from '../../interfaces/labelsConfig';
import { GroupedLoan } from '../../interfaces/loans';
import Items from './Items';
import Selector from './Selector';

export interface LoanSelectorLoan {
  accountHolderKey?: string;
  companyName: string;
  companyNumber?: string;
  encodedKey?: string;
  id?: string;
  groupId?: string;
  loanName?: string;
  loanProductEncodedKey?: string;
  allLoans?: boolean;
}

export interface GroupedLoanListItem extends GroupedLoan {
  id?: string;
  allLoans?: boolean;
  name?: string;
}

export interface Props {
  list?: GroupedLoan[];
  selected: LoanSelectorLoan;
  labelsConfig: LabelsConfig;
  readOnly?: boolean;
  onChange?: (item: LoanSelectorLoan) => void;
  includeAllLoansOption?: boolean;
  borrower?: boolean;
}

const LoanSelector: React.FC<Props> = ({
  list,
  selected: _selected,
  labelsConfig,
  readOnly,
  onChange,
  includeAllLoansOption,
  borrower,
}) => {
  const ALL_LOANS_SELECTOR: LoanSelectorLoan = {
    companyName: `ALL ${labelsConfig.loanUpper.toUpperCase()}`,
    allLoans: true,
  };
  const selected = _selected?.allLoans ? ALL_LOANS_SELECTOR : _selected;

  const [selectedLoan, setSelectedLoan] = useState<LoanSelectorLoan>(selected);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    setSelectedLoan(_selected?.allLoans ? ALL_LOANS_SELECTOR : _selected);
  }, [_selected]);

  const selectLoan = (loan: LoanSelectorLoan) => {
    setSelectedLoan(loan);
    onChange?.(loan);
  };

  const filteredList = useMemo(() => {
    const result: GroupedLoanListItem[] =
      list?.filter((i) =>
        i?.companyName?.toLowerCase().includes(search?.toLowerCase())
      ) ?? [];
    result.sort((a, b) =>
      (a.companyName || '') <= (b.companyName || '') ? -1 : 1
    );
    if (includeAllLoansOption) {
      result.unshift(ALL_LOANS_SELECTOR);
    }
    return result;
  }, [list, includeAllLoansOption, search]);

  return (
    <Menu as="div" className={classNames('relative')}>
      {({ open }) => (
        <>
          <Menu.Button className="z-10" disabled={readOnly}>
            <Selector
              readOnly={readOnly}
              selected={readOnly ? selected : selectedLoan}
              isOpen={open}
              borrower
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            {!readOnly && (
              <Menu.Items className="absolute origin-top-right right-0 border border-neutral-200 rounded-md shadow-snackbar bg-neutral-100 focus:outline-none z-[100] mt-1.5">
                <div className="p-3 pt-4">
                  <label className="relative">
                    <SearchIcon
                      width={16}
                      className="text-neutral-700 absolute top-3 left-4"
                    />
                    <Input
                      id="filterName"
                      name="filterName"
                      type="search"
                      autoComplete="off"
                      value={search}
                      onChange={(e) => setSearch(e?.target?.value)}
                      placeholder={`Search ${labelsConfig.borrowerLower}`}
                      autoFocus={false}
                      className="border-neutral-400 rounded-md body-400 h-10 pl-10"
                    />
                  </label>
                </div>
                <Items
                  list={filteredList}
                  onClick={(loan: LoanSelectorLoan) => selectLoan(loan)}
                  borrower={borrower}
                />
              </Menu.Items>
            )}
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default memo(LoanSelector);
