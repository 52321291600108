import React from 'react';
import { Button, DropDown } from 'ui';
import { DotsVerticalIcon } from '@heroicons/react/outline';

/**
 * Fixed single character width in pixels for 14px font size.
 * Keep it as constant for now and move it to props only when
 * component gets extended in some way.
 */
const FIXED_SINGLE_CHAR_WIDTH = 140 / 17;

/**
 * Fixed icon width in pixels for icon with `w-5` tailwind class.
 * Keep it as constant for now and move it to props only when
 * component gets extended in some way.
 */
const FIXED_ICON_WIDTH = 20;

type NavigationItemOptions = {
  display: boolean;
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  skipHiddenItemIcon?: boolean;
};

/**
 * Returns width to accumulate calculated by fixed amounts (icon width + label wdith).
 * @param item navigation item
 * @returns width to accumulate
 */
const getNavigationItemWidthToAccumulate = (
  item: NavigationItemOptions
): number => FIXED_ICON_WIDTH + item.label.length * FIXED_SINGLE_CHAR_WIDTH;

/**
 * Groups initial navigation items to visible and hidden. Also removes
 * all `display=false` items from the list.
 * @param items navigation items
 * @param maxVisibilityWidth max visibility width
 * @returns items grouped to visible and hidden
 */
const groupNavigationItemsBasedOnVisibility = (
  items: NavigationItemOptions[],
  maxVisibilityWidth: number
): {
  visibleItems: NavigationItemOptions[];
  hiddenItems: NavigationItemOptions[];
} => {
  const { visibleItems, hiddenItems } = items
    .filter((item) => item.display)
    .reduce(
      (acc, item) => {
        acc.totalAccumulatedWidth += getNavigationItemWidthToAccumulate(item);

        if (acc.totalAccumulatedWidth <= maxVisibilityWidth) {
          acc.visibleItems.push(item);
          return acc;
        }

        acc.hiddenItems.push(item);
        return acc;
      },
      {
        totalAccumulatedWidth: 0,
        visibleItems: [] as NavigationItemOptions[],
        hiddenItems: [] as NavigationItemOptions[],
      }
    );

  return {
    visibleItems,
    hiddenItems,
  };
};

type NavigationItemProps = Omit<NavigationItemOptions, 'display'>;
const NavigationItem: React.FC<NavigationItemProps> = (props) => {
  const { icon, label, onClick } = props;
  return (
    <div
      className="flex items-center text-secondary-400 cursor-pointer"
      onClick={onClick}
    >
      {icon}
      <Button
        layout="link"
        type="button"
        size={'limitedPaddingX'}
        className="ml-1 px-0"
      >
        {label}
      </Button>
    </div>
  );
};

const MoreButton: React.FC = () => {
  return (
    <>
      <DotsVerticalIcon className="w-5 h-5 text-secondary-400" />
      <Button
        layout="link"
        type="button"
        size={'limitedPaddingX'}
        className="px-0"
      >
        More
      </Button>
    </>
  );
};

type CurrentBalanceWidgetNavigationProps = {
  items: NavigationItemOptions[];
  /**
   * Option responsible for showing and hiding navigation items.
   * Adjust it to your needs.
   */
  maxVisibilityWidth: number;
};
export const CurrentBalanceWidgetNavigation: React.FC<
  CurrentBalanceWidgetNavigationProps
> = (props) => {
  const { items, maxVisibilityWidth } = props;

  const { visibleItems, hiddenItems } = groupNavigationItemsBasedOnVisibility(
    items,
    maxVisibilityWidth
  );

  if (!visibleItems?.length && !hiddenItems.length) return null;
  return (
    <div className="flex gap-4 border-t border-neutral-300 pt-5">
      {visibleItems.map((item) => (
        <NavigationItem
          key={item.label}
          icon={item.icon}
          label={item.label}
          onClick={item.onClick}
        />
      ))}
      {!!hiddenItems.length && (
        <DropDown
          dropDownItems={hiddenItems.map((item) => ({
            icon: item.skipHiddenItemIcon ? item.icon : null,
            text: item.label,
            onClickHandler: item.onClick,
          }))}
          button={<MoreButton />}
          size="lg"
          separator={false}
          alignOffset={0}
          anchor="right"
          align="start"
        />
      )}
    </div>
  );
};
