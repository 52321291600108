import React from 'react';
import classNames from 'classnames';
import { Input, NumberInput } from 'ui';
import { FormInputProps } from './FormInput';

export interface StandardInputProps extends FormInputProps {
  passwordVision: boolean;
  symbolSelectAvailable: boolean;
  numberInputOnWheelPreventChange: (e: any, type: string) => void;
}

export const StandardInput = React.forwardRef<
  HTMLInputElement,
  StandardInputProps
>(function StandardInput(props, ref) {
  const {
    errors,
    disabled,
    type = 'text',
    placeholder,
    leftSymbol,
    showXButton,
    passwordEye = false,
    allowAutoComplete = false,
    defaultValue,
    className = '',
    layout = 'primary',
    noMargins,
    customLeftPadding = 0,
    passwordVision,
    symbolSelectAvailable,
    numberInputOnWheelPreventChange,
    ...other
  } = props;

  const inputClassName: string = classNames(
    noMargins ? '' : 'mt-[5px]',
    className,
    {
      'outline outline-error-300 outline-offset-2': errors,
      'pl-10': leftSymbol,
      'pl-[80px]': symbolSelectAvailable,
      'pr-16': errors && (showXButton || passwordEye),
      'pr-10':
        (errors && !showXButton && !passwordEye) ||
        (showXButton && !errors && !passwordEye) ||
        (passwordEye && !errors && !showXButton),
      'pr-44': defaultValue,
      [`pl-${customLeftPadding}`]: customLeftPadding,
    }
  );

  if (type === 'decimalNumber') {
    return (
      <NumberInput
        ref={ref}
        disabled={disabled}
        placeholder={placeholder}
        layout={layout}
        className={inputClassName}
        autoComplete={allowAutoComplete ? 'on' : 'off'}
        onWheel={(e) => numberInputOnWheelPreventChange(e, type)}
        {...other}
      />
    );
  }

  return (
    <Input
      ref={ref}
      type={!passwordEye ? type : passwordVision ? 'text' : 'password'}
      disabled={disabled}
      placeholder={placeholder}
      layout={layout}
      className={inputClassName}
      autoComplete={allowAutoComplete ? 'on' : 'off'}
      onWheel={(e) => numberInputOnWheelPreventChange(e, type)}
      {...other}
    />
  );
});
