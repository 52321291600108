import React from 'react';
import { IconProps } from '../iconTypes';

const RightIcon: React.FC<IconProps> = (props) => (
  <svg
    width="6"
    height="9"
    viewBox="0 0 6 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mt-1"
    {...props}
  >
    <path
      d="M1.22266 1L4.33377 4.5L1.22266 8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RightIcon;
