import React, { useState } from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { cn } from '../utils';
import ToggleSwitch from './ToggleSwitch';
import Tooltip from './Tooltip';

type ToggleInputProps = {
  label: string;
  enabled: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  tooltipText?: string;
  labelClassName?: string;
};
const ToggleInput: React.FC<ToggleInputProps> = ({
  label,
  enabled,
  disabled,
  onChange,
  tooltipText,
  labelClassName,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <label htmlFor={label} className="flex justify-between">
      <div className="heading-200 py-2 flex">
        <span className={cn(labelClassName)}>{label}</span>
        {tooltipText && (
          <div
            className="flex items-center gap-x-1 ml-2"
            onMouseOver={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <div className="relative flex items-center">
              <QuestionMarkCircleIcon width={14} className="cursor-pointer " />
              {showTooltip && (
                <Tooltip
                  position="right"
                  customClasses="!w-[400px] whitespace-pre-wrap"
                >
                  {tooltipText}
                </Tooltip>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="pt-1">
        <ToggleSwitch
          id={label}
          enabled={enabled}
          disabled={disabled}
          onChange={onChange}
        />
      </div>
    </label>
  );
};

export default ToggleInput;
