import React from 'react';
import classNames from 'classnames';
import { PencilAltIcon } from '@heroicons/react/outline';

interface Props {
  onClick?: () => void;
  hideText?: boolean;
}

const EditButton: React.FC<Props> = ({ onClick, hideText = false }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center body-400 text-secondary-400"
    >
      {hideText || 'Edit'}
      <PencilAltIcon
        data-testid="edit-icon"
        height={20}
        className={classNames('inline', { 'ml-1': !hideText })}
      />
    </button>
  );
};

export default EditButton;
