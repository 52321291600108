import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { env } from '@/env/public';
import editLoanSlice from '@/features/edit-loan/editLoanSlice';
import { onboardingSlice } from '@/features/onboarding';
import { authApi } from '@/services/auth';
import { rootApi } from '@/services/rootApi';
import alertsReducer from './alerts';
import borrowerReducer from './borrower';
import { SIGN_OUT } from './signout';
import uaasSlice from './uaas';
import userReducer from './user';

const reducers = combineReducers({
  user: userReducer,
  borrower: borrowerReducer,
  alerts: alertsReducer,
  onboarding: onboardingSlice,
  editLoan: editLoanSlice,
  uaas: uaasSlice,
  [authApi.reducerPath]: authApi.reducer,
});

export const store = configureStore({
  reducer: (state: ReturnType<typeof reducers>, action) => {
    if (action.type === SIGN_OUT.type) {
      state = undefined;
    }
    return reducers(state, action);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rootApi.middleware),
  devTools:
    process.env.NODE_ENV !== 'production' || env.NEXT_PUBLIC_REDUX_DEVTOOLS,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {user: UserState}
export type AppDispatch = typeof store.dispatch;
