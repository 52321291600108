interface Props {
  content: string | number;
}

const Unread = ({ content }: Props) => {
  return (
    <span className="bg-primary-700 relative text-white caption rounded-full flex w-5 h-5 justify-center items-center">
      {content}
    </span>
  );
};

export default Unread;
