import { useEffect, useState } from 'react';
import Head from 'next/head';
import { kennekFavicon } from 'kennek/assets';
import { useDebouncedCallback } from 'use-debounce';
import { useBranchEncodedKey } from '@/hooks/useBranchEncodedKey';
import useGetBrandData from '@/hooks/useGetBrandData';

const PAGE_TITLE_DEFAULT_LOGGED_IN = 'Kennek';
const PAGE_TITLE_DEFAULT_AUTH = 'Kennek solutions';

interface MetaHeaderProps {
  isAuth?: boolean;
}

export const MetaHeader = ({ isAuth = false }: MetaHeaderProps) => {
  const { branchEncodedKey, isLoading } = useBranchEncodedKey();
  const [pageTitle, setPageTitle] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  const { brandData, isLoadingBranding } = useGetBrandData(branchEncodedKey);

  const isPendingData = isLoading || isLoadingBranding;
  const defaultTitle = isAuth
    ? PAGE_TITLE_DEFAULT_AUTH
    : PAGE_TITLE_DEFAULT_LOGGED_IN;

  const onChangePageTitle = useDebouncedCallback(
    (val: string) => setPageTitle(val),
    1500
  );

  const onChangeLogoUrl = useDebouncedCallback(
    (val: string) => setLogoUrl(val),
    1500
  );

  useEffect(() => {
    if (isPendingData || !brandData) return;
    onChangeLogoUrl(brandData.favicon?.url || kennekFavicon.src);
    onChangePageTitle(brandData.name || defaultTitle);
  }, [brandData, isPendingData]);

  return (
    <Head>
      <title>{pageTitle}</title>
      <link rel="icon" href={logoUrl} />
    </Head>
  );
};
