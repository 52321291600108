import React, { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { SvgIcon } from 'kennek/icons';
import { Button, Table } from 'ui';
import { PencilAltIcon } from '@heroicons/react/outline';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { ROUTES } from '@/constants/routes';
import { SNACKBAR_ONBOARDING } from '@/constants/snackbar-messages';
import { useRole } from '@/hooks/useRole';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useGetLoanOnboardingCustomInformationPageIdQuery } from '@/services/kennek';
import { useGetCustomInformationsQuery } from '@/services/loans';
import { getRouterQuery } from '@/utils/helpers';
import { RefinanceSteps } from '../edit-loan/EditLoan';
import { useCustomInformation } from '../edit-loan/editLoanSlice';
import { OnboardingSteps } from '../onboarding/Onboarding';
import {
  formatKeyIntoLabel,
  sortCustomInformation,
} from '../onboarding/custom-information/utils';
import { Column } from 'ui/types';

export interface CustomInformationTableProps {
  loanId: string;
  isBorrowerView?: boolean;
  isLoanDetails?: boolean;
  branchEncodedKey?: string;
  setActiveStep?: (value: RefinanceSteps | OnboardingSteps) => void;
}

const CustomInformationTable: React.FC<CustomInformationTableProps> = ({
  loanId,
  isBorrowerView = false,
  branchEncodedKey,
  isLoanDetails,
  setActiveStep,
}) => {
  const { role } = useRole();
  const router = useRouter();
  const snackbar = useSnackbar();
  const customInformationState = useCustomInformation();

  const {
    data: loanCustomInformationQuery,
    isLoading: isCustomInformationLoading,
    isError: isErrorFetchingCustomInfo,
  } = useGetCustomInformationsQuery(loanId, {
    skip: !loanId,
  });

  const {
    data: customInformationPageId,
    isError: isPageIdFetchedError,
    isLoading: isPageIdLoading,
  } = useGetLoanOnboardingCustomInformationPageIdQuery(
    { branchEncodedKey },
    { skip: !branchEncodedKey }
  );

  const mappedCustomInformationData = (
    customInformationState ?? loanCustomInformationQuery?.data
  )?.map((item) => ({
    ...item,
    key: item.key
      ? formatKeyIntoLabel(item.key, customInformationPageId?.pageId)
      : undefined,
  }));

  useEffect(() => {
    if (isErrorFetchingCustomInfo) {
      snackbar.show({
        severity: 'error',
        title: SNACKBAR_ONBOARDING.CUSTOM_INFORMATION_FAILED_GET_DATA,
      });
    }
    if (isPageIdFetchedError) {
      snackbar.show({
        severity: 'error',
        title: SNACKBAR_ONBOARDING.CUSTOM_INFORMATION_FAILED_GET_UNITS,
      });
    }
  }, [isErrorFetchingCustomInfo, customInformationPageId]);

  if (isPageIdLoading || isCustomInformationLoading)
    return <LoadingAnimationPlat />;

  const customInformationtableDataColumns = () => {
    return [
      {
        Header: 'Visibility',
        accessor: 'visibility',
        Cell: ({ row: { original } }) => {
          return original.visibility && original.visibility.length > 0
            ? original.visibility
                .map((visibility) => {
                  const formattedVisibility = visibility.split('_')[0];
                  return (
                    formattedVisibility.charAt(0).toUpperCase() +
                    formattedVisibility.slice(1).toLowerCase()
                  );
                })
                .join(', ')
            : '-';
        },
        show: !isBorrowerView,
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: 'Label',
        accessor: 'key',
        maxWidth: 250,
        minWidth: 250,
        Cell: ({ row }: { row: { original: { key: string } } }) =>
          row.original.key || '-',
      },
      {
        Header: 'Value',
        accessor: 'value',
        Cell: ({
          row: {
            original: { value },
          },
        }) => {
          return value ? (
            <span className="font-bold whitespace-break-spaces break-words [overflow-wrap:anywhere]">
              {value}
            </span>
          ) : (
            '-'
          );
        },
        maxWidth: 300,
        minWidth: 300,
      },
    ].filter((column) => column.show !== false);
  };
  return (
    <Table.Container variant="compact" isLoading={isCustomInformationLoading}>
      <Table.Header>
        <Table.Header.Left>
          <Table.Name
            title="Additional Information"
            leftIcon={<SvgIcon name="StatementIcon" />}
            style="heading-300"
          />
        </Table.Header.Left>
        <Table.Header.Right>
          {role === 'ORIGINATOR_ADMIN' && (
            <Button
              layout="ghost"
              size="small"
              onClick={() => {
                if (isLoanDetails) {
                  const path = getRouterQuery(ROUTES.CUSTOM_INFORMATION, {
                    loanId,
                  });
                  return router.push(path);
                }
                setActiveStep('CUSTOM_INFORMATION');
              }}
            >
              {!isLoanDetails ? (
                <PencilAltIcon height={20} className="text-secondary-500" />
              ) : (
                'Edit'
              )}
            </Button>
          )}
        </Table.Header.Right>
      </Table.Header>
      <Table.Body
        columns={customInformationtableDataColumns() as Column<object>[]}
        emptyTableMessage="No custom information to display"
        maxHeight
        data={sortCustomInformation(
          mappedCustomInformationData,
          customInformationPageId?.pageId
        )}
      />
    </Table.Container>
  );
};
export default CustomInformationTable;
