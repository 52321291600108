import * as React from 'react';
import { useContext } from 'react';
import classNames from 'classnames';
import { valueHasDecimals } from '@/utils/helpers';
import { LoanSummaryContext } from './ContextWrapper';
import NextInstallmentRow from './NextInstallmentRow';

type Props = {
  showDetails: boolean;
};

const NextInstallments: React.FC<Props> = ({ showDetails }) => {
  const { nextPaymentData, nextInstallmentPaymentDue, totalGraceAmount } =
    useContext(LoanSummaryContext);

  const isOverdueVisible = nextPaymentData?.totalOverdueAmount > 0;
  const isGraceVisible = totalGraceAmount > 0;

  const rootElementHeight = showDetails
    ? isOverdueVisible && isGraceVisible
      ? 'h-[136px]'
      : 'h-[91px]'
    : 'h-0 mt-0';

  return (
    <div
      className={classNames(
        'rounded-2sm transition-[height] overflow-y-hidden mt-1',
        rootElementHeight
      )}
    >
      <div className="flex flex-col w-full h-full bg-neutral-200 rounded-md">
        {isOverdueVisible && (
          <NextInstallmentRow
            amount={nextPaymentData?.totalOverdueAmount}
            variant="OVERDUE"
            showDecimals={valueHasDecimals(nextPaymentData?.totalOverdueAmount)}
          />
        )}
        {isGraceVisible && (
          <NextInstallmentRow amount={totalGraceAmount} variant="GRACE" />
        )}
        <hr className="border-neutral-300 mx-4" />
        <NextInstallmentRow
          amount={nextInstallmentPaymentDue}
          variant="TOTAL"
          showDecimals={valueHasDecimals(nextInstallmentPaymentDue)}
        />
      </div>
    </div>
  );
};

export default NextInstallments;
