import React from 'react';

interface CardTopicProps {
  icon?: React.ReactElement;
  title: string;
}

const CardTopic: React.FC<CardTopicProps> = ({ icon, title }) => {
  const reSizedIcon = icon ? React.cloneElement(icon, { width: '24' }) : null;
  return (
    <>
      <div className="flex pl-7 pt-6">
        {reSizedIcon}
        <h2 className="heading-400 pl-2 select-none">{title}</h2>
      </div>
    </>
  );
};

export default CardTopic;
