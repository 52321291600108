import classNames from 'classnames';

interface Balance {
  color: string;
  paid: number;
  wholeBalance: number;
}

interface Props {
  balances: Balance[];
  mainSum: number;
}

const MultiDataProgressBar = ({ balances, mainSum }: Props) => {
  const filteredBalances = balances.filter((x) => x.paid);
  return (
    <div className="w-full bg-neutral-200 rounded-full h-2 flex overflow-hidden">
      {filteredBalances
        .filter((x) => x.paid)
        .map((x, index) => {
          const className =
            index === filteredBalances.length - 1
              ? classNames('h-2 rounded-r-2xl')
              : 'h-2';

          return (
            <div
              className={className}
              style={{
                width: `${(x.paid / mainSum) * 100 || 0}%`,
                backgroundColor: `${x.color}`,
              }}
              key={index}
            ></div>
          );
        })}
    </div>
  );
};

export default MultiDataProgressBar;
