import React from 'react';
import { IconProps } from '../iconTypes';

export interface CovenantIconProps extends IconProps {
  pathFill?: string;
}

const CovenantIcon: React.FC<CovenantIconProps> = ({ pathFill, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...rest}
  >
    <path
      d="M3.24512 2.00024C2.69283 2.00024 2.24512 2.44796 2.24512 3.00024V17.0002C2.24512 17.5525 2.69283 18.0002 3.24512 18.0002H13.2451C13.7974 18.0002 14.2451 17.5525 14.2451 17.0002V15.2162L16.2451 13.05V17.0002C16.2451 18.6571 14.902 20.0002 13.2451 20.0002H3.24512C1.58826 20.0002 0.245117 18.6571 0.245117 17.0002V3.00024C0.245117 1.34339 1.58826 0.000244141 3.24512 0.000244141H8.8309C9.36134 0.000244141 9.87005 0.210957 10.2451 0.586031L15.6593 6.00024C16.0344 6.37532 16.2451 6.88403 16.2451 7.41446V8.42947L14.2451 10.5956V7.41446L8.8309 2.00024H3.24512ZM14.2451 11.3328L12.2644 13.4781L13.7738 14.9898L19.7548 8.51186L18.2454 7.00024L16.2451 9.16668V9.16653L14.2451 11.3327V11.3328ZM4.24513 8.00026C4.24513 7.44797 4.69284 7.00026 5.24513 7.00026H11.2451C11.7974 7.00026 12.2451 7.44797 12.2451 8.00026C12.2451 8.55254 11.7974 9.00026 11.2451 9.00026H5.24513C4.69284 9.00026 4.24513 8.55254 4.24513 8.00026ZM4.24513 12.0003C4.24513 11.448 4.69284 11.0003 5.24513 11.0003H11.2451C11.7974 11.0003 12.2451 11.448 12.2451 12.0003C12.2451 12.5525 11.7974 13.0003 11.2451 13.0003H5.24513C4.69284 13.0003 4.24513 12.5525 4.24513 12.0003ZM10.2475 16.983C10.3212 17.0569 12.9758 16.9295 13.0119 15.8723C13.0275 15.4167 12.781 15.0841 12.459 14.7617C12.1371 14.4393 11.4756 14.1703 11.0768 14.484C10.8361 14.6734 10.8292 15.0463 10.8227 15.4035C10.8193 15.5869 10.816 15.7661 10.7811 15.9142C10.7068 16.2302 10.4938 16.5625 10.3598 16.7716C10.2839 16.89 10.2333 16.9689 10.2475 16.983Z"
      fill={pathFill || 'currentColor'}
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default CovenantIcon;
