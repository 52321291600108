import React from 'react';
import { useRole } from '@/hooks/useRole';
import { useGetSupportedAssetsQuery } from '@/services/loans/securities';
import { AssetWidgetProps } from './AssetWidget.props';
import DetailedAssetWidget from './widgets/DetailedAssetWidget';

const AssetWidgetResolver: React.FC<AssetWidgetProps> = ({
  displayedAssets,
  showAsset,
  currency,
  loanEncodedKey,
}) => {
  const { isOriginator } = useRole();

  const { data: supportedAssets } = useGetSupportedAssetsQuery(loanEncodedKey, {
    skip: !loanEncodedKey,
  });

  return (
    <DetailedAssetWidget
      currency={currency}
      displayedAssets={displayedAssets}
      showAsset={showAsset}
      supportedAssets={supportedAssets}
      isOriginator={isOriginator}
    ></DetailedAssetWidget>
  );
};

export default AssetWidgetResolver;
