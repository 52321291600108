import { ColorPalate } from 'kennek/interfaces/kennek';

export const colorDefault = Object.freeze({
  primary: {
    100: '#CEFFED',
    200: '#A4FFDD',
    300: '#7CFFCE',
    400: '#48FFBA',
    500: '#00F096',
    600: '#0ED98D',
    700: '#0CB676',
    800: '#0B8759',
    900: '#075135',
  },
  secondary: {
    100: '#EDF4FF',
    200: '#CEE0FF',
    300: '#71def6',
    400: '#184083',
    500: '#092047',
    600: '#05142D',
    700: '#041023',
    800: '#030D1D',
    900: '#020A17',
  },
  neutral: {
    100: '#f8fafc',
    200: '#f1f5f9',
    300: '#e2e8f0',
    400: '#cbd5e1',
    500: '#94a3b8',
    600: '#64748b',
    700: '#334155',
    800: '#1e293b',
    900: '#0a132c',
  },
  info: {
    200: '#FFC6F8',
    300: '#FC89EE',
    500: '#F04BDC',
    700: '#A61594',
    800: '#670A5B',
  },
  success: {
    200: '#ECF5EE',
    300: '#CAE6CF',
    500: '#5FB164',
    700: '#458B49',
    800: '#326E36',
  },
  warning: {
    200: '#FDF7EB',
    300: '#F6DFB8',
    500: '#E29735',
    700: '#C17D23',
    800: '#9F6313',
  },
  error: {
    200: '#FEEEEE',
    300: '#FCDDDD',
    500: '#F15656',
    700: '#CA3B3B',
    800: '#A82828',
  },
});

export const setAppPalette = (
  primary: ColorPalate,
  secondary: ColorPalate
): void => {
  setPalette('primary', colorDefault.primary, primary);
  setPalette('secondary', colorDefault.secondary, secondary);
};

const setPalette = (
  value: string,
  defaultPalete: {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  },
  palate?: ColorPalate
): void => {
  setRootColor(`--color-${value}-100`, palate?.C100 || defaultPalete[100]);
  setRootColor(`--color-${value}-200`, palate?.C200 || defaultPalete[200]);
  setRootColor(`--color-${value}-300`, palate?.C300 || defaultPalete[300]);
  setRootColor(`--color-${value}-400`, palate?.C400 || defaultPalete[400]);
  setRootColor(`--color-${value}-500`, palate?.C500 || defaultPalete[500]);
  setRootColor(`--color-${value}-600`, palate?.C600 || defaultPalete[600]);
  setRootColor(`--color-${value}-700`, palate?.C700 || defaultPalete[700]);
  setRootColor(`--color-${value}-800`, palate?.C800 || defaultPalete[800]);
  setRootColor(`--color-${value}-900`, palate?.C900 || defaultPalete[900]);
};

const setRootColor = (val: string, color: string): void => {
  if (window) document.documentElement.style.setProperty(val, color);
};
