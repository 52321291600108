import {
  Execution,
  ExecutionPage,
  ExecutionResponse,
  Flow,
  Module,
  PageConfig,
  Product,
} from '@/store/uaas';
import { rootApi } from './rootApi';
import { User } from 'kennek/interfaces/accounts';
import {
  DocumentData,
  DocumentUrl,
} from 'kennek/interfaces/documentManagement';
import { PaymentSchedule } from '@/interfaces/loans';
import {
  CodatCompanyRedirect,
  CodatIntegrationItem,
  DMUnderwritingDocument,
  DirectDebitData,
  DirectDebitStatus,
  ExecutionServicingRedirectionDetails,
  RiskAnalysisRequest,
  RiskAnalysisResponse,
  UnderwritingRepaymentsQuery,
  UnderwritingRequestResponse,
} from '@/interfaces/underwriting';

const baseUnderwritingApi = '/api/underwriting';

export const underwritingApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getUnderwritingRequest: builder.query<
      { result: UnderwritingRequestResponse; count: number },
      {
        branchEncodedKey?: string;
        page: number;
        pageSize: number;
        includeTotalCount?: boolean;
        status?: string;
        search?: string;
        orderBy?: string;
        orderCriteria?: string;
      }
    >({
      query: ({
        branchEncodedKey,
        page,
        pageSize,
        includeTotalCount,
        search,
        status,
        orderBy,
        orderCriteria,
      }) => ({
        url: baseUnderwritingApi + '/request',
        params: {
          branchEncodedKey,
          page,
          pageSize,
          includeTotalCount,
          search,
          status,
          orderBy,
          orderCriteria,
        },
      }),
      transformResponse(result, meta) {
        return {
          result: result as UnderwritingRequestResponse,
          count: Number(meta.response.headers.get('items-total')) || undefined,
        };
      },
      providesTags: ['Underwriting-Request'],
    }),
    createUnderwritingRequest: builder.mutation({
      query: ({ body }) => ({
        url: baseUnderwritingApi + `/request`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Request'],
    }),
    createExecution: builder.mutation({
      query: ({ body }) => ({
        url: baseUnderwritingApi + `/execution`,
        method: 'POST',
        body,
      }),
    }),
    getExecution: builder.query<Execution, { id: string }>({
      query: ({ id }) => ({
        url: baseUnderwritingApi + `/execution/${id}`,
      }),
      providesTags: ['Underwriting-Execution'],
    }),
    getPages: builder.query<PageConfig[], { executionId: string }>({
      query: ({ executionId }) => ({
        url: baseUnderwritingApi + `/execution/${executionId}/pages`,
      }),
      providesTags: ['Underwriting-Config'],
    }),
    getUniquePage: builder.query<PageConfig, { pageId: string }>({
      query: ({ pageId }) => ({
        url: baseUnderwritingApi + `/page/${pageId}`,
      }),
      providesTags: ['Underwriting-Config'],
    }),
    getFlows: builder.query<Flow[], { originatorId: string }>({
      query: ({ originatorId }) => ({
        url: baseUnderwritingApi + `/flow`,
        params: { originatorId },
      }),
      providesTags: ['Underwriting-Config'],
    }),
    getFlow: builder.query<Flow, { flowId: string }>({
      query: ({ flowId }) => ({
        url: baseUnderwritingApi + `/flow/${flowId}`,
      }),
      providesTags: ['Underwriting-Flow'],
    }),
    getFlowPages: builder.query<PageConfig[], { flowId: string }>({
      query: ({ flowId }) => ({
        url: baseUnderwritingApi + `/flow/${flowId}/pages`,
      }),
      providesTags: ['Underwriting-Config'],
    }),
    getFlowModules: builder.query<Module[], { flowId: string; pageId: string }>(
      {
        query: ({ pageId, flowId }) => ({
          url: baseUnderwritingApi + `/flow/${flowId}/page/${pageId}`,
        }),
        // Disable cache to avoid rendering inaccessible pages.
        // The performance should not have a significant impact
        // as it is only to fetch the modules for just one page.
        keepUnusedDataFor: 0,
        providesTags: ['Underwriting-Config'],
      }
    ),
    getFlowProducts: builder.query<Product[], { flowId: string }>({
      query: ({ flowId }) => ({
        url: baseUnderwritingApi + `/flow/${flowId}/products`,
      }),
      providesTags: ['Underwriting-Config', 'Underwriting-Flow'],
    }),
    getModules: builder.query<
      Module[],
      { executionId: string; pageId: string }
    >({
      query: ({ pageId, executionId }) => ({
        url: baseUnderwritingApi + `/execution/${executionId}/page/${pageId}`,
      }),
      // Disable cache to avoid rendering inaccessible pages.
      // The performance should not have a significant impact
      // as it is only to fetch the modules for just one page.
      keepUnusedDataFor: 0,
      providesTags: ['Underwriting-Config'],
    }),
    executionAction: builder.mutation<
      ExecutionResponse,
      {
        executionId: string;
        pageId: string;
        actions: ('FORWARD' | 'BACK' | 'REFER')[];
        buttonRef?: string;
      }
    >({
      query: ({ executionId, pageId, actions, buttonRef }) => ({
        url: baseUnderwritingApi + `/execution/${executionId}/action`,
        body: { pageId, actions, buttonRef },
        method: 'POST',
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Execution'],
    }),
    executionAccept: builder.mutation({
      query: (executionId: string) => ({
        url: baseUnderwritingApi + `/execution/${executionId}/accept`,
        method: 'POST',
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Execution'],
    }),
    executionReject: builder.mutation({
      query: (executionId: string) => ({
        url: baseUnderwritingApi + `/execution/${executionId}/reject`,
        method: 'POST',
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Execution'],
    }),
    patchExecution: builder.mutation<
      Execution,
      { executionId: string; currentPageId: string }
    >({
      query: ({ executionId, currentPageId }) => ({
        url: baseUnderwritingApi + `/execution/${executionId}`,
        body: { currentPageId },
        method: 'PATCH',
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Execution'],
    }),
    inviteUser: builder.mutation({
      query: ({ executionId, pageId, moduleId, user }) => ({
        url: baseUnderwritingApi + `/execution/${executionId}/invite-user`,
        body: {
          pageId,
          moduleId,
          ...user,
        },
        method: 'POST',
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Execution'],
    }),

    addPagesToExecution: builder.mutation<
      Execution,
      { executionId: string; currentPageId: string; newPages: ExecutionPage[] }
    >({
      query: ({ executionId, currentPageId, newPages }) => ({
        url: baseUnderwritingApi + `/execution/${executionId}/pages`,
        body: { currentPageId, newPages },
        method: 'POST',
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Execution'],
    }),
    getDatastore: builder.query({
      query: (query: RetrieveFields) => ({
        url: baseUnderwritingApi + `/datastore`,
        method: 'POST',
        body: query,
      }),
      providesTags: ['Underwriting-Datastore'],
    }),
    createOrUpdateDatastore: builder.mutation({
      query: (input: CreateOrUpdateDatastore) => ({
        url: baseUnderwritingApi + `/datastore`,
        method: 'PUT',
        body: input,
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Datastore'],
    }),
    deleteDatastore: builder.mutation({
      query: (input: DeleteFields) => ({
        url: baseUnderwritingApi + `/datastore`,
        method: 'DELETE',
        params: input,
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Datastore'],
    }),
    getDocumentUploadUrl: builder.mutation({
      query: (query: UploadFileData) => ({
        url: baseUnderwritingApi + `/datastore/upload-url`,
        method: 'POST',
        body: query,
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Datastore'],
    }),
    getDocumentDownloadUrl: builder.mutation({
      query: ({ key }) => ({
        url: baseUnderwritingApi + `/datastore/download-url/${key}`,
        method: 'GET',
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Datastore'],
    }),
    getCodatRedirectionUrl: builder.mutation<
      CodatCompanyRedirect,
      { executionId: string }
    >({
      query: (query: { executionId: string }) => ({
        url: baseUnderwritingApi + `/integrations/codat/company`,
        method: 'POST',
        body: query,
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Codat-Integration'],
    }),
    getCodatStatus: builder.query({
      query: ({ executionId }) => ({
        url: baseUnderwritingApi + `/integrations/codat/status`,
        method: 'GET',
        params: {
          executionId: executionId,
        },
      }),
      providesTags: ['Underwriting-Codat-Integration'],
    }),
    disconnectCodat: builder.mutation({
      query: ({ executionId }) => ({
        url: baseUnderwritingApi + `/integrations/codat/connections`,
        method: 'DELETE',
        params: {
          executionId: executionId,
        },
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Codat-Integration'],
    }),
    getCodatSupportedBanksList: builder.query<
      CodatIntegrationItem[],
      { executionId: string }
    >({
      query: ({ executionId }) => ({
        url: baseUnderwritingApi + `/integrations/codat/supported-banks`,
        method: 'GET',
        params: {
          executionId: executionId,
        },
      }),
      providesTags: ['Underwriting-Codat-Integration'],
    }),
    transferData: builder.mutation<
      User,
      { executionId: string; borrowerEmail?: string }
    >({
      query: ({ executionId, borrowerEmail }) => ({
        url: baseUnderwritingApi + `/integrations/data-transfer/${executionId}`,
        method: 'POST',
        body: {
          borrowerEmail,
        },
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Integrations'],
    }),

    getDirectDebitStatus: builder.query<
      DirectDebitStatus,
      { executionId: string; loanId: string }
    >({
      query: (query: { executionId: string }) => ({
        url: baseUnderwritingApi + `/integrations/direct-debit/status`,
        method: 'GET',
        params: query,
      }),
      providesTags: ['Underwriting-Codat-Integration'],
    }),
    enableDirectDebit: builder.mutation<DirectDebitStatus, DirectDebitData>({
      query: (query) => ({
        url: baseUnderwritingApi + `/integrations/direct-debit/enable`,
        method: 'POST',
        body: query,
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Integrations'],
    }),
    ingestDocumentToDM: builder.mutation<DocumentData, DMUnderwritingDocument>({
      query: (query) => ({
        url: baseUnderwritingApi + `/integrations/document-management/ingest`,
        method: 'POST',
        body: query,
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Integrations'],
    }),
    downloadDocumentManagementFile: builder.mutation<
      DocumentUrl,
      { executionId: string; documentId: string }
    >({
      query: (query: { executionId: string; documentId: string }) => ({
        url:
          baseUnderwritingApi +
          `/integrations/document-management/download/${query.executionId}/${query.documentId}`,
        method: 'GET',
      }),
    }),
    createLoanUnderwriting: builder.mutation({
      query: (query: { executionId: string }) => ({
        url: baseUnderwritingApi + `/integrations/loan-creation`,
        method: 'POST',
        body: query,
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Integrations'],
    }),
    getUnderwritingRepaymentSchedule: builder.query<
      PaymentSchedule,
      { id: string; query: UnderwritingRepaymentsQuery }
    >({
      query: ({ id, query }) => ({
        url: baseUnderwritingApi + `/integrations/repayment-schedule/${id}`,
        method: 'GET',
        params: query,
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: ['Underwriting-Codat-Integration'],
    }),
    getExecutionToServicingRedirection: builder.mutation<
      ExecutionServicingRedirectionDetails,
      { executionId: string }
    >({
      query: (query: { executionId: string }) => ({
        url: baseUnderwritingApi + `/execution/servicing-redirection`,
        method: 'POST',
        body: query,
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Execution'],
    }),
    createRiskReportUnderwriting: builder.mutation<
      RiskAnalysisResponse,
      RiskAnalysisRequest
    >({
      query: (body: RiskAnalysisRequest) => ({
        url: baseUnderwritingApi + `/integrations/risk-analysis/generate`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RiskAnalysisReports'],
    }),
  }),
});

interface DatastoreId {
  executionId: string;
  prefix: string;
}

export interface CreateOrUpdateDatastore extends DatastoreId {
  keyValues: Record<string, any>;
}

export interface RetrieveFields extends DatastoreId {
  keys: string[];
}

export interface DeleteFields extends DatastoreId {
  keys: string[];
}

export interface UploadFileData {
  executionId: string;
  originalFileName: string;
}

export const {
  useGetUnderwritingRequestQuery,
  useCreateUnderwritingRequestMutation,
  useCreateExecutionMutation,
  useGetExecutionQuery,
  useGetPagesQuery,
  useGetUniquePageQuery,
  useGetModulesQuery,
  useCreateOrUpdateDatastoreMutation,
  useDeleteDatastoreMutation,
  useLazyGetDatastoreQuery,
  useGetDocumentUploadUrlMutation,
  useGetDocumentDownloadUrlMutation,
  useExecutionActionMutation,
  useExecutionAcceptMutation,
  useExecutionRejectMutation,
  usePatchExecutionMutation,
  useInviteUserMutation,
  useAddPagesToExecutionMutation,
  useLazyGetFlowsQuery,
  useGetFlowsQuery,
  useGetFlowQuery,
  useGetFlowPagesQuery,
  useGetFlowModulesQuery,
  useLazyGetFlowProductsQuery,
  useGetFlowProductsQuery,
  useGetCodatRedirectionUrlMutation,
  useGetCodatStatusQuery,
  useDisconnectCodatMutation,
  useGetCodatSupportedBanksListQuery,
  useTransferDataMutation,
  useGetDirectDebitStatusQuery,
  useEnableDirectDebitMutation,
  useIngestDocumentToDMMutation,
  useDownloadDocumentManagementFileMutation,
  useCreateLoanUnderwritingMutation,
  useGetUnderwritingRepaymentScheduleQuery,
  useGetExecutionToServicingRedirectionMutation,
  useCreateRiskReportUnderwritingMutation,
  util,
} = underwritingApi;
