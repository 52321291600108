import React from 'react';
import { Control, Controller } from 'react-hook-form';
import FormInput from 'ui/components/FormInput';
import { cn } from 'ui/utils';
import { InvestmentsFormData } from '../InvestmentsForm.types';
import { Currencies } from 'kennek/interfaces/loans';

type InvestmentsFormAmountInputProps = {
  currency: Currencies;
  control: Control<InvestmentsFormData, unknown>;
  index: number;
  rules: {
    maxAmount: number;
  };
  disabled?: boolean;
  error?: string;
};

export const InvestmentsFormAmountInput: React.FC<
  InvestmentsFormAmountInputProps
> = (props) => {
  return (
    <Controller
      name={`investments.${props.index}.amount`}
      control={props.control}
      rules={{
        required: 'Amount is required',
        min: {
          value: 0,
          message: `Amount cannot be less than 0 ${props.currency}`,
        },
        max: {
          value: props.rules.maxAmount,
          message: `Amount cannot exceed ${props.rules.maxAmount} ${props.currency}`,
        },
        validate: (_, formValues) => {
          const amountsSum = formValues.investments
            .map((investment) =>
              isNaN(parseFloat(investment.amount))
                ? 0
                : parseFloat(investment.amount)
            )
            .reduce((acc, amount) => acc + amount, 0);
          return (
            amountsSum <= props.rules.maxAmount ||
            `Aggregated amount can't exceed ${props.rules.maxAmount} ${props.currency}`
          );
        },
      }}
      render={({ field }) => (
        <FormInput
          onValueChange={(e) => {
            field.onChange(e ?? '');
          }}
          value={field.value}
          onBlur={field.onBlur}
          onChange={() => undefined}
          currencyInput={props.currency}
          customLeftPadding="7"
          errorBottomPosition="bottom-4"
          containerClassName={cn('w-[140px] mt-[0px]', {
            'mb-[24px]': !!props.error,
          })}
          errors={props.error}
          disabled={props.disabled}
        />
      )}
    />
  );
};
