import React from 'react';
import * as yup from 'yup';

export const HeaderIcon: React.FC = () => {
  return (
    <div className="rounded-full w-16 h-16 bg-primary-500 flex items-center justify-center mb-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke={'black'}
        className="w-8 h-8 opacity-50"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
        />
      </svg>
    </div>
  );
};

export const counterpartyFromValidationSchema = yup.object({
  type: yup.string().trim().required('Type is required.'),
  bankAccountHolderName: yup
    .string()
    .trim()
    .required('Bank account holder name is required'),
  bankAccountSortCode: yup
    .string()
    .trim()
    .required('Bank account sort code is required.')
    .length(6, 'Bank account sort code must have length of 6 characters.'),
  bankAccountNumber: yup
    .string()
    .trim()
    .length(8, 'Bank account number must have length of 8 characters.')
    .required('Bank account number is required.'),
  description: yup
    .string()
    .trim()
    .optional()
    .max(1000, 'Description length cannot exceed 1000 characters.'),
});

export type CounterpartyFormData = {
  type: string;
  bankAccountHolderName: string;
  bankAccountSortCode: string;
  bankAccountNumber: string;
  description?: string;
};
