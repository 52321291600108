import React from 'react';
import { IconProps } from '../iconTypes';

const PortfolioIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 15 17"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M2.25 8.13043C1.83579 8.13043 1.5 8.46135 1.5 8.86957V14.7826C1.5 15.1908 1.83579 15.5217 2.25 15.5217H12.75C13.1642 15.5217 13.5 15.1908 13.5 14.7826V10.3478C13.5 9.93962 13.1642 9.6087 12.75 9.6087H8.25C8.05109 9.6087 7.86032 9.53082 7.71967 9.39221L6.43934 8.13043H2.25ZM0 8.86957C0 7.64493 1.00736 6.65217 2.25 6.65217H6.75C6.94891 6.65217 7.13968 6.73005 7.28033 6.86866L8.56066 8.13043H12.75C13.9926 8.13043 15 9.12319 15 10.3478V14.7826C15 16.0072 13.9926 17 12.75 17H2.25C1.00736 17 0 16.0072 0 14.7826V8.86957Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M3.11649 3.77495C3.05479 3.84727 3 3.9693 3 4.12681L3 7.03514C3 7.44335 2.66421 7.77427 2.25 7.77427C1.83579 7.77427 1.5 7.44335 1.5 7.03514L1.5 4.12681C1.5 3.19757 2.16617 2.21739 3.24841 2.21739L6.03685 2.21739C6.3495 2.21739 6.63249 2.33959 6.84528 2.53055C6.86044 2.54415 6.87397 2.55678 6.88425 2.56645L6.90915 2.58994C6.91558 2.59597 6.91902 2.59907 6.92031 2.60023L10.7706 5.6775C10.8273 5.72284 10.9354 5.81336 11.0258 5.95093C11.1757 6.17901 11.25 6.44544 11.25 6.70977L11.25 8.86957C11.25 9.27778 10.9142 9.6087 10.5 9.6087C10.0858 9.6087 9.75 9.27778 9.75 8.86957L9.75 6.76508L5.97302 3.74641C5.95063 3.72852 5.93034 3.71105 5.91308 3.69565L3.24841 3.69565C3.22643 3.69565 3.17684 3.70421 3.11649 3.77495Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M5.36649 1.55756C5.30479 1.62988 5.25 1.75191 5.25 1.90942V2.60035C5.25 3.00857 4.91421 3.33949 4.5 3.33949C4.08579 3.33949 3.75 3.00856 3.75 2.60035V1.90942C3.75 0.980176 4.41617 -1.86482e-07 5.49841 0L8.28685 4.80481e-07C8.5995 5.34353e-07 8.88249 0.1222 9.09528 0.313155C9.11044 0.326763 9.12397 0.339391 9.13425 0.349061L9.15915 0.372547C9.16558 0.378579 9.16902 0.38168 9.17031 0.382837L13.0206 3.46011C13.0773 3.50545 13.1854 3.59597 13.2758 3.73354C13.4257 3.96162 13.5 4.22805 13.5 4.49238L13.5 8.86957C13.5 9.27778 13.1642 9.6087 12.75 9.6087C12.3358 9.6087 12 9.27778 12 8.86957L12 4.54769L8.22302 1.52902C8.20064 1.51113 8.18034 1.49366 8.16308 1.47826L5.49841 1.47826C5.47643 1.47826 5.42684 1.48682 5.36649 1.55756Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default PortfolioIcon;
