import { TypedUseSelectorHook, shallowEqual, useSelector } from 'react-redux';
import { RootState } from '..';
import { Flow } from './types';

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

// Flow
export function useSelectFlow() {
  return useTypedSelector((state) => state.uaas.flow, shallowEqual) as Flow;
}

//Execution
export function useSelectExecution() {
  return useTypedSelector(
    (state) => state.uaas.execution,
    shallowEqual
  ) as Flow;
}
