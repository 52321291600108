import React from 'react';
import { IconProps } from '../iconTypes';

const RepaymentsIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 12"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M3 0.000244141C1.34315 0.000244141 0 1.34339 0 3.00024L5.24537e-07 9.00024C5.24537e-07 10.6571 1.34315 12.0002 3 12.0002H13C13.5001 12 14.0003 12 14.0001 11.5C14 11.3126 14 11 14 11C14.0001 10.5 13.3649 10.5 13 10.5H3.66269C3.57102 9.94031 3.56535 9.43254 3.13305 9.00024C2.6666 8.53379 2.10725 8.56995 1.50014 8.5L1.50014 3.5C2.10725 3.43005 2.53368 3.46645 3.00014 3C3.4666 2.53354 3.43019 2.10711 3.50014 1.5L13 1.5C13.4029 1.5 14.0005 1.5 14.0001 1C13.9998 0.5 14.0005 0.999998 14.0001 0.499999C13.9998 0 13 0.00024422 13 0.00024422L3 0.000244141Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M9.93195 4.5999C9.21588 4.5999 8.60016 5.20691 8.60016 5.9999C8.60016 6.7929 9.21588 7.3999 9.93195 7.3999C10.2003 7.3999 10.4498 7.31703 10.66 7.17234C10.9329 6.98445 11.3065 7.05341 11.4944 7.32636C11.6823 7.59931 11.6133 7.9729 11.3404 8.16079C10.9386 8.43734 10.4535 8.5999 9.93195 8.5999C8.51423 8.5999 7.40016 7.41605 7.40016 5.9999C7.40016 4.58376 8.51423 3.3999 9.93195 3.3999C10.3963 3.3999 10.8322 3.5288 11.2061 3.75272C11.4904 3.92298 11.5828 4.29146 11.4126 4.57575C11.2423 4.86003 10.8739 4.95247 10.5896 4.78221C10.3952 4.66579 10.1713 4.5999 9.93195 4.5999Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M16.4244 2.57564C16.6587 2.80995 16.6587 3.18985 16.4244 3.42417L14.4487 5.3999H19.0002C19.3315 5.3999 19.6002 5.66853 19.6002 5.9999C19.6002 6.33127 19.3315 6.5999 19.0002 6.5999H14.4487L16.4244 8.57564C16.6587 8.80995 16.6587 9.18985 16.4244 9.42417C16.1901 9.65848 15.8102 9.65848 15.5759 9.42417L12.5759 6.42417C12.3416 6.18985 12.3416 5.80995 12.5759 5.57564L15.5759 2.57564C15.8102 2.34132 16.1901 2.34132 16.4244 2.57564Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default RepaymentsIcon;
