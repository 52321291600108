import { rootApi } from './rootApi';
import type { Notification } from '@/interfaces/notifications';

const baseNotificationsApi = '/api/notifications';

export const notificationsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getNotifications: builder.query<Notification[], void>({
      query: () => baseNotificationsApi,
      providesTags: ['Notifications'],
    }),
    getAllUserNotifications: builder.query<Notification[], { email: string }>({
      query: ({ email }) => ({
        url: baseNotificationsApi + '/user',
        method: 'GET',
        params: {
          email,
        },
      }),
      providesTags: ['Notifications'],
    }),
    readNotificationById: builder.mutation({
      query: (id) => ({
        url: baseNotificationsApi + `/user/${id}`,
        method: 'PATCH',
        params: {
          id,
        },
      }),
      invalidatesTags: ['Notifications'],
    }),
    readBulkNotifications: builder.mutation({
      query: (bulk: string[]) => ({
        url: baseNotificationsApi + '/user/bulk-mark-as-read',
        method: 'PATCH',
        body: {
          bulkMarkNotificationAsRead: bulk,
        },
      }),
      invalidatesTags: ['Notifications'],
    }),
    deleteNotification: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: baseNotificationsApi + `/user/${id}`,
        method: 'DELETE',
        params: {
          id,
        },
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetAllUserNotificationsQuery,
  useReadNotificationByIdMutation,
  useReadBulkNotificationsMutation,
  useDeleteNotificationMutation,
} = notificationsApi;
