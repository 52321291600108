import { baseAccountsApi, usePatchUserMutation } from '.';
import { rootApi } from '../rootApi';
import {
  CreateUnderwritingBorrowerDto,
  User,
} from 'kennek/interfaces/accounts';
import type {
  BorrowerOnboardingTaskStatus,
  BorrowerPayload,
  NoticePaymentPayload,
  UpdateCompany,
  ValidateBorrowerCompany,
  ValidateBorrowerCompanyResponse,
} from '@/interfaces/accounts/borrower';
import { OnboardingTasks } from '@/interfaces/loans';

export const borrowerApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createBorrower: builder.mutation<User, BorrowerPayload>({
      query: (payload: BorrowerPayload) => ({
        url: baseAccountsApi + '/borrower',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Users', 'Loans']),
    }),

    createBorrowerUnderwriting: builder.mutation({
      query: (payload: CreateUnderwritingBorrowerDto) => ({
        url: baseAccountsApi + `/borrower/create-borrower-underwriting`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (...[, error]) =>
        error ? [] : ['Underwriting-Execution'],
    }),

    updateBorrowerCompanyAccount: builder.mutation({
      query: (companyInfo: UpdateCompany) => ({
        url: baseAccountsApi + '/borrower/company',
        method: 'PUT',
        body: companyInfo,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Companies', 'Loans']),
    }),
    patchOnboardingTaskStatus: builder.mutation({
      query: (payload: BorrowerOnboardingTaskStatus) => ({
        url: baseAccountsApi + '/borrower/onboarding-task-status',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Loans']),
    }),
    getOnboardingTaskStatuses: builder.query<
      OnboardingTasks[],
      { loanId?: string; borrowerEmail?: string }
    >({
      query: (payload: {
        loanId?: string;
        borrowerEmail?: string;
        executionId?: string;
      }) => ({
        url: baseAccountsApi + '/borrower/onboarding-tasks-statuses',
        method: 'GET',
        params: payload,
      }),
      providesTags: ['Originator-Investors'],
    }),
    getBorrowerOriginatorName: builder.query({
      query: () => ({
        url: baseAccountsApi + '/borrower/originator',
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    noticeBorrowerPayment: builder.mutation({
      query: (payload: NoticePaymentPayload) => ({
        url: baseAccountsApi + '/borrower/borrower-payment-notice',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Users', 'Loans']),
    }),
    validateCompanyCreation: builder.mutation<
      ValidateBorrowerCompanyResponse,
      ValidateBorrowerCompany
    >({
      query: (payload: ValidateBorrowerCompany) => ({
        url: baseAccountsApi + '/borrower/validate-company-onboarding',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Users', 'Loans']),
    }),
  }),
});

export const {
  useCreateBorrowerMutation,
  useCreateBorrowerUnderwritingMutation,
  usePatchOnboardingTaskStatusMutation,
  useUpdateBorrowerCompanyAccountMutation,
  useGetBorrowerOriginatorNameQuery,
  useNoticeBorrowerPaymentMutation,
  useValidateCompanyCreationMutation,
  useGetOnboardingTaskStatusesQuery,
} = borrowerApi;

export { usePatchUserMutation };
