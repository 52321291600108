import { useEffect, useRef } from 'react';
import { Option, PaginationSettings } from '../Select';
import { ITEM_HEIGHT } from '../configConst';
import OptionsItem from './OptionItem';
import VirtualScrollSelectOptions from './VirtualScrollSelectOptions';

interface SelectOptionsProps {
  selectOptions: Option[];
  selected?: Option;
  selectedStyle: string;
  activeStyle: string;
  selectWidth: number | undefined;
  selectOptionsHeight: number;
  pagination?: PaginationSettings;
}

const SelectOptions = ({
  selectOptions,
  selected,
  selectedStyle,
  activeStyle,
  selectWidth,
  selectOptionsHeight,
  pagination,
}: SelectOptionsProps) => {
  const virtualScrollRef = useRef<{
    scrollToIndex: (index: number) => void;
  } | null>(null);
  useEffect(() => {
    if (selected && virtualScrollRef.current) {
      const selectedIndex = selectOptions.findIndex(
        (option) => option.value === selected.value
      );
      if (selectedIndex !== -1)
        virtualScrollRef.current.scrollToIndex(selectedIndex);
    }
  }, [selected]);

  return (
    <VirtualScrollSelectOptions<Option>
      ref={virtualScrollRef}
      items={selectOptions}
      itemHeight={ITEM_HEIGHT}
      pagination={pagination}
      selectOptionsHeight={selectOptionsHeight}
      renderItem={(option: Option) => (
        <OptionsItem
          option={option}
          activeStyle={activeStyle}
          selectedStyle={selectedStyle}
          selectWidth={selectWidth}
        />
      )}
    />
  );
};

export default SelectOptions;
