import React from 'react';
import { Badge } from 'ui';
import { BadgeSectionProps } from './BadgeSection.props';
import { Severity } from 'ui/types';

const getBadgeColor = (type: Severity): string => {
  return type === 'error' ? 'error-500' : 'success-500';
};

const BadgeSection: React.FC<BadgeSectionProps> = ({
  color,
  label,
  title,
  type,
}) => {
  return (
    <div className="flex justify-between">
      <p className="heading-400">{title}</p>
      <Badge
        className="capitalize h-5 rounded-full px-2"
        color={color ?? getBadgeColor(type)}
        type={type}
      >
        {label}
      </Badge>
    </div>
  );
};

export default BadgeSection;
