import { User, UserTypes } from 'kennek/interfaces/accounts';
import { ApprovedLoan } from '@/interfaces/loans/queries';

const useUserRole = (
  baseRole: UserTypes,
  user: User,
  loans?: ApprovedLoan[]
): UserTypes | 'BORROWER_UNDERWRITING' => {
  if (baseRole !== 'BORROWER') return baseRole;
  if (user?.borrowerExecutionIds?.length > 0 && !loans?.length)
    return 'BORROWER_UNDERWRITING';
  return 'BORROWER';
};

export default useUserRole;
