import React from 'react';
import { Control, Controller } from 'react-hook-form';
import FormInput from 'ui/components/FormInput';
import { cn } from 'ui/utils';
import { InvestmentsFormData } from '../InvestmentsForm.types';

type InvestmentsFormPercentageInputProps = {
  control: Control<InvestmentsFormData, unknown>;
  index: number;
  disabled?: boolean;
  error?: string;
};

const sumPercentageOverAllErr =
  'The sum of investment percentage cannot be more than 100%';

export const InvestmentsFormPercentageInput: React.FC<
  InvestmentsFormPercentageInputProps
> = (props) => {
  return (
    <Controller
      name={`investments.${props.index}.percentage`}
      control={props.control}
      rules={{
        required: 'Percentage is required',
        min: {
          value: 0,
          message: `Percentage cannot be less than 0%`,
        },
        max: {
          value: 100,
          message: `Percentage cannot exceed 100%`,
        },
        validate: (_, formValues) => {
          const amountsSum = formValues.investments
            .map((investment) =>
              isNaN(parseFloat(investment.percentage))
                ? 0
                : parseFloat(investment.percentage)
            )
            .reduce((acc, percentage) => acc + percentage, 0);
          return amountsSum <= 100 || sumPercentageOverAllErr;
        },
      }}
      render={({ field }) => (
        <FormInput
          value={field.value}
          onBlur={field.onBlur}
          onChange={(e) => field.onChange(e)}
          leftSymbol="%"
          type="number"
          customLeftPadding="7"
          errorBottomPosition={`bottom-${props.error === sumPercentageOverAllErr ? '1' : '4'}`}
          containerClassName={cn('w-[140px] mt-[0px]', {
            'mb-[24px]': !!props.error,
          })}
          errors={props.error}
          disabled={props.disabled}
        />
      )}
    />
  );
};
