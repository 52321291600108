export const FullCopyrightStatement = () => {
  return (
    <div>
      <p className="heading-100 text-neutral-700">
        © Kennek Solutions Limited {new Date().getFullYear()} all rights
        reserved
      </p>
      <p className="body-100 text-neutral-600 leading-4">
        <br />
        Confidential- limited distribution to authorised persons only, pursuant
        to Kennek's legal terms.
        <br />
        This constitutes a trade secret of Kennek Solutions Limited, 25 Wilton
        Road, London SW1V 1 LW.
      </p>
    </div>
  );
};
