import { createSlice } from '@reduxjs/toolkit';
import { DebitInfoInput } from 'kennek/interfaces/accounts';
import type { GetLoanData, LoanSummary } from '@/interfaces/loans/queries';

export interface BorrowerState {
  approvedLoans: GetLoanData[];
  loanSummary: LoanSummary;
  debitInfo: Partial<DebitInfoInput>;
}

const initialState = {
  approvedLoans: [],
  debitInfo: {},
  loanSummary: {},
} as BorrowerState;

const borrowerSlice = createSlice({
  name: 'borrower',
  initialState,
  reducers: {
    setDebitInfo(state, action): void {
      state.debitInfo = {
        ...state.debitInfo,
        ...action.payload,
      };
    },
    clearDebitInfo(state): void {
      state.debitInfo = {};
    },
  },
});

export const { setDebitInfo, clearDebitInfo } = borrowerSlice.actions;

export default borrowerSlice.reducer;
