import React from 'react';
import { IconProps } from '../iconTypes';

const FiltersIcon: React.FC<IconProps> = (props) => (
  <svg
    width="17"
    height="10"
    viewBox="0 0 17 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.03448C5.12999e-08 0.463153 0.475697 -4.99471e-08 1.0625 0L15.9375 2.1047e-06C16.5243 2.15465e-06 17 0.463156 17 1.03448C17 1.60581 16.5243 2.06897 15.9375 2.06897L1.0625 2.06896C0.475697 2.06896 -5.12999e-08 1.60581 0 1.03448Z"
      fill="#184083"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3958 6.2069L8.14583 6.2069L3.89583 6.2069C3.30903 6.2069 2.83333 5.74374 2.83333 5.17242C2.83333 4.60109 3.30903 4.13793 3.89583 4.13793L8.14583 4.13793L12.3958 4.13793C12.9826 4.13793 13.4583 4.60109 13.4583 5.17241C13.4583 5.74374 12.9826 6.2069 12.3958 6.2069Z"
      fill="#184083"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.02083 8.96552C6.02083 8.39419 6.49653 7.93104 7.08333 7.93104H9.20833C9.79514 7.93104 10.2708 8.39419 10.2708 8.96552C10.2708 9.53685 9.79514 10 9.20833 10H7.08333C6.49653 10 6.02083 9.53685 6.02083 8.96552Z"
      fill="#184083"
    />
  </svg>
);

export default FiltersIcon;
