import { SvgIcon } from 'kennek/icons';

interface Props {
  svgIconName?: 'LockClosedIcon';
  message?: string;
}

const InaccessibleResource = ({
  svgIconName = 'LockClosedIcon',
  message = 'This page is not available currently',
}: Props) => {
  return (
    <div className="grid w-100 m-auto">
      <div className="grid w-[48px] h-[48px] bg-neutral-100 rounded-full m-auto">
        <div className="w-[24px] h-[24px] m-auto">
          <SvgIcon
            name={svgIconName}
            className="static w-[18px] h-[20px] m-auto"
          />
        </div>
      </div>
      <span className="text-neutral-600 caption flex py-2 justify-center items-center">
        {message}
      </span>
    </div>
  );
};

export default InaccessibleResource;
