import React from 'react';
import { IconProps } from '../iconTypes';

const StatementIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M6 3.00037L6 2.00037C5.44772 2.00037 5 2.44808 5 3.00037L6 3.00037ZM20 3.00036L21 3.00036C21 2.44808 20.5523 2.00036 20 2.00036L20 3.00036ZM18.25 20.0004L7.75 20.0004L7.75 22.0004L18.25 22.0004L18.25 20.0004ZM7 19.0004L7 3.00037L5 3.00037L5 19.0004L7 19.0004ZM19 3.00036L19 19.0004L21 19.0004L21 3.00036L19 3.00036ZM7.75 20.0004C7.45704 20.0004 7 19.6823 7 19.0004L5 19.0004C5 20.5276 6.10997 22.0004 7.75 22.0004L7.75 20.0004ZM18.25 22.0004C19.89 22.0004 21 20.5276 21 19.0004L19 19.0004C19 19.6823 18.543 20.0004 18.25 20.0004L18.25 22.0004ZM6 4.00037L20 4.00036L20 2.00036L6 2.00037L6 4.00037Z"
      fill="currentColor"
    />
    <mask id="path-2-inside-1_734_1196" fill="white">
      <path d="M2 16.0002C2 15.448 2.44772 15.0002 3 15.0002H15C15.5523 15.0002 16 15.448 16 16.0002V22.0002H5C3.34315 22.0002 2 20.6571 2 19.0002V16.0002Z" />
    </mask>
    <path
      d="M2 16.0002C2 15.448 2.44772 15.0002 3 15.0002H15C15.5523 15.0002 16 15.448 16 16.0002V22.0002H5C3.34315 22.0002 2 20.6571 2 19.0002V16.0002Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="4"
      mask="url(#path-2-inside-1_734_1196)"
    />
    <path
      d="M9 8.00024C9 7.44796 9.44772 7.00024 10 7.00024H16C16.5523 7.00024 17 7.44796 17 8.00024C17 8.55253 16.5523 9.00024 16 9.00024H10C9.44772 9.00024 9 8.55253 9 8.00024Z"
      fill="currentColor"
    />
    <rect x="9" y="10.0002" width="8" height="2" rx="1" fill="currentColor" />
    <rect x="16" y="18.0002" width="3" height="2" fill="currentColor" />
    <rect x="16" y="14.0002" width="3" height="6" rx="1.5" fill="white" />
  </svg>
);

export default StatementIcon;
