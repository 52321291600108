export interface Facility extends FacilityForm {
  updatedAt?: Date;
  createdAt?: Date;
  _id?: string;
  branchEncodedKey?: string;
  productEncodedKey?: string;
  name?: string;
  id?: string;
  loanFacilityId?: string;
}

export interface FacilityForm {
  name?: string;
  productEncodedKey?: string;
}

export interface FacilityPayload {
  branchEncodedKey: string;
  productEncodedKey: string;
  name: string;
}

export interface LoanFacilityForm {
  id?: string;
  amount?: number;
  mambuLoanEncodedKey?: string;
}

export enum PaymentFacilityStatus {
  ACTIVE = 'ACTIVE',
}

export interface LoanFacilityPayment {
  amount: number;
  transactionEncodedKey: string;
  status: PaymentFacilityStatus;
}

export interface LoanFacility extends LoanFacilityForm {
  updatedAt?: Date;
  mambuLoanId?: string;
  createdAt?: Date;
  _id?: string;
  branchEncodedKey?: string;
  productEncodedKey?: string;
  name?: string;
  disbursementBasedPaidPercentage?: string;
  facilityId?: string;
  payments?: LoanFacilityPayment[];
  allocations?: LoanFacilityAllocation[];
  selectId?: string;
}

export interface RowLoanFacility extends Facility {
  rowLoaded?: boolean;
  amount?: string;
  name?: string;
  facilityId?: string;
  mambuLoanId?: string;
  mambuLoanEncodedKey?: string;
  selectId?: string;
}

export interface LoanFacilityPayload {
  mambuLoanId?: string;
  amount?: number;
  mambuLoanEncodedKey?: string;
  facilityId?: string;
}

export interface LoanFacilityAllocation {
  amount: number;
  type: 'KENNEK_TRANCHE_ALLOCATION' | 'MAMBU_TRANCHE_ALLOCATION';
  disbursementTransactionKey: string;
  kennekTrancheId: string;
  mambuTrancheId: string;
  status: LoanFacilityAllocationStatus;
}

export enum LoanFacilityAllocationStatus {
  PENDING_DISBURSEMENT = 'PENDING_DISBURSEMENT',
  DISBURSED = 'DISBURSED',
}
