import React from 'react';
import { IconProps } from '../iconTypes';

const MyFolder: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 20"
    aria-hidden="true"
    {...props}
  >
    <g id="Fill / Folder">
      <path
        id="Subtract"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V8C18 6.89543 17.1046 6 16 6H11L9 4H4ZM8.57143 8.2C8.57143 8.86274 8.09173 9.4 7.5 9.4C6.90827 9.4 6.42857 8.86274 6.42857 8.2C6.42857 7.53726 6.90827 7 7.5 7C8.09173 7 8.57143 7.53726 8.57143 8.2ZM7.5 10.2C6.11929 10.2 5 11.4536 5 13H10C10 11.4536 8.88071 10.2 7.5 10.2Z"
        fill="#64748B"
      />
    </g>
  </svg>
);

export default MyFolder;
