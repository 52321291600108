import { ReactNode } from 'react';
import {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues,
  PathValue,
  useWatch,
} from 'react-hook-form';

const Watch = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  children,
  ...watchProps
}: {
  children: (watched: PathValue<TFieldValues, TFieldName>) => ReactNode;
  name: TFieldName;
  defaultValue?: FieldPathValue<TFieldValues, TFieldName>;
  control?: Control<TFieldValues>;
  disabled?: boolean;
  exact?: boolean;
}) => {
  // watch inside component to avoid rerender of parent
  const watched = useWatch(watchProps);

  return <>{children(watched as PathValue<TFieldValues, TFieldName>)}</>;
};

export { Watch };
