import React from 'react';
import { IconProps } from '../iconTypes';

const ChainIcon: React.FC<IconProps> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1033 3.50333C14.0406 2.56608 15.5602 2.56608 16.4974 3.50333C17.4347 4.44059 17.4347 5.96019 16.4974 6.89745L12.8974 10.4974C11.9602 11.4347 10.4406 11.4347 9.50331 10.4974C9.03469 10.0288 8.27489 10.0288 7.80626 10.4974C7.33763 10.9661 7.33763 11.7259 7.80626 12.1945C9.68077 14.069 12.72 14.069 14.5945 12.1945L18.1945 8.5945C20.069 6.71999 20.069 3.68079 18.1945 1.80628C16.32 -0.0682385 13.2808 -0.0682385 11.4063 1.80628L9.60626 3.60628C9.13763 4.07491 9.13763 4.83471 9.60626 5.30333C10.0749 5.77196 10.8347 5.77196 11.3033 5.30333L13.1033 3.50333Z"
      fill="#0E2736"
    />
    <path
      d="M7.10333 9.50333C8.04059 8.56608 9.56019 8.56608 10.4974 9.50333C10.9661 9.97196 11.7259 9.97196 12.1945 9.50333C12.6631 9.03471 12.6631 8.27491 12.1945 7.80628C10.32 5.93176 7.28079 5.93176 5.40628 7.80628L1.80628 11.4063C-0.0682385 13.2808 -0.0682385 16.32 1.80628 18.1945C3.68079 20.069 6.71999 20.069 8.5945 18.1945L10.3945 16.3945C10.8631 15.9259 10.8631 15.1661 10.3945 14.6974C9.92587 14.2288 9.16608 14.2288 8.69745 14.6974L6.89745 16.4974C5.96019 17.4347 4.44059 17.4347 3.50333 16.4974C2.56608 15.5602 2.56608 14.0406 3.50333 13.1033L7.10333 9.50333Z"
      fill="#0E2736"
    />
  </svg>
);

export default ChainIcon;
