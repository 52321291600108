export const isEmpty = <T>(
  value: T | undefined | null
): value is undefined | null => value === undefined || value === null;

export const isEmptyString = (value: string | number) => value === '';

export const isZero = (value: number | string) => value === 0;

export const isEmptyOrZero = (value: number | string) =>
  isEmpty(value) || isEmptyString(value) || isZero(value);

export const isNotEmpty = <T>(value: T | undefined | null): value is T =>
  !isEmpty(value);

export const arrayNotEmpty = <T>(
  value: T[] | undefined | null
): value is T[] => {
  return !isEmpty(value) && value.length > 0;
};

export const arrayEmpty = <T>(value: T[] | undefined | null) => {
  return !arrayNotEmpty(value);
};

export const areEmpty = (...values: unknown[]) =>
  values.every((value) => isEmpty(value));

export const areNotEmpty = (...values: unknown[]) =>
  values.every((value) => isNotEmpty(value));
