import { DocumentData } from 'kennek/interfaces/documentManagement';
import { BorrowerDocuments } from '@/interfaces/loans';

export const uploadFilesToBorrowerDocs = (
  documents: DocumentData[]
): BorrowerDocuments[] => {
  return documents?.map((x) => {
    return {
      draft: x.isDraft,
      createdAt: x.createdAt,
      fileName: x.name,
      mimetype: x.mimeType,
      size: x.size,
      storageKey: x.storageKey,
      updatedAt: x.updatedAt,
      _id: x.id,
      isDraftDeleted: !!x.isDraftDeleted,
    };
  });
};

export const borrowerDocsToUploadFiles = (
  documents: BorrowerDocuments[]
): DocumentDataExtended[] => {
  return documents?.map((x) => {
    return {
      id: x._id,
      storageKey: x.storageKey,
      name: x.fileName,
      mimeType: x.mimetype,
      size: x.size,
      isDraft: x.draft,
      createdAt: x.createdAt,
      updatedAt: x.updatedAt,
      uploadedByUserName: x.uploadedByUserName,
      isDraftDeleted: !!x.isDraftDeleted,
    };
  });
};

export const removeFileExtension = (name: string) => {
  const lastDotIndex = name.lastIndexOf('.');
  return {
    name: lastDotIndex !== -1 ? name.substring(0, lastDotIndex) : name,
    extension: name.substring(lastDotIndex) || '',
  };
};

export interface DocumentDataExtended extends DocumentData {
  uploadedByUserName: string;
}

export const isBorrowerDocuments = (
  files: DocumentDataExtended[] | BorrowerDocuments[]
): files is BorrowerDocuments[] => {
  if (!files) return;
  return (files as BorrowerDocuments[])[0]?.fileName !== undefined;
};

export enum DocumentManagementRowType {
  FOLDER = 'folder',
  USER_FOLDER = 'user_folder',
  USER_FOLDER_EDIT = 'user_folder_edit',
  USER_FOLDER_DRAFT_DELETE = 'user_folder_draft_delete',
  USER_FOLDER_EDIT_CORE = 'user_folder_edit_core',
  FILE = 'file',
  FILE_EDIT = 'file_edit',
  FILE_EDIT_CORE = 'file_edit_core',
  FILE_DRAFT_DELETE = 'file_draft_delete',
}

export interface PathData {
  id: string;
  label: string;
  originalName?: string;
}

export const createPathString = (pathData: PathData[]): string => {
  return pathData
    .map(({ originalName, id }, index) => {
      if (id === 'loan') return '';
      const isLast = pathData.length === index + 1;
      return `${originalName}${isLast ? '' : '/'}`;
    })
    .join('');
};
