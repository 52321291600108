import React from 'react';
import { Dialog } from 'ui';

interface Props {
  open: boolean;
  onClose: () => void;
  termsAndConditionsText: string;
  title?: string;
  logo?: React.ReactNode;
}

const TermsAndConditions: React.FC<Props> = ({
  open,
  onClose,
  termsAndConditionsText,
  title = 'Terms and Conditions',
  logo = false,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="w-[779px] max-h-[497px]">
        <div className="flex justify-between">
          <div>{title}</div>
          {logo && <div className="mr-7">{logo}</div>}
        </div>

        <div className="body-300 text-left self-start max-h-[325px] my-6 pr-6 whitespace-pre-line overflow-x-hidden scrollbar-thin scrollbar-thumb-neutral-400 scrollbar-track-neutral-200 overflow-y-scroll scrollbar-thumb-rounded">
          {termsAndConditionsText}
        </div>
      </div>
    </Dialog>
  );
};

export { TermsAndConditions };
