import { Cell, CellProps, Column, Row } from 'react-table';
import { colorDefault } from 'ui/theme/defaultColors';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import CircleButton from '../../CircleButton';
import { Body } from './TableBody';
import { TableProps } from './TableProps';

export function ExtendableBody<R>(
  props: TableProps<R> & {
    rowMapper?: (row: object) => object;
    expandButtonColumnTransform?: {
      colSpan: number;
      columnMapper: (row: object) => JSX.Element;
    };
  }
) {
  function anyCellsToAdjust(cells: Cell<object, any>[]): boolean {
    return cells.some(
      (cell: Cell<object, any>) =>
        cell.column.colSpan && cell.column.colSpan > 1
    );
  }

  function getAdjustedCells(row: Row<object>): Cell<object, any>[] {
    const cellsAfterColspan: Cell<object, any>[] = [];

    for (let i = 0; i < row.cells.length; ) {
      const cell: any = row.cells[i];
      cellsAfterColspan.push(cell);

      i += cell.column.colSpan > 1 && row.depth === 1 ? cell.column.colSpan : 1;
    }

    return cellsAfterColspan;
  }

  function filterCells(row: Row<object>): Cell<object, any>[] {
    if (!anyCellsToAdjust(row.cells)) {
      return row.cells;
    }

    return getAdjustedCells(row);
  }

  function specifyColumnSpan(rowDepth: number, columnSpan?: number): number {
    if (rowDepth !== 1) {
      return 1;
    }

    return columnSpan ?? 1;
  }

  const expandButtonColumn = {
    Header: ' ',
    accessor: '',
    Cell: ({ row }: CellProps<object>) => {
      return row.canExpand ? (
        <div {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <CircleButton>
              <ChevronUpIcon stroke={colorDefault.neutral[800]} />
            </CircleButton>
          ) : (
            <CircleButton>
              <ChevronDownIcon stroke={colorDefault.neutral[800]} />
            </CircleButton>
          )}
        </div>
      ) : props.expandButtonColumnTransform ? (
        props.expandButtonColumnTransform.columnMapper(row)
      ) : null;
    },
    disableFilters: true,
    minWidth: 40,
    maxWidth: 40,
    width: 40,
    sorting: false,
    disableSortBy: true,
    colSpan: props.expandButtonColumnTransform
      ? props.expandButtonColumnTransform.colSpan
      : 1,
  };

  function createExtendendColumnSet(props: TableProps<R>): Column<object>[] {
    return [expandButtonColumn, ...props.columns];
  }

  function mapToSubRows(rows: object[]): object[] {
    if (!props.rowMapper) {
      return rows;
    }

    return rows.map(props.rowMapper);
  }

  function createUpdatedProps(): TableProps<R> {
    return {
      ...props,
      filterCells,
      specifyColumnSpan,
      columns: createExtendendColumnSet(props),
      data: mapToSubRows(props.data),
    };
  }

  return <Body {...createUpdatedProps()} />;
}
