import React from 'react';
import { IconProps } from '../iconTypes';

const BankIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 19"
    aria-hidden="true"
    width="16px"
    height="16px"
    {...props}
  >
    <rect width="48" height="48" rx="24" fill="#F1F5F9" />
    <path
      d="M10.5956 0.558102C10.2267 0.347291 9.77378 0.347291 9.40486 0.558102L1.00486 5.3581C0.429442 5.68691 0.229526 6.41994 0.558338 6.99536C0.779598 7.38257 1.18389 7.59974 1.60002 7.6002V16C0.937283 16 0.400024 16.5373 0.400024 17.2C0.400024 17.8627 0.937283 18.4 1.60002 18.4H18.4C19.0628 18.4 19.6 17.8627 19.6 17.2C19.6 16.5373 19.0628 16 18.4 16V7.6002C18.8163 7.59988 19.2208 7.38269 19.4421 6.99536C19.7709 6.41994 19.571 5.68691 18.9956 5.3581L10.5956 0.558102ZM5.20002 8.79999C4.53728 8.79999 4.00002 9.33725 4.00002 9.99999V13.6C4.00002 14.2627 4.53728 14.8 5.20002 14.8C5.86277 14.8 6.40002 14.2627 6.40002 13.6V9.99999C6.40002 9.33725 5.86277 8.79999 5.20002 8.79999ZM8.80002 9.99999C8.80002 9.33725 9.33728 8.79999 10 8.79999C10.6628 8.79999 11.2 9.33725 11.2 9.99999V13.6C11.2 14.2627 10.6628 14.8 10 14.8C9.33728 14.8 8.80002 14.2627 8.80002 13.6V9.99999ZM14.8 8.79999C14.1373 8.79999 13.6 9.33725 13.6 9.99999V13.6C13.6 14.2627 14.1373 14.8 14.8 14.8C15.4628 14.8 16 14.2627 16 13.6V9.99999C16 9.33725 15.4628 8.79999 14.8 8.79999Z"
      fill="#1E293B"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default BankIcon;
