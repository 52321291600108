import React from 'react';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/outline';

interface SelectorProps {
  isOpen: boolean;
  readOnly?: boolean;
  selected: { companyName?: string; companyNumber?: string; id?: string };
  borrower?: boolean;
}

const Selector: React.FC<SelectorProps> = ({
  readOnly,
  selected,
  isOpen,
  borrower,
}) => {
  return (
    <div
      className={classNames(
        'flex justify-between px-8 py-5 rounded-full cursor-pointer gap-1.5 relative text-secondary-400',
        {
          'cursor-default text-neutral-800': readOnly,
          'hover:bg-neutral-100': !readOnly,
          'bg-neutral-100': isOpen,
        }
      )}
    >
      <div className="flex gap-1.5">
        <p className="heading-400 overflow-hidden text-ellipsis whitespace-nowrap text-left">
          {selected?.companyName}
        </p>
        <span className="heading-200 text-left pt-[1.5px]">
          {borrower ? selected?.id : selected?.companyNumber}
        </span>
      </div>
      {!readOnly ? (
        <ChevronDownIcon className="w-5 h-5 text-secondary-400" />
      ) : null}
    </div>
  );
};

export default Selector;
