import { FC } from 'react';
import { Dialog } from 'ui';
import { ExclamationIcon } from '@heroicons/react/solid';
import { UseConfirmDialogResult } from '../../../../hooks/useConfirmDialog';
import { DeleteInvestorModal } from '../DeleteInvestorModal';

export interface DeleteInvestmentDialogOptions {
  investorName: string;
}

interface DeleteInvestmentDialogProps {
  isOpen: boolean;
  dialogOptions: DeleteInvestmentDialogOptions;
  closeDialog: (result: UseConfirmDialogResult) => void;
}

export const DeleteInvestmentDialog: FC<DeleteInvestmentDialogProps> = ({
  isOpen,
  dialogOptions,
  closeDialog,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => closeDialog({ confirmed: false })}
      dialogIconColor="destructive"
      dialogIcon={<ExclamationIcon />}
    >
      {dialogOptions && (
        <DeleteInvestorModal
          onClose={() => closeDialog({ confirmed: false })}
          investorName={dialogOptions.investorName}
          onConfirmDelete={() => closeDialog({ confirmed: true })}
        />
      )}
    </Dialog>
  );
};
