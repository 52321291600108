import classNames from 'classnames';
import { intervalToDuration } from 'date-fns';
import { SvgIcon } from 'kennek/icons';
import { Button } from 'ui';
import * as Dialog from '@radix-ui/react-dialog';

type SignOutModalProps = {
  open: boolean;
  remainingSeconds: number;
  onStaySignedIn: () => void;
};

const SignOutModal = ({
  open,
  remainingSeconds,
  onStaySignedIn,
}: SignOutModalProps) => {
  const remainingTime = intervalToDuration({
    start: 0,
    end: remainingSeconds * 1000,
  });
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay
          className={classNames(
            'fixed',
            'inset-0',
            'z-[500]',
            'bg-neutral-800',
            'bg-opacity-60',
            'overflow-y-auto',
            'flex',
            'justify-center',
            'items-start',
            'py-28'
          )}
        >
          <Dialog.Content
            className="w-[384px] bg-white p-[30px] rounded-md relative space-y-4"
            onOpenAutoFocus={(e) => e.preventDefault()}
          >
            <div className="flex justify-center">
              <SvgIcon name="ClockIcon" />
            </div>

            <Dialog.Title className="heading-300 text-center">
              Session expires in {remainingTime.minutes}:
              {remainingTime.seconds?.toString().padStart(2, '0')}
            </Dialog.Title>

            <p className="body-400 text-neutral-700 text-center">
              For security reasons, due to inactivity you will be logged out.
            </p>

            <Button
              layout="primary"
              className="w-full !heading-200"
              onClick={() => onStaySignedIn()}
            >
              Stay signed in
            </Button>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export { SignOutModal };
