import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, FormInput, Label, Textarea, Title } from 'ui';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CounterpartyFormData,
  HeaderIcon,
  counterpartyFromValidationSchema,
} from './CounterpartyForm.utils';

export type CounterpartyFormProps = {
  isLoading: boolean;
  onSubmit: (data: CounterpartyFormData) => void;
  onCancel: () => void;
};

export const CounterpartyForm: React.FC<CounterpartyFormProps> = (props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CounterpartyFormData>({
    resolver: yupResolver(counterpartyFromValidationSchema),
    defaultValues: {
      type: '',
      bankAccountHolderName: '',
      bankAccountSortCode: '',
      bankAccountNumber: '',
      description: '',
    },
    mode: 'onSubmit',
  });

  const onSubmitHandler: SubmitHandler<CounterpartyFormData> = (data) => {
    props.onSubmit(data);
  };

  const onCancelHandler = () => {
    props.onCancel();
  };

  return (
    <>
      <HeaderIcon />
      <Title title="Add a counterparty account" insideCard className="mb-8" />
      <form
        className="stepper-form"
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
        noValidate
      >
        <div className="flex flex-col gap-1">
          <div>
            <Label className="-mb-1">Counterparty type</Label>
            <FormInput
              disabled={props.isLoading}
              containerClassName={`m-0 p-0 h-[62px]`}
              placeholder="Enter type"
              type="text"
              withSpacing={false}
              dynamicHeightErrorBanner={!!errors?.type}
              errorTextAlign="text-left"
              errors={errors?.type?.message}
              {...register('type', {})}
            />
          </div>
          <div>
            <Label className="-mb-1">Account holder name</Label>
            <FormInput
              disabled={props.isLoading}
              containerClassName={`m-0 p-0 h-[62px]`}
              withSpacing={false}
              dynamicHeightErrorBanner={!!errors?.bankAccountHolderName}
              errorTextAlign="text-left"
              placeholder="Full name"
              type="text"
              errors={errors?.bankAccountHolderName?.message}
              {...register('bankAccountHolderName', {})}
            />
          </div>
          <div>
            <Label className="-mb-1">Sort code</Label>
            <FormInput
              disabled={props.isLoading}
              containerClassName={`m-0 p-0 h-[62px]`}
              dynamicHeightErrorBanner={!!errors?.bankAccountSortCode}
              withSpacing={false}
              errorTextAlign="text-left"
              placeholder="6 digit number"
              type="text"
              errors={errors?.bankAccountSortCode?.message}
              {...register('bankAccountSortCode', {})}
            />
          </div>

          <div>
            <Label className="-mb-1">Account number</Label>
            <FormInput
              disabled={props.isLoading}
              containerClassName={`m-0 p-0 h-[62px]`}
              dynamicHeightErrorBanner={!!errors?.bankAccountNumber}
              withSpacing={false}
              errorTextAlign="text-left"
              placeholder="Bank account number"
              type="text"
              errors={errors?.bankAccountNumber?.message}
              {...register('bankAccountNumber', {})}
            />
          </div>
          <div>
            <Label
              className="-mb-1"
              tooltipText="The description added here will be visible in the transaction statement log"
              tooltipClassName="w-[350px]"
            >
              Description (optional)
            </Label>
            <Textarea
              disabled={props.isLoading}
              className="mt-2"
              placeholder="Additional description..."
              rows={4}
              {...register('description', {})}
            />
            {errors?.description?.message && (
              <p className="text-error-700 text-left body-300 mt-1">
                {errors?.description?.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex justify-around mt-8">
          <Button layout="ghost" className="w-40" onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button
            className="w-40"
            onClick={handleSubmit(onSubmitHandler)}
            disabled={props.isLoading}
            loading={props.isLoading}
            type="button"
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};
