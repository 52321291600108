import React from 'react';
import { IconProps } from '../iconTypes';

const RiskAnalysisIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M10.2528 11.5401L12.4185 5.34223L9.56543 11.2552L10.2528 11.5401Z"
      fill="currentColor"
    />
    <path
      d="M12.5142 5.11126C12.3918 5.06051 12.251 5.11418 12.1934 5.23358L9.34027 11.1466C9.31039 11.2085 9.30733 11.28 9.33183 11.3443C9.35632 11.4085 9.4062 11.4599 9.46972 11.4862L10.1571 11.771C10.2206 11.7974 10.2922 11.7964 10.3549 11.7683C10.4177 11.7402 10.4661 11.6875 10.4888 11.6226L12.6545 5.42468C12.6983 5.29954 12.6367 5.16201 12.5142 5.11126ZM10.1041 11.2078L9.90557 11.1256L10.7295 9.41801L10.1041 11.2078Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M10 17.5C13.866 17.5 17 14.366 17 10.5C17 6.63404 13.866 3.50003 10 3.50003C6.13401 3.50003 3 6.63404 3 10.5C3 14.366 6.13401 17.5 10 17.5ZM10 19C14.6944 19 18.5 15.1945 18.5 10.5C18.5 5.80561 14.6944 2.00003 10 2.00003C5.30558 2.00003 1.5 5.80561 1.5 10.5C1.5 15.1945 5.30558 19 10 19Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M13.2061 6.1636C13.8984 6.66956 14.4619 7.33449 14.8477 8.10471C15.2934 8.99492 15.4843 9.99109 15.3992 10.983C15.3141 11.975 14.9563 12.9241 14.3653 13.7253L13.5605 13.1318C14.042 12.479 14.3335 11.7057 14.4029 10.8975C14.4722 10.0894 14.3167 9.27776 13.9535 8.55248C13.6393 7.92496 13.1801 7.38322 12.616 6.971L13.2061 6.1636ZM10.0581 5.12414C10.5005 5.12714 10.9388 5.18451 11.3634 5.29349L11.1148 6.26209C10.7688 6.1733 10.4118 6.12656 10.0513 6.12412C9.24017 6.11861 8.44333 6.33756 7.7489 6.75674C7.05446 7.17592 6.4895 7.77901 6.11649 8.49929C5.74347 9.21958 5.57695 10.029 5.63533 10.838C5.6937 11.6471 5.97471 12.4242 6.44727 13.0835L5.6345 13.6661C5.05448 12.8569 4.70957 11.903 4.63792 10.91L5.13662 10.874L4.63792 10.91C4.56627 9.917 4.77066 8.92351 5.2285 8.03943C5.68633 7.15535 6.37977 6.41513 7.23212 5.90062C8.08446 5.38612 9.06251 5.11738 10.0581 5.12414Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default RiskAnalysisIcon;
