import React from 'react';
import { IconProps } from '../iconTypes';

const InvestorIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 21"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M14.4996 8.80024C11.683 8.80024 9.39961 11.0836 9.39961 13.9002C9.39961 16.7169 11.683 19.0002 14.4996 19.0002C17.3163 19.0002 19.5996 16.7169 19.5996 13.9002C19.5996 11.0836 17.3163 8.80024 14.4996 8.80024ZM7.59961 13.9002C7.59961 10.0895 10.6888 7.00024 14.4996 7.00024C18.3104 7.00024 21.3996 10.0895 21.3996 13.9002C21.3996 17.711 18.3104 20.8002 14.4996 20.8002C10.6888 20.8002 7.59961 17.711 7.59961 13.9002ZM12.9329 11.9002C12.9329 10.6668 13.9328 9.66691 15.1663 9.66691C16.3997 9.66691 17.3996 10.6668 17.3996 11.9002C17.3996 12.3973 16.9967 12.8002 16.4996 12.8002C16.0026 12.8002 15.5996 12.3973 15.5996 11.9002C15.5996 11.6609 15.4056 11.4669 15.1663 11.4669C14.927 11.4669 14.7329 11.6609 14.7329 11.9002V13.0002H15.1663C15.6633 13.0002 16.0663 13.4032 16.0663 13.9002C16.0663 14.3973 15.6633 14.8002 15.1663 14.8002H14.7329V15.2336C14.7329 15.3818 14.7185 15.5267 14.6909 15.6669H16.4996C16.9967 15.6669 17.3996 16.0699 17.3996 16.5669C17.3996 17.064 16.9967 17.4669 16.4996 17.4669H12.4996C12.0026 17.4669 11.5996 17.064 11.5996 16.5669C11.5996 16.0699 12.0026 15.6669 12.4996 15.6669C12.7389 15.6669 12.9329 15.4729 12.9329 15.2336V14.8002H12.4996C12.0026 14.8002 11.5996 14.3973 11.5996 13.9002C11.5996 13.4032 12.0026 13.0002 12.4996 13.0002H12.9329V11.9002Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M7.76048 9.94278C8.37366 9.0439 9.26033 8.20841 10.4996 7.50024C10.9786 7.22653 11.383 6.94355 11.7201 6.65374C11.9014 6.13616 12 5.57972 12 5.00024C12 2.23882 9.76142 0.000244141 7 0.000244141C4.23858 0.000244141 2 2.23882 2 5.00024C2 7.76167 4.23858 10.0002 7 10.0002C7.25855 10.0002 7.51251 9.98062 7.76048 9.94278ZM7.37869 10.5744C7.26189 10.5266 7.13402 10.5002 7 10.5002C4.65304 10.5002 2.87664 11.6006 1.71302 13.0849C0.569123 14.5439 0 16.3884 0 18.0002V19.0002C0 19.5525 0.447715 20.0002 1 20.0002H9L8.27781 19.0002C8.10287 18.7087 7.92656 18.3722 7.76048 18.0002H2C2 16.8163 2.43088 15.4108 3.28698 14.3188C4.07297 13.3163 5.20093 12.592 6.7059 12.5083C6.82872 11.8546 7.04469 11.2033 7.37869 10.5744ZM4 5.00024C4 3.34339 5.34315 2.00024 7 2.00024C8.65685 2.00024 10 3.34339 10 5.00024C10 6.6571 8.65685 8.00024 7 8.00024C5.34315 8.00024 4 6.6571 4 5.00024Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default InvestorIcon;
