import { Surface } from 'ui';

const StepperSurface = ({ children }) => {
  return (
    <Surface border="light" padding="md">
      {children}
    </Surface>
  );
};

export { StepperSurface };
