import classNames from 'classnames';
import { ChevronRightIcon } from '@heroicons/react/solid';

interface BreadcrumbItem {
  id: string;
  label: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
  onClick: (id: string, tailoredList: BreadcrumbItem[]) => void;
  className?: string;
  addEndArrow?: boolean;
}

const Breadcrumbs = ({
  items,
  onClick,
  addEndArrow,
  className = '',
}: BreadcrumbsProps) => {
  return (
    <div className={`flex ${className}`}>
      {items.map(({ id, label }, index) => {
        const isLast = index + 1 === items.length;
        const isFullVisibility = !isLast || addEndArrow;
        return (
          <div className="flex items-center" key={id}>
            <p
              className={classNames('text-xs', {
                'text-secondary-400 font-semibold cursor-pointer':
                  isFullVisibility,
                'text-neutral-600': !isFullVisibility,
              })}
              onClick={() => {
                if (!isFullVisibility) return;
                const tailoredList = cutList(items, id);
                onClick(id, tailoredList);
              }}
            >
              {label}
            </p>
            {isFullVisibility && (
              <ChevronRightIcon
                height={22}
                width={25}
                className="text-neutral-400 mx-2"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

const cutList = (list: BreadcrumbItem[], id: string): BreadcrumbItem[] => {
  const indexToRemove = list.findIndex((item) => item.id === id);
  return indexToRemove !== -1 ? list.slice(0, indexToRemove + 1) : list;
};
export default Breadcrumbs;
