import { loansBaseApi } from '.';
import { rootApi } from '../rootApi';
import {
  Facility,
  FacilityPayload,
  LoanFacility,
  LoanFacilityForm,
  LoanFacilityPayload,
} from '@/interfaces/loans/facility';

export const facilityApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createFacility: builder.mutation<Facility, FacilityPayload>({
      query: (payload) => ({
        url: loansBaseApi + '/facility',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Loans'],
    }),
    getFacilityForOriginator: builder.query<
      Facility[],
      Facility['branchEncodedKey']
    >({
      query: (branchEncodedKey) => ({
        url: loansBaseApi + '/facility/originator',
        method: 'GET',
        params: { branchEncodedKey },
      }),
      providesTags: ['Loans'],
    }),
    getFacilityForProduct: builder.query<
      Facility[],
      {
        productEncodedKey: Facility['productEncodedKey'];
        branchEncodedKey: Facility['branchEncodedKey'];
      }
    >({
      query: ({ branchEncodedKey, productEncodedKey }) => ({
        url:
          loansBaseApi +
          `/facility/originator/${branchEncodedKey}/product/${productEncodedKey}`,
        method: 'GET',
        params: { productEncodedKey, branchEncodedKey },
      }),
      providesTags: ['Loans'],
    }),
    getLoanFacilitiesForLoan: builder.query<
      LoanFacility[],
      LoanFacility['mambuLoanEncodedKey']
    >({
      query: (mambuLoanEncodedKey) => ({
        url: loansBaseApi + `/loan-facility/loan/${mambuLoanEncodedKey}`,
        method: 'GET',
        params: { mambuLoanEncodedKey },
      }),
      providesTags: ['Loans'],
    }),
    createLoanFacilityFromTemplate: builder.mutation<
      LoanFacility,
      LoanFacilityPayload
    >({
      query: (payload) => ({
        url: loansBaseApi + '/loan-facility/',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Loans'],
    }),
    createManyLoanFacilitiesFromTemplate: builder.mutation<
      LoanFacilityForm[],
      LoanFacilityPayload[]
    >({
      query: (payload) => ({
        url: loansBaseApi + '/loan-facility/create-many/',
        method: 'POST',
        body: { loanFacilities: payload },
      }),
      invalidatesTags: ['Loans'],
    }),
    deleteFacility: builder.mutation({
      query: ({ id }: { id: string }) => ({
        url: loansBaseApi + `/loan-facility/${id}`,
        method: 'DELETE',
        params: {
          id,
        },
      }),
      invalidatesTags: ['Notifications'],
    }),
    clearAllLoanFacilitiesForLoan: builder.mutation({
      query: ({ mambuLoanEncodedKey }: { mambuLoanEncodedKey: string }) => ({
        url: loansBaseApi + `/loan-facility/loan/${mambuLoanEncodedKey}`,
        method: 'DELETE',
        params: {
          mambuLoanEncodedKey,
        },
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useCreateFacilityMutation,
  useGetFacilityForOriginatorQuery,
  useGetLoanFacilitiesForLoanQuery,
  useGetFacilityForProductQuery,
  useClearAllLoanFacilitiesForLoanMutation,
  useCreateLoanFacilityFromTemplateMutation,
  useCreateManyLoanFacilitiesFromTemplateMutation,
} = facilityApi;
