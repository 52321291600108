import React from 'react';
import { HeaderGroup } from 'react-table';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

interface Props {
  column: HeaderGroup;
}

const Sort: React.FC<Props> = ({ column }: Props) => {
  if (!column?.canSort || column.Header === '') return null;

  if (!column?.isSorted) return getArrows('both');

  return column.isSortedDesc ? getArrows('down') : getArrows('up');
};

export default Sort;

const getArrows = (select: 'up' | 'down' | 'both') => {
  return (
    <span className="inline-block relative pl-1 w-5">
      {(select === 'up' || select === 'both') && (
        <ChevronUpIcon className="absolute bottom-0 w-4 h-4" />
      )}
      {(select === 'down' || select === 'both') && (
        <ChevronDownIcon className="absolute -bottom-1.5 w-4 h-4" />
      )}
    </span>
  );
};
