import * as fns from 'date-fns';
import {
  addBusinessDays as addBusinessDaysFns,
  addDays,
  isBefore,
} from 'date-fns';
import { zonedDate, zonedNow } from './datetime';
import { formatDate } from './formatters';

// To be deleted once formatDate is implemented in every date
export const stringDate = (date?: string | number | Date): string => {
  if (date) return zonedDate(date).toISOString().substring(0, 10);
  return zonedNow().toISOString().substring(0, 10);
};

export const getNextDay = (stringDate?: string) => {
  const date = zonedDate(
    (stringDate ? +new Date(stringDate) : +zonedNow()) + 86400000
  );

  return formatDate(date, 'yyyy-MM-dd');
};

export const getPreviousDay = (stringDate?: string) => {
  const date = zonedDate(
    (stringDate ? +new Date(stringDate) : +zonedNow()) - 86400000
  );

  return formatDate(date, 'yyyy-MM-dd');
};

export const getHoursDifferenceFromCurrentDate = (
  date: string,
  dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
): number => {
  const parsed = fns.parse(date, dateFormat, new Date());
  return fns.differenceInHours(new Date(), parsed);
};

export const getDaysDifferenceFromCurrentDate = (
  date: string,
  dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
): number => {
  const parsed = fns.parse(date, dateFormat, new Date());
  return fns.differenceInCalendarDays(new Date(), parsed);
};

export const addBusinessDays = (date: Date, days: number): string => {
  if (days < 0) return '';
  let addedDays = 0;

  while (addedDays < days) {
    date.setDate(date.getDate() + 1);
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      addedDays++;
    }
  }

  return formatDate(date, 'yyyy-MM-dd');
};

export const checkDateIsInThePast = (date: Date) => {
  if (!date) return false;
  return fns.isBefore(date, zonedNow());
};

export const checkDateIsInTheFuture = (date: Date) => {
  if (!date) return false;
  return fns.isAfter(date, zonedNow());
};

export const isDateBefore = (date: Date, dateToCompare: Date) => {
  if (!date || !dateToCompare) return false;
  return fns.isBefore(date, dateToCompare);
};

export const differenceInBusinessDays = (from: Date, to: Date) => {
  return fns.differenceInBusinessDays(from, to);
};

/**
 * Check whether more than `days` days have passed since `date`.
 *
 * @param date - String representation of the reference date
 * @param days - Maximum number of days allowed to still be in range
 * @param businessDays - If `true`, `days` counts only business days
 */
export const isDateOutOfRange = (
  date: string,
  days: number,
  businessDays?: boolean
) => {
  const today = new Date();
  const targetDate = new Date(date);
  const futureDate = businessDays
    ? addBusinessDaysFns(today, days)
    : addDays(today, days);
  return isBefore(futureDate, targetDate);
};
