import { createContext } from 'react';
import { UaaSContextType } from './types';

export const UaaSContext = createContext<UaaSContextType>({
  execution: null,
  flow: null,
  flowId: null,
  executionId: null,
  currentPageId: null,
  currentPageConfig: null,
  isDashboard: null,
  pageModules: [],
  isLoading: false,
  pendingDataStoreRequests: [],
  isLoadingModule: false,
  isSubmitting: false,
  loadExecution: null,
  loadFlow: null,
  saveToDatastore: null,
  setSelectedPageId: null,
  setCompletePage: null,
  retrieveFromDatastore: null,
  pageIsComplete: null,
  addNewPages: null,
  getModuleRef: null,
  pages: [],
  sortedPages: [],
  modulesQuery: null,
});
