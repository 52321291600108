import React, { Fragment } from 'react';
import {
  ASSET_OCCUPANCY_STATUS_NAMES,
  getAssetLabelByType,
} from '@/constants/assetType';
import { formatAmount } from '@/utils/formatters';
import { isNotEmpty } from '@/utils/validation';
import { AssetWidgetProps } from '../AssetWidget.props';
import AssetDetail from '../components/AssetDetail';

const DetailedAssetWidget: React.FC<AssetWidgetProps> = ({
  displayedAssets,
  showAsset,
  isOriginator,
  supportedAssets,
  currency,
}) => {
  if (!showAsset) {
    return;
  }

  return displayedAssets?.map((asset, index) => (
    <Fragment key={index}>
      <p className="text-[12px] font-medium">Asset {index + 1} </p>
      <AssetDetail title="Asset Owner" detail={asset?.borrowerType} />
      <AssetDetail
        title="Asset type"
        detail={getAssetLabelByType(asset?.assetType, supportedAssets) ?? ''}
      />
      <AssetDetail title="Asset description" detail={asset?.assetDescription} />

      {isOriginator && isNotEmpty(asset?.assetValuation) && (
        <AssetDetail
          title="Asset valuation"
          detail={formatAmount(asset?.assetValuation, true, currency)}
        />
      )}

      <AssetDetail
        title="Security asset address"
        detail={asset?.securityAddress}
      />
      <div className="mb-[5px]">
        <AssetDetail
          title="Tenanted status"
          detail={ASSET_OCCUPANCY_STATUS_NAMES[asset?.occupancyStatus] ?? ''}
        />
      </div>
      {index !== displayedAssets?.length - 1 && (
        <div className="border-[1px] border-solid border-neutral-300 mb-[5px]"></div>
      )}
    </Fragment>
  ));
};

export default DetailedAssetWidget;
