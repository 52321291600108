import React from 'react';
import classNames from 'classnames';

interface Props {
  color?: 'primary' | 'destructive' | 'neutral' | 'warning' | 'success';
  icon?: React.ReactElement;
  mb?: string;
  textCenter?: boolean;
  title: string;
}

const ModalTitleWithIcon: React.FC<Props> = (props) => {
  const {
    color = 'primary',
    icon,
    title,
    mb = 'mb-2',
    textCenter = false,
  } = props;

  const selectColor = () => {
    switch (color) {
      case 'primary':
        return 'text-primary-700 bg-primary-100';
      case 'destructive':
        return 'text-error-500 bg-error-200';
      case 'neutral':
        return 'bg-neutral-200';
      case 'warning':
        return 'text-warning-500 bg-warning-200';
      case 'success':
        return 'text-success-500 bg-success-200';
    }
  };

  const iconCls = selectColor();

  let reSizedIcon = null;
  if (icon) {
    reSizedIcon = React.cloneElement(icon, {
      className: classNames('inline w-6 h-6'),
    });
  }

  const lines = title.split('<br />');
  return (
    <div className={`flex flex-col items-center ${mb}`}>
      {icon && (
        <div
          className={classNames(
            'w-12 h-12 rounded-full flex justify-center items-center mb-4',
            iconCls
          )}
        >
          {reSizedIcon}
        </div>
      )}
      <h3
        className={classNames('body-500-semibold whitespace-pre-wrap', {
          'text-center': textCenter,
        })}
      >
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            {line.trim()}
            {index < lines.length - 1 && <br />}
          </React.Fragment>
        ))}
      </h3>
    </div>
  );
};

export default ModalTitleWithIcon;
