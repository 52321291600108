import { useState } from 'react';
import { Tooltip } from 'ui';
import { formatLineBreaks } from 'ui/utils/FormatText.utils';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

const SummaryDetail = ({
  title,
  detail,
  questionTooltip = '',
  show = true,
}: {
  title: string;
  detail: string | number;
  questionTooltip?: string;
  show?: boolean;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  if (!show) return;
  return (
    <div className="flex-grow flex flex-col mt-4">
      <span className="heading-200 mr-2">{detail}</span>
      <span className="heading-100 text-neutral-700">
        <div
          className="flex items-center gap-x-1"
          onMouseOver={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {title}
          {questionTooltip && (
            <div className="relative flex items-center">
              <QuestionMarkCircleIcon width={14} className="cursor-pointer" />
              {showTooltip && (
                <Tooltip position="left" withoutArrow>
                  {formatLineBreaks(questionTooltip)}
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </span>
    </div>
  );
};

export default SummaryDetail;
