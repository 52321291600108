import { useState } from 'react';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { Title } from 'ui';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useLoanDetailsOverwriteLabel } from '@/hooks/useLoanDetailsOverwriteLabel';
import { formatAmount, formatPercent } from '@/utils/formatters';
import SummaryDetail from './Summary';
import { LoanSummary } from '@/interfaces/loans/queries';

const LoanDetailGroup = ({
  loanSummary,
  divider = false,
  groupLoanValue,
}: {
  loanSummary: LoanSummary;
  divider?: boolean;
  groupLoanValue?: number;
}) => {
  const [show, setShow] = useState(false);
  const { loanUpper } = useGetLabelsConfig();
  const loanToValue = formatPercent(
    (100 * loanSummary.totalAmount) / groupLoanValue
  );

  const overwriteLabel = useLoanDetailsOverwriteLabel();

  const loanAmountLabel = overwriteLabel.getPropertyLabel(
    `${loanUpper} Amount`,
    'LOAN_AMOUNT',
    null
  );

  const loanAmountTooltip = overwriteLabel.getPropertyTooltip(
    `Initial ${loanUpper} Amount`,
    'LOAN_AMOUNT'
  );

  return (
    <div
      className={classNames({
        'border-b-[1px] border-solid border-neutral-300 pb-5': divider,
      })}
    >
      <div className="flex justify-between items-center">
        <Title
          title={loanSummary?.loanName}
          className={classNames('max-w-[280px]', { 'mb-[3px]': !show })}
        />
        <button
          className={classNames({ 'rotate-180': show }, { 'mt-[18px]': !show })}
          onClick={() => setShow(!show)}
        >
          <SvgIcon name="ArrowIcon" />
        </button>
      </div>

      {show && (
        <>
          <SummaryDetail
            title={loanAmountLabel}
            detail={formatAmount(
              loanSummary?.totalAmount,
              true,
              loanSummary?.currency
            )}
            questionTooltip={loanAmountTooltip}
          />

          <SummaryDetail
            title="Interest rate"
            detail={
              formatPercent(loanSummary?.displayedInterestRate, 3) + ' per year'
            }
          />

          <SummaryDetail title={`${loanUpper} to Value`} detail={loanToValue} />
        </>
      )}
    </div>
  );
};

export default LoanDetailGroup;
