import { Key } from 'react';
import classNames from 'classnames';
import { Listbox } from '@headlessui/react';
import HoverTooltip from '../../HoverTooltip';
import { Option } from '../Select';

interface OptionsItem {
  selectWidth: number | undefined;
  option: Option;
  selectedStyle: string;
  activeStyle: string;
}

const OptionsItem = ({
  selectWidth,
  option,
  selectedStyle,
  activeStyle,
}: OptionsItem) => {
  return (
    <Listbox.Option
      key={option.value as Key}
      value={option}
      style={{ width: `${selectWidth}px` }}
      className={({ active, selected }) =>
        classNames(
          'px-3 py-2 hover:bg-primary-100',
          selected && selectedStyle,
          active && activeStyle
        )
      }
    >
      <HoverTooltip label={option.label} zIndex={2000} side="bottom">
        <p
          className={`w-${selectWidth}px overflow-hidden whitespace-nowrap text-ellipsis`}
        >
          {option.label}
        </p>
      </HoverTooltip>
    </Listbox.Option>
  );
};

export default OptionsItem;
