import { env } from '@/env/public';

const EnvironmentBadge = () => {
  const isSandboxEnv = env.NEXT_PUBLIC_ENVIRONMENT === 'sandbox';
  if (!isSandboxEnv) return null;
  return (
    <div className="bg-neutral-200 heading-200 text-neutral-700 px-4 py-2 rounded ml-4">
      Sandbox
    </div>
  );
};

export default EnvironmentBadge;
