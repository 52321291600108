import React, { Fragment, memo } from 'react';
import classNames from 'classnames';
import { colorDefault } from 'ui/theme/defaultColors';
import { Menu } from '@headlessui/react';
import { GroupedLoanListItem, LoanSelectorLoan } from '.';
import CircleIcon from '../../icons/svg/CircleIcon';
import { GroupedLoanChildItem } from '../../interfaces/loans';

interface Props {
  onClick?: (item: LoanSelectorLoan) => void;
  list: GroupedLoanListItem[];
  borrower?: boolean;
}

const Items: React.FC<Props> = ({ onClick, list, borrower }) => {
  const handleOnClick = (
    item: GroupedLoanListItem,
    child?: GroupedLoanChildItem
  ) => {
    if (!onClick) return;
    if (item.allLoans || borrower) {
      onClick(item);
      return;
    }
    if (!item.loans) return;

    const loan = child ? child : item.loans[0];
    const data: LoanSelectorLoan = {
      ...loan,
      ...item,
    };
    onClick(data);
  };

  return (
    <div className="max-h-[234px] overflow-x-hidden w-[380px]">
      {list?.map((item) => {
        const itemLength = item.loans?.length;
        const isMultiLoan = itemLength && itemLength > 1;

        return (
          <Fragment key={`${item.companyNumber || ''}${item.companyName}`}>
            <Menu.Item
              as="div"
              className={classNames(
                'flex items-center  py-3 rounded-md  z-[100',
                { 'cursor-pointer hover:bg-primary-100': !isMultiLoan }
              )}
              onClick={() => !isMultiLoan && handleOnClick(item)}
            >
              <div className="flex gap-1 body-400 px-5 z-50 text-secondary-400 overflow-hidden">
                <p className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {borrower ? item.name : item.companyName}
                </p>

                {isMultiLoan && (
                  <div className="ml-2 text-secondary-400 bg-secondary-200 rounded-full flex justify-center items-center w-[18px] h-[18px] text-[10px]">
                    {itemLength}
                  </div>
                )}
                {!isMultiLoan && item.companyNumber && (
                  <span className="whitespace-nowrap">
                    ({borrower ? item.id : item.companyNumber})
                  </span>
                )}
              </div>
            </Menu.Item>
            {isMultiLoan &&
              item.loans &&
              item.loans.map((x) => (
                <Menu.Item
                  key={x.id}
                  as="div"
                  className="flex items-center cursor-pointer py-3 rounded-md hover:bg-primary-100 z-[100]"
                  onClick={() => handleOnClick(item, x)}
                >
                  <div className="flex gap-1 body-400 px-5 z-50 text-secondary-400 overflow-hidden">
                    <div className="flex justify-center items-center">
                      <CircleIcon
                        circleCx={2.5}
                        circleCy={3}
                        circleR={2.5}
                        fill={colorDefault.secondary[200]}
                      />
                    </div>
                    <p className="whitespace-nowrap text-ellipsis overflow-hidden">
                      {x.loanName}
                    </p>
                    <span className="whitespace-nowrap">{x.id}</span>
                  </div>
                </Menu.Item>
              ))}
          </Fragment>
        );
      })}
    </div>
  );
};

export default memo(Items);
