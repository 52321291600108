import React from 'react';
import { IconProps } from '../iconTypes';

const EditInterestRateIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.37847 6.85834C6.64909 6.85834 6.86847 6.63145 6.86847 6.35157C6.86847 6.07168 6.64909 5.8448 6.37847 5.8448C6.10785 5.8448 5.88847 6.07168 5.88847 6.35157C5.88847 6.63145 6.10785 6.85834 6.37847 6.85834ZM6.37847 7.79948C7.15167 7.79948 7.77847 7.15123 7.77847 6.35157C7.77847 5.5519 7.15167 4.90365 6.37847 4.90365C5.60527 4.90365 4.97847 5.5519 4.97847 6.35157C4.97847 7.15123 5.60527 7.79948 6.37847 7.79948Z"
      fill="#184083"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5785 10.8401C10.8491 10.8401 11.0685 10.6132 11.0685 10.3333C11.0685 10.0534 10.8491 9.82656 10.5785 9.82656C10.3078 9.82656 10.0885 10.0534 10.0885 10.3333C10.0885 10.6132 10.3078 10.8401 10.5785 10.8401ZM10.5785 11.7812C11.3517 11.7812 11.9785 11.133 11.9785 10.3333C11.9785 9.53367 11.3517 8.88542 10.5785 8.88542C9.80527 8.88542 9.17847 9.53367 9.17847 10.3333C9.17847 11.133 9.80527 11.7812 10.5785 11.7812Z"
      fill="#184083"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9962 5.06684C11.2957 5.31967 11.3403 5.77573 11.0959 6.08547L6.71389 11.6375C6.46943 11.9472 6.02846 11.9933 5.72897 11.7405C5.42948 11.4877 5.38487 11.0316 5.62934 10.7219L10.0113 5.16988C10.2558 4.86014 10.6967 4.814 10.9962 5.06684Z"
      fill="#184083"
    />
    <path
      d="M17.7714 1.38282C18.1619 0.992299 18.795 0.992299 19.1856 1.38282C19.5761 1.77335 19.5761 2.40651 19.1856 2.79704L18.7891 3.19348L17.3749 1.77927L17.7714 1.38282Z"
      fill="#184083"
    />
    <path
      d="M16.6678 2.48638L12.4785 6.67572V8.08993H13.8927L18.082 3.90059L16.6678 2.48638Z"
      fill="#184083"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.06354 3.70712C3.08226 2.28772 4.52673 1.22984 6.18738 0.686954C7.84803 0.144073 9.63846 0.144437 11.2989 0.687996C11.6401 0.799681 11.8261 1.16679 11.7144 1.50796C11.6027 1.84913 11.2356 2.03517 10.8944 1.92348C9.49661 1.46589 7.98934 1.46558 6.59133 1.9226C5.19332 2.37963 3.97729 3.2702 3.11968 4.46512C2.26208 5.66003 1.80751 7.09712 1.82194 8.56787C1.83637 10.0386 2.31904 11.4665 3.19992 12.6444C4.0808 13.8222 5.31407 14.6888 6.72078 15.1183C8.12748 15.5478 9.63446 15.5179 11.023 15.033C12.4116 14.5481 13.6096 13.6333 14.4431 12.4215C14.6465 12.1257 15.0512 12.0508 15.347 12.2543C15.6428 12.4577 15.7176 12.8624 15.5142 13.1582C14.5241 14.5977 13.1011 15.6843 11.4516 16.2603C9.8022 16.8363 8.01213 16.8718 6.34115 16.3616C4.67017 15.8514 3.20522 14.8221 2.15885 13.423C1.11249 12.0238 0.539141 10.3277 0.522002 8.58062C0.504864 6.83358 1.04483 5.12651 2.06354 3.70712Z"
      fill="#184083"
    />
  </svg>
);

export default EditInterestRateIcon;
