import { LABELS } from '@/constants/productTypeDictionary';
import { TableColumnsConfiguration } from 'kennek/interfaces/kennek';
import { LabelsConfig } from 'kennek/interfaces/labelsConfig';

export type ColumnName = (typeof COLUMNS_CHECK)[number];
export type GroupColumnName = (typeof GROUP_COLUMNS_CHECK)[number];
export type EditColumnName = (typeof EDIT_COLUMNS_CHECK)[number];

export const getColumnsTooltips = ({
  loanLower,
}: LabelsConfig): Record<ColumnName | GroupColumnName, string> => {
  return {
    number: 'Index of the instalment',
    accruedInterest: 'Accrued interest for the period',
    days: 'Days in interest period',
    dueDate: 'Date by which payment must be made',
    feeDue: 'Amount of fee to be paid by due date',
    feeExpected: 'Amount of fee to be paid by due date',
    feePaid: 'Amount of fee that was paid',
    interestDue: 'Amount of interest to be paid by due date',
    interestExpected: 'Amount of interest to be paid by due date',
    interestPaid: 'Amount of interest that was paid',
    investorInterest: 'Interest gain by the investors',
    maturityDate: `Maturity date of the entire ${loanLower}`,
    originatorInterest: 'Interest gain by the organization',
    penaltyDue: 'Penalty accumulated during the time period',
    penaltyExpected: 'Penalty accumulated during the time period',
    principalDue: 'Amount of principal to be paid by due date',
    principalExpected: 'Amount of principal to be paid by due date',
    principalOutstanding: 'Principal currently outstanding',
    principal: 'Total principal outstanding',
    principalPaid: 'Amount of principal that was paid',
    defaultInterest: '',
    totalExpected: 'Total to be paid by due date',
    totalOutstanding: 'Principal plus Total accrued interest',
    totalAccruedInterest: 'Total accrued interest till the period',
    state: 'Status of the instalment',
    interestRollUp: 'Amount of interest related to roll up duration',
    disbursement: 'Disbursement',
    totalPaid: 'Total Paid',
  };
};

export type ProductType = 'blockedUntilMatured' | 'default';

export const columnsNames = (type: ProductType) => {
  const ob: Record<ColumnName, string> = {
    accruedInterest: 'Accrued Interest',
    days: 'Days',
    dueDate: LABELS[type].dueDate,
    feeExpected: 'Fee Due',
    feePaid: 'Fee Paid',
    interestExpected: 'Interest Due',
    interestPaid: 'Interest Paid',
    investorInterest: 'Investor Interest',
    maturityDate: 'Maturity Date',
    number: 'Instalment',
    penaltyExpected: 'Penalty due',
    principal: 'Principal',
    principalExpected: 'Principal Due',
    principalOutstanding: 'Principal Outstanding',
    principalPaid: 'Principal Paid',
    state: 'Status',
    originatorInterest: 'Originator Interest',
    totalAccruedInterest: 'Total Accrued Interest',
    defaultInterest: 'Default Interest',
    totalExpected: 'Total Due',
    totalOutstanding: 'Total Outstanding',
    interestRollUp: 'Interest Roll Up',
    disbursement: 'Disbursement',
    totalPaid: 'Total paid',
  };
  return ob;
};

export const columnsNamesGroup = () => {
  const ob: Record<GroupColumnName, string> = {
    number: '#',
    dueDate: 'Due date',
    days: 'Days',
    feeDue: 'Fee Due',
    interestDue: 'Interest Due',
    penaltyDue: 'Penalty due',
    state: '',
    principalDue: 'Principal Due',
    defaultInterest: 'Default Interest',
    totalExpected: 'Total Due',
    totalOutstanding: 'Total Outstanding',
  };
  return ob;
};

export const columnsNamesEdit = () => {
  const ob: Record<EditColumnName, string> = {
    number: '#',
    dueDate: 'Date',
    principalDue: 'Principal',
    interestDue: 'Interest',
    feeDue: 'Fees',
  };
  return ob;
};

export const TABLE_NAMES = {
  repaymentSchedule: 'Repayment Schedule',
};

export const DEFAULT_CONFIGURATION: TableColumnsConfiguration<ColumnName>[] = [
  {
    name: 'number',
    active: true,
    editable: false,
  },
  { name: 'days', active: true, editable: false },
  {
    name: 'dueDate',
    active: true,
    editable: false,
  },
  {
    name: 'feeExpected',
    active: true,
    editable: false,
  },
  {
    name: 'interestExpected',
    active: true,
    editable: false,
  },
  {
    name: 'principalExpected',
    active: true,
    editable: false,
  },
  {
    name: 'principalOutstanding',
    active: true,
    editable: false,
  },
  {
    name: 'defaultInterest',
    active: true,
    editable: false,
  },
  {
    name: 'totalExpected',
    active: true,
    editable: false,
  },
  {
    name: 'principalPaid',
    active: true,
    editable: false,
  },
  {
    name: 'interestPaid',
    active: true,
    editable: false,
  },
  {
    name: 'feePaid',
    active: true,
    editable: false,
  },
  {
    name: 'totalOutstanding',
    active: true,
    editable: false,
  },
  {
    name: 'state',
    active: false,
    editable: false,
  },
];

export const GROUP_CONFIGURATION: TableColumnsConfiguration<GroupColumnName>[] =
  [
    {
      name: 'number',
      active: true,
      editable: true,
    },
    {
      name: 'dueDate',
      active: true,
      editable: true,
    },
    {
      name: 'days',
      active: true,
      editable: true,
    },
    {
      name: 'principalDue',
      active: true,
      editable: true,
    },
    {
      name: 'interestDue',
      active: true,
      editable: true,
    },
    {
      name: 'feeDue',
      active: true,
      editable: true,
    },
    {
      name: 'penaltyDue',
      active: false,
      editable: true,
    },
    {
      name: 'defaultInterest',
      active: true,
      editable: true,
    },
    {
      name: 'totalExpected',
      active: true,
      editable: true,
    },
    {
      name: 'totalOutstanding',
      active: true,
      editable: true,
    },
    {
      name: 'state',
      active: true,
      editable: true,
    },
  ];

export const EDIT_CONFIGURATION: TableColumnsConfiguration<EditColumnName>[] = [
  {
    name: 'number',
    active: true,
    editable: false,
  },
  {
    name: 'dueDate',
    active: true,
    editable: false,
  },
  {
    name: 'principalDue',
    active: true,
    editable: true,
  },
  {
    name: 'interestDue',
    active: true,
    editable: true,
  },
  {
    name: 'feeDue',
    active: true,
    editable: true,
  },
];

export const COLUMNS_CHECK = [
  'accruedInterest',
  'days',
  'dueDate',
  'feeExpected',
  'interestExpected',
  'investorInterest',
  'maturityDate',
  'number',
  'penaltyExpected',
  'principal',
  'principalExpected',
  'principalOutstanding',
  'state',
  'originatorInterest',
  'totalAccruedInterest',
  'defaultInterest',
  'totalExpected',
  'principalPaid',
  'interestPaid',
  'feePaid',
  'totalOutstanding',
  'interestRollUp',
  'disbursement',
  'totalPaid',
] as const;

export const GROUP_COLUMNS_CHECK = [
  'number',
  'days',
  'dueDate',
  'feeDue',
  'interestDue',
  'number',
  'penaltyDue',
  'principalDue',
  'defaultInterest',
  'totalExpected',
  'totalOutstanding',
  'state',
] as const;

export const EDIT_COLUMNS_CHECK = [
  'number',
  'dueDate',
  'principalDue',
  'interestDue',
  'feeDue',
] as const;

export const PENDING_LOAN_DEACTIVATED_COLUMNS_CHECK = Object.freeze([
  'principalPaid',
  'interestPaid',
  'feePaid',
  'totalOutstanding',
]);

export const COLUMNS_ORDER: Record<ColumnName, number> = (
  [
    'number',
    'disbursement',
    'dueDate',
    'days',
    'principalExpected',
    'interestExpected',
    'interestRollUp',
    'maturityDate',
    'principal',
    'accruedInterest',
    'investorInterest',
    'originatorInterest',
    'totalAccruedInterest',
    'feeExpected',
    'penaltyExpected',
    'defaultInterest',
    'totalExpected',
    'principalPaid',
    'interestPaid',
    'feePaid',
    'totalPaid',
    'principalOutstanding',
    'totalOutstanding',
    'state',
  ] as ColumnName[]
).reduce(
  (o, column, i) => {
    o[column] = i + 1;
    return o;
  },
  {} as Record<ColumnName, number>
);
