import type { ReactNode } from 'react';
import * as React from 'react';
import { createContext } from 'react';
import { GetConfigurationProductResponse } from 'kennek/interfaces/kennek/queries';
import { Currencies } from 'kennek/interfaces/loans';
import {
  DirectDebitMandateStatus,
  LoanSummary,
} from '@/interfaces/loans/queries';
import { NextPaymentData } from '@/interfaces/next-payment';

export type LoanSummaryContextType = {
  nextPaymentData: NextPaymentData;
  currency: Currencies;
  nextInstallmentPaymentDue: number;
  nextInstallmentDueDate: string;
  totalOverdueAmount: number;
  originatorName: string;
  totalGraceAmount: number;
  directDebitMandateStatus: DirectDebitMandateStatus;
  loanId: string;
  productConfig: GetConfigurationProductResponse;
};

export const LoanSummaryContext = createContext<LoanSummaryContextType>(null);

type Props = {
  loanSummary: LoanSummary;
  loanId: string;
  children: ReactNode;
  productConfig: GetConfigurationProductResponse;
};

const ContextWrapper: React.FC<Props> = ({
  loanSummary,
  loanId,
  children,
  productConfig,
}) => {
  const {
    nextPayment: nextPaymentData,
    directDebitMandateStatus,
    currency,
    nextInstallmentPaymentDue,
    nextInstallmentDueDate,
    totalOverdueAmount,
    originatorName,
    totalGraceAmount,
  } = loanSummary;
  return (
    <LoanSummaryContext.Provider
      value={{
        loanId,
        nextPaymentData,
        directDebitMandateStatus,
        currency,
        nextInstallmentPaymentDue,
        nextInstallmentDueDate,
        totalOverdueAmount,
        originatorName,
        totalGraceAmount,
        productConfig,
      }}
    >
      {children}
    </LoanSummaryContext.Provider>
  );
};

export default ContextWrapper;
