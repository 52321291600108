import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Tooltip } from 'ui';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { useGetCurrentUserApprovedLoans } from '@/hooks/useGetCurrentUserApprovedLoans';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useSelectCompanyForm } from '../onboardingSlice';
import { filterLoans } from './GroupedLoanIdField.utils';
import {
  ApprovedLoanSearchSortBy,
  ApprovedLoanSearchSortOrder,
} from '@/interfaces/loans/queries';

type LoanIdFieldProps = {
  value: string | null;
  onChange: (newValue: string) => void;
  error: boolean;
  borrowerEmail: string;
  onSetIsValid: (isValid: boolean) => void;
};

export const GroupedLoanIdField = ({
  value,
  onChange,
  borrowerEmail,
  onSetIsValid,
  error,
}: LoanIdFieldProps) => {
  const labelsConfig = useGetLabelsConfig();
  const borrowerCompany = useSelectCompanyForm();
  const [showTooltip, setShowTooltip] = useState(false);

  const [searchTerm, setSearchTerm] = useState(value ?? '');
  useEffect(
    function updateSearchTermOnValueChange() {
      if (value) {
        setSearchTerm(value);
      }
    },
    [value]
  );
  const loansQuery = useGetCurrentUserApprovedLoans({
    page: 1,
    pageSize: 100,
    // ↑ Why is page size set to 100? The endpoint we're calling here doesn't
    // support all the filtering options we need, so we're setting the page size
    // a bit higher than normal to make sure we still have data left after
    // calling `filterLoans` to filter in the frontend.
    companyName: borrowerCompany?.name,
    sortBy: ApprovedLoanSearchSortBy.DISBURSEMENT_DATE,
    sortOrder: ApprovedLoanSearchSortOrder.DESC,
  });
  const filteredLoans = filterLoans(
    loansQuery.data?.data,
    borrowerEmail,
    searchTerm
  );

  const onChangeSearchTerm = (newSearchTerm: string) => {
    if (newSearchTerm === '') {
      onChange('');
      onSetIsValid(true);
    }
    setSearchTerm(newSearchTerm);
  };

  const handleOnBlur = () => {
    if (!searchTerm) return;

    const matchingLoan = filteredLoans.find(
      (loan) => loan.id.toLowerCase() === searchTerm.toLowerCase()
    );

    if (!matchingLoan) {
      onSetIsValid(false);
    } else {
      onSetIsValid(true);
      setSearchTerm(matchingLoan.id);
      onChange(matchingLoan.id);
    }
  };

  const onSelectLoan = (loanId: string): void => {
    if (!loanId) return;
    // ↑ check because `loanId` will be empty if the user clicks on the
    // dropdown item saying "Loading…" or "Failed to fetch"

    onSetIsValid(true);
    setSearchTerm(loanId);
    onChange(loanId);
  };

  const results: { id: string; label: string }[] = useMemo(() => {
    if (loansQuery.isError) {
      return [{ id: '', label: `Failed to fetch ${labelsConfig.loansLower}` }];
    }

    if (loansQuery.isFetching) {
      return [{ id: '', label: 'Loading…' }];
    }

    return (filteredLoans ?? []).map((loan) => ({
      id: loan.id,
      label: `${loan.name} (${loan.id})`,
    }));
  }, [filteredLoans, labelsConfig, loansQuery]);

  return (
    <label className="relative heading-100 select-none mb-3">
      <div
        className="flex w-fit gap-1"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        Group with
        <div className="relative flex items-center mr-1">
          <QuestionMarkCircleIcon width={14} className="cursor-pointer" />
          {showTooltip && (
            <Tooltip position="right">
              <div className="whitespace-normal w-[200px] text-left">
                Loan ID of the loan to group with
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <Autocomplete
        value={searchTerm}
        onChange={onSelectLoan}
        onBlur={handleOnBlur}
        openOnFocus
        onSearch={onChangeSearchTerm}
        results={results}
        error={error}
        placeholder={`${labelsConfig.loanUpper} name or ID`}
        displayErrorIcon={true}
        bottomLabel="Optional"
      />
    </label>
  );
};
