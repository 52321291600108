import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  clean?: boolean;
  children: React.ReactNode;
}

const CardBody: React.FC<Props> = ({ children, className, clean }) => {
  const rootClassName = classNames(className, !clean && 'flex-1');

  return <div className={rootClassName}>{children}</div>;
};

export default CardBody;
