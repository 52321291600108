import React, { useMemo } from 'react';
import { useRouter } from 'next/navigation';
import { SvgIcon } from 'kennek/icons';
import { Button, Table } from 'ui';
import { ROUTES } from '@/constants/routes';
import { formatAmount, formatPercent } from '@/utils/formatters';
import { getRouterQuery } from '@/utils/helpers';
import { showInvestorName } from '@/utils/onboarding';
import { getInvestmentTypeLabel } from '../investments/InvestmentsForm/InvestmentsForm.utils';
import { sortInvestments } from '../investments/utils';
import {
  InvestmentType,
  InvestorDetails,
  LenderOfRecord,
} from 'kennek/interfaces/accounts/investor';
import { Currencies } from 'kennek/interfaces/loans';
import { Column } from 'ui/types';
import { LoanInvestment } from '@/interfaces/loans/queries';

export interface Props extends React.ComponentProps<React.FC> {
  title: string;
  loanInvestors: LoanInvestment[];
  isLoading?: boolean;
  loanId?: string;
}

const InvestorsTable: React.FC<Props> = ({
  title,
  loanInvestors,
  isLoading,
  loanId,
}) => {
  const router = useRouter();

  const shouldDisplayInterestColumn = loanInvestors?.some(
    (investment) => investment?.currentInterest
  );
  const shouldDisplayInvestorTypeColumn = loanInvestors.some(
    (investment) => investment.type
  );

  const sortedLoanInvestors = useMemo(() => {
    return sortInvestments(loanInvestors);
  }, [loanInvestors]);

  const columnWidthSettings = {
    withoutInterest: {
      investor: 270,
      lender: 150,
    },
    withInterest: {
      investor: 190,
      lender: 170,
    },
  };

  const currentColumnWidthSettings = shouldDisplayInterestColumn
    ? columnWidthSettings.withInterest
    : columnWidthSettings.withoutInterest;

  const investorsColumns = useMemo(() => {
    return (
      [
        {
          Header: 'Investor name',
          accessor: 'investor',
          Cell: ({
            row: {
              original: {
                investor: { details },
              },
            },
          }: {
            row: { original: { investor: { details: InvestorDetails } } };
          }) => {
            return showInvestorName(details);
          },
          maxWidth: currentColumnWidthSettings.investor,
          minWidth: currentColumnWidthSettings.investor,
        },
        {
          Header: 'Lender Of Record',
          Cell: ({
            row: {
              original: { lenderOfRecord },
            },
          }: {
            row: { original: { lenderOfRecord: LenderOfRecord } };
          }) => {
            return lenderOfRecord?.name;
          },
          maxWidth: currentColumnWidthSettings.lender,
          minWidth: currentColumnWidthSettings.lender,
        },
        shouldDisplayInvestorTypeColumn
          ? {
              Header: 'Type',
              Cell: ({
                row: {
                  original: { type },
                },
              }: {
                row: { original: { type: InvestmentType } };
              }) => {
                return type ? getInvestmentTypeLabel(type) : '-';
              },
              alignRight: true,
              maxWidth: 100,
              minWidth: 100,
            }
          : null,
        shouldDisplayInterestColumn
          ? {
              Header: 'Investor Rate',
              accessor: 'currentInterest',
              id: 'rate',
              Cell: ({
                row: {
                  original: { currentInterest },
                },
              }: {
                row: { original: { currentInterest: number } };
              }) => {
                return formatPercent(currentInterest, 2, 2);
              },
              alignRight: true,
              maxWidth: 120,
              minWidth: 120,
            }
          : null,
        {
          Header: 'Amount Invested',
          accessor: 'currentAmount',
          id: 'amount',
          Cell: ({
            row: {
              original: { currency, currentAmount },
            },
          }: {
            row: { original: { currency: Currencies; currentAmount: number } };
          }) => {
            return formatAmount(currentAmount, true, currency);
          },
          alignRight: true,
          maxWidth: 150,
          minWidth: 150,
        },
      ] as Array<Column<object>>
    ).filter(Boolean);
  }, [shouldDisplayInterestColumn, currentColumnWidthSettings]);

  return (
    <Table.Container variant="compact" isLoading={isLoading}>
      <Table.Header>
        <Table.Header.Left>
          <Table.Name
            title={title}
            leftIcon={<SvgIcon name="InvestorIcon" />}
          />
        </Table.Header.Left>
        <Table.Header.Right>
          <Button
            layout="ghost"
            size="small"
            onClick={() => {
              const path = getRouterQuery(ROUTES.MANAGE_INVESTMENTS, {
                loanId,
              });
              router.push(path);
            }}
          >
            Manage
          </Button>
        </Table.Header.Right>
      </Table.Header>

      <Table.Body<LoanInvestment>
        columns={(investorsColumns as unknown as Column<object>[]) || []}
        data={sortedLoanInvestors ?? []}
        emptyTableMessage="No investors added"
        maxHeight
      />
    </Table.Container>
  );
};

export default InvestorsTable;
