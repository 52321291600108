import React from 'react';
import classNames from 'classnames';

interface GridProps {
  justify?: 'center' | 'between';
  children?: React.ReactNode;
}

interface ColProps extends GridProps {
  direction?: 'row' | 'col';
  size?: 'main' | 'aside';
  children?: React.ReactNode;
}

export const Row: React.FC<GridProps> = (props) => {
  return <div className="flex gap-6 mb-5 justify-center">{props.children}</div>;
};

export const Col: React.FC<ColProps> = (props) => {
  return (
    <div
      className={classNames('flex', {
        'flex-col': props.direction === 'col',
        'w-[785px] gap-5': props.size === 'main',
        'w-[340px] gap-5': props.size === 'aside',
        [`justify-${props.justify}`]: !!props.justify,
      })}
    >
      {props.children}
    </div>
  );
};
