import { FC } from 'react';
import { Dialog } from 'ui';
import { UserAddIcon } from '@heroicons/react/outline';
import { UseConfirmDialogResult } from '../../../../hooks/useConfirmDialog';
import { AddInvestorForm } from '../AddInvestorForm';

export type AddInvestorDialogResult = {
  investorId: string;
  investorName: string;
};

interface AddInvestorDialogProps {
  isOpen: boolean;
  closeDialog: (
    result: UseConfirmDialogResult<AddInvestorDialogResult>
  ) => void;
}

export const AddInvestorDialog: FC<AddInvestorDialogProps> = ({
  isOpen,
  closeDialog,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => closeDialog({ confirmed: false })}
      dialogIcon={<UserAddIcon />}
      dialogIconTitle="Add an investor"
    >
      <AddInvestorForm
        onClose={() => closeDialog({ confirmed: false })}
        setOnSuccess={(investorId, investorName) =>
          closeDialog({
            confirmed: true,
            result: {
              investorId,
              investorName,
            },
          })
        }
      />
    </Dialog>
  );
};
