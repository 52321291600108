import { FC } from 'react';
import { Dialog } from 'ui';
import { UserAddIcon } from '@heroicons/react/outline';
import { UseConfirmDialogResult } from '../../../../hooks/useConfirmDialog';
import { AddLenderOfRecordForm } from '../AddLenderOfRecordForm';
import { LenderOfRecord } from 'kennek/interfaces/accounts/investor';

export type AddILenderOfRecordDialogResult = {
  lenderOfRecord: LenderOfRecord;
};

interface AddILenderOfRecordDialogProps {
  isOpen: boolean;
  closeDialog: (
    result: UseConfirmDialogResult<AddILenderOfRecordDialogResult>
  ) => void;
  originatorEncodedKey: string;
}

export const AddILenderOfRecordDialog: FC<AddILenderOfRecordDialogProps> = ({
  isOpen,
  closeDialog,
  originatorEncodedKey,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => closeDialog({ confirmed: false })}
      dialogIcon={<UserAddIcon />}
      dialogIconTitle="Add an investor"
    >
      <AddLenderOfRecordForm
        onClose={() => closeDialog({ confirmed: false })}
        originatorEncodedKey={originatorEncodedKey}
        setNewLenderOfRecord={(newLender: LenderOfRecord) =>
          closeDialog({
            confirmed: true,
            result: {
              lenderOfRecord: newLender,
            },
          })
        }
      />
    </Dialog>
  );
};
