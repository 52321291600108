import React, { useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'ui';
import { formatLineBreaks } from 'ui/utils/FormatText.utils';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

interface SummaryItemProps {
  topic?: string;
  information: string;
  boldInformation?: boolean;
  verticalGap?: 'my-1' | '-my-3' | 'my-5';
  commaInformation?: string;
}

const DirectDebitSummaryItem: React.FC<SummaryItemProps> = ({
  topic,
  information,
  verticalGap = 'my-1',
  boldInformation = false,
  commaInformation = '',
}) => {
  return (
    <div className={classNames(verticalGap)}>
      {!!topic && <div className="body-100 text-neutral-600">{topic}</div>}
      <div
        className={classNames(
          'my-1',
          boldInformation ? 'heading-200' : 'body-200'
        )}
      >
        {information}
        {commaInformation && <span>{`, ${commaInformation}`}</span>}
      </div>
    </div>
  );
};

export { DirectDebitSummaryItem };

interface OnboardingSummaryItem {
  topic?: string;
  content?: string | number;
  unit?: string;
  questionTooltip?: string;
  inline?: boolean;
  customMargin?: string;
}

const OnboardingSummaryItem = ({
  topic = '',
  content,
  unit = '',
  questionTooltip = '',
  inline = false,
  customMargin = '',
}: OnboardingSummaryItem) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <span className="heading-200 text-neutral-700">
      <div
        className={`flex items-center gap-x-1 ${customMargin}`}
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {topic}
        {questionTooltip && (
          <div className="relative flex items-center">
            <QuestionMarkCircleIcon width={14} className="cursor-pointer" />
            {showTooltip && (
              <Tooltip position="right">
                {formatLineBreaks(questionTooltip)}
              </Tooltip>
            )}
          </div>
        )}
        {inline && (
          <b>
            {content} {unit ? unit : ''}
          </b>
        )}
      </div>
      {!inline && (
        <b>
          {content} {unit ? unit : ''}
        </b>
      )}
    </span>
  );
};

export { OnboardingSummaryItem };
