import { baseAccountsApi } from '.';
import { rootApi } from '../rootApi';
import { User } from 'kennek/interfaces/accounts';
import {
  InvestorMutationPayload,
  InvestorMutationResponse,
  OriginatorInvestor,
} from 'kennek/interfaces/accounts/investor';

export const investorApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createInvestor: builder.mutation<
      InvestorMutationResponse,
      InvestorMutationPayload
    >({
      query: (payload) => ({
        url: baseAccountsApi + '/investor',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Originator-Investors'],
    }),
    getOriginatorInvestors: builder.query<
      OriginatorInvestor[],
      OriginatorInvestor['originatorEncodedKey']
    >({
      query: (branchEncodedKey) => ({
        url: baseAccountsApi + '/investor',
        method: 'GET',
        params: { branchEncodedKey },
      }),
      providesTags: ['Originator-Investors'],
    }),
    getOwnInvestors: builder.query<OriginatorInvestor, void>({
      query: () => ({
        url: baseAccountsApi + '/investor/me',
        method: 'GET',
      }),
      providesTags: ['Originator-Investors'],
    }),
    createOwnInvestorUser: builder.mutation({
      query: (body: CreateInvestorUser) => ({
        url: baseAccountsApi + '/investor/user',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Originator-Investors'],
    }),
    getOwnInvestorUsers: builder.query<User[], void>({
      query: () => ({
        url: baseAccountsApi + '/investor/users',
        method: 'GET',
      }),
      providesTags: ['Originator-Investors'],
    }),
    isEmailAddressInUse: builder.query<
      { isEmailAddressInUse: boolean },
      { email: string }
    >({
      query: ({ email }) => ({
        url: baseAccountsApi + '/investor/email',
        method: 'GET',
        params: { email },
      }),
      providesTags: ['Originator-Investors'],
    }),
    deleteOwnInvestorUser: builder.mutation<User[], { userId: string }>({
      query: ({ userId }) => ({
        url: baseAccountsApi + `/investor/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Originator-Investors'],
    }),
  }),
});

export const {
  useCreateInvestorMutation,
  useGetOriginatorInvestorsQuery,
  useGetOwnInvestorsQuery,
  useLazyIsEmailAddressInUseQuery,
  useGetOwnInvestorUsersQuery,
  useCreateOwnInvestorUserMutation,
  useDeleteOwnInvestorUserMutation,
} = investorApi;

export class CreateInvestorUser {
  firstName: string;
  lastName: string;
  email: string;
}
