import { useEffect, useState } from 'react';
import { produce } from 'immer';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { mapDtoToLoanInformationForm } from '@/features/onboarding/loan-details/LoanInformationContainer';
import { mapDtoToScheduleForm } from '@/features/onboarding/loan-details/ScheduleContainer';
import { useGetOnboardingTaskStatusesQuery } from '@/services/accounts/borrower';
import { useGetLoanProductRulesQuery, useGetLoanQuery } from '@/services/loans';
import { useGetLoanFacilitiesForLoanQuery } from '@/services/loans/facility';
import { useGetBorrowerCompanyByCompanyExternalIdQuery } from '@/services/originator/borrower-company';
import { useGetProductsByOriginatorIdQuery } from '@/services/products';
import { useSelectUser } from '@/store/user/selectors';
import { mapLoanDataToPayload } from '../Onboarding.utils';
import {
  useSelectAdditionalDetailsForm,
  useSelectIsLoanInformationFormReady,
  useSelectIsScheduleFormReady,
  useSelectLoanFacilities,
  useSelectLoanInformationForm,
  useSelectRepaymentsForm,
  useSelectShouldOverrideAdditionalDetailsWithRules,
} from '../onboardingSlice';
import {
  AdditionalDetailsForm,
  AdditionalDetailsFormType,
  AdditionalDetailsProps,
} from './AdditionalDetailsForm';
import { GetConfigurationProductResponse } from 'kennek/interfaces/kennek/queries';
import type { Loan } from '@/interfaces/loans';

type ContainerProps = Pick<
  AdditionalDetailsProps,
  'onBack' | 'onContinue' | 'onChangeLoanId'
> & {
  loanId: string;
  originatorExternalId: string;
  borrowerCompanyExternalId: string;
  borrowerEmail?: string;
  executionId?: string;
  configurationProductData: GetConfigurationProductResponse;
};

const AdditionalDetailsContainer = ({
  loanId,
  executionId,
  originatorExternalId,
  borrowerCompanyExternalId,
  borrowerEmail,
  configurationProductData,
  ...props
}: ContainerProps) => {
  const isLoanInformationFormReady = useSelectIsLoanInformationFormReady();
  const user = useSelectUser();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const isScheduleFormReady = useSelectIsScheduleFormReady();
  const { data: onboardingTasks = [], ...onboardingTasksQuery } =
    useGetOnboardingTaskStatusesQuery(
      { loanId, borrowerEmail },
      { skip: !loanId }
    );

  const { data: loan, ...loanQuery } = useGetLoanQuery(loanId, {
    skip: !loanId,
  });

  const { data: products, ...productsQuery } =
    useGetProductsByOriginatorIdQuery(
      { id: user?.mambuUser?.[0]?.mambuBranchEncodedKey },
      { skip: !user?.mambuUser?.[0]?.mambuBranchEncodedKey }
    );

  const { data: company, ...companyQuery } =
    useGetBorrowerCompanyByCompanyExternalIdQuery(
      { borrowerCompanyExternalId },
      {
        skip: !borrowerCompanyExternalId,
      }
    );

  const loanInformationForm = useSelectLoanInformationForm();

  const loanFacilitiesForm = useSelectLoanFacilities();
  const { data: loanFacilitiesStored } = useGetLoanFacilitiesForLoanQuery(
    loan?.encodedKey,
    {
      skip: !loan?.encodedKey,
    }
  );

  const [loanFacilities, setLoanFacilities] = useState(undefined);

  useEffect(() => {
    setLoanFacilities(
      (loanFacilitiesForm ?? loanFacilitiesStored)?.map(
        ({ facilityId, amount }) => ({
          facilityId,
          amount,
        })
      )
    );
  }, [loanFacilitiesStored, loanFacilitiesForm]);

  const loanInformation = loanInformationForm
    ? loanInformationForm
    : loan
      ? mapDtoToLoanInformationForm({ loan, company, products })
      : undefined;

  const scheduleForm = useSelectRepaymentsForm();

  const schedule = scheduleForm
    ? scheduleForm
    : loan
      ? mapDtoToScheduleForm(loan, 'ONBOARDING')
      : undefined;

  const { data: loanProductRules, ...rulesQuery } = useGetLoanProductRulesQuery(
    { id: loanInformation?.productTypeKey || loan?.productTypeKey },
    { skip: !loanInformation?.productTypeKey && !loan?.productTypeKey }
  );

  const additionalDetailsForm = useSelectAdditionalDetailsForm();

  const isEdit =
    onboardingTasks?.find(({ name }) => name === 'LOAN_DETAILS')?.status ===
    'COMPLETED';

  const shouldOverrideValueWithRules =
    useSelectShouldOverrideAdditionalDetailsWithRules();

  const initialFormValues = (() => {
    const loanRepaymentsInitialFormValues = additionalDetailsForm
      ? additionalDetailsForm
      : loan
        ? mapDtoToForm(loan)
        : EMPTY_FORM;

    const graceAmount = loanProductRules?.rules?.graceAmount?.defaultValue;
    const gracePeriod = loanProductRules?.rules?.gracePeriod?.defaultValue;
    const penalty = loanProductRules?.rules?.penalty?.defaultValue;

    const overrideWithRules = produce<AdditionalDetailsFormType>((draft) => {
      if (graceAmount !== null || graceAmount !== undefined) {
        draft.graceAmount = graceAmount;
      }

      if (gracePeriod !== null || gracePeriod !== undefined) {
        draft.gracePeriod = gracePeriod;
      }

      if (penalty !== null || penalty !== undefined) {
        draft.penaltyRate = penalty;
      }
    });

    return shouldOverrideValueWithRules
      ? overrideWithRules(loanRepaymentsInitialFormValues)
      : loanRepaymentsInitialFormValues;
  })();

  const isLoading =
    !isLoanInformationFormReady ||
    !isScheduleFormReady ||
    loanQuery.isFetching ||
    rulesQuery.isFetching ||
    companyQuery.isFetching ||
    onboardingTasksQuery.isFetching ||
    productsQuery.isFetching;

  if (isLoading && !isSubmittingForm)
    return (
      <div className="flex justify-center items-center">
        <LoadingAnimationPlat fitBox />
      </div>
    );

  return (
    <AdditionalDetailsForm
      loan={loan}
      isEdit={isEdit}
      loanProductRules={loanProductRules}
      initialFormValues={initialFormValues}
      getPayload={({ form: additionalDetailsForm }) =>
        mapLoanDataToPayload({
          borrowerCompanyExternalId,
          originatorExternalId,
          loan,
          loanProductRules,
          loanInformationForm: loanInformation,
          scheduleForm: schedule,
          additionalDetailsForm,
          loanFacilities,
          executionId,
          configurationProductData,
        })
      }
      scheduleForm={scheduleForm}
      formFlow="ONBOARDING"
      setIsSubmittingForm={setIsSubmittingForm}
      {...props}
    />
  );
};

export const mapDtoToForm = (
  loan: Loan
): AdditionalDetailsFormType | undefined => {
  if (!loan) return;

  return {
    graceAmount: loan.graceAmount,
    gracePeriod: loan.gracePeriod,
    penaltyRate: loan?.penaltyRate ?? loan?.interestSettings?.rate,
  };
};

export const EMPTY_FORM: AdditionalDetailsFormType = {
  gracePeriod: null,
  graceAmount: null,
  penaltyRate: null,
};

export { AdditionalDetailsContainer };
