import { useEffect, useState } from 'react';
import { useGetBrandByDomainQuery, useGetBrandQuery } from '@/services/kennek';

const useGetBrandData = (
  branchEncodedKey?: string,
  isLoadingBranchKey?: boolean
) => {
  const [domainName, setDomainName] = useState(null);
  useEffect(() => {
    // Must use useEffect -> prevent hydration error
    if (typeof window !== 'undefined') setDomainName(window.location?.host);
  }, []);

  const branchEncodedKeyExist =
    branchEncodedKey && branchEncodedKey != 'undefined'; // Sometimes, the customerId in the URL is set to 'undefined'. URL parameter is a string, we need to handle this by checking the string value.

  const {
    currentData: branDataByBranchKey,
    isLoading: isLoadingDataByBranch,
    isError: isErrorBranchKey,
    isUninitialized: isUninitializedBranchKey,
  } = useGetBrandQuery(
    { branchEncodedKey },
    { skip: !branchEncodedKeyExist || isLoadingBranchKey }
  );

  const {
    currentData: branDataByDomain,
    isLoading: isLoadingDomain,
    isError: isErrorDomain,
    isUninitialized: isUninitializedDomain,
  } = useGetBrandByDomainQuery(
    { domainName },
    { skip: !domainName || !!branchEncodedKeyExist || isLoadingBranchKey }
  );
  const isInitializing = isUninitializedBranchKey && isUninitializedDomain;

  return {
    brandData: branDataByBranchKey || branDataByDomain,
    isLoadingBranding:
      isLoadingDataByBranch || isLoadingDomain || isInitializing,
    isErrorBranding: isErrorBranchKey || isErrorDomain,
  };
};

export default useGetBrandData;
