import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import {
  ANNUAL_CALCULATION_DATES,
  FREQUENCY_LABELS,
  MONTHLY_CALCULATION_DATES,
  SCHEDULE_ANCHOR_LABELS,
} from 'kennek/constants/reports';
import {
  Button,
  FormInput,
  Modal,
  ModalTitleWithIcon,
  Select,
  SelectOption,
  Textarea,
} from 'ui';
import * as yup from 'yup';
import { PencilIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { ABSOLUTE_MAX_DATE } from '@/constants/numeric';
import { LOAN_MESSAGES } from '@/constants/onboarding';
import { useSelectScheduleForm } from '@/features/edit-loan/editLoanSlice';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import useGetLoanPreview from '@/hooks/useGetLoanPreview';
import { formatDate } from '@/utils/formatters';
import { mapOptionsToSelect } from '@/utils/map';
import { validationStandardMessages } from '../uaas/const/validationStandardMessages';
import { LabelsConfig } from 'kennek/interfaces/labelsConfig';
import { DayType, Frequency } from 'kennek/interfaces/scheduling';
import { Loan, LoanProductRules } from '@/interfaces/loans';
import {
  CalculationDate,
  Schedule,
  ScheduleAnchor,
} from '@/interfaces/schedules';

interface EditReportFormProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  report?: Schedule | {};
  isSubmitting: boolean;
  onSubmit: (report: Schedule) => void;
  defaultFinancialYearEnd?: Date;
  loanAccount?: Loan;
  formFlow?: 'DETAIL_VIEW' | 'REFINANCE';
  loanEncodedKey?: string;
  rules?: LoanProductRules;
}

const isFrequencyIsMonthly = (frequencyValue) =>
  [Frequency.QUARTERLY, Frequency.MONTHLY].includes(frequencyValue);
const isFrequencyIsAnnual = (frequencyValue) =>
  Frequency.ANNUAL === frequencyValue;

const isCustomDate = (scheduleAnchorValue) => {
  return scheduleAnchorValue === ScheduleAnchor.CUSTOM_DATE;
};

const schema = ({ borrowerUpper }: LabelsConfig) =>
  yup
    .object({
      name: yup
        .string()
        .required(validationStandardMessages.isRequired)
        .test(
          'len',
          'Name length should be less than 50 characters',
          (val) => val.length < 50
        ),
      description: yup
        .string()
        .required(validationStandardMessages.isRequired)
        .test(
          'len',
          'Description length should be less than 250 characters',
          (val) => val.length < 250
        ),
      frequency: yup.string().required('Frequency cannot be empty'),
      deadline: yup
        .object({
          days: yup
            .number()
            .typeError(validationStandardMessages.isRequired)
            .min(1, 'must be between 1 and 31')
            .max(31, 'must be between 1 and 31')
            .required(validationStandardMessages.isRequired),
          dayType: yup.string().required('Day type cannot be empty'),
          calculationDate: yup.string().required('Calculation cannot be empty'),
          scheduleAnchor: yup
            .string()
            .nullable()
            .test(
              'is-required',
              'Report request schedule anchor cannot be empty',
              (value, ctx) => {
                const isFrequencyMonthly = isFrequencyIsMonthly(
                  (ctx as any)?.from?.length
                    ? (ctx as any)?.from[1]?.value?.frequency
                    : null
                );
                // is required only if frequency is set to monthly/quoter
                return !!(value && isFrequencyMonthly) || !isFrequencyMonthly;
              }
            ),
          customDate: yup
            .date()
            .nullable()
            .transform((v) => (v instanceof Date && !isNaN(+v) ? v : null))
            .test(
              'is-required',
              'Custom date cannot be empty',
              (value, ctx) =>
                // is required only if frequency is set to monthly/quoter
                !!(
                  value &&
                  isCustomDate(ctx.parent.scheduleAnchor) &&
                  new Date(value).getFullYear() &&
                  new Date(value).getMonth() !== undefined &&
                  new Date(value).getDate()
                ) || !isCustomDate(ctx.parent.scheduleAnchor)
            )
            .test(
              'is-current-day-or-later',
              'Custom date cannot be past date',
              (value, ctx) => {
                const isFrequencyMonthly = isFrequencyIsMonthly(
                  (ctx as any)?.from?.length
                    ? (ctx as any)?.from[1]?.value?.frequency
                    : null
                );
                const scheduleAnchorSetToCustomDate = isCustomDate(
                  (ctx as any)?.from[0]?.value?.scheduleAnchor
                );
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);

                return (
                  (isFrequencyMonthly &&
                    scheduleAnchorSetToCustomDate &&
                    new Date(value) >= currentDate) ||
                  !isFrequencyMonthly ||
                  !scheduleAnchorSetToCustomDate
                );
              }
            ),
          financialYearEnd: yup
            .date()
            .nullable()
            .transform((v) => (v instanceof Date && !isNaN(+v) ? v : null))
            .test(
              'is-required',
              `${borrowerUpper} next financial year end date cannot be empty`,
              (value, ctx) =>
                // is required only if frequency is set to annual
                {
                  const isAnnual = isFrequencyIsAnnual(
                    (ctx as any)?.from?.length
                      ? (ctx as any)?.from[1]?.value?.frequency
                      : null
                  );
                  return (
                    (value && isAnnual) ||
                    !!(
                      isAnnual &&
                      value &&
                      new Date(value).getFullYear() &&
                      new Date(value).getMonth() !== undefined &&
                      new Date(value).getDate()
                    ) ||
                    !isAnnual
                  );
                }
            )
            .test(
              'is-current-day-or-later',
              `${borrowerUpper} next financial year end date cannot be past date`,
              (value, ctx) => {
                const isAnnual = isFrequencyIsAnnual(
                  (ctx as any)?.from?.length
                    ? (ctx as any)?.from[1]?.value?.frequency
                    : null
                );
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);

                return (
                  (isAnnual && new Date(value) >= currentDate) || !isAnnual
                );
              }
            ),
        })
        .required()
        .when('frequency', {
          is: (freq: string) => isFrequencyIsAnnual(freq),
          then: yup.object({
            calculationDate: yup
              .string()
              .test(
                'is-correct-value',
                'Must be selected before or after end of the financial year',
                (value) =>
                  value === CalculationDate.PRE_FINANCIAL_YEAR_END ||
                  value === CalculationDate.POST_FINANCIAL_YEAR_END
              ),
          }),
          otherwise: yup.object({
            calculationDate: yup
              .string()
              .test(
                'is-correct-value',
                'Must be selected before or after end of the month',
                (value) =>
                  value === CalculationDate.PRE_MONTH_END ||
                  value === CalculationDate.POST_MONTH_END
              ),
          }),
        }),
      remindDaysBeforeDeadline: yup
        .number()
        .min(1, 'Reminder must be between 1 and 365')
        .max(365, 'Reminder must be between 1 and 365')
        .typeError(validationStandardMessages.isRequired)
        .required(validationStandardMessages.isRequired),
    })
    .required();

function EditReportForm({
  showModal,
  setShowModal,
  isSubmitting,
  report,
  onSubmit,
  defaultFinancialYearEnd,
  loanAccount,
  formFlow,
  loanEncodedKey,
  rules,
}: EditReportFormProps) {
  const labelsConfig = useGetLabelsConfig();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    reset,
    watch,
  } = useForm({
    shouldFocusError: false,
    resolver: yupResolver(schema(labelsConfig)),
    mode: 'all',
  });
  const scheduleForm = useSelectScheduleForm();
  const isRefinance = formFlow === 'REFINANCE';

  const { loanPreview } = useGetLoanPreview(rules, loanEncodedKey, false);

  const frequencies = useMemo(
    () =>
      Array.from(new Set(Object.keys(Frequency))).map((frequency) => ({
        value: frequency,
        label: FREQUENCY_LABELS[frequency],
      })),
    []
  );

  const dateLabel = (value): string => {
    return value ? ` ${formatDate(value, 'dd/MM/yyyy')}` : '';
  };

  const scheduleAnchorOptions = useMemo(() => {
    const loanDisbursementDate =
      loanAccount?.disbursementDetails?.previewDisbursementDate ||
      loanAccount?.disbursementDetails?.expectedDisbursementDate;

    const disbursementDate = isRefinance
      ? scheduleForm?.disbursementDate
      : loanDisbursementDate;

    const maturityDate = isRefinance
      ? loanPreview?.maturityDate
      : loanAccount?.maturityDate;

    return [
      {
        value: ScheduleAnchor.DISBURSEMENT_DATE,
        label: `${SCHEDULE_ANCHOR_LABELS.DISBURSEMENT_DATE(labelsConfig.loanUpper)}${dateLabel(
          disbursementDate
        )}`,
      },
      {
        value: ScheduleAnchor.MATURITY_DATE,
        label: `${SCHEDULE_ANCHOR_LABELS.MATURITY_DATE}${dateLabel(
          maturityDate
        )}`,
      },
      {
        value: ScheduleAnchor.CUSTOM_DATE,
        label: SCHEDULE_ANCHOR_LABELS.CUSTOM_DATE,
      },
    ];
  }, [isRefinance, loanAccount, scheduleForm?.disbursementDate, loanPreview]);

  const dayTypes = useMemo(
    () =>
      Array.from(new Set(Object.values(DayType))).map((type) => ({
        value: type,
        label: (type.replaceAll('_', ' ') + ' days').toLowerCase(),
      })),
    []
  );

  const [calculationDates, setCalculationDates] = useState<SelectOption[]>([]);
  const frequency = watch('frequency', false);
  const scheduleAnchor = watch('deadline.scheduleAnchor', false);

  const setDefaultValuesForAnnualFrequency = () => {
    let defaultDate = null;
    if (defaultFinancialYearEnd) {
      const date = new Date(defaultFinancialYearEnd);
      defaultDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        ?.toISOString()
        ?.split('T')[0];
    }
    setValue('deadline.financialYearEnd', defaultDate);
    setValue('deadline.scheduleAnchor', null);
    setValue('deadline.customDate', null);
    setCalculationDates(mapOptionsToSelect(ANNUAL_CALCULATION_DATES));
  };

  const setDefaultValuesForMonthlyFrequency = () => {
    setValue('deadline.financialYearEnd', null);
    setCalculationDates(mapOptionsToSelect(MONTHLY_CALCULATION_DATES));
  };

  const setDefaultValuesForEmptyFrequency = () => {
    setValue('deadline.financialYearEnd', null);
    setValue('deadline.scheduleAnchor', null);
    setValue('deadline.customDate', null);
    setCalculationDates([]);
  };

  useEffect(() => {
    if (isFrequencyIsAnnual(frequency)) {
      setDefaultValuesForAnnualFrequency();
    } else if (isFrequencyIsMonthly(frequency)) {
      setDefaultValuesForMonthlyFrequency();
    } else {
      setDefaultValuesForEmptyFrequency();
    }
  }, [frequency]);

  useEffect(() => {
    if (report) {
      clearErrors();
      Object.entries(report).forEach(([name, value]: any) =>
        setValue(name, value)
      );
    }
  }, [setValue, report]);

  const saveReport = async (data: any) => {
    if (!isFrequencyIsAnnual(data.frequency)) {
      data.deadline.financialYearEnd = null;
    }
    if (!isFrequencyIsMonthly(data.frequency)) {
      data.deadline.scheduleAnchor = null;
    }
    if (!isCustomDate(scheduleAnchor)) {
      data.deadline.customDate = null;
    }
    onSubmit({
      ...data,
      deadline: { ...data.deadline, scheduleAnchor: scheduleAnchor },
    });
    clearErrors();
  };

  useEffect(() => {
    if (!showModal) {
      reset();
    }
  }, [showModal]);

  return (
    <Modal
      isVisible={showModal}
      closeOnClickOutside={false}
      includeHeader="withCloseButton"
      setIsVisible={setShowModal}
      className="bg-opacity-40 top-0 bg-neutral-800 overflow-y-auto z-[101]"
      paddingBodyX="px-8 py-4 pb-4 mt-0 mb-0 pt-0"
      width="w-full max-w-md"
    >
      <div className="flex flex-col">
        <ModalTitleWithIcon
          title={`${report?.['id'] ? 'Edit' : 'Create'} report`}
          icon={<PencilIcon />}
        />
        <FormInput
          label="Report name"
          placeholder="Report name"
          errors={(errors as any)?.name?.message}
          {...register('name', {})}
        />

        <div className="relative">
          <label className="heading-100 flex mb-2">Description</label>
          <Textarea
            id="description"
            name="description"
            placeholder="Report description"
            className={classNames('!border-neutral-400 mt-1.5 resize-none', {
              'outline outline-error-300 outline-offset-2 rounded-inputs pr-4':
                errors?.description,
            })}
            {...register('description', {})}
          />
          {errors?.description?.message && (
            <ExclamationCircleIcon className="absolute text-error-500 w-5 h-5 bottom-[3.3rem] right-4" />
          )}
          {errors?.description?.message ? (
            <div className="body-200 text-error-700 h-[20px] mt-2">
              {errors?.description?.message as string}
            </div>
          ) : (
            <div className="h-[24px]"></div>
          )}
        </div>
        <div className="relative">
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  label="Frequency"
                  {...field}
                  error={(errors as any)?.frequency?.message}
                  options={frequencies}
                />
              );
            }}
          />
        </div>

        <div className="flex items-top justify-between mt-1">
          <FormInput
            label="Due date"
            placeholder="Days"
            type="number"
            noMargins
            errors={(errors as any)?.deadline?.days?.message}
            {...register('deadline.days', {})}
            className="!w-[180px]"
          />

          <Controller
            name="deadline.dayType"
            control={control}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  placeholder="Select a day type"
                  error={(errors as any)?.deadline?.dayType?.message}
                  options={dayTypes}
                  className="pt-[19px] !w-[180px]"
                  handleErrorSpacing
                />
              );
            }}
          />
        </div>

        <Controller
          disabled={!frequency}
          name="deadline.calculationDate"
          control={control}
          render={({ field }) => {
            return (
              <Select
                height="82px"
                {...field}
                placeholder="Select a calculation date"
                error={(errors as any)?.deadline?.calculationDate?.message}
                options={calculationDates}
                className="pb-5"
              />
            );
          }}
        />

        <FormInput
          disabled={!isFrequencyIsAnnual(frequency)}
          label={`${labelsConfig.borrowerUpper} next financial year end`}
          {...register('deadline.financialYearEnd', {
            min: {
              value: formatDate(new Date(), 'yyyy-MM-dd'),
              message: LOAN_MESSAGES.MIN_DATE_NEXT_FINANCIAL_YEAR_END(
                formatDate(new Date(), 'dd-MM-yyyy')
              ),
            },
            max: {
              value: ABSOLUTE_MAX_DATE,
              message: LOAN_MESSAGES.MAX_DATE_ABSOLUTE(
                formatDate(ABSOLUTE_MAX_DATE, 'dd-MM-yyyy')
              ),
            },
          })}
          type="date"
          questionTooltip={`Last date of ${labelsConfig.borrowersLower}'s fiscal year`}
          errors={(errors as any)?.deadline?.financialYearEnd?.message}
          min={formatDate(new Date(), 'yyyy-MM-dd')}
          max="3000-12-31"
        />

        <Controller
          disabled={!isFrequencyIsMonthly(frequency)}
          name="deadline.scheduleAnchor"
          control={control}
          render={({ field }) => {
            return (
              <Select
                label="Report request schedule anchor"
                height="82px"
                {...field}
                placeholder="Select a calculation date"
                error={(errors as any)?.deadline?.scheduleAnchor?.message}
                options={scheduleAnchorOptions}
                className="pb-5"
                questionTooltip="Select the date which will calculate the report request schedule"
              />
            );
          }}
        />
        {scheduleAnchor === ScheduleAnchor.CUSTOM_DATE && (
          <FormInput
            label="Custom anchor date"
            {...register('deadline.customDate', {
              min: {
                value: formatDate(new Date(), 'yyyy-MM-dd'),
                message: LOAN_MESSAGES.MIN_DATE_NEXT_FINANCIAL_YEAR_END(
                  formatDate(new Date(), 'dd-MM-yyyy')
                ),
              },
              max: {
                value: ABSOLUTE_MAX_DATE,
                message: LOAN_MESSAGES.MAX_DATE_ABSOLUTE(
                  formatDate(ABSOLUTE_MAX_DATE, 'dd-MM-yyyy')
                ),
              },
            })}
            type="date"
            errors={(errors as any)?.deadline?.customDate?.message}
            min={formatDate(new Date(), 'yyyy-MM-dd')}
            max="3000-12-31"
          />
        )}

        <FormInput
          label="Reminder"
          type="number"
          placeholder="Number of days before deadline"
          questionTooltip="How many days before this report is due do you want to send a reminder"
          errors={(errors as any)?.remindDaysBeforeDeadline?.message}
          {...register('remindDaysBeforeDeadline', {})}
        />
        <div className="flex mt-2">
          <Button
            layout="outline"
            className="mr-4 w-full h-8"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="w-full h-8"
            onClick={handleSubmit(saveReport)}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default EditReportForm;
