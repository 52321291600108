import React from 'react';
import classNames from 'classnames';
import { ArrowLeftIcon } from '@heroicons/react/solid';

interface TitleWithIconProps {
  title: string;
  titleSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  icon?: React.ReactElement;
  insideCard?: boolean;
  className?: string;
  welcomeLabel?: string;
  subtitle?: string;
  showBackButton?: boolean;
  onArrowBackClick?: React.MouseEventHandler<SVGSVGElement>;
}

const TitleWithIcon: React.FC<TitleWithIconProps> = ({
  title,
  titleSize = 'lg',
  icon,
  insideCard = false,
  className = '',
  welcomeLabel,
  subtitle,
  showBackButton,
  onArrowBackClick,
}) => {
  const selectIconSize = () => {
    switch (titleSize) {
      case 'xs':
        return { text: 'heading-100', icon: 'w-5 h-5' };
      case 'sm':
        return { text: 'heading-200', icon: 'w-5 h-5' };
      case 'md':
        return { text: 'heading-300', icon: 'w-5 h-5' };
      case 'lg':
        return { text: 'heading-400', icon: 'w-6 h-6' };
      case 'xl':
        return { text: 'heading-500', icon: 'w-6 h-6' };
    }
  };

  const sizes = selectIconSize();
  const iconSize = sizes.icon;
  const textSize = sizes.text;

  let reSizedIcon = null;
  if (icon) {
    reSizedIcon = React.cloneElement(icon, {
      className: classNames('inline text-secondary-600', iconSize),
    });
  }

  return (
    <h2 className={classNames({ 'my-6': !insideCard }, className)}>
      <div className="flex items-center">
        {!!showBackButton && (
          <ArrowLeftIcon
            width={20}
            className="cursor-pointer mr-2"
            onClick={onArrowBackClick}
          />
        )}
        <div>{reSizedIcon}</div>
        <span
          className={classNames('text-secondary-600 select-none', textSize, {
            'ml-2': icon,
          })}
        >
          {title}
        </span>
        {!!welcomeLabel && (
          <span
            className={classNames(
              'text-secondary-600 select-none ml-auto',
              textSize
            )}
          >
            {welcomeLabel}
          </span>
        )}
      </div>
      {!!subtitle && (
        <div className="pt-2">
          <span className="text-secondary-600 body-500 select-none">
            {subtitle}
          </span>
        </div>
      )}
    </h2>
  );
};

export default TitleWithIcon;
