import React from 'react';
import classNames from 'classnames';
import { OnboardingSummaryItem as SummaryItem } from 'ui';
import { PencilAltIcon } from '@heroicons/react/outline';
import {
  ASSET_OCCUPANCY_STATUS_NAMES,
  getAssetLabelByType,
} from '@/constants/assetType';
import { RefinanceSteps } from '@/features/edit-loan/EditLoan';
import { OnboardingSteps } from '@/features/onboarding/Onboarding';
import { formatAmount, formatPercent } from '@/utils/formatters';
import { areNotEmpty } from '@/utils/validation';
import { Asset, AssetDetails, LoanProductRules } from '@/interfaces/loans';

interface Props {
  assets: Asset[];
  supportedAssets: AssetDetails[];
  editable: boolean;
  rules: LoanProductRules;
  setActiveStep: (step: OnboardingSteps | RefinanceSteps) => void;
}

const AssetsSummary = ({
  assets,
  supportedAssets,
  editable,
  rules,
  setActiveStep,
}: Props) => {
  const getGridChildPadding = (index: number, rules: LoanProductRules) => {
    const gridRowsNumber = 3;

    // Adjust the index if EPC facilities are enabled
    // Add 1 to the index if facilities are present
    const adjustedIndex = index + (rules?.facilities ? 1 : 0);

    // Determine if the element is the first in its row
    return adjustedIndex % gridRowsNumber === 0 ? 'pl-7' : 'pl-14';
  };

  return assets?.map((asset, index) => (
    <div
      className={classNames(
        'flex-col mt-8',
        { 'pt-[20px]': index !== 0 },
        getGridChildPadding(index, rules)
      )}
      key={asset[index]}
    >
      {index === 0 && (
        <div className="flex justify-between">
          <span className="body-200 text-neutral-700">ASSET INFORMATION</span>
          {editable && (
            <button
              onClick={() => {
                setActiveStep('LOAN_DETAILS.SECURITIES');
              }}
            >
              <PencilAltIcon height={20} className="text-secondary-500" />
            </button>
          )}
        </div>
      )}
      <p className="text-[14px] font-medium pb-[10px]">Asset {index + 1} </p>
      <div className="block">
        <SummaryItem topic="Asset Owner: " content={asset?.borrowerType} />
      </div>
      <div className="block">
        <SummaryItem
          topic="Asset Type: "
          content={getAssetLabelByType(asset?.assetType, supportedAssets) ?? ''}
        />
      </div>
      <div className="block">
        <SummaryItem
          topic="Asset Description: "
          content={asset?.assetDescription}
        />
      </div>
      {areNotEmpty(asset?.assetValuation, asset?.loanToValuePercentage) && (
        <>
          <div className="block">
            <SummaryItem
              topic="Asset Valuation: "
              content={formatAmount(
                asset?.assetValuation,
                true,
                rules?.currency
              )}
            />
          </div>
          <div className="block">
            <SummaryItem
              topic="LTV: "
              content={formatPercent(asset?.loanToValuePercentage)}
            />
          </div>
        </>
      )}
      <div className="block">
        <SummaryItem
          topic="Security Asset Address: "
          content={asset?.securityAddress}
        />
      </div>
      <div className="block">
        <div className="mb-[10px]">
          <SummaryItem
            topic="Tenanted Status: "
            content={ASSET_OCCUPANCY_STATUS_NAMES[asset?.occupancyStatus] ?? ''}
          />
        </div>
      </div>
    </div>
  ));
};

export default AssetsSummary;
