import React from 'react';
import {
  INVESTMENTS_FORM_COLUMNS_WIDTHS,
  InvestmentsFormColumName,
} from '../InvestmentsForm.utils';

type InvestmentsFormColumnsHeadingProps = {
  visibleColumns: Record<InvestmentsFormColumName, boolean>;
};
export const InvestmentsFormColumnsHeading: React.FC<
  InvestmentsFormColumnsHeadingProps
> = ({ visibleColumns }) => {
  return (
    // @refactor use HTML table structure here instead of flex.
    <div className="heading-200 rounded-lg flex bg-neutral-100 p-4">
      {visibleColumns[InvestmentsFormColumName.INVESTOR_NAME] ? (
        <div style={{ width: INVESTMENTS_FORM_COLUMNS_WIDTHS.INVESTOR_NAME }}>
          Investor
        </div>
      ) : null}
      {visibleColumns[InvestmentsFormColumName.LENDER_OF_RECORDS] ? (
        <div
          style={{ width: INVESTMENTS_FORM_COLUMNS_WIDTHS.LENDER_OF_RECORDS }}
        >
          Lender Of Record
        </div>
      ) : null}
      {visibleColumns[InvestmentsFormColumName.AMOUNT_INVESTED] ? (
        <div style={{ width: INVESTMENTS_FORM_COLUMNS_WIDTHS.AMOUNT_INVESTED }}>
          Amount Invested
        </div>
      ) : null}
      {visibleColumns[InvestmentsFormColumName.INVESTOR_RATE] ? (
        <div style={{ width: INVESTMENTS_FORM_COLUMNS_WIDTHS.INVESTOR_RATE }}>
          Investor rate
        </div>
      ) : null}
      {visibleColumns[InvestmentsFormColumName.FREQUENCY] ? (
        <div style={{ width: INVESTMENTS_FORM_COLUMNS_WIDTHS.FREQUENCY }}>
          Frequency
        </div>
      ) : null}
    </div>
  );
};
