import React from 'react';
import { Row } from 'react-table';
import classNames from 'classnames';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import DropDown, { DropDownItemType } from '../Dropdown/DropDown';

export interface DropDownTableItem
  extends Omit<DropDownItemType, 'onClickHandler'> {
  onClickHandler?: (e: any) => void;
  actionRules?: (data: any) => boolean;
}
interface Props {
  row: Row;
  verticalDotsBtn?: boolean;
  dropDownStyle?: string;
  dropDownProps?: any;
  dropDownItems?: DropDownTableItem[];
  customItemsFilter?: (
    items: DropDownTableItem[],
    row: Row
  ) => DropDownTableItem[];
}

const DropDownActions: React.VFC<Props> = ({
  row,
  verticalDotsBtn,
  dropDownItems = [],
  dropDownStyle = '',
  dropDownProps = {},
  customItemsFilter,
}: Props) => {
  const RoundButton = () => {
    return (
      <div className="text-secondary-400 rounded-full p-2 hover:bg-neutral-200 hover:bg-opacity-40">
        <DotsVerticalIcon width={20} />
      </div>
    );
  };

  const tasksFilter = (row: Row) => {
    // Workaround to filter Tasks table options in dropdown
    // TODO: Table refactor
    if (customItemsFilter) {
      return customItemsFilter(dropDownItems, row);
    }

    return dropDownItems
      .filter((item: DropDownTableItem) => {
        const rowData = row.original as { type: string; status: string };

        if (dropDownProps.isSkip) {
          if (rowData.type === 'REPORTING_SCHEDULE' && item.text === 'Skip')
            return item;
        } else if (dropDownProps.isClosed) {
          if (rowData.status !== 'LOAN_CLOSED') return item;
        } else {
          return item;
        }
      })
      .filter(
        (option) =>
          !(row as any)?.original?._ignoredDropdownOptions?.includes(
            option?.name as string
          )
      );
    // Workaround END
  };

  const getActionsList = (row: Row) => {
    const actions = tasksFilter(row).filter((ddi: DropDownTableItem) =>
      ddi?.actionRules ? ddi.actionRules(row.original) : true
    );
    return actions.map((item: DropDownTableItem) => {
      return {
        ...item,
        ...(item.onClickHandler
          ? {
              onClickHandler: () => item.onClickHandler!(row.original),
            }
          : {}),
      };
    }) as DropDownItemType[];
  };

  const filteredActions = getActionsList(row);
  const isVisible = verticalDotsBtn && filteredActions.length > 0;
  return (
    <td
      className={classNames(dropDownStyle, 'w-[1%]', {
        visible: isVisible,
        invisible: !isVisible,
      })}
    >
      <DropDown
        anchor="middle"
        button={<RoundButton />}
        dropDownItems={getActionsList(row)}
        listItems
        separator={false}
        size="lg"
        textClasses="body-400 text-black"
        {...dropDownProps}
      />
    </td>
  );
};

export default DropDownActions;
