import { Fragment, useMemo, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { Button, ModalV2 as Modal, Surface, Title } from 'ui';
import { ChevronRightIcon } from '@heroicons/react/outline';
import { getMambuProductType } from '@/components/sections/tranches/utils';
import { ROUTES } from '@/constants/routes';
import { useRole } from '@/hooks/useRole';
import { useGetLoanProductRulesQuery } from '@/services/loans';
import { useGetProductsByOriginatorIdQuery } from '@/services/products';
import { useSelectUser } from '@/store/user/selectors';
import { formatAmount, formatDate } from '@/utils/formatters';
import { getRouterQuery } from '@/utils/helpers';
import { TrancheModal, getTrancheDate } from './DisburseTrancheModal';
import { UserTypes } from 'kennek/interfaces/accounts';
import { Loan, Tranche } from '@/interfaces/loans';

type DisburseTrancheCardVariant = `servicing.${'top' | 'bottom'}` | 'borrower';

type DisburseTrancheCardProps = {
  loan: Loan;
  variant: DisburseTrancheCardVariant;
  onClickEdit: () => void;
  isDisburseButtonLoading?: boolean;
  onDisburseTrancheButtonClick: (tranche: Tranche) => void;
  productType?: string;
};

const DisburseTrancheCard = ({
  loan,
  variant,
  onClickEdit,
  isDisburseButtonLoading,
  onDisburseTrancheButtonClick,
}: DisburseTrancheCardProps) => {
  const { role } = useRole();

  const permissions = ROLES_PERMISSIONS[role];

  let tranches = loan?.tranches
    ?.filter((tranche) => !tranche.disbursed)
    ?.map((tranche) => {
      return {
        tranche,
        tranchePosition: loan?.tranches?.findIndex((t) => t === tranche),
        trancheDate: getTrancheDate(tranche),
      };
    });

  tranches = tranches?.length ? [tranches[0]] : [];

  const shouldPositionTop =
    (permissions?.includes('DISBURSE') &&
      !!tranches?.length &&
      tranches?.filter((tranche) => tranche?.trancheDate === 'future')
        ?.length === 0) ||
    loan?.state !== 'APPROVED';

  const paidOff = loan?.state === 'REPAID';
  const isLoanRestructured = loan?.state === 'RESTRUCTURED';

  const showEditButton =
    permissions?.includes('EDIT') &&
    !!tranches?.length &&
    !paidOff &&
    variant !== 'borrower' &&
    !isLoanRestructured;

  if (
    (variant === 'servicing.top' && !shouldPositionTop) ||
    (variant === 'servicing.bottom' && shouldPositionTop)
  )
    return null;

  return (
    <Surface padding="sm" border="light">
      <div
        className={classNames('flex flex-col', {
          'h-44':
            loan?.state === 'APPROVED' &&
            variant === 'servicing.top' &&
            permissions?.includes('DISBURSE'),
          'h-28':
            loan?.state === 'APPROVED' &&
            variant === 'servicing.top' &&
            !permissions?.includes('DISBURSE'),
        })}
      >
        <div
          className={classNames('flex justify-between', {
            'mb-1': variant === 'servicing.top', // smaller margin when card is in the top, so it fits in first row height of /loan-details page
            'mb-4': variant === 'servicing.bottom' || variant === 'borrower',
          })}
        >
          <Title
            icon={<SvgIcon name="DisburseIcon" />}
            title="Disbursement"
            className="!my-0"
            titleSize="lg"
          />

          {showEditButton && (
            <Button layout="ghost" size="small" onClick={onClickEdit}>
              Edit
            </Button>
          )}
        </div>

        {(!tranches?.length || paidOff) && (
          <div className="flex flex-col">
            <p className="text-neutral-800 body-500">
              There are no pending tranches to disburse
            </p>

            <div className="mt-2">
              <Link
                href={{
                  pathname:
                    variant === 'borrower'
                      ? ROUTES.TRANCHES
                      : ROUTES.SERVICING_TRANCHES,
                  query: { loanId: loan?.id },
                }}
              >
                <Button
                  layout="link"
                  size="small"
                  iconRight={<ChevronRightIcon />}
                  className="!px-0"
                >
                  See tranches history
                </Button>
              </Link>
            </div>
          </div>
        )}

        {!!tranches?.length && !paidOff && (
          <div className="flex flex-col pl-2.5 grow">
            {tranches.map((trancheItem) => {
              return (
                <Fragment key={trancheItem.tranche.id}>
                  <div className="relative">
                    <p className="mb-2 pl-5 body-100 text-neutral-800">
                      Tranche {trancheItem.tranchePosition + 1}
                    </p>
                    <div className="w-2 h-2 rounded-full bg-neutral-500 absolute -left-[3px] top-0.5" />
                  </div>

                  <div
                    className={classNames(
                      'border-l-2 border-neutral-500 pl-5',
                      {
                        'mb-auto': variant === 'servicing.top', // smaller margin when card is in the top, so it fits in first row height of /loan-details page
                        'mb-2 pb-2':
                          variant === 'servicing.bottom' ||
                          variant === 'borrower',
                      }
                    )}
                  >
                    <div className="flex gap-x-2.5">
                      <div className="flex flex-col justify-end">
                        <p className="heading-500 text-neutral-800">
                          {formatAmount(
                            trancheItem.tranche?.amount,
                            false,
                            loan?.currency
                          )}
                        </p>

                        <p className="body-100 text-neutral-800 mt-auto">
                          Disbursement amount
                        </p>
                      </div>

                      <div className="flex flex-col justify-end gap-[3px]">
                        <p className="heading-400 text-neutral-800">
                          {formatDate(
                            trancheItem.tranche?.disbursementDate,
                            'dd/MM/yyyy'
                          )}
                        </p>

                        <p className="body-100 text-neutral-800">Date</p>
                      </div>
                    </div>

                    {trancheItem.trancheDate !== 'future' &&
                      (variant === 'servicing.top' ||
                        variant === 'servicing.bottom') &&
                      permissions?.includes('DISBURSE') && (
                        <div className="my-2">
                          <Modal.Trigger asChild>
                            <Button
                              layout="primary"
                              loading={isDisburseButtonLoading}
                              onClick={() =>
                                onDisburseTrancheButtonClick(
                                  trancheItem.tranche
                                )
                              }
                            >
                              Disburse
                            </Button>
                          </Modal.Trigger>
                        </div>
                      )}
                  </div>
                </Fragment>
              );
            })}
            <div className="pl-5 relative">
              <div className="w-1 h-1 rounded-full bg-neutral-500 absolute -left-[1px] top-3" />

              <Link
                href={{
                  pathname:
                    variant === 'borrower'
                      ? ROUTES.TRANCHES
                      : ROUTES.SERVICING_TRANCHES,
                  query: { loanId: loan?.id },
                }}
              >
                <Button
                  layout="link"
                  size="small"
                  iconRight={<ChevronRightIcon />}
                  className="!px-0"
                >
                  See all tranches
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </Surface>
  );
};

type DisburseTrancheWidgetProps = {
  loan: Loan;
  variant: DisburseTrancheCardVariant;
  tranchesEditionEnabled?: boolean;
};

const DisburseTrancheWidget = ({
  loan,
  variant,
  tranchesEditionEnabled = false,
}: DisburseTrancheWidgetProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edit, setEdit] = useState<'date' | 'page'>();
  const [trancheToDisburse, setTrancheToDisburse] = useState<Tranche>(null);

  const router = useRouter();
  const hasNonDisbursedTranche = !!loan?.tranches?.find(
    (tranche) => !tranche?.disbursed
  );

  const rulesQuery = useGetLoanProductRulesQuery(
    {
      id: loan?.productTypeKey,
      action: 'RESTRUCTURE',
    },
    {
      skip:
        !(variant === 'servicing.bottom' || variant === 'servicing.top') ||
        !loan?.productTypeKey ||
        !hasNonDisbursedTranche,
    }
  );

  const user = useSelectUser();

  const { data: products } = useGetProductsByOriginatorIdQuery(
    { id: user?.mambuUser?.[0]?.mambuBranchEncodedKey },
    { skip: !user?.mambuUser?.[0]?.mambuBranchEncodedKey }
  );

  const mambuProductType = useMemo(
    () => getMambuProductType(products, loan?.productTypeKey),
    [products, loan?.productTypeKey]
  );

  const isLoadingRules = rulesQuery.isFetching && !rulesQuery.currentData;

  return (
    <TrancheModal.Root
      open={isModalOpen && !isLoadingRules}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          setEdit(undefined);
        }
        setIsModalOpen(isOpen);
      }}
    >
      <DisburseTrancheCard
        loan={loan}
        variant={variant}
        onClickEdit={() => {
          if (tranchesEditionEnabled) {
            const path = getRouterQuery(ROUTES.SERVICING_TRANCHES, {
              loanId: loan.id,
              edit: true,
            });

            router.push(path);
          } else {
            setIsModalOpen(true);
            setEdit('page');
          }
        }}
        isDisburseButtonLoading={isModalOpen && isLoadingRules}
        onDisburseTrancheButtonClick={(tranche) =>
          setTrancheToDisburse(tranche)
        }
        productType={mambuProductType}
      />

      <TrancheModal.Content>
        {edit ? (
          <>
            {edit === 'date' && (
              <TrancheModal.BackButton
                onClick={() => {
                  setEdit(undefined);
                }}
              />
            )}

            {edit === 'page' && <TrancheModal.CloseButton />}

            <TrancheModal.Edit />
          </>
        ) : (
          <TrancheModal.Disburse
            loan={loan}
            rules={rulesQuery.currentData}
            onEditDate={() => {
              setEdit('date');
            }}
            onSuccess={() => {
              setIsModalOpen(false);
              setTrancheToDisburse(null);
            }}
            tranche={trancheToDisburse}
          />
        )}
      </TrancheModal.Content>
    </TrancheModal.Root>
  );
};

export { DisburseTrancheWidget };

type Permissions = 'DISBURSE' | 'EDIT';

const ROLES_PERMISSIONS: Partial<Record<UserTypes, Permissions[]>> = {
  ORIGINATOR_ADMIN: ['DISBURSE', 'EDIT'],
  ORIGINATOR_ANALYST: ['DISBURSE', 'EDIT'],
};
