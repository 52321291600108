import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { Button, FormInput, Input, Select, Title, ToggleSwitch } from 'ui';
import { DevTool } from '@hookform/devtools';
import { getMambuProductType } from '@/components/sections/tranches/utils';
import { DEFAULT_CURRENCY } from '@/constants/currency';
import { LOAN } from '@/constants/numeric';
import { LOAN_MESSAGES } from '@/constants/onboarding';
import {
  onChangeLoanProductType as onChangeRefinanceLoanProductType,
  onEnterLoanInformation as onEnterRefinanceLoanInformation,
  onExitLoanInformation as onExitRefinanceLoanInformation,
  onLoanInformationSaved,
  useSelectShouldOverrideLoanInfoWithRules,
} from '@/features/edit-loan/editLoanSlice';
import { FeeAmortizationDetailsType } from '@/features/onboarding/loan-details/fee-amortization-section/FeeAmortizationDetailsType';
import { FeeAmortizationSectionForm } from '@/features/onboarding/loan-details/fee-amortization-section/FeeAmortizationSection';
import useGetInterestTypeLabel, {
  getInterestConfig,
} from '@/hooks/useGetInterestTypeLabel';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useLoanDetailsOverwriteLabel } from '@/hooks/useLoanDetailsOverwriteLabel';
import { useLazyValidateLoanIdQuery } from '@/services/accounts';
import { useGetConfigurationProductQuery } from '@/services/kennek';
import { useGetLoanProductRulesQuery } from '@/services/loans';
import { isFetchBaseQueryError } from '@/services/utils';
import { useAppDispatch } from '@/store/hooks';
import { useSelectUser } from '@/store/user/selectors';
import { formatAmount, formatNumber } from '@/utils/formatters';
import { parseFloatOrZero, toFloatOrDefault } from '@/utils/helpers';
import { productHasTranches } from '@/utils/loans';
import { isEmpty, isEmptyOrZero, isEmptyString } from '@/utils/validation';
import {
  onChangeLoanProductType as onChangeOnboardingLoanProductType,
  onContinueLoanInformation,
  onEnterLoanInformation as onEnterOnboardingLoanInformation,
  onExitLoanInformation as onExitOnboardingLoanInformation,
  useSelectGroupedLoanId,
} from '../onboardingSlice';
import { interestCapitalizationFrequencyOptions } from '../utils';
import { FacilitiesForm } from './FacilitiesForm';
import { GroupedLoanIdField } from './GroupedLoanIdField';
import NoProductsBanner from './NoProductsBanner';
import { FeeAmortizationForm } from './fee-amortization-section/FeeAmortizationSection.props';
import { CompanyByIdQueryResponse } from 'kennek/interfaces/originator';
import { Product } from 'kennek/interfaces/products';
import {
  InterestCapitalisationFrequency,
  Loan,
  NET_LOAN_CALCULATION_METHOD_OPTIONS,
  ProductTypesNames,
} from '@/interfaces/loans';
import { RowLoanFacility } from '@/interfaces/loans/facility';

type RawLoanInformationForm = Pick<
  LoanInformationFormType,
  | 'loanName'
  | 'interestRate'
  | 'defaultInterestRate'
  | 'productTypeKey'
  | 'externalId'
  | 'netLoanCalculationMethod'
  | 'totalRepaymentAmount'
  | 'factorAmount'
  | 'interestCapitalisationFrequency'
  | 'feeAmortizationDetails'
  | 'interestCeiling'
  | 'interestFloor'
  | 'useInitialInterestRateAsCeiling'
  | 'useInitialInterestRateAsFloor'
  | 'periodicPayment'
  | 'balloonPaymentAmount'
  | 'balloonPaymentPercentage'
> & {
  loanAmount: string;
  ltc?: string;
  gdc?: string;
  ltv?: string;
  gdv?: string;
  fees: Record<string, { amount: string; enabled: boolean }>;
  exitFeeAmount?: number;
};

export type LoanInformationFormType = {
  loanName: string;
  loanAmount: number;
  interestRate: number;
  defaultInterestRate: number;
  productTypeKey: string;
  externalId: string;
  fees: Record<string, { predefinedEncodedKey: string; amount: number }>;
  netLoanCalculationMethod?: NET_LOAN_CALCULATION_METHOD_OPTIONS;
  ltc?: number;
  gdc?: number;
  ltv?: number;
  gdv?: number;
  exitFeeAmount?: number;
  totalRepaymentAmount?: string;
  factorAmount?: number;
  interestCapitalisationFrequency?: InterestCapitalisationFrequency;
  feeAmortizationDetails?: FeeAmortizationDetailsType;
  interestCeiling?: number | null;
  interestFloor?: number | null;
  useInitialInterestRateAsCeiling?: boolean | null;
  useInitialInterestRateAsFloor?: boolean | null;
  periodicPayment?: string;
  balloonPaymentAmount?: string;
  balloonPaymentPercentage?: number;
};

export interface LoanInformationProps {
  onContinue: () => void;
  onBack: (arg?: LoanInformationFormType, fromEdit?: boolean) => void;
  onCancel?: () => void;
  setProductKey?: (prevState: string) => void;
  company: CompanyByIdQueryResponse;
  isReview: boolean;
  initialFormValues: LoanInformationFormType;
  initialLoanFacilities?: RowLoanFacility[];
  disableLoanType?: boolean;
  formFlow: 'ONBOARDING' | 'REFINANCE';
  productsTypeOptions: { value: string; label: string }[];
  productsFetched: boolean;
  originalLoan?: Loan;
  products: Product[];
  borrowerEmail: string;
  enableGroupedLoans: boolean;
}

const LoanInformationForm = ({
  onBack,
  onContinue,
  setProductKey,
  onCancel = () => {},
  company,
  isReview,
  initialFormValues,
  initialLoanFacilities,
  disableLoanType = false,
  formFlow,
  productsTypeOptions,
  productsFetched,
  originalLoan,
  products,
  borrowerEmail,
  enableGroupedLoans,
}: LoanInformationProps) => {
  const dispatch = useAppDispatch();
  const netLoanCalculationMethod = initialFormValues?.netLoanCalculationMethod;
  const user = useSelectUser();
  const labelsConfig = useGetLabelsConfig();

  const [validateLoanId] = useLazyValidateLoanIdQuery();
  const [loanIdError, setLoanIdError] = useState<string | null>(null);
  const initialGroupedLoanId = useSelectGroupedLoanId();
  const [groupedLoanId, setGroupedLoanId] = useState<string | null>(
    initialGroupedLoanId
  );
  const [groupedLoanIdIsValid, setGroupedLoanIdIsValid] = useState(true);
  const [showLtvGdv, setShowLtvGdv] = useState<boolean>(false);
  const [showLtcGdc, setShowLtcGdc] = useState<boolean>(false);
  const [disabledInterestCeiling, setDisabledInterestCeiling] = useState(
    initialFormValues?.useInitialInterestRateAsCeiling ?? false
  );
  const [disabledInterestFloor, setDisabledInterestFloor] = useState(
    initialFormValues?.useInitialInterestRateAsFloor ?? false
  );

  const router = useRouter();
  const editLoanId = router.query?.loanId as string;

  const netLoanCalculationMethodTypeOptions = [
    {
      value: NET_LOAN_CALCULATION_METHOD_OPTIONS.STATED,
      label: 'Stated',
    },
    {
      value: NET_LOAN_CALCULATION_METHOD_OPTIONS.LTV,
      label: NET_LOAN_CALCULATION_METHOD_OPTIONS.LTV,
    },
    {
      value: NET_LOAN_CALCULATION_METHOD_OPTIONS.LTC,
      label: NET_LOAN_CALCULATION_METHOD_OPTIONS.LTC,
    },
  ];

  const {
    clearErrors,
    register,
    formState: { errors, touchedFields, isDirty },
    handleSubmit,
    setValue,
    watch,
    getValues,
    trigger,
    control,
    formState,
  } = useForm<RawLoanInformationForm>({
    shouldFocusError: false,
    defaultValues: mapToRawForm(initialFormValues),
    mode: 'onChange',
    shouldUnregister: true,
  });

  const touchedFieldsRef = useRef(touchedFields);
  const productTypeKey = watch('productTypeKey');

  const loanAmount = watch('loanAmount');

  const { data: loanProductRules, ...rulesQuery } = useGetLoanProductRulesQuery(
    {
      id: productTypeKey,
      action: formFlow === 'REFINANCE' ? 'RESTRUCTURE' : 'CREATE',
    },
    { skip: !productTypeKey }
  );

  const overwriteLabel = useLoanDetailsOverwriteLabel();
  const factorAmount = watch('factorAmount');

  useEffect(() => {
    const factorVal = factorAmount
      ? parseFloat(factorAmount as unknown as string)
      : 0;
    const loanAmounVal = loanAmount ? parseFloat(loanAmount) : 0;
    const totalRepaymentAmount = factorVal + loanAmounVal;
    setValue(
      'totalRepaymentAmount',
      totalRepaymentAmount > 0 ? formatNumber(totalRepaymentAmount) : ''
    );
  }, [loanAmount, factorAmount, setValue]);

  useEffect(() => {
    setProductKey?.(productTypeKey);
  }, [setProductKey, productTypeKey]);

  useLayoutEffect(() => {
    touchedFieldsRef.current = touchedFields;
  }, [touchedFields]);

  const isDirtyRef = useRef(isDirty);

  useLayoutEffect(() => {
    isDirtyRef.current = isDirty;
  }, [isDirty]);

  useEffect(() => {
    dispatch(flowAction.enter[formFlow]());

    return () => {
      const form = mapFromRawForm(
        getValues(),
        feeAmortizationForm?.getValues()
      );

      const isDirty = isDirtyRef.current;
      if (isReview) {
        // exit in isReview flow happens only if user clicked cancel
        // on cancel we do not want to dispatch any action to the store
        return;
      }
      dispatch(
        flowAction.exit[formFlow]({
          form,
          isDirty,
        })
      );
    };
  }, [dispatch, formFlow, getValues, setShowLtvGdv, setShowLtcGdc]);

  useEffect(() => {
    // only dispatch `onChangeLoanProductType` if the loan type is changed by the user
    if (!touchedFieldsRef.current.productTypeKey) return;

    dispatch(flowAction.changeProductType[formFlow]({ productTypeKey }));
  }, [dispatch, formFlow, productTypeKey]);

  useEffect(() => {
    setShowLtvGdv(
      netLoanCalculationMethod === NET_LOAN_CALCULATION_METHOD_OPTIONS.LTV
    );
    setShowLtcGdc(
      netLoanCalculationMethod === NET_LOAN_CALCULATION_METHOD_OPTIONS.LTC
    );
  }, [netLoanCalculationMethod, setShowLtvGdv, setShowLtcGdc]);

  const branchEncodedKey = user?.mambuUser?.[0]?.mambuBranchEncodedKey;

  const { data: configurationProductData, ...configurationProductQuery } =
    useGetConfigurationProductQuery(
      {
        branchEncodedKey: branchEncodedKey,
        productEncodedKey: productTypeKey,
      },
      {
        skip: !branchEncodedKey || !productTypeKey,
      }
    );

  const isRevenueBasedFactorLoan =
    configurationProductData?.otherConfigurations?.revenueBasedFactorLoan;
  const isTranchedLoan = productHasTranches(
    configurationProductData,
    loanProductRules
  );

  const loanAmountLabel = useMemo(() => {
    return overwriteLabel.getPropertyLabel(
      `${labelsConfig.loanUpper} Amount`,
      'LOAN_AMOUNT',
      configurationProductData?.grace
    );
  }, [configurationProductData?.grace]);

  const loanAmountTooltip = useMemo(() => {
    return overwriteLabel.getPropertyTooltip(
      `Initial ${labelsConfig.loanUpper} Amount`,
      'LOAN_AMOUNT'
    );
  }, [configurationProductData?.grace]);

  const applyNetLoanCalculation = (
    data: LoanInformationFormType
  ): LoanInformationFormType => {
    switch (initialFormValues.netLoanCalculationMethod) {
      case NET_LOAN_CALCULATION_METHOD_OPTIONS.LTC: {
        return {
          ...data,
          ltc: initialFormValues.ltc,
          gdc: initialFormValues.gdc,
          netLoanCalculationMethod: initialFormValues.netLoanCalculationMethod,
        };
      }
      case NET_LOAN_CALCULATION_METHOD_OPTIONS.LTV: {
        return {
          ...data,
          ltv: initialFormValues.ltv,
          gdv: initialFormValues.gdv,
          netLoanCalculationMethod: initialFormValues.netLoanCalculationMethod,
        };
      }
      default:
        return data;
    }
  };
  const onSubmit = handleSubmit(async (data: RawLoanInformationForm) => {
    if (!groupedLoanIdIsValid) {
      return;
    }
    if (loanProductRules?.facilities) {
      // Trigger facilities form validation
      facilitiesForm.clearErrors();
      const isValid = await facilitiesForm.trigger();
      if (!isValid) {
        // Validation is handled inside FacilitiesForm
        return;
      }
    }
    if (configurationProductData?.fees?.feeAmortization) {
      // Trigger feeAmortizationForm form validation
      feeAmortizationForm.clearErrors();
      const isValid = await feeAmortizationForm.trigger();
      if (!isValid) {
        // Validation is handled inside feeAmortizationForm
        return;
      }
    }

    setProductKey?.(data?.productTypeKey);
    onContinue();
    let form = mapFromRawForm(data, feeAmortizationForm?.getValues());

    if (formFlow === 'REFINANCE') {
      form = applyNetLoanCalculation(form);
    }
    dispatch(
      flowAction.saveAndContinue[formFlow]({
        form,
        groupedLoanId,
        loanFacilities: getFacilitiesFromForm(),
        isDirty,
      })
    );
  });

  const getFacilitiesFromForm = () => {
    const formValues = facilitiesForm.getValues();
    return formValues?.data
      ? Object.keys(formValues.data).map((selectId) => {
          return {
            facilityId: formValues.data[selectId].facilityId,
            name: formValues.data[selectId].name,
            amount: formValues.data[selectId].amount,
            selectId,
            loanFacilityId: formValues.data[selectId].loanFacilityId,
          };
        })
      : [];
  };

  useEffect(() => {
    // only set default values from rules if the loan type is changed by the user
    if (!touchedFieldsRef.current.productTypeKey) return;

    if (
      loanProductRules?.rules?.loanAmount?.defaultValue &&
      formFlow === 'ONBOARDING'
    ) {
      setValue(
        'loanAmount',
        loanProductRules?.rules?.loanAmount?.defaultValue?.toString()
      );
      clearErrors('loanAmount');
    }

    if (loanProductRules?.rules?.interestRate?.defaultValue) {
      setValue(
        'interestRate',
        loanProductRules?.rules?.interestRate?.defaultValue
      );
      clearErrors('interestRate');
    }

    if (loanProductRules?.fees?.length > 0) {
      loanProductRules.fees.forEach((fee) => {
        setValue(`fees.${fee.id}.enabled`, true);
        if (fee.calculationMethod === 'FLAT' && !!fee.amount) {
          setValue(`fees.${fee.id}.amount`, fee.amount.toFixed(2).toString());
          clearErrors(`fees.${fee.id}.amount`);
        }
      });
    }
  }, [clearErrors, dispatch, loanProductRules, setValue]);
  const shouldOverrideWithRules = useSelectShouldOverrideLoanInfoWithRules();
  const updateOneTime = useRef<boolean>(true);

  if (
    rulesQuery.isSuccess &&
    shouldOverrideWithRules &&
    updateOneTime.current
  ) {
    const productLabel = productsTypeOptions.find(
      (product) => product.value === watch('productTypeKey')
    ).label;
    if (productLabel) {
      setValue('loanName', companyName(productLabel, company?.name));
    }
    if (loanProductRules?.fees?.length > 0) {
      loanProductRules.fees.forEach((fee) => {
        setValue(`fees.${fee.id}.enabled`, true);
        if (fee.calculationMethod === 'FLAT' && !!fee.amount) {
          setValue(`fees.${fee.id}.amount`, fee.amount.toFixed(2).toString());
          clearErrors(`fees.${fee.id}.amount`);
        }
      });
    }
    updateOneTime.current = false;
  }

  const handleProductTypeChange = async (e: {
    target: {
      name: string;
      id: string;
      value: Loan['productTypeKey'];
      label: string;
    };
  }) => {
    clearErrors('productTypeKey');
    setValue('productTypeKey', e.target.value);
    const newLoanName = companyName(e.target.label, company?.name);
    setValue('loanName', newLoanName);

    if (
      !configurationProductData?.otherConfigurations?.netLoanCalculationMethod
    ) {
      setValue('netLoanCalculationMethod', null);
      setValue('ltv', null);
      setValue('gdv', null);
      setValue('ltc', null);
      setValue('gdc', null);
      setShowLtvGdv(false);
      setShowLtcGdc(false);
    }
  };

  const handleNetLoanCalculationMethodTypeChange = async (e: {
    target: {
      name: string;
      id: string;
      value: Loan['netLoanCalculationMethod'];
      label: string;
    };
  }) => {
    clearErrors('netLoanCalculationMethod');
    setValue('netLoanCalculationMethod', e.target.value);
    setValue('ltv', null);
    setValue('gdv', null);
    setValue('ltc', null);
    setValue('gdc', null);
    setValue('loanAmount', '');
    setShowLtvGdv(e.target.value === NET_LOAN_CALCULATION_METHOD_OPTIONS.LTV);
    setShowLtcGdc(e.target.value === NET_LOAN_CALCULATION_METHOD_OPTIONS.LTC);
  };

  const handleNetCalculationValueChange = (
    valueType: 'ltv' | 'gdv' | 'ltc' | 'gdc'
  ) => {
    const formValues = getValues();
    const isLtvGdv = valueType === 'ltv' || valueType === 'gdv';
    const firstVal = (isLtvGdv ? formValues.ltv : formValues.ltc) ?? '0';
    const secondVal = (isLtvGdv ? formValues.gdv : formValues.gdc) ?? '0';

    if (!isNaN(Number(firstVal)) && !isNaN(Number(secondVal))) {
      setValue(isLtvGdv ? 'ltc' : 'ltv', null);
      setValue(isLtvGdv ? 'gdc' : 'gdv', null);
      setValue(
        'loanAmount',
        ((Number(firstVal) / 100) * Number(secondVal)).toString()
      );
    }
  };
  const handleDefaultInterestRate = () => {
    setValue(
      'interestRate',
      loanProductRules?.rules?.interestRate?.defaultValue
    );
    clearErrors('interestRate');
  };

  const onValidateLoanId = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value) {
      setLoanIdError(null);
      return;
    }
    validateLoanId({
      mambuBranchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
      loanExternalId: value,
    })
      .unwrap()
      .then(() => setLoanIdError(null))
      .catch((error) => {
        if (isFetchBaseQueryError(error) && error.status === 409) {
          setLoanIdError(LOAN_MESSAGES.LOAN_ID_ALREADY_EXISTS(labelsConfig));
        }
      });
  };

  const interestType = useGetInterestTypeLabel(
    getInterestConfig({ loanProductRules })
  );

  const mambuProductType = useMemo(
    () =>
      getMambuProductType(
        products,
        configurationProductData?.productEncodedKey
      ),
    [products, configurationProductData?.productEncodedKey]
  );

  //  facilities begin
  const [loanFacilities, setLoanFacilities] = useState<RowLoanFacility[]>([]);

  useEffect(() => {
    setLoanFacilities(initialLoanFacilities);
  }, [initialLoanFacilities]);

  const facilitiesForm = useForm({ shouldFocusError: false, mode: 'onChange' });
  // facilities end

  const feeAmortizationForm = useForm<FeeAmortizationForm>({
    shouldFocusError: false,
    mode: 'onChange',
    defaultValues: {
      feeAmortizationDetails: initialFormValues?.feeAmortizationDetails,
    },
  });

  const loanInformationForm = (
    <form
      className="form stepper-form"
      onSubmit={(e) => e.preventDefault()}
      autoComplete="off"
      noValidate
    >
      <div className="form-input">
        <Controller
          name="productTypeKey"
          control={control}
          rules={{
            required: LOAN_MESSAGES.REQUIRED_LOAN_TYPE(labelsConfig),
          }}
          render={({ field: { name, onBlur, onChange, ref, value } }) => (
            <Select
              label={`${labelsConfig.loanUpper} type`}
              placeholder="Select a type"
              ref={ref}
              name={name}
              error={errors?.productTypeKey?.message}
              options={productsTypeOptions}
              onChange={(e) => {
                onChange(e);
                handleProductTypeChange(e);
              }}
              onBlur={onBlur}
              value={value}
              disabled={
                !productsTypeOptions.length ||
                (isReview && formFlow === 'ONBOARDING') ||
                formFlow === 'REFINANCE' ||
                disableLoanType
              }
            />
          )}
        />

        <FormInput
          label={`${labelsConfig.loanUpper} name`}
          placeholder={`Internal ${labelsConfig.loanLower} name`}
          errors={errors?.loanName?.message}
          questionTooltip={`Name in which ${labelsConfig.loanLower} is held`}
          showXButton={!!watch('loanName')}
          handleXButton={() => setValue('loanName', '')}
          {...register('loanName', {
            required: LOAN_MESSAGES.REQUIRED_LOAN_NAME(labelsConfig),
            maxLength: {
              value: 100,
              message: 'Cannot contain more than 100 characters',
            },
          })}
        />

        <FormInput
          label={`${labelsConfig.loanUpper} ID`}
          {...register('externalId')}
          placeholder="Internal identification number"
          bottomLabel={!loanIdError ? 'Optional' : undefined}
          onBlur={onValidateLoanId}
          errors={loanIdError ?? undefined}
          containerClassName="mb-4"
        />

        {formFlow === 'ONBOARDING' && enableGroupedLoans && (
          <GroupedLoanIdField
            value={groupedLoanId}
            onChange={setGroupedLoanId}
            onSetIsValid={setGroupedLoanIdIsValid}
            error={!groupedLoanIdIsValid}
            borrowerEmail={borrowerEmail}
          />
        )}

        {formFlow === 'ONBOARDING' &&
          configurationProductData?.otherConfigurations
            ?.netLoanCalculationMethod && (
            <>
              <Controller
                name="netLoanCalculationMethod"
                control={control}
                rules={{
                  required:
                    LOAN_MESSAGES.REQUIRED_LOAN_NET_CALCULATION_TYPE(
                      labelsConfig
                    ),
                }}
                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                  <Select
                    label={`Net ${labelsConfig.loanUpper} Calculation Method`}
                    placeholder="Select a type"
                    ref={ref}
                    name={name}
                    error={errors?.netLoanCalculationMethod?.message}
                    options={netLoanCalculationMethodTypeOptions}
                    onChange={(e) => {
                      onChange(e);
                      handleNetLoanCalculationMethodTypeChange(e);
                    }}
                    onBlur={onBlur}
                    value={value}
                    disabled={!netLoanCalculationMethodTypeOptions.length}
                  />
                )}
              />

              {showLtvGdv && (
                <div className="flex justify-between">
                  <Controller
                    control={control}
                    name="ltv"
                    render={({ field: { onChange, value } }) => (
                      <FormInput
                        label="LTV"
                        leftSymbol="%"
                        questionTooltip={`${labelsConfig.loanUpper} to Value`}
                        value={value}
                        disabled={rulesQuery.isFetching}
                        errors={errors?.ltv?.message}
                        type="number"
                        className="mr-1"
                        onChange={(e) => {
                          onChange(e);
                          handleNetCalculationValueChange('ltv');
                        }}
                      />
                    )}
                    rules={{
                      required: LOAN_MESSAGES.REQUIRED_LTV,
                      max: {
                        value: LOAN.MAX_LTV,
                        message: LOAN_MESSAGES.MAX_LTV(LOAN.MAX_LTV),
                      },
                      min: {
                        value: LOAN.MIN_LTV,
                        message: LOAN_MESSAGES.MIN_LTV(LOAN.MIN_LTV),
                      },
                    }}
                  />
                  <Controller
                    control={control}
                    name="gdv"
                    render={({ field }) => (
                      <FormInput
                        label="GDV"
                        questionTooltip="Gross Development Value"
                        value={field.value}
                        currencyInput={loanProductRules?.currency}
                        disabled={rulesQuery.isFetching}
                        errors={errors?.gdv?.message}
                        type="number"
                        className="ml-1"
                        onValueChange={(value) => {
                          field.onChange(value ?? '');
                          handleNetCalculationValueChange('gdv');
                        }}
                      />
                    )}
                    rules={{
                      required: LOAN_MESSAGES.REQUIRED_GDV,
                      min: {
                        value: LOAN.MIN_GDV,
                        message: LOAN_MESSAGES.MIN_GDV(LOAN.MIN_GDV),
                      },
                    }}
                  />
                </div>
              )}
              {showLtcGdc && (
                <div className="flex">
                  <Controller
                    control={control}
                    name="ltc"
                    render={({ field: { onChange, value } }) => (
                      <FormInput
                        label="LTC"
                        leftSymbol="%"
                        questionTooltip={`${labelsConfig.loanUpper} to Cost`}
                        value={value}
                        disabled={rulesQuery.isFetching}
                        errors={errors?.ltc?.message}
                        type="number"
                        className="mr-1"
                        onChange={(e) => {
                          onChange(e);
                          handleNetCalculationValueChange('ltc');
                        }}
                      />
                    )}
                    rules={{
                      required: LOAN_MESSAGES.REQUIRED_LTC,
                      max: {
                        value: LOAN.MAX_LTC,
                        message: LOAN_MESSAGES.MAX_LTC(LOAN.MAX_LTC),
                      },
                      min: {
                        value: LOAN.MIN_LTC,
                        message: LOAN_MESSAGES.MIN_LTC(LOAN.MIN_LTC),
                      },
                    }}
                  />
                  <Controller
                    control={control}
                    name="gdc"
                    render={({ field }) => (
                      <FormInput
                        label="GDC"
                        disabled={rulesQuery.isFetching}
                        errors={errors?.gdc?.message}
                        questionTooltip="Gross Development Cost"
                        value={field.value}
                        currencyInput={loanProductRules?.currency}
                        type="number"
                        className="ml-1"
                        onValueChange={(value) => {
                          field.onChange(value ?? '');
                          handleNetCalculationValueChange('gdc');
                        }}
                      />
                    )}
                    rules={{
                      required: LOAN_MESSAGES.REQUIRED_GDC,
                      min: {
                        value: LOAN.MIN_GDC,
                        message: LOAN_MESSAGES.MIN_GDC(LOAN.MIN_GDC),
                      },
                    }}
                  />
                </div>
              )}
            </>
          )}

        <Controller
          control={control}
          name="loanAmount"
          render={({ field }) => (
            <FormInput
              label={loanAmountLabel}
              bottomLabel={
                formFlow === 'REFINANCE'
                  ? formatAmount(
                      originalLoan?.loanAmount,
                      true,
                      loanProductRules?.currency
                    )
                  : null
              }
              bottomLabelTopic={
                formFlow === 'REFINANCE'
                  ? `Original ${labelsConfig.loanLower} amount: `
                  : null
              }
              currencyInput={loanProductRules?.currency}
              disabled={
                rulesQuery.isFetching ||
                formFlow === 'REFINANCE' ||
                rulesQuery.isUninitialized ||
                showLtcGdc ||
                showLtvGdv
              }
              errors={errors?.loanAmount?.message}
              questionTooltip={loanAmountTooltip}
              value={field.value}
              onValueChange={(value) => {
                const balloonPaymentPercentage = getValues(
                  'balloonPaymentPercentage'
                );
                const parsedBalloonPaymentPercentage = parseFloatOrZero(
                  balloonPaymentPercentage
                );
                field.onChange(value ?? '');
                if (parsedBalloonPaymentPercentage > 0) {
                  const loanAmount = parseFloatOrZero(value);
                  const balloonPaymentAmount = (
                    (parsedBalloonPaymentPercentage / 100) *
                    loanAmount
                  ).toFixed(2);
                  setValue('balloonPaymentAmount', balloonPaymentAmount);
                }
              }}
              defaultValue={
                formFlow === 'ONBOARDING' &&
                !showLtcGdc &&
                !showLtvGdv &&
                loanProductRules?.rules?.loanAmount?.defaultValue !==
                  parseFloat(field.value) &&
                loanProductRules?.rules?.loanAmount?.defaultValue
              }
              defaultValueSetter={() =>
                field.onChange(
                  loanProductRules?.rules?.loanAmount?.defaultValue?.toString()
                )
              }
              showEditButton={formFlow === 'REFINANCE'}
              handleEditButton={() =>
                onBack(
                  formFlow === 'REFINANCE'
                    ? mapFromRawForm(
                        getValues(),
                        feeAmortizationForm?.getValues()
                      )
                    : null,
                  true
                )
              }
            />
          )}
          rules={{
            required: LOAN_MESSAGES.REQUIRED_LOAN_AMOUNT(labelsConfig),
            max: {
              value: loanProductRules?.rules?.loanAmount?.maxValue
                ? loanProductRules?.rules?.loanAmount?.maxValue
                : LOAN.MAX_LOAN_AMOUNT,
              message: LOAN_MESSAGES.MAX_VALUE_LOAN_AMOUNT(
                loanProductRules?.rules?.loanAmount?.maxValue ||
                  LOAN.MAX_LOAN_AMOUNT,
                loanProductRules?.currency,
                labelsConfig
              ),
            },
            min: {
              value: loanProductRules?.rules?.loanAmount?.minValue
                ? loanProductRules?.rules?.loanAmount?.minValue
                : LOAN.MIN_LOAN_AMOUNT,
              message: LOAN_MESSAGES.MIN_VALUE_LOAN_AMOUNT(
                loanProductRules?.rules?.loanAmount?.minValue ||
                  LOAN.MIN_LOAN_AMOUNT,
                loanProductRules?.currency,
                labelsConfig
              ),
            },
          }}
        />

        {((formFlow === 'ONBOARDING' &&
          loanProductRules?.interestRateSource === 'INDEX_INTEREST_RATE') ||
          loanProductRules?.platformIndexRateEnabled) && (
          <div className="mb-4">
            <p className="heading-100">Base Index Rate</p>
            <p className="mt-1">{loanProductRules?.baseIndexRate}</p>
          </div>
        )}

        {!loanProductRules?.factorLoan && !isRevenueBasedFactorLoan && (
          <FormInput
            label={
              loanProductRules?.interestRateSource === 'INDEX_INTEREST_RATE' ||
              loanProductRules?.platformIndexRateEnabled
                ? 'Interest Spread'
                : 'Interest rate'
            }
            leftSymbol="%"
            disabled={rulesQuery.isFetching}
            errors={errors?.interestRate?.message}
            type="number"
            containerClassName="mb-4"
            bottomLabel={interestType}
            bottomLabelTopic="Type: "
            defaultValue={
              loanProductRules?.rules?.interestRate?.defaultValue !==
                watch('interestRate') &&
              loanProductRules?.rules?.interestRate?.defaultValue
            }
            defaultValueSetter={handleDefaultInterestRate}
            {...register('interestRate', {
              valueAsNumber: true,
              required: LOAN_MESSAGES.REQUIRED_INTEREST_RATE,
              max: {
                value: loanProductRules?.rules?.interestRate?.maxValue
                  ? loanProductRules?.rules?.interestRate?.maxValue
                  : LOAN.MAX_INTEREST_RATE,
                message: LOAN_MESSAGES.MAX_VALUE_INTEREST_RATE(
                  loanProductRules?.rules?.interestRate?.maxValue ||
                    LOAN.MAX_INTEREST_RATE
                ),
              },
              min: {
                value: loanProductRules?.rules?.interestRate?.minValue
                  ? loanProductRules?.rules?.interestRate?.minValue
                  : LOAN.MIN_INTEREST_RATE,
                message: LOAN_MESSAGES.MIN_VALUE_INTEREST_RATE(
                  loanProductRules?.rules?.interestRate?.minValue ||
                    LOAN.MIN_INTEREST_RATE
                ),
              },
            })}
            onChange={(e) => {
              if (disabledInterestFloor) {
                setValue(
                  'interestFloor',
                  e.target.value ? parseFloat(e.target.value) : null,
                  { shouldValidate: true }
                );
              }
              if (disabledInterestCeiling) {
                setValue(
                  'interestCeiling',
                  e.target.value ? parseFloat(e.target.value) : null,
                  { shouldValidate: true }
                );
              }
              register('interestRate').onChange(e);
            }}
          />
        )}

        {loanProductRules?.balloonPaymentEnabled && (
          <>
            <Controller
              control={control}
              name="periodicPayment"
              rules={{
                validate: {
                  required: (value) => {
                    if (isEmpty(value) || isEmptyString(value)) {
                      return LOAN_MESSAGES.REQUIRED_PERIODIC_PAYMENT;
                    }
                    return true;
                  },
                  max: (value) => {
                    const parsed = parseFloatOrZero(value.toString());
                    if (isNaN(parsed) || parsed > LOAN.MAX_PERIODIC_PAYMENT) {
                      return LOAN_MESSAGES.MAX_VALUE_PERIODIC_PAYMENT(
                        LOAN.MAX_PERIODIC_PAYMENT
                      );
                    }
                    return true;
                  },
                  min: (value) => {
                    const parsed = parseFloatOrZero(value.toString());
                    if (isNaN(parsed) || parsed < LOAN.MIN_PERIODIC_PAYMENT) {
                      return LOAN_MESSAGES.MIN_VALUE_PERIODIC_PAYMENT(
                        LOAN.MIN_PERIODIC_PAYMENT
                      );
                    }
                    return true;
                  },
                },
              }}
              render={({ field }) => (
                <FormInput
                  ref={field.ref}
                  label="Periodic payment amount"
                  currencyInput={loanProductRules?.currency || DEFAULT_CURRENCY}
                  name={field.name}
                  value={field.value}
                  type="string"
                  onValueChange={(value) => field.onChange(value ?? '')}
                  onBlur={field.onBlur}
                  errors={formState.errors?.periodicPayment?.message}
                />
              )}
            />

            <Controller
              control={control}
              name="balloonPaymentAmount"
              rules={{
                validate: {
                  max: (value) => {
                    const parsed = parseFloatOrZero(value?.toString());
                    const loanAmount = parseFloatOrZero(
                      getValues('loanAmount')
                    );
                    if (isEmptyOrZero(value)) {
                      return true;
                    }
                    if (isNaN(parsed) || loanAmount < parsed) {
                      return LOAN_MESSAGES.MAX_VALUE_BALLOON_PAYMENT(
                        loanAmount
                      );
                    }
                    return true;
                  },
                  min: (value) => {
                    const parsed = parseFloatOrZero(value?.toString());
                    if (isEmptyOrZero(value)) {
                      return true;
                    }
                    if (isNaN(parsed) || parsed < 0) {
                      return LOAN_MESSAGES.MIN_VALUE_BALLOON_PAYMENT(0);
                    }
                  },
                },
              }}
              render={({ field }) => (
                <FormInput
                  ref={field.ref}
                  label="Balloon payment"
                  currencyInput={loanProductRules?.currency || DEFAULT_CURRENCY}
                  name={field.name}
                  value={field.value}
                  type="number"
                  onValueChange={(value) => {
                    if (isEmptyOrZero(value)) {
                      setValue('balloonPaymentPercentage', null);
                      field.onChange(value ?? '');
                      return;
                    }
                    const parsed = parseFloatOrZero(value?.toString());
                    const loanAmount = parseFloatOrZero(
                      getValues('loanAmount')
                    );
                    const percentage = ((parsed / loanAmount) * 100).toFixed(4);
                    setValue(
                      'balloonPaymentPercentage',
                      parseFloat(percentage)
                    );
                    return field.onChange(value ?? '');
                  }}
                  onBlur={field.onBlur}
                  errors={formState.errors?.balloonPaymentAmount?.message}
                />
              )}
            />

            <Controller
              control={control}
              name="balloonPaymentPercentage"
              rules={{
                required: false,
                min: 0,
                max: 100,
              }}
              render={({ field }) => (
                <FormInput
                  ref={field.ref}
                  label="Balloon payment"
                  leftSymbol="%"
                  name={field.name}
                  value={field.value}
                  type="number"
                  onChange={(event) => {
                    const value = event.target.value;
                    if (isEmptyOrZero(value)) {
                      setValue('balloonPaymentAmount', null);
                      field.onChange(value ?? '');
                      return;
                    }
                    const parsed = parseFloatOrZero(value);
                    const loanAmount = getValues('loanAmount');
                    const parsedLoanAmount = parseFloatOrZero(loanAmount);
                    const flatAmount = (
                      parsedLoanAmount *
                      (parsed / 100)
                    ).toFixed(2);
                    field.onChange(value ?? '');
                    setValue('balloonPaymentAmount', flatAmount);
                  }}
                  onBlur={field.onBlur}
                  errors={formState.errors?.balloonPaymentPercentage?.message}
                />
              )}
            />
          </>
        )}

        {(loanProductRules?.productType ===
          ProductTypesNames.REVOLVING_CREDIT ||
          loanProductRules?.productType ===
            ProductTypesNames.DYNAMIC_TERM_LOAN) &&
          (loanProductRules?.interestRateSource === 'INDEX_INTEREST_RATE' ||
            loanProductRules?.platformIndexRateEnabled) && (
            <>
              <FormInput
                label="Floor"
                leftSymbol="%"
                disabled={rulesQuery.isFetching || disabledInterestFloor}
                errors={errors?.interestFloor?.message}
                type="number"
                containerClassName="mb-0"
                placeholder={disabledInterestFloor ? '' : 'X.XX'}
                withSpacing={!!errors?.interestFloor?.message}
                {...register('interestFloor', {
                  valueAsNumber: true,
                  validate: {
                    min: (value) => {
                      if (isEmpty(value)) return true;
                      if (value < 0) {
                        return 'The floor cannot be lower than 0%';
                      }
                      return true;
                    },
                    max: (value) => {
                      if (isEmpty(value)) return true;
                      if (value > LOAN.MAX_INTEREST_RATE) {
                        return `The floor cannot be greater than ${LOAN.MAX_INTEREST_RATE}`;
                      }
                      if (
                        !isEmpty(getValues('interestCeiling')) &&
                        value > getValues('interestCeiling')
                      ) {
                        return 'The floor cannot be greater than the ceiling.';
                      }
                      return true;
                    },
                  },
                })}
              />
              <label
                htmlFor="interestFloor"
                className="relative flex items-center mt-0 mb-4 body-500"
              >
                <Controller
                  name="useInitialInterestRateAsFloor"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="interestFloor"
                      type="checkbox"
                      className="mr-2"
                      ref={field.ref}
                      checked={field.value}
                      onChange={(e) => {
                        setDisabledInterestFloor(e.currentTarget.checked);
                        if (e.currentTarget.checked) {
                          setValue('interestFloor', null, {
                            shouldValidate: true,
                          });
                        }
                        trigger('interestCeiling');
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                <span className="body-400">Use initial rate as floor</span>
              </label>

              <FormInput
                label="Ceiling"
                leftSymbol="%"
                disabled={rulesQuery.isFetching || disabledInterestCeiling}
                errors={errors?.interestCeiling?.message}
                type="number"
                containerClassName="mb-0"
                placeholder={disabledInterestCeiling ? '' : 'X.XX'}
                withSpacing={!!errors?.interestCeiling?.message}
                {...register('interestCeiling', {
                  valueAsNumber: true,
                  validate: {
                    min: (value) => {
                      if (isEmpty(value)) return true;
                      if (
                        !isEmpty(getValues('interestFloor')) &&
                        value < getValues('interestFloor')
                      ) {
                        return 'The ceiling cannot be lower than the floor';
                      }
                      if (value < 0) {
                        return 'The ceiling cannot be lower than 0%';
                      }
                      if (value === 0) {
                        return 'The ceiling cannot be 0%';
                      }
                      return true;
                    },
                    max: (value) => {
                      if (isEmpty(value)) return true;
                      if (value > 10000) {
                        return `The ceiling cannot be greater than ${LOAN.MAX_INTEREST_RATE}`;
                      }
                      return true;
                    },
                  },
                })}
              />
              <label
                htmlFor="interestCeiling"
                className="relative flex items-center mt-0 mb-4 body-500"
              >
                <Controller
                  name="useInitialInterestRateAsCeiling"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="interestCeiling"
                      type="checkbox"
                      className="mr-2"
                      ref={field.ref}
                      checked={field.value ?? undefined}
                      onChange={(e) => {
                        setDisabledInterestCeiling(e.currentTarget.checked);
                        if (e.currentTarget.checked) {
                          setValue('interestCeiling', null, {
                            shouldValidate: true,
                          });
                        }
                        trigger('interestFloor');
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                <span className="body-400">Use initial rate as ceiling</span>
              </label>

              <p className="body-300 text-neutral-600 mb-4">
                If changes in the base rate nor spread cause the rate to exceed
                the ceiling or floor, the system will automatically make an
                adjustment to align the rate with the ceiling nor floor.
              </p>
            </>
          )}

        <FeeAmortizationSectionForm
          isEpcFeeAmortizationEnabled={
            configurationProductData?.fees?.feeAmortization ?? false
          }
          form={feeAmortizationForm}
          isFetching={configurationProductQuery.isFetching}
        />

        {loanProductRules?.defaultInterestRate ? (
          <FormInput
            {...register('defaultInterestRate', {
              valueAsNumber: true,
              required: LOAN_MESSAGES.REQUIRED_DEFAULT_INTEREST_RATE,
              max: {
                value: loanProductRules?.rules?.interestRate?.maxValue
                  ? loanProductRules?.rules?.interestRate?.maxValue
                  : LOAN.MAX_INTEREST_RATE,
                message: LOAN_MESSAGES.MAX_VALUE_DEFAULT_INTEREST_RATE(
                  loanProductRules?.rules?.interestRate?.maxValue ||
                    LOAN.MAX_INTEREST_RATE
                ),
              },
              min: {
                value: loanProductRules?.rules?.interestRate?.minValue
                  ? loanProductRules?.rules?.interestRate?.minValue
                  : LOAN.MIN_INTEREST_RATE,
                message: LOAN_MESSAGES.MIN_VALUE_DEFAULT_INTEREST_RATE(
                  loanProductRules?.rules?.interestRate?.minValue ||
                    LOAN.MIN_INTEREST_RATE
                ),
              },
            })}
            label="Default rate"
            leftSymbol="%"
            disabled={rulesQuery.isFetching}
            errors={errors?.defaultInterestRate?.message}
            type="number"
            containerClassName="mb-4"
          />
        ) : null}

        {loanProductRules?.rollUp &&
          mambuProductType === 'REVOLVING_CREDIT' && (
            <Controller
              name="interestCapitalisationFrequency"
              control={control}
              rules={{
                required:
                  LOAN_MESSAGES.REQUIRED_INTEREST_CAPITALIZATION_FREQUENCY,
              }}
              render={({ field: { name, onBlur, onChange, ref, value } }) => (
                <Select
                  label="Interest capitalization frequency"
                  placeholder="Select a interest capitalization frequency"
                  ref={ref}
                  name={name}
                  questionTooltip="Period during which interest is added to the Outstanding balance"
                  error={errors?.interestCapitalisationFrequency?.message}
                  options={interestCapitalizationFrequencyOptions}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          )}
        {loanProductRules?.factorLoan && (
          <Controller
            control={control}
            name={`factorAmount`}
            render={({ field }) => (
              <FormInput
                currencyInput={loanProductRules?.currency}
                label="Factor Amount"
                disabled={rulesQuery.isFetching}
                errors={errors?.factorAmount?.message}
                type="number"
                name={field.name}
                value={field.value}
                onValueChange={(value) => field.onChange(value ?? '')}
                onBlur={field.onBlur}
                autoComplete="off"
              />
            )}
            rules={{
              required: `Factor Amount cannot be empty`,
              validate: {
                min: (value) =>
                  parseFloat(value as unknown as string) > 0
                    ? true
                    : `Factor Amount cannot be less than ${formatAmount(
                        0,
                        false,
                        loanProductRules?.currency
                      )}`,
              },
            }}
          />
        )}
        {formFlow === 'REFINANCE' &&
          originalLoan?.interestSettings?.displayedInterestRate >= 0 && (
            <div
              className={classNames({
                'mb-[13px]':
                  formFlow === 'REFINANCE' && !errors?.interestRate?.message,
              })}
            />
          )}

        {!isTranchedLoan &&
          loanProductRules?.fees?.map(
            ({
              amount,
              calculationMethod,
              id,
              name,
              percentageAmount,
              required,
            }) => (
              <Controller
                key={id}
                control={control}
                name={`fees.${id}.enabled`}
                render={({ field: enabled }) => {
                  return (
                    <div className="relative">
                      {calculationMethod === 'PERCENTAGE' && (
                        <FormInput
                          currencyInput={loanProductRules?.currency}
                          label={name}
                          {...register('fees')}
                          value={(() => {
                            if (!enabled.value) return '';

                            const loanAmount = parseFloat(watch('loanAmount'));
                            if (Number.isNaN(loanAmount)) return '';
                            return loanAmount * (percentageAmount / 100) || 0;
                          })()}
                          disabled
                          type="number"
                          bottomLabel={`${
                            percentageAmount || 0
                          }% of ${labelsConfig.loanLower} amount`}
                        />
                      )}

                      {calculationMethod === 'FLAT' && (
                        <Controller
                          control={control}
                          name={`fees.${id}.amount`}
                          render={({ field }) => (
                            <FormInput
                              currencyInput={loanProductRules?.currency}
                              label={name}
                              disabled={
                                rulesQuery.isFetching ||
                                !watch(`fees.${id}.enabled`) ||
                                amount > 0
                              }
                              errors={errors?.fees?.[id]?.amount?.message}
                              type="number"
                              name={field.name}
                              value={field.value}
                              onValueChange={(value) =>
                                field.onChange(value ?? '')
                              }
                              onBlur={field.onBlur}
                              autoComplete="off"
                            />
                          )}
                          rules={{
                            required: {
                              value: watch(`fees.${id}.enabled`) && amount <= 0,
                              message: `${name} cannot be empty`,
                            },
                            validate: {
                              min: (value) =>
                                parseFloat(value) > 0 ||
                                !watch(`fees.${id}.enabled`) ||
                                amount > 0
                                  ? true
                                  : `${name} cannot be less than ${formatAmount(
                                      0,
                                      false,
                                      loanProductRules?.currency
                                    )}`,
                            },
                          }}
                        />
                      )}

                      <div
                        className={classNames('absolute -right-12 top-8', {
                          hidden: required,
                        })}
                      >
                        <ToggleSwitch
                          size="sm"
                          enabled={enabled.value}
                          onChange={(value) => {
                            clearErrors(`fees.${id}.amount`);
                            enabled.onChange(value);
                          }}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            )
          )}

        {/* Late repayment fees are not meant to be editable or sent to the BE, it's just a preview */}
        {!isTranchedLoan &&
          loanProductRules?.lateRepaymentFees?.map(
            ({ amount, calculationMethod, id, name, percentageAmount }) => (
              <Controller
                key={id}
                control={control}
                name={`fees.${id}.enabled`}
                render={({ field: enabled }) => {
                  return (
                    <div className="relative">
                      {calculationMethod === 'PERCENTAGE' && (
                        <FormInput
                          currencyInput={loanProductRules?.currency}
                          label={name}
                          {...register('fees')}
                          value={(() => {
                            if (!enabled.value) return '';

                            const loanAmount = parseFloat(watch('loanAmount'));
                            if (Number.isNaN(loanAmount)) return '';
                            return loanAmount * (percentageAmount / 100) || 0;
                          })()}
                          disabled
                          type="number"
                          bottomLabel={`${
                            percentageAmount || 0
                          }% of ${labelsConfig.loanLower} amount`}
                        />
                      )}

                      {calculationMethod === 'FLAT' && (
                        <Controller
                          control={control}
                          name={`fees.${id}.amount`}
                          render={({ field }) => (
                            <FormInput
                              currencyInput={loanProductRules?.currency}
                              label={name}
                              errors={errors?.fees?.[id]?.amount?.message}
                              type="number"
                              name={field.name}
                              value={amount}
                              disabled
                              autoComplete="off"
                            />
                          )}
                        />
                      )}
                    </div>
                  );
                }}
              />
            )
          )}

        {loanProductRules?.exitFeeEnabled && (
          <Controller
            name="exitFeeAmount"
            control={control}
            render={({ field }) => {
              return (
                <FormInput
                  label="Exit fee"
                  questionTooltip="Fee applied on last instalment."
                  type="number"
                  currencyInput={loanProductRules?.currency}
                  errors={errors?.exitFeeAmount?.message}
                  onValueChange={(value) => {
                    field.onChange(value ?? '');
                  }}
                  value={field.value}
                />
              );
            }}
          />
        )}

        {loanProductRules?.factorLoan && (
          <FormInput
            label="Total repayment amount"
            {...register('totalRepaymentAmount')}
            leftSymbol="£"
            name="totalRepaymentAmount"
            id="totalRepaymentAmount"
            disabled
            type="text"
          />
        )}
      </div>
      {loanProductRules?.facilities && (
        <div>
          <FacilitiesForm
            onContinue={onContinue}
            onBack={onBack}
            form={facilitiesForm}
            loanId={editLoanId}
            availableAmount={parseFloat(loanAmount) || 0}
            assignedBranchKey={branchEncodedKey}
            productTypeKey={productTypeKey}
            initialStateValues={loanFacilities}
            disablePreload={
              formFlow === 'REFINANCE' &&
              loanProductRules.productType ===
                ProductTypesNames.REVOLVING_CREDIT
            }
          />
        </div>
      )}

      <div className="flex justify-between">
        {isReview ? (
          <Button layout="ghost" onClick={onCancel}>
            Cancel
          </Button>
        ) : (
          <Button
            layout="ghost"
            onClick={() =>
              onBack(
                formFlow === 'REFINANCE'
                  ? mapFromRawForm(
                      getValues(),
                      feeAmortizationForm?.getValues()
                    )
                  : null
              )
            }
          >
            Back
          </Button>
        )}

        {!(productsFetched && !productsTypeOptions.length) && (
          <Button
            layout="primary"
            onClick={onSubmit}
            disabled={
              !productsTypeOptions.length ||
              !!loanIdError ||
              rulesQuery.isFetching
            }
          >
            Continue
          </Button>
        )}
      </div>

      <DevTool control={control} />
    </form>
  );

  return (
    <div className="flex flex-col gap-8">
      <Title
        title={`${labelsConfig.loanUpper} Information`}
        icon={<SvgIcon name="StatementIcon" />}
        titleSize="lg"
        className="mt-2 mb-1"
      />

      {productsFetched && !productsTypeOptions.length ? (
        <NoProductsBanner />
      ) : (
        loanInformationForm
      )}
    </div>
  );
};

export { LoanInformationForm };

const mapToRawForm = (
  form: LoanInformationFormType
): RawLoanInformationForm => ({
  ...form,
  loanAmount: form.loanAmount?.toString(),
  ltv: form.ltv?.toString(),
  gdv: form.gdv?.toString(),
  ltc: form.ltc?.toString(),
  gdc: form.gdc?.toString(),
  fees: Object.entries(form?.fees ?? {}).reduce(
    (acc, [predefinedFeeEncodedKey, content]) => ({
      ...acc,
      [predefinedFeeEncodedKey]: {
        amount: content?.amount?.toString(),
        enabled: true,
      },
    }),
    {} as Record<string, { amount: string; enabled: boolean }>
  ),
  feeAmortizationDetails: form?.feeAmortizationDetails,
  interestCeiling: form?.interestCeiling,
  interestFloor: form?.interestFloor,
  useInitialInterestRateAsCeiling: form?.useInitialInterestRateAsCeiling,
  useInitialInterestRateAsFloor: form?.useInitialInterestRateAsFloor,
  periodicPayment: form?.periodicPayment,
  balloonPaymentAmount: form?.balloonPaymentAmount,
  balloonPaymentPercentage: form?.balloonPaymentPercentage,
});

const mapFromRawForm = (
  form: RawLoanInformationForm,
  feeAmortizationDetails: FeeAmortizationForm
): LoanInformationFormType => ({
  ...form,
  loanAmount: toFloatOrDefault(form.loanAmount),
  ltv: toFloatOrDefault(form.ltv),
  gdv: toFloatOrDefault(form.gdv),
  ltc: toFloatOrDefault(form.ltc),
  gdc: toFloatOrDefault(form.gdc),
  fees: Object.entries(form?.fees ?? {}).reduce(
    (acc, [predefinedFeeEncodedKey, rawContent]) => {
      const amount = toFloatOrDefault(rawContent.amount);
      return rawContent.enabled
        ? { ...acc, [predefinedFeeEncodedKey]: { amount } }
        : acc;
    },
    {}
  ),
  exitFeeAmount: form?.exitFeeAmount as number,
  factorAmount: form?.factorAmount,
  feeAmortizationDetails: feeAmortizationDetails?.feeAmortizationDetails,
  interestCeiling: form?.interestCeiling,
  interestFloor: form?.interestFloor,
  totalRepaymentAmount: form?.totalRepaymentAmount,
  useInitialInterestRateAsCeiling: form?.useInitialInterestRateAsCeiling,
  useInitialInterestRateAsFloor: form?.useInitialInterestRateAsFloor,
  periodicPayment: form?.periodicPayment,
  balloonPaymentAmount: form?.balloonPaymentAmount,
  balloonPaymentPercentage: form?.balloonPaymentPercentage,
});

const companyName = (loanType: string, companyName?: string) =>
  `${companyName || 'company'}_${loanType}`;

const flowAction = {
  enter: {
    ONBOARDING: onEnterOnboardingLoanInformation,
    REFINANCE: onEnterRefinanceLoanInformation,
  },
  exit: {
    ONBOARDING: onExitOnboardingLoanInformation,
    REFINANCE: onExitRefinanceLoanInformation,
  },
  changeProductType: {
    ONBOARDING: onChangeOnboardingLoanProductType,
    REFINANCE: onChangeRefinanceLoanProductType,
  },
  saveAndContinue: {
    ONBOARDING: onContinueLoanInformation,
    REFINANCE: onLoanInformationSaved,
  },
};
