import { arrayEmpty } from 'kennek/utils/validations';
import { InvestmentFundingMethod } from '@/features/onboarding/investments/funding-methods/InvestmentFundingMethod.types';
import { sum } from '@/utils/math';
import {
  getFeeAmountFromRules,
  mapAndRemoveRepaymentAmountForFactorLoanFees,
} from '../../onboarding/utils';
import { AmountToFundType, Loan, LoanProductRules } from '@/interfaces/loans';
import { Fee } from '@/interfaces/loans/fee-transaction';
import { InvestmentFundingMethodFeeSettingsAdjustmentType } from '@/interfaces/loans/queries';

export const filterFeesByInvestmentFundingMethod = (
  fees: Fee[],
  investmentFundingMethod: InvestmentFundingMethod,
  adjustmentType: InvestmentFundingMethodFeeSettingsAdjustmentType
) => {
  if (investmentFundingMethod.amountToFundType === AmountToFundType.GROSS) {
    return fees.filter((fee) => fee.trigger === 'CAPITALIZED_DISBURSEMENT');
  }

  if (investmentFundingMethod.amountToFundType === AmountToFundType.NET) {
    return fees.filter((fee) => fee.trigger === 'DISBURSEMENT');
  }

  if (investmentFundingMethod.amountToFundType === AmountToFundType.CUSTOM) {
    const enabledFeeIds = new Set(
      investmentFundingMethod.fees
        .filter((fee) => fee.enabled && fee.adjustmentType === adjustmentType)
        .map((fee) => fee.feeId)
    );
    return fees.filter((fee) => enabledFeeIds.has(fee.customId));
  }

  return fees;
};

export const getFeesFromStandardLoan = (
  loan: Loan,
  rules: LoanProductRules
): Fee[] =>
  mapAndRemoveRepaymentAmountForFactorLoanFees(
    rules,
    loan?.disbursementDetails?.fees
  ).map(({ fee, feeFound }) => ({
    feeName: feeFound?.name,
    trigger: feeFound?.trigger,
    customId: feeFound?.customId,
    amount:
      fee?.amount ||
      getFeeAmountFromRules(
        fee.predefinedFeeEncodedKey,
        loan?.loanAmount,
        rules
      ),
  })) || [];

export const getFeesFromTrancheLoan = (
  loan: Loan,
  rules: LoanProductRules
): Fee[] => {
  const feesGroupedByCustomId = new Map<string, Fee[]>();

  for (const tranche of loan?.tranches ?? []) {
    if (arrayEmpty(tranche.fees)) continue;

    const fees = mapAndRemoveRepaymentAmountForFactorLoanFees(
      rules,
      tranche.fees
    );

    for (const { fee, feeFound } of fees) {
      const feeToGroup: Fee = {
        feeName: feeFound?.name,
        trigger: feeFound?.trigger,
        customId: feeFound?.customId,
        amount:
          fee?.amount ??
          getFeeAmountFromRules(
            fee.predefinedFeeEncodedKey,
            tranche?.amount,
            rules
          ),
      };

      if (feesGroupedByCustomId.has(feeToGroup.customId)) {
        feesGroupedByCustomId.get(feeToGroup.customId).push(feeToGroup);
      } else {
        feesGroupedByCustomId.set(feeToGroup.customId, [feeToGroup]);
      }
    }
  }

  return (
    Array.from(feesGroupedByCustomId).map(([customId, fees]) => ({
      feeName: fees.at(0)?.feeName,
      customId,
      amount: sum(fees, (fee) => fee.amount),
      trigger: fees.at(0)?.trigger,
    })) || []
  );
};

export const getDisbursementLoanAmount = (loan: Loan) => {
  if (loan.tranches?.length > 0) {
    return sum(
      loan.tranches.filter(
        (tranche) => new Date(tranche.disbursementDate) <= new Date()
      ),
      (tranche) => tranche.amount
    );
  } else {
    return loan.loanAmount;
  }
};
