import React from 'react';
import { SvgIcon } from 'kennek/icons';
import { Select, Title } from 'ui';
import { PencilAltIcon } from '@heroicons/react/outline';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { InvestmentFundingMethod } from './InvestmentFundingMethod.types';
import { getInvestmentFundingMethodAfterSelectChange } from './InvestmentFundingMethod.utils';

export type InvestmentFundingMethodSelectInputProps = {
  investmentFundingMethods: InvestmentFundingMethod[];
  selectedInvestmentFundingMethod: InvestmentFundingMethod;
  onChange: (investmentFundingMethod: InvestmentFundingMethod) => void;
  onAddNewClicked: () => void;
  onEditClicked: () => void;
  isReadOnly?: boolean;
};

export const InvestmentFundingMethodSelectInput: React.FC<
  InvestmentFundingMethodSelectInputProps
> = (props) => {
  const { loanUpper, loanLower } = useGetLabelsConfig();

  return (
    <div className="select-none">
      <Title
        title="Investor"
        icon={<SvgIcon name="InvestorIcon" />}
        titleSize="lg"
        className="mt-2 mb-3"
      />
      <p>Add any funding source related to this {loanLower}</p>
      <div className="flex items-center mt-8 gap-4 relative">
        <Select
          value={props.selectedInvestmentFundingMethod?.id}
          label={`Select the amount to fund`}
          placeholder={`Select Gross, Net or custom ${loanUpper} amount`}
          optionsButton={{
            onClick: () => props.onAddNewClicked(),
            text: '+ Add new funding method',
          }}
          onChange={(event) =>
            props.onChange(
              getInvestmentFundingMethodAfterSelectChange(
                event.target.value,
                props.investmentFundingMethods
              )
            )
          }
          options={props.investmentFundingMethods.map(
            (investmentFundingMethod) => ({
              value: investmentFundingMethod.id,
              label: investmentFundingMethod.name,
            })
          )}
          disabled={props.isReadOnly}
          className="w-72"
        />
        {!props.isReadOnly && (
          <PencilAltIcon
            className="w-5 text-darkBlue cursor-pointer"
            onClick={() => props.onEditClicked()}
          />
        )}
      </div>
    </div>
  );
};
