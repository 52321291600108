import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useRouter, useSearchParams } from 'next/navigation';
import { isEmpty } from 'lodash';
import { SignOutModal } from 'ui';
import { useDebouncedCallback } from 'use-debounce';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { ROUTES } from '@/constants/routes';
import { env } from '@/env/public';
import { useRole } from '@/hooks/useRole';
import { useLazyGetUserByEmailQuery } from '@/services/accounts';
import { useGetDisabledFeaturesQuery } from '@/services/kennek';
import { setSnackBar } from '@/store/alerts';
import { SIGN_OUT } from '@/store/signout';
import { setUser } from '@/store/user';
import { useSelectUser } from '@/store/user/selectors';
import { getRouterQuery } from '@/utils/helpers';
import { isAnonymousUser } from '@/utils/session';
import { UserTypes } from 'kennek/interfaces/accounts';
import { OptionalFeatures } from 'kennek/interfaces/kennek';

function AuthGuard({
  children,
  roles = [],
  pageDisabledBy,
}: {
  children: ReactElement;
  roles: UserTypes[];
  pageDisabledBy: OptionalFeatures[];
}) {
  const router = useRouter();
  const searchParams = useSearchParams();
  const customerId = searchParams.get('customerId');
  const dispatch = useDispatch();
  const isSessionExpiredRef = useRef(false);
  const user = useSelectUser();
  const branchEncodedKey = user?.mambuUser?.[0]?.mambuBranchEncodedKey;

  const allowAnonymousAccess = useMemo(
    () => roles && roles.some((role) => isAnonymousUser(role)),
    [roles]
  );
  const { data: session, status } = useSession({
    required: true,
    async onUnauthenticated() {
      if (allowAnonymousAccess) {
        const response = await signIn('anonymous', { redirect: false });
        if (response.error) {
          dispatch(
            setSnackBar({
              severity: 'error',
              title: response.error,
            })
          );
        }

        return;
      }
      onLogoutUser();
    },
  });

  const onLogoutUser = useDebouncedCallback(() => {
    dispatch(SIGN_OUT);
    const path = getRouterQuery(ROUTES.SIGN_IN, {
      sessionExpired: isSessionExpiredRef.current,
      customerId: customerId || branchEncodedKey,
    });
    router.push(path);
  }, 300);

  const [getUserByEmail] = useLazyGetUserByEmailQuery();

  const { isLoading: isLoadingRole, role, isInvestor } = useRole();

  // Don't use customerId or useBranchEncodedKey, we only fetch data when user is logged in.
  const disabledFeaturesQuery = useGetDisabledFeaturesQuery(
    { branchEncodedKey },
    { skip: !branchEncodedKey }
  );

  const userIsAnonymous = isAnonymousUser(session?.accessRole as UserTypes);

  useEffect(() => {
    if (session?.error === 'RefreshTokenError') {
      signOut({ redirect: false });
    }
  }, [session?.error]);

  useEffect(() => {
    const effect = async () => {
      if (status === 'loading') return;

      if (userIsAnonymous) return;

      if (isEmpty(user?._id)) {
        // If a session exists but the User data is lost User data is fetched
        try {
          const res = await getUserByEmail(
            { email: session?.user?.email },
            false
          );
          dispatch(setUser(res.data));
          if (res.data?.phoneVerified === false) {
            const path = getRouterQuery(ROUTES.FIRST_LOGIN, {
              email: session.user.email,
              verify_phone: true,
              customerId,
            });
            router.push(path);
          }
        } catch (_error) {
          dispatch(
            setSnackBar({ severity: 'error', title: 'Server Unavailable' })
          );
        }
      } else if (!user?.phoneVerified) {
        // If the User has no phoneVerified is redirected to verify phone
        const path = getRouterQuery(ROUTES.FIRST_LOGIN, {
          email: session.user.email,
          verify_phone: true,
          customerId,
        });
        router.push(path);
      }
    };

    effect();
  }, [
    session?.user?.email,
    status,
    user?._id,
    user?.phoneVerified,
    getUserByEmail,
    dispatch,
    router,
    userIsAnonymous,
    customerId,
  ]);

  const shouldCheckDisabledFeatures = (() => {
    const roles: UserTypes[] = [
      'ORIGINATOR_ADMIN',
      'ORIGINATOR_ANALYST',
      'BORROWER',
    ];
    return roles.includes(role) && !user?.borrowerExecutionIds?.length;
  })();

  const canSeeFeature = !(
    shouldCheckDisabledFeatures &&
    pageDisabledBy?.some((feature) =>
      disabledFeaturesQuery.currentData?.includes(feature)
    )
  );

  const canSeeRole =
    !roles || !session
      ? null
      : (roles.length === 0 && !userIsAnonymous) ||
        roles.includes(session.accessRole as UserTypes);

  const canSee = canSeeFeature && canSeeRole;

  useEffect(() => {
    if (canSee || canSee == null) return;
    const path = getRouterQuery(
      userIsAnonymous ? ROUTES.SIGN_IN : ROUTES.ROOT,
      {
        customerId,
      }
    );

    if (!userIsAnonymous) {
      // If the User has no access to current page is redirected to Dashboard
      router.push(path);
    } else {
      // If the User has no access to current page and is a anonymous redirect to sign in
      dispatch(SIGN_OUT);
      router.push(path);
    }
  }, [canSee, router, userIsAnonymous, dispatch]);

  const [openModal, setOpenModal] = useState<'signOut'>();

  const idleTimer = useIdleTimer({
    onPrompt() {
      setOpenModal('signOut');
      setRemainingSeconds(getRemainingTime() / 1000);
    },
    async onIdle() {
      isSessionExpiredRef.current = true;
      if (idleTimer.isLeader()) {
        await signOut({ redirect: false });
      }
    },
    timeout: env.NEXT_PUBLIC_SIGN_OUT_MODAL_TIMEOUT * 60 * 1000,
    promptBeforeIdle:
      env.NEXT_PUBLIC_CONFIRM_SIGN_OUT_MODAL_TIMEOUT * 60 * 1000,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    async onMessage(message: IdleTimerMessages) {
      if (message === 'STAY_LOGGED_IN') {
        {
          setOpenModal(undefined);
          idleTimer.reset();
        }
      }
    },
  });

  const onStayLoggedIn = () => {
    const message: IdleTimerMessages = 'STAY_LOGGED_IN';
    idleTimer.message(message, true);
  };

  const [remainingSeconds, setRemainingSeconds] = useState(0);

  const { getRemainingTime } = idleTimer;

  useEffect(() => {
    if (openModal !== 'signOut') return;

    const id = setTimeout(() => {
      setRemainingSeconds(getRemainingTime() / 1000);
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, [openModal, remainingSeconds, getRemainingTime]);

  if (
    status === 'loading' ||
    !canSee ||
    isLoadingRole ||
    (!branchEncodedKey && !userIsAnonymous && !isInvestor) ||
    (shouldCheckDisabledFeatures &&
      disabledFeaturesQuery.isFetching &&
      !disabledFeaturesQuery.currentData)
  ) {
    return <LoadingAnimationPlat />;
  }

  return (
    <>
      {!userIsAnonymous && (
        <SignOutModal
          open={openModal === 'signOut'}
          onStaySignedIn={onStayLoggedIn}
          remainingSeconds={remainingSeconds}
        />
      )}
      {children}
    </>
  );
}

type IdleTimerMessages = 'STAY_LOGGED_IN';

export { AuthGuard };
