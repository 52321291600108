import { isEmpty } from './validation';

/**
 * Calculates the sum of given values extracted with `getValueFromElement()`.
 * @param array array that contains values you want to sum up
 * @param getValueFromElement function that extracts exact value to sum up
 * @param initialValue initial value to start the accumulation
 * @returns sum of given values
 */
export const sum = <T>(
  array: T[] | undefined | null,
  getValueFromElement: (element: T) => number,
  initialValue = 0
) => {
  if (isEmpty(array)) return 0;
  return array.reduce(
    (sum, element) => sum + getValueFromElement(element),
    initialValue
  );
};
