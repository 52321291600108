import { ChangeEvent, Dispatch, SetStateAction, useRef } from 'react';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { Button, DragAndDropInputWrapper } from 'ui';
import { DownloadIcon } from '@heroicons/react/outline';
import { TooltipLabel } from '@/components/widgets/TooltipLabel';
import { env } from '@/env/public';

export interface FileData {
  fileName: string;
  type: string;
  base64File: string | ArrayBuffer;
}

interface ImportScheduleProps {
  handleFileSelection: (e: ChangeEvent<HTMLInputElement>) => void;
  importedSchedule: FileData | null;
  setImportedSchedule: Dispatch<SetStateAction<FileData | null>>;
  validationErrors: string[];
  setValidationErrors: Dispatch<SetStateAction<string[]>>;
}

const ImportSchedule = ({
  handleFileSelection,
  importedSchedule,
  setImportedSchedule,
  validationErrors,
  setValidationErrors,
}: ImportScheduleProps) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const handleDownloadTemplate = () => {
    const url = env.NEXT_PUBLIC_TEMPLATE_URL;
    const link = document.createElement('a');
    const linkNameAttribute = `Template.xlsx`;
    link.href = url;
    link.setAttribute('download', linkNameAttribute);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    setValidationErrors([]);
  };

  const handleDelete = () => {
    setImportedSchedule(null);
    setValidationErrors([]);
  };

  return (
    <div className="flex flex-col items-start gap-1 min-h-[46px]">
      <TooltipLabel labelText={'Import Schedule'}>
        <span>
          Import the schedule using the provided template. If any item is not
          applicable, please leave the corresponding column blank.
        </span>
      </TooltipLabel>
      <DragAndDropInputWrapper
        testId="import-schedule"
        acceptFiles={['xlsx']}
        inputFile={inputFile}
        handleFileSelection={handleFileSelection}
        disableFileInput={!!importedSchedule}
      >
        {importedSchedule ? (
          <div className="flex justify-between items-center px-4 heading-100 text-neutral-900 h-[37px]">
            {importedSchedule.fileName}
            <button
              className="flex gap-2 items-center cursor-pointer heading-100"
              onClick={handleDelete}
            >
              <SvgIcon name="DeleteIcon" height={13} width={13} />
              Delete
            </button>
          </div>
        ) : (
          <div className="py-3 px-5 flex items-center rounded">
            <div
              className={classNames(
                'text-neutral-900 select-none body-200 cursor-pointer'
              )}
            >
              Drag and drop files, or&nbsp;
              <label className="text-[#227AB0] cursor-pointer">Browse</label>
            </div>
          </div>
        )}
      </DragAndDropInputWrapper>
      {!!validationErrors.length && importedSchedule && (
        <div className="text-xs body-300 text-error-700 mt-1">
          <ul className="px-4 list-disc">
            {validationErrors.map((errorMessage) => (
              <li key={errorMessage} className="mb-0.5">
                {errorMessage}
              </li>
            ))}
          </ul>
          <p className="mt-1">
            Please review the uploaded document or edit the value entered in the
            platform.
          </p>
        </div>
      )}

      {!importedSchedule && (
        <Button
          layout="link"
          size="small"
          className="!px-0"
          onClick={handleDownloadTemplate}
        >
          <span className="mr-2 text-xs text-secondary-400">
            Download template
          </span>
          <DownloadIcon className="w-4 h-4 text-secondary-400" />
        </Button>
      )}
    </div>
  );
};

export default ImportSchedule;
