import { InvestmentsFormFields } from '@/features/investments/InvestmentsForm/InvestmentsForm.types';
import { LoanInvestment } from '@/interfaces/loans/queries';

export const investmentsFormFieldsFactory = (
  investments: LoanInvestment[]
): InvestmentsFormFields[] =>
  investments.map((investment) => ({
    id: investment._id,
    investorId: investment.investor._id,
    lenderOfRecordId: investment.lenderOfRecord._id,
    amount: investment.currentAmount?.toString(),
    percentage: investment.currentPercentage?.toString(),
    interestRate: investment.currentInterest.toString(),
    type: investment.type,
  }));
