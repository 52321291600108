import * as React from 'react';
import { useContext } from 'react';
import classNames from 'classnames';
import { formatAmount } from '@/utils/formatters';
import { LoanSummaryContext } from './ContextWrapper';
import { NextInstallmentRowProps } from '@/interfaces/next-payment';

type RowVariant = 'GRACE' | 'OVERDUE' | 'TOTAL';

const nextInstallmentsRowVariant: Record<RowVariant, NextInstallmentRowProps> =
  {
    GRACE: { text: 'Grace', style: 'text-warning-500' },
    OVERDUE: { text: 'Overdue', style: 'text-error-500' },
    TOTAL: { text: 'Next instalment', style: '' },
  };

type Props = {
  amount: number;
  variant?: RowVariant;
  showDecimals?: boolean;
};

const NextInstallmentRow: React.FC<Props> = ({
  amount,
  variant,
  showDecimals = false,
}) => {
  const { currency } = useContext(LoanSummaryContext);
  return (
    <div className="flex justify-between items-center py-3.5 px-5 h-[45px]">
      <span
        className={classNames(
          'heading-200',
          nextInstallmentsRowVariant[variant].style
        )}
      >
        {formatAmount(amount, showDecimals, currency)}
      </span>
      <span
        className={classNames(
          'body-100',
          nextInstallmentsRowVariant[variant].style
        )}
      >
        {nextInstallmentsRowVariant[variant].text}
      </span>
    </div>
  );
};

export default NextInstallmentRow;
