import { loansBaseApi } from '.';
import { rootApi } from '../rootApi';
import { EditLoanPayload } from '@/interfaces/loans/queries';

const loanEditsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    editLoan: builder.mutation<
      { _id: string },
      { editInfo: EditLoanPayload } & { loanEncodedKey: string }
    >({
      query: ({ loanEncodedKey, editInfo }) => ({
        url: `${loansBaseApi}/${loanEncodedKey}/edits`,
        method: 'POST',
        body: editInfo,
      }),
    }),
    editLoanModification: builder.mutation<
      { _id: string },
      { editInfo: EditLoanPayload } & { loanEncodedKey: string; id: string }
    >({
      query: ({ loanEncodedKey, editInfo, id }) => ({
        url: `${loansBaseApi}/${loanEncodedKey}/edits/${id}`,
        method: 'PATCH',
        body: editInfo,
      }),
    }),
    deleteLoanModification: builder.mutation<
      { _id: string },
      { loanEncodedKey: string; id: string }
    >({
      query: ({ loanEncodedKey, id }) => ({
        url: `${loansBaseApi}/${loanEncodedKey}/edits/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useEditLoanMutation,
  useEditLoanModificationMutation,
  useDeleteLoanModificationMutation,
} = loanEditsApi;
