import React from 'react';
import MaintenancePage, { MaintenancePageProps } from './MaintenancePage';

interface ErrorBoundaryProps extends MaintenancePageProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if ((this.state as { hasError: boolean }).hasError) {
      // Fallback UI
      return (
        <MaintenancePage
          outsideLayout={this.props.outsideLayout}
        ></MaintenancePage>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
