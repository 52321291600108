import React from 'react';
import LoadingAnimation from './LoadingAnimation';

type LoanGeneratingLoadingProps = {
  processingInstallmentNumber: number;
  totalInstallmentsCount: number;
};

const LoanGeneratingLoading: React.FC<LoanGeneratingLoadingProps> = (props) => {
  const { processingInstallmentNumber, totalInstallmentsCount } = props;

  const shouldDisplayProgressState =
    processingInstallmentNumber !== 0 && totalInstallmentsCount !== 0;

  return (
    <div className="h-4/5 flex flex-col justify-center items-center gap-6">
      <LoadingAnimation fitBox />
      <div className="flex flex-col items-center py-2 text-neutral-700">
        <div className="heading-700">Repayment Schedule is being generated</div>
        {shouldDisplayProgressState ? (
          <div className="heading-400 pt-4">
            Instalment {processingInstallmentNumber} out of{' '}
            {totalInstallmentsCount} is being processed.
          </div>
        ) : null}
        <div className="heading-400 pt-4">This could take a few moments.</div>
      </div>
    </div>
  );
};

export default LoanGeneratingLoading;
