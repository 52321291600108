import { FC } from 'react';
import ScheduleDetail from '@/components/widgets/ScheduleDetails';
import { LABELS } from '@/constants/productTypeDictionary';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import {
  PropertyType,
  useLoanDetailsOverwriteLabel,
} from '@/hooks/useLoanDetailsOverwriteLabel';
import { formatAmount, formatDate, formatPercent } from '@/utils/formatters';
import { getLoanStartDate } from './utils';
import { ConfigurationProduct } from 'kennek/interfaces/kennek/queries';
import { LabelsConfig } from 'kennek/interfaces/labelsConfig';
import {
  DEFAULT_HEADERS,
  HeaderConfiguration,
  HeaderName,
  getHeadersLabels,
} from 'kennek/interfaces/loans/repaymentSchedule.utils';
import {
  DisbursementDetails,
  InterestSettings,
  PaymentSchedule,
} from '@/interfaces/loans';
import { LoanSummary } from '@/interfaces/loans/queries';

export interface RepaymentScheduleHeadersProps {
  currency: string;
  loanSummary: LoanSummary;
  repaymentSchedule: PaymentSchedule | undefined;
  configurationProductData: ConfigurationProduct;
  headersConfig?: HeaderConfiguration[];
  repaymentScheduleType: 'preview' | 'repayments';
  productType: 'blockedUntilMatured' | 'default';
  disbursementDetails?: DisbursementDetails;
  interestSettings?: InterestSettings;
}
export const RepaymentScheduleHeaders: FC<RepaymentScheduleHeadersProps> = ({
  currency,
  loanSummary,
  repaymentSchedule,
  configurationProductData,
  headersConfig,
  repaymentScheduleType,
  productType,
  disbursementDetails,
  interestSettings,
}) => {
  const overwriteLabel = useLoanDetailsOverwriteLabel();
  const labelsConfig = useGetLabelsConfig();

  const headersData = getHeadersData(
    currency,
    loanSummary,
    repaymentSchedule,
    disbursementDetails,
    interestSettings
  );

  const filteredHeaders = (
    headersConfig?.length ? headersConfig : DEFAULT_HEADERS
  ).filter((x) => x.active);

  return (
    <div className="flex justify-start w-full">
      {filteredHeaders.map((header, index) => (
        <div className="grow max-w-[180px]" key={`${header.name}-${index}`}>
          <ScheduleDetail
            title={getHeaderLabel(
              header.name,
              configurationProductData,
              overwriteLabel.getPropertyLabel,
              productType,
              labelsConfig
            )}
            detail={headersData[header.name]}
            smallDetail={repaymentScheduleType === 'preview'}
            principal
            questionTooltip={getHeaderTooltip(
              header.name,
              overwriteLabel.getPropertyTooltip,
              labelsConfig
            )}
          />
        </div>
      ))}
    </div>
  );
};

const getHeaderTooltip = (
  header: HeaderName,
  getPropertyTooltip: (
    baseTooltip: string,
    propertyType: PropertyType
  ) => string,
  labelsConfig: LabelsConfig
): string => {
  return header === 'loanAmount'
    ? getPropertyTooltip(
        `Initial ${labelsConfig.loanUpper} Amount`,
        'LOAN_AMOUNT'
      )
    : null;
};

const getHeaderLabel = (
  header: HeaderName,
  configurationProductData: ConfigurationProduct,
  getPropertyLabel: (
    baseLabel: string,
    propertyType: PropertyType,
    grace: {
      interestGrace: boolean;
      principalGracePeriod: boolean;
    }
  ) => string,
  productType: 'blockedUntilMatured' | 'default',
  labelsConfig: LabelsConfig
): string => {
  if (header === 'loanAmount') {
    return getPropertyLabel(
      `${labelsConfig.loanUpper} Amount`,
      'LOAN_AMOUNT',
      configurationProductData?.grace
    );
  } else if (header === 'deductedInterest') {
    return getPropertyLabel(
      'Deducted Interest',
      'DEDUCTED_INTEREST_SETUP',
      configurationProductData?.grace
    );
  } else if (header === 'remainingInstallmentsCount') {
    return LABELS[productType].remainingInstallmentsCount;
  }
  const labels = getHeadersLabels(labelsConfig);
  return labels[header];
};

const getHeadersData = (
  currency: string,
  loanSummary: LoanSummary,
  repaymentSchedule: PaymentSchedule | undefined,
  disbursementDetails?: DisbursementDetails,
  interestSettings?: InterestSettings
): Record<HeaderName, string | number> => {
  const loanAmount = loanSummary?.totalAmount;
  const startDate = getLoanStartDate(loanSummary, disbursementDetails);
  const installments = repaymentSchedule?.installments.filter(
    (x) => x.state !== 'RESTRUCTURED'
  );

  const firstPaymentDueDate =
    installments[0]?.dueDate ??
    installments[0]?.previewDueDate ??
    loanSummary?.firstRepaymentDate ??
    '';

  const interestRate =
    interestSettings?.displayedInterestRate ??
    loanSummary?.displayedInterestRate;

  return {
    loanAmount: formatAmount(loanAmount, true, currency),
    startDate: formatDate(startDate),
    capitalizedFees: formatAmount(
      repaymentSchedule?.capitalizedFees,
      true,
      currency
    ),
    deductedInterest: formatAmount(
      repaymentSchedule?.deductedFees,
      true,
      currency
    ),
    onDisbursementFees: formatAmount(
      repaymentSchedule?.onDisbursementFees,
      true,
      currency
    ),
    firstPayment: formatDate(firstPaymentDueDate),
    remainingInstallmentsCount: loanSummary?.remainingInstallmentsCount,
    interestRate: formatPercent(interestRate, 2),
    totalRepaymentAmount: formatAmount(
      loanSummary?.totalRepaymentAmount,
      true,
      currency
    ),
    leftToPay: formatAmount(loanSummary?.leftToPay, true, currency),
    redemptionAmount: formatAmount(
      loanSummary?.balances?.payoffTotalAmount,
      true,
      currency
    ),
  };
};
