// Absolutes
export const ABSOLUTE_MAX_CHARACTERS = 254;
export const SETTINGS_LABEL_CONFIG_MAX_CHARACTER = 15;

export const ABSOLUTE_MIN_DATE = '1900-01-01';
export const ABSOLUTE_MAX_DATE = '2900-01-01';
export const MAX_FILE_SIZE = 10e6;
export const MAX_NUMBER_OF_FILES = 20;

// Special
export const UK_PREFIX = '+44';
export const UK_UNSIGNED_PREFIX = '44';

export const PHONE = {
  MAX_CODE_DIGITS: 6,
  MIN_CODE_DIGITS: 6,
};

export const COMPANY = {
  MAX_SIC_CODE: 99999,
  MIN_SIC_CODE: 1000,
  MAX_SIC_CODE_DIGITS: 5,
  MIN_SIC_CODE_DIGITS: 4,
  COMPANY_NUMBER_DIGITS: 8,
};

export const LOAN = {
  MAX_LOAN_AMOUNT: 1000000000000,
  MIN_LOAN_AMOUNT: 0,
  MAX_INTEREST_RATE: 1000,
  MIN_INTEREST_RATE: 0,
  MAX_PERIODIC_PAYMENT: 1000000000000,
  MIN_PERIODIC_PAYMENT: 1,
  MIN_INTEREST_INVESTOR: 0,
  MIN_LTV: 0,
  MAX_LTV: 100,
  MIN_GDV: 0,
  MIN_LTC: 0,
  MAX_LTC: 100,
  MIN_GDC: 0,
  MIN_FACTOR_AMOUNT: 0,
  MIN_EFFECTIVE_INTEREST_RATE: 0,
  MAX_EFFECTIVE_INTEREST_RATE: 100,
  MIN_GROSS_YIELD: 0,
  MAX_GROSS_YIELD: 100,
};

export const DIRECT_DEBIT = {
  ACCOUNT_NUMBER: 8,
  SORT_CODE_DIGITS: 6,
  ACCOUNT_NAME_MAX_CHARACTERS: 254,
  ORIGINATOR_NAME_MAX_CHARACTERS: 254,
};

export const USER_MGMT = {
  FIRST_NAME_MAX_CHARACTERS: 254,
  LAST_NAME_MAX_CHARACTERS: 254,
  EMAIL_MAX_CHARACTERS: 254,
};
