import classNames, { ArgumentArray } from 'classnames';
import { twMerge } from 'tailwind-merge';

/**
 * Combination of tailwind merge and classnames utilities. It strips duplicates of
 * classes in favor of last defined class name, thus allowing us to override initial
 * classes in our components.
 * @param inputs tailwind class name or classnames object { 'tailwindClass': boolean }
 * @returns
 */
export const cn = (...inputs: ArgumentArray) => {
  return twMerge(classNames(inputs));
};
