import React from 'react';
import { IconProps } from '../iconTypes';

const RateHistoryIcon: React.FC<IconProps> = ({ fill }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.1C12.9212 16.1 16.1 12.9212 16.1 9C16.1 5.07878 12.9212 1.9 9 1.9C5.07878 1.9 1.9 5.07878 1.9 9C1.9 12.9212 5.07878 16.1 9 16.1ZM9 17.5C13.6944 17.5 17.5 13.6944 17.5 9C17.5 4.30558 13.6944 0.5 9 0.5C4.30558 0.5 0.5 4.30558 0.5 9C0.5 13.6944 4.30558 17.5 9 17.5Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.9 7.45468C7.17062 7.45468 7.39 7.2278 7.39 6.94791C7.39 6.66803 7.17062 6.44114 6.9 6.44114C6.62938 6.44114 6.41 6.66803 6.41 6.94791C6.41 7.2278 6.62938 7.45468 6.9 7.45468ZM6.9 8.39583C7.6732 8.39583 8.3 7.74758 8.3 6.94791C8.3 6.14825 7.6732 5.5 6.9 5.5C6.1268 5.5 5.5 6.14825 5.5 6.94791C5.5 7.74758 6.1268 8.39583 6.9 8.39583Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1 11.4364C11.3706 11.4364 11.59 11.2096 11.59 10.9297C11.59 10.6498 11.3706 10.4229 11.1 10.4229C10.8294 10.4229 10.61 10.6498 10.61 10.9297C10.61 11.2096 10.8294 11.4364 11.1 11.4364ZM11.1 12.3776C11.8732 12.3776 12.5 11.7293 12.5 10.9297C12.5 10.13 11.8732 9.48177 11.1 9.48177C10.3268 9.48177 9.7 10.13 9.7 10.9297C9.7 11.7293 10.3268 12.3776 11.1 12.3776Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5178 5.66319C11.8173 5.91602 11.8619 6.37208 11.6174 6.68182L7.23543 12.2338C6.99096 12.5436 6.54999 12.5897 6.2505 12.3369C5.95101 12.084 5.9064 11.628 6.15087 11.3182L10.5328 5.76623C10.7773 5.45649 11.2183 5.41035 11.5178 5.66319Z"
    />
  </svg>
);

export default RateHistoryIcon;
