import React from 'react';
import { IconProps } from '../iconTypes';

const CopyIcon: React.FC<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    width="20"
    height="16"
    {...props}
  >
    <path
      d="M4.66665 11.3333H2.99998C2.07951 11.3333 1.33331 10.5871 1.33331 9.66666V2.99999C1.33331 2.07952 2.07951 1.33333 2.99998 1.33333H9.66665C10.5871 1.33333 11.3333 2.07952 11.3333 2.99999V4.66666M6.33331 14.6667H13C13.9205 14.6667 14.6666 13.9205 14.6666 13V6.33333C14.6666 5.41285 13.9205 4.66666 13 4.66666H6.33331C5.41284 4.66666 4.66665 5.41285 4.66665 6.33333V13C4.66665 13.9205 5.41284 14.6667 6.33331 14.6667Z"
      fill="#F1F5F9"
      stroke="#334155"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CopyIcon;
