import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useTypedSelector } from '@/store/hooks';
import { InvestmentsFormFields } from '../investments/InvestmentsForm/InvestmentsForm.types';
import { CustomInformationFields } from '../onboarding/custom-information/utils';
import type { AdditionalDetailsFormType } from '../onboarding/loan-details/AdditionalDetailsForm';
import type { LoanInformationFormType } from '../onboarding/loan-details/LoanInformationForm';
import type { ScheduleFormType } from '../onboarding/loan-details/ScheduleForm';
import type { BalanceForm } from './EditLoanBalanceForm';
import { AmountToFundType, InvestmentAmountType } from '@/interfaces/loans';
import {
  LoanFacility,
  LoanFacilityForm,
  RowLoanFacility,
} from '@/interfaces/loans/facility';
import { UpdateBorrowerCompanyPayload } from '@/interfaces/originator/borrower-company';
import { EditSchedule } from '@/interfaces/schedules';

export type EditLoanState = {
  isActive: boolean;
  balance: {
    form: BalanceForm;
    newLoanAmount: number;
    productTypeKey: string;
  };
  loanInformation: {
    form: LoanInformationFormType;
    productTypeKey: string;
    shouldOverrideFormWithRules: boolean;
  };
  schedule: {
    form: ScheduleFormType;
    shouldOverrideFormWithRules: boolean;
  };
  additionalDetails: {
    form: AdditionalDetailsFormType;
    shouldOverrideFormWithRules: boolean;
  };
  investments: {
    form: InvestmentsFormFields[];
    amountToFundType: AmountToFundType;
    fundingMethodId: string;
  };
  loanFacilities: {
    editId: string;
    form: LoanFacilityForm[];
    shouldOverrideFormWithRules: boolean;
    loanFacilities: LoanFacility[] | RowLoanFacility[];
  };
  loanReporting: {
    reports: EditSchedule[];
  };
  company: {
    form: UpdateBorrowerCompanyPayload;
  };
  editId: string;
  borrowerCompanyExternalId?: string;
  originatorExternalId?: string;
  customInformation?: CustomInformationFields[];
};

const INITIAL_STATE: EditLoanState = {
  isActive: false,
  balance: {
    form: undefined,
    newLoanAmount: undefined,
    productTypeKey: undefined,
  },
  loanInformation: {
    form: undefined,
    productTypeKey: undefined,
    shouldOverrideFormWithRules: false,
  },
  schedule: {
    form: undefined,
    shouldOverrideFormWithRules: false,
  },
  additionalDetails: {
    form: undefined,
    shouldOverrideFormWithRules: false,
  },
  investments: {
    form: [],
    amountToFundType: AmountToFundType.GROSS,
    fundingMethodId: null,
  },
  loanFacilities: {
    editId: undefined,
    form: undefined,
    loanFacilities: [],
    shouldOverrideFormWithRules: false,
  },
  loanReporting: {
    reports: [],
  },
  editId: undefined,
  company: {
    form: {
      company: undefined,
      id: undefined,
      userId: undefined,
      mambuBranchEncodedKey: undefined,
    },
  },
  customInformation: undefined,
};

const onboardingSlice = createSlice({
  name: 'editLoan',
  initialState: INITIAL_STATE,
  reducers: {
    // Balance
    onBalanceSaved(
      state,
      action: PayloadAction<{ form: BalanceForm; newLoanAmount: number }>
    ) {
      state.balance.form = action.payload.form;
      state.balance.newLoanAmount = action.payload.newLoanAmount;
      state.balance.productTypeKey = action.payload.form.productTypeKey;
    },
    // Loan Information
    onEnterLoanInformation(_state) {},
    onExitLoanInformation(
      state,
      action: PayloadAction<{ form: LoanInformationFormType; isDirty: boolean }>
    ) {
      if (action.payload.isDirty) {
        state.loanInformation.form = action.payload.form;
      }
    },
    onLoanInformationSaved(
      state,
      action: PayloadAction<{
        form: LoanInformationFormType;
        loanFacilities?: RowLoanFacility[] | LoanFacility[];
      }>
    ) {
      state.loanInformation.form = action.payload.form;
      state.loanInformation.shouldOverrideFormWithRules = false;
      state.loanFacilities.loanFacilities = action.payload.loanFacilities;
    },
    onLoanInformationBack(
      state,
      action: PayloadAction<LoanInformationFormType>
    ) {
      state.loanInformation.form = action.payload;
      state.loanInformation.shouldOverrideFormWithRules = false;
    },
    onChangeLoanProductType(
      state,
      { payload }: PayloadAction<{ productTypeKey: string }>
    ) {
      if (state.balance.form) {
        state.balance.form.productTypeKey = payload.productTypeKey;
      }
      state.balance.productTypeKey = payload.productTypeKey;
      state.loanInformation.productTypeKey = payload.productTypeKey;
      state.loanInformation.shouldOverrideFormWithRules = true;
      state.schedule.shouldOverrideFormWithRules = true;
      state.additionalDetails.shouldOverrideFormWithRules = true;
    },
    // Schedule
    onScheduleSaved(state, action: PayloadAction<{ form: ScheduleFormType }>) {
      state.schedule.form = action.payload.form;
      state.schedule.shouldOverrideFormWithRules = false;
    },
    onScheduleBack(state, action: PayloadAction<ScheduleFormType>) {
      state.schedule.form = action.payload;
      state.schedule.shouldOverrideFormWithRules = false;
    },
    // Additional details
    onAdditionalDetailsSaved(
      state,
      action: PayloadAction<AdditionalDetailsFormType>
    ) {
      state.additionalDetails.form = action.payload;
      state.additionalDetails.shouldOverrideFormWithRules = false;
    },
    onAdditionalDetailsBack(
      state,
      action: PayloadAction<AdditionalDetailsFormType>
    ) {
      state.additionalDetails.form = action.payload;
      state.additionalDetails.shouldOverrideFormWithRules = false;
    },
    // Investments
    onInvestmentsSaved(
      state,
      action: PayloadAction<{
        form: InvestmentsFormFields[];
        amountToFundType: AmountToFundType;
        fundingMethodId: string;
        investmentAmountType: InvestmentAmountType;
      }>
    ) {
      state.investments.form = structuredClone(action.payload.form);
      state.investments.amountToFundType = action.payload.amountToFundType;
      state.investments.fundingMethodId = action.payload.fundingMethodId;
    },
    onEditSaved(state, action: PayloadAction<string>) {
      state.editId = action.payload;
    },
    // LoanFacilities
    onLoanFacilitiesSaved(
      state,
      action: PayloadAction<{
        form?: LoanFacilityForm[];
        editId?: string;
        loanFacilities: LoanFacility[];
      }>
    ) {
      state.loanFacilities.form = action.payload.form;
      state.loanFacilities.editId = action.payload.editId;
      state.loanFacilities.loanFacilities = action.payload.loanFacilities;
      state.additionalDetails.shouldOverrideFormWithRules = false;
    },
    onLoanFacilitiesBack(_state) {},
    // User
    onUserSaved(_state) {},
    // Company
    onCompanySaved(state, action: PayloadAction<UpdateBorrowerCompanyPayload>) {
      state.company.form = action.payload;
    },
    // Loan Reporting
    onLoanReportingSaved(state, action: PayloadAction<EditSchedule[]>) {
      state.loanReporting.reports = action.payload;
    },
    // Custom Information
    onCustomInformationSaved(
      state,
      action: PayloadAction<CustomInformationFields[]>
    ) {
      state.customInformation = action.payload;
    },
    // general
    onEnterRefinance(state) {
      state.isActive = true;
    },
    onEnterClientIds(
      state,
      action: PayloadAction<{
        borrowerCompanyExternalId: string;
        originatorExternalId: string;
      }>
    ) {
      state.borrowerCompanyExternalId =
        action.payload.borrowerCompanyExternalId;
      state.originatorExternalId = action.payload.originatorExternalId;
    },
    onExitRefinance() {
      return INITIAL_STATE;
    },
  },
});

export const {
  onEnterRefinance,
  onExitRefinance,
  onBalanceSaved,
  onEnterLoanInformation,
  onExitLoanInformation,
  onChangeLoanProductType,
  onLoanInformationBack,
  onLoanInformationSaved,
  onScheduleBack,
  onScheduleSaved,
  onAdditionalDetailsSaved,
  onAdditionalDetailsBack,
  onInvestmentsSaved,
  onLoanFacilitiesSaved,
  onLoanFacilitiesBack,
  onUserSaved,
  onCompanySaved,
  onLoanReportingSaved,
  onEditSaved,
  onEnterClientIds,
  onCustomInformationSaved,
} = onboardingSlice.actions;

export function useSelectBalanceForm() {
  return useTypedSelector(({ editLoan }) => editLoan.balance.form);
}

export function useSelectLoanInfoForm() {
  return useTypedSelector(({ editLoan }) => editLoan.loanInformation.form);
}

export function useSelectScheduleForm() {
  return useTypedSelector(({ editLoan }) => editLoan.schedule.form);
}

export function useSelectEditInvestmentsForm() {
  return useTypedSelector(({ editLoan }) => editLoan.investments.form);
}

export function useSelectEditInvestments() {
  return useTypedSelector(({ editLoan }) => editLoan.investments);
}

export function useSelectEditId() {
  return useTypedSelector(({ editLoan }) => editLoan.editId);
}

export function useSelectEditReports() {
  return useTypedSelector(({ editLoan }) => editLoan.loanReporting);
}

export function useSelectEditCompany() {
  return useTypedSelector(({ editLoan }) => editLoan.company.form.company);
}

export function useSelectAdditionalDetailsForm() {
  return useTypedSelector(({ editLoan }) => editLoan.additionalDetails.form);
}

export function useSelectEditLoanFacilitiesStore() {
  return useTypedSelector(({ editLoan }) => editLoan.loanFacilities);
}

export function useSelectShouldOverrideLoanInfoWithRules() {
  return useTypedSelector(
    ({ editLoan }) => editLoan.loanInformation.shouldOverrideFormWithRules
  );
}

export function useSelectShouldOverrideScheduleWithRules() {
  return useTypedSelector(
    ({ editLoan }) => editLoan.schedule.shouldOverrideFormWithRules
  );
}

export function useSelectShouldOverrideAdditionalDetailsWithRules() {
  return useTypedSelector(
    ({ editLoan }) => editLoan.additionalDetails.shouldOverrideFormWithRules
  );
}

export function useSelectNewLoanAmount() {
  return useTypedSelector(({ editLoan }) => editLoan?.balance?.newLoanAmount);
}

export function useSelectProductTypeKey() {
  return useTypedSelector(
    ({ editLoan }) => editLoan?.balance?.form?.productTypeKey
  );
}

export function useSelectBorrowerCompanyExternalId() {
  return useTypedSelector(({ editLoan }) => editLoan.borrowerCompanyExternalId);
}

export function useSelectOriginatorExternalId() {
  return useTypedSelector(({ editLoan }) => editLoan.originatorExternalId);
}

export function useSelectPrincipal() {
  return useTypedSelector(({ editLoan }) => editLoan?.balance.form?.principal);
}

export function useCustomInformation() {
  return useTypedSelector(({ editLoan }) => editLoan?.customInformation);
}

export default onboardingSlice.reducer;
