import { baseAccountsApi } from '.';
import { rootApi } from '../rootApi';
import {
  GetLenderOfRecordsParams,
  GetOriginatorParams,
  LenderOfRecord,
  LenderOfRecordMutationPayload,
} from 'kennek/interfaces/accounts/investor';
import { Company } from 'kennek/interfaces/originator';

export const originatorApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createLenderOfRecord: builder.mutation<
      LenderOfRecord,
      LenderOfRecordMutationPayload
    >({
      query: (payload) => ({
        url: baseAccountsApi + '/originator/lender-of-records',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Lender-Of-Records'],
    }),
    getLenderOfRecord: builder.query<
      LenderOfRecord[],
      GetLenderOfRecordsParams
    >({
      query: (params) => ({
        url: baseAccountsApi + '/originator/lender-of-records',
        method: 'GET',
        params: { ...params },
      }),
      providesTags: ['Lender-Of-Records'],
    }),
    getOriginator: builder.query<Company, GetOriginatorParams>({
      query: ({ brachEncodedKey }) => ({
        url: baseAccountsApi + `/originator/company/${brachEncodedKey}`,
        method: 'GET',
      }),
      providesTags: ['Originator'],
    }),
  }),
});

export const {
  useCreateLenderOfRecordMutation,
  useGetLenderOfRecordQuery,
  useGetOriginatorQuery,
} = originatorApi;
