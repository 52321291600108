import { rootApi } from './rootApi';
import { UserTypes } from 'kennek/interfaces/accounts';

export interface ChangePasswordType {
  accessToken: string;
  password: string;
  newPassword: string;
}

export interface ConfirmPasswordType {
  email: string;
  code: string;
  newPassword: string;
}

const baseAuthApi = '/api/auth';

export const authApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: ({
        ...credentials
      }: {
        email: string;
        name: string;
        firstName?: string;
        lastName?: string;
        phone?: string;
        role: UserTypes;
      }) => ({
        url: baseAuthApi + '/sign-up',
        method: 'POST',
        body: credentials,
      }),
      extraOptions: {
        noAuth: true,
      },
    }),
    changePassword: builder.mutation({
      query: ({ ...credentials }: ChangePasswordType) => ({
        url: baseAuthApi + '/change-password',
        method: 'POST',
        body: credentials,
      }),
    }),
    sendPasswordRecoveryEmail: builder.mutation({
      query: ({ email }: { email: string }) => ({
        url: baseAuthApi + '/forgot-password',
        method: 'POST',
        body: { email: email },
      }),
      extraOptions: {
        noAuth: true,
      },
    }),
    confirmPasswordRecovery: builder.mutation({
      query: ({ ...credentials }: ConfirmPasswordType) => ({
        url: baseAuthApi + '/confirm-forgot-password',
        method: 'POST',
        body: credentials,
      }),
      extraOptions: {
        noAuth: true,
      },
    }),
    resendInvitation: builder.mutation({
      query: ({
        email,
        createdByOriginatorName,
      }: {
        email: string;
        createdByOriginatorName?: string;
      }) => ({
        url: baseAuthApi + '/resend-invitation',
        method: 'POST',
        body: createdByOriginatorName
          ? { email, createdByOriginatorName }
          : { email },
      }),
      extraOptions: {
        noAuth: true,
      },
    }),
    enableMfa: builder.mutation({
      query: ({ email }: { email: string }) => ({
        url: `${baseAuthApi}/enable-mfa/${encodeURIComponent(email)}`,
        method: 'POST',
      }),
    }),
    disableMfa: builder.mutation({
      query: ({ email }: { email: string }) => ({
        url: `${baseAuthApi}/disable-mfa/${encodeURIComponent(email)}`,
        method: 'POST',
      }),
    }),
    sendPhoneVerification: builder.mutation({
      query: ({ accessToken }: { accessToken: string }) => ({
        url: baseAuthApi + '/send-phone-verification',
        method: 'POST',
        body: { accessToken: accessToken },
      }),
      extraOptions: {
        noAuth: true,
      },
    }),
    verifyPhone: builder.mutation({
      query: ({
        accessToken,
        code,
      }: {
        accessToken: string;
        code: number;
      }) => ({
        url: baseAuthApi + '/verify-phone',
        method: 'POST',
        body: { accessToken: accessToken, code: code },
      }),
      extraOptions: {
        noAuth: true,
      },
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useCreateUserMutation,
  useSendPasswordRecoveryEmailMutation,
  useConfirmPasswordRecoveryMutation,
  useResendInvitationMutation,
  useEnableMfaMutation,
  useDisableMfaMutation,
  useVerifyPhoneMutation,
} = authApi;
