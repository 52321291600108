import React from 'react';
import classNames from 'classnames';
import { SvgIcon } from 'kennek/icons';
import { Button, Title } from 'ui';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { usePatchOnboardingTaskStatusMutation } from '@/services/accounts/borrower';
import EditDocumentsTable from '../document-management/EditDocumentsTable';

export interface UploadingFiles {
  id: string;
  name: string;
  percentage: number;
  cancel: AbortController;
}

type UploadDocumentationFormProps = {
  onContinue: () => void;
  onBack: () => void;
  loanId: string;
  isReview: boolean;
};

const UploadDocumentationForm: React.FC<UploadDocumentationFormProps> = ({
  onBack,
  onContinue,
  loanId,
  isReview,
}) => {
  const { loanLower, borrowerLower } = useGetLabelsConfig();
  const [patchOnboardingTaskStatus, { isLoading: isPatchingStepStatus }] =
    usePatchOnboardingTaskStatusMutation();

  const onSubmit = async () => {
    await patchOnboardingTaskStatus({
      taskName: 'UPLOAD_DOCUMENTATION',
      taskStatus: 'COMPLETED',
      loanId,
    });
    onContinue();
  };

  return (
    <div
      className={classNames({
        'w-[788px]': !isReview,
      })}
    >
      <Title
        icon={<SvgIcon name="DocumentsIcon" />}
        title={`Upload covenant and ${loanLower} documentation`}
        titleSize="lg"
        className="mt-2 mb-3 text-primary-900"
      />
      <div className="flex flex-col">
        <span className="body-500 mt-3 select-none">
          Upload the covenant agreement and all the documentation that is
          related to the {loanLower} agreement.
        </span>
        <span className="text-xs">
          These documents will be visible by the {borrowerLower} and investors
          with access to this {loanLower}.
        </span>
      </div>
      <div className="mt-8">
        <EditDocumentsTable loanId={loanId} narrow={true} showBorder />
      </div>
      <div className="flex justify-between mt-8">
        <Button onClick={onBack} disabled={isPatchingStepStatus} layout="ghost">
          {isReview ? 'Cancel' : 'Back'}
        </Button>

        <Button
          onClick={onSubmit}
          type="submit"
          loading={isPatchingStepStatus}
          disabled={isPatchingStepStatus}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default UploadDocumentationForm;
