import React, { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';
import BREAKPOINTS from 'ui/utils/breakpoints';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import SideBarItem, { SideBarItemType } from './SideBarItem';

export type SideBarStatus = 'collapsed' | 'expanded';
interface Props {
  items: SideBarItemType[];
  collapsed: SideBarStatus;
  onCollapsedChange: (status: SideBarStatus) => void;
  disabledRoute?: string;
  sticky?: boolean;
  hidden?: boolean;
}

const SideBar: React.FC<Props> = ({
  items,
  disabledRoute,
  collapsed,
  onCollapsedChange,
  sticky,
  hidden = false,
}) => {
  const pathname = usePathname();

  const handleResize = () => {
    const status: SideBarStatus =
      window.innerWidth <= BREAKPOINTS.Lg ? 'collapsed' : 'expanded';
    onCollapsedChange(status);
  };

  const isCollapsed = collapsed === 'collapsed';

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOnCollapseChange = () => {
    onCollapsedChange(isCollapsed ? 'expanded' : 'collapsed');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderItems: any = (itemsToRender: SideBarItemType[]) => {
    return itemsToRender?.map((item, index) => {
      if (item.title && item.items) {
        return hidden ? (
          <></>
        ) : !isCollapsed ? (
          <div
            className="w-full flex flex-col my-2 items-start select-none"
            key={`section${index}`}
          >
            {index !== 0 && (
              <div className="border-t border-neutral-300 h-1 w-[177px] ml-3" />
            )}
            <span className="body-400 text-neutral-700 mt-6 ml-6">
              {item.title.toUpperCase()}
            </span>
            {renderItems(item.items)}
          </div>
        ) : (
          renderItems(item.items)
        );
      }

      const { route = '' } = item;

      return (
        <SideBarItem
          key={`section-item${index}`}
          active={pathname?.includes(route) && route !== disabledRoute}
          collapsed={isCollapsed}
          {...item}
        />
      );
    });
  };

  return (
    <div
      className={classNames('transition-all duration-75', {
        relative: !sticky,
        'sticky top-[var(--nav-header-height)] h-[calc(100vh-var(--nav-header-height))]':
          sticky,
      })}
    >
      {!hidden && (
        <div>
          <nav
            className={classNames(
              'py-5 px-3 flex flex-col justify-between border-t overflow-y-auto border-neutral-300 text-neutral-700 text-sm transition-[width]',
              'max-h-[calc(100vh-var(--nav-header-height))]',
              {
                'overflow-x-hidden': sticky,
                'pl-2 items-start w-sidebar-expanded': !isCollapsed,
                'items-start w-sidebar-collapsed': isCollapsed,
              }
            )}
          >
            {renderItems(items)}
          </nav>
          <button
            onClick={handleOnCollapseChange}
            className="bg-primary-700 hover:bg-primary-600 align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-semibold focus:outline-none absolute rounded-[50%] h-6 w-6 -right-3 top-9"
            aria-label={isCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
          >
            {isCollapsed ? (
              <ChevronRightIcon className="text-white w-3.5 h-3.5" />
            ) : (
              <ChevronLeftIcon className="text-white w-3.5 h-3.5" />
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default SideBar;
