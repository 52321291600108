import React from 'react';
import { Button } from 'ui';

interface DeleteInvestorModalProps {
  onClose: () => void;
  onConfirmDelete: () => void;
  investorName: string;
}

const DeleteInvestorModal: React.FC<DeleteInvestorModalProps> = ({
  onClose,
  onConfirmDelete,
  investorName,
}) => {
  return (
    <>
      <div className="heading-300 text-center pt-3 pb-7 max-w-sm">
        {`Do you want to delete ${investorName} as investor?`}
      </div>
      <div className="flex justify-between w-full">
        <Button layout="outline" className="w-40" onClick={onClose}>
          Cancel
        </Button>
        <Button layout="outline" className="w-40" onClick={onConfirmDelete}>
          Yes, delete
        </Button>
      </div>
    </>
  );
};

export { DeleteInvestorModal };
