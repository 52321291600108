import { CounterpartyDto } from '@/interfaces/loans';

export type Nullable<T> = T | null;

export const mapCounterpartiesToSelectOptions = (dtos?: CounterpartyDto[]) => {
  if (!dtos) return [];
  return dtos.map((dto) => ({
    label: `${dto.bankAccountHolderName} (${dto.bankAccountNumber})`,
    value: dto.id,
  }));
};
