import React, { useMemo } from 'react';
import { ProductComponentVisibility } from '@/hooks/useProductComponentConfiguration';
import { useRole } from '@/hooks/useRole';
import { mapLoanSummary } from '../BalanceWidget.utils';
import DetailedBalanceWidget from '../widgets/DetailedBalanceWidget';
import SimpleBalanceWidget from '../widgets/SimpleBalanceWidget';
import { ProductTypesNames } from '@/interfaces/loans';
import { LoanSummary } from '@/interfaces/loans/queries';

export interface BalanceWidgetResolverProps {
  children: React.ReactNode;
  loanBalanceConfig: ProductComponentVisibility;
  decliningBalance: boolean;
  isFactorLoan: boolean;
  isLoading?: boolean;
  isWrittenOffLoan: boolean;
  loanSummary: LoanSummary;
  openRepaymentSchedule?: () => void;
  principalAndInterest: boolean;
  type: string;
}

const BalanceWidgetResolver: React.FC<BalanceWidgetResolverProps> = ({
  children,
  loanBalanceConfig,
  decliningBalance,
  isFactorLoan,
  isLoading,
  isWrittenOffLoan,
  loanSummary,
  openRepaymentSchedule,
  principalAndInterest,
  type,
}) => {
  const { role } = useRole();

  const {
    balance,
    balancePaidPercent,
    detailedSummary,
    percentage,
    progress,
    simpleSummary,
    sumOfPaid,
    totalValue,
  } = mapLoanSummary(
    role,
    loanSummary,
    isWrittenOffLoan,
    isFactorLoan,
    loanBalanceConfig
  );

  const filteredProgress = useMemo(() => {
    if (!progress) return [];
    return progress.filter((data) => {
      if (data.id === 'principal') return loanBalanceConfig?.showPrincipal;
      if (data.id === 'interest') return loanBalanceConfig?.showInterest;
      if (data.id === 'fees') return loanBalanceConfig?.showFee;
      return true;
    });
  }, [progress, loanBalanceConfig]);

  if (
    (principalAndInterest && decliningBalance) ||
    type === ProductTypesNames.REVOLVING_CREDIT ||
    type === ProductTypesNames.DYNAMIC_TERM_LOAN
  ) {
    return (
      <DetailedBalanceWidget
        currency={loanSummary?.currency}
        isLoading={isLoading}
        mainSum={balance}
        openRepaymentSchedule={openRepaymentSchedule}
        percentage={balancePaidPercent}
        progress={filteredProgress}
        state={loanSummary?.state}
        summary={detailedSummary}
        totalPaidAmount={sumOfPaid}
        totalValue={balance}
      >
        {children}
      </DetailedBalanceWidget>
    );
  }

  return (
    <SimpleBalanceWidget
      currency={loanSummary?.currency}
      isLoading={isLoading}
      openRepaymentSchedule={openRepaymentSchedule}
      percentage={percentage}
      state={loanSummary?.state}
      summary={simpleSummary}
      totalPaidAmount={loanSummary?.totalPaidAmount}
      totalValue={totalValue}
    >
      {children}
    </SimpleBalanceWidget>
  );
};

export default BalanceWidgetResolver;
