import { isNotEmpty } from 'kennek/utils/validations';
import { isEmpty } from 'lodash';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { useGetOnboardingTaskStatusesQuery } from '@/services/accounts/borrower';
import { useGetConfigurationProductQuery } from '@/services/kennek';
import { useGetLoanProductRulesQuery, useGetLoanQuery } from '@/services/loans';
import { useGetLoanFacilitiesForLoanQuery } from '@/services/loans/facility';
import { useGetProductsByOriginatorIdQuery } from '@/services/products';
import { useSelectUser } from '@/store/user/selectors';
import { zonedStringDate } from '@/utils/datetime';
import { mapLoanDataToPayload } from '../Onboarding.utils';
import {
  useSelectIsLoanInformationFormReady,
  useSelectLoanFacilities,
  useSelectLoanInformationForm,
  useSelectRepaymentsForm,
} from '../onboardingSlice';
import { mapDtoToLoanInformationForm } from './LoanInformationContainer';
import { ScheduleForm, ScheduleFormType, ScheduleProps } from './ScheduleForm';
import {
  ChargeFrequency,
  InterestType,
  Loan,
  LoanProductRules,
  ProductTypesNames,
} from '@/interfaces/loans';

type ScheduleContainerProps = Pick<ScheduleProps, 'onContinue' | 'onBack'> & {
  isReview: boolean;
  loanId: string;
  borrowerCompanyExternalId: string;
  originatorExternalId: string;
  executionId: string;
  loanCreateMode: boolean;
  onChangeLoanId: (loanId: string) => void;
  borrowerEmail?: string;
};

const ScheduleContainer = ({
  loanId,
  borrowerCompanyExternalId,
  originatorExternalId,
  executionId,
  loanCreateMode,
  borrowerEmail,
  ...props
}: ScheduleContainerProps) => {
  const { data: loan, ...loanQuery } = useGetLoanQuery(loanId, {
    skip: !loanId,
  });
  const { data: onboardingTasks = [], ...onboardingTasksQuery } =
    useGetOnboardingTaskStatusesQuery(
      { loanId, borrowerEmail },
      { skip: !loanId }
    );
  const scheduleForm = useSelectRepaymentsForm();
  const localLoanFacilities = useSelectLoanFacilities();
  const { data: fetchedloanFacilities, ...loanFacilitiesQuery } =
    useGetLoanFacilitiesForLoanQuery(loan?.encodedKey, {
      skip: !loan?.encodedKey || !isEmpty(localLoanFacilities),
    });

  const loanFacilities = localLoanFacilities || fetchedloanFacilities;

  const isLoanInformationFormReady = useSelectIsLoanInformationFormReady();

  const loanInformationForm = useSelectLoanInformationForm();

  const user = useSelectUser();

  const isEdit =
    onboardingTasks?.find(({ name }) => name === 'LOAN_DETAILS')?.status ===
    'COMPLETED';

  const { data: products, ...productsQuery } =
    useGetProductsByOriginatorIdQuery(
      { id: user?.mambuUser?.[0]?.mambuBranchEncodedKey },
      { skip: !user?.mambuUser?.[0]?.mambuBranchEncodedKey }
    );

  const loanInformation = loanInformationForm
    ? loanInformationForm
    : loan
      ? mapDtoToLoanInformationForm({ loan, products, company: undefined }) // company not needed
      : undefined;

  const { data: loanProductRules, ...rulesQuery } = useGetLoanProductRulesQuery(
    { id: loanInformation?.productTypeKey || loan?.productTypeKey },
    { skip: !loanInformation?.productTypeKey && !loan?.productTypeKey }
  );

  const { data: configurationProductData, ...configurationProductQuery } =
    useGetConfigurationProductQuery(
      {
        branchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
        productEncodedKey: loanInformation?.productTypeKey,
      },
      {
        skip:
          !user?.mambuUser?.[0]?.mambuBranchEncodedKey ||
          !loanInformation?.productTypeKey,
      }
    );
  const initialFormValues = scheduleForm
    ? scheduleForm
    : loan
      ? mapDtoToScheduleForm(loan, 'ONBOARDING')
      : EMPTY_FORM;

  if (
    !isLoanInformationFormReady ||
    loanQuery.isFetching ||
    loanFacilitiesQuery.isFetching ||
    rulesQuery.isFetching ||
    onboardingTasksQuery?.isFetching ||
    productsQuery.isFetching ||
    (configurationProductQuery.isFetching &&
      !configurationProductQuery.currentData)
  )
    return (
      <div className="flex justify-center items-center">
        <LoadingAnimationPlat fitBox />
      </div>
    );

  return (
    <ScheduleForm
      initialFormValues={initialFormValues}
      loanProductRules={loanProductRules}
      loanAmount={loanInformation?.loanAmount}
      balloonPaymentAmount={
        isNotEmpty(loanInformation?.balloonPaymentAmount)
          ? parseFloat(loanInformation?.balloonPaymentAmount)
          : null
      }
      configurationProductData={configurationProductData}
      formFlow="ONBOARDING"
      loanFacilities={loanFacilities}
      products={products}
      loanCreateMode={loanCreateMode}
      loan={loan}
      isEdit={isEdit}
      getPayload={(scheduleForm: ScheduleFormType) =>
        mapLoanDataToPayload({
          borrowerCompanyExternalId,
          originatorExternalId,
          loan,
          loanProductRules,
          loanInformationForm: loanInformation,
          scheduleForm,
          loanFacilities,
          executionId,
          configurationProductData,
        })
      }
      {...props}
    />
  );
};

export const getInterestConfig = ({
  loanProductRules,
}: {
  loanProductRules: LoanProductRules;
}) => {
  const interestType = loanProductRules?.prefilledFields?.find(
    ({ name }) => name === 'interestType'
  )?.value as InterestType;

  const chargeFrequency = loanProductRules?.prefilledFields?.find(
    ({ name }) => name === 'interestChargeFrequency'
  )?.value as ChargeFrequency;

  return { interestType, chargeFrequency };
};

export const mapDtoToScheduleForm = (
  loan: Loan | undefined,
  formFlow: 'ONBOARDING' | 'REFINANCE',
  loanAmount?: number
): ScheduleFormType | undefined => {
  if (!loan) return;
  return {
    monthlyRepaymentDay: loan.scheduleSettings?.monthlyRepaymentDay,
    repaymentInstallments: loan.scheduleSettings?.repaymentInstallments,
    payCurrentInstallment: loan.payCurrentInstallment,
    minimumInterestPeriod: loan.minimumInterestPeriod,
    interestGraceInstallments: loan.interestSettings?.interestGraceInstallments,
    repaymentPeriodCount: loan.scheduleSettings?.repaymentPeriodCount,
    repaymentPeriodUnits: loan.scheduleSettings?.repaymentPeriodUnits,
    paymentHoliday: loan.paymentHoliday,
    rollUpDuration: loan.rollUpDuration,
    interestGraceHandleMethod: loan.interestSettings?.interestGraceHandleMethod,
    disbursementDate: loan.disbursementDetails?.expectedDisbursementDate
      ? zonedStringDate(loan.disbursementDetails.expectedDisbursementDate)
      : loan.disbursementDetails?.disbursementDate
        ? zonedStringDate(loan.disbursementDetails.disbursementDate)
        : undefined,
    firstRepaymentDate: loan.firstRepaymentDate
      ? zonedStringDate(loan.firstRepaymentDate)
      : undefined,
    tranches: mapTranchesDtoToScheduleForm(loan, formFlow, loanAmount || 0),
    counterpartyId: loan.counterparty?.id,
  };
};

const getDifference = (fromForm: number, fromLoan?: number) =>
  fromForm >= 0 ? fromForm - fromLoan : 0;

const mapTranchesDtoToScheduleForm = (
  loan: Loan,
  formFlow: 'ONBOARDING' | 'REFINANCE',
  loanAmount: number
): ScheduleFormType['tranches'] => {
  if (
    loan.productType === ProductTypesNames.REVOLVING_CREDIT &&
    formFlow === 'REFINANCE'
  ) {
    const fromLoan = parseFloat(
      (
        loan?.balances?.interestBalance + loan?.balances?.principalBalance
      ).toFixed(2)
    );
    const amountDifference = getDifference(loanAmount, fromLoan);

    return [
      {
        disbursementAmount: amountDifference > 0 ? fromLoan : loanAmount,
        disbursementDate: zonedStringDate(new Date()),
        fees: {},
        facilities: [],
        counterpartyId: '',
      },
    ];
  }

  return loan?.tranches?.map((tranche) => {
    const fees = tranche?.fees?.reduce(
      (acc, { predefinedFeeEncodedKey, amount }) => ({
        ...acc,
        [predefinedFeeEncodedKey]: {
          amount: amount || '',
          enabled: true,
        },
      }),
      {}
    );
    return {
      disbursementAmount: tranche?.amount,
      disbursementDate: tranche?.disbursementDate
        ? zonedStringDate(tranche?.disbursementDate)
        : undefined,
      fees,
      facilities:
        tranche?.facilities?.map((facility) => ({
          ...facility,
          amount: facility.amount?.toString() || '',
        })) || [],
      counterpartyId: tranche?.counterpartyId || '',
    };
  });
};

export const EMPTY_FORM: ScheduleFormType = {
  repaymentInstallments: null,
  payCurrentInstallment: null,
  minimumInterestPeriod: null,
  interestGraceInstallments: null,
  monthlyRepaymentDay: null,
  repaymentPeriodCount: null,
  repaymentPeriodUnits: null,
  paymentHoliday: null,
  rollUpDuration: null,
  firstRepaymentDate: '',
  disbursementDate: '',
  counterpartyId: '',
  tranches: [
    {
      disbursementDate: '',
      disbursementAmount: '' as unknown as number, // using `null` does not override previous value
      fees: {},
      facilities: [],
      counterpartyId: '',
    },
  ],
};

export { ScheduleContainer };
