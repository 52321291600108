import { rootApi } from '../rootApi';
import {
  LoanIdValidQuery,
  UpdateUser,
  User,
  UserQuery,
  UserRoleQuery,
  UserTypes,
} from 'kennek/interfaces/accounts';

export const baseAccountsApi = '/api/accounts';

export const accountsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getUsersByRole: builder.query<Array<User>, UserRoleQuery>({
      query: ({ role, branchEncodedKey }) => ({
        url: baseAccountsApi + `/user/role/${role}`,
        method: 'GET',
        params: {
          branchEncodedKey,
        },
      }),
    }),
    getUserByEmail: builder.query<User, UserQuery>({
      query: ({ email, mambuBranchEncodedKey }) => ({
        url: baseAccountsApi + '/user',
        method: 'GET',
        params: {
          email,
          mambuBranchEncodedKey,
          details: true,
        },
      }),
      providesTags: ['Users'],
    }),
    getUserById: builder.query<User, { id: string }>({
      query: ({ id }) => ({
        url: baseAccountsApi + '/user/' + id,
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getAllUsersByRole: builder.query<User[], UserRoleQuery>({
      async queryFn(...[arg, , , baseQuery]) {
        try {
          const { role, branchEncodedKey } = arg;

          const params = {
            details: true,
            branchEncodedKey: branchEncodedKey,
          };

          const response = await baseQuery({
            method: 'GET',
            url:
              role === 'BORROWER'
                ? '/api/accounts/user/all'
                : `/api/accounts/user/role/${role}`,
            params,
          });

          if ('error' in response) {
            return { error: response.error };
          }

          const data: User[] = [];

          data.push(...(response?.data as User[]));

          if (
            ['ORIGINATOR_ADMIN', 'ORIGINATOR_ANALYST'].some((r) => r === role)
          ) {
            const secondaryRole = [
              'ORIGINATOR_ADMIN',
              'ORIGINATOR_ANALYST',
            ].find((r) => r !== role);

            const response = await baseQuery({
              method: 'GET',
              url: `/api/accounts/user/role/${secondaryRole}`,
              params,
            });

            if ('error' in response) {
              return { error: response.error };
            }

            data.push(...(response?.data as User[]));
          }

          return { data };
        } catch (error) {
          return { error };
        }
      },
      providesTags: ['Users'],
    }),
    createOriginator: builder.mutation({
      query: (
        payload: Partial<User & { branchEncodedKey: string; role: UserTypes }>
      ) => ({
        url: baseAccountsApi + '/originator',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: builder.mutation({
      query: ({ id, mambuClientEncodedKey, mambuBranchEncodedKey }) => ({
        url: baseAccountsApi + `/user/${id}`,
        method: 'DELETE',
        params: {
          ...(mambuClientEncodedKey && { mambuClientEncodedKey }),
          ...(mambuBranchEncodedKey && { mambuBranchEncodedKey }),
        },
      }),
      invalidatesTags: ['Users'],
    }),
    patchUser: builder.mutation({
      query: (payload: UpdateUser) => ({
        url: baseAccountsApi + '/user',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (...[, error]) => (error ? [] : ['Users']),
    }),
    validateLoanId: builder.query<boolean, LoanIdValidQuery>({
      query: (params) => ({
        url: baseAccountsApi + '/user/validate-loan-external-id',
        params,
      }),
    }),
  }),
});

export const {
  useGetUsersByRoleQuery,
  useLazyGetUserByEmailQuery,
  useGetUserByEmailQuery,
  useLazyGetAllUsersByRoleQuery,
  useGetAllUsersByRoleQuery,
  useCreateOriginatorMutation,
  useDeleteUserMutation,
  useLazyValidateLoanIdQuery,
  usePatchUserMutation,
  useGetUserByIdQuery,
} = accountsApi;

export const { useQuerySubscription: useGetUserByEmailQuerySubscription } =
  accountsApi.endpoints.getUserByEmail;
