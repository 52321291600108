import React from 'react';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { SideBarStatus } from '../SideBar/SideBar';

type YPosition = 'top' | 'bottom';
type XPosition = 'right' | 'left';
type Positions = YPosition | XPosition | SideBarStatus;

export interface SnackBarContainerProps {
  yPosition: YPosition;
  xPosition: XPosition;
  sidebarStatus?: SideBarStatus;
  open: boolean;
  children: React.ReactNode;
}

const SnackBarContainer: React.FC<SnackBarContainerProps> = ({
  children,
  yPosition,
  xPosition,
  sidebarStatus,
  open,
}) => {
  return (
    <div
      className={classNames(
        'fixed z-[110]',
        positions[yPosition],
        positions[xPosition],
        sidebarStatus ? positions[sidebarStatus] : ''
      )}
    >
      <Transition
        show={open}
        enter="transition-all ease-in duration-200"
        enterFrom="translate-y-10 opacity-0"
        enterTo="translate-y-0 opacity-1"
        leave="transition-all ease-out duration-75"
        leaveFrom="-translate-y-0 opacity-1"
        leaveTo="translate-y-0 opacity-0"
        className="self-start"
      >
        {children}
      </Transition>
    </div>
  );
};

const positions: Record<Positions, string> = {
  right: 'right-0',
  left: 'left-0',
  top: 'top-3',
  bottom: 'bottom-20',
  collapsed: 'ml-sidebar-collapsed',
  expanded: 'ml-sidebar-expanded',
};

export default SnackBarContainer;
