import { FC, useState } from 'react';
import { Dialog } from 'ui';
import { InvestmentsAmountDetails } from '@/features/edit-loan/InvestmentsAmountDetails';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { formatAmount } from '@/utils/formatters';
import { Currencies } from 'kennek/interfaces/loans';
import { AmountToFundType } from '@/interfaces/loans';

interface AmountToFundDetailsProps {
  amountToFundType: AmountToFundType;
  amountToAllocate: number;
  topUp?: number;
  total: number;
  currency: Currencies;
  deductedFees: number;
  capitalizedFees: number;
  originalLoanAmount?: number;
}

const AmountToFundDetails: FC<AmountToFundDetailsProps> = ({
  amountToFundType,
  capitalizedFees,
  deductedFees,
  topUp,
  originalLoanAmount,
  total,
  amountToAllocate,
  currency,
}) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const openModal = () => setIsDetailsModalOpen(true);
  const closeModal = () => setIsDetailsModalOpen(false);
  const { loanUpper, loanLower } = useGetLabelsConfig();

  return (
    <>
      <div className="flex flex-col items-end mr-3">
        <div className="heading-100 text-neutral-600 select-none">
          Amount to fund
        </div>
        {amountToFundType ? (
          <>
            <div className="heading-500">
              {formatAmount(amountToAllocate, true, currency)}
            </div>
            <div
              className="body-100 text-secondary-400 cursor-pointer"
              onClick={openModal}
            >
              See details
            </div>
          </>
        ) : (
          <div className="heading-200">
            Select Net, Gross or custom {loanUpper} amount
          </div>
        )}
      </div>

      <Dialog open={isDetailsModalOpen} onClose={closeModal}>
        <InvestmentsAmountDetails.Dialog
          total={total}
          currency={currency}
          amountItems={[
            <InvestmentsAmountDetails.ListItem
              amount={originalLoanAmount ?? 0}
              currency={currency}
              title={`Original ${loanLower} amount`}
              visible={(originalLoanAmount ?? 0) > 0}
              key={1}
            />,
            <InvestmentsAmountDetails.ListItem
              amount={topUp ?? 0}
              currency={currency}
              title="Top up"
              visible={(topUp ?? 0) > 0}
              key={2}
            />,
            <InvestmentsAmountDetails.ListItem
              amount={capitalizedFees}
              currency={currency}
              title="Capitalised fees"
              visible={
                capitalizedFees > 0 &&
                (amountToFundType === AmountToFundType.GROSS ||
                  amountToFundType === AmountToFundType.CUSTOM)
              }
              key={3}
            />,
            <InvestmentsAmountDetails.ListItem
              amount={deductedFees}
              currency={currency}
              title="Deducted fees"
              negative={true}
              visible={
                deductedFees > 0 &&
                (amountToFundType === AmountToFundType.NET ||
                  amountToFundType === AmountToFundType.CUSTOM)
              }
              key={4}
            />,
          ]}
        />
      </Dialog>
    </>
  );
};

export default AmountToFundDetails;
