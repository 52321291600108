import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { RootContext } from './Root';
import { StepContext } from './Step';

type SubstepProps<T extends string> = {
  id: T;
  title: string;
  hidden?: boolean;
  accessible?: boolean;
};

const Substep = <T extends string>({
  title,
  id: stepId,
  hidden,
  accessible = true,
}: SubstepProps<T>) => {
  const { control, disableSkip } = useContext(RootContext);
  const { index, substepsCount, parentIndex } = useContext(StepContext);

  const isLast = substepsCount === index + 1;

  const isActive = control.activeStepId === stepId;

  const isCompleted = control.internal.completed.has(stepId);

  const {
    internal: { structureRef },
  } = control;

  useEffect(() => {
    const structure = structureRef.current;
    structure.add({
      stepId,
      order: parentIndex + index,
      accessible,
    });
    return () => {
      structure.delete(stepId);
    };
  }, [stepId, structureRef, accessible]);

  const isButtonEnabled =
    !disableSkip && control.internal.isButtonEnabled(stepId);

  return (
    <>
      {control.activeStepId?.split('.')[0] === stepId?.split('.')[0] && (
        <div className="px-7">
          <div className="flex items-center">
            <Dot completed={isCompleted} active={isActive} />
            <button
              type="button"
              onClick={() => control.internal.onClick(stepId)}
              className={classNames(
                'heading-100 text-neutral-600 text-ellipsis overflow-hidden ml-[21px] min-w-[100px] flex items-center text-left',
                {
                  'text-neutral-800': isActive,
                  'text-primary-700': isCompleted,
                  'cursor-pointer': isButtonEnabled,
                  'cursor-default': !isButtonEnabled,
                }
              )}
            >
              {title}
            </button>
          </div>
          {!hidden && !isLast && <DotLine completed={isCompleted} />}
        </div>
      )}
    </>
  );
};

const Dot: React.FC<{ completed?: boolean; active?: boolean }> = ({
  completed,
  active,
}) => {
  return (
    <span
      className={classNames(
        'w-[7px] min-w-[7px] h-[7px] bg-neutral-600 rounded-full flex',
        {
          'bg-primary-900': active,
          'bg-primary-500': completed,
        }
      )}
    />
  );
};

const DotLine: React.FC<{ completed?: boolean }> = ({ completed }) => {
  return (
    <div
      className={classNames(
        'border-l-2 h-[14px] border-dotted border-neutral-600 ml-[2.5px] my-1',
        {
          'border-primary-500': completed,
        }
      )}
    />
  );
};

export { Substep };
export type { SubstepProps };
