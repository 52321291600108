import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import BadgeSection from '../badge-section/BadgeSection';

const BadgeFactory = ({ state }: { state: string }) => {
  const { loanLower } = useGetLabelsConfig();
  const badges = {
    RESTRUCTURED: {
      label: 'Restructured',
      title: `This ${loanLower} has been restructured`,
      type: 'error',
    },
    RESCHEDULED: {
      label: 'Rescheduled',
      title: `This ${loanLower} has been rescheduled`,
      type: 'error',
    },
    REPAID: {
      label: 'Repaid in full',
      title: `This ${loanLower} has been repaid in full`,
      type: 'success',
    },
  };

  const badge = badges[state];
  if (badge) {
    return (
      <BadgeSection label={badge.label} title={badge.title} type={badge.type} />
    );
  }
};

export default BadgeFactory;
