import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import classNames from 'classnames';
import { kennekLogo } from 'kennek/assets';
import { FullCopyrightStatement } from './FullCopyrightStatement';
import { SimplifiedCopyrightStatement } from './SimplifiedCopyrightStatement';

const Footer: React.FC<{
  className?: string;
  modulrDisclaimerUrl?: string;
  footerMode?: 'full' | 'simplified';
}> = (props) => {
  const { className, modulrDisclaimerUrl, footerMode = 'full' } = props;
  const isFullFooterMode = footerMode === 'full';
  const footerLayout = isFullFooterMode
    ? 'flex-col justify-center gap-4'
    : 'flex-row-reverse justify-between';

  return (
    <footer
      className={classNames(
        'flex items-center border-t mt-5 m-auto max-w-7xl w-11/12 pt-6 pb-8 border-neutral-300 text-neutral-700 text-sm z-50',
        footerLayout,
        className
      )}
    >
      <div className="flex gap-8 text-center">
        <Link
          href="https://kennek.io/privacy-policy"
          target="_blank"
          className="headings-300 text-secondary-400"
        >
          Privacy
        </Link>
        {modulrDisclaimerUrl && (
          <Link
            href={modulrDisclaimerUrl}
            target="_blank"
            className="headings-300 text-secondary-400"
          >
            Modulr Disclaimer
          </Link>
        )}
        <div className="flex gap-1 text-center cursor-default">
          <div>Powered by</div>
          <Image
            src={kennekLogo}
            alt="kennekLogo"
            width={50}
            height={16}
          ></Image>
        </div>
      </div>
      <div className="flex text-center flex-column">
        {isFullFooterMode ? (
          <FullCopyrightStatement></FullCopyrightStatement>
        ) : (
          <SimplifiedCopyrightStatement></SimplifiedCopyrightStatement>
        )}
      </div>
    </footer>
  );
};

export default Footer;
