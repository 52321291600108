import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { PaginationSettings } from '../Select';
import { SEARCH_HEIGHT, SPARE_VISIBILITY } from '../configConst';

interface VirtualScrollSelectOptionsProps<T> {
  items: T[];
  itemHeight: number;
  renderItem: (item: T) => JSX.Element;
  selectOptionsHeight: number;
  pagination?: PaginationSettings;
}

interface VirtualScrollHandle {
  scrollToIndex: (index: number) => void;
}

const VirtualScrollSelectOptions = <T,>(
  {
    items,
    itemHeight,
    renderItem,
    selectOptionsHeight,
    pagination,
  }: VirtualScrollSelectOptionsProps<T>,
  ref: Ref<VirtualScrollHandle>
) => {
  const [visibleItems, setVisibleItems] = useState<T[]>([]);
  const [scrollTop, setScrollTop] = useState(0);
  const [currentItemsLength, setCurrentItemsLength] = useState<number | null>(
    null
  );

  const containerRef = useRef<HTMLDivElement | null>(null);

  const itemsInView = Math.ceil(selectOptionsHeight / itemHeight);
  const startIndex = Math.floor(scrollTop / itemHeight);
  const endIndex = Math.min(items.length, startIndex + itemsInView);

  const handleScroll = () => {
    const scrollPosition = containerRef.current?.scrollTop || 0;
    setScrollTop(scrollPosition);
  };

  useEffect(() => {
    containerRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setVisibleItems(items.slice(startIndex, endIndex + SPARE_VISIBILITY));
    if (!pagination) return;
    const { isLoadingSettings, total, defaultOptions } = pagination;
    const userIsOnBottomScroll = endIndex + SPARE_VISIBILITY > items.length;
    const isMaxItemsFetched =
      total !== null ? total + defaultOptions <= items.length : false;
    const canLoadMoreOptions = !isLoadingSettings && !isMaxItemsFetched;
    const itemsAreChanges = items.length !== currentItemsLength;
    if (userIsOnBottomScroll && canLoadMoreOptions && itemsAreChanges) {
      pagination.fetchMoreOptions();
      setCurrentItemsLength(items.length);
    }
  }, [items, startIndex, endIndex, pagination]);

  useEffect(() => {
    if (!containerRef.current) return;
    const newScrollTop = Math.min(scrollTop, (items.length - 1) * itemHeight);
    containerRef.current.scrollTop = newScrollTop;
  }, [visibleItems.length, items.length]);

  useImperativeHandle(ref, () => ({
    scrollToIndex: (index: number) => {
      const middleIndex =
        index > selectOptionsHeight / (2 * itemHeight)
          ? index - selectOptionsHeight / (2 * itemHeight)
          : index;
      if (!containerRef.current) return;
      containerRef.current.scrollTo({
        top: middleIndex * itemHeight,
      });
    },
  }));

  return (
    <div
      ref={containerRef}
      style={{
        maxHeight: `${pagination ? selectOptionsHeight - SEARCH_HEIGHT : selectOptionsHeight}px`,
      }}
      className={'overflow-y-auto overflow-x-hidden'}
    >
      <div
        style={{
          height: items.length * itemHeight + 'px',
          position: 'relative',
        }}
      >
        <div
          style={{ position: 'absolute', top: startIndex * itemHeight + 'px' }}
        >
          {visibleItems.map((item, index) => (
            <div key={index}>{renderItem(item)}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(VirtualScrollSelectOptions) as <T>(
  props: VirtualScrollSelectOptionsProps<T> & { ref?: Ref<VirtualScrollHandle> }
) => ReturnType<typeof VirtualScrollSelectOptions>;
