import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/outline';

interface Props {
  onArrowBackClick: React.MouseEventHandler<SVGSVGElement>;
  title: string;
}

const Path: React.FC<Props> = ({ onArrowBackClick, title }) => {
  return (
    <div className="flex justify-start items-center text-secondary-600 my-6">
      <ArrowLeftIcon
        width={20}
        className="cursor-pointer"
        onClick={onArrowBackClick}
      />
      <div className="heading-500 ml-4 select-none">{title}</div>
    </div>
  );
};

export default Path;
