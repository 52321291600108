import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import classNames from 'classnames';
import { isNumber } from 'lodash';
import { ShowPassword } from 'ui';
import { QuestionMarkCircleIcon, XIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { formatLineBreaks } from '../utils/FormatText.utils';
import { InputLayoutType } from './Input';
import { StandardInput } from './StandardInput';
import SymbolSelect, { FormInputSymbolOption } from './SymbolSelect';
import Tooltip from './Tooltip';

export const currencies = ['EUR', 'GBP', 'USD'] as const;

export type CurrencyEquivalentValue = (typeof currencies)[number];

export const currencySymbol: Record<CurrencyEquivalentValue, string> = {
  EUR: '€',
  GBP: '£',
  USD: '$',
};

export interface FormInputProps extends React.ComponentPropsWithRef<'input'> {
  errors?: string;
  showErrorMessage?: boolean;
  dynamicHeightErrorBanner?: boolean;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  label?: string;
  leftSymbol?: string;
  rightIcon?: boolean;
  withSpacing?: boolean;
  bottomLabel?: string;
  bottomLabelTopic?: string;
  questionTooltip?: string;
  showXButton?: boolean;
  passwordEye?: boolean;
  symbolSelectOptions?: FormInputSymbolOption[];
  setSelectedSymbol?: (val: string) => void;
  selectedSymbol?: string;
  allowAutoComplete?: boolean;
  handleXButton?: () => void;
  defaultValue?: number;
  currencyInputDefaultValue?: string | number;
  defaultValueSetter?: () => void;
  handleEditButton?: () => void;
  className?: string;
  containerClassName?: string;
  showEditButton?: boolean;
  info?: number;
  currencyInput?: CurrencyEquivalentValue;
  onValueChange?: (value: string | undefined) => void;
  errorBottomPosition?: string;
  errorTextAlign?: string;
  layout?: InputLayoutType;
  noMargins?: boolean;
  customLeftPadding?: number | string;
}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  function FormInput(props, ref) {
    const {
      errors,
      showErrorMessage = true,
      dynamicHeightErrorBanner = false,
      disabled,
      placeholder,
      label,
      leftSymbol,
      rightIcon = false,
      withSpacing = true,
      bottomLabel,
      bottomLabelTopic = '',
      questionTooltip,
      showXButton,
      handleXButton,
      handleEditButton,
      passwordEye = false,
      selectedSymbol,
      setSelectedSymbol,
      symbolSelectOptions,
      currencyInputDefaultValue = '',
      defaultValue,
      defaultValueSetter,
      className = '',
      containerClassName = '',
      showEditButton,
      info,
      currencyInput,
      onValueChange,
      errorBottomPosition = 'bottom-auto mt-2',
      errorTextAlign = '',
      noMargins,
      customLeftPadding = 0,
      ...other
    } = props;

    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [passwordVision, setPasswordVision] = useState<boolean>(false);

    const numberInputOnWheelPreventChange = (e: any, type: string) => {
      if (type === 'number') {
        e.target.blur();
        e.stopPropagation();
        setTimeout(() => {
          e.target.focus();
        }, 0);
      }
    };

    const symbolSelectAvailable = !!(setSelectedSymbol && symbolSelectOptions);

    return (
      <div
        className={classNames(
          containerClassName,
          noMargins ? 'relative' : 'relative mt-1',
          dynamicHeightErrorBanner
            ? 'mb-5'
            : withSpacing
              ? errors
                ? 'h-[95px]'
                : 'h-[82px]'
              : ''
        )}
      >
        <label className={'relative block heading-100 select-none'}>
          {label && (
            <div
              className="flex items-center gap-x-1 mb-[5px] w-fit"
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              {label}
              {questionTooltip && (
                <div className="relative flex items-center">
                  <QuestionMarkCircleIcon
                    width={14}
                    className="cursor-pointer"
                  />
                  {showTooltip && (
                    <Tooltip position="right">
                      {formatLineBreaks(questionTooltip)}
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          )}
          {!currencyInput ? (
            <StandardInput
              {...props}
              ref={ref}
              passwordVision={passwordVision}
              symbolSelectAvailable={symbolSelectAvailable}
              numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
            />
          ) : (
            <CurrencyInput
              ref={ref}
              allowNegativeValue={false}
              decimalSeparator="."
              groupSeparator=","
              decimalScale={2}
              decimalsLimit={2}
              disabled={disabled}
              defaultValue={currencyInputDefaultValue}
              placeholder={placeholder}
              onValueChange={(value) => {
                onValueChange ? onValueChange(value) : undefined;
              }}
              className={classNames(
                'body-400 rounded-inputs border border-neutral-400 focus:outline-none focus:ring-0 focus:border-neutral-400 focus:outline-primary-500 outline-offset-2 outline-1 h-10 w-full mt-[5px]',
                className,
                {
                  'outline outline-error-300 outline-offset-2': errors,
                  'pl-10': leftSymbol || currencyInput,
                  'pl-[80px]': symbolSelectAvailable,
                  'pr-16': errors && (showXButton || passwordEye),
                  'pr-10':
                    (errors && !showXButton && !passwordEye) ||
                    (showXButton && !errors && !passwordEye) ||
                    (passwordEye && !errors && !showXButton),
                  'pr-44': isNumber(defaultValue),
                  'cursor-not-allowed bg-neutral-200 border-neutral-400':
                    disabled,
                  [`pl-${customLeftPadding}`]: customLeftPadding,
                }
              )}
              {...other}
              step={1}
              type="string"
            />
          )}

          {(leftSymbol || currencyInput) && (
            <span className="absolute bottom-3 left-3 heading-100 text-neutral-900">
              {currencyInput ? currencySymbol[currencyInput] : leftSymbol}
            </span>
          )}

          {symbolSelectAvailable && (
            <div className="absolute bottom-2 left-3">
              <SymbolSelect
                options={symbolSelectOptions}
                setSelectedValue={setSelectedSymbol}
                selectedValue={selectedSymbol}
              />
            </div>
          )}

          {errors && (
            <ExclamationCircleIcon
              className={classNames(
                'absolute text-error-500 w-5 h-5 bottom-[0.65rem]',
                rightIcon || showXButton || passwordEye || showEditButton
                  ? 'right-10'
                  : 'right-4'
              )}
            />
          )}
          {isNumber(defaultValue) && (
            <div
              onClick={
                defaultValueSetter ? () => defaultValueSetter() : undefined
              }
              className={classNames(
                'absolute text-secondary-400 body-200 bottom-[0.85rem] cursor-pointer',
                errors ? 'right-11' : 'right-3'
              )}
            >
              Restore default value
            </div>
          )}
          {passwordEye && (
            <ShowPassword
              showPassword={passwordVision}
              setShowPassword={setPasswordVision}
              className="absolute bottom-[0.62rem] right-4 w-5 h-5"
            />
          )}
          {showXButton && (
            <XIcon
              width={20}
              className="absolute text-neutral-600 bottom-[0.65rem] right-3 cursor-pointer"
              onClick={handleXButton}
            />
          )}
          {showEditButton && (
            <a
              className="absolute text-secondary-400 bottom-[0.70rem] cursor-pointer right-4"
              onClick={handleEditButton}
            >
              Edit
            </a>
          )}
        </label>
        {((errors && showErrorMessage) || bottomLabel) && (
          <div
            className={classNames(
              'body-200',
              errorBottomPosition,
              dynamicHeightErrorBanner ? 'block mt-2' : 'absolute'
            )}
          >
            <div className="flex flex-col">
              {bottomLabel && (
                <div className="text-neutral-700 body-300 mr-10 whitespace-nowrap">
                  <span className="text-neutral-600">{bottomLabelTopic}</span>
                  {bottomLabel}
                </div>
              )}
              {errors && (
                <span className={`text-error-700 ${errorTextAlign}`}>
                  {errors}
                </span>
              )}
            </div>
          </div>
        )}
        {!errors && info != null && (
          <div
            className={classNames(
              'body-200 bottom-0',
              dynamicHeightErrorBanner ? 'block mt-2' : 'absolute'
            )}
          >
            {!!info && info < 0 && currencyInput && (
              <span className="text-error-700">
                {`${currencySymbol[currencyInput]} ${Math.abs(info)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}  Write-off`}
              </span>
            )}
            {!!info && info > 0 && currencyInput && (
              <span className="text-success-700">
                {`${currencySymbol[currencyInput]} ${info
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} Top up`}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default FormInput;
