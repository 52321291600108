import { JwtPayload } from 'jwt-decode';
import { UserTypes } from 'kennek/interfaces/accounts';

/**
 * Checks jwt for user role.
 * @param jwt Decoded token
 * @returns Custom role for signed in user or amr in case of anonymous access
 */
export const getUserRole = (jwt: JwtPayload): UserTypes => {
  const customRole = jwt['custom:role'];
  if (customRole) return customRole;

  const amr = jwt['amr'];
  return amr && amr.length > 0 && amr[0];
};

/**
 * Checks if user role is a anonymous type.
 * @param role User role
 * @returns True if role describes anonymous access
 */
export const isAnonymousUser = (role: UserTypes): boolean => {
  return role === 'unauthenticated';
};
