import { LabelsConfig } from '../interfaces/labelsConfig';

export const labelsConfigDefault: LabelsConfig = {
  borrowerLower: 'borrower',
  borrowerUpper: 'Borrower',
  borrowersLower: 'borrowers',
  borrowersUpper: 'Borrowers',
  loanLower: 'loan',
  loanUpper: 'Loan',
  loansLower: 'loans',
  loansUpper: 'Loans',
} as const;
