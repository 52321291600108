import { useMemo } from 'react';
import { isNotEmpty } from 'kennek/utils/validations';
import { InvestmentFundingMethod } from '@/features/onboarding/investments/funding-methods/InvestmentFundingMethod.types';
import { sum } from '@/utils/math';
import {
  filterFeesByInvestmentFundingMethod,
  getDisbursementLoanAmount,
  getFeesFromStandardLoan,
  getFeesFromTrancheLoan,
} from './useInvestmentsSummary.utils';
import { AmountToFundType, Loan, LoanProductRules } from '@/interfaces/loans';
import { Fee } from '@/interfaces/loans/fee-transaction';
import { InvestmentFundingMethodFeeSettingsAdjustmentType } from '@/interfaces/loans/queries';

export const useInvestmentsSummary = (
  loan: Loan,
  rules: LoanProductRules,
  amountToFundType: AmountToFundType,
  topUp: number = 0,
  investmentFundingMethod?: InvestmentFundingMethod
) => {
  const hasTranches = loan?.tranches?.length > 0;
  const loanAmount = loan ? getDisbursementLoanAmount(loan) : 0;
  const areCapitalizedFeesIncludedInLoanAmount =
    loan?.capFeesIncludedInLoanAmount ?? false;
  const shouldUseCustomFundingMethod =
    amountToFundType === AmountToFundType.CUSTOM &&
    isNotEmpty(investmentFundingMethod);

  const fees = useMemo(
    () =>
      hasTranches
        ? getFeesFromTrancheLoan(loan, rules)
        : getFeesFromStandardLoan(loan, rules),
    [hasTranches, loan, rules]
  );

  const capitalizedFees = useMemo(
    () =>
      shouldUseCustomFundingMethod
        ? filterFeesByInvestmentFundingMethod(
            fees,
            investmentFundingMethod,
            InvestmentFundingMethodFeeSettingsAdjustmentType.CAPITALIZE
          )
        : fees.filter((fee) => fee.trigger === 'CAPITALIZED_DISBURSEMENT'),
    [shouldUseCustomFundingMethod, fees, investmentFundingMethod]
  );

  const deductedFees = useMemo(
    () =>
      shouldUseCustomFundingMethod
        ? filterFeesByInvestmentFundingMethod(
            fees,
            investmentFundingMethod,
            InvestmentFundingMethodFeeSettingsAdjustmentType.DEDUCT
          )
        : fees.filter((fee) => fee.trigger === 'DISBURSEMENT'),
    [shouldUseCustomFundingMethod, fees, investmentFundingMethod]
  );

  const originalLoanAmount = useMemo(() => {
    if (!areCapitalizedFeesIncludedInLoanAmount) return loanAmount;

    // here we want to show the original loan amount specified by user,
    // so in case if cap fees are included in the loan amount, we need to subtract them
    return loanAmount - sum(capitalizedFees, (fee: Fee) => fee.amount);
  }, [capitalizedFees, areCapitalizedFeesIncludedInLoanAmount, loanAmount]);

  const loanAmountWithCapitalizedFees = useMemo(
    () =>
      areCapitalizedFeesIncludedInLoanAmount
        ? loanAmount
        : sum(capitalizedFees, (fee: Fee) => fee.amount, loanAmount),
    [capitalizedFees, loanAmount, areCapitalizedFeesIncludedInLoanAmount]
  );

  const loanAmountWithoutDeductedFees = useMemo(
    () => originalLoanAmount - sum(deductedFees, (fee: Fee) => fee.amount),
    [deductedFees, originalLoanAmount]
  );

  const loanAmountWithCapitalizedAndDeductedFees = useMemo(() => {
    const capitalizedFeesAmount = !areCapitalizedFeesIncludedInLoanAmount
      ? sum(capitalizedFees, (fee: Fee) => fee.amount)
      : 0;

    const deductedFeesAmount = sum(deductedFees, (fee: Fee) => fee.amount);

    return loanAmount + capitalizedFeesAmount - deductedFeesAmount;
  }, [
    areCapitalizedFeesIncludedInLoanAmount,
    capitalizedFees,
    deductedFees,
    loanAmount,
  ]);

  const loanAmountWithFees = useMemo(() => {
    if (amountToFundType === AmountToFundType.CUSTOM) {
      return topUp + loanAmountWithCapitalizedAndDeductedFees;
    }

    if (amountToFundType === AmountToFundType.GROSS) {
      return topUp + loanAmountWithCapitalizedFees;
    }

    if (amountToFundType === AmountToFundType.NET) {
      return topUp + loanAmountWithoutDeductedFees;
    }

    return topUp;
  }, [
    amountToFundType,
    loanAmountWithCapitalizedAndDeductedFees,
    loanAmountWithCapitalizedFees,
    loanAmountWithoutDeductedFees,
    topUp,
  ]);

  return {
    originalLoanAmount,
    capitalizedFees,
    deductedFees,
    total: loanAmountWithFees,
  };
};
