import {
  ChargeFrequency,
  InterestType,
  LoanProductRules,
} from '@/interfaces/loans';

const SIMPLE_INTEREST_FREQUENCIES_LABELS: Record<ChargeFrequency, string> = {
  ANNUALIZED: 'per year',
  EVERY_MONTH: 'per month',
  EVERY_FOUR_WEEKS: 'per four weeks',
  EVERY_WEEK: 'per week',
  EVERY_DAY: 'per day',
};

export const useGetInterestTypeLabel = ({
  chargeFrequency,
  interestType,
}: {
  interestType: InterestType;
  chargeFrequency: ChargeFrequency;
}) => {
  const frequency = SIMPLE_INTEREST_FREQUENCIES_LABELS[chargeFrequency];

  switch (interestType) {
    case 'COMPOUNDING_INTEREST':
      return 'Compound';
    case 'SIMPLE_INTEREST':
      return `Simple ${frequency}`;
  }
};

export const getInterestConfig = ({
  loanProductRules,
}: {
  loanProductRules: LoanProductRules;
}) => {
  const interestType = loanProductRules?.prefilledFields?.find(
    ({ name }) => name === 'interestType'
  )?.value as InterestType;

  const chargeFrequency = loanProductRules?.prefilledFields?.find(
    ({ name }) => name === 'interestChargeFrequency'
  )?.value as ChargeFrequency;

  return { interestType, chargeFrequency };
};

export default useGetInterestTypeLabel;
