import { ChangeEvent, ReactNode, RefObject, useRef } from 'react';
import classNames from 'classnames';
import useGetIsDragging from '../hooks/useGetIsDragging';

interface DragAndDropInputWrapperProps {
  children: ReactNode;
  /**
   * List of file name extensions without leading period.
   * @example
   * ```ts
   * ["doc", "txt"] // Accepts *.doc and *.txt
   * ```
   */
  acceptFiles: string[] | undefined;
  handleFileSelection: (e: ChangeEvent<HTMLInputElement>) => void;
  inputFile: RefObject<HTMLInputElement>;
  hideFileInput?: boolean;
  testId?: string;
  disableFileInput?: boolean;
  uploadMultipleFiles?: boolean;
}

const DragAndDropInputWrapper = ({
  children,
  acceptFiles,
  handleFileSelection,
  inputFile,
  hideFileInput,
  testId,
  disableFileInput,
  uploadMultipleFiles,
}: DragAndDropInputWrapperProps) => {
  const dragAndDropZone = useRef<HTMLDivElement>(null);
  const isDragging = useGetIsDragging(dragAndDropZone);

  return (
    <div
      ref={dragAndDropZone}
      className={classNames('relative bg-neutral-200 w-[410px] rounded-lg', {
        'bg-primary-200 border-primary-500': isDragging,
      })}
      data-testid={testId}
    >
      {children}
      {!disableFileInput && (
        <input
          data-testid={`${testId}-input`}
          ref={inputFile}
          id="file"
          type="file"
          multiple={uploadMultipleFiles}
          className={classNames({
            'absolute top-0 left-0 opacity-0 cursor-pointer w-full h-full file:cursor-pointer':
              !hideFileInput,
            hidden: hideFileInput,
          })}
          accept={
            acceptFiles && acceptFiles.length > 0
              ? acceptFiles.map((f) => `.${f}`).join(',')
              : undefined
          }
          onChange={(e) => handleFileSelection(e)}
        />
      )}
    </div>
  );
};

export default DragAndDropInputWrapper;
