import React, { Fragment, memo } from 'react';
import { SelectOption } from 'ui';
import { Menu } from '@headlessui/react';

export interface Props {
  onClick?: (item: SelectOption) => void;
  list?: SelectOption[];
}

const Items: React.FC<Props> = ({ onClick, list }) => {
  return (
    <div className="max-h-[234px] overflow-y-scroll overflow-x-hidden max-w-[450px]">
      {list?.map((item, index) => {
        return (
          <Fragment key={index}>
            <Menu.Item
              as="div"
              className="flex items-center cursor-pointer py-3 rounded-md hover:bg-primary-100 z-[100]"
              onClick={() => onClick?.(item)}
            >
              <div className="flex gap-1 body-400 px-5 z-50 text-secondary-400 overflow-hidden">
                <p className="whitespace-nowrap text-ellipsis overflow-hidden">
                  {item?.label}
                </p>
              </div>
            </Menu.Item>
          </Fragment>
        );
      })}
    </div>
  );
};

export default memo(Items);
