import { useDispatch } from 'react-redux';
import { useRouter } from 'next/navigation';
import classNames from 'classnames';
import { Button, DropDown } from 'ui';
import { BellIcon } from '@heroicons/react/outline';
import { ROUTES } from '@/constants/routes';
import { SNACKBAR_NOTIFICATIONS } from '@/constants/snackbar-messages';
import {
  useGetAllUserNotificationsQuery,
  useReadBulkNotificationsMutation,
} from '@/services/notifications';
import { setSnackBar } from '@/store/alerts';
import { useSelectUser } from '@/store/user/selectors';
import { formatDate } from '@/utils/formatters';
import { Notification } from '@/interfaces/notifications';

function NotificationButton() {
  const router = useRouter();
  const dispatch = useDispatch();
  const user = useSelectUser();

  const { data: notifications = [] } = useGetAllUserNotificationsQuery(
    { email: user?.email },
    { skip: !user?.email }
  );

  const [markAllAsRead, { isLoading: isEditing }] =
    useReadBulkNotificationsMutation();

  const allUnread = notifications
    .filter((notification) => !notification.read)
    .map((notification) => notification._id);

  const onMarkAsReadClick = () => {
    markAllAsRead(allUnread).catch(() => {
      dispatch(
        setSnackBar({
          severity: 'error',
          title: SNACKBAR_NOTIFICATIONS.UPDATE_FAILED_TITLE,
          content: SNACKBAR_NOTIFICATIONS.UPDATE_FAILED_CONTENT,
        })
      );
    });
  };

  const sortedNotifications = [...notifications]
    ?.sort((a, b) =>
      a?.createdAt < b?.createdAt ? 1 : b?.createdAt < a?.createdAt ? -1 : 0
    )
    .slice(0, 5);

  return (
    <DropDown
      hoverColor="none"
      size="xl"
      separator={false}
      button={
        <div className="relative">
          <Button
            icon={<BellIcon />}
            layout="ghost"
            className="text-neutral-700 p-2"
            aria-label="Notifications"
            tag="div"
          />
          {allUnread.length > 0 && (
            <span className="bg-primary-600 ml-0 text-white caption rounded-full flex w-3.5 h-3.5 justify-center items-center absolute top-1 right-1.5 text-xxxs">
              {allUnread.length}
            </span>
          )}
        </div>
      }
    >
      <div className="flex justify-between border-b-2 border-neutral-300 py-4 w-full cursor-default">
        <span className="heading-200 text-neutral-900 pl-5">Notifications</span>
        <div className="flex">
          <button
            className="body-200 text-neutral-600 cursor-pointer mr-4 text-xs hover:underline"
            onClick={onMarkAsReadClick}
            disabled={isEditing}
          >
            Mark all as read
          </button>
          <button
            className="body-200 text-secondary-400 cursor-pointer mr-3 text-xs hover:underline"
            onClick={() => router.push(ROUTES.NOTIFICATIONS)}
          >
            View all
          </button>
        </div>
      </div>
      {!notifications?.length && (
        <div className="heading-400 text-neutral-600 p-4 px-8">
          There are no new notifications
        </div>
      )}
      {sortedNotifications.map((item: Notification, index) => {
        return (
          <div
            className="min-h-20 flex p-4 hover:bg-neutral-200 w-full"
            key={`notification-${index}`}
          >
            <div className="flex flex-col justify-center">
              <div
                className={classNames('w-2 h-2 mr-4', {
                  'rounded-full bg-primary-500': !item.read,
                })}
              />
            </div>
            <div className="flex flex-col justify-center">
              <div className="body-300 line-clamp-4">{item.message}</div>
              <div className="body-100 mt-2">
                {formatDate(item.updatedAt, 'dd MMMM, yyyy')}
              </div>
            </div>
          </div>
        );
      })}
    </DropDown>
  );
}

export default NotificationButton;
