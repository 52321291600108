import React from 'react';
import { Modal } from 'ui';
import { MailIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';

interface ContactSupportProps {
  isVisible: boolean;
  setIsVisible: () => void;
  email: string;
}

const ContactSupportModal: React.FC<ContactSupportProps> = ({
  isVisible,
  setIsVisible,
  email,
}) => {
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      includeHeader="withCloseButton"
      className="bg-opacity-40 top-0 bg-neutral-800 overflow-y-auto"
      paddingBodyX="px-8 py-4 pb-4 mt-0 mb-8 pt-0"
      width="w-full max-w-md"
    >
      <div className="flex flex-col items-center">
        <div className="flex justify-center items-center rounded-3xl bg-success-200 w-12 h-12">
          <QuestionMarkCircleIcon className="text-success-500 w-6 h-6" />
        </div>
        <span className="heading-500 mt-4">Support</span>
        <span className="mt-4 body-400">
          If you need assistance please contact us at
        </span>
        <div className="flex flex-row items-end mt-4">
          <MailIcon className="w-5 h-5 mr-1 mb-1 text-secondary-400"></MailIcon>
          <span className="heading-300-semibold text-secondary-400">
            <a href={`mailto:${email}`}>{email}</a>
          </span>
        </div>
      </div>
    </Modal>
  );
};
export default ContactSupportModal;
