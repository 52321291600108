import { LOAN } from '@/constants/numeric';

export const calculateFeeYield = (
  effectiveInterestRate?: number,
  grossYield?: number
): number | null => {
  if (!isFeeAmortizationProvided(effectiveInterestRate, grossYield))
    return null;
  const feeYield = grossYield - effectiveInterestRate;

  return feeYield < 0 ? null : feeYield;
};

export const isMaxEffectiveInterestRateBasedOnGrossYield = (
  effectiveInterestRate?: number,
  grossYield?: number
) =>
  isDefinedAndNumber(effectiveInterestRate) &&
  isDefinedAndNumber(grossYield) &&
  effectiveInterestRate <= LOAN.MAX_EFFECTIVE_INTEREST_RATE;

const isFeeAmortizationProvided = (
  effectiveInterestRate?: number,
  grossYield?: number
) =>
  isDefinedAndNumber(effectiveInterestRate) && isDefinedAndNumber(grossYield);

const isDefinedAndNumber = (value?: number) => !!value && !isNaN(value);
