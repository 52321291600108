import React from 'react';
import classNames from 'classnames';
import { Button, ModalTitleWithIcon } from 'ui';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import * as Dialog from '@radix-ui/react-dialog';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { formatDate } from '@/utils/formatters';

interface ConfirmFutureLoanDialogProps {
  open: boolean;
  disbursementDate: string;
  onClose: () => void;
  onSaveDraft: () => void;
  onConfirm: () => void;
}

export const ConfirmFutureLoanDialog: React.FC<
  ConfirmFutureLoanDialogProps
> = ({ open, disbursementDate, onClose, onSaveDraft, onConfirm }) => {
  const { loanLower } = useGetLabelsConfig();

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay
          className={classNames(
            'fixed',
            'inset-0',
            'z-[100]',
            'bg-neutral-900',
            'bg-opacity-60',
            'overflow-y-auto',
            'flex',
            'justify-center',
            'items-center'
          )}
        >
          <Dialog.Content className="min-w-[390px] max-w-[600px] bg-white p-[24px] rounded-md relative space-y-4">
            <div className="flex flex-col">
              <button
                onClick={onClose}
                className="flex-auto flex flex-row justify-end cursor-pointer text-neutral-700 font-normal hover:text-black outline-none"
              >
                <XIcon className="w-4" />
              </button>

              <ModalTitleWithIcon
                icon={<ExclamationIcon className="w-6 h-6 text-error-500" />}
                color="destructive"
                title={`Schedule ${loanLower} disbursement`}
              />

              <div className="font-medium text-[13px] text-center body-400 w-[336px] pt-1">
                {`By confirming, the ${loanLower} will be automatically activated on disbursement date of ${formatDate(
                  disbursementDate
                )}`}
              </div>

              <div className="flex justify-between mt-6">
                <Button layout="link" className="w-40" onClick={onSaveDraft}>
                  Save as draft
                </Button>
                <Button className="w-40" layout="link" onClick={onConfirm}>
                  Confirm
                </Button>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
