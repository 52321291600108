import React from 'react';
import classNames from 'classnames';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

interface ShowPasswordProps {
  showPassword: boolean;
  setShowPassword: (show: boolean) => void;
  className?: string;
}

const ShowPassword = ({
  showPassword,
  setShowPassword,
  className = '',
}: ShowPasswordProps) => {
  const handleToggleVision = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {showPassword ? (
        <EyeOffIcon
          onClick={handleToggleVision}
          className={classNames(
            'cursor-pointer text-neutral-600 w-5 h-5',
            className
          )}
        />
      ) : (
        <EyeIcon
          onClick={handleToggleVision}
          className={classNames(
            'cursor-pointer text-neutral-600 w-5 h-5',
            className
          )}
        />
      )}
    </>
  );
};

export default React.memo(ShowPassword);
