import { isEmpty } from 'lodash';
import { rootApi } from './rootApi';
import { Product } from 'kennek/interfaces/products';

const baseProductsApi = '/api/products';

export const productsApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getProductsByOriginatorId: builder.query<
      Product[],
      { id: string; state?: string; matchConfigurationProductId?: string }
    >({
      query: ({ id, ...params }) => ({
        url: baseProductsApi + `/${id}`,
        params: isEmpty(params) ? null : params,
      }),
    }),
    getAllProductsForUser: builder.query<Product[], void>({
      query: () => baseProductsApi + `/all`,
    }),
  }),
});

export const {
  useGetProductsByOriginatorIdQuery,
  useGetAllProductsForUserQuery,
} = productsApi;

export const {
  useQuerySubscription: useGetProductsByOriginatorIdQuerySubscription,
} = productsApi.endpoints.getProductsByOriginatorId;
