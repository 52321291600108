import { ReactNode } from 'react';
import useErrorHandler from '@/hooks/useErrorHandler';

const ErrorHandler = ({ children }: { children: ReactNode }) => {
  const {
    data: { error },
  } = useErrorHandler();

  const isErrorToDisplay = error.find((e) => e.id === 'error' && !e.statusCode);

  if (isErrorToDisplay) {
    return (
      <>
        {error.map((err, index: number) => {
          if (err.id === 'error' && !err.statusCode) {
            return (
              <div
                key={index}
                className="min-h-content flex flex-col grow justify-around text-center"
              >
                <div className="flex flex-col items-center gap-1">
                  <p className="heading-700-black">
                    Sorry, something went wrong
                  </p>
                  <span className="heading-400">
                    Please try again, or refresh the page.
                  </span>
                </div>
              </div>
            );
          }
          return <></>;
        })}
        ;
      </>
    );
  }

  return <>{children}</>;
};

export default ErrorHandler;
