import { RefObject, useEffect, useRef, useState } from 'react';

const useGetIsDragging = (
  dragAndDropZone: RefObject<HTMLDivElement>,
  canAddListeners = true
) => {
  const dragCounter = useRef(0);
  const [isDragging, setIsDragging] = useState(false);
  const handleDragEnter = () => {
    dragCounter.current++;
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };
  const handleDrop = () => {
    dragCounter.current = 0;
    setIsDragging(false);
  };

  useEffect(() => {
    if (!dragAndDropZone?.current || !canAddListeners) return;
    dragAndDropZone.current.addEventListener(
      'dragenter',
      handleDragEnter,
      false
    );
    dragAndDropZone.current.addEventListener(
      'dragleave',
      handleDragLeave,
      false
    );
    dragAndDropZone.current.addEventListener('drop', handleDrop, false);

    return () => {
      dragAndDropZone?.current?.removeEventListener(
        'dragenter',
        handleDragEnter
      );
      dragAndDropZone?.current?.removeEventListener(
        'dragleave',
        handleDragLeave
      );
      dragAndDropZone?.current?.removeEventListener('drop', handleDrop);
    };
  }, [dragAndDropZone, canAddListeners]);

  return isDragging;
};

export default useGetIsDragging;
