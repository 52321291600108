import { AmountToFundType } from '@/interfaces/loans';
import { InvestmentFundingMethodFeeSettingsAdjustmentType } from '@/interfaces/loans/queries';

export type InvestmentFundingMethod = {
  id: string;
  name: string;
  fees: InvestmentFundingMethodFeeSettings[];
  isPredefined: boolean;
  amountToFundType: AmountToFundType;
};

export type InvestmentFundingMethodFeeSettings = {
  feeId: string;
  name: string;
  enabled: boolean;
  adjustmentType: InvestmentFundingMethodFeeSettingsAdjustmentType;
};

export type InvestmentFundingMethodCreateFormData = {
  name: string;
  fees: InvestmentFundingMethodFeeSettings[];
};

export type InvestmentFundingMethodEditFormData = {
  id: string;
  name: string;
  fees: InvestmentFundingMethodFeeSettings[];
};

export enum PREDEFINED_FUNDING_METHOD_ID {
  GROSS = 'GROSS',
  NET = 'NET',
}
