interface Props {
  text: string;
  color: string;
  showDot?: boolean;
  className?: string;
}

function Chip({ text, color, showDot, className = '' }: Props) {
  return (
    <div
      className={`flex items-center rounded-full p-1 text-${color} border-2 border-${color} body-200 ${className}`}
    >
      {showDot && (
        <span className={`mr-1 h-1.5 w-1.5 bg-${color} rounded-full`} />
      )}
      <span className="">{text}</span>
    </div>
  );
}

export default Chip;
