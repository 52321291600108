import { useRef, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { SNACKBAR_ONBOARDING } from '@/constants/snackbar-messages';
import { useSnackbar } from '@/hooks/useSnackbar';
import { User } from 'kennek/interfaces/accounts';

export interface UploadingFiles {
  id: string;
  name: string;
  percentage: number;
  cancel: AbortController;
}

export interface UploadSettings {
  hideSnackbarSuccess?: boolean;
  isDraft?: boolean;
}

export const useUploadFile = () => {
  const [filesUploading, setFilesUploading] = useState<UploadingFiles[]>([]);
  const uploadSuccessRef = useRef(0);
  const snackbar = useSnackbar();

  const normalizeFileName = (name: string): string =>
    name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const onUploadDocument = async (
    file: File,
    user: User,
    loanEncodedKey: string,
    folderId: string,
    settings?: UploadSettings
  ) => {
    const borrowerFormData = new FormData();
    const id = uuid();

    if (user?.email) {
      borrowerFormData.append('file', file, normalizeFileName(file?.name));
      borrowerFormData.append('email', user?.email);
      borrowerFormData.append('userName', user?.userName);
      borrowerFormData.append('cognitoId', user?.cognitoId);
      borrowerFormData.append('uploadedByEmail', user?.email);
      borrowerFormData.append('uploadedByUserName', user?.userName);
      borrowerFormData.append('loanEncodedKey', loanEncodedKey);
      borrowerFormData.append('useDocumentManagement', 'true'); // Document management: TO remove after BE changes
      borrowerFormData.append('folderId', folderId);
    }
    if (settings?.isDraft) borrowerFormData.append('draft', 'true');

    const controller = new AbortController();
    setFilesUploading((prev) => {
      return [
        ...prev,
        {
          id,
          name: normalizeFileName(file.name),
          percentage: 0,
          cancel: controller,
        },
      ];
    });

    try {
      await axios.post('/api/loans/documents/upload-file', borrowerFormData, {
        onUploadProgress: ({ loaded, total }) => {
          const percentage = Math.floor((loaded * 100) / total);

          setFilesUploading((prev) => {
            const currentUploading = prev;
            const stateCopy = currentUploading.filter((f) => f.id !== id);
            const updating = currentUploading.find((f) => f.id === id);
            updating.percentage = percentage;
            const index = _.sortedIndexBy(stateCopy, updating, (o) => o.name);
            stateCopy.splice(index, 0, updating);
            return stateCopy;
          });
        },
        signal: controller.signal,
      });
      uploadSuccessRef.current += 1;

      if (!settings?.hideSnackbarSuccess) {
        snackbar.show({
          severity: 'primary',
          title: SNACKBAR_ONBOARDING.FILE_UPLOAD,
        });
      }
    } catch (error) {
      const status = error?.response?.status;
      if (status === 415) {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_ONBOARDING.FILE_UPLOAD_WRONG_EXTENSION,
        });
        return;
      }

      if (status === 413) {
        snackbar.show({
          severity: 'error',
          title: SNACKBAR_ONBOARDING.FILE_UPLOAD_MAX_SIZE_EXCEED,
        });
        return;
      }

      snackbar.show({
        severity: 'error',
        title: SNACKBAR_ONBOARDING.FILE_UPLOAD_FAILED,
        content: normalizeFileName(file?.name),
      });
    } finally {
      setFilesUploading((prev) => prev.filter((f) => f.id !== id));
    }
  };

  return {
    onUploadDocument,
    filesUploading,
    uploadSuccessRef,
    clearSuccessFilesCounter: () => (uploadSuccessRef.current = 0),
  };
};
