import { useState } from 'react';
import { Badge } from 'ui';
import { BadgeProps } from 'ui/components/Badge';
import Tooltip from 'ui/components/Tooltip';
import { formatAmount, formatDate } from '@/utils/formatters';
import { Currencies } from 'kennek/interfaces/loans';

const installmentState = {
  PARTIALLY_PAID: 'Pending',
  OVERDUE: 'Overdue',
  PAID: 'Paid',
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  GRACE: 'Grace period',
  RESTRUCTURED: 'Restructured',
  NONE: '-',
};

const PaidBadge = ({
  paidDate,
  state,
  totalPaid,
  currency,
}: {
  state: string;
  paidDate: string;
  totalPaid: number;
  currency?: Currencies;
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  let type: BadgeProps['type'];

  switch (state) {
    case 'PENDING':
      type = 'neutral';
      break;
    case 'PROCESSING':
      type = 'neutral';
      break;
    case 'OVERDUE':
      type = 'error';
      break;
    case 'GRACE':
      type = 'warning';
      break;
    case 'PARTIALLY_PAID':
      type = 'neutral';
      break;
    case 'PAID':
      type = 'success';
      break;
    default:
      type = 'neutral';
      break;
  }

  return (
    <div className="flex justify-end">
      <Badge
        type={type}
        onMouseOver={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="rounded-full px-1 flex justify-center w-24"
      >
        {state ? installmentState[state] : '-'}
      </Badge>
      {showTooltip && state === 'PAID' && (
        <Tooltip position="left" withoutArrow>
          {formatAmount(totalPaid, true, currency)} | {formatDate(paidDate)}
        </Tooltip>
      )}
      {showTooltip && state === 'PROCESSING' && (
        <Tooltip position="custom" customClasses="right-28 top-2" withoutArrow>
          This payment is awaiting confirmation and may take up to 2 business
          days.
        </Tooltip>
      )}
    </div>
  );
};

export { PaidBadge };
